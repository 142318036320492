import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CmsService } from '../cms.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { PopupService } from 'app/shared/popup.service';

@Component({
  selector: 'video-participation',
  templateUrl: './video-participation.component.html',
  styleUrls: ['./video-participation.component.scss']
})
export class VideoParticipationComponent implements OnInit {
  @Input() mediaType: string;
  @Input() assetId: number;
  details: any;
  cpds: any;
  campaigns: any;
  playlists: any;
  clientLibTotal: number;
  clientList:[];
  showClientListModal:boolean;

  constructor(private _cmsService: CmsService,
    private _popupService: PopupService,
    private _clipboard: Clipboard) { }

  ngOnInit(): void {
    console.log('###' + this.mediaType + " " + this.assetId);
    this.getVideoParticipationDetails();
  }


  getVideoParticipationDetails() {
    this._cmsService.getVideoParticipationDetails(this.mediaType, this.assetId)
      .subscribe(
        (data: any) => {
          this.details = data.result;
          this.cpds = this.details.filter(x => x.MediaType === 'cpd');
          this.playlists = this.details.filter(x => x.MediaType === 'playlist');
          this.campaigns = this.details.filter(x => x.MediaType === 'campaign');
          //format cpd path
          this.cpds = this.cpds.map(x => { x.NavParam = x.NavKey.split('/'); return x; });
        },
        err => {
          console.log(err);
        }
      );
    this._cmsService.getVideoParticipationCount(this.mediaType, this.assetId)
      .subscribe(
        (data: any) => {
          this.clientLibTotal = data.result.Item1;
          this.clientList = data.result.Item2.split(',');
        },
        err => {
          console.log(err);
        }
      );

  }

  stopProp($event: any): void {
    $event.stopPropagation();
  }

  showClientList() {
    console.log("show clientmodal");
    this.showClientListModal=!this.showClientListModal;
  }

  copyClientList(){
    this._clipboard.copy(this.clientList.toString());
    this._popupService.updateNotification({ message: 'Client list successfully copied to clipboard.', success: true });
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, Route, ActivatedRoute } from '@angular/router';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';

//  Services
import { CmsService } from '../cms.service';
import { UtilitiesService } from '../../shared/utilities.service';

// Components/Classes
import { CanadaReportBuildItem } from '../canadaReportBuildItem';
import { CanadaReportTemplateItem } from '../canadaReportTemplateItem';
import { Subscription } from 'rxjs';
import { ConfirmService } from 'app/shared/confirm.service';
import { MessagesService } from 'app/messages/messages.service';
import * as moment from 'moment';
import { ReportingService } from 'app/services/reporting.service';
import { SnackbarService } from 'app/services/Snackbar.service';

declare var tinymce: any;

@Component({
  selector: 'cms-canada-report-build', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-canada-report-build.component.html',
  providers: [MessagesService]
})

export class CmsCanadaReportBuildComponent implements OnInit, OnDestroy {

  errorMessage: string;

  buildItem: CanadaReportBuildItem;
  templates: CanadaReportTemplateItem[] = [];
  canadaDepts: any = [];
  showDeptField: boolean = false;
  showDatepickers: boolean = true;

  // confirm service variables
  confirmData: {};
  confirmBuildData: any;
  confirmAction: string;
  confirmSubscription: Subscription;

  constructor( public _cmsService: CmsService, public _messagesService: MessagesService, public _emsConfig: EmsConfig, public _utilitiesService: UtilitiesService, private router: Router, public _confirmService: ConfirmService, public _reportingService: ReportingService, public _snackbarService: SnackbarService) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'buildCanadaReport') {
            // TEMP run the generate function instead of the main build function
            // Uncomment & remove temp after AZ migration
            // this.buildReport(this.confirmBuildData);
            this.generateReport(this.confirmBuildData);
          }
        }
      }
    );
   }

  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(component: string, action: string, buildData: any, message: string): void {
    this.confirmAction = action;
    this.confirmBuildData = buildData;

    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }

  getTemplateList(): void {
    this._cmsService.getReportTemplates()
      .subscribe(
        CanadaReportTemplateItem => {
          this.templates = CanadaReportTemplateItem;
        },
        err => this.errorMessage = <any>err,
        () => console.log(this.templates)
    );
  }

  getDepartmentsDropdown(): void {
    this._cmsService.getCanadaDepartments('EN')
      .subscribe(
        data => {
          this.canadaDepts = data;
        },
        err => this.errorMessage = <any>err,
        () => console.log(this.canadaDepts)
      );
  }

  toggleDepartmentField(reportType): void {
    if (reportType === 'single'){
      this.showDeptField = true;
      this.getDepartmentsDropdown();
    }
    else {
      this.showDeptField = false;
    }
  }

    // Display Save Historical Data check box only when month difference is 6 (April 1st to September 30th) or 12 (April 1st to March 31st (the next year))
    public get DisplayHistoricalData(): boolean {
      const monthDifference = (moment(this.buildItem.EndDate).add(1, 'd').diff(moment(this.buildItem.StartDate), 'months', true)).toString();
      return (((monthDifference === '6') || (monthDifference === '12')) && moment(this.buildItem.StartDate).date() === 1 && (moment(this.buildItem.StartDate).month() + 1) === 4) ? true : false;
    }

  buildReport(buildItem): void {
    console.log(buildItem);

    // to allow for reports to be run with same dates as previous report (if new date is selected it will be a date object again)
    if (typeof(buildItem.StartDate) != 'string') {
      buildItem.StartDate = buildItem.StartDate.formatted.replace(/\//g, '-');
    }

    if (typeof (buildItem.EndDate) != 'string') {
      buildItem.EndDate = buildItem.EndDate.formatted.replace(/\//g, '-');
    }

    console.log(buildItem);

    // check that all fields are filled out before trying to create/update volume
    if (this._utilitiesService.isBlank([buildItem.StartDate, buildItem.EndDate, buildItem.TemplateName, buildItem.ReportType])) {
      this._snackbarService.error(this._emsConfig.text.EMS_Content.Complete_Fields_Error);
    } else {
      this._messagesService.buildCanadaReport(buildItem).subscribe(data => {
        console.log(data);
        if (data) {

          if (buildItem.ReportType === 'all') {
            this._snackbarService.success('Report generation request has been sent! You will receive an email once all reports finished building.');
          } else {
            this._snackbarService.success('Report/s successfully created!');
          }

          // reset the build item on successful report build
          this.clearFields();

        } else {
          this._snackbarService.error('Something went wrong! The report/s have not been generated. Please refresh and try again.');
        }
      });
    }
  }

  buildReportAZ(buildItem): void {
    console.log(buildItem);

    // to allow for reports to be run with same dates as previous report (if new date is selected it will be a date object again)
    if (typeof(buildItem.StartDate) != 'string') {
      buildItem.StartDate = buildItem.StartDate.formatted.replace(/\//g, '-');
    }

    if (typeof (buildItem.EndDate) != 'string') {
      buildItem.EndDate = buildItem.EndDate.formatted.replace(/\//g, '-');
    }

    if (this._utilitiesService.isBlank([buildItem.StartDate, buildItem.EndDate, buildItem.TemplateName, buildItem.ReportType])) {
      this._snackbarService.error(this._emsConfig.text.EMS_Content.Complete_Fields_Error);
    } else {
      this._reportingService.runCanadaReportBuilder(buildItem).subscribe(data => {
        if (data) {

          if (buildItem.ReportType === 'all') {
            this._snackbarService.success('Report generation request has been sent! You will receive an email once all reports finished building.');
          } else {
            this._snackbarService.success('Report/s successfully created!');
          }

          // reset the build item on successful report build
          this.clearFields();

        } else {
          this._snackbarService.error('Something went wrong! The report/s have not been generated. Please refresh and try again.');
        }
      });
    }
  }

  //TEMP switch function for building canada reports
  // Delete after AZ migration
  generateReport(buildItem) {
    if (this._emsConfig.infraLocation == 'azure') {
      this.buildReportAZ(buildItem);
    } else {
      this.buildReport(buildItem);
    }
  }

  clearFields(): void {
    this.buildItem.DeptNum = null;
    this.buildItem.ReportType = null;
    this.buildItem.TemplateName = null;
    this.showDeptField = false;
    console.log(this.buildItem);
  }

  ngOnInit(): void {
    this.getTemplateList();

    this.buildItem = new CanadaReportBuildItem;
  }

  ngOnDestroy() {
    this.confirmSubscription.unsubscribe();
  }
}


import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, JsonpInterceptor } from '@angular/common/http';
import { EmsConfig } from 'app/shared/emsConfig';
import { CampaignDetails } from 'app/cms/details/cms-campaign/campaign-details';
import { ClientCampaign } from 'app/accounts/details/account-campaign/clientCampaign';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class CampaignBulkBuildService {
    private _getCampaignForBulkUploadURL = this.emsConfig.apiEndpoint + '/campaign/getcampaignforbulkupload';
		private _getclientcampaignbulkuploadlistURL = this.emsConfig.apiEndpoint + '/campaign/getclientcampaignbulkuploadlist';
		private _insertUpdateClientCampaignForBulkUploadURL = this.emsConfig.apiEndpoint + '/campaign/insertupdateclientcampaignforbulkupload';
		private _deleteClientCampaignURL = this.emsConfig.apiEndpoint + '/campaign/deleteclientcampaign';
		private _bulkuploadclientcampaigndataURL = this.emsConfig.apiEndpoint + '/campaign/bulkuploadclientcampaigndata';

    constructor(private _httpClient: HttpClient,	private emsConfig: EmsConfig) { }

		getCampaignForBulkUpload(): Observable<any> {
			return this._httpClient.get<CampaignDetails>(this._getCampaignForBulkUploadURL);
		}

		getClientCampaignBulkUploadList(campaignID: number): Observable<any> {
			const params = new HttpParams()
			.set('campaignID', campaignID.toString());
			return this._httpClient.get<any>(this._getclientcampaignbulkuploadlistURL, { params });
		}

		insertUpdateClientCampaignForBulkUpload(clientCampaign: ClientCampaign): Observable<any> {
			const body = JSON.stringify(clientCampaign);
			return this._httpClient.post<ClientCampaign>(this._insertUpdateClientCampaignForBulkUploadURL, body);
		}

		deleteClientCampaign(ParticipatingID: number, forBulkUpload: boolean = true): Observable<any> {
			return this._httpClient.post<any>(this._deleteClientCampaignURL + '?ParticipatingID=' + ParticipatingID.toString() +  "&ForBulkUpload=" + forBulkUpload.toString(), "");
		}

		bulkuploadclientcampaigndata():Observable<any> {
			return this._httpClient.post<any>(this._bulkuploadclientcampaigndataURL, "");
		}
}

import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

// services
import { CmsService } from '../cms.service';
import { EmsConfig } from 'app/shared/emsConfig';
import { UtilitiesService } from 'app/shared/utilities.service';
import { ConfirmService } from 'app/shared/confirm.service';
import { PopupService } from 'app/shared/popup.service';
import { SafeHtml } from '../../shared/html-sanitize.pipe';

// classes
import { TipsheetItem } from '../tipsheetItem';
import { SimpleVolume } from 'app/shared/SimpleVolume';
import { CanadaReportTemplateItem } from '../canadaReportTemplateItem';

// third party
declare var tinymce: any;

@Component({
  selector: 'cms-canada-report-template-edit', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-canada-report-template-edit.component.html'
})

export class CmsCanadaReportTemplateEditComponent implements OnInit, OnDestroy, AfterViewInit {

  // variables for getting account id from url
  id: number;

  private sub: any;

  lang: string;

  errorMessage: string;

  template: CanadaReportTemplateItem;

  addNewTipsheet: boolean = false;
  createNew: boolean = false;

  // editor variables
  editor: any;
  activeEditor: any;
  templateEditorData: any = '';
  updatedTemplateEditorData: any;

  // confirm service variables
  confirmData: {};
  confirmTemplateData: any;
  confirmAction: string;
  confirmSubscription: Subscription;



  constructor(public _cmsService: CmsService, public _emsConfig: EmsConfig, public _utilitiesService: UtilitiesService, private router: Router, private route: ActivatedRoute, private _confirmService: ConfirmService, private _popupService: PopupService, private _location: Location) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'updateTemplate') {
            this.updateTemplate(this.confirmTemplateData);
          }
        }
      }
    );
   }

  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(component: string, action: string, volumeData: any, message: string): void {
    this.confirmAction = action;
    this.confirmTemplateData = volumeData;

    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }


  updateEditor(editorData): void {
    this.activeEditor = tinymce.get('template-area');
    if (this.activeEditor != null) {
      this.activeEditor.setContent(editorData);
    }
  }

  getTemplate(): void {
    this._cmsService.getReportTemplate(this.id).subscribe(data => {
      this.template = data;
      console.log(this.template);

      this.templateEditorData = this.template.TemplateContent;
      this.updateEditor(this.templateEditorData);
    });
  }


  getUpdatedContentFromEditor(): void {
    console.log(tinymce.activeEditor.getContent());
    return tinymce.activeEditor.getContent();
  }


  updateTemplate(template): void {
    console.log(template);

    if (template.ID === 0) {
      template.ID = -1;
      this.createNew = true;
    }

    this.updatedTemplateEditorData = this.getUpdatedContentFromEditor();
    template.TemplateContent = this.updatedTemplateEditorData;

    if (/\s/.test(template.TemplateTitle)) {
      this._popupService.updateNotification({ message: 'Template title must not contain spaces. Please replace spaces with dashes.', error: true });
    } else {
      console.log(template);
      this._cmsService.updateTemplate(template).subscribe(
        data => {
          console.log(data);
          if (this.createNew) {
            if (data.templateId === -1) {
              this._popupService.updateNotification({ message: 'Template not created. Please try again.', error: true });
            } else {
              this._popupService.updateNotification({ message: 'Template has been updated successfully.', success: true });
              this.id = data.templateId;
              // update the url with tipsheet id
              this._location.replaceState('/cms/canadareporttemplate/' + data.templateId);
              this.getTemplate();
            }
          } else {
            this.id = template.ID;
            if (!data.succeeded) {
              this._popupService.updateNotification({ message: 'Template not been created/updated. Please try again.', error: true });
            } else {
              this._popupService.updateNotification({ message: 'Template has been created/updated successfully.', success: true });
              this.getTemplate();
            }
          }
        },
        err => this.errorMessage = <any>err,
      );
    }

  }


  ngOnInit(): void {
    // gets the account id from url
    this.sub = this.route.params.subscribe(params => {
      this.id = Number(params['id']);

      console.log(this.id);
    });

    // get the template
    this.getTemplate();
  }

  ngAfterViewInit() {
    tinymce.init({
      selector: '#template-area',
      plugins: 'link table code lists preview template table charmap',
      toolbar:
        'code | undo redo | styles | fontsize | bullist numlist | bold italic underline | link unlink | alignleft aligncenter | template | table | charmap',
      font_size_formats: '13px 16px 18px 20px 24px 45px 56px',
      browser_spellcheck: true,
      entity_encoding: 'raw',
      min_height: 650,
      menubar: false,
      inline_styles: true,
      content_css: [
        'https://lifespeak.s3-us-west-2.amazonaws.com/Reports/canada-report-template-pdf-set.css',
        // 'https://lifespeak.s3-us-west-2.amazonaws.com/Reports/tinymce-report-table-styles.css'
      ],
      style_formats: [
        {
          title: 'Report Title',
          block: 'h1',
          classes: 'report-title'
        },
        {
          title: 'Header Date',
          block: 'p',
          classes: 'header-date'
        },
        {
          title: 'Department Name',
          block: 'p',
          classes: 'department-name'
        },
        {
          title: 'Table title',
          block: 'h2',
          classes: 'table-title'
        }
      ],
      charmap: [
        [0x00E7, 'ç'],
        [0x00E9, 'é'],
        [0x00E8, 'è'],
        [0x00EA, 'ê'],
        [0x00EB, 'ë'],
        [0x00E0, 'à'],
        [0x00E2, 'â'],
        [0x00EC, 'ì'],
        [0x00EE, 'î'],
        [0x00EF, 'ï'],
        [0x00F2, 'ò'],
        [0x00F4, 'ô'],
        [0x00F9, 'ù'],
        [0x00FB, 'û'],
        [0x00FC, 'ü'],
      ],
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    tinymce.remove(this.editor);
    this.confirmSubscription.unsubscribe();
  }

}

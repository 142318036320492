import { Component, HostBinding, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { EmsConfig } from '../../shared/emsConfig';
import { ConnexService } from '../connex.service';
import { SecuredRoutes } from 'app/shared/secured-routes';
import { PopupService } from '../../shared/popup.service';

// Objects
import { AteAnswer } from '../Objects/AteAnswer';
import { AteQuestionStatus } from '../Objects/AteQuestionStatus';

@Component({
  selector: 'ate-mod-trash',
  templateUrl: 'ate-mod-trash.component.html',
  providers: [ConnexService]
})
export class ATEModTrash {
  @HostBinding('class') classAttribute: string = 'ate-mod-trash';

  @Input() questions: AteAnswer[];
  @Input() langStr: string;

  @Output() moveQuestion = new EventEmitter();

  constructor(
    public _emsConfig: EmsConfig,
    public _securedRoutes: SecuredRoutes,
    private _connexService: ConnexService,
    private _popupService: PopupService
  ) {
  }

  restoreQuestion(question: AteAnswer) {
    question.Status = AteQuestionStatus.Unassigned;
    this._connexService.modifyATEQuestion(question)
      .subscribe(response => {
        if (response.QuestionID !== -1) {
          this.moveQuestion.emit(question);
        } else {
          question.Status = AteQuestionStatus.Rejected;
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
        }
      })
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { EmsConfig } from './shared/emsConfig';
import { LoginToken } from './shared/login-token';
import { SecuredRoutes } from './shared/secured-routes';

// Services
import { LoginService } from './home/login.service';
import { CampaignBulkBuildService } from './services/campaign-bulkbuild.service';

@Component({
  selector: 'ems-app',
  templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit {
  public isLoggedIn: boolean;
  protected validLogIn: boolean;
  protected showDropdownMenu: boolean;
  isBulkBuild: boolean = false;

  public helpURL: string = '';

  public powerBIDashboardEndpoint:string = this._emsConfig.powerBIDashboardEndpoint;

  subscription: Subscription;

  constructor(public _emsConfig: EmsConfig, private router: Router, private _loginService: LoginService,
    public _securedRoutes: SecuredRoutes, private _campaignBulkBuildService: CampaignBulkBuildService) {
    this.subscription = _loginService.isLoggedIn$.subscribe(
      data => {
        this.isLoggedIn = data;
        // run at a delay before checking bulk build so page has a chance to render and initialize before making the API request
        if (this.isLoggedIn) {
          setTimeout(() => {
            this.checkBulkBuildActive()
          }, 250);
        }
      });
  }

  // get the appropriate help guide based on user role
  getHelpDoc(): void {
    // if connexpert set the help URL to the Expert Connex Instruction PDF
    const helpDoc = this._emsConfig.UserRole === 'expert'
      ? 'https://lifespeak.s3-us-west-2.amazonaws.com/Connex/LifeSpeak%20Expert%20Connex%20-%20Expert%20Instructions.pdf'
      : 'https://docs.google.com/document/d/17I3vjviGPswNhcYvXSuSXlouPHCHUeiwYZzO-yEKGvw/edit?usp=sharing';
    window.open(helpDoc, '_blank');
  }

  showDropdown() {
    this.showDropdownMenu = true;
  }

  // Check if a campaign is set to bulk build status, if it is, we will show the "Add to campaign" tab in the header nav
  // Campaign bulk build is set per campaign in the CMS campaign section
  checkBulkBuildActive() {
    // only run the request to check once the user is logged in, and not on the EMS Expert portal
    if (this.isLoggedIn && !this._emsConfig.emsDomain.includes('expert')) {
      console.log('ems config', this._emsConfig);
      this._campaignBulkBuildService.getCampaignForBulkUpload()
        .subscribe(
          data => {
            // if ID !== -1, a campaign is set to bulk build and will show the "Add to Campaign" tab in the top nav
            if (data?.ID !== -1 && data !== null) {
              this.isBulkBuild = true;
            }
          });
    }
  }

  // this function is passed in the clickOutside directive - closes the dropdown menu when anywhere but the menu is clicked
  close() {
    this.showDropdownMenu = false;
  }

  logOut() {
    this._emsConfig.deleteLoginToken();
    this.router.navigateByUrl('/login');
    this.isLoggedIn = false;
    this.showDropdownMenu = false;
  }

  ngOnInit(): void {
    // ensure we are not on the login page
    if (this._emsConfig.loginToken != null) {

      //if request is for a changepassword then do nothing and let the page load.
      if ((window.location.href.indexOf(`/changepassword`) > -1)) {
        return;
      }

      // check if user is on latest build version, if not log them out

      if (this._emsConfig.buildVersion !== this._emsConfig.loginToken.BuildVersion) {
        this.logOut();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

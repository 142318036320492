<h2 mat-dialog-title>Custom questions</h2>
<mat-dialog-content class="mat-typography">
	
	<table>
		<thead>
              <tr>
				  <th>ID</th>
                <th>Name</th>
                <th>Template</th>
                <th>Actions</th>
              </tr>
            </thead>
		<tbody>
        
        <tr *ngFor="let template of asyncTemplates | async | paginate: { id: 'server', itemsPerPage: pageSize, currentPage: p, totalItems: total }">
			<td>{{ template.ID }}</td>
				<td>{{ template.Name }}</td>
				<td>
					<mat-form-field class="example-full-width">
    				<textarea matInput placeholder="Ex. Pizza" value="Sushi" [(ngModel)]="template.Template" ></textarea>
  					</mat-form-field>
				</td>
				<td><button type="button" class="" (click)="detectCategoriesCustomEdit(data.mainId, data.subId, data.categoryType, template)" [disabled]="networkCall">Ask Question</button> 
				<mat-checkbox matTooltip="For example it will send the category and sub-catgeories choices from the database. Look at the final question for results." class="example-margin" [(ngModel)]="template.SendParameters" >Send built-in parameters</mat-checkbox></td>
				<td><button type="button" class="" (click)="deleteTemplate(template)" [disabled]="networkCall">Delete</button> </td>
		 	</tr>
	
    </tbody>
	</table>

<div class="has-text-centered">
    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
    <pagination-controls (pageChange)="getPage($event)" id="server"></pagination-controls>
</div>
	
<button type="button" class="" (click)="addTemplate()" [disabled]="networkCall">New</button>	
    
</mat-dialog-content>
	
	
	
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmsConfig } from '.././shared/emsConfig';

// Classes
import { BlogPostItem } from './blog-post-item';
import { BlogCategoryItem } from './blog-category-item';
import { BlogTagItem } from './blog-tag-item';
import { BlogFeaturedPostItem } from './blog-featured-post-item';
import { PostSuppressionItem } from 'app/accounts/details/blog-post-suppression-item';

@Injectable()
export class BlogService {

  // URLS
  // ******POSTS METHODS********
  private _postListingsURL = this.emsConfig.apiEndpoint + '/blog/getblogpostlistings';
  private _simplePostListingsURL = this.emsConfig.apiEndpoint + '/blog/getsimpleblogpostlistings';
  private _postListingsForClientURL = this.emsConfig.apiEndpoint + '/blog/getpostlistingsforclient';
  private _featuredPostListingsURL = this.emsConfig.apiEndpoint + '/blog/getfeaturedposts';
  private _currentFeaturedPostListingsURL = this.emsConfig.apiEndpoint + '/blog/getcurrentfeaturedposts';
  private _postByIdURL = this.emsConfig.apiEndpoint + '/blog/getblogpost';
  private _postUpdateURL = this.emsConfig.apiEndpoint + '/blog/addupdateblogpost';
  private _deletePostURL = this.emsConfig.apiEndpoint + '/blog/deleteblogpost?id=';
  private _featuredPostsUpdateURL = this.emsConfig.apiEndpoint + '/blog/addupdatefeaturedposts';
  private _deleteFeaturedPostsURL = this.emsConfig.apiEndpoint + '/blog/deletefeaturedpostgroup?groupId=';
  private _matchedPostURL = this.emsConfig.apiEndpoint + '/blog/getmatchedpost';
  private _matchedPostForSharelinkURL = this.emsConfig.apiEndpoint + '/blog/getmatchedpostsforsharelink';

  // ******CATEGORY METHODS********
  private _categoryListingsURL = this.emsConfig.apiEndpoint + '/blog/getblogcategories';
  private _categoryUpdateURL = this.emsConfig.apiEndpoint + '/blog/addupdateblogcategory';
  private _categoryByIdURL = this.emsConfig.apiEndpoint + '/blog/getblogcategory';

  // ******TAG METHODS********
  private _tagListingsURL = this.emsConfig.apiEndpoint + '/blog/getblogtags';
  private _tagUpdateURL = this.emsConfig.apiEndpoint + '/blog/addupdateblogtag';
  private _tagByIdURL = this.emsConfig.apiEndpoint + '/blog/getblogtag';

  //  *******Blog Suppression URLS*******
  private _getSuppressedPostsURL = this.emsConfig.apiEndpoint + '/blog/getpostsuppressionbyclient';
  private _getEligiblePostsURL = this.emsConfig.apiEndpoint + '/blog/geteligiblepostsforsuppression';
  private _addSuppressedPostURL = this.emsConfig.apiEndpoint + '/blog/addpostsuppression';
  private _removeSuppressedPostURL = this.emsConfig.apiEndpoint + '/blog/removepostsuppression';

  private get cacheBust(): string {
    // get list of additional categories by volume ID -- add a cachebuster to retrieve make a fresh request
    const cacheBuster: number = Math.floor(Math.random() * 500000 + 1);
    return cacheBuster.toString();
  }

  constructor(private _httpClient: HttpClient, private emsConfig: EmsConfig) { }

  // ==============
  // Posts Methods
  // ==============
  // get list of blog posts
  getBlogPosts(lang: string, getProdBlogs: boolean = false, pastPosts = false, sort = 'publishDate'): Observable<BlogPostItem[]> {
    const params = new HttpParams()
      .set('postLang', lang)
      .set('getProdBlogs',getProdBlogs.toString())
      .set('pastPosts', pastPosts.toString())
      .set('sort', sort);
    return this._httpClient.get<BlogPostItem[]>(this._postListingsURL, {params: params});
  }

  // get list of simple blog posts
  getSimpleBlogPosts(lang: string, isPublished: boolean): Observable<BlogPostItem[]> {
    const params = new HttpParams()
      .set('postLang', lang)
      .set('isPublished', isPublished.toString());
    return this._httpClient.get<BlogPostItem[]>(this._simplePostListingsURL, { params: params });
  }

  // get list of simple blog posts by client
  getBlogPostsByClient(lang: string, clientName: string, fromProd: boolean = false): Observable<BlogPostItem[]> {
    const params = new HttpParams()
      .set('postLang', lang)
      .set('clientname', clientName)
      .set('fromProd', fromProd.toString());
    return this._httpClient.get<BlogPostItem[]>(this._postListingsForClientURL, { params: params });
  }

  // get list of featured blog posts
  getFeaturedBlogPosts(lang: string, groupId: string): Observable<BlogFeaturedPostItem[]> {
    const params = new HttpParams()
      .set('postLang', lang)
      .set('groupId', groupId);
    return this._httpClient.get<BlogFeaturedPostItem[]>(this._featuredPostListingsURL, { params: params });
  }

  // get list of featured blog posts
  getCurrentFeaturedBlogPosts(lang: string): Observable<BlogFeaturedPostItem[]> {
    const params = new HttpParams()
      .set('postLang', lang);
    return this._httpClient.get<BlogFeaturedPostItem[]>(this._currentFeaturedPostListingsURL, { params: params });
  }

  getBlogPost(id: number): Observable<BlogPostItem> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('_cb', this.cacheBust);
    return this._httpClient.get<BlogPostItem>(this._postByIdURL, {
      params: params
    });
  }

  getMatchedPostID(lang: string, postId: number): Observable<any> {
    const params = new HttpParams()
      .set('postLang', lang)
      .set('postId', postId.toString());
    return this._httpClient.get<any>(this._matchedPostURL, {
      params: params
    });
  }
  getMatchedBlogsForSharelinks(client:string): Observable<any> {
    const params = new HttpParams()
      .set('client', client);
    return this._httpClient.get<any>(this._matchedPostForSharelinkURL, {
      params: params
    });
  }

  updatePost(post: BlogPostItem): Observable<any> {
    const body = JSON.stringify(post);
    return this._httpClient.post<any>(this._postUpdateURL, body);
  }

  updateFeaturedPosts(posts: BlogFeaturedPostItem[]): Observable<any> {
    const body = JSON.stringify(posts);
    return this._httpClient.post<any>(this._featuredPostsUpdateURL, body);
  }

  deleteFeaturedPostGroup(groupId: string): Observable<any> {
    return this._httpClient.post<any>(this._deleteFeaturedPostsURL + groupId, '');
  }

  deletePost(id: number, lang: string): Observable<any> {
    return this._httpClient.post<any>(this._deletePostURL + id + '&postLang=' + lang, '');
  }

  // =================
  // Category Methods
  // =================
  // get list of blog categories
  getBlogCategories(): Observable<BlogCategoryItem[]> {
    return this._httpClient.get<BlogCategoryItem[]>(this._categoryListingsURL);
  }

  getBlogCategory(id: number): Observable<BlogCategoryItem> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('_cb', this.cacheBust);
    return this._httpClient.get<BlogCategoryItem>(this._categoryByIdURL, {
      params: params
    });
  }

  updateCategory(category: BlogCategoryItem): Observable<any> {
    const body = JSON.stringify(category);
    return this._httpClient.post<any>(this._categoryUpdateURL, body);
  }

  // ============
  // Tag Methods
  // ============
  // get list of blog tags
  getBlogTags(): Observable<BlogTagItem[]> {
    return this._httpClient.get<BlogCategoryItem[]>(this._tagListingsURL);
  }

  getBlogTag(id: number): Observable<BlogTagItem> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('_cb', this.cacheBust);
    return this._httpClient.get<BlogTagItem>(this._tagByIdURL, {
      params: params
    });
  }

  updateTag(category: BlogTagItem): Observable<any> {
    const body = JSON.stringify(category);
    return this._httpClient.post<any>(this._tagUpdateURL, body);
  }

  // *******Post Suppression METHODS********
  getPostSuppressionsByOrg(username: string, lang: string, getProdBlogs: boolean=false) {
    const params = new HttpParams()
      .set('username', username)
      .set('language', lang)
      .set('getProdBlogs',getProdBlogs.toString());
    return this._httpClient.get<PostSuppressionItem[]>(this._getSuppressedPostsURL, { params: params });
  }

  getEligiblePostsForSuppression(lang: string) {
    const params = new HttpParams()
      .set('language', lang);
    return this._httpClient.get<BlogPostItem[]>(this._getEligiblePostsURL, { params: params });
  }

  addPostSuppression(posts: any[]): Observable<PostSuppressionItem> {
    const body = JSON.stringify(posts);
    return this._httpClient.post<any>(this._addSuppressedPostURL, body);
  }

  removePostSuppression(posts: any[]): Observable<PostSuppressionItem> {
    const body = JSON.stringify(posts);
    return this._httpClient.post<any>(this._removeSuppressedPostURL, body);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmsConfig } from '.././shared/emsConfig';
import { AnalyticsQuery } from '.././shared/analytics-query';
import { DashboardItem } from './dashboard-item';


@Injectable()
export class DashboardService {

  private _dashboardReportUrl = this._emsConfig.apiEndpoint + '/analytics/getdashboardreport';

  constructor(private _httpClient: HttpClient, private _emsConfig: EmsConfig) { }

  getDashboard(query: AnalyticsQuery): Observable<DashboardItem[]> {
    const body = JSON.stringify(query);
    return this._httpClient.post<any>(this._dashboardReportUrl, body);
  }

}

<div class="basic-info video-participation-container">
  <ng-container>
    <div class="container-title">
      <h4 class="basic-info__heading setting__heading ls-subheading">
        <span class="svg-icon svg-baseline">
          <svg fill="#BBBBBB" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M12,8A3,3 0 0,0 15,5A3,3 0 0,0 12,2A3,3 0 0,0 9,5A3,3 0 0,0 12,8M12,11.54C9.64,9.35 6.5,8 3,8V19C6.5,19 9.64,20.35 12,22.54C14.36,20.35 17.5,19 21,19V8C17.5,8 14.36,9.35 12,11.54Z" />
          </svg>
        </span>
        Total Library Count
      </h4>
    </div>
    <div class="total-count">
      <span>{{clientLibTotal}}</span><span class="client-list-btn" role="button" (click)="showClientList()">Client List</span>
    </div>
  </ng-container>
  <ng-container>
    <div class="container-title">
      <h4 class="basic-info__heading setting__heading ls-subheading">
        <span class="svg-icon svg-baseline">
          <svg fill="#BBBBBB" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M3 10H14V12H3V10M3 6H14V8H3V6M3 14H10V16H3V14M16 13V21L22 17L16 13Z" />
          </svg>
        </span>
        CPD
      </h4>
    </div>
    <div class="no-content" *ngIf="cpds?.length==0">
      Content is not present in any CPD section
    </div>
    <table *ngIf="cpds?.length>0" class="display-table">
      <thead>
        <tr>
          <th class="table-title">Title</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cpd of cpds">
          <td class="table-title"> {{cpd.Title}}</td>
          <td class="path-container">
            <a [routerLink]="['/cms/cpd', cpd.NavParam[0],cpd.NavParam[1],cpd.NavParam[2]]">
              <span>Edit
                <svg class="right-arrow" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z">
                  </path>
                </svg>
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-container>
    <div class="container-title">
      <h4 class="basic-info__heading setting__heading ls-subheading">
        <span class="svg-icon svg-baseline">
          <svg fill="#BBBBBB" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M3 10H14V12H3V10M3 6H14V8H3V6M3 14H10V16H3V14M16 13V21L22 17L16 13Z" />
          </svg>
        </span>
        Learning/Training Hub Playlists
      </h4>
    </div>
    <div class="no-content" *ngIf="playlists?.length==0">
      Content is not present in any Learning/Training Hub Playlists
    </div>
    <table *ngIf="playlists?.length>0" class="display-table">
      <thead>
        <tr>
          <th class="table-title">Title</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let playlist of playlists">
          <td class="table-title"> {{playlist.Title}}</td>
          <td class="path-container">
            <a [routerLink]="['/cms/learninghub', playlist.NavKey]">
              <span>Edit
                <svg class="right-arrow" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z">
                  </path>
                </svg>
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-container>
    <div class="container-title">
      <h4 class="basic-info__heading setting__heading ls-subheading">
        <span class="svg-icon svg-baseline">
          <svg fill="#BBBBBB" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M3 10H14V12H3V10M3 6H14V8H3V6M3 14H10V16H3V14M16 13V21L22 17L16 13Z" />
          </svg>
        </span>
        Campaign
      </h4>
    </div>
    <div class="no-content" *ngIf="campaigns?.length==0">
      Content is not present in any campaign
    </div>
    <table *ngIf="campaigns?.length>0" class="display-table">
      <thead>
        <tr>
          <th class="table-title">Title</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let campaign of campaigns">
          <td class="table-title"> {{campaign.Title}}</td>
          <!-- /cms/cpd/accountant/BC/EN -->
          <td class="path-container">
            <a [routerLink]="['/cms/campaigns', campaign.NavKey]">
              <span>Edit
                <svg class="right-arrow" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z">
                  </path>
                </svg>
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <div class="client-list-modal modal-window" *ngIf="showClientListModal" (click)="showClientListModal = false">
    <div class="modal-container" (click)="stopProp($event)">
      <div class="modal__title-container b-dark-blue">
        <h3 class="modal__title ls-headline f-white">Clients with Volume</h3>
        <button class="modal__close" (click)="showClientListModal = false">
          <svg viewBox="0 0 24 24">
            <path fill="#ffffff"
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>
      <div class="modal__content">
        <div class="client-container">
          <div class="participating-client" *ngFor="let client of clientList">
            <div>{{client}}</div>
          </div>
        </div>

        <div class="align-btn-right">
          <button class="btn ls-button-1" (click)="copyClientList()">
            <svg  class="copy-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
              <path fill="#ffffff" d="M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24 17v-21h21v2h-19v19h-2z" />
            </svg>
            Copy list</button>
        </div>
      </div>
    </div>
  </div>
</div>

// Angular Things
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

// Services
import { CmsService } from '../cms.service';
import { UtilitiesService } from '../../shared/utilities.service';
import { ConfirmService } from '../../shared/confirm.service';
import { SnackbarService } from 'app/services/Snackbar.service';
import { SpeechRecognitionService } from 'app/services/speechRecognition.service';

// Classes
import { ModuleItem } from '../../shared/ModuleItem';
import { EmsConfig } from '../../shared/emsConfig';
import { AiPromptType } from '../../shared/objects/aiPrompts';

// Third-Party Things
import { FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { SubCategory } from 'app/shared/SubCategory';
import { CategoriesService } from 'app/categories/categories.service';

//UI dialogs for AI
import {MatDialog, } from '@angular/material/dialog';
import {DialogContentExampleDialog} from '../../ai/CustomAITemplate.component';
import {DialogGenericAnswerContentDialog} from '../../ai/AIAnswer.component';

declare var tinymce: any;

@Component({
  selector: 'cms-module-details', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-module-details.component.html',
  providers: [Location]
})

export class CmsModuleDetailComponent implements OnInit, OnDestroy {
  // adds class to parent element (element named using the selector in the component decorator)
  // @HostBinding('class.cmsVolumeDetail') cmsVolumeDetail = true;

  // variables for getting account id from url
  id: number;
  volumeIdFromUrl: number;

  moduleCreateFromVolume: boolean = false;

  private sub: any;

  errorMessage: string;

  addNewModule: boolean = false;
  moduleDetails: ModuleItem;
  newModuleID: any;
  tags: any[] = [];

  //for subcategory list
  subCategories: SubCategory[] = [];
  videoSubcategoryIDs: any = {};
  categoryName:string;
  
  simpleVolumes: any;

  confirmData: {};
  confirmModuleData: any;
  confirmAction: string;
  confirmSubscription: Subscription;

  volumeAddModule: boolean = false;
  videoLocation: string='';

  // image stuff
  imgPreviewUrl: string;
  showImgPreview: boolean = false;
  imageToUpload: string;
  resendImage: boolean = false;
  imageLoaded: boolean;

  videoPreviewLink: string;
  showPreviewLink: boolean = false;

  tagBroadcast: Subject<any> = new Subject();
  networkCall: boolean=false;
  enableAI: boolean = this._emsConfig.enableAI;
  //AI
  requestId: string;
  lastRequestresponse: string;
  aiPromptType = AiPromptType;

  // image upload stuff
  showImageDropzone: boolean;
  
  private CATEGORY_VOLUME_MODULE = 'volume';
  
  public moduleImageUploader: FileUploader = new FileUploader({ url: this._emsConfig.apiEndpoint + '/assetAdmin/uploadmoduleimage' });
  public imageHasBaseDropZoneOver: boolean = false;

  public get AmazonIsUploadedString(): string {
    return this.moduleDetails && this.moduleDetails.AmazonIsUploaded ? 'Amazon Video Uploaded' : 'Amazon video not uploaded';
  }

  public get BrightcoveIsUploadedString(): string {
    return this.moduleDetails && this.moduleDetails.BrightCoveID != '-1' ? 'Video is in brightcove with id ' + this.moduleDetails.BrightCoveID : 'Brightcove Video Not Uploaded';
  }

  public clipLanguage: string='';
  public moduleDataIsLoaded:boolean;

  public presignedUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private _cmsService: CmsService,
    private _utilitiesService: UtilitiesService,
    private _confirmService: ConfirmService,
    public _emsConfig: EmsConfig,
    private _snackBar: SnackbarService,
    private _categories: CategoriesService,
    public _speechRecognitionService: SpeechRecognitionService,
    public dialog: MatDialog
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'updateModule') {
            this.moduleSubmitForm(this.confirmModuleData);
          }
        }
      }
    );
  }

  onEvent(event: any) {
    event.stopPropagation();
  }

  getSimpleVolumes(): void {
    // second param is onPoint (true or false)
    this._cmsService.getSimpleVolumes(null, false)
      .subscribe(
        SimpleVolume => {
          this.simpleVolumes = SimpleVolume;
        },
        err => this.errorMessage = <any>err,
      );
  }

  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(component: string, action: string, moduleData: any, message: string): void {
    this.confirmAction = action;
    this.confirmModuleData = moduleData;

    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }

  getModuleDetails(): void {
    this._cmsService.getModuleDetails(this.id)
      .subscribe(
        data => {
          console.log(data);
          this.moduleDetails = data.media;
          if (this.id === -1) {
            this.addNewModule = true;
            this.moduleDetails.ID = 'N/A';
            this.moduleDetails.AllowLinkedClipImage = false; // will no longerbe used so is not shown on front end
            data.media.Transcripts = '';
            // this.moduleDetails.SortOrder = '';
            if (this.moduleCreateFromVolume) {
              this.moduleDetails.VolumeID = this.volumeIdFromUrl;
            }

          } else {
           
            // convert the string into an array & set video subcategories to true
            for (let i = 0; i < this.moduleDetails.VideoSubcategories?.split(',').map(Number).length; i++) {
              this.videoSubcategoryIDs[this.moduleDetails.VideoSubcategories?.split(',').map(Number)[i]] = true;
            }

          // get the status of the parent volume
          this.getVolumeStatus(this.moduleDetails.VolumeID);
            try {
              this.buildTagArray(JSON.parse(this.moduleDetails.Tags));
            }
            catch {
              console.log('Ne existing tags detected. Creating empty tag list');
              this.buildTagArray([]);
            }
            //show preview link for existing module
            this.showPreviewLink = true;            
            this.videoPreviewLink = `${this._emsConfig.assetPreviewLink}/video/${this.moduleDetails.AssetKey}`;
          }

          // check if an image has been uploaded or if it's in queue
          this.checkImageStatus();

          // send transcript to tiny editor
          if (data.media.Transcripts === undefined) {
            data.media.Transcripts = '';
          }

          tinymce.get('resource-text-area').setContent(data.media.Transcripts);
        },
        err => this.errorMessage = <any>err,
      );
  }

  getVolumeStatus(volumeId): void {
    this._cmsService.getEmsVolume(volumeId, false)
      .subscribe(
        VolumeDetailItem => {
          this.moduleDetails.VolumeStatus = VolumeDetailItem.volume.EMSStatus;
          this.clipLanguage= VolumeDetailItem.volume.Language;
          this.moduleDataIsLoaded=true;
          // get the subcategories by video category
          this.getAllSubcategoriesByCategory(VolumeDetailItem.volume.CategoryID);
          // get the video file path in cloudberry
          this.videoLocation=`VideosLowRes/${VolumeDetailItem.volume.LibraryItemName}/${this.moduleDetails.VideoUrl}.mp4`;
          this._cmsService.getAWSPresignedUrl(this.videoLocation).subscribe(url => { this.presignedUrl = url }); // get full presigned url to generate AI transcript
        });
  }

  // get the subcategory list by video category ID
  getAllSubcategoriesByCategory(id:number):void{
    this._cmsService.getSubCategoriesByCategoryId(id)
      .subscribe(
        data => {
          this.subCategories = data;
          this.categoryName= this._categories.getCategoryName(id);
        },
        err => {
          this._snackBar.error();
          return;
        });
  }
  
  goToVolume(volumeID): void {
    this.router.navigate(['/cms/volumes', volumeID]);
  }

  requestAmazonReupload() {
    this._cmsService.reuploadAmazonModule(this.moduleDetails.ID).subscribe(result => {

      this.moduleDetails.AmazonIsUploaded = false;
    },
      () => {
        window.alert('An error has occured - please try again later.');
      });
  }

  updateVideoContent() {

    this._cmsService.getBrightcoveID(this.moduleDetails.VideoUrl).subscribe(data => {

      if (data.id === '-1') {

        alert(`Video with name ${this.moduleDetails.VideoUrl} could not be found in brightcove library`);
      }
      else {
        this.moduleDetails.BrightCoveID = data.id;

        // if this a new module. reqiu
        if (this.moduleDetails.ID !== 'N/A') {
          this.requestAmazonReupload();
        }
      }

    },
      () => {
        window.alert('An error has occured - please try again later.');
      });


  }

  // adds the updated subcategory IDs with the moduledetails object
  updateVideoSubcategories(){
    this.moduleDetails.VideoSubcategories = '';
    var result = new Array();
    for (let i = 0; i < this.subCategories.length; i++) {
        // if the subcategory is marked true, then it will be to added to the array
      if(this.videoSubcategoryIDs[this.subCategories[i].ID])
      result.push(this.subCategories[i].ID);
    }
    this.moduleDetails.VideoSubcategories=result.join(",");
  }

  moduleSubmitForm(moduleDetails): void {
    this.updateVideoSubcategories();
    console.log(moduleDetails);
    //process tags for upload
    if (this.tags.length > 0) {
      const selectedTags = this.tags.filter(e => e.Keyword_Token !== '-').map(e => e.Keyword_Token).join(',');
      moduleDetails.Tags = selectedTags;

    }


    // check that all fields are filled out before trying to create/update volume
    if (this._utilitiesService.isBlank([moduleDetails.Title, moduleDetails.VideoUrl]) || moduleDetails.VolumeID === -1 || moduleDetails.SortOrder === 0) {
      this._snackBar.error(this._emsConfig.text.EMS_Content.Complete_Fields_Error);
    } else {

      if (moduleDetails.ID === 'N/A') {
        moduleDetails.ID = -1;
      }

      moduleDetails.AllowLinkedClipImage = this.moduleDetails.AllowLinkedClipImage; // this field is not included on front end anymore
      moduleDetails.SortOrder = Number(moduleDetails.SortOrder);
      moduleDetails.Transcripts = this.unEntity(tinymce.get('resource-text-area').getContent());

      console.log('in the submit function');
      console.log(moduleDetails);
      this._cmsService.addUpdateModule(moduleDetails)
        .subscribe(
          data => {
            console.log(data);

            if (data !== -1) {
              // check for create or update
              if (this.id === -1) {

                // this is to updated the url with the new volume id
                this.newModuleID = data;

                this.location.go('/cms/modules/' + this.newModuleID);

                this.id = this.newModuleID;

              } else {
                console.log('updated this volume - ' + data.mediaClipId);
              }

              this.getModuleDetails();

              this._snackBar.success(this._emsConfig.text.EMS_General.Popup_Success);
            } else {
              this._snackBar.error(this._emsConfig.text.EMS_General.Popup_Error);
            }
          },
          err => this.errorMessage = <any>err,
        );
    }
  }

  addModuleFromVolume(): void {
    this.volumeAddModule = true;
    this.id = -1;
  }

  // ===============
  // image upload
  // ===============
  public fileOverBaseImage(e: any): void {
    this.imageHasBaseDropZoneOver = e;
    this.validateFileTypeImage();
    // by default, the file uploader plugin requires credentials for submission, this remove them
    this.moduleImageUploader.queue.forEach(file => file.withCredentials = false);
  }

  // make sure the current item is a jpg file. Otherwise remove from the queue
  private validateFileTypeImage(): void {
    const currentItem = this.moduleImageUploader.queue[0];
    console.log(currentItem);
    if (currentItem !== undefined && currentItem.file.name.indexOf('.jpg') === -1) {
      alert('Only JPG files are permitted for Image upload');
      this.moduleImageUploader.queue.pop();
      this._emsConfig.getFileUploadHeaders();
    } else if (currentItem !== undefined) {
      // renames file to match the video url/image url
      currentItem.file.name = this.moduleDetails.ImageUrl + '.jpg';
      this.imageToUpload = currentItem.file.name;
    }
  }

  checkImageStatus(): void {
    // urls for queue and upload folders
    const moduleImageUrl = this._emsConfig.assetPath + '/ImageAssets/originals/videos/' + this.moduleDetails.ImageUrl + '.jpg';
    // check if resized and uploaded
    this.imageExists(moduleImageUrl);
  }


  resetVideoSettings() {
    this.moduleDetails.BrightCoveID = '-1';
    this.moduleDetails.AmazonIsUploaded = false;

  }


  clearModuleImage(): void {
    this.showImageDropzone = true;
    this.imageLoaded = false;
  }

  removeImageFromAWS(imageName): void {

    let imgFullUrl = '';

    // use the correct image preview path
    if (this.imageLoaded) {
      imgFullUrl = 'ImageAssets/originals/videos/' + this.moduleDetails.ImageUrl + '.jpg';
    } else {
      imgFullUrl = '';
    }
    this._cmsService.removeAwsProcessedFileFromAWS(imgFullUrl)
      .subscribe(
        data => {
          console.log(data);
          if (data.succeeded) {
            this._snackBar.success("Image successfully removed from AWS.");
            this.resendImage = false;
            this.checkImageStatus();
          } else {
            this._snackBar.error("Image was not removed from AWS. Please try again.");
            this.resendImage = true;
          }
        },
        err => this.errorMessage = <any>err,
      );
  }

  imageExists(url): void {
    const img = new Image();
    // set the image loaded variable to either show or hide image upload dropzone
    img.onload = () => {
      if (url.toLowerCase().indexOf('/originals') !== -1) {
        this.imageLoaded = true;
        console.log('imageLoaded is ' + this.imageLoaded);
      }
    };
    img.onerror = () => {
      if (url.toLowerCase().indexOf('/originals') !== -1) {
        this.imageLoaded = false;
        console.log('imageLoaded is ' + this.imageLoaded);
      }
    };

    img.src = url;
  }

  previewModuleImage(): void {
    let imgUrl = '';

    // use the correct image preview path
    if (this.imageLoaded) {
      imgUrl = this._emsConfig.assetPath + '/ImageAssets/originals/videos/' + this.moduleDetails.ImageUrl + '.jpg';
    } else {
      imgUrl = '';
    }

    this.imgPreviewUrl = imgUrl;
    console.log(this.imgPreviewUrl);
    this.showImgPreview = true;
  }

  closePreviewModal(): void {
    this.showImgPreview = false;
  }

  updateImageUrl($event): void {
    console.log($event);
  }

  uploadingGifAdd(): void {
    const container = document.querySelector('body');
    container.classList.add('request-in-progress');
  }

  uploadingGifRemove(): void {
    const container = document.querySelector('body');
    container.classList.remove('request-in-progress');
  }


  // OLD AWS AI CODE
	//Detect keywords:
	// detectKeywords(volumeId: number, moduleId: number): void {
	// 	this.networkCall = true;
	// 	var rString = this._utilitiesService.randomString(32);

	// 	this._cmsService.extractTags(volumeId, this.CATEGORY_VOLUME_MODULE, moduleId, rString)
	// 		.subscribe(
	// 			data => {
	// 				this.requestId = rString;
	// 				this.lastRequestresponse = data.value;


	// 				this.networkCall = false;
	// 				if (data.succeeded === false) {
	// 					this._snackBar.error('Categories not extracted.');
	// 					this._snackBar.error(data.error);
	// 					this._snackBar.error(data.errorMessage);
	// 				} else {
	// 					this._snackBar.success('Categories extracted.');
	// 					console.info('Received:');
	// 					console.info(data.value);

  //           var myWidth = window.innerWidth + 'px';

	// 					const dialogRef = this.dialog.open(DialogGenericAnswerContentDialog, {
	// 						width: myWidth,
	// 		        height: '700px',
	// 						data: {
	// 							question: data.value.question,
	// 							answer: data.value.answer
	// 						},
	// 					});

	// 					dialogRef.afterClosed().subscribe(result => {
	// 						console.log(`Dialog result: ${result}`);
	// 					});
	// 				}
	// 			},
	// 			err => {
	// 				this.errorMessage = <any>err;
	// 				this.networkCall = false;
	// 			},
	// 		);
	// }

	//Detect categories:
	detectCategories(volumeId: number, moduleId: number): void {
		this.networkCall = true;
		var onlySubcategories = true;

		var rString = this._utilitiesService.randomString(32);

		this._cmsService.extractCategories(volumeId, onlySubcategories, this.CATEGORY_VOLUME_MODULE, moduleId, rString)
			.subscribe(
				data => {
					this.networkCall = false;
					this.requestId = rString;
					this.lastRequestresponse = data.value;

					if (data.succeeded === false) {
						this._snackBar.error('Categories not extracted.');
						this._snackBar.error(data.error);
						this._snackBar.error(data.errorMessage);
					} else {
						this._snackBar.success('Categories extracted.');

						console.info('Received:');
						console.info(data.value);
						var myWidth = window.innerWidth + 'px';
						const dialogRef = this.dialog.open(DialogGenericAnswerContentDialog, {
							width: myWidth,
			        height: '700px',
							data: {
								question: data.value.Question,
								answer: data.value.Answer
							},
						});

						dialogRef.afterClosed().subscribe(result => {
							console.log(`Dialog result: ${result}`);
						});
					}
				},
				err => {
					this.errorMessage = <any>err;
					this.networkCall = false;
				},
			);
	}

  // OLD AWS AI CODE
	// detectTagsCustom(volumeId: number, moduleId: number): void {
	// 	const category = 'MODULE_EXTRACT_KEYWORDS';
	// 	const templateLanguage = 'EN';
	// 	this.extractTemplate(volumeId, moduleId, category, templateLanguage);
	// }

	// detectCategoriesCustom(volumeId: number, moduleId: number): void {
	// 	const category = 'MODULE_EXTRACT_SUBCATEGORIES';
	// 	const templateLanguage = 'EN';
	// 	this.extractTemplate(volumeId, moduleId, category, templateLanguage);
	// }

	extractTemplate(volumeId: number, moduleId: number, category: string, templateLanguage: string): void {

		var myWidth = window.innerWidth + 'px';

		const dialogRef = this.dialog.open(DialogContentExampleDialog, {
			width: myWidth,
			height: '700px',
			data: {
				templates: [],
				mainId: volumeId,
				subId: moduleId,
				category: category,
				templateLanguage: templateLanguage,
				categoryType: this.CATEGORY_VOLUME_MODULE
			},
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`);
		});
	}

  ngOnInit(): void {
    // gets the account id from url
    this.sub = this.route.params.subscribe(params => {
      this.id = parseInt(params['id']);
      this.volumeIdFromUrl = Number(params['volumeId']);
      if (!isNaN(this.volumeIdFromUrl)) {
        this.moduleCreateFromVolume = true;
      }
    });

    // gets module details
    this.getModuleDetails();
    this.getSimpleVolumes();
    this.moduleImageUploader.options.headers = this._emsConfig.getFileUploadHeaders();

    // gets the response after the upload is completed
    this.moduleImageUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const responsePath = JSON.parse(response);
      if (!item.isSuccess) {
        this._snackBar.error("Image has not been uploaded. Please try again.");
        this.uploadingGifRemove();
      } else {
        this._snackBar.success("Image successfully uploaded.");
        this.uploadingGifRemove();
      }
    };

  }

    // if the tag has changed in child component, find the index of that tag, and update the token and the keyword
  public updateTag($event) {
    console.log($event);
    this.tagBroadcast.next($event);
    this.tags[$event.index].Keyword_Token = $event.keyword.Keyword_Token;
    this.tags[$event.index].Keyword = $event.keyword.Keyword;

  }

  private buildTagArray(currentTags: any[]) {
    // create an empty array to store tags
    this.tags = [];

    // add a record for each of the 4 tags. If there are records missing, add an empty tag
    for (let i = 0; i < 4; i++) {

      let currentTag = i <= (currentTags.length - 1) ? { Index: i, ...currentTags[i] } : { Keyword_Token: '-', 'Keyword': 'Not Selected', Index: i };
      this.tags.push(currentTag);
    }

  }

  // =======================================================
  // characters: &,>,< will be stored in non-entity form
  // =======================================================
  unEntity(text: string) {
    text = text.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    return text;
  }

  ngAfterViewInit() {
    tinymce.init({
      selector: '#resource-text-area',
      plugins: 'link code lists preview',
      toolbar:
        'code | undo redo | styles fontsize | forecolor | bullist numlist | bold italic underline | link unlink | alignleft aligncenter',
      font_size_formats: '10px 12px 14px 16px 18px 20px 24px',
      browser_spellcheck: true,
      min_height: 400,
      menubar: false,
      inline_styles: true,
      content_style: 'body {font-size: 14px;}',
      color_map: [
        '000000', 'Black',
        '666666', 'Gray',
      ],
      custom_colors: false,
      formats: {
        heading: { block: 'h3', styles: { fontWeight: '700', fontSize: '14px', lineHeight: '1.2', letterSpacing: '0.09px' }},
        underline: {inline: 'u'} // need this because they regular underline setting uses a style attribute which we strip out
      },
      style_formats: [
        { title: 'Heading', format: 'heading'}
      ]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.confirmSubscription.unsubscribe();
    tinymce.remove(tinymce.get('resource-text-area'));
  }

}

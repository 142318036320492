import { Component, OnInit, OnDestroy } from '@angular/core';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';

//  Services
import { CmsService } from '../cms.service';
import { UtilitiesService } from '../../shared/utilities.service';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { CanadaReportTemplateItem } from '../canadaReportTemplateItem';

declare var tinymce: any;

@Component({
  selector: 'cms-canada-report-template-list', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-canada-report-template-list.component.html',
})

export class CmsCanadaReportTemplateListComponent implements OnInit, OnDestroy {

  templates: CanadaReportTemplateItem[] = [];
  errorMessage: string;

  lang: string = 'EN';

  constructor( public _cmsService: CmsService, public _emsConfig: EmsConfig, public _utilitiesService: UtilitiesService, private router: Router, private route: ActivatedRoute) { }

  getAllTemplates(): void {
    this._cmsService.getReportTemplates()
      .subscribe(
        CanadaReportTemplateItem => {
          this.templates = CanadaReportTemplateItem;
        },
        err => this.errorMessage = <any>err,
        () => console.log(this.templates )
      );
  }

  // Navigates to template edit
  onSelect(id) {
    // Navigate with relative link
    if (this.router.url.includes('canadareports/template')) {
      this.router.navigate([id], { relativeTo: this.route });
    } else {
      // if coming directly from cms default route (need to add volumes to url)
      this.router.navigate(['canadareports/template', id], { relativeTo: this.route });
    }
  }


  createNewTemplate(): void {
    const id = -1;
    // Navigate with relative link
    if (this.router.url.includes('canadareports/template')) {
      this.router.navigate([id], { relativeTo: this.route });
    } else {
      // if coming directly from cms default route (need to add volumes to url)
      this.router.navigate(['canadareports/template', id], { relativeTo: this.route });
    }
  }

  ngOnInit(): void {
    // get list of templates
    this.getAllTemplates();

  }

  ngOnDestroy() {

  }
}

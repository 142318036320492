import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, catchError, map, of, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { LoginToken } from 'app/shared/login-token';
import { LoginService } from 'app/home/login.service';

@Injectable({
  providedIn: 'root'
})
//Load app settings from appsettings.json file when the angular app starts
//Also check for login token in the cookie and if it exists then get auth token and put it in the observable so app can use it.
export class AppConfigService {
  public configs: any;
  //set the is logged in var
  private loginToken: Subject<LoginToken> = new BehaviorSubject(new LoginToken());

  loginToken$ = this.loginToken.asObservable();

  //update the login status
  changeLoginTokenStatus(token: LoginToken) {
    this.loginToken.next(token);
  }

  constructor(private http: HttpClient,
    private cookieService: CookieService,
    private loginService: LoginService) { }

  load(): Promise<any> {
    const promise = this.http.get('/appsettings.json')
      .toPromise()
      .then(data => {
        Object.assign(this, data);
        this.configs = data;
        //get login token and put it in the observable
        this.checkSession().subscribe(token => {
          window["loginToken"] = token;
        });
        return data;
      });

    return promise;
  }
  getConfig(): Observable<any> {
    return this.http.get<any>('/appsettings.json');
  }
  private checkSession(): Observable<any> {
    var sessionCookie = this.cookieService.get('ls_user_token');
    if (sessionCookie != null && sessionCookie != "") {
      const authEndpoint = `${this.configs.apiPath}api/authentication/getemsuserdata?ls_user_token=${sessionCookie}`;
      var headers = new HttpHeaders()
        .set('X-LS-Auth-Token', this.configs.apiToken)
        .set('X-LS-CORS-Template', this.configs.corsTemplate);
      return this.http.post<any>(authEndpoint, { headers: headers }).pipe(map(data => {
        if (data && data.sessionData !== "") {
          this.configs.loginToken = JSON.parse(data.sessionData);
          this.loginService.changeLoginStatus(true);
          //set in the windows obj so we can avoid circular dependency with app.component.ts
          window["loginToken"] = this.configs.loginToken;
        } else {
          this.configs.loginToken = new LoginToken();
        }
        this.changeLoginTokenStatus(this.configs.loginToken);

        return this.configs.loginToken;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
    } else {
      return of(null);
    }
  }

}

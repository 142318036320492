export class LoginToken {
  public Token: string;

  public IsValid: boolean

  public Role: string;

  public EmployeeNumber: string;

  public FirstName?: string;

  public PermissionVal: number;

  public BuildVersion: string;

  public BearerToken: string;

  constructor() {

    this.IsValid = false;

  }
}
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';
// Classes
import { Expert } from '../../shared/expert';

//  Services
import { CmsService } from '../cms.service';
import { ExpertReviewItem } from 'app/shared/expert-review-item';
import { UtilitiesService } from 'app/shared/utilities.service';
import { SnackbarService } from 'app/services/Snackbar.service';
import * as moment from 'moment';

@Component({
    selector: 'cms-expert-review', // this renames the undefined element after router-outlet in the dom
    templateUrl: 'cms-expert-review.component.html'
})

export class CmsExpertReviewComponent implements OnInit {

    public IsTestRun: string;

    public ExpertSearch: string ='';

    public AllExpertsSelected: boolean = false;

    public ErrorOccured: boolean=false;

    public ExpertReviewFilterArgs:any = {
        HasRoyalty: 'all',
        AlreadySent: 'all',
        NoEmailAddress:'all'
    }

    public ProcessCode: string;

    public ExpertItems: ExpertReviewItem[];

    constructor(private router: Router, private route: ActivatedRoute, public _cmsService: CmsService, public _emsConfig: EmsConfig, public _utilities: UtilitiesService, private _snackBar: SnackbarService) { }
    ngOnInit(): void {
        // gets the expert id from url
        this.ProcessCode = 'begin';

    }

    public ToggleAllExperts():void{

            this.ExpertItems.filter((expert)=>!expert.NoEmailAddress).forEach((expert)=>{expert.AddToMailout= this.AllExpertsSelected});

    }


    public SubmitMailoutRequest() {

        const ExpertIDList: string = this.ExpertItems.filter(e=>e.AddToMailout===true && !e.NoEmailAddress).map(e => e.ExpertID).join(',');
        const EmployeeName = this._emsConfig.getLoginToken().EmployeeNumber;
        const IsTestRun = this.IsTestRun;

        // if no expert is selected for the mailout, show warning & return 
        if (ExpertIDList.length === 0) {
            this._snackBar.warning('Please select atleast one checkbox for the mailout.');
            return;
        }

        //for production mailouts, at a confirmation dialog
        const confirmText = "This is a live mailout and will get sent to actual experts. \r\n Please confirm you wish to continue";

        if(!this.IsTestRun && !confirm(confirmText)){
            return;
        }

        this._cmsService.requestExpertReviewMailout({ ExpertIDList, EmployeeName, IsTestRun }).subscribe(() => {

            this.ProcessCode='complete';
            this._snackBar.success('Email(s) sent to the Expert(s) suceessfully');
        },
            () => {
                this.ErrorOccured= true;
                this._snackBar.error('Something went wrong! Please try again!')
            }
        )

    }

    public GetExpertReviewItems() {

        this._cmsService.getExpertsForReviewMailout().subscribe(experts => {

            this.ExpertItems = experts.map((e) => {

                e.ExpertNameNormalized =this._utilities.normalizeTerm(e.ExpertName);
                if (this.IsTestRun) {
                    if(!e.NoEmailAddress){
                    e.Email = e.NoEmailAddress?"NO_EMAIL" :this._emsConfig.getLoginToken().EmployeeNumber;
                    }
                }
                else {
                    e.AddToMailout = false;
                }

                return e;
            });


            this.ProcessCode = 'review';

        });


    }

    public RunAnotherMailout(){
        this.ProcessCode='begin';
        this.ErrorOccured= false;
        this.ResetMailoutList();
        return false;
    }

    public previousYear():string{
       return moment().subtract(1, 'years').format('DD-MM-YYYY');
    }

    private ResetMailoutList(){

        this.ExpertItems.forEach((e)=>{e.AddToMailout=false});
    }

}

<div class="col image-upload">
    <div *ngIf="!imageLoaded && ImageUploader?.queue?.length < 1"
        ng2FileDrop (onFileDrop)="fileDropped($event)" [uploader]="ImageUploader"
        class="well my-drop-zone upload-container__dropzone" [ngClass]="{'nv-file-over': imageHasBaseDropZoneOver}">
        Drag and drop file here
    </div>
    <div *ngFor="let item of ImageUploader.queue; let i = index;">
        <div class="image-upload__uploaded-container" *ngIf="ImageUploader?.queue?.length > 0 && !imageLoaded && i < 1">
            <input type="text" value="{{ fileName }}.jpg" disabled/>
            <div class="image-upload__btn-container">
                <button type="button" class="btn upload-btn btn-xs" 
                    (click)="item.upload()" (mousedown)="uploadingGifAdd()" 
                    [disabled]="item.isReady || item.isUploading || item.isSuccess">
                    Upload
                </button>
                <button type="button" class="btn btn-danger btn-xs delete-btn" 
                    (click)="item.remove()">
                    Remove
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="imageLoaded">
        <div class="image-upload__uploaded-container">
            <div class="image-upload__value-container">
                <input type="text" value="{{ existingImageUrl || fileUrl }}" disabled/>
                <p class="image-in-queue" *ngIf="showInQueueMessage">* Your new image has been added to the processing queue and will replace the old image once processed. Please allow 5 minutes for processing.</p>
            </div>
            <div class="image-upload__btn-container">
                <button type="button" class="btn btn-remove btn-xs" (click)="previewImage()">Preview</button>
                <button type="button" class="btn btn-remove btn-xs delete-btn" (click)="removeImage()" *ngIf="imageLoaded">Remove Image</button>
            </div>
        </div>
    </div>
</div>
<div class="img-preview-modal modal-window" *ngIf="showImgPreview" (click)="showImgPreview = false">
    <div class="modal-container img-modal-container" (click)="$event.stopPropagation();">
        <div class="modal__title-container b-dark-blue">
            <h3 class="welcome-page-modal__title modal__title ls-headline f-white">Image Preview</h3>
            <button class="welcome-page-modal__close modal__close" (click)="showImgPreview = false">
            <svg viewBox="0 0 24 24">
                <path fill="#ffffff"
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
            </button>
        </div>
        <div class="img-preview-content-container">
            <div class="img-container">
            <img [src]="imgPreviewUrl" alt="">
            </div>
        </div>
    </div>
</div>

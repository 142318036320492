import { TextItem } from '.././shared/TextItem';
import { Expert } from '.././shared/expert';

export class Event {
  EventID: any;
  CategoryID: number;
  EventCode: string;
  EventData: string;
  EventDataFR: string;
  EventDate: any;
  EventDateString?: any;
  EventDescription: TextItem = new TextItem();
  EventModerator: string;
  EventTopic: TextItem = new TextItem();
  IsHappeningToday: boolean;
  IsTranslated: boolean;
  Language: string;
  ScribbleliveEventID: number;
  Expert: Expert = new Expert();
  ExpertID: number;
  For_Marathon: boolean;
  MarathonScheduleId: number;
  ChatStartTime?: any;
  ChatEndTime?: any;
  RestrictedAte: boolean;
  RestrictedToClients?: string;
  AssetKey?: string;
  IncludeInContentFusion:boolean;
}

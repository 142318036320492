import { Component, OnInit, HostBinding } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { AnalyticsQuery } from '../shared/analytics-query';
import { DashboardItem } from './dashboard-item';
import { EmsConfig } from '../shared/emsConfig';
import { UserPermissions} from '../shared/user-permissions';
import {Account} from '../accounts/account';

// Services
import { AccountsService } from '../accounts/accounts.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  public queryControls: Array<string> = ['clients'];

  public DashboardItems: DashboardItem[]=[];

  public accountSearch: string;

  public filterAccounts: boolean = true;

  public welcomeMessage: string = `Welcome to the EMS, ${this._emsConfig.UserFirstName}. Here are your daily numbers.`;

  private accounts: string[];

  public get accountsFiltered():DashboardItem[]{
      const self = this;
        return  this.filterAccounts && this._emsConfig.loginToken.Role === 'accountadmin' ?
         this.DashboardItems.filter(function(item: DashboardItem){return self.accounts.includes(item.Organization)  || item.Organization === 'All' }) : this.DashboardItems;

  }

  constructor(
    private _dashboardService: DashboardService,
    private myAnalyticsQuery: AnalyticsQuery,
    public _emsConfig:EmsConfig,
    private accountService:AccountsService
  ) { }

  public runReport($event) {
    console.log('running report');
    console.log($event);
  }

  ngOnInit() {
    // sysadmins see all clients
    if (this._emsConfig.loginToken.Role === 'systemadmin') {
      this.filterAccounts = false;
    }    
    this.getAccounts();
  }

  private getDashboardReport() {
    this._dashboardService.getDashboard(this.myAnalyticsQuery).subscribe(
      DashboardItems => {this.DashboardItems = DashboardItems;
      });
  }

 // get users as populates depending on the account type
  private getAccounts() {
    this.accountService.getAllAccounts()
      .subscribe(
      Accounts => {
        const self = this;
        this.accounts = Accounts.filter(function(account: Account) {
          return account.AccountAdminUsername ===  self._emsConfig.loginToken.EmployeeNumber || account.AccountBackupUsername ===  self._emsConfig.loginToken.EmployeeNumber;
        }).map(function(account: Account) {
          return account.OrganizationName;
        });

      this.getDashboardReport();
      console.log(this.accounts);
      });

  }
}

export class SubCategory {
  ID?: number=-1;
  Lang?: string;
  Name?: string;
  IsLive?: boolean=true;
  CategoryID?:number=-1;
  SubCategoryLanguageID?:number;
  LangName?: string;
  AssetCount?: number;
}

<div class="volume-details-container section-padding">

  <div class="basic-container info-container volume-details-container__info-container">

    <syncstatus [assetID]="volumeDetails?.volume.ID" [tableID]="8"></syncstatus>
    <h3 class="ls-subheading f-dark-blue info-container__heading">Volume Details</h3>
    <div class="details-container info-container__text-container">
      <form class="addEditVolumeForm">
        <div class="info-container__section-split">
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z" />
                </svg>
              </div>
              Volume ID
            </h4>
            <p class="basic-info__text setting__text ls-subheading">{{volumeDetails?.volume.ID}}</p>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
              <span class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" />
                </svg>
              </span>
              Language
            </h4>
            <div class="select-container">
              <select class="setting__select-input select-input" name="volumeLanguage"
                [(ngModel)]="volumeDetails && volumeDetails?.volume.Language">
                <option value="EN">EN</option>
                <option value="FR">FR</option>
              </select>
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                </svg>
              </div>
              Title
            </h4>
            <div class="input-container">
              <input type="text" class="basic-info__text setting__text-input text-input" name="VolumeTitle"
                [(ngModel)]="volumeDetails && volumeDetails?.volume.VolumeTitle">
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M14,10H19.5L14,4.5V10M5,3H15L21,9V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3M5,12V14H19V12H5M5,16V18H14V16H5Z" />
                </svg>
              </div>
              Description
            </h4>
            <div class="input-container">
              <textarea class="basic-info__text setting__text-input textarea-input" rows="4" name="volumeDescription"
                [(ngModel)]="volumeDetails && volumeDetails?.volume.VolumeDescription"></textarea>
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                </svg>
              </div>
              Volume Status
            </h4>
            <div class="select-container">
              <select class="setting__select-input select-input" name="volumeStatus"
                [(ngModel)]="volumeDetails && volumeDetails?.volume.EMSStatus"
                (ngModelChange)="volumeStatusChange(volumeDetails.volume.EMSStatus)">
                <option value="live">Active</option>
                <option value="audio_only">Audio Only</option>
                <option value="retired">Retired</option>
                <option value="testing">Testing</option>
                <option value="qa">QA</option>
              </select>
            </div>
            <div class="volume-detail-retired-volume-box" *ngIf="showVolumeStatusConfirmation===true">
              <p>Are you sure you want to retire this volume? It will be removed from all client libraries!<br />
                Syncing is not required for this action.</p>
              <div class="confirmation-button-container">
                <button class="btn warning" (click)="retireCurrentVolume()">YES!</button>
                <button class="btn" (click)="goBackPreviousStatus()">NO</button>
              </div>
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V7H1.5L5,3.5L8.5,7H6V17Z" />
                </svg>
              </div>
              Sort Order
            </h4>
            <div class="input-container">
              <input type="text" class="basic-info__text setting__text-input text-input" name="volumeSortOrder"
                [(ngModel)]="volumeDetails && volumeDetails?.volume.SortOrder">
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                </svg>
              </div>
              Include in Content Fusion?
            </h4>
            <div class="basic-info__checkbox-container">
              <div class="checkbox-container">
                <div class="contentfusion-checkbox">
                  <input name="includecontent" [(ngModel)]="volumeDetails && volumeDetails.volume.IncludeInContentFusion" type="checkbox">
                </div>
              </div>
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />
                </svg>
              </div>
              <div class="inventory-tooltip basic-info__heading setting__heading ls-subheading">
                Inventory
                <tooltip class="inventory-tooltip__icon"></tooltip>
                <div class="inventory-tooltip__message">
                  <span>
                    Volume status:
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                      <path id="Volume_Off_icon" d="M1,1H13V13H1ZM0,14H14V0H0Z" fill="#666"/>
                    </svg> Unassigned
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                      <g id="Volume_Soon_icon" transform="translate(-800 -200)">
                        <rect id="Rectangle_2536" data-name="Rectangle 2536" width="14" height="14" transform="translate(800 200)" fill="#fd8e01"/>
                        <path id="Path_3209" data-name="Path 3209" d="M27,1a6,6,0,1,0,6,6,6,6,0,0,0-6-6m2.52,8.52L26.4,7.6V4h.9V7.12L30,8.74Z" transform="translate(780 200)" fill="#fff"/>
                      </g>
                    </svg> Coming soon
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                      <g id="Volume_New_icon" transform="translate(-840 -200)">
                        <path id="Path_3210" data-name="Path 3210" d="M0,0H14V14H0Z" transform="translate(840 200)" fill="#238469"/>
                        <path id="Path_3211" data-name="Path 3211" d="M44.641,5.174V8.817H44.2a.382.382,0,0,1-.165-.032.388.388,0,0,1-.13-.108L42.189,6.5c.007.066.011.128.014.189s0,.118,0,.171V8.817h-.745V5.174h.446A.751.751,0,0,1,42,5.179a.306.306,0,0,1,.068.018.241.241,0,0,1,.057.039.747.747,0,0,1,.063.068l1.732,2.187c-.007-.069-.013-.137-.016-.2S43.9,7.16,43.9,7.1V5.174Z" transform="translate(800 200)" fill="#fff"/>
                        <path id="Path_3212" data-name="Path 3212" d="M46,5.825v.85h1.146V7.3H46v.867H47.49v.65H45.145V5.175H47.49v.65Z" transform="translate(800 200)" fill="#fff"/>
                        <path id="Path_3213" data-name="Path 3213" d="M52.974,5.174,51.849,8.817h-.766L50.4,6.559a.93.93,0,0,1-.038-.116c-.012-.042-.024-.087-.034-.134-.012.047-.024.092-.036.134a.93.93,0,0,1-.038.116l-.694,2.258h-.766L47.668,5.174h.71a.322.322,0,0,1,.184.05.227.227,0,0,1,.1.133l.5,1.9c.014.06.03.125.044.2s.031.145.045.221a2.874,2.874,0,0,1,.111-.417l.59-1.9a.247.247,0,0,1,.1-.126.284.284,0,0,1,.179-.057h.25a.286.286,0,0,1,.177.052.315.315,0,0,1,.1.131l.585,1.9a2.678,2.678,0,0,1,.111.4c.013-.075.027-.145.041-.211s.029-.128.044-.186l.5-1.9a.234.234,0,0,1,.094-.128.3.3,0,0,1,.181-.055Z" transform="translate(800 200)" fill="#fff"/>
                      </g>
                    </svg> New
                  </span>
                  <span>
                    <div class="active-square"></div> Active
                  </span>
                </div>
              </div>
            </h4>
            <div class="basic-info__checkbox-container">
              <div class="checkbox-container" *ngFor="let inventory of inventories">
                <div class="ls-checkbox" (click)="updateInventoryStatus(inventory.Key)">
                  <input  type="number"
                          id="inventory{{ inventory.Key }}"
                          name="volumeInventory{{inventory.Key}}"
                          [(ngModel)]="inventoryStatuses[inventory.Key]"
                          min="0"
                          max="3"
                          class="status-input"
                          disabled
                  >
                  <label for="inventory{{ inventory.Key }}" class="status-icon">
                    <span *ngIf="!inventoryStatuses[inventory.Key] || inventoryStatuses[inventory.Key] === 0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                        <path id="Volume_Off_icon" d="M1,1H13V13H1ZM0,14H14V0H0Z" fill="#666"/>
                      </svg>
                    </span>
                    <span *ngIf="inventoryStatuses[inventory.Key] === 1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                        <g id="Volume_Soon_icon" transform="translate(-800 -200)">
                          <rect id="Rectangle_2536" data-name="Rectangle 2536" width="14" height="14" transform="translate(800 200)" fill="#fd8e01"/>
                          <path id="Path_3209" data-name="Path 3209" d="M27,1a6,6,0,1,0,6,6,6,6,0,0,0-6-6m2.52,8.52L26.4,7.6V4h.9V7.12L30,8.74Z" transform="translate(780 200)" fill="#fff"/>
                        </g>
                      </svg>
                    </span>
                    <span *ngIf="inventoryStatuses[inventory.Key] === 2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                        <g id="Volume_New_icon" transform="translate(-840 -200)">
                          <path id="Path_3210" data-name="Path 3210" d="M0,0H14V14H0Z" transform="translate(840 200)" fill="#238469"/>
                          <path id="Path_3211" data-name="Path 3211" d="M44.641,5.174V8.817H44.2a.382.382,0,0,1-.165-.032.388.388,0,0,1-.13-.108L42.189,6.5c.007.066.011.128.014.189s0,.118,0,.171V8.817h-.745V5.174h.446A.751.751,0,0,1,42,5.179a.306.306,0,0,1,.068.018.241.241,0,0,1,.057.039.747.747,0,0,1,.063.068l1.732,2.187c-.007-.069-.013-.137-.016-.2S43.9,7.16,43.9,7.1V5.174Z" transform="translate(800 200)" fill="#fff"/>
                          <path id="Path_3212" data-name="Path 3212" d="M46,5.825v.85h1.146V7.3H46v.867H47.49v.65H45.145V5.175H47.49v.65Z" transform="translate(800 200)" fill="#fff"/>
                          <path id="Path_3213" data-name="Path 3213" d="M52.974,5.174,51.849,8.817h-.766L50.4,6.559a.93.93,0,0,1-.038-.116c-.012-.042-.024-.087-.034-.134-.012.047-.024.092-.036.134a.93.93,0,0,1-.038.116l-.694,2.258h-.766L47.668,5.174h.71a.322.322,0,0,1,.184.05.227.227,0,0,1,.1.133l.5,1.9c.014.06.03.125.044.2s.031.145.045.221a2.874,2.874,0,0,1,.111-.417l.59-1.9a.247.247,0,0,1,.1-.126.284.284,0,0,1,.179-.057h.25a.286.286,0,0,1,.177.052.315.315,0,0,1,.1.131l.585,1.9a2.678,2.678,0,0,1,.111.4c.013-.075.027-.145.041-.211s.029-.128.044-.186l.5-1.9a.234.234,0,0,1,.094-.128.3.3,0,0,1,.181-.055Z" transform="translate(800 200)" fill="#fff"/>
                        </g>
                      </svg>
                    </span>
                    <span *ngIf="inventoryStatuses[inventory.Key] === 3">
                      <div class="active-square"></div>
                    </span>
                    {{inventory.Value}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--second half-->
        <div class="info-container__section-split">
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </div>
              Date Created
            </h4>
            <p class="basic-info__text setting__text">{{volumeDetails?.volume.DateCreated}}</p>
          </div>

          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
                </svg>
              </div>
              Expert
            </h4>
            <div class="select-container">
              <select class="setting__select-input select-input" name="volumeSpeaker"
                [(ngModel)]="volumeDetails && volumeDetails?.volume.ExpertID">
                <option value="-1" disabled>Select an expert </option>
                <option value="{{expert.ExpertID}}" *ngFor="let expert of expertList">{{expert.ExpertName.English}}
                </option>
              </select>
            </div>
          </div>

          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z" />
                </svg>
              </div>
              Category
            </h4>
            <div class="select-container">
              <select class="setting__select-input select-input" name="volumeCategory"
                [(ngModel)]="volumeDetails && volumeDetails?.volume.CategoryID">
                <option value="{{category.ID}}" *ngFor="let category of categoryList">{{category.Name.English}}</option>
              </select>
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                </svg>
              </div>
              Matched Volume
            </h4>
            <div class="select-container" [ngClass]="{'volume-matched-dropdown-disabled': showMatchedReadOnly}">
              <select class="setting__select-input select-input volume-matched-dropdown" name="volumeMatched" [disabled]="showMatchedReadOnly" *ngIf="volumeDetails" [(ngModel)]="volumeDetails?.volume.MatchedVolume">
                <option [ngValue]="0" disabled>Select a matched volume</option>
                <option [ngValue]="matchedVol.ID" *ngFor="let matchedVol of simpleVolumes">
                  {{matchedVol.VolumeTitle}}</option>
              </select>
            </div>
          </div>

          <div class="basic-info__setting tipsheet-setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M14,9H19.5L14,3.5V9M7,2H15L21,8V20A2,2 0 0,1 19,22H7C5.89,22 5,21.1 5,20V4A2,2 0 0,1 7,2M11.93,12.44C12.34,13.34 12.86,14.08 13.46,14.59L13.87,14.91C13,15.07 11.8,15.35 10.53,15.84V15.84L10.42,15.88L10.92,14.84C11.37,13.97 11.7,13.18 11.93,12.44M18.41,16.25C18.59,16.07 18.68,15.84 18.69,15.59C18.72,15.39 18.67,15.2 18.57,15.04C18.28,14.57 17.53,14.35 16.29,14.35L15,14.42L14.13,13.84C13.5,13.32 12.93,12.41 12.53,11.28L12.57,11.14C12.9,9.81 13.21,8.2 12.55,7.54C12.39,7.38 12.17,7.3 11.94,7.3H11.7C11.33,7.3 11,7.69 10.91,8.07C10.54,9.4 10.76,10.13 11.13,11.34V11.35C10.88,12.23 10.56,13.25 10.05,14.28L9.09,16.08L8.2,16.57C7,17.32 6.43,18.16 6.32,18.69C6.28,18.88 6.3,19.05 6.37,19.23L6.4,19.28L6.88,19.59L7.32,19.7C8.13,19.7 9.05,18.75 10.29,16.63L10.47,16.56C11.5,16.23 12.78,16 14.5,15.81C15.53,16.32 16.74,16.55 17.5,16.55C17.94,16.55 18.24,16.44 18.41,16.25M18,15.54L18.09,15.65C18.08,15.75 18.05,15.76 18,15.78H17.96L17.77,15.8C17.31,15.8 16.6,15.61 15.87,15.29C15.96,15.19 16,15.19 16.1,15.19C17.5,15.19 17.9,15.44 18,15.54M8.83,17C8.18,18.19 7.59,18.85 7.14,19C7.19,18.62 7.64,17.96 8.35,17.31L8.83,17M11.85,10.09C11.62,9.19 11.61,8.46 11.78,8.04L11.85,7.92L12,7.97C12.17,8.21 12.19,8.53 12.09,9.07L12.06,9.23L11.9,10.05L11.85,10.09Z" />
                </svg>
              </div>
              Tipsheet
              <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Content.Tipsheet_Tooltip"></tooltip>
            </h4>
            <div class="setting__tipsheet-container">
              <p class="basic-info__text setting__text">PDF Tipsheet Title: {{volumeDetails?.volume.ResourceUrl}}</p>
              <button class=" btn btn-editTipsheet" (click)="navigateToTipsheet(volumeDetails.volume.ID)"
                [disabled]="addNewVolume">
                Add/Edit HTML Tipsheet
              </button>
            </div>
          </div>

          <div class="basic-info__setting upload-setting image-upload__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
                </svg>
              </div>
              Volume Image
              <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Content.Volume_Image_Tooltip"></tooltip>
            </h4>
            <div class="upload-setting__container">
              <div ng2FileDrop [ngClass]="{'nv-file-over': imageHasBaseDropZoneOver}"
                (fileOver)="fileOverBaseImage($event)" [uploader]="volumeImageUploader"
                class="well my-drop-zone upload-container__dropzone"
                *ngIf="!imageLoaded && volumeImageUploader?.queue?.length < 1">
                Drag and drop file here
              </div>
              <div class="upload-setting__file-container" *ngFor="let item of volumeImageUploader.queue">
                <div class="upload-setting__file-name-btns"
                  *ngIf="volumeImageUploader?.queue?.length > 0 && !imageLoaded ">
                  <span>{{item?.file?.name}}</span>
                  <div class="upload-btn-container">
                    <button type="button" class="btn btn-success btn-xs" (click)="item.upload()"
                      (mousedown)="uploadingGifAdd()" [disabled]="item.isReady || item.isUploading || item.isSuccess">
                      Upload
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" (click)="item.remove()">
                      Remove
                    </button>
                  </div>
                </div>
              </div>

              <div class="upload-container__existing-image" *ngIf="imageLoaded">
                <span>{{volumeDetails?.volume.ImageUrl}}.jpg</span>
                <button type="button" class="btn btn-remove btn-xs" (click)="previewVolumeImage()">Preview</button>
                <button type="button" class="btn btn-remove btn-xs" (click)="clearVolumeImage()"
                  *ngIf="imageLoaded">Remove</button>
                <button type="button" class="btn btn-remove btn-xs"
                  (click)="removeImageFromAWS(volumeDetails.volume.ImageUrl)" *ngIf=" imageLoaded">Remove from
                  AWS</button>
                <p class="image-in-queue" *ngIf="imageLoaded ">* Your new image has
                  been added to the processing queue and will replace the old image once processed. Please allow 5
                  minutes for processing.</p>
              </div>
            </div>
          </div>
          <div class="basic-info__setting">
            <video-participation [mediaType]="'volume'" [assetId]="id"></video-participation>
          </div>
        </div>
        <div class="form-button-container">
          <button
            (click)="openConfirmDialog('volumeDetails', 'updateVolume', volumeDetails, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
            type="button" class="btn volume-submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="volume-details-container__module-info">
    <div class="basic-container info-container volume-details-container__info-container">
      <h3 class="ls-subheading f-dark-blue info-container__heading volume-modules__heading">Modules</h3>
      <div class="details-container info-container__text-container">
        <p *ngIf="!volumeCreated">Module creation not available until volume has been created.</p>
        <div class="module-buttons-container">
          <div class="button-container" *ngFor="let module of volumeDetails?.media; let i = index">
            <button (click)="goToModule(module.ID)">
              Module {{i + 1}} - {{module.Title}}
            </button>
          </div>
          <div class="button-container" *ngIf="showAddModule">
            <button (click)="createModule()" class="add-new-module">
              Add Module
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="volume-details-container__translation-info">
    <div class="basic-container info-container volume-details-container__info-container">
      <h3 class="ls-subheading f-dark-blue info-container__heading volume-modules__heading">Translation info</h3>
      <div class="details-container info-container__text-container info-container__translation-container">
        <p *ngIf="Subtitles.length==0 && !showNewLanguage"> No translation info found!</p>
        <table class="display-table display-table--extra-padding translation-info__table">
          <thead *ngIf="Subtitles.length>0 || showNewLanguage">
            <th></th>
            <th>Volume Title</th>
            <th>Volume Description</th>
          </thead>
          <tbody>
            <tr *ngFor="let subtitle of Subtitles; let i = index">
              <td>
                <a href="javascript:void(0);"
                  (click)="openConfirmDialog('volumeDetails', 'deleteSubtitle', subtitle, _emsConfig.text?.EMS_General.Confirm_Delete_Message)">
                  <svg viewBox="0 0 9.725 10" width="15" fill="#ee6a53">
                    <path class="a"
                      d="M7.039,3v.556H4V4.667h.608v7.222A1.167,1.167,0,0,0,5.823,13H11.9a1.167,1.167,0,0,0,1.216-1.111V4.667h.608V3.556H10.686V3H7.039m0,2.778H8.255v5H7.039v-5m2.431,0h1.216v5H9.47Z"
                      transform="translate(-4 -3)" />
                  </svg>
                </a>
                <span>
                  {{subtitle.LangName}}</span>
              </td>

              <td>
                <div class="input-container">
                  <input type="text" class="basic-info__text setting__text-input text-input" name="title-{{i}}"
                    [(ngModel)]="subtitle.VolumeTitle" dir="{{subtitle.LangDirection}}" placeholder="Please add title">
                </div>
              </td>
              <td>
                <div class="input-container additional-margin">
                  <textarea class="basic-info__text setting__text-input textarea-input" dir="{{subtitle.LangDirection}}"
                    [innerHTML]="subtitle?.VolumeDescription"></textarea>
                </div>
              </td>
            </tr>
            <tr *ngIf="showNewLanguage">
              <td>
                <div class="select-container  translation-info__language">
                  <a href="javascript:void(0);" (click)="toggleAddLanguage()">
                    <svg viewBox="0 0 9.725 10" width="15" fill="#ee6a53">
                      <path class="a"
                        d="M7.039,3v.556H4V4.667h.608v7.222A1.167,1.167,0,0,0,5.823,13H11.9a1.167,1.167,0,0,0,1.216-1.111V4.667h.608V3.556H10.686V3H7.039m0,2.778H8.255v5H7.039v-5m2.431,0h1.216v5H9.47Z"
                        transform="translate(-4 -3)" />
                    </svg>
                  </a>
                  <select class="setting__select-input select-input"
                    [ngClass]="{'no-selected-language': !volumeSubtitle.LangCode}" name="selectLanguage"
                    [(ngModel)]="volumeSubtitle && volumeSubtitle.LangCode">
                    <option [selected]="true" [disabled]="true" value="">Select</option>
                    <option value={{item.Code}} *ngFor="let item of availableLanguages">{{item.Name}}</option>
                  </select>
                </div>
              </td>
              <td>
                <div class="input-container">
                  <input type="text" [(ngModel)]="volumeSubtitle && volumeSubtitle.VolumeTitle"
                    placeholder="Please add Volume Title" class="basic-info__text setting__text-input text-input"
                    dir="auto" name="subtitle">
                </div>
              </td>
              <td>
                <div class="input-container additional-margin">
                  <textarea class="basic-info__text setting__text-input textarea-input"
                    placeholder="Please add Volume Description"
                    [(ngModel)]="volumeSubtitle && volumeSubtitle.VolumeDescription"></textarea>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="form-button-container">
          <button *ngIf="!showNewLanguage" type="button" class="btn add-language" (click)="toggleAddLanguage()">
            ADD Language
          </button>

          <button type="button" class="btn volume-submit" (click)="addUpdateVolumeSubtitles()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="img-preview-modal modal-window" *ngIf="showImgPreview" (click)="closePreviewModal()">
    <div class="modal-container img-modal-container" (click)="onEvent($event)">
      <div class="modal__title-container b-dark-blue">
        <h3 class="welcome-page-modal__title modal__title ls-headline f-white">Volume Image Preview</h3>
        <button class="welcome-page-modal__close modal__close" (click)="closePreviewModal()">
          <svg viewBox="0 0 24 24">
            <path fill="#ffffff"
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>
      <div class="img-preview-content-container">
        <div class="img-container">
          <img [src]="imgPreviewUrl" (error)="updateImageUrl($event)" alt="">
        </div>
      </div>
    </div>
  </div>

</div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmsConfig } from 'app/shared/emsConfig';
import { CmsService } from 'app/cms/cms.service';
import { SnackbarService } from 'app/services/Snackbar.service';
import { FileUploader } from 'ng2-file-upload';

@Component({
    selector: 'image-upload',
    templateUrl: './image-upload.component.html',
})

export class ImageUploadComponent implements OnInit {

    public ImageUploader: FileUploader = new FileUploader({ url: this._emsConfig.apiEndpoint + '/assetAdmin/uploadfile' });
    public imageLoaded: boolean;
    public imageHasBaseDropZoneOver: boolean;
    public showInQueueMessage: boolean = false;
    public showImgPreview: boolean;
    public imgPreviewUrl: string;
    public fileUrl: string = '';
    @Input() public fileName: string = ''; // Override original file name
    @Input() public filePath: string = 'Other'; // Override with custom path for component: _emsConfig.assetPath/{filePath}/{fileName}.jpg; otherwise will be uploaded to "Other" folder
    @Input() public existingImageUrl: string = ''; // Pass FULL image url to image-upload component to check if image already exists in AWS. This will hide file drop state and show preview state instead
    @Output() urlOutput = new EventEmitter<any>(); // Pass image url back to parent component
    @Output() imageUploaded = new EventEmitter<boolean>(); // Pass boolean value if image is uploaded in aws or not
    constructor(
        public _emsConfig: EmsConfig,
        private _cmsService: CmsService,
        private _snackBar: SnackbarService
    ) {}
    
    fileDropped(e: any): void {
        this.imageHasBaseDropZoneOver = e;
        this._validateFileTypeImage();
        // by default, the file uploader plugin requires credentials for submission, this remove them
        this.ImageUploader.queue.forEach(file => file.withCredentials = false);
        this.ImageUploader.options.additionalParameter = { 'path': this.filePath };
    }

    private _imageUploaderSetup() {
        this.ImageUploader.options.headers = this._emsConfig.getFileUploadHeaders();
        // gets the response after the upload is completed
        this.ImageUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
            const responsePath = response;
            if (!item.isSuccess) {
                this._snackBar.error('Image has not been uploaded. Please try again.');
                this.uploadingGifRemove();
            } else {
                this.createFileUrl();
                this._snackBar.success('Image successfully uploaded.');
                this.uploadingGifRemove();
                this.ImageUploader.clearQueue();
            }
        };
    }

    // TODO - add max file size restriction

    // make sure the current item is a jpg file. Otherwise remove from the queue
    private _validateFileTypeImage(): void {
        const currentItem = this.ImageUploader.queue[0] as any;
        console.log(currentItem);
        console.log(this.fileName);
        if (currentItem !== undefined && currentItem.file.name.indexOf('.jpg') === -1) {
            alert('Only JPG files are permitted for Image upload');
            this.ImageUploader.queue.pop();
            this._emsConfig.getFileUploadHeaders();
        } else if (currentItem !== undefined) {
            if (this.fileName) {
                currentItem.file.name = this.fileName + '.jpg';
            } else {
                this.fileName = currentItem.file.name.replace('.jpg', '');
            }
        }
    }

    // Create new file URL based on given file name and path - or by passed in existingImageUrl from parent
    createFileUrl(): void {
        if (this.existingImageUrl) {
            this.fileUrl = this.existingImageUrl;
        } else if (this.filePath && this.fileName) {
            this.fileUrl = this._emsConfig.assetPath + '/' + this.filePath + '/' +  this.fileName + '.jpg';
        } else {
            this.fileUrl = '';
        }
        // check if resized and uploaded
        this.imageExists();
        this.showInQueueMessage = true;
        this.urlOutput.emit(this.fileUrl)
    }

    imageExists(): void {
        console.log("this.fileUrl", this.fileUrl)
        const img = new Image();
        // imageUpload will show/hide the image upload dropzone
        img.onload = (load) => {
            console.log(load)
            this.imageLoaded = true;
            this.imageUploaded.emit(this.imageLoaded);
        };
        img.onerror = (err) => {
            console.log(err)
            this.clearImage();
        };
        img.src = this.fileUrl;
    }


    uploadingGifAdd(): void {
        const container = document.querySelector('body');
        container.classList.add('request-in-progress');
    }

    uploadingGifRemove(): void {
        const container = document.querySelector('body');
        container.classList.remove('request-in-progress');
    }

    previewImage(): void {
        this.imgPreviewUrl = this.fileUrl + '?ts='+Date.now();
        this.showImgPreview = true;
        console.log("preview image: ", this.imgPreviewUrl)
    }

    clearImage(): void {
        this.imageLoaded = false;
        this.imageUploaded.emit(this.imageLoaded);
        this.fileUrl = '';
        this.existingImageUrl = '';
        this.urlOutput.emit(this.fileUrl)
    }

    // removes image from aws
    removeImage(): void {
        let imgFullUrl = '';
    
     //   use the correct image preview path
        if (this.imageLoaded) {
          imgFullUrl = this.filePath + '/' + this.fileName + '.jpg'
        } else {
          imgFullUrl = '';
        }
        this._cmsService.removeAwsProcessedFileFromAWS(imgFullUrl)
          .subscribe(
            data => {
                console.log(data);

              if (data.succeeded === 'false') {
                this._snackBar.error("Image was not removed. Please try again.");
              } else {
                this._snackBar.success("Image successfully removed. It will take couple of hours to clear it from cache",5000);
                this.imageLoaded = false;
                this.imageUploaded.emit(this.imageLoaded);
                this.fileUrl = '';
                this.existingImageUrl = '';
                this.urlOutput.emit(this.fileUrl);
              }
            },
            err => console.log(err)
          );
    }

  

    ngOnInit(): void {
        this._imageUploaderSetup();
        if (this.existingImageUrl) {
            this.fileUrl = this.existingImageUrl;
            this.imageExists();
        }
    }
}

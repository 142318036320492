<div class="inner-page-main section-padding ">
  <div (click)="onEvent($event)">
    <syncstatus [assetID]="poll_id" [tableID]="42"></syncstatus>
    <button class="btn--back back pointer " [routerLink]="['/cms/polls']">&#8592; Back</button>

    <div class="basic-container module-details-container__info-container">
      <h3 class="ls-subheading f-dark-blue info-container__heading" *ngIf="editPoll && (editPoll.Poll_ID!=-1)">Edit
        Poll
        {{editPoll && editPoll.Poll_ID}}</h3>
        <h3 class="ls-subheading f-dark-blue info-container__heading" *ngIf="editPoll && (editPoll.Poll_ID==-1)">Add
          new Poll</h3>
        <h3 class="ls-subheading f-dark-blue info-container__heading" *ngIf="isActivePoll">Currently Active Poll</h3>
    </div>
    <div class="details-container info-container__text-container">
      <table>
        <tbody>
          <tr>
            <td class="basic-info__setting ">Poll Description (en)</td>
            <td>
              <textarea ls-subheading required-field
                [(ngModel)]="editPoll && editPoll.Poll_Details?.Poll.Language['EN'][0].Description" name="poll-descp-en"
                rows="3" cols="55"></textarea>
            </td>
            <td class="basic-info__setting ">Poll Description (fr)</td>
            <td>
              <textarea [(ngModel)]="editPoll && editPoll.Poll_Details?.Poll.Language['FR'][0].Description"
                name="poll-descp-fr" rows="3" cols="55"></textarea>
            </td>

          </tr>
          <tr>
            <td class="basic-info__setting ">Question (en)</td>
            <td>
              <textarea [disabled]="isActivePoll" [(ngModel)]="editPoll && editPoll?.Poll_Details.Poll.Language['EN'][0].Questions[0].Question"
                name="poll-question-en" rows="3" cols="55"></textarea>
            </td>
            <td class="basic-info__setting ">Question (fr)</td>
            <td>
              <textarea  [disabled]="isActivePoll" [(ngModel)]="editPoll && editPoll?.Poll_Details.Poll.Language['FR'][0].Questions[0].Question"
                name="poll-question-en" rows="3" cols="55"></textarea>
            </td>
          </tr>
          <!-- option EN -->
          <tr>
            <td colspan="2">
              <div *ngFor="let option of optionsEN" class="en-options  basic-info__setting " name="optionsEN">
                <span *ngIf="option">Option {{option.ID}} (en)</span>
                <textarea [disabled]="isActivePoll" *ngIf="option" [(ngModel)]="option.Option" name="poll-op1-en" rows="1" cols="55"></textarea>
              </div>
            </td>
            <td colspan="2">
              <div *ngFor="let option of optionsFR" class="fr-options basic-info__setting " name="optionsFR">
                <span *ngIf="option">Option {{option.ID}} (fr)</span>
                <textarea [disabled]="isActivePoll" *ngIf="option" [(ngModel)]="option.Option" name="poll-op1-fr" rows="1" cols="55"></textarea>
              </div>
            </td>
          </tr>
          <tr>
            <td class="basic-info__setting  required-field">Start Date</td>
            <td>
              <input class="date-time-input" type="datetime-local" name="SD"
                [(ngModel)]="editPoll && editPoll.Start_Date">
            </td>
            <td class="basic-info__setting required-field">Poll type</td>
            <td>
              <div>
                <select [(ngModel)]="currentPollType" name="Poll_types" (change)="changePollType(pollType.value)"
                  #pollType>
                  <option [ngValue]="0" disabled>Select the Type</option>
                  <option>global
                  <option>specific
                  </option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td class="basic-info__setting   required-field">End Date</td>
            <td>
              <input class="date-time-input" type="datetime-local" name="ED"
                [(ngModel)]="editPoll && editPoll.End_Date">
            </td>
            <td *ngIf="currentPollType=='specific'">
              <h4 class=" basic-info__setting required-field">
                Client Name
              </h4>
            </td>
            <td *ngIf="currentPollType=='specific'">
              <div class="client-search">
                <svg class="searchbar-icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
                <input type="text" class="client-search-input" placeholder="Search Client" [(ngModel)]="clientSearchVal"
                  name="client-search-val">
                <select class="client-selection" [(ngModel)]="editPoll && editPoll.Client">
                  <option [ngValue]="0" disabled>Select a Client </option>
                  <option [ngValue]="client.OrganizationName"
                    *ngFor="let client of clientList | accountSearchFilter:clientSearchVal">
                    {{client.OrganizationName}}</option>
                </select>
              </div>
            </td>

          </tr>

        </tbody>
      </table>
      <div class="model-btn-container" (click)="onEvent($event)">
        <div class="align-btn-right">
          <button type="button" class="btn module-submit"
            (click)="confirmAction('submitPoll','Are you sure?',editPoll)">Save</button>
        </div>
      </div>

    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SyncUpdateService {

  private syncData: Subject<any> = new BehaviorSubject(this.notification({ prevent: true }));
  syncData$ = this.syncData.asObservable();

  public syncInfo: any = {};

  notification(data: any): any {
    return data;
  }

  updateSyncData(data: any): void {
    this.syncInfo = data;
    this.syncData.next(data);
  }

  public get currentSyncOperation(): number {
    return this.syncInfo.operationID ? this.syncInfo.operationID : -1;
  }

  public get currentSyncOperationAdminUser(): string {
    return this.syncInfo.adminUser ? this.syncInfo.adminUser : '';
  }

}

<div class="section-padding">

  <h1>CPD Management</h1>

  <table class="display-table display-table--extra-padding">
    <thead>
      <tr>
        <th>Profession</th>          
        <th>Association</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let title of titles">
        <td>{{title.Profession}} ({{title.Lang}})</td>          
        <td class="edit-link" (click)="navToVolumes(title.Profession, title.JurisdictionCode, title.Lang)">{{title.Title}}</td>
        <td>{{title.Status}}</td>
      </tr>
    </tbody>
  </table>

</div>

import { Event } from './../../shared/event';
import { Component, HostBinding, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { EmsConfig } from '../../shared/emsConfig';
import { ConnexService } from '../connex.service';
import { CmsService } from "app/cms/cms.service";
import { BlogService } from '../../blog/blog.service';
import { AccountsService } from 'app/accounts/accounts.service';

import { SecuredRoutes } from 'app/shared/secured-routes';
import { PopupService } from '../../shared/popup.service';
import { UtilitiesService } from '../../shared/utilities.service';

import { Categories } from 'app/categories/categories';
import { CategoriesService } from 'app/categories/categories.service';
import { VolumeDetailItem } from 'app/shared/VolumeDetailItem';

// Objects
import { ExpertEvent } from '../../shared/ExpertEvent';
import { AteAnswer } from '../Objects/AteAnswer';
import { AteQuestionStatus } from '../Objects/AteQuestionStatus';
import { AteQuestionType } from '../Objects/AteQuestionType';

//signalr service and lib
import { HubConnection, HubConnectionState } from "@microsoft/signalr";

import { WebSocketsService } from 'app/connex/ate/websockets.service';
import { AteQuestion } from '../Objects/AteQuestion';
import { MarathonScheduleItem } from '../Objects/MarathonScheduleItem';

import * as momenttz from 'moment-timezone';
import 'moment/locale/fr';

declare var tinymce: any;

@Component({
  selector: 'ate-moderation',
  templateUrl: 'ate-moderation.component.html',
  providers: [ConnexService, AccountsService]
})
export class ATEModerationComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class') classAttribute: string = 'ate-moderation ate-edit';

  public eventID: number;

  public expertEvent: ExpertEvent = new ExpertEvent();
  public questions: AteAnswer[] = [];
  public inboxQuestions: AteAnswer[] = [];
  public postedQuestions: AteAnswer[] = [];
  public trashQuestions: AteAnswer[] = [];
  public langStr: string = 'English';
  public activeTab: number = 1;

  public upcomingMarathonChat: MarathonScheduleItem;

  public hubConnection: HubConnection;
  public hubConnected: boolean = true;

  public modModal = false;
  public modComment = false;
  public modNickname: string = '';
  public modQuestion: string = '';
  public modModalTitle = 'Question';

  public selectedPreloadedResource: string = 'text';

  public preloadedText: any[] = [];
  public preloadedTextVal: string = '';

  public activeQuestion: AteAnswer;
  public toggleModify = false;

  public blogVal: string = '';
  public expertBlogPosts: any[] = [];
  public blogSearchTerm: string = '';

  public categories: Categories[] = [];
  public volumes: any[] = [];
  public modules: VolumeDetailItem;
  public moduleVal: any;

  public enableText: string = 'Enable Live Stream';
  public connectedUsersText: number = 0;
  subscription: Subscription;
  public validInput: boolean = true;
  // editor variables
  public questionEditor: any;

  // this holds all sharelink settings and the sharekey
  public sharelink: any = {};
  public orgDomain: string;
  public sharelinkBaseUrl: string;

  constructor(
    private route: ActivatedRoute,
    public _emsConfig: EmsConfig,
    private _popupService: PopupService,
    private _utilities: UtilitiesService,
    public _securedRoutes: SecuredRoutes,
    private _connexService: ConnexService,
    private _cmsService: CmsService,
    private _blogService: BlogService,
    private hubConnectionService: WebSocketsService,
    private _categoriesService: CategoriesService,
    private _accountsService: AccountsService
  ) {
    this.route.params.subscribe(params => {
      this.eventID = params['id'];
    });
    this.hubConnectionService.buildConnection(this.eventID);
    this.sharelink.previewSettings = {};
    this.sharelink.settings = {};
    this.sharelink.settings.Type = 'init';
  }

  public async ngOnInit() {
    this._connexService.getExpertEventByID(this.eventID)
      .subscribe(response => {
        console.log(response);
        this.expertEvent = response;
        this.langStr = response.Language === 'FR' ? 'French' : 'English';
        this.getExpertBlogPosts(this.expertEvent.Language);
        this.getCategories();
        this.loadNextMarathonEvent(this.expertEvent.MarathonScheduleId);
      });

    this._connexService.getATEQuestions(this.eventID)
      .subscribe(response => {
        console.log(response);
        this.questions = response;
        this.sortQuestions();
      });

    // Get the preloaded ATE text set by moderator in CMS (ID 45 - Name: ATE_Preloaded_Text)
    this._cmsService.getResourcesByPageID(45)
      .subscribe(text => {
        console.log(text);
        this.preloadedText = text;
      });

    try {
      this.hubConnection = this.hubConnectionService.getConnection();
      // register all server events so we don't miss any messages
      this.registerOnServerEvents();
    } catch (err) {
      console.log(" Signalr connection error " + err);
    }

    // subscribe to the hub connection status
    this.hubConnectionService.connectedStatus.subscribe((status: boolean) => {
      this.hubConnected = status;
    });

  }

  getExpertBlogPosts(lang: string) {
    this._blogService.getBlogPosts(lang, true, true)
      .subscribe(posts => {
        console.log('EXPERT BLOG POSTS');
        console.log(posts);
        this.expertBlogPosts = posts;
        this.normalizeBlogPosts();
      });
  }

  getCategories(): void {
    if (this.categories.length < 1) {
      this._categoriesService.getAllCategories()
        .subscribe((allcategories: any) => {
          this.categories = allcategories.categories;
        });
    }
  }

  getVolumes(categoryID: number) {
    this._cmsService.getAllVolumes(false, this.expertEvent.Language, null, -1, true)
      .subscribe(volumes => {
        this.volumes = volumes.filter(volume => {
          return categoryID ? volume.CategoryID == categoryID : volume
        })
      })
  }

  getVideosForVolume(id: number): void {
    this.resourceChange();
    this._cmsService.getEmsVolume(id, true)
      .subscribe(modules => {
        this.modules = modules
        console.log(modules)
      });
  }

  addVideoAsset(module, volume) {
    this.moduleVal = module;
    if (module.ID) {
      const postHtml = this.buildVideoIframe(module, volume);
      this.questionEditor.setContent(postHtml);
    } 
  }

  // Reset dropdown options to show "Select Video"/"Select Blog" as default rather than previously selected resource
  resourceChange() {
    this.moduleVal = '';
    this.blogVal = '';
  }

  // loop through and normalize any accented characters for search purposes
  normalizeBlogPosts() {
    for (let i = 0; i < this.expertBlogPosts.length; i++) {
      this.expertBlogPosts[i].PostTitleNormalized = this._utilities.normalizeTerm(this.expertBlogPosts[i].PostTitle);
    }
  }

  toggleTab(tab: number) {
    this.activeTab = tab;
  }

  // Prevent DOM event bubbling when clicking in modals
  onEvent(event: any) {
    event.stopPropagation();
  }

  // Sort the questions for the various tab groups (inbox, posted, recycling bin)
  sortQuestions() {
    this.inboxQuestions = this.questions.filter(q =>
      q.Status === AteQuestionStatus.Unassigned ||
      q.Status === AteQuestionStatus.Pending ||
      q.Status === AteQuestionStatus.Answered);
    this.postedQuestions = this.questions.filter(q => q.Status === AteQuestionStatus.Sent);
    this.trashQuestions = this.questions.filter(q => q.Status === AteQuestionStatus.Rejected);
  }

  //Method to change livechat status/ (Disables/Enables buttons/textfields on all client's livechat page)
  enableLiveStream(status) {
    this._connexService.updateATEStreamStatus(this.eventID, status)
      .subscribe(response => {
        console.log(response);
        if (response.Key) {
          this.expertEvent.StreamLive = status;
          this.hubConnection.send('LiveChatStatus', status, this.eventID).catch(err => console.error(err));
        } else {
          const errorMessage = 'Webchat ID: ' + response.Value + ' is currently live. Please disable it before enabling another.'
          this._popupService.updateNotification({ message: errorMessage, error: true });
        }
      });
  }

  // Called when a question is moved: Child component --> Parent --> Different child
  moveQuestionToParent(question: AteAnswer) {
    const index = this.questions.findIndex(x => x.QuestionID === question.QuestionID);
    if (index > -1) {
      this.questions[index] = question;
      this.sortQuestions();
      this.refreshQuestion(question);
    }
  }

  addQuestion(comment: boolean) {
    // Prevent body scrolling
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
    this.modModalTitle = comment ? 'Comment' : 'Question';
    this.modComment = comment;
    this.modModal = true;
    this.questionEditor = tinymce.get('ate-question-submit');
    if (this.questionEditor != null) {
      this.questionEditor.setContent(this.modQuestion);
    }
  }

  modifyQuestion(question: AteAnswer) {
    // add no scroll class to body element
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
    this.activeQuestion = question;
    this.toggleModify = true;
  }

  closeModify(event: any) {
    this.toggleModify = false;
  }

  // Pass back any saved question changes from the modify question modal
  updateFromModify(question: AteAnswer) {
    const index = this.questions.findIndex(x => x.QuestionID === question.QuestionID);
    if (index > -1) {
      // if the chat ID is different, remove question as it has been reassigned to a different chat
      if (question.ArchiveChatID !== this.expertEvent.EventID) {
        this.questions = this.questions.filter(x => x.QuestionID !== question.QuestionID);
      } else {
        this.questions[index] = question;
      }
      this.sortQuestions();
    }
  }

  // close the moderator question modal
  closeQuestion() {
    this.modModal = false;
    this.modComment = false;
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('no-scroll');
    this.resetQuestionModal();
    this.validInput = true;
  }

  // reset mod question modal data
  resetQuestionModal() {
    this.modNickname = '';
    this.modQuestion = '';
    this.preloadedTextVal = '';
    this.blogVal = '';
    this.blogSearchTerm = '';
  }

  // Moderator submitting an ATE Question
  modSubmitQuestion() {
    this.modQuestion = this.questionEditor.getContent();
    if (this.modComment) {
      this.modNickname = 'admin';

    }
    // ensure question and nicknames fields are not blank
    if (this._utilities.isBlank([this.modQuestion, this.modNickname])) {
      this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Required, error: true });
      return;
    }
    let question = this.buildModQuestion();
    this._connexService.submitATEQuestion(question)
      .subscribe(response => {
        if (response.QuestionID > -1) {
          console.log(response);
          question = response;
          question.Status = AteQuestionStatus.Unassigned;
          this.questions.unshift(question);
          this.sortQuestions();
          this.closeQuestion();
        } else {
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
        }
      });
    console.log(question);
  }

  // Set all the vals for a moderator question
  buildModQuestion() {
    let q = new AteAnswer();
    q.Email = this.modNickname;
    q.Question = this.modQuestion;
    q.CategoryID = this.expertEvent.DivisionID;
    q.ExpertID = this.expertEvent.ExpertID;
    q.ClientName = 'team';
    q.OriginalLang = this.expertEvent.Language;
    q.ArchiveChatID = this.expertEvent.EventID;
    q.QuestionType = this.modComment ? AteQuestionType.ModComment : AteQuestionType.Question;
    return q;
  }

  //Send user question to expert
  sendQuestionToExpert(data) {
    this.hubConnection.send('SendQuestionToExpert', data);
  }

  //Method to post question/data to livechat page for all the users
  postToLivechat(data) {
    this.hubConnection.send('PostToLivechat', data);
  }

  //Remove question from the livechat
  removeQuestionFromLiveChat(questionId) {
    this.hubConnection.send('RemoveQuestionFromLiveChat', questionId);
  }

  // // Add Moderator comment to Livechat Question
  //TODO send whole question object with eventid so Hub can decide connection id and groupname
  // addCommentOnQuestion(questionId, comment) {
  //   this.hubConnection.send('AddCommentOnQuestion', questionId, comment);
  // }

  //Update question on livechat
  updateQuestion(data) {
    this.hubConnection.send('UpdateQuestion', data);
  }
  //Refresh restored questions on livechat
  refreshQuestion(data) {
    this.hubConnection.send('RefreshQuestions', data);
  }

  private registerOnServerEvents(): void {
    this.hubConnection.on('OnConnected', (message: any) => {
      console.log(message);
    });

    this.hubConnection.on('LiveChatStatus', (status: boolean) => {
      console.log("LiveChatStatus", status);
    });
    //Receive count of connected users
    this.hubConnection.on('ReceiveConnectedUsers', (connectedUsers: number) => {
      console.log("ReceiveConnectedUsers", connectedUsers);
      this.connectedUsersText = connectedUsers;
    });
    //receive posted qustion from the livechat
    this.hubConnection.on('ReceiveUserQuestion', (connexQuestion: AteQuestion) => {
      console.log("Received User Question" + connexQuestion);
      console.log(connexQuestion);
      this.questions.unshift(connexQuestion as AteAnswer);
      this.sortQuestions();
    });
    this.hubConnection.on('RefreshQuestions', (connexQuestion?: AteQuestion) => {
      console.log("Refresh Questions");
      const index = this.questions.findIndex(x => x.QuestionID === connexQuestion.QuestionID);
      if (index > -1) {
        this.questions[index] = (connexQuestion as AteAnswer);
        this.sortQuestions();
      }
    });
    this.hubConnection.on('ReceiveQuestionFromExpert', (question: AteQuestion) => {
      console.log("Received question from the Expert");
      console.log(question);
      // Expert comment added
      if (question.Status === AteQuestionStatus.Answered && question.QuestionType === AteQuestionType.ExpertComment) {
        this.questions.unshift(question as AteAnswer);
        this.sortQuestions();
        console.log("expert comment added");
        return;
      }
      const index = this.questions.findIndex(x => x.QuestionID === question.QuestionID);
      if (index > -1) {
        this.questions[index] = question as AteAnswer;
        this.sortQuestions();
      }
    });
    this.hubConnection.onreconnecting(reconnecting => {
      console.log(reconnecting);
      this.hubConnected = false;
    });

    this.hubConnection.onclose(closed => {
      console.log(closed);
      this.hubConnected = false;
    });
    this.hubConnection.onreconnected(reconnected => {
      console.log(reconnected); this.hubConnected = true;
    });
  }

  addPreloadedText(data) {
    // Get the event time from the formatted date string
    const splitVal = this.expertEvent.Language === 'FR' ? 'à ' : 'at ';
    momenttz.locale(this.expertEvent.Language);
    const eventTime = this.expertEvent.Language === 'FR' ? momenttz(this.expertEvent.ChatStartTime).tz("America/Toronto").format('H [h] mm z') : momenttz(this.expertEvent.ChatStartTime).tz("America/Toronto").format('h:mmA z');
    let upcomingEventTime = '';
    if (this.upcomingMarathonChat) {
      upcomingEventTime = this.expertEvent.Language === 'FR' ? momenttz(this.upcomingMarathonChat.StartTime).tz("America/Toronto").format('H [h] mm z') : momenttz(this.upcomingMarathonChat.StartTime).tz("America/Toronto").format('h:mmA z');
    }
    // Replace any potential text variables with specific chat data
    data = data
      .replace('[FullName]', this.expertEvent.Expert.ExpertNameFormatted)
      .replace('[FirstName]', this.expertEvent.Expert.FirstName)
      .replace('[LastName]', this.expertEvent.Expert.LastName)
      .replace('[ShortBio]', this.expertEvent.Expert.ShortBio)
      .replace('[EventTime]', eventTime)
      .replace('[NextSessionName]', this.upcomingMarathonChat ? this.upcomingMarathonChat.Title : '')
      .replace('[NextExpertName]', this.upcomingMarathonChat ? this.upcomingMarathonChat.Expert.ExpertNameFormatted : '')
      .replace('[NextSessionStartTime]', upcomingEventTime);

    this.questionEditor.setContent(data);
  }

  // gets data for following event in marathon which will be used as variables in moderator's preloaded text
  loadNextMarathonEvent(id: number): void {
    if (id > 0) {
      this._connexService.getEventsByMarathonId(id).subscribe(response => {
        for(let i = 0; i < response.length; i++) {
          // check if current chat is preceded by another marathon chat
          if (response[i].EventID == this.expertEvent.EventID && (i !== response.length)) {
            this.upcomingMarathonChat = response[i + 1];
          }
        }
      });
    }
  }

  addExpertBlogPost(id) {
    let post = this.expertBlogPosts.find(p => p.ID === parseInt(id));
    console.log(post);
    if (post) {
      post.FeaturedImagePathFull = this._emsConfig.assetPath + '/ImageAssets/resized/blogs/featuredimages/larg/' + post.FeaturedImagePath;
      const postHtml = this.buildExpertBlogPostHtml(post);
      this.questionEditor.setContent(postHtml);
    }
  }

  buildExpertBlogPostHtml(post: any) {
    let html = `<div class="ate-posted ate-posted--blog">
                  <div class="ate-posted__intro">[PostedResourceIntro]</div>
                  <div class="ate-posted__content ate-posted__content--blog" id="[BlogAssetKey]" data-blog-id="[BlogId]" data-blog-assetkey="[BlogAssetKey]" data-blog-expertid="[ExpertId]">
                    <div class="ate-posted__img" style="background-image: url('[imgSrc]');">&nbsp;</div>
                    <div class="ate-posted__text">
                      <span class="ate-posted__categories">[Categories]</span>
                      <span class="ate-posted__title">[Title]</span>
                      <span class="ate-posted__excerpt">[Excerpt]</span>
                      <span class="ate-posted__expert">[Expert]</span>
                      <span class="ate-posted__expertbio">[ExpertBio]</span>
                    </div>
                  </div>
                </div>`;

    html = html.replace('[imgSrc]', post.FeaturedImagePathFull)
      .replace('[Title]', post.PostTitle)
      .replace('[Excerpt]', post.PostExcerpt)
      .replace('[BlogId]', post.ID.toString())
      .replace('[BlogAssetKey]', post.AssetKey)//replace assetkey
      .replace('[Expert]', post.ExpertName)
      .replace('[ExpertId]', post.ExpertId.toString())
      .replace('[ExpertBio]', post.ExpertBio)
      .replace('[Categories]', post.CategoriesString ? post.CategoriesString : '')
      .replace('[PostedResourceIntro]', this.expertEvent.Language === 'FR' ? this._emsConfig.text.EMS_Content.ATE_Posted_Resource_Intro_FR : this._emsConfig.text.EMS_Content.ATE_Posted_Resource_Intro);

    return html;
  }

  buildVideoIframe(module, volume) {
    let html = `<div class="ate-posted ate-posted--video">
                  <div class="ate-posted__intro">[PostedResourceIntro]</div>
                  <div class="ate-posted__content ate-posted__content--video">
                    <div class="ate-posted__iframe">
                      <iframe src="/video/iframe/[AssetId]?livechat=true&pageType=livechat&hideinfo=true" tabindex="-1"></iframe>
                    </div>
                    <div class="ate-posted__text">
                      <span class="ate-posted__categories"></span>
                      <span class="ate-posted__title">[Title]</span>
                      <span class="ate-posted__expert">[ExpertNameFormatted]</span>
                      <span class="ate-posted__expertbio">[ShortBio]</span>
                    </div>
                  </div>
                </div>`;

    html = html.replace('[AssetId]', module.ID)
      .replace('[PostedResourceIntro]', this.expertEvent.Language === 'FR' ? this._emsConfig.text.EMS_Content.ATE_Posted_Resource_Intro_FR : this._emsConfig.text.EMS_Content.ATE_Posted_Resource_Intro)  
      .replace('[Title]', module.Title)
      .replace('[ExpertNameFormatted]', volume?.Expert?.ExpertNameFormatted)
      .replace('[ShortBio]', volume?.Expert?.ShortBio);          

    return html;
  }

  //Block user from entering 'Admin' user name
  onNicknameChange(nickname: string): void {
    if (nickname.toLowerCase() === 'admin') {
      this._popupService.updateNotification({ message: 'this nickname is not allowed', error: true });
      this.validInput = false;
    } else {
      this.validInput = true;
    }
  }

  ngAfterViewInit() {
    tinymce.init({
      selector: '#ate-question-submit',
      entity_encoding: 'raw',
      plugins: 'link code lists preview autolink',
      toolbar:
        'code | undo redo | styles | fontsize | bullist numlist | bold italic underline | link unlink',
      font_size_formats: '13px 16px 18px 20px',
      style_formats: [
        { title: 'Headings', items: [
          { title: 'Heading 2', format: 'h2' },
          { title: 'Heading 3', format: 'h3' },
          { title: 'Heading 4', format: 'h4' },
          { title: 'Paragraph', format: 'p' }
        ]},
        { title: 'Styles', items: [
          { title: 'Bold', format: 'bold' },
          { title: 'Italic', format: 'italic' },
          { title: 'Underline', format: 'underline' }
        ]}],
      browser_spellcheck: true,
      min_height: 200,
      menubar: false,
      inline_styles: true,
      forced_root_block: false,
      default_link_target: '_blank',
      content_css: '/assets/editor-content.css',
      extended_valid_elements: 'script[language|type|src]'
    });
  }

  ngOnDestroy() {
    tinymce.remove(this.questionEditor);
  }
}

<h1>Webchats</h1>

<div class="webchat-container__header-section">
  <div class="webchat-container__sort-container sort-container">
    <div class="sort-container__searchbar">
      <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
      <input class="searchbar__input" [(ngModel)]="ateSearch" (ngModelChange)="usingSearchBar($event)" type="search"
        placeholder="Search webchats">
    </div>


    <div class="sort-container__list">
      <p class="ls-body-2">Sort</p>
      <ul class="sort-container__options">
        <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'all'}"
          (click)="resetFilters()">All</li>
        <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'editing'}"
          (click)="updateWebchatFilters('editing')">Editing</li>
        <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'ready'}"
          (click)="updateWebchatFilters('ready')">Ready</li>
        <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'for_marathon'}"
          (click)="updateWebchatFilters('for_marathon')">For Marathon</li>
      </ul>
    </div>
  </div>
  <button class="btn--fab b-dark-blue btn--ate ate-container__fab" (click)="createNewEvent()">
    <svg viewBox="0 0 24 24">
      <path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
  </button>
</div>

<table class="display-table ate-update display-table--extra-padding">
  <thead>
    <tr>
      <th>ID</th>
      <th>Date</th>
      <th>Title</th>
      <th>Expert</th>
      <th>Type</th>
      <th>Status</th>
      <th>Transcript</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let event of events | webchatSearchFilter: ateSearch | webchatStatusFilter: ateStatus"
      [ngClass]="{'event-live-streaming': event.StreamLive === true}">
      <td>{{event.EventID}}</td>
      <td>{{trimDate(event.ChatStartTime)}}</td>
      <td class="title-size edit-link" [routerLink]="['/asktheexpert/event-edit', event.EventID]">
        {{event.EventTopic}}</td>
      <td>{{event.Expert.ExpertNameFormatted}}</td>
      <td>
        <span *ngIf="event.For_Marathon">Marathon</span>
        <span *ngIf="!event.For_Marathon">Webchat</span>
      </td>
      <td>
        <span class="event-status-button editing-status-button" *ngIf="!event.IsTranslated">Editing</span>
        <span class="event-status-button ready-status-button" *ngIf="event.IsTranslated">Ready</span>
      </td>
      <td style="text-align: center;">
        <a href="javascript:void(0);" (click)="downloadWebChatTranscript(event.EventID,event.EventTopic)">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="#BBBBBB"
              d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z" />
          </svg>
        </a>
      </td>
    </tr>
  </tbody>
</table>


<!--modal for displaying information about a given sync task-->
<div class="modal-window sync-description-modal" *ngIf='modalOpen' (click)="closeModal()">
  <div class="modal-container b-white" (click)="stopProp($event)">
    <div class="modal__title-container b-dark-blue">
      <h3 class="modal__title ls-headline f-white">Transcript</h3>
      <button class="welcome-page-modal__close modal__close" (click)="closeModal()">
        <svg viewBox="0 0 24 24">
          <path
            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            fill="#ffffff"></path>
        </svg>
      </button>
    </div>
    <div class="modal__settings-container sync-descripton-text-container">
      <div class="sync-description-table-container">
        <div class="asset-sync-table-container">
          <h3>{{transcriptTitle}}</h3>
          <div class="ate-transcript" *ngFor="let item of answersForTranscript">
            <p><strong>Question</strong></p>
            <p>{{item.Question.English}}</p>
            <p><strong>Answer</strong></p>
            <p> {{item.Answer.English}}</p>
            <hr /><br /><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="section-padding">
  <h1> CMS Reports</h1>

  <!--message to indicate that most of the reports (aside from expert review) are in PowerBI, with a link to new report-->
  <div style="margin-top:10px" [innerHTML]="powerBIMessage"></div>

    <table class="display-table" style="margin-top:50px">
      <thead>
        <tr>
          <th>Report Name</th>
          <th>Report Description</th>
          <th>Download Report</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of cmsReports">
          <td>{{report.ReportDisplayName}}</td>
          <td>{{report.ReportDescription}}</td>
          <td align="center">
            <button (click)="getCMSReport(report.APIEndpoint,report.ReportName)">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="#BBBBBB" d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z"
                />
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

// Angular things
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

// Connex related
import { ConnexRoutingModule } from './connex-routing.module';

// ATE edit related
import { ATEListComponent } from './ate/ate-list.component';
import { ATEEditComponent } from './ate/ate-edit.component';
import { ATEEventEditComponent } from './ate/ate-event-edit.component';
import { ATEModerationComponent } from './ate/ate-moderation.component';
import { ModifyATEQuestionComponent } from './ate/modify-ate-question.component';
import { ATEModInbox } from './ate/ate-mod-inbox.component';
import { ATEModPosted } from './ate/ate-mod-posted.component';
import { ATEModTrash } from './ate/ate-mod-trash.component';
import { AteExpertComponent } from './expert/ate-expert.component';
import { ATEExpertAnswered } from './expert/ate-expert-answered.component';

// Third Party
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';


import {
  ConnexStatusFilter,
  ConnexLangFilter,
  ConnexExpertLangFilter,
  ATETranslatedFilter,
  ConnexEmergencyRejectionFilter,
  WebchatSearchFilter,
  WebchatStatusFilter,
  BlogSearchFilter
} from './helpers/connex-filters.pipe';

// Shared Module
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [BrowserModule, FormsModule, SharedModule, ConnexRoutingModule, MatSelectModule, MatInputModule], // module dependencies
  declarations: [
    ATEListComponent,
    ATEEditComponent,
    ATEEventEditComponent,
    ATEModerationComponent,
    ModifyATEQuestionComponent,
    ATEModInbox,
    ATEModPosted,
    ATEModTrash,
    AteExpertComponent,
    ATEExpertAnswered,
    ConnexStatusFilter,
    ConnexLangFilter,
    ConnexExpertLangFilter,
    ATETranslatedFilter,
    ConnexEmergencyRejectionFilter,
    WebchatSearchFilter,
    WebchatStatusFilter,
    BlogSearchFilter
  ], // components, pipes and directives
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }] // services
})
export class ConnexModule { }

// Angular Things
import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';

//Components
import { SyncComponent } from './sync.component';
import { OrganizationSyncComponent } from './organization-sync.component';
import { AssetSyncComponent } from 'app/sync/asset-sync.component';

//Security Things
import { AuthGuard } from '../authGuard';
import { AuthGuardSysAdmin } from '../authGuardSysAdmin';


const syncRoutes: Routes = [
  { path: 'sync', component: SyncComponent, canActivate: [AuthGuard],
    children: [
       { path: '', component: OrganizationSyncComponent, canActivate: [AuthGuard] }, //sets default route to volume
       { path: 'organization', component: OrganizationSyncComponent, canActivate: [AuthGuard] }, //sets default route to volume
       { path: 'lifespeakasset', component: AssetSyncComponent, canActivate: [AuthGuard] },
     ]
 },
];

@NgModule({
  imports: [
    RouterModule.forChild(syncRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class SyncRoutingModule { }

export class VolumeDetailList {
  DivisionID: number;
  AdditionalDivisionIDs: number[];
  ID: any;
  ImageUrl: string;
  Language: string;
  OnPoint: boolean;
  SortOrder: number;
  Expert: Object;
  ExpertID: number;
  VolumeDescription: string;
  VolumeTitle: string;
  ResourceUrl: any;
  DateCreated: any;
  HasCaptions: boolean;
  AnnualReviewDate: any;
  MatchedVolume: any;
  Inventory: any;
  AdditionalCategoryUpdate: boolean;
  InventoryUpdate: any;
  EMSStatus: string;
  LibraryItemName:string;
  CategoryID: number;
  IncludeInContentFusion:boolean;
}

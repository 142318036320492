<!--default display (shows when not selected)-->
<div *ngIf="!IsActive">
  <a  href="javascript:void(0);" (click)="ActivateDropdown()">{{keyword}}</a>
</div>

<!--tag list dropdown (retrieved from module keywords) -->
<div *ngIf="IsActive">
  <select style="width:200px" [(ngModel)]="keywordToken" (ngModelChange)="UpdateTag($event)" class="setting__select-input select-input">
  <option *ngFor="let tagItem of tagList"  value="{{tagItem.Keyword_Token}}">{{tagItem.Keyword }}</option>
  </select>
</div>
 
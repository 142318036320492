import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { EmsConfig } from './shared/emsConfig';
import { Observable } from 'rxjs';
import { SecuredRoutes } from 'app/shared/secured-routes';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private emsConfig: EmsConfig, private securedRoutes: SecuredRoutes) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const parentRoute = route.parent;
    let urlStr;
    // if the parent route exists we also want to add that to the url so we can check access for the user
    if (parentRoute.url.length > 0) {
      urlStr = parentRoute.url.map(token => token.path).join(' ') + route.url.map(token => token.path).join(' ');
    } else {
      urlStr = route.url.map(token => token.path).join(' ');
    }

    if (this.emsConfig.loginToken?.IsValid) {
      if (this.emsConfig.UserRole === 'sysadmin') {
        return true;
      }
      // ***IMPORTANT: Checking if Role === connexpert must be above the currentuserhasaccess else if***
      if (this.emsConfig.emsDomain == 'expert' || this.emsConfig.emsDomain == 'expert5') {
       //to avoid the issue of circular auth check and navigation, we need to check if the query param is already set to true
       //then let the expert page load or else set the query param to true and navigate to the expert page
        if (route.queryParams['q'] !== undefined || route.queryParams['q'] === 'true') {
          return true;
        } else {
          this.router.navigate(['/asktheexpert/expert'], { queryParams: { q: 'true' } });
        }
      } else if (this.securedRoutes.CurrentUserHasAccess(urlStr, true)) {
        return true;
      } else if (this.emsConfig.UserRole === 'siteadmin') {
        // check for any possible routes the user has access to
        const authorizedRoute = this.securedRoutes.FindAvailableRouteByPermission();
        if (authorizedRoute !== undefined) {
          this.router.navigate(['/' + authorizedRoute.route]);
        }
      }
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    }

    return false;

  }
}

<div class="subcategory subcategory-container section-padding">
  <div class="subcategory-container_subcategory-list">
    <div class="subcategory-filters-container">
      <div class="sort-container__searchbar">
        <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        <input class="searchbar__input" [(ngModel)]="subcategorySearch" type="search" placeholder="Search for subcategory">
      </div>
      <div class="filters-cotainer_select-check">
        <div class="sort-container__select">
          <label for="category-select">Category Filter:</label>
          <select name="category-select" id="category-select" [(ngModel)]="selectedCategory">
            <option [value]="-1">Select Parent Category</option>
            <option [value]="item.ID" *ngFor="let item of categoryList" [innerHTML]="item.Name.English"></option>
          </select>
        </div>
        <div class="sort-container__checkbox">
            <input name="isLive" id="isLive" [(ngModel)]="isLive" type="checkbox">
            <label for="isLive">Only show subcategories visible on site</label>
        </div>
      </div>
    </div>
    <table class="display-table display-table--extra-padding">
      <thead>
        <tr>
          <th>Subcategory</th>
          <th>Parent Category</th>
          <th>Visible on Site</th>
          <th>Number of Assets</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let s of subCategories| subcategorySearchFilter: subcategorySearch | subcategoriesByCategory: selectedCategory | subcategoryIsLive: isLive">
          <td class="text-add text-btn edit-btn" (click)="onSelect(s.ID)">{{s.Name}}</td>
          <td>{{s.CategoryName}}</td>
          <td>{{s.IsLive? 'Yes': 'No'}}</td>
          <td>{{s.AssetCount}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <button class="btn--fab b-dark-blue cms-container__fab cms-container__fab--add" title="Create new Subcategory"
    (click)="createNewSubcategory()">
    <svg viewBox="0 0 24 24">
      <path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
  </button>
</div>
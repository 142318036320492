import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from './spinner.service';

@Component({
    selector: 'ajax-spinner',
    templateUrl: 'spinner.component.html'
})
export class SpinnerComponent implements OnDestroy {
  public spinnerActive = false;
  public subscription: Subscription;

  constructor(private _spinnerService: SpinnerService) {
    // subscribe to the spinner service so we can recieve info from it
    this.subscription = _spinnerService.spinnerData$.subscribe(
      data => {
        this.toggleSpinner(data.toggle);
      }
    );
  }

  toggleSpinner(toggle: boolean): void {
    this.spinnerActive = toggle;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

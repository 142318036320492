// Angular Things
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsService } from 'app/accounts/accounts.service';
import { BlogService } from 'app/blog/blog.service';
import { Categories } from 'app/categories/categories';
import { CategoriesService } from 'app/categories/categories.service';
import { ConnexService } from 'app/connex/connex.service';
import { LearningHubService } from 'app/services/learninghub.service';
import { ConfirmService } from 'app/shared/confirm.service';
import { ViewType } from 'app/shared/lifespeakViewType';
import { Playlist } from 'app/shared/objects/playlist';
import { PlaylistAsset } from 'app/shared/objects/playlistAsset';
import { PopupService } from 'app/shared/popup.service';
import { VolumeDetailItem } from 'app/shared/VolumeDetailItem';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
declare var tinymce: any;

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';

//  Services
import { CmsService } from '../cms.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'cms-learninghub-details', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'learninghub-details.component.html'
})

export class CmsLearningHubDetailsComponent implements OnInit, OnDestroy {
  @HostBinding('class') classAttribute: string = 'playlists-details';

  public showEditButton: boolean;
  public showAddEdit: boolean;
  public activeFilter: string;
  public statusFilter: string;
  public search: string;
  public playlistAssetsDetails: PlaylistAsset[] = [];
  public assetsType = ViewType;
  public playlist: Playlist;
  public AssetTypes: any = [{ ID: 1, TileType: 'Video' }, { ID: 2, TileType: 'Tipsheet' }, { ID: 5, TileType: 'ATE' }, { ID: 7, TileType: 'BlogPost' }];
  public tipsheets: any = [];
  public ateArchives: any = [];
  public blogs: any = [];
  public selectedAssets: PlaylistAsset[] = [];
  public categories: Categories[] = [];

  public volumes: any[] = [];
  public modules: any[] = [];
  public addPlaylist: boolean;
  public imageLoaded: boolean;
  public imageHasBaseDropZoneOver: boolean;

  public imgPreviewUrl: string;
  public showImgPreview: boolean = false;
  private _confirmSubscription: Subscription;

  public playlistImageUploader: FileUploader = new FileUploader({ url: this.emsConfig.apiEndpoint + '/assetAdmin/uploadplaylistimage' });
  constructor(private route: ActivatedRoute,
    private _router: Router,
    public emsConfig: EmsConfig,
    private _cmsService: CmsService,
    private _playlistService: LearningHubService,
    private _categoriesService: CategoriesService,
    private _blogService: BlogService,
    private _confirmService: ConfirmService,
    private _popupService: PopupService,
    private _ateService: ConnexService,
    private _accountService: AccountsService) { }

  ngOnInit(): void {
    this._confirmSubscription = this._confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.data.action === 'addUpdatePlaylist' && response.confirmed) {
          this._addUpdatePlaylist();
        }
      }
    );

    this.route.params.subscribe(params => {
      console.log(params);
      let playlistID = params.id;
      if (playlistID == -1) {
        this.addPlaylist = true;
        let playList: Playlist = { ID: -1, Lang: 'EN', Description: ' ', Status: 'active' };
        this.playlist = playList;
        this.onFilterLang('EN');
        this.addAsset();
        return;
      } else {
        this.addPlaylist = false;
      }
      this._playlistService.getPlayListDetails(playlistID).subscribe(details => {
        console.log(details);
        this.setUpDetails(details);
      });
      this._imageUploaderSetup();
    });
  }

  private _imageUploaderSetup() {
    this.playlistImageUploader.options.headers = this.emsConfig.getFileUploadHeaders();
    // gets the response after the upload is completed
    this.playlistImageUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const responsePath = response;
      console.log(item);
      if (!item.isSuccess) {
        this._popupService.updateNotification({ message: 'Image has not been uploaded. Please try again.', error: true });
        this.uploadingGifRemove();
      } else {
        this.checkImageStatus();
        this._popupService.updateNotification({ message: 'Image successfully uploaded.', success: true });
        if (this.addPlaylist) {
          this.playlist.ImageName = this.playlist.ImageName ? this.playlist.ImageName : this.playlist.Assetkey;
        }
        this.uploadingGifRemove();
      }
    };
  }

  private setUpDetails(details: any) {

    this.playlist = details.playList as Playlist;
    this.playlist.Description ? tinymce.get('description-text-area').setContent(this.playlist.Description) : tinymce.get('description-text-area').setContent('');
    this.playlist.FullDescription ? tinymce.get('fulldescription-text-area').setContent(this.playlist.FullDescription) : tinymce.get('fulldescription-text-area').setContent('');

    //hide category and volums options and oly show videos drop down.
    if (this.playlist.AssetsDetails.length > 0) {
      this.playlist.AssetsDetails?.forEach(asset => {
        if (asset.AssetTypeID === ViewType.Video) {
          asset.HideCategoryOption = true;
          asset.HideVolumesOption = true;
          asset.Modules = [{ ID: asset.AssetID, Title: asset.Title }];
        }

      });
    }

    this.selectedAssets = this.playlist.AssetsDetails;

    this.onFilterLang(this.playlist.Lang);
    this.checkImageStatus();
  }
  // Event to update the data model once the user finishes dragging
  drop(event: CdkDragDrop<PlaylistAsset[]>) {
    moveItemInArray(this.selectedAssets, event.previousIndex, event.currentIndex);
  }
  fileOverBaseImage(e: any): void {
    this.imageHasBaseDropZoneOver = e;
    this._validateFileTypeImage();
    // by default, the file uploader plugin requires credentials for submission, this remove them
    this.playlistImageUploader.queue.forEach(file => file.withCredentials = false);
    this.playlistImageUploader.options.additionalParameter = { 'playlistid': this.playlist.ID };
  }

  previewImage(): void {
    let imgUrl = '';

    // use the correct image preview path
    if (this.imageLoaded) {
      imgUrl = this.emsConfig.assetPath + '/ImageAssets/originals/learninghub/' + this.playlist.ImageName;
    } else {
      imgUrl = '';
    }

    this.imgPreviewUrl = imgUrl;
    console.log(this.imgPreviewUrl);
    this.showImgPreview = true;
  }

  clearModuleImage(): void {
    this.imageLoaded = false;
    this.playlist.ImageName = null;
  }

  removeImageFromAWS(imageName): void {

    let imgFullUrl = '';
    // use the correct image preview path
    if (this.imageLoaded) {
      imgFullUrl = '/ImageAssets/originals/learninghub/' + this.playlist.ImageName;
    } else {
      imgFullUrl = '';
    }
    console.log(imgFullUrl);
    this._cmsService.removeAwsProcessedFileFromAWS(imgFullUrl)
      .subscribe(
        data => {
          console.log(data);
          if (data.succeeded) {
            this._popupService.updateNotification({ message: 'Image successfully removed from AWS.', success: true });
            this.checkImageStatus();
          } else {
            this._popupService.updateNotification({ message: 'Image was not removed from AWS. Please try again.', error: true });
          }
        },
        err => console.log(err),
      );
  }

  uploadingGifAdd(): void {
    const container = document.querySelector('body');
    container.classList.add('request-in-progress');
  }

  uploadingGifRemove(): void {
    const container = document.querySelector('body');
    container.classList.remove('request-in-progress');
  }

  checkImageStatus(): void {
    // urls for queue and upload folders
    const moduleImageUrl = this.emsConfig.assetPath + '/ImageAssets/originals/learninghub/' + this.playlist.ImageName;
    // check if resized and uploaded
    this.imageExists(moduleImageUrl);
  }

  imageExists(url): void {
    const img = new Image();
    // set the image loaded variable to either show or hide image upload dropzone
    img.onload = () => {
      if (url.toLowerCase().indexOf('/originals') !== -1) {
        this.imageLoaded = true;
        console.log('imageLoaded is ' + this.imageLoaded);
      }
    };
    img.onerror = () => {
      if (url.toLowerCase().indexOf('/originals') !== -1) {
        this.imageLoaded = false;
        console.log('imageLoaded is ' + this.imageLoaded);
      }
    };

    img.src = url;
  }

  closeClientsModal() {
    console.log("close");
  }

  addAsset() {
    let newAsset: PlaylistAsset = { ID: -1, PlaylistID: this.playlist.ID, AssetTypeID: 0, AssetID: 0 };
    this.selectedAssets.push(newAsset);
  }

  updateImageUrl($event): void {
    console.log($event);
  }

  closePreviewModal(): void {
    this.showImgPreview = false;
  }

  selectVideo(asset) {
    console.log(asset);
    asset.HideCategoryOption = true;
    asset.HideVolumesOption = true;
  }

  //reload all assets on language change
  onFilterLang(lang) {
    // this.getAllClients();
    this._getCategories();
    this._getBlogPosts();
    this._getTipsheets();
    this._getAteArchives();
  }

  getVolumes(asset, categoryID: number) {
    asset.Volumes = []
    let videos = []

    this._cmsService.getAllVolumes(false, this.playlist.Lang, null, -1, true)
      .subscribe(volumes => {
        videos = volumes.filter(volume => {
          return categoryID ? volume.CategoryID == categoryID : volume
        })
        asset.Volumes.push(...videos);
      })
  }

  getVideosForVolume(asset, id: number): void {
    if (!id)
      return;
    this._cmsService.getEmsVolume(id, true)
      .subscribe(module => {
        console.log(module);
        asset.Modules = module.media;
      });
  }

  deleteAsset(assetIndex) {
    this.selectedAssets.splice(assetIndex, 1);
  }

  confirmAction(action: string, message: string): void {
    this._confirmService.updateNotification({ action: action, message: message });
  }



  private _getCategories(): void {
    if (this.categories.length < 1) {
      this._categoriesService.getAllCategories()
        .subscribe((categories: any) => {
          this.categories.push(...categories?.categories);
        });
    }
  }

  private _getBlogPosts() {
    //Get prod blogs
    this._blogService.getBlogPosts(this.playlist.Lang, true, true, 'alpha').subscribe(blogs => {
      this.blogs = blogs;
    })
  }

  private _getAteArchives() {
    //Get prod ates
    this._ateService.getAllExpertEvents(null, null, true).subscribe(ates => {
      var ateArchives = ates.filter(x => (!x.For_Marathon && x.IsTranslated && x.Language == this.playlist.Lang));
      this.ateArchives = ateArchives;
    })
  }

  private _getTipsheets() {
    this._cmsService.getTipsheets(this.playlist.Lang).subscribe(tipsheets => {
      this.tipsheets = tipsheets;
    })
  }

  private _addUpdatePlaylist() {

    this.selectedAssets.forEach((asset, index) => {
      asset.SortOrder = index;
    })

    this.playlist.AssetsDetails = this.selectedAssets;
    this.playlist.Description = this.unEntity(tinymce.get('description-text-area').getContent());
    this.playlist.FullDescription = this.unEntity(tinymce.get('fulldescription-text-area').getContent());

    if (!this._validateDetails()) {
      this._popupService.updateNotification({ message: 'Please fillout all the required fields.', error: true });
      return;
    }

    this._playlistService.addUpdatePlaylist(this.playlist).subscribe(result => {
      console.log(result);
      if (this.addPlaylist) {
        this._router.navigate(['/cms/learninghub', result.ID]);
      }
      if (result.ID > 0) {
        this._popupService.updateNotification({ message: this.emsConfig.text.EMS_General.Popup_Success, success: true });
      } else {
        this._popupService.updateNotification({
          message: 'Something went wrong! Update failed. Please refresh and try again.',
          error: true
        });
      }
    }, err => console.log(`Update error`, err));
  }

  private _validateDetails(): boolean {
    let emptyContent = false;
    this.playlist.AssetsDetails.forEach(asset => {
      if (!asset.AssetID || asset.AssetID === 0) {
        emptyContent = true;
        return false;
      }
    })
    if (emptyContent) return false;
    if (!this.playlist.Title) return false;
    if (!this.playlist.Subtitle) return false;
    if (!this.playlist.Description) return false;
    if (!this.playlist.Lang) return false;
    // if (this.participatingClients.length <= 0) return false;
    //if all of the above conditions are valid
    return true;
  }

  // make sure the current item is a jpg file. Otherwise remove from the queue
  private _validateFileTypeImage(): void {
    const currentItem = this.playlistImageUploader.queue[0] as any;
    console.log(currentItem);
    if (currentItem !== undefined && currentItem.file.name.indexOf('.jpg') === -1) {
      alert('Only JPG files are permitted for Image upload');
      this.playlistImageUploader.queue.pop();
      this.emsConfig.getFileUploadHeaders();
    } else if (currentItem !== undefined) {
      // renames file to match the playlist url/image url
      if (this.addPlaylist) {
        currentItem.file.name = this.playlist.Assetkey + '.jpg';
        this.playlist.ImageName = currentItem.file.rawFile?.name;
        currentItem.file.name = currentItem.file.rawFile?.name;
      } else {
        currentItem.file.name = this.playlist.Assetkey + '.jpg';
        this.playlist.ImageName = this.playlist.Assetkey + '.jpg';
      }
    }
  }

  // =======================================================
  // characters: &,>,< will be stored in non-entity form
  // =======================================================
  unEntity(text: string) {
    text = text.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    return text;
  }

  ngAfterViewInit() {
    tinymce.init({
      selector: '#description-text-area',
      plugins: 'link code lists preview',
      toolbar:
        'code | undo redo | styles fontsize | forecolor | bullist numlist | bold | link unlink | alignleft aligncenter',
      font_size_formats: '10px 12px 14px 16px 18px 20px 24px',
      browser_spellcheck: true,
      min_height: 200,
      menubar: false,
      inline_styles: true,
      content_style: 'body {font-size: 14px;}',
      color_map: [
        '000000', 'Black',
        '666666', 'Gray',
        '238469', 'Green'
      ],
      custom_colors: false,
      formats: {
        heading: { block: 'h3', styles: { fontWeight: '700', fontSize: '14px', lineHeight: '1.2', letterSpacing: '0.09px' } },
      },
      style_formats: [
        { title: 'Heading', format: 'heading' },
      ]
    });

    tinymce.init({
      selector: '#fulldescription-text-area',
      plugins: 'link code lists preview',
      toolbar:
        'code | undo redo | styles fontsize | forecolor | bullist numlist | bold | link unlink | alignleft aligncenter',
      font_size_formats: '10px 12px 14px 16px 18px 20px 24px',
      browser_spellcheck: true,
      min_height: 450,
      menubar: false,
      inline_styles: true,
      content_style: 'body {font-size: 14px;}',
      color_map: [
        '000000', 'Black',
        '666666', 'Gray',
        '238469', 'Green'
      ],
      custom_colors: false,
      formats: {
        heading: { block: 'h3', styles: { fontWeight: '700', fontSize: '14px', lineHeight: '1.2', letterSpacing: '0.09px' } },
      },
      style_formats: [
        { title: 'Heading', format: 'heading' },
      ]
    });
  }

  ngOnDestroy(): void {
    this._confirmSubscription.unsubscribe();
    tinymce.remove(tinymce.get('description-text-area'));
    tinymce.remove(tinymce.get('fulldescription-text-area'));
  }
}

<div class="cms-iq-container section-padding">
  <div class="iq-container__sort-container sort-container">
    <div class="sort-container__searchbar">
      <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
      <input class="searchbar__input" [(ngModel)]="iqSearch" type="search" placeholder="Search for iQ">
    </div>
  </div>

  <div class="iq-container__quiz-list card-container">
    <div class="iq-card card" (click)="onSelect()" 
    *ngFor="let quiz of allQuizzes | iqSearchFilter:iqSearch" 
    (blur)="deselectIq($event)" (focus)="selectIq($event)" 
    data-id="{{quiz.ID}}" tabindex="-1">
      <h4 class="iq-card__title ls-subheading f-dark-blue">{{quiz.Title}}</h4>
    </div>
  </div>
</div>
import { AccountsService } from './../../accounts/accounts.service';
import { PopupService } from 'app/shared/popup.service';
import { Subscription } from 'rxjs';
import { ConfirmService } from 'app/shared/confirm.service';
import { Account } from './../../accounts/account';

// Angular Things
import { Component, HostBinding, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';
import { UtilitiesService } from '../../shared/utilities.service';

//  Services
import { CmsService } from '../cms.service';
import { PollJson, Option } from './../../shared/pollJson';
import * as moment from 'moment';

@Component({
  selector: 'cms-poll-details', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-polls-details.component.html'
})

export class CmsPollDetailsComponent implements OnInit {
  @HostBinding('class') classAttribute: string = 'cms-polls-details';

  @Input() poll: PollJson[];



  public editPoll: PollJson;
  sub: any;

  public currentPollType: string;
  public optionsEN: Option[] = [];
  public optionsFR: Option[] = [];
  public isActivePoll: boolean;
  public confirmSubscription: Subscription;
  public clientSearchVal: string = '';
  public clientList: Account[];
  public poll_id: number = -1;
  constructor(private router: Router,
    private route: ActivatedRoute,
    public _cmsService: CmsService,
    private _confirmService: ConfirmService,
    public _emsConfig: EmsConfig,
    public _accountService: AccountsService,
    public _popupService: PopupService,
    public _utilities: UtilitiesService) {

    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (response.data.action === 'submitPoll') {
            this.submitPoll(response.data.poll);
          } else if (response.data.action === 'deletePoll') {
            // this.deletePoll(response.data.poll);

          }
        }
      });
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.poll_id = params['poll'];
      if (this.poll_id == -1) {
        this.editPoll = this.creteEmptyPoll();
      } else {
        this.getPollbyID(this.poll_id);
      }
      this.getAccountList();
    });
  }

  getPollbyID = (pollId) => {
    this._cmsService.getPollbyID(pollId).subscribe(poll => {
      console.log(poll);
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      this.editPoll = poll;
      if (new Date(this.editPoll.Start_Date.toString()).getTime() === today.getTime()) {
        this.isActivePoll = true;
      }
      this.editPoll.Poll_Details = JSON.parse(this.editPoll.Poll_Details.toString());
      this.optionsEN = this.editPoll.Poll_Details.Poll.Language['EN'][0].Questions[0].Options;
      this.optionsFR = this.editPoll.Poll_Details.Poll.Language['FR'][0].Questions[0].Options;
      this.optionsEN = this.checkOptionArraySize(this.optionsEN);
      this.optionsFR = this.checkOptionArraySize(this.optionsFR);
      this.currentPollType = this.editPoll.Global_poll ? 'global' : 'specific';
      console.log('getpollbyid', this.editPoll);
    });

  }

  checkOptionArraySize = (options: Option[]): Option[] => {
    if (options.length === 4) {
      console.log('this array is good', options);
    } else {
      for (var i = options.length + 1; i <= 4; i++) {
        var optn = new Option();
        optn.ID = i;
        optn.Option = '';
        options.push(optn);
      }
    }
    return options;
  }

  onEvent(event: any) {
    event.stopPropagation();
  }

  //Get list of accounts to assign client specific poll
  getAccountList = () => {
    this._accountService.getAllAccounts().subscribe(accounts => {
      this.clientList = accounts;
      console.log(this.clientList);
    });
  }

  //Submit poll
  submitPoll = (poll) => {
    console.log(poll);
    if (this._utilities.isBlank([poll.Poll_Details.Poll.Language['EN'][0].Questions[0].Question])) {
      this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Required, error: true });
      return;
    }
    this.addUpdatePoll(poll);
  }


  //Add update poll
  addUpdatePoll = (poll) => {
    let newpoll: PollJson;
    this.deleteEmptyProp(this.optionsEN);
    this.deleteEmptyProp(this.optionsFR);
    this._cmsService.addUpdatePoll(poll).subscribe(_poll => {
      newpoll = _poll;
      newpoll.Poll_Details = JSON.parse(newpoll.Poll_Details.toString());
      console.log(newpoll);
      this.editPoll = newpoll;
    });
  }
  //Create empty poll with valid json format
  creteEmptyPoll = (): PollJson => {
    let poll = new PollJson();
    var today = new Date();

    poll.Date_Created = moment(new Date()).format('YYYY-MM-DDTHH:mm');
    poll.Start_Date = moment(new Date(today.getFullYear(), today.getMonth(), today.getDay(), 0)).format('YYYY-MM-DDTHH:mm');
    poll.End_Date = moment(new Date(today.getFullYear(), today.getMonth(), today.getDay() + 7, 0)).format('YYYY-MM-DDTHH:mm');
    poll.Poll_ID = -1;
    poll.Global_poll = true;
    poll.Poll_Details = JSON.parse('{"Poll":{"Colour":"#FEBE10","Language":{"EN":[{"Description":"TAKE THE LIFESPEAK POLL!","ID":0,"Questions":[{"ID":0,"Question":"","Options":[{"Rank":1,"ID":1,"Option":""},{"Rank":2,"ID":2,"Option":""},{"Rank":3,"ID":3,"Option":""},{"Rank":4,"ID":4,"Option":""}]}]}],"FR":[{"Description":"RÉPONDEZ AU SONDAGE LIFESPEAK !","ID":0,"Questions":[{"ID":0,"Question":"","Options":[{"Rank":1,"ID":1,"Option":""},{"Rank":2,"ID":2,"Option":""},{"Rank":3,"ID":3,"Option":""},{"Rank":4,"ID":4,"Option":""}]}]}]}}}');
    this.optionsEN = poll.Poll_Details.Poll.Language['EN'][0].Questions[0].Options;
    this.optionsFR = poll.Poll_Details.Poll.Language['FR'][0].Questions[0].Options;
    this.currentPollType = poll.Global_poll ? 'global' : 'specific';
    return poll;
  }

  deleteEmptyProp = (options) => {
    //  //delete empty properties
    for (var i = 0; i < options.length; i++) {
      if (options[i].Option.trim() === "") {
        //delete options[i];
        options.splice(i, 1);
        i--
      }
    }
  }

  confirmAction(action: string, message?: string, poll?: PollJson): void {
    this._confirmService.updateNotification({ action: action, message: message, poll: poll });
  }
  changePollType(value: any) {
    //Refresh marathon schedulelist if lang changes
    if (value) {
      this.editPoll.Global_poll = (value === 'global') ? true : false;
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.confirmSubscription.unsubscribe();
  }
}

<div class="cms-volumes-container section-padding">
  <div class="all-volumes-container">
    <div class="get-connected__lang-button-container lang-button-container">
      <button class="btn--toggle get-connected__lang-switch-button lang-switch-button"
        [ngClass]="{'active': lang === 'EN'}" (click)="setLang('EN')">
        {{_emsConfig.text?.EMS_General.EN}}
      </button>
      <button class="btn--toggle get-connected__lang-switch-button lang-switch-button"
        [ngClass]="{'active': lang === 'FR'}" (click)="setLang('FR')">
        {{_emsConfig.text?.EMS_General.FR}}
      </button>
    </div>

    <div class="volumes-container__sort-container sort-container">
      <div class="sort-container__searchbar">
        <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        <input class="searchbar__input" [(ngModel)]="volumeSearch" (ngModelChange)="usingSearchBar($event)"
          type="search" placeholder="Search for volumes">
      </div>


      <div class="sort-container__list">
        <p class="ls-body-2">Sort</p>
        <ul class="sort-container__options">
          <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'all'}"
            (click)="resetFilters()">All</li>
          <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'live'}"
            (click)="updateVolumeFilters('live')">Active</li>
          <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'audio_only'}"
            (click)="updateVolumeFilters('audio_only')">Audio Only</li>
          <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'testing'}"
            (click)="updateVolumeFilters('testing')">Testing</li>
          <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'retired'}"
            (click)="updateVolumeFilters('retired')">Retired</li>
          <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'qa'}"
            (click)="updateVolumeFilters('qa')">QA</li>
        </ul>
      </div>
    </div>

    <div class="volumes-container__volume-list card-container">
      <div class="volume-card card" [ngClass]="{onPoint: volume.OnPoint}" (click)="onSelect()"
        *ngFor="let volume of allVolumes | volumeSearchFilter:volumeSearch | statusFilter:volumeStatus"
        data-id="{{volume.ID}}" (blur)="deselectVolume($event)" (focus)="selectVolume($event)" tabindex="-1">
        <div class="volume-card__image" *ngIf="!volume.OnPoint"
          [style.background-image]="'url(' + _emsConfig.assetPath + '/ImageAssets/originals/volumeimagesfeatured/' + volume.ImageUrl + '.jpg)'">
        </div>
        <div class="volume-card__text-container">
          <h4 class="volume-card__title ls-body-2 f-dark-blue" [innerHTML]="volume.VolumeTitle"></h4>
          <h5 class="volume-card__speaker ls-body-2" [innerHtml]="volume.Expert"></h5>
          <h5 class="volume-card__status ls-body-2 status-active" *ngIf="volume.EMSStatus === 'live'">Active</h5>
          <h5 class="volume-card__status ls-body-2 status-{{volume.EMSStatus}}" *ngIf="volume.EMSStatus != 'live'">
            {{volume.EMSStatus | titlecase}}</h5>
        </div>
        <div class="volume-card__button btn btn--svg b-dark-blue ls-button-1 uppercase" *ngIf="volume.OnPoint">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 67">
            <circle fill="#FFFFFF" cx="33.5" cy="27.5" r="8.1" />
            <path fill="#FFFFFF"
              d="M67,33.5C67,15,52,0,33.5,0S0,15,0,33.5c0,9.3,3.8,17.7,9.8,23.7L0,67l32.8,0	c0.2,0,1.1,0,1.4,0l0.1,0l0,0C52.4,66.6,67,51.7,67,33.5z M45.4,31.7c-0.1,0.3-0.3,0.7-0.4,1l-1,1.7l-4.5,7.8l-6,10.4l-6-10.4	L23,34.3l-1-1.7c-0.2-0.3-0.3-0.7-0.4-1c-0.5-1.4-0.8-2.9-0.8-4.5c0-7,5.7-12.7,12.7-12.7s12.7,5.7,12.7,12.7	C46.2,28.8,45.9,30.3,45.4,31.7z" />
          </svg>
        </div>
      </div>
    </div>
  </div>

  <button class="btn--fab b-dark-blue cms-container__fab cms-container__fab--add" *ngIf="!showEditButton" title="Create new volume"
    (click)="createNewVolume()">
    <svg viewBox="0 0 24 24">
      <path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
  </button>
</div>

import { Component, HostBinding, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { EmsConfig } from '../../shared/emsConfig';
import { ConfirmService } from '../../shared/confirm.service';
import { PopupService } from '../../shared/popup.service';
import { ConnexService } from '../connex.service';
import { UtilitiesService } from '../../shared/utilities.service';

// Connex objects
import { AteAnswer } from '../Objects/AteAnswer';
import { AteQuestionStatus } from '../Objects/AteQuestionStatus';
import { TextItem } from 'app/shared/TextItem';

//signalr service and lib
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { WebSocketsService } from 'app/connex/ate/websockets.service';
import { AteQuestionType } from '../Objects/AteQuestionType';
import { ExpertEvent } from 'app/shared/ExpertEvent';

declare var tinymce: any;

@Component({
  selector: 'modify-ate-question',
  templateUrl: 'modify-ate-question.component.html',
  providers: [ConnexService]
})
export class ModifyATEQuestionComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @HostBinding('class') classAttribute: string = 'modify-question';
  @Input() activeQuestion: AteAnswer;
  @Input() expertEvent: ExpertEvent;

  @Output() deactivateModal = new EventEmitter();
  @Output() updateVals = new EventEmitter();

  public confirmSubscription: Subscription;
  public langStr: string = 'English';
  public hubConnection: HubConnection;

  // need a seperate value to copy the input var by value (not by reference)
  public question: AteAnswer = new AteAnswer();
  public qTypes: any[] = [1, 2, 4];
  public relatedMarathonChats: any[] = [];
  public showReassign: boolean = false;
  public relatedChatID: number = -1;

  // editor variables
  public questionEditor: any;
  public answerEditor: any;
  private validInput: boolean;
  constructor(
    private route: ActivatedRoute,
    public _emsConfig: EmsConfig,
    private _connexService: ConnexService,
    private _confirmService: ConfirmService,
    private _popupService: PopupService,
    private _utilities: UtilitiesService,
    private hubConnectionService: WebSocketsService
  ) { }

  onEvent(event: any) {
    event.stopPropagation();
  }

  toggleEditWindow(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('no-scroll');
    this.questionEditor.setContent('');
    this.answerEditor.setContent('');
    this.deactivateModal.emit({});
  }

  confirmAction(action: string, message: string): void {
    this._confirmService.updateNotification({ action: action, message: message });
  }

  updateQuestion() {
    this.question.Question = this.questionEditor.getContent();
    this.question.Answer = this.answerEditor.getContent();

    if (!this.formValid(this.question))
      return;

    this._connexService.modifyATEQuestion(this.question)
      .subscribe(response => {
        if (response.QuestionID !== -1) {
          this.updateVals.emit(response);
          this.sendHub(response);
          this.toggleEditWindow();
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Success, success: true });
        } else {
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
        }
      });
  }

  // update the connex question AND send to expert
  updateAndSend(status: number, reassigned: boolean = false): void {
    // create a deep copy of the question and update the status to pending
    const questionToSend = JSON.parse(JSON.stringify(this.question));
    questionToSend.Question = this.questionEditor.getContent();
    questionToSend.Answer = this.answerEditor.getContent();

    if (!this.formValid(questionToSend))
      return;

    questionToSend.Status = status;

    // If this is a comment (any question type higher than 1)
    // Then post directly to the chat
    // If question is getting reassigned to another related marathon chat, DO NOT change it's status
    if (questionToSend.QuestionType > 1 && !reassigned) {
      questionToSend.Status = 4;
    }

    this._connexService.modifyATEQuestion(questionToSend)
      .subscribe(response => {
        if (response.QuestionID !== -1) {
          // on success close the modal and alert the user
          this.updateVals.emit(response);
          this.toggleEditWindow();
          this.sendHub(response);
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Success, success: true });
        } else {
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
        }
      });
  }

  getSendStatus(question: AteAnswer) {
    let status = 2;
    switch (question.QuestionType) {
      case AteQuestionType.ModComment:
      case AteQuestionType.ExpertComment:
      case AteQuestionType.UserComment:
        status = 4;
        break;
    }

    return status;
  }

  // Check if the ate modify question modal is valid before submitting
  formValid(q: AteAnswer) {
    let valid = true;

    // ensure nickname and question aren't blank
    if (this._utilities.isBlank([q.Email, q.Question]))
      valid = false;

    // ensure the answer isn't blank (comments excluded)
    if (q.Status > AteQuestionStatus.Pending && this._utilities.isBlank([q.Answer]) && q.QuestionType < 2)
      valid = false;

    if (!valid)
      this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Required, error: true });

    return valid;
  }


  sendHub(question: AteAnswer) {
    if (question.Status === AteQuestionStatus.Pending) {
      // Send user question to expert
      this.hubConnection.send('SendQuestionToExpert', question);
      this.hubConnection.send('PostToLivechat', question);
    }
    if (question.Status === AteQuestionStatus.Sent) {
      this.hubConnection.send('UpdateQuestion', question);
    }
    if (question.Status === AteQuestionStatus.Pending || question.Status === AteQuestionStatus.Sent || question.Status === AteQuestionStatus.Answered) {
      this.hubConnection.send('UpdateQuestion', question);
    }
  }

  onNicknameChange(nickname: string): void {
    if (nickname.toLowerCase() === 'admin') {
      this._popupService.updateNotification({ message: 'this nickname is not allowed', error: true });
      this.validInput = false;
    } else {
      this.validInput = true;
    }
  }

  loadRelatedMarathonChats(id: number): void {
    if (this.relatedMarathonChats.length === 0) {
      this._connexService.getEventsByMarathonId(id)
        .subscribe(response => {
          console.log('load up related marathon chats');
          console.log(response);
          // filter out the current before assignment
          this.relatedMarathonChats = response.filter(x => x.EventID !== this.expertEvent.EventID);
        });
    }
  }

  toggleReassign(id) {
    this.showReassign = id == -1 ? false : true;
  }

  // reassign and move the question to another chat within a marathon series
  reassignQuestion() {
    console.log(this.relatedChatID);
    this.question.ArchiveChatID = this.relatedChatID;
    this.updateAndSend(1, true);
  }

  ngOnChanges(): void {
    if (this.activeQuestion !== undefined) {
      // need to assign a new object (copy by value), otherwise there are issues with the original object when values change in the modal
      this.question = JSON.parse(JSON.stringify(this.activeQuestion));
      this.langStr = this.question.OriginalLang === 'FR' ? 'French' : 'English';
      console.log('marathonID: ' + this.expertEvent.MarathonScheduleId);
      console.log(this.question);

      // load marathon related chats if this is marathon event
      if (this.expertEvent.MarathonScheduleId !== 0 && this.expertEvent.MarathonScheduleId !== -1) {
        this.loadRelatedMarathonChats(this.expertEvent.MarathonScheduleId);
      }

      if (!this.question.Answer || !this.question.Answer) {
        this.question.Answer = "";
      }

      // Question data needs to be fully loaded before setting tiny editor content
      if (this.question) {        
        this.questionEditor = tinymce.get('ate-question-editor');
        if (this.questionEditor != null) {
          this.questionEditor.setContent(this.question.Question);
        }
        this.answerEditor = tinymce.get('ate-answer-editor');
        if (this.answerEditor != null) {
          this.answerEditor.setContent(this.question.Answer);
        }
      }
    }
  }

  hideAnswerField(q): boolean {
    // hide for questions until they are ready to post
    if (q.QuestionType === AteQuestionType.Question && q.Status < 3) {
      return true;
    }

    // hide for mod or expert comments
    if (q.QuestionType === AteQuestionType.ModComment || q.QuestionType === AteQuestionType.ExpertComment) {
      return true
    }

    return false;
  }

  getQuestionType(id: number) {
    let qType = 'Question';
    if (id === AteQuestionType.ModComment) {
      qType = 'Mod comment';
    } else if (id === AteQuestionType.ExpertComment) {
      qType = 'Expert comment';
    } else if (id === AteQuestionType.UserComment) {
      qType = 'User comment';
    }

    return qType;
  }

  async ngOnInit() {
    try {
      this.hubConnection = this.hubConnectionService.getConnection();
    } catch (err) {
      console.log(" Signalr connection error " + err);
    }
  }

  ngAfterViewInit() {
    tinymce.init({
      selector: '#ate-answer-editor',
      entity_encoding: 'raw',
      plugins: 'link code lists preview',
      toolbar:
        'code | undo redo | styles | fontsize | bullist numlist | bold italic underline | link unlink',
      font_size_formats: '13px 16px 18px 20px',
      style_formats: [
        { title: 'Headings', items: [
          { title: 'Heading 2', format: 'h2' },
          { title: 'Heading 3', format: 'h3' },
          { title: 'Heading 4', format: 'h4' },
          { title: 'Paragraph', format: 'p' }
        ]},
        { title: 'Styles', items: [
          { title: 'Bold', format: 'bold' },
          { title: 'Italic', format: 'italic' },
          { title: 'Underline', format: 'underline' }
        ]}],
      browser_spellcheck: true,
      min_height: 180,
      menubar: false,
      inline_styles: true,
      default_link_target: '_blank',
      setup: function(editor) {
        editor.on('BeforeSetContent', function(ContentEvent) {
          // TODO - WEB-960 - leave commented
          // console.log("AteAnswerEditor", ContentEvent)
          // ContentEvent.content = ContentEvent.content.replace(/\r?\n/g, '<br/>')
        })
      }
    });

    tinymce.init({
      selector: '#ate-question-editor',
      entity_encoding: 'raw',
      plugins: 'link code lists preview autolink',
      toolbar:
        'code | undo redo | styles | fontsize | bullist numlist | bold italic underline | link unlink',
      font_size_formats: '13px 16px 18px 20px',
      style_formats: [
        { title: 'Headings', items: [
          { title: 'Heading 2', format: 'h2' },
          { title: 'Heading 3', format: 'h3' },
          { title: 'Heading 4', format: 'h4' },
          { title: 'Paragraph', format: 'p' }
        ]},
        { title: 'Styles', items: [
          { title: 'Bold', format: 'bold' },
          { title: 'Italic', format: 'italic' },
          { title: 'Underline', format: 'underline' }
        ]}],
      browser_spellcheck: true,
      min_height: 180,
      menubar: false,
      inline_styles: true,
      default_link_target: '_blank',
      content_css: '/assets/editor-content.css',
      extended_valid_elements: 'script[language|type|src]',
      setup: function(editor) {
        editor.on('BeforeSetContent', function(ContentEvent) {
          console.log("AteQuestionEditor", ContentEvent)
          // if question is from user, /n needs to be replaced with <br> tags before arriving to editor in order to preserve intential line breaks from user questions
          // TODO - WEB-960 - leave uncommented
          // if (this.question && this.question.QuestionType == 1) {
            ContentEvent.content = ContentEvent.content.replace(/\r?\n/g, '<br/>')
          // }
        })
      }
    });
  }

  ngOnDestroy(): void {
    tinymce.remove(this.questionEditor);
    tinymce.remove(this.answerEditor);
  }

}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DragulaService } from 'ng2-dragula';
import { MatFormField } from '@angular/material/form-field';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CmsService } from 'app/cms/cms.service';
import { timingSafeEqual } from 'crypto';
import { ConfirmService } from 'app/shared/confirm.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { BlogService } from 'app/blog/blog.service';
import { EmsConfig } from 'app/shared/emsConfig';
import { CampaignAsset, CampaignDetails, CampaignSection } from '../../campaign-details';
import { UtilitiesService } from 'app/shared/utilities.service';
import { ConnexService } from 'app/connex/connex.service';
import { MessagesService } from 'app/messages/messages.service';
import { ClientCampaignBuild } from 'app/shared/objects/ClientCampaignBuild';
import { FileUploader } from 'ng2-file-upload';
import { SnackbarService } from 'app/services/Snackbar.service';

@Component({
  selector: 'app-cms-campaign-details',
  templateUrl: './cms-campaign-details.component.html'
})
export class CmsCampaignDetailsComponent implements OnInit, OnDestroy {
  private sub: any;
  campaignDetails: CampaignDetails;

  assets: any = [];
  categories: any = [];
  volumes: any = [];
  videos: any = [];
  blogposts: any = [];
  tipsheets: any = [];
  allAteEvents: any = [];
  filteredAteEvents: any = []; // ATE Events filtered by language

  linkedCampaigns: any = [];

  settingsForm: FormGroup;

  // Asset dropdown variables
  showBlogs: boolean = false;
  showVideos: boolean = false;
  showTipsheets: boolean = false;

  // variables for Confirm Services
  confirmData: {};
  confirmCampaignData: any;
  confirmSubscription: Subscription;
  confirmAction: string;
  disableBuildBtn: boolean;
  public dragDropModelSubscription: Subscription;

  @ViewChild('formItem') formItem: ElementRef;
  formItemEl: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _dragula: DragulaService,
    private _cmsService: CmsService,
    private _confirmService: ConfirmService,
    private _blogService: BlogService,
    public _emsConfig: EmsConfig,
    private _utilitiesService: UtilitiesService,
    private _connexService: ConnexService,
    private _messagesService: MessagesService,
    private _snackBar: SnackbarService
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'addUpdateCampaignDetails') {
            this.submitCampaignDetails();
          }
          if (this.confirmAction === 'bulkBuildMessages') {
            this.bulkBuildMessages();
          }
        }
      }
    )

    this._dragula.createGroup('contents', {});

    // function to run after drop event has occurred
    this.dragDropModelSubscription = _dragula.dropModel("contents")
      .subscribe(({ name, el, target, source, sibling, sourceModel, targetModel, item }) => {
        if (name === 'contents') {
          console.log(this.campaignDetails.CampaignSections);
          this.setAssetSortOrder();
        }

      })
  }

  setAssetSortOrder() {
    // set timeout is needed here or else sort order does not update properly & reverts back to original order on save
    setTimeout(() => {
      for (let i = 0; i < this.campaignDetails.CampaignSections.length; i++) {
        for (let j = 0; j < this.campaignDetails.CampaignSections[i].Details.length; j++) {
          this.campaignDetails.CampaignSections[i].Details[j].SortOrder = j + 1;
        }
      }

      // save on order update (as part of auto-saving)
      this.submitCampaignDetails();
    }, 0);
  }

  // check for duplicates in all sections of the campaign when adding new asset
  checkForDupe(sectionIndex: number, assetId: number, assetTypeId: number): void {
    for (let i = 0; i < this.campaignDetails.CampaignSections.length; i++) {
      // need to deep clone array to avoid altering the source section assets which is used to update the UI
      let sectionAssets = [...this.campaignDetails.CampaignSections[i].Details];
      if (sectionIndex === i) {
        // remove asset that is being added so it doesn't get checked for dupe
        sectionAssets.splice(sectionAssets.length - 1, 1);
      }

      let dupeIndex = sectionAssets.findIndex((e) => e.AssetID === assetId && e.AssetTypeId === assetTypeId);

      if(dupeIndex !== -1) {
        this._snackBar.warning('Warning: This asset has already been added to the campaign. Please choose another.');
        return;
      }
    }
  }

  // ============================
  // confirm modal stuff
  // ============================
  openConfirmDialog(
    component: string,
    action: string,
    campaignData: any,
    message: string
  ): void {
    this.confirmAction = action;
    this.confirmCampaignData = campaignData;
    this.confirmData = { component: component, message: message };

    this._confirmService.updateNotification(this.confirmData);
  }

  // submit campaign details
  submitCampaignDetails(): void {
    this.campaignDetails.Title = this.settingsForm.value.CampaignName;
    this.campaignDetails.LanguageCode = this.settingsForm.value.CampaignLanguage;
    this.campaignDetails.CampaignStatus = this.settingsForm.value.CampaignStatus;
    this.campaignDetails.StartDate = moment(this.settingsForm.value.StartDate).format('YYYY-MM-DD');
    this.campaignDetails.EndDate = moment(this.settingsForm.value.EndDate).format('YYYY-MM-DD');
    this.campaignDetails.DefaultPreviewDate = moment(this.settingsForm.value.PreviewDate).format('YYYY-MM-DD');
    this.campaignDetails.RelatedEvent.EventID = this.settingsForm.value.RelatedEvent;
    // Need to explicitly set LinkCampaignId for English campaigns. Otherwise it returns blank (because we hide linking Campaigns on the English side)
    this.campaignDetails.LinkCampaignId = this.settingsForm.value.LinkedCampaign ? this.settingsForm.value.LinkedCampaign : -1;
    // TODO: Uncomment when we figure out what we'd like to do for the homepage tiles
    // campaign.HomepageTile = this.settingsForm.value.FeaturedTile;

    // NOTE: Amy requested we remove this check so she can create just the campaign shell without any content. Can add back if needed. It is understood that this will result in broken state on the site.
    // if (!this.settingsForm.valid || !this.validateContentForm()) {
      //   this._snackBar.error('Please fill out the required fields (*) and at least one Content section.');

    // settings and content form validity checks
    if (!this.settingsForm.valid) {
      this._snackBar.error('Please fill out the required fields (*).');
      // checking if preview date is before, or the same date as start date
    } else if (moment(this.campaignDetails.DefaultPreviewDate).isAfter(this.campaignDetails.StartDate)) {
      this._snackBar.error('Preview date must be before or the same date as the campaign start date.');
    } else {
      this._cmsService.insertUpdateCampaign(this.campaignDetails)
        .subscribe(
          data => {
            console.log(data);
            if (this.campaignDetails.ID === -1) {
              this.campaignDetails.ID = parseInt(data);
              this.router.navigate(['cms/campaigns/' + this.campaignDetails.ID]);
            }

            // on successful save get a fresh copy of the campaign from the database
            this.getCampaignDetails(this.campaignDetails.ID);
            this._snackBar.success('Campaign saved');

          },
          (res) => {
            this._snackBar.error(this.setErrorMessage(res.error));
          }
        )
    }
  }

  // setting error messages for certain scenarios
  setErrorMessage(errorMsg: string): string {
    // when a linked campaign is already set
    if (errorMsg.includes('link-already-set')) {
      return 'Cannot set linked Campaign as it is already set for another Campaign';
      // when bulkbuild is already set for another campaign
    } else if (errorMsg.includes('bulk-already-set')) {
      return 'Another Campaign is already set to Bulkbuild status'
    } else {
      return this._emsConfig.text.EMS_General.Popup_Error;
    }
  }

  // Content Settings Template-Driven Form validation
  // Originally set up as a reactive FormArray, but was difficult to loop through both Campaign Sections and Assets (details), so opted for Template-Driven
  validateContentForm(): boolean {
    // checks if a campaign section is created/exists
    if (this.campaignDetails.CampaignSections.length === 0) {
      return false;
    }

    // Looping through Campaign Sections array to check if Title is blank, and assets (Details) are added to sections
    this.campaignDetails.CampaignSections.forEach(section => {
      if (this._utilitiesService.isBlank([section.Title])) {
        return false;
      } else if (section.Details.length === 0) {
        return false;
      }

      // second loop through Details array to check if AssetID and AssetTypeId is blank
      section.Details.forEach(detail => {
        if (this._utilitiesService.isBlank([detail.AssetID, detail.AssetTypeId])) {
          return false;
        } else if (detail.AssetID == -1 && detail.AssetTypeId == -1) { // -1 indicates that asset has not yet been selected
          return false;
        }
      })
    });

    return true;
  }

  // Grabbing list of ATE events related to campaign
  getRelatedAteEvents(lang) {
    this._connexService.getAllExpertEvents(null)
      .subscribe(events => {
        this.allAteEvents = events.filter(x => !x.For_Marathon);
        this.filteredAteEvents = this.allAteEvents.filter(x => x.Language === lang);
      })
  }

  getAssetType(lang: string) {
    this._cmsService.getAssetType(lang)
      .subscribe(
        data => {
          this.assets = data;
        }
      ),
      err => {
        console.log(err);
      }
  }

  getCategories(lang: string) {
    this._cmsService.getCategories(lang)
      .subscribe(
        data => {
          this.categories = data;
        }
      ),
      err => {
        console.log(err)
      }
  }

  categoryChange(id: number, sectionIndex: number, assetIndex: number) {
    this.campaignDetails.CampaignSections[sectionIndex].Details[assetIndex].AssetCategory = id;
    if (!this.volumes[id]) {
      this.getVolumes(id, this.settingsForm.value.CampaignLanguage);
    }
  }

  getVolumes(categoryId: number, lang: string) {
    this._cmsService.getVolumes(categoryId, lang).subscribe(data => {
      this.volumes[categoryId] = data;
    }),
      err => {
        console.log(err);
      }
  }

  volumeChange(id: number, sectionIndex: number, assetIndex: number) {
    this.campaignDetails.CampaignSections[sectionIndex].Details[assetIndex].AssetVolume = id;
    if (!this.videos[id]) {
      this.getVideos(id);
    }
  }

  getVideos(volumeId: number) {
    this._cmsService.getEmsVolume(volumeId, true)
      .subscribe(
        data => {
          this.videos[volumeId] = data.media;
          console.log("Videos:")
          console.log(this.videos);
        }
      ),
      err => {
        console.log(err);
      }
  }

  getCampaignBlogposts(lang: string) {
    this._blogService.getBlogPosts(lang)
      .subscribe(
        data => {
          this.blogposts = data;
        }
      ),
      err => {
        console.log(err);
      }
  }

  getCampaignTipsheets(lang: string) {
    this._cmsService.getTipsheets(lang)
      .subscribe(
        data => {
          this.tipsheets = data;
        }
      ),
      err => {
        console.log(err);
      }
  }

  getCampaignsForLinking() {
    this._cmsService.getCampaignsForLinking()
      .subscribe(
        data => {
          this.linkedCampaigns = data;
          console.log("Linkable Campaigns: ", this.linkedCampaigns);
        }
      ),
      err => {
        console.log(err);
      }
  }

  // Get campaign asset data by language.
  // Ex: If a user selects French for campaign language we want to get all of the French assets for building a campaign
  languageChange(lang: string) {
    this.campaignDetails.LanguageCode = lang;
    this.getAssetData(lang);
  }

  // Add new asset to draggable section
  addAsset(section: CampaignSection) {
    const asset: CampaignAsset = new CampaignAsset();
    // check how many assets in section & add 1 to get the sort order (fixes bug where changing the order of assets sometimes caused new assets to have inconsistent an order on save)
    let assetCount = section.Details.length;
    asset.SortOrder = assetCount + 1;
    section.Details.push(asset);
  }

  // Will delete a campaign asset or entire section (on create or saved status)
  deleteItem(campaignId: number, id: number, isCampaignSection: boolean, sectionIndex: number, assetIndex: number = -1) {
    // -1 indicates that the campaign is being created and/or not yet saved
    if (id == -1) {
      if (isCampaignSection) {
        this.campaignDetails.CampaignSections.splice(sectionIndex, 1);
      } else {
        // Deletes campaign assets
        this.campaignDetails.CampaignSections[sectionIndex].Details.splice(assetIndex, 1);
      }
    } else {
      // if user deletes while editing an existing campaign, it will call the delete method and reget the campaign details
      this._cmsService.deleteCampaignSectionDetail(campaignId, id, isCampaignSection)
        .subscribe(
          response => {
            this.getCampaignDetails(this.campaignDetails.ID);

            if (isCampaignSection) {
              this._snackBar.success("Campaign Section has been successfully deleted");
            } else {
              this._snackBar.success("Campaign Asset has been successfully deleted");
            }
          },
          err => {
            console.log(err);
            if (isCampaignSection) {
              this._snackBar.error("Campaign Section was not deleted");
            } else {
              this._snackBar.error("Campaign Asset was not deleted");
            }
          })
    }
  }

  // Adds a new campaign section when editing or creating a new campaign
  addSection() {
    const section: CampaignSection = new CampaignSection();
    this.campaignDetails.CampaignSections.push(section);
  }

  getCampaignDetails(id: number) {
    this._cmsService.getCampaignDetails(id)
      .subscribe(
        data => {
          this.campaignDetails = data;
          console.log('Campaign Details: ', this.campaignDetails);

          this.getAssetData(this.campaignDetails.LanguageCode);

          // Setting General Settings reactive form field values
          this.settingsForm.setValue({
            CampaignName: this.campaignDetails.Title,
            CampaignLanguage: this.campaignDetails.LanguageCode,
            CampaignStatus: this.campaignDetails.CampaignStatus,
            StartDate: new Date(this.campaignDetails.StartDate),
            EndDate: new Date(this.campaignDetails.EndDate),
            PreviewDate: new Date(this.campaignDetails.DefaultPreviewDate),
            RelatedEvent: this.campaignDetails.RelatedEvent.EventID,
            LinkedCampaign: this.campaignDetails.LinkCampaignId
          })
          this.disableBuildBtn = moment(this.campaignDetails.EndDate).isBefore(Date());
        }
      )
  }

  bulkBuildMessages() {
    if (this.campaignDetails.ID < 1) {
      return;
    }
    const campaignBuild = new ClientCampaignBuild();
    campaignBuild.CampaignId = this.campaignDetails.ID;
    campaignBuild.BulkBuild = true;
    this._messagesService.buildCampaignMessages(campaignBuild)
      .subscribe(data => {
        this._snackBar.success('Messages successfully generated!');
      },
        (error) => {
          this._snackBar.error('Something went wrong! Messages have not been generated. Please try again.');
        });
  }

  // retrieves data for Content Settings Form & ATE Events for General Settings form when language code is set
  getAssetData(lang: string) {
    this.getAssetType(lang);
    this.getCategories(lang);
    this.getCampaignBlogposts(lang);
    this.getCampaignTipsheets(lang);
    this.getRelatedAteEvents(lang);
  }

  ngOnInit(): void {
    // gets the section id from the url
    this.campaignDetails = new CampaignDetails();

    this.sub = this.route.params.subscribe(params => {
      this.campaignDetails.ID = parseInt(params['id']);
    });

    if (this.campaignDetails.ID !== -1) {
      this.getCampaignDetails(this.campaignDetails.ID);
    } else {
      // Set default values when creating new campaign
      this.campaignDetails.LanguageCode = "EN";
      this.campaignDetails.LinkCampaignId = -1;
      this.getAssetData("EN");
    }

    this.getCampaignsForLinking();

    // General Settings Reactive Form
    this.settingsForm = this.fb.group({
      CampaignName: ['', [Validators.required]],
      // Set default campaign Lang
      CampaignLanguage: ['EN', [Validators.required]],
      RelatedEvent: ['', [Validators.required]],
      StartDate: ['', [Validators.required]],
      EndDate: ['', [Validators.required]],
      PreviewDate: ['', [Validators.required]],
      CampaignStatus: [1, [Validators.required]],
      LinkedCampaign: ['']
      // TODO: Uncomment when we decide what we'd like to do with Homepage tiles
      // FeaturedTile: ['', [Validators.required]]
    });
  }

  ngAfterViewInit(): void {
    this.formItemEl = this.formItem;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this._dragula.destroy('contents');
    this.dragDropModelSubscription.unsubscribe();
    this.confirmSubscription.unsubscribe();
  }
}

import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { EmsConfig } from '../../shared/emsConfig';
import { UtilitiesService } from '../../shared/utilities.service';
import { ConnexService } from '../connex.service';
import { PopupService } from '../../shared/popup.service';
import { ConfirmService } from '../../shared/confirm.service';

// Connex objects
import { AteQuestionStatus } from '../Objects/AteQuestionStatus';
import { AteAnswer } from '../Objects/AteAnswer';

declare var tinymce: any;

@Component({
  selector: 'ate-edit',
  templateUrl: 'ate-edit.component.html'
})
export class ATEEditComponent implements OnInit {
  @HostBinding('class') classAttribute: string = 'ate-edit';
  public eventID: number;

  confirmSubscription: Subscription;

  public expertEvent: any = {
    Language: 'EN'
  };

  public langStr = 'English';
  public answers: AteAnswer[] = [];
  // send an answer to the modify question component
  public activeQuestion: AteAnswer = new AteAnswer();
  // By default set status filter to ate editing (6)
  public connexStatus = AteQuestionStatus.ateEditing;
  public connexStatusType = AteQuestionStatus;
  // used to activate the modify question component
  public modalActive = false;

  // editor variables
  public questionEditor: any;
  public answerEditor: any;
  constructor(
    private route : ActivatedRoute,
    public _emsConfig: EmsConfig,
    private _connexService: ConnexService,
    public _utilities: UtilitiesService,
    private _popupService: PopupService,
    private _confirmService: ConfirmService
  ) {
    this.route.params.subscribe(params => {
      this.eventID = params['id'];
    });

    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        console.log(response);
        if (response.confirmed) {
          if (response.data.action === 'importQuestions') {
            this.importQuestions();
          } else if (response.data.action === 'updateQuestion') {
            this.updateQuestion(this.activeQuestion);
          } else if (response.data.action === 'updateQuestionWithStatus') {
            this.updateQuestionWithStatus(this.activeQuestion, response.data.status);
          }
        }
      });
  }

  confirmAction(action: string, message: string, newStatus: number = 1): void {
    this._confirmService.updateNotification({ action: action, message: message, status: newStatus });
  }

  truncate(text: string, length: number): string {

    if (text === null || text === undefined) return '';

    if (text && text.length < length) {
      return text;
    } else {
      const truncateAmt = text.length - length;
      return text.slice(0, -truncateAmt) + '...';
    }
  }

  // get the specific answer to send to the modify question component
  modifyAnswer(id: number): void {
    this.activeQuestion = JSON.parse(JSON.stringify(this.answers.filter(x => x.QuestionID === id)[0]));
    this.modalActive = true;
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');

    // Question data needs to be fully loaded before setting tiny editor content
    if (this.activeQuestion) {
      this.questionEditor = tinymce.get('ate-question-editor');
      if (this.questionEditor != null) {
        this.questionEditor.setContent(this.activeQuestion.Question);
      }
      this.answerEditor = tinymce.get('ate-answer-editor');
      if (this.answerEditor != null) {
        this.answerEditor.setContent(this.activeQuestion.Answer);
      }
    }
  }

  onEvent(event: any) {
    event.stopPropagation();
  }

  toggleEditWindow(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('no-scroll');
    this.modalActive = false;
  }

  // Update an ATE question in the DB
  updateQuestion(q): void {
    // if updating from the modal, get the content from the WYSIWYG editors
    if (this.modalActive) {
      q.Question = this.questionEditor.getContent();
      q.Answer = this.answerEditor.getContent();
    }

    this._connexService.updateQuestionAndAnswer(q)
      .subscribe(succeeded => {
        if (succeeded) {
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Success, success: true });
          // get the updated questions/answers
          this.getAnswersByEventId();
          this.toggleEditWindow();
        } else {
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
        }
      });
  }

  updateQuestionWithStatus(q, status): void {
    q.Status = status;
    this.updateQuestion(q);
  }

  // gets all the questions/answers for a specific ATE event
  getAnswersByEventId(): void {
    this._connexService.getAnswersByChatID(this.eventID)
      .subscribe(response => {
        console.log(response);
        this.answers = response;
      });
  }

  importQuestions(): void {
    this._connexService.importATEQuestions(this.eventID)
      .subscribe(response => {
        console.log('Import event questions');
        if (response) {
          this.getAnswersByEventId();
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Success, success: true });
        } else {
          this._popupService.updateNotification({ message: 'Questions have already been imported!', error: true });
        }
      });
  }

  ngOnInit(): void {
    // get ATE event details
    this._connexService.getExpertEventByID(this.eventID)
      .subscribe(response => {
        console.log(response);
        this.expertEvent = response;
        this.langStr = response.Language === 'FR' ? 'French' : 'English';
      });

    // get questions/answers for event
    this.getAnswersByEventId();
  }


  ngAfterViewInit() {
    tinymce.init({
      selector: '#ate-answer-editor',
      entity_encoding: 'raw',
      plugins: 'link code lists preview autolink',
      toolbar:
        'code | undo redo | styles | fontsize | bullist numlist | bold italic underline | link unlink',
      font_size_formats: '13px 16px 18px 20px',
      browser_spellcheck: true,
      min_height: 180,
      menubar: false,
      inline_styles: true,
      forced_root_block: false,
      default_link_target: '_blank'
    });

    tinymce.init({
      selector: '#ate-question-editor',
      entity_encoding: 'raw',
      plugins: 'link code lists preview autolink',
      toolbar:
        'code | undo redo | styles | fontsize | bullist numlist | bold italic underline | link unlink',
      font_size_formats: '13px 16px 18px 20px',
      style_formats: [
        { title: 'Headings', items: [
          { title: 'Heading 2', format: 'h2' },
          { title: 'Heading 3', format: 'h3' },
          { title: 'Heading 4', format: 'h4' },
          { title: 'Paragraph', format: 'p' }
        ]},
        { title: 'Styles', items: [
          { title: 'Bold', format: 'bold' },
          { title: 'Italic', format: 'italic' },
          { title: 'Underline', format: 'underline' }
        ]}],
      browser_spellcheck: true,
      min_height: 180,
      menubar: false,
      inline_styles: true,
      forced_root_block: false,
      default_link_target: '_blank',
      content_css: '/assets/editor-content.css',
      extended_valid_elements: 'script[language|type|src]',
      setup: function(editor) {
        editor.on('BeforeSetContent', function(ContentEvent) {
          // TODO - WEB-960 - leave commented
          console.log("AteAnswerEditor", ContentEvent)
          // ContentEvent.content = ContentEvent.content.replace(/\r?\n/g, '<br/>')
        })
      }
    });
  }

  ngOnDestroy(): void {
    tinymce.remove(this.questionEditor);
    tinymce.remove(this.answerEditor);
  }

}

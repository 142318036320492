import { TextItem } from '.././shared/TextItem';

export class LanguageItem {
  PageName: String;
  ResourceKey: String;
  ResourceType: TextItem;
  DateCreated: string;
  ID: number;
  SyncKey: number;
}

import { Pipe, PipeTransform } from "@angular/core";
import { ClientCampaign } from "app/accounts/details/account-campaign/clientCampaign";

// // Filter mailing list users by language
@Pipe({
    name: 'campaignClientFilter',
    pure: false
  })
  export class CampaignClientFilter implements PipeTransform {
    transform(value: ClientCampaign[], ...args: string[]): ClientCampaign[] {
      if (value == null) {
        return null;
      }
  
      const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
      return filter ? value.filter((client: ClientCampaign) =>
      client.ClientName.toLocaleLowerCase().indexOf(filter) !== -1) : value;
    }
  }
  
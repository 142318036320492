<div class="subcategory-details-container section-padding">
  <syncstatus [assetID]="id" [tableID]="1031"></syncstatus>
  <div class="subcategory-details-title">
    <button class="btn" [routerLink]="['/cms/subcategories']">Back</button>
    <h3 class="ls-subheading f-dark-blue info-container__heading">Subcategory Details</h3>
  </div>
  <div class="details-container info-container__text-container">
    <div class="info-container__section-split">
      <div class="basic-info__setting">
        <h4 class="basic-info__heading setting__heading ls-subheading">
          <div class="svg-icon svg-baseline">
            <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
              <path d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z" />
            </svg>
          </div>
          Subcategory ID
        </h4>
        <p class="basic-info__text setting__text ls-subheading">{{defaultSubCategory.ID}}</p>
      </div>
      <div class="basic-info__setting">
        <h4 class="basic-info__heading setting__heading ls-subheading">
          <div class="svg-icon svg-baseline">
            <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
              <path d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z" />
            </svg>
          </div>
          Subcategory
        </h4>
        <p class="basic-info__text setting__text ls-subheading">{{defaultSubCategory.Name}}</p>
      </div>
      <div class="basic-info__setting">
        <h4 class="basic-info__heading setting__heading ls-subheading required-field">
          <div class="svg-icon svg-baseline">
            <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
              <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
            </svg>
          </div>
          Parent Category
          <tooltip [tooltipMessage]="'Parent category can only be edited when creating a new subcategory.'">
          </tooltip>
        </h4>
        <div class="select-container">
          <select class="setting__select-input select-input" name="Category" [(ngModel)]="defaultSubCategory.CategoryID"
            [disabled]="id!==-1">
            <option [value]="-1">Select Parent Category</option>
            <option value="{{category.ID}}" *ngFor="let category of categoryList">{{category.Name.English}}</option>
          </select>
        </div>
      </div>
      <div class="translations-table">
        <h4 class="basic-info__heading setting__heading ls-subheading">
          <div class="svg-icon svg-baseline">
            <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
              <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
            </svg>
          </div>
          Available Subcategories
        </h4>
        <div class="table-container">
          <table class="display-table display-table--extra-padding">
            <thead>
              <tr>
                <th>Language</th>
                <th>Subcategory Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let s of subCategoryTranslations">
                <td class="text-lang-name">{{s.LangName}}</td>
                <td class="text-translation">{{s.Name}}</td>
                <td class="text-edit text-btn edit-btn" *ngIf="s.Name != null" (click)="addEditSubCategory(s)">Edit</td>
                <td class="text-add text-btn edit-btn" *ngIf="s.Name === null" (click)="addEditSubCategory(s)">Add</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="info-container__section-split info-container__basic-details">
      <div class="basic-info__setting">
        <h4 class="basic-info__heading setting__heading ls-subheading">
          <div class="svg-icon svg-baseline">
            <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
            </svg>
          </div>
          Visible on Site
          <tooltip [tooltipMessage]="'This setting controls if the subcategory button is visible on the search page.'"></tooltip>
        </h4>
        <div class="basic-info__checkbox-container">
          <div class="checkbox-container">
            <div class="ls-radio">
              <input name="allowGuestPreview" [(ngModel)]="defaultSubCategory.IsLive" type="radio" [value]=true
                (ngModelChange)="updateIsLive()" id="allowTrue">
              <label for="allowTrue">
                <span></span>
              </label>
            </div>
            Yes
          </div>
          <div class="checkbox-container">
            <div class="ls-radio">
              <input name="allowGuestPreview" [(ngModel)]="defaultSubCategory.IsLive" type="radio" [value]=false
                (ngModelChange)="updateIsLive()" id="allowFalse">
              <label for="allowFalse">
                <span></span>
              </label>
            </div>
            No
          </div>
        </div>
      </div>
      <div class="add-new-category-container" *ngIf="updateSubCategory">
        <form #form="ngForm">
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                </svg>
              </div>
              Subcategory Name
            </h4>
            <div class="input-container">
              <input type="text" class="basic-info__text setting__text-input text-input" name="subcategoryName"
                [(ngModel)]="subCategory.Name">
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                </svg>
              </div>
              Subcategory Language
            </h4>
            <div class="input-container">
              <input type="text" class="basic-info__text setting__text-input text-input" name="categoryNameFR"
                [disabled]="true" [(ngModel)]="subCategory.Lang">
            </div>
          </div>

          <div class="form-button-container">
            <button (click)="openConfirmDialog('subCategory', 'addUpdateSubCategory', _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
              type="button" class="btn module-submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
    <div class="delete-button-container">
      <!--todo uncomment the button tag when delete subcategory is implemented-->
      <!--  <button (click)="openConfirmDialog('subCategory', 'deleteSubcategory', _emsConfig.text?.EMS_General.Confirm_Delete_Message)" type="button" class="btn subcategory-delete" [disabled]="defaultSubCategory?.AssetCount>0 || id===-1">
        DELETE SUBCATEGORY
      </button>  -->
      <label class="general-info-text" *ngIf="defaultSubCategory?.AssetCount>0"> * This subcategory is linked to {{defaultSubCategory?.AssetCount}} videos.</label>
    </div>
  </div>
</div>

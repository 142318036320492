import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmsConfig } from '.././shared/emsConfig';
import { UserProfile } from 'app/shared/user-profile';
import { AdminUserDetails } from 'app/shared/objects/adminUserDetails';
import { forkJoin } from 'rxjs';
import { AdminVacationDetails } from 'app/shared/objects/adminVacationDetails';
import { ClientMigrationStatus } from './client-migration-status';


@Injectable()
export class AdminService {

  private _getLSAdminUserListEndpoint = this.emsConfig.apiEndpoint + '/adminusers/getalllifespeakusers';
  private _getLSAdminUserDetailsEndpoint = this.emsConfig.apiEndpoint + '/adminusers/getadminuserdetails';
  private _createLsUserEndpoint = this.emsConfig.apiEndpoint + '/adminusers/createadminuser';
  private _getUserRolesEndpoint = this.emsConfig.apiEndpoint + '/adminusers/getavailableroles';
  private _getAllEmsPermissionsEndpoint = this.emsConfig.apiEndpoint + '/adminusers/getallemspermissions';
  private _getEmailReportTypesEndpoint = this.emsConfig.apiEndpoint + '/adminusers/getemailreporttypes';
  private _updateUserDetailsEndpoint = this.emsConfig.apiEndpoint + '/adminusers/updateuserdetails';
  private _deleteUserEndpoint = this.emsConfig.apiEndpoint + '/adminusers/deleteadminuser?username=';
  private _adminVacationEndpoint = this.emsConfig.apiEndpoint + '/adminusers/gevacationdetailsforuser?username=';
  private _updateUserVacationDetailsEndpoint = this.emsConfig.apiEndpoint + '/adminusers/updateuservacationdetails';


  constructor(private _http: HttpClient, private emsConfig: EmsConfig) {

  }

  getAllCachedAssets(): Observable<any[]> {
    return this._http.get<any[]>(`${this.emsConfig.libraryEndpoint}/cache/getitemsincache`);
  }

  clearEntireLibraryCache(): Observable<any[]> {
    return this._http.get<any[]>(`${this.emsConfig.libraryEndpoint}/cache/clearservercache`);
  }

  getCachedItemsForClient(client: string): Observable<any> {
    return this._http.get<any>(`${this.emsConfig.libraryEndpoint}/cache/getcacheditemsforclient?clientName=${client}`);
  }

  removeCachedItemsForClient(client: string): Observable<any> {
    return this._http.get<any[]>(`${this.emsConfig.libraryEndpoint}/cache/removecacheditemsforclient?clientName=${client}`);

  }

  clearCacheForAsset(asset: string): Observable<any> {
    return this._http.get<any[]>(`${this.emsConfig.libraryEndpoint}/cache/clearcacheforasset?assetToken=${asset}`);
  }

  clearClientCacheForAsset(asset: string,client:string,lang:string): Observable<any> {
  var url=`${this.emsConfig.libraryEndpoint}/cache/clearclientcacheforasset?assetToken=${asset}&client=${client}&lang=${lang}`;
    return this._http.get<any[]>(url);
  }
  //Admin user management methods
  getLsAdminUsers(): Observable<any> {
    return this._http.get<any[]>(`${this._getLSAdminUserListEndpoint}`);
  }

  getLsUserDetails(username: string): Observable<any> {
    username = encodeURIComponent(username);
    return this._http.get<any[]>(`${this._getLSAdminUserDetailsEndpoint}?username=${username}`);
  }

  getLsUserVacationDetails(username: string): Observable<any> {
    username = encodeURIComponent(username);
    return this._http.get<any[]>(`${this._adminVacationEndpoint}${username}`);
  }

  //Get list of all user roles
  getUserRoles(): Observable<any> {
    return this._http.get<any[]>(`${this._getUserRolesEndpoint}`);
  }

  //Get list of all ems permissions
  getEmsPermissions(): Observable<any> {
    return this._http.get<any[]>(`${this._getAllEmsPermissionsEndpoint}`);
  }

  //Get list of all ems permissions
  getEmailReportTypes(): Observable<any> {
    return this._http.get<any[]>(`${this._getEmailReportTypesEndpoint}`);
  }

  //Update User role and permissions
  updateUserDetails(userDetails: AdminUserDetails): Observable<any> {
    return this._http.post<any[]>(this._updateUserDetailsEndpoint, userDetails);
  }

  //Update User vacation details
  updateUserVacationDetails(userVacationDetails: AdminVacationDetails): Observable<any> {
    return this._http.post<any[]>(this._updateUserVacationDetailsEndpoint, userVacationDetails);
  }

  //Delete user account
  deleteUserAccount(username: string): Observable<any> {
    username = encodeURIComponent(username);
    return this._http.get<any[]>(this._deleteUserEndpoint + username);
  }

  //Create user
  createLifespeakUser(userProfile: UserProfile): Observable<any> {
    return this._http.post<any[]>(this._createLsUserEndpoint, userProfile);
  }

  //Get all ems permissions , email reporting and user role details at once.
  public requestAdminDataFromMultipleSources(): Observable<any[]> {
    let userRolesResponse = this._http.get<any[]>(`${this._getUserRolesEndpoint}`);
    let emsPermissionResponse = this._http.get<any[]>(`${this._getAllEmsPermissionsEndpoint}`);
    let emailReportsResponse = this._http.get<any[]>(`${this._getEmailReportTypesEndpoint}`);
    return forkJoin([userRolesResponse, emsPermissionResponse, emailReportsResponse]);
  }


}

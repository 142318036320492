// Angular Things
import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

// Services
import { CmsService } from '../cms.service';
import { CategoriesService } from '../../categories/categories.service';
import { UtilitiesService } from '../../shared/utilities.service';
import { ConfirmService } from '../../shared/confirm.service';
import { PopupService } from '../../shared/popup.service';

// Classes
import { VolumeDetailItem } from '../../shared/VolumeDetailItem';
import { SimpleVolume } from '../../shared/SimpleVolume';
import { ModuleItem } from '../../shared/ModuleItem';
import { Expert } from '../../shared/expert';
import { Categories } from '../../categories/categories';

import { EmsConfig } from '../../shared/emsConfig';

// Components
import { CmsModuleDetailComponent } from './cms-module-details.component';

// Third-Party Things
import { FileUploader } from 'ng2-file-upload';
import { VolumeSubtitle } from 'app/shared/VolumeSubtitle';
import { SnackbarService } from 'app/services/Snackbar.service';
import { VolumeDetailList } from 'app/shared/VolumeDetailList';


@Component({
  selector: 'cms-volume-details', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-volume-details.component.html',
  providers: [Location]
})

export class CmsVolumeDetailComponent implements OnInit, OnDestroy {
  // adds class to parent element (element named using the selector in the component decorator)
  // @HostBinding('class.cmsVolumeDetail') cmsVolumeDetail = true;

  // variables for getting account id from url
  id: number;

  private sub: any;

  errorMessage: string;

  volumeDetails: VolumeDetailItem;
  simpleVolumes: any;
  matchedVolLang: string;
  showMatchedReadOnly: boolean;
  volumeInitialStatus: string;

  addNewVolume = false;
  newVolume: VolumeDetailItem;

  expertList: any;

  newVolumeID: any;


  storedCategories: any;

  showAddModule: boolean;
  volumeCreated: boolean;
  showVolumeStatusConfirmation: boolean;

  // img stuff
  imgPreviewUrl: string;
  showImgPreview: boolean = false;
  imageToUpload: string;
  resendImage: boolean = false;
  imageLoaded: boolean;

  // for category lists
  public categoryList: Categories[];

  inventories: any;
  // for inventory status checkboxes
  inventoryStatuses: any[] = [];

  updatedInventoryStatuses: any[] = [];

  confirmData: {};
  confirmVolumeData: any;
  confirmAction: string;
  confirmSubscription: Subscription;

  showTipsheetDropzone: boolean;
  public uploader: FileUploader = new FileUploader({ url: this._emsConfig.apiEndpoint + '/assetAdmin/addtipsheets' });
  public hasBaseDropZoneOver: boolean = false;

  showImageDropzone: boolean;
  public volumeImageUploader: FileUploader = new FileUploader({ url: this._emsConfig.apiEndpoint + '/assetAdmin/uploadvolumeimage' });
  public imageHasBaseDropZoneOver: boolean = false;

  // for volume subtitles
  public Subtitles: VolumeSubtitle[] = [];
  public allLsLanguages: any[] = [];
  public availableLanguages: any[] = [];
  public showNewLanguage: boolean = false;
  public volumeSubtitle: VolumeSubtitle;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private _cmsService: CmsService,
    private _categoriesService: CategoriesService,
    private _utilitiesService: UtilitiesService,
    private _confirmService: ConfirmService,
    private _popupService: PopupService,
    public _emsConfig: EmsConfig,
    private _snackBar: SnackbarService
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'updateVolume') {
            this.volumeSubmitForm(this.confirmVolumeData);
          }
          if (this.confirmAction === 'deleteSubtitle') {
            this.deleteVolumeSubtitleInfo(this.confirmVolumeData);
          }
        }
      }
    );
  }

  onEvent(event: any) {
    event.stopPropagation();
  }

  // ========================
  // get lists for dropdowns
  // ========================

  getCategories(): void {
    this._categoriesService.getAllCategories()
      .subscribe(
        CategoryList => {
          this.categoryList = CategoryList['categories'];
        },
        err => this.errorMessage = <any>err
      );
  }

  getSimpleVolumes(matchedLang): void {
    // second param is onPoint (true or false)
    this._cmsService.getAllVolumes(false, matchedLang)
      .subscribe(
        SimpleVolume => {
          //filter out retired volumes EMSStatus: "retired"
          this.simpleVolumes = SimpleVolume.filter(x => x.EMSStatus !== 'retired');
        },
        err => this.errorMessage = <any>err
      );
  }

  getExpertsForDropdown(): void {
    this._cmsService.getAllExperts()
      .subscribe(
        Expert => {
          this.expertList = Expert;
        },
        err => this.errorMessage = <any>err,
      );
  }

  getInventories(): void {
    this._cmsService.getInventories()
      .subscribe(
        data => {
          this.inventories = data.inventories;
          data.inventories.forEach(inv => {
            this.inventoryStatuses[inv.Key] = 0;
          })
        },
        err => this.errorMessage = <any>err,
      );
  }

  updateInventoryStatus(inventoryID) {
    // Update status icon in table
    if (this.inventoryStatuses[inventoryID] === 3) {
      this.inventoryStatuses[inventoryID] = 0
    } else {
      this.inventoryStatuses[inventoryID]++;
    }

    let existingInvIndex = this.updatedInventoryStatuses.findIndex(i => i.InventoryID === inventoryID)
    if (existingInvIndex > -1) {
      if (this.inventoryStatuses[inventoryID] !== this.volumeDetails.volume.Inventory[inventoryID]) {
        // If inventory has already been clicked and has changed statuses from its original status
        this.updatedInventoryStatuses[existingInvIndex].StatusID = this.inventoryStatuses[inventoryID];
      } else { // status is the same as original status; remove inventory from updated array
        this.updatedInventoryStatuses.splice(existingInvIndex, 1);
      }
    } else { // Add new inventory item with updated StatusID to array
      this.updatedInventoryStatuses = [
        ...this.updatedInventoryStatuses,
        {
          'InventoryID': inventoryID,
          'StatusID': this.inventoryStatuses[inventoryID]
        }
      ]
    }
    console.log("Updated inventory statuses:", this.updatedInventoryStatuses)
  }

  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(component: string, action: string, volumeData: any, message: string): void {
    this.confirmAction = action;
    this.confirmVolumeData = volumeData;

    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }

  setPopupMessage(volumeCreate: boolean, volumeID: number, customError: string, categoryError: string, categoriesUpdated: boolean, inventoriesUpdated: boolean): void {
    let successMessage = 'The volume has been successfully created/updated.';
    let errorMessage = 'The volume has not been created/updated. ';

    // set messages variations
    if (categoryError !== '') {
      errorMessage = errorMessage + 'There was an error in adding/updating categories. Please try again.';
    }


    // check for linked volume error
    if (customError === 'Duplicate linked volume') {
      errorMessage = 'That volume has already been linked to another volume. Please choose a different volume and try again. Your changes have not been saved.'
      this._snackBar.error(errorMessage);
    } else if (customError !== 'Volume not created') {
      if (categoryError !== '') {
        successMessage = successMessage + ' However, there was an error in adding/updating additional categories.';
        this._snackBar.error(successMessage);
      } else {
        this._snackBar.success(successMessage);
      }

    } else {
      this._snackBar.error(errorMessage);
    }
  }

  // ==========================
  // get/update/create volumes
  // ==========================
  getVolumeDetails(): void {
    this._cmsService.getEmsVolume(this.id, true)
      .subscribe(
        VolumeDetailItem => {
          console.log(VolumeDetailItem)
          this.volumeDetails = VolumeDetailItem;
          // check if an image has been uploaded or if it's in queue
          this.checkImageStatus();

          // only show the add module button if there are less than 6
          const numOfModules = Object.keys(this.volumeDetails.media).length;
          if (numOfModules >= 6) {
            this.showAddModule = false;
          } else {
            this.showAddModule = true;
          }

          // matched volume
          this.matchedVolLang = this.volumeDetails.volume.Language;

          // set to be the opposite (if EN then it should only show FR volumes for matching)
          if (this.matchedVolLang === 'EN') {
            this.matchedVolLang = 'FR';
          } else {
            this.matchedVolLang = 'EN';
          }

          // matched volume field should be visible but disabled if volume lang is FR (since you can't match a volume FR to EN)
          this.showMatchedReadOnly = this.volumeDetails.volume.Language === 'FR';

          // Parse inventory string from DB and match keys to new InventoryStatuses array
          this.volumeDetails.volume.Inventory = JSON.parse(this.volumeDetails.volume.Inventory);
          // this.volumeDetails.volume.Inventory = [];
          for (let i = 1; i <= this.inventories.length; i++) {
            if (i in this.volumeDetails.volume.Inventory) {
              this.inventoryStatuses[i] = this.volumeDetails.volume.Inventory[i]
            }
          }

          // trim time from dates
          if (this.volumeDetails.volume.DateCreated !== '0001-01-01T00:00:00') {
            this.volumeDetails.volume.DateCreated = this._utilitiesService.trimDate(this.volumeDetails.volume.DateCreated);
          } else {
            this.volumeDetails.volume.DateCreated = '--';
          }

          // don't show message if volume existing
          this.volumeCreated = true;

          // get all simple volumes (for matched volume list)
          this.getSimpleVolumes(this.matchedVolLang);
          this.volumeInitialStatus = this.volumeDetails.volume.EMSStatus;
        },
        err => this.errorMessage = <any>err,
      );
  }

  setupNewVolume(): void {
    this.addNewVolume = true;
    let newVolume = new VolumeDetailList();
    newVolume.ID = 'N/A';
    newVolume.DateCreated = new Date().toLocaleDateString();

    // disable matched volume field until volume has been create
    this.showMatchedReadOnly = true;

    // don't show add module button until volume has been created
    this.showAddModule = false;
    this.volumeCreated = false;

    this.volumeDetails = new VolumeDetailItem();
    this.volumeDetails.volume = newVolume;
  }


  volumeSubmitForm(volumeDetails): void {
    console.log(volumeDetails);
    // check that all fields are filled out before trying to create/update volume
    if (this._utilitiesService.isBlank([volumeDetails]) || this._utilitiesService.isBlank([volumeDetails.volume.VolumeTitle, volumeDetails.volume.VolumeDescription, volumeDetails.volume.Language]) || parseInt(volumeDetails.volume.CategoryID) === 0 || volumeDetails.volume.ExpertID === 0) {
      this._snackBar.error(this._emsConfig.text.EMS_Content.Complete_Fields_Error);
    } else {

      // if new volume, set id to -1 so it will create a new one
      if (volumeDetails.volume.ID === 0 || volumeDetails.volume.ID === 'N/A') {
        this.volumeDetails.volume.ID = this.id;
      }

      // onpoint is always false
      this.volumeDetails.volume.OnPoint = false;

      if (volumeDetails.volume.Language === 'FR') {
        volumeDetails.volume.MatchedVolume = 0;
      }

      this.volumeDetails.volume.Inventory = ''; // This information is not important to send to the server
      this.volumeDetails.volume.InventoryUpdate = this.updatedInventoryStatuses; // Pass an array of updated inventory statuses to server

      this._cmsService.addUpdateEmsVolume(volumeDetails.volume)
        .subscribe(
          data => {
            // set the snackbar messages
            this.setPopupMessage(data.volumeCreate, data.volumeID, data.customError, data.categoryError, data.categoriesUpdated, data.inventoriesUpdated);

            // check for create or update
            if (data.volumeCreate && data.customError === 'Volume not created') {
              // if volume not created, don't reset the volume details object by calling get volume details
              this.volumeDetails.volume.ID = 'N/A';
            } else if (data.volumeCreate && data.customError !== 'Volume not created') {

              // this is to update the url with the new volume id
              const newData = data;
              this.newVolumeID = newData.volumeId;

              this.location.go('/cms/volumes/' + this.newVolumeID);

              this.id = this.newVolumeID;
              this.getVolumeDetails();
            } else {
              this.getVolumeDetails();
            }
          },
          err => this.errorMessage = <any>err,
        );
    }
  }

  navigateToTipsheet(id) {
    this.router.navigate(['/cms/tipsheets/-1', { volumeId: id, lang: this.volumeDetails.volume.Language }]);
  }

  goToModule(moduleID): void {
    this.router.navigate(['/cms/modules', moduleID]);
  }

  createModule(): void {
    // Navigate to the module create page with volumeId param
    this.router.navigate(['/cms/modules/-1', { volumeId: this.id }]);
  }

  // ===============
  // image upload
  // ===============
  public fileOverBaseImage(e: any): void {
    this.imageHasBaseDropZoneOver = e;
    this.validateFileTypeImage();
    // by default, the file uploader plugin requires credentials for submission, this remove them
    this.volumeImageUploader.queue.forEach(file => file.withCredentials = false);
  }

  // make sure the current item is a jpg file. Otherwise remove from the queue
  private validateFileTypeImage(): void {
    const currentItem = this.volumeImageUploader.queue[0];
    if (currentItem !== undefined && currentItem.file.name.indexOf('.jpg') === -1) {
      alert('Only JPG files are permitted for Image upload');
      this.volumeImageUploader.queue.pop();
      this._emsConfig.getFileUploadHeaders();
    } else if (currentItem !== undefined) {
      currentItem.file.name = this.volumeDetails.volume.ImageUrl + '.jpg';
      this.imageToUpload = currentItem.file.name;
    }
  }

  checkImageStatus(): void {
    // urls for queue and upload folders
    const volumeImage = this._emsConfig.assetPath + '/ImageAssets/originals/volumeimagesfeatured/' + this.volumeDetails.volume.ImageUrl + '.jpg';
    // check if resized and uploaded (exists on live site)
    this.imageExists(volumeImage);

  }




  clearVolumeImage(): void {
    this.showImageDropzone = true;
    this.imageLoaded = false;
  }

  removeImageFromAWS(imageName): void {
    let imgFullUrl = '';

    // use the correct image preview path
    if (this.imageLoaded) {
      imgFullUrl = 'ImageAssets/originals/volumeimagesfeatured/' + imageName + '.jpg'
    } else {
      imgFullUrl = '';
    }
    this._cmsService.removeAwsProcessedFileFromAWS(imgFullUrl)
      .subscribe(
        data => {
          if (data.succeeded === 'false') {
            this._popupService.updateNotification({ message: 'Image was not removed from AWS. Please try again.', error: true });
            this.resendImage = true;
          } else {
            this._popupService.updateNotification({ message: 'Image successfully removed from AWS.', success: true });
            this.resendImage = false;
            this.checkImageStatus();
          }
        },
        err => this.errorMessage = <any>err,
      );
  }

  imageExists(url): void {
    const img = new Image();
    // set the image loaded variable to either show or hide image upload dropzone
    img.onload = () => {
      if (url.toLowerCase().indexOf('/originals') !== -1) {

        this.imageLoaded = true;
      }
    };
    img.onerror = () => {
      if (url.toLowerCase().indexOf('/originals') !== -1) {
        this.imageLoaded = false;
      }
    };

    img.src = url;
  }

  previewVolumeImage(): void {
    let imgUrl = '';

    // use the correct image preview path
    if (this.imageLoaded) {
      imgUrl = this._emsConfig.assetPath + '/ImageAssets/originals/volumeimagesfeatured/' + this.volumeDetails.volume.ImageUrl + '.jpg';
    } else {
      imgUrl = '';
    }

    this.imgPreviewUrl = imgUrl;
    this.showImgPreview = true;
  }

  closePreviewModal(): void {
    this.showImgPreview = false;
  }

  updateImageUrl($event): void {
    console.log($event);
  }

  uploadingGifAdd(): void {
    const container = document.querySelector('body');
    container.classList.add('request-in-progress');
  }

  uploadingGifRemove(): void {
    const container = document.querySelector('body');
    container.classList.remove('request-in-progress');
  }

  // ===================================================================================================
  // Called if volume status changes. It status is changed to 'retired', for system admin it will
  // show a confirmation box and for non system admin, it will rollback to previous status automatically
  // ===================================================================================================
  volumeStatusChange(status: string): void {
    if (status === 'retired') {
      if (this._emsConfig.UserRole !== 'systemadmin') {
        // if not system admin rollback to previous status
        this.volumeDetails.volume.EMSStatus = this.volumeInitialStatus;
        this._popupService.updateNotification({ message: 'Please contact administrator to retire this volume', success: false });
      } else {
        this.showVolumeStatusConfirmation = (status === 'retired' && this.volumeInitialStatus !== 'retired') ? true : false;
      }
    }
  }

  // called if systemadmin does not confirm retiring the volume
  goBackPreviousStatus(): void {
    this.volumeDetails.volume.EMSStatus = this.volumeInitialStatus;
    this.showVolumeStatusConfirmation = false;
  }

  // call api method to change volume status to retire in both staging & Prod
  retireCurrentVolume(): void {
    this._cmsService.retireVolume(this.volumeDetails.volume.ID)
      .subscribe(data => {
        if (data && data.succeeded) {
          this._snackBar.success( 'Volume has been successfully retired & removed from all clients library');
        } else {
          this.volumeDetails.volume.EMSStatus = this.volumeInitialStatus;
          this._snackBar.error( 'There was an error while retiring volume' + data?.message , 10000 );
        }
        this.showVolumeStatusConfirmation = false;
      }, (error) => {
        console.log(error.error);
        this._snackBar.error('There was an error while retiring volume.' + error.error?.message,10000);
      });

  }

  // ====================
  // Volume Subtitle Info
  // ====================

  //show/hide add new subtitle
  toggleAddLanguage() {
    this.volumeSubtitle = new VolumeSubtitle();
    this.showNewLanguage = !this.showNewLanguage;
  }

  //loads all the languages supported by the LifeSpeak
  getAllLsLanguages() {
    this._cmsService.getLanguages().subscribe(languages => {
      this.allLsLanguages = this.availableLanguages = languages;
    }, err => {
      console.log(err);
    });
  }

  // get list of all subtitles for the volume
  getAllVolumeSubtitles(): void {
    this._cmsService.getSubtitlesByVolume(this.id)
      .subscribe(
        subtitleItem => {
          this.Subtitles = subtitleItem;
          this.filterExistingSubtitleLanguages();
        },
        err => {
          console.log(err);
          if (err.status != 404)
            this._snackBar.error();
        }
      );
  }

  // filter out languages to make sure that existed languages are not showing up in lang dropdown for 'add new volume language'
  filterExistingSubtitleLanguages() {
    this.availableLanguages = this.allLsLanguages?.filter(o1 => !this.Subtitles.some(o2 => o1.Code.toLowerCase() === o2.LangCode.toLowerCase()));
  }

  //bulk updates for all the subtitles/ add new volume subtitle info
  addUpdateVolumeSubtitles() {
    // if new volume subtitle, add it to the subtitle list
    if (this.showNewLanguage) {
      this.volumeSubtitle.VolumeID = this.id;
      this.Subtitles.push(this.volumeSubtitle);
    }

    // check blank value for each input box
    for (let item of this.Subtitles) {
      if (this._utilitiesService.isBlank([item.LangCode, item.VolumeTitle, item.VolumeDescription])) {
        this._snackBar.error(this._emsConfig.text.EMS_Content.Complete_Fields_Error);
        // if there is any empty field, also pop the new item
        if (this.showNewLanguage)
          this.Subtitles.pop();
        return;
      }
    }
    this._cmsService.addUpdateVolumeSubtitles(this.Subtitles).subscribe(data => {
      this._snackBar.success("Subtitle(s) has/have been successfully created/updated.");
      this.refresh();
    }, err => {
      this._snackBar.error();;
      return;
    });
  }

  //delete subtitle info for volume
  deleteVolumeSubtitleInfo(item: VolumeSubtitle) {
    this._cmsService.deleteVolumeSubtitleInfo(this.id, item.ID)
      .subscribe(
        data => {
          this._snackBar.success('Subtitle info has been deleted successfully')
          this.refresh();
        },
        err => {
          this._snackBar.error();
          console.log(err);
        }
      );
  }

  //refresh the page after add/edit/delete of translation info to get sync status
  refresh(): void {
    window.location.reload();
  }

  ngOnInit(): void {
    // gets the account id from url
    this.sub = this.route.params.subscribe(params => {
      this.id = parseInt(params['id']);
    });

    // get volume details on page load unless creating new volume
    if (this.id === -1) {
      this.setupNewVolume();
    } else {
      this.getVolumeDetails();
    }

    // get stuff for dropdowns/checkboxes
    this.getExpertsForDropdown();
    this.getCategories();
    this.getInventories();

    this.getAllLsLanguages();
    this.getAllVolumeSubtitles();

    // for uploads
    // this gets all the token api headers and adds to any upload request
    this.uploader.options.headers = this._emsConfig.getFileUploadHeaders();

    // gets the response after the upload is completed
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const responsePath = JSON.parse(response);
      if (!item.isSuccess) {
        this._popupService.updateNotification({ message: 'Tipsheet has not been uploaded. Please try again.', error: true });
        this.uploadingGifRemove();
      } else {
        this._popupService.updateNotification({ message: 'Tipsheet successfully uploaded.', success: true });
        this.volumeDetails.volume.ResourceUrl = item.file.name;
        this.uploadingGifRemove();
      }
    };

    this.volumeImageUploader.options.headers = this._emsConfig.getFileUploadHeaders();

    // gets the response after the upload is completed
    this.volumeImageUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const responsePath = JSON.parse(response);
      if (!item.isSuccess) {
        this._popupService.updateNotification({ message: 'Image has not been uploaded. Please try again.', error: true });
        this.uploadingGifRemove();
      } else {
        this._popupService.updateNotification({ message: 'Image successfully uploaded.', success: true });
        this.uploadingGifRemove();
        this.imageLoaded = true;
      }
    };
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.confirmSubscription.unsubscribe();
  }
}

// Angular Things
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleCasePipe } from '@angular/common';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';

// Classes
import { VolumeListItem } from '../../shared/VolumeListItem';

// Components
import { CategoriesComponent } from '../../categories/categories.component';

//  Services
import { CmsService } from '../cms.service';
import { UtilitiesService } from '../../shared/utilities.service';

@Component({
  selector: 'cms-volumes', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-volumes.component.html'
})

export class CmsVolumesComponent implements OnInit {

  allVolumes: VolumeListItem[] = [];

  errorMessage: string;

  lang: string = 'EN';

  // edit volumes variables
  showEditButton: boolean = false;
  selectedVolumeId: number;

  // filter variables
  volumeSearch: string;
  volumeStatus: string;
  public isOnPoint: boolean = false;
  activeFilter: string = 'active';
  currentlySearching: boolean = false;
  activeFilterStore: string;
  volumeStatusStore: string;


  constructor(private router: Router, private route: ActivatedRoute, public _cmsService: CmsService, public _emsConfig: EmsConfig, public _utilitiesService: UtilitiesService) { }

  // update various pipe filters
  public updateVolumeFilters(status: string): void {
    this.volumeStatus = status;
    this.activeFilter = status;
    console.log('status changed');
    console.log(status);
    console.log(this.allVolumes.length);
  }

  // sort alphabetically - currently these filters don't work with status ones (it will swtch the status back to all to search)
  sortAlphabeticallyByTitle(): void {
    this.volumeStatus = 'all';
    this.allVolumes.sort(function (a, b) {
      var textA = a.VolumeTitle.toUpperCase();
      var textB = b.VolumeTitle.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    this.activeFilter = 'title';
  }

  sortAlphabeticallyByExpert(): void {
    this.volumeStatus = 'all';
    this.allVolumes.sort(function (a, b) {
      var textA = a.Expert.toUpperCase();
      var textB = b.Expert.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    this.activeFilter = 'expert';
  }

  // if using the search bar ignore the other filter options
  usingSearchBar(text: any): void {
    if (this.volumeSearch.length > 0) {
      if (!this.currentlySearching) {
        // store the current filter settings to apply back later
        this.currentlySearching = true;
        this.activeFilterStore = this.activeFilter;
        this.volumeStatusStore = this.volumeStatus;
      }
      this.resetFilters();
      
    } else {
      // when search input is cleared, set back the filters
      this.activeFilter = this.activeFilterStore;
      this.volumeStatus = this.volumeStatusStore;
      this.currentlySearching = false;
    }
  }

  // reset filters
  public resetFilters(): void {
    this.activeFilter = 'all';
    this.updateVolumeFilters('all');
  }

  getAllVolumes(): void {
    this._cmsService.getAllVolumes(this.isOnPoint, this.lang)
      .subscribe(
      VolumeListItem => {
        this.allVolumes = VolumeListItem;
        for (let i = 0; i < this.allVolumes.length; i++) {
          this.allVolumes[i].VolumeTitleNormalized = this._utilitiesService.normalizeTerm(this.allVolumes[i].VolumeTitle);
          this.allVolumes[i].ExpertNormalized = this._utilitiesService.normalizeTerm(this.allVolumes[i].Expert);
        }
        this.updateVolumeFilters(this.volumeSearch ? 'all' : 'live');
      },
      err => this.errorMessage = <any>err,
      () => console.log(this.allVolumes)
    );
  }


  // focus on selected volume and store the id
  selectVolume($event: any): void {
    const target = $event.currentTarget;
    target.focus();
    this.selectedVolumeId = $event.currentTarget.attributes.id.value;
    console.log(this.selectedVolumeId);
    this.showEditButton = true;
  }

  deselectVolume($event: any): void {
    // check if click is on specific element
    if ($event.relatedTarget != null) {
      // if click is another volume or the fab button, show the edit button
      if ($event.relatedTarget.className === 'btn--fab b-dark-blue cms-container__fab cms-container__fab--edit' || $event.relatedTarget.className === 'volume-card card') {
        this.showEditButton = true;
      } else {
        // if not clicking on other volume, show add button instead of edit
        this.showEditButton = false;
      }
    } else {
      this.showEditButton = false;
    }
  }

  createNewVolume(): void {
    // Set Selected Volume to -1 for new volume
    this.selectedVolumeId = -1;
    if (this.router.url.includes('onpoints')) {
      this.router.navigate([this.selectedVolumeId], { relativeTo: this.route });
    } else if (this.router.url.includes('volumes')) {
      this.router.navigate([this.selectedVolumeId], { relativeTo: this.route });
    } else {
      // if coming directly from cms default route (need to add volumes to url)
      this.router.navigate(['volumes', this.selectedVolumeId], { relativeTo: this.route });
    }
  }

  // Navigates to Volume Details template
  onSelect() {
    // If search query exists, add param to route to allow for easy navigation back to filtered list of volumes
    this.router.navigate([], { 
      relativeTo: this.route, 
      queryParams: { query: this.volumeSearch },
      queryParamsHandling: 'merge' 
    });
    // Navigate with relative link after route is updated with search query param
    setTimeout(() => {
      if (this.router.url.includes('onpoints') || this.router.url.includes('volumes')) {
        this.router.navigate([this.selectedVolumeId], { relativeTo: this.route });
      } else {
        // if coming directly from cms default route (need to add volumes to url)
        this.router.navigate(['volumes', this.selectedVolumeId], { relativeTo: this.route });
      }
    }, 100)
  }

  // update the volume list by language
  setLang(lang: string): void {
    this.lang = lang;
    console.log(this.lang);

    // adding query param for selected language, so that if a user navigates away from this page and comes back, the language they chose will be remembered
    this.router.navigate([], { 
      relativeTo: this.route, 
      queryParams: { lang: lang },
      queryParamsHandling: 'merge' 
    });

    // reset the filters
    this.resetFilters();
    this.getAllVolumes();
  }


  ngOnInit(): void {
    // check if onpoints page
    if (this.router.url.includes('onpoints')) {
      this.isOnPoint = true;
    }

    // checks if search query param exists, and populates search input filter
    if (this.route.snapshot.queryParams["query"]) {
      const searchParam = this.route.snapshot.queryParams["query"];
      this.volumeSearch = searchParam;
    };

    // checks if language query param exists, and calls setLang() method to set and remember selected language
    if (this.route.snapshot.queryParams["lang"]) {
      const param = this.route.snapshot.queryParams["lang"];
      this.setLang(param);
    };

    // gets list of volume by language
    this.getAllVolumes();
  }

}

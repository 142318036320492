import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopupService } from './popup.service';

@Component({
  selector: 'popup-message',
  templateUrl: 'popup.component.html'
})
export class PopupComponent implements OnDestroy {

  public popupData: any = {};

  // instructions for use, pass the following in as object properties
  // success: true - for a success message
  // error: true - for an error message
  // message: 'sample' - for the custom message

  // set initial vals
  public active: boolean = false;
  subscription: Subscription;
  public popupDelay: any;

  constructor(private _popupService: PopupService) {
    // set initial popup vals
    this.popupData.success = false;
    this.subscription = _popupService.popupData$.subscribe(
      data => {
        // on init we prevent the popup from displaying
        if (!data.prevent) {
          this.updatePopup(data);
        }
      }
    );
  }

  // popup will display fora default of 4 seconds, then reset it's data
  setPopupDelay(duration: number = 4000): void {
    this.active = true;
    this.popupDelay = setTimeout(() => {
      this.active = false;
      this.popupData = {};
    }, duration);
  }

  // update's the popup with various values
  updatePopup(data: any): void {
    this.popupData = data;

    // if there is a popup currently showing, reset it
    if (this.active) {
      clearTimeout(this.popupDelay);
      this.setPopupDelay(data.duration);
    } else {
      this.setPopupDelay(data.duration);
    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

<div class="cms-help-container section-padding">
  <div class="get-connected__lang-button-container lang-button-container">
    <button class="btn--toggle get-connected__lang-switch-button lang-switch-button" [ngClass]="{'active': lang === 'EN'}" (click)="setLang('EN')" >
      {{_emsConfig.text?.EMS_General.EN}}
    </button>
      <button class="btn--toggle get-connected__lang-switch-button lang-switch-button" [ngClass]="{'active': lang === 'FR'}"(click)="setLang('FR')"  >
      {{_emsConfig.text?.EMS_General.FR}}
    </button>
    <button class="btn--toggle get-connected__lang-switch-button lang-switch-button" [ngClass]="{'active': lang === 'ES'}"(click)="setLang('ES')">
      ES
    </button>
  </div>

  <div class="help-sections-container">
    <div class="help-section-container__help-section-list">
      <table class="display-table display-table--extra-padding">
        <thead>
          <tr>
            <th>Section Name</th>
            <th class="center-column">Section Order</th>
            <th class="center-column"></th>
            <th class="center-column"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let section of helpSections">
            <td>{{section.SectionName}}</td>
            <td class="center-column">{{section.SortOrder}}</td>
            <td class="center-column edit-btn" (click)="editQuestions(section.ID)">Edit Questions</td>
            <td class="edit-btn" (click)="editHelpSection(section.ID)">Edit Section</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="help-edit-container" *ngIf="editMode">
      <!-- add a category -->
      <div class="add-new-help-container" *ngIf="addSection">
        <form #form="ngForm">
            <div class="basic-info__setting">
              <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                  </svg>
                </div>
                Section Name
              </h4>
              <div class="input-container">
                <input type="text" class="basic-info__text setting__text-input text-input" name="sectionName" [(ngModel)]="newHelpSection.SectionName">
              </div>
            </div>
            <div class="basic-info__setting">
              <h4 class="basic-info__heading setting__heading ls-subheading">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                  </svg>
                </div>
                Section Language
              </h4>
              <div class="select-container">
                <select class="setting__select-input select-input" name="sectionLang" [(ngModel)]="newHelpSection.SectionLang">
                  <option value="EN">English</option>
                  <option value="FR">French</option>
                </select>
              </div>
            </div>
            <div class="basic-info__setting">
              <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path d="M7 21H3V19H7V18H5C3.9 18 3 17.11 3 16V15C3 13.9 3.9 13 5 13H7C8.11 13 9 13.9 9 15V19C9 20.11 8.11 21 7 21M7 15H5V16H7M5 3H7C8.11 3 9 3.9 9 5V9C9 10.11 8.11 11 7 11H5C3.9 11 3 10.11 3 9V5C3 3.9 3.9 3 5 3M5 9H7V5H5M12 5H22V7H12M12 19V17H22V19M12 11H22V13H12Z" />
                  </svg>
                </div>
                Sort Order
              </h4>
              <div class="input-container">
                <input type="text" class="basic-info__text setting__text-input text-input" name="sortOrder" [(ngModel)]="newHelpSection.SortOrder">
              </div>
            </div>
            <button (click)="openConfirmDialog('helpSection', 'addSection', newHelpSection, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
              type="button" class="btn build-submit">
              Submit
            </button>
        </form>
        <button (click)="clearNewSection()" type="button" class="btn--fab-smaller btn--delete f-dark-blue">
          <svg viewBox="0 0 24 24">
            <path fill="#E3604C" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            />
          </svg>
        </button>
      </div>

      <div class="add-new-help-container" *ngIf="updatingSection">
        <form #form="ngForm">
          <syncstatus [assetID]="editSection?.ID" [tableID]="1002" ></syncstatus>
            <div class="basic-info__setting">
              <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                  </svg>
                </div>
                Section Name
              </h4>
              <div class="input-container">
                <input type="text" class="basic-info__text setting__text-input text-input" name="editDectionName" [(ngModel)]="editSection && editSection.SectionName">
              </div>
            </div>
            <div class="basic-info__setting">
              <h4 class="basic-info__heading setting__heading ls-subheading">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                  </svg>
                </div>
                Section Language
              </h4>
              <div class="select-container">
                <select class="setting__select-input select-input" name="editSectionLang" [(ngModel)]="editSection && editSection.SectionLang">
                  <option value="EN">English</option>
                  <option value="FR">French</option>
                </select>
              </div>
            </div>
            <div class="basic-info__setting">
              <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path d="M7 21H3V19H7V18H5C3.9 18 3 17.11 3 16V15C3 13.9 3.9 13 5 13H7C8.11 13 9 13.9 9 15V19C9 20.11 8.11 21 7 21M7 15H5V16H7M5 3H7C8.11 3 9 3.9 9 5V9C9 10.11 8.11 11 7 11H5C3.9 11 3 10.11 3 9V5C3 3.9 3.9 3 5 3M5 9H7V5H5M12 5H22V7H12M12 19V17H22V19M12 11H22V13H12Z" />
                  </svg>
                </div>
                Sort Order
              </h4>
              <div class="input-container">
                <input type="text" class="basic-info__text setting__text-input text-input" name="editSortOrder" [(ngModel)]="editSection && editSection.SortOrder">
              </div>
            </div>
            <button (click)="openConfirmDialog('helpSection', 'addSection', editSection, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
              type="button" class="btn build-submit">
              Submit
            </button>
        </form>
        <button (click)="clearNewSection()" type="button" class="btn--fab-smaller btn--delete f-dark-blue">
          <svg viewBox="0 0 24 24">
            <path fill="#E3604C" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>

  <button class="btn--fab b-dark-blue cms-container__fab cms-container__fab--add" (click)="createNewHelpSection()">
    <svg viewBox="0 0 24 24"><path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" /></svg>
  </button>
</div>

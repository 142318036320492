// Angular Things
import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';
import { UtilitiesService } from '../../shared/utilities.service';
import { ConfirmService } from '../../shared/confirm.service';

// Classes
import { Expert } from '../../shared/expert';

// Components
import { CategoriesComponent } from '../../categories/categories.component';

//  Services
import { CmsService } from '../cms.service';
import { cpdTitle } from 'app/shared/objects/cpdTitle';
import { SnackbarService } from 'app/services/Snackbar.service';

@Component({
  selector: 'cms-cpd-volumes', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-cpd-volumes.component.html'
})

export class CmsCpdVolumesComponent implements OnInit, OnDestroy {
  @HostBinding('class') classAttribute: string = 'cpd-vol';

  sub: any;
  public confirmSubscription: Subscription;
  profession: string;
  jurisdiction: string;

  lang: string = 'EN';
  toggleAdd: boolean = false;
  toggleEdit: boolean=false;

  activeTitle: any;
  accTypes: any[] = [];
  volumes: any[] = [];
  cpdVols: any[] = [];
  cpdTitleID: number = -1;
  public titleDetails: cpdTitle= new cpdTitle();
  statusList: any[] = [];
  // Adding variables
  volId: number = -1;
  mins: number;
  accType: string = '';
  volSearch: string = '';

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public _cmsService: CmsService,
    public _emsConfig: EmsConfig,
    public _utilitiesService: UtilitiesService,
    public _confirmService: ConfirmService,
    private _snackBar: SnackbarService
    ) {

    }


  getCpdTitles = function() {
    this._cmsService.getCpdTitles()
      .subscribe(res => {
        // Set the page title based on routing params
        this.activeTitle = res.find(item => item.Profession === this.profession && item.JurisdictionCode === this.jurisdiction);
        this.activeTitle.Lang=this.lang;
        this.titleDetails.Title=this.activeTitle.Title;
        this.titleDetails.StatusID=this.activeTitle.StatusID;
        this.titleDetails.ID=this.activeTitle.ID;
      });
  }
// Get cpd status
  getCpdStatuses():void {
    this._cmsService.getCpdTitleStatuses()
      .subscribe(res => {
        this.statusList = res;
      });
  }

  // update cpd title name & status
  updateCpdTitle():void{
    this._cmsService.updateCPDTitle(this.titleDetails).subscribe(data => {
      if (data) {
        this.getCpdTitles();
        this._snackBar.success('CPD title info has been updated');
      }
    },
      err => {
        this._snackBar.error();
        return;
      });
  }
  getCpdAccTypes = function(lang: string) {
    this._cmsService.getCpdAccTypes(lang)
      .subscribe(res => {
        this.accTypes = res;
      });
  }

  getVolumes = function(lang: string) {
    this._cmsService.getAllVolumes(false, lang)
      .subscribe(res => {
        // Add a volume normalized term for searching
        res.forEach(v => {
          v.VolumeTitleNormalized = this._utilitiesService.normalizeTerm(v.VolumeTitle);
          v.ExpertNormalized = '';
        });

        // filter only live / active volumes
        this.volumes = res.filter(v => v.EMSStatus === 'live');
      });
  }

  getCpdVolumes = function(profession: string, jurisdiction: string, lang: string) {
    this._cmsService.getCpdVolumesByType(profession, jurisdiction, lang)
      .subscribe(res => {
        this.cpdVols = res;
        this.cpdTitleID = res[0].CpdTitleID;
      });
  }

  // Build a new CPD volume to add to the list
  buildCpdVol = function() {
    let vol = {
      ID: this.volId,
      CPDMinutesAccredited: this.mins,
      AccreditationType: this.accType,
      Profession: this.profession,
      Jurisdiction: this.jurisdiction,
      CpdLanguage: this.lang
    }

    return vol;
  }

  // Add cpd volume to list
  addCpdVol = function() {
    // ensure all fields are filled out
    if (this.volId === -1 || !this.mins) {
      this._snackBar.error('Invalid input selection!');
      return;
    }

    // ensure a duplicate volume isn't added
    if (this.cpdVols.filter(item => item.ID === parseInt(this.volId, 10)).length > 0) {
      this._snackBar.error('Volume already exists in list!');
      return;
    }

    this._cmsService.addCpdVolume(this.buildCpdVol())
      .subscribe(id => {
        if (id !== -1) {
          // refresh volume list and reset volume adding variables
          this.getCpdVolumes(this.profession, this.jurisdiction, this.lang);
          this.volId = -1;
          this.accType = '';
          this.mins = null;
          this.volSearch = '';
          this._snackBar.success(this._emsConfig.text.EMS_General.Popup_Success);
        } else {
          this._snackBar.error('There was a problem updating the CPD Volume. Please refresh and try again.');
          }
      });
  }

  // Edit an existing CPD volume
  editVol = function(id: number) {
    // first reset editing for any other volumes
    this.cpdVols.map(x => x.EditMode = false);

    const index = this.cpdVols.findIndex(v => v.CPDID === id);
    if (index > -1) {
      this.cpdVols[index].EditMode = true;
    }
  }

  // Update the edited CPD Volume in the list
  updateVol = function(id: number) {
    const index = this.cpdVols.findIndex(v => v.CPDID === id);
    if (index > -1) {
      console.log(this.cpdVols[index].CPDMinutesAccredited);
      console.log(this.cpdVols[index].AccreditationType);

      if (this.cpdVols[index].CPDMinutesAccredited < 1 || this.cpdVols[index].AccreditationType === '-1') {
        this._popupService.updateNotification({message: 'Invalid input selection!', error: true});
        return;
      }

      this._cmsService.updateCpdVolume(this.cpdVols[index])
        .subscribe(succeeded => {
          if (succeeded) {
            this.closeEdit();
            this.getCpdVolumes(this.profession, this.jurisdiction, this.lang);
            this._snackBar.success(this._emsConfig.text.EMS_General.Popup_Success);
          } else {
            this._snackBar.error('There was a problem updating the CPD Volume. Please refresh and try again.');
            }
        });
    }
  }

  closeEdit = function() {
    this.cpdVols.map(x => x.EditMode = false);
  }

  // get volume's ems status from DB and update them to be more understandable
  getOnPointStatus(emsStatus:string): string{
    var updatedStatus = '';
    switch (emsStatus) {
      case 'qa':
        updatedStatus = 'QA';
        break;
      case 'testing':
        updatedStatus = 'Testing';
        break;
      case 'retired':
        updatedStatus = 'Retired';
        break;
      case 'audio_only':
        updatedStatus = 'Audio Only';
        break;
      case 'live':
        updatedStatus = 'Active';
        break;
      default:
        break;
    }
    return updatedStatus;
  }

  ngOnInit(): void {
    // gets the account id from url
    this.sub = this.route.params.subscribe(params => {
      this.profession = params['profession'];
      this.jurisdiction = params['jurisdiction'];
      this.lang = params['language'];

/*       if (this.jurisdiction.includes('-FR')) {
        this.lang = 'FR';
      } */
      this.getCpdAccTypes(this.lang);
      this.getVolumes(this.lang);
      this.getCpdVolumes(this.profession, this.jurisdiction, this.lang);
    });

    this.getCpdTitles();
    this.getCpdStatuses();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}

export class SyncRequest
{
    AssetID: number=-1;

    OperationID: number;

    OrganizationName: string;

    Revert: boolean;

    OperationName: string;

}

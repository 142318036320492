import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EmsConfig } from '../../shared/emsConfig';
import { ConnexService } from '../connex.service';


import { UtilitiesService } from '../../shared/utilities.service';

@Component({
  selector: 'ate-list',
  templateUrl: 'ate-list.component.html',
  providers: [ConnexService]
})
export class ATEListComponent implements OnInit {
  @HostBinding('class') classAttribute: string = 'ate-list';

  public events: any[] = [];
  public answersForTranscript: any;
  public transcriptTitle: string;

  // used to toggle between editing and finished translating events
  public translatedEvents = false;
  public modalOpen: boolean = false;

  // filter variables
  ateSearch: string;
  ateStatus: string;
  activeFilter: string = 'editing';
  currentlySearching: boolean = false;
  activeFilterStore: string;
  ateStatusStore: string;

  selectedEventID: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public _emsConfig: EmsConfig,
    private _connexService: ConnexService,
    public _utilitiesService: UtilitiesService
  ) { }

  // if using the search bar ignore the other filter options
  usingSearchBar(text: any): void {
    if (this.ateSearch.length > 0) {
      if (!this.currentlySearching) {
        // store the current filter settings to apply back later
        this.currentlySearching = true;
        this.activeFilterStore = this.activeFilter;
        this.ateStatusStore = this.ateStatus;
      }

      // turn off the filters
      this.activeFilter = 'all';
      this.ateStatus = 'all';
    } else {
      // when search input is cleared, set back the filters
      this.activeFilter = this.activeFilterStore;
      this.ateStatus = this.ateStatusStore;
      this.currentlySearching = false;
    }
  }

  // update various pipe filters
  public updateWebchatFilters(status: string): void {
    this.ateStatus = status;
    this.activeFilter = status;
  }

  // reset filters
  public resetFilters(): void {
    this.activeFilter = 'all';
    this.updateWebchatFilters('all');
  }

  trimDate(date: string): string {
    return date.slice(0, -15);
  }

  updateATEWebchats(): void {
    this._connexService
      .updateATEWebchats()
      .subscribe(
        () =>
          alert(
            'Your request has been submitted. You will received an email shortly'
          ),
        error => alert(`An error has occured: ${error}`)
      );
  }

  createNewEvent(): void {
    // Set Selected Event to -1 for new Event
    this.selectedEventID = -1;
    if (this.router.url.includes('asktheexpert')) {
      this.router.navigate(['event-edit', this.selectedEventID], { relativeTo: this.route });
    } else {
      // if coming directly from cms default route (need to add Expert to url)
      this.router.navigate(['asktheexpert', 'event-edit', this.selectedEventID], { relativeTo: this.route });
    }
  }

  downloadWebChatTranscript(chatId: number, title: string) {
    this._connexService.getAnswersByChatID(chatId).subscribe(data => {
      console.log(data);
      this.transcriptTitle = title;
      this.answersForTranscript = data;
      this.modalOpen = true;
    });
  }

  ngOnInit(): void {
    this._connexService.getAllExpertEvents(null)
      .subscribe(response => {
        console.log(response);
        this.events = response;
        for (let i = 0; i < this.events.length; i++) {
          this.events[i].ExpertNameNormalized = this._utilitiesService.normalizeTerm(this.events[i].Expert.ExpertNameFormatted);
        }
        this.updateWebchatFilters('all');
      });
  }

  public closeModal() {
    this.modalOpen = false;
  }

  public stopProp(event: any) {
    event.stopPropagation();
  }
}

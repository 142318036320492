import { PipeTransform, Pipe } from '@angular/core';
import {DashboardItem} from './dashboard-item'

@Pipe({
  name: 'dashboardNameFilter'
})
export class DashboardNameFilter implements PipeTransform {
  transform(value: DashboardItem[], ...args: string[]): DashboardItem[] {
      if (value==null) {
        return null;
      }

      let filter: string = args[0] ? args[0].toLocaleLowerCase(): null;
      return filter ? value.filter((item:  DashboardItem) =>
      item.Organization.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}
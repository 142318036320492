import { Component, HostBinding } from '@angular/core';
import { SecuredRoutes } from 'app/shared/secured-routes';


@Component({
    selector: 'sync', // this renames the undefined element after router-outlet in the dom
    templateUrl: 'sync.component.html'
})

export class SyncComponent {

constructor(public securedRoutes: SecuredRoutes){}
  public closeNav: boolean = false;
}


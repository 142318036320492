import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { EmsConfig } from 'app/shared/emsConfig';

@Injectable({
  providedIn: 'root'
})

export class EncryptionService {
  private keySize = 256;
  private ivSize = 128;
  private saltSize = 256;
  private iterations = 1000;
  private iv: string | any;
  constructor(private _emsConfig: EmsConfig) { }
      public encrypt(msg):string {
        var salt = CryptoJS.lib.WordArray.random(this.saltSize / 8);

        var key = CryptoJS.PBKDF2(this._emsConfig.tokenEncryptionKey, salt, {
          keySize:  this.keySize / 32,
          iterations: this.iterations
        });
         this.iv = CryptoJS.lib.WordArray.random(this.ivSize / 8);

        var encrypted = CryptoJS.AES.encrypt(msg, key, {
          iv: this.iv,
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC

        });

        var encryptedHex = this.base64ToHex(encrypted.toString());
        var base64result = this.hexToBase64(salt + this.iv.toString() + encryptedHex);


        return base64result;
      }
      public decrypt(transitmessage):string {

        var hexResult = this.base64ToHex(transitmessage)

        var salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
        var iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
        var encrypted = this.hexToBase64(hexResult.substring(96));

        var key = CryptoJS.PBKDF2(this._emsConfig.tokenEncryptionKey, salt, {
          keySize: this.keySize / 32,
          iterations: this.iterations
        });

        var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
          iv: iv,
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC

        })

        return decrypted.toString(CryptoJS.enc.Utf8);
      }
      private hexToBase64(str) {
        return btoa(String.fromCharCode.apply(null,
          str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
        );
      }

      private base64ToHex(str) {
        for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
          var tmp = bin.charCodeAt(i).toString(16);
          if (tmp.length === 1) tmp = "0" + tmp;
          hex[hex.length] = tmp;
        }
        return hex.join("");
      }
}

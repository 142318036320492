import { Component, HostBinding, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { EmsConfig } from '../../shared/emsConfig';
import { ConnexService } from '../connex.service';

// Objects
import { AteAnswer } from '../Objects/AteAnswer';

@Component({
  selector: 'ate-expert-answered',
  templateUrl: 'ate-expert-answered.component.html',
  providers: [ConnexService]
})
export class ATEExpertAnswered implements OnInit {
  @HostBinding('class') classAttribute: string = 'ate-expert';

  @Input() questions: AteAnswer[];
  @Input() expertLang: string;

  constructor(
    public _emsConfig: EmsConfig,
    private _connexService: ConnexService) {
  }
  ngOnInit() {
    console.log("answered loaded");
    console.log(this.questions);

  }
}

<div class="section-padding aws-images">
  <h1>LifeSpeak Image Assets</h1>
  <div class="page-description">
    <p> This page displays the missing resized images processed by the AWS image processing tool.</p>
    <p> {{_emsConfig?.text?.EMS_CMS?.CMS_Image_Processor_Instructions}}</p>
  </div>
  <table class="display-table display-table--extra-padding" id="image-list">
    <thead>
      <tr>
        <th>Original file's folder path</th>
        <th>Missing Resized images</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let image of imageConfigDetails">
        <td>{{image?.AwsFolder}}
        </td>
        <td>
          <div class="missing-images" *ngFor="let missingImageName of  image?.MissingImageKeys">
            {{missingImageName}}
          </div>
          <div *ngIf="image?.MissingImageKeys.length===0">
            All Images has been resized successfully.
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
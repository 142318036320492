import { PipeTransform, Pipe } from '@angular/core';
import { Account } from './account';

@Pipe({
  name: 'accountSearchFilter'
})
export class AccountFilter implements PipeTransform {
  transform(value: Account[], ...args: string[]): Account[] {
      if (value == null) {
        return null;
      }

      const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
      // filter on the account name or the display name
      return filter ? value.filter((account: Account) =>
      account.OrganizationName.toLocaleLowerCase().indexOf(filter) !== -1 || account.DisplayName.English.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}

@Pipe({
  name: 'accountManagerFilter'
})
export class AccountManagerFilter implements PipeTransform {
  transform(value: Account[], ...args: string[]): Account[] {
      if (value == null) {
        return null;
      }

      const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
      return filter ? value.filter((account: Account) =>
      account.AccountAdminUsername?.toLocaleLowerCase().indexOf(filter) !== -1 || account.AccountBackupUsername?.toLocaleLowerCase().indexOf(filter) !== -1 ) : value;
  }
}

@Pipe({
  name: 'accountStatusFilter'
})
export class AccountStatusFilter implements PipeTransform {
  transform(value: Account[], ...args: string[]): Account[] {
      if (value == null) {
        return null;
      }

      const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
      // if search bar is being used we want to return any account that is not deleted
      if (filter === 'search-bar') {
        return value.filter((account: Account) =>
        account.AccountStatus.toLocaleLowerCase().indexOf('deleted') === -1);
      } else if (filter === 'all') {
        return filter ? value.filter((account: Account) =>
          account.AccountStatus.toLocaleLowerCase().indexOf('active') !== -1 || account.AccountStatus.toLocaleLowerCase().indexOf('prospect') !== -1 || account.AccountStatus.toLocaleLowerCase().indexOf('demo') !== -1 || account.AccountStatus.toLocaleLowerCase().indexOf('testing') !== -1) : value;
      } else {
        return value.filter((account: Account) =>
        account.AccountStatus.toLocaleLowerCase().indexOf(filter) !== -1);
      }

  }
}

<div class="section-padding">
    <h1>Expert Review</h1>

    <!-- beginning section-->
    <div *ngIf="ProcessCode==='begin'">
        <div class="expert-review-intro">
            The tool below provides a mailout to all experts with current volumes, asking them to review their video content. This helps ensure our video content is still accurate and up-to-date with the latest information.<br/>
            To begin the process, please indicate whether this is a test or live mailout and click CONTINUE.<br/><br/>
            Note:
            <ul class="expert-review-text">
                <li>Only System Admin can run Expert Review Tool.</li>
                <li>Experts will be sent their volumes that have been created before {{previousYear()}}.</li>
                <li>Test mailouts will only send the email to your account, it will NOT send to the expert(s).</li>
            </ul>
        </div>

        <div class="expert-review-begin-process">
            <div class="checkbox-container expert-review-is-testrun">
                <div class="expert-checkbox">
                    <input name="IsTestRun" [(ngModel)]="IsTestRun" type="checkbox">
                </div>
                This is a test run
            </div>
            <div class="expert-review-begin-mailout">
                <button class="btn  b-dark-blue ls-button-2 uppercase"
                    (click)=" GetExpertReviewItems()">Continue</button>
            </div>

        </div>
    </div>
    <div *ngIf="ProcessCode==='review'">
        <div class="expert-review-review">
            Please review the list below<br />
            <ul class="expert-review-text">
                <li>Select the expert(s) for the mailout. When you are done, click the “Yes, I am ready to begin mailout” button to begin the mailout. If you want to restart the entire process, click the “Start Again” button</li>
            </ul>
        </div>
        <div class="warning-note" *ngIf="IsTestRun">By default, all emails during a test run are sent to your LifeSpeak
            address</div>

        <div class="expert-review-filters" style="display:flex">
            <div class="filter-container">
                Mailout Status
                <div class="select-container">
                    <select class="setting__select-input select-input" [(ngModel)]="ExpertReviewFilterArgs.AlreadySent">
                        <option value="all">All</option>
                        <option value="false">Not yet sent</option>
                        <option value="true">Already Sent</option>
                    </select>
                </div>
            </div>

            <div class="filter-container">
                Email Address Status
                <div class="select-container">
                    <select class="setting__select-input select-input"
                        [(ngModel)]="ExpertReviewFilterArgs.NoEmailAddress">
                        <option value="all">All</option>
                        <option value="false">Email present</option>
                        <option value="true">Email missing</option>
                    </select>
                </div>
            </div>

            <div class="filter container">
                <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                  />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
                Search for expert
                <div class="expert-review-search-container">
                <input class="expert-review-search" [(ngModel)]="ExpertSearch"  type="search" placeholder="Expert Name">
                </div>
              </div>


        </div>
        <div class="expert-review-begin-mailout">
            <button class="btn  b-dark-blue ls-button-2 uppercase"  style="margin-right:10px" (click)="SubmitMailoutRequest()">Yes, I am
                ready to begin mailout</button>
            <button class="btn  b-dark-blue ls-button-2 uppercase" (click)="RunAnotherMailout()">Start Again</button>
        </div>
        <!--review section-->
        <table class="display-table display-table--extra-padding">
            <thead>
                <tr>
                    <th> <input id="review-select-all" type="checkbox" [(ngModel)]="AllExpertsSelected"
                            (change)="ToggleAllExperts()" />Added To Mailout</th>
                    <th>Expert ID</th>
                    <th>Expert Name</th>
                    <th>Already Sent</th>
                    <th>Number Of Volumes For Review</th>
                    <th>Number Of Volumes Confirmed This Year</th>
                    <th>Email for Mailout</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of ExpertItems | expertReviewFilter : ExpertReviewFilterArgs : ExpertSearch">
                    <td>
                        <input *ngIf="!item.NoEmailAddress" [(ngModel)]="item.AddToMailout" type="checkbox" />
                        <span *ngIf="item.NoEmailAddress" class="warning-note">!</span>
                    </td>
                    <td>{{item.ExpertID}}</td>
                    <td>{{item.ExpertName}}</td>
                    <td>{{item.AlreadySent}}</td>
                    <td>{{item.NumberOfVolumes}}</td>
                    <td>{{item.VolumesConfirmedThisYear}}</td>
                    <td [ngClass]="{'warning-note': item.NoEmailAddress}">
                        {{item.Email ==='NO_EMAIL'?'No Email Provided!':item.Email }}</td>

                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="ProcessCode==='complete' && ErrorOccured===false">
        <div class="expert-review-review">
            The mailout request has begun, and you will receive a full report when it has completed<br />
            Please <a class="report-link" (click)="RunAnotherMailout()">click here</a> to run another mailout

        </div>
    </div>
    <div *ngIf="ProcessCode==='complete' && ErrorOccured===true">
        <div class="expert-review-review">
            Sorry, an error has occured. Please submit a ticket to the dev team<br />
        </div>
    </div>
</div>

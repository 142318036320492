import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { EmsConfig } from './shared/emsConfig';
import { Observable } from 'rxjs';
import { SecuredRoutes } from 'app/shared/secured-routes';

@Injectable()
export class AuthGuardSysAdmin implements CanActivate {

  constructor(private router: Router, private emsConfig: EmsConfig, private securedRoutes: SecuredRoutes) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    const urlStr = route.url.map(token => token.path).join(' ');
    if (this.emsConfig.loginToken?.IsValid && this.securedRoutes.CurrentUserHasAccess(urlStr, false) ) {
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }

  }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, tap, throwError } from 'rxjs';
import { EmsConfig } from './shared/emsConfig';
import { SpinnerService } from './shared/spinner.service';
import { SyncUpdateService } from './sync/sync-update.service';
import { AppConfigService } from './services/AppConfigService.service';

// can inject the emsConfig directly in here to change variables in that service
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  public syncInfo: any = {};

  public prodCacheEndpoints: string[] = ['getitemsincache', 'clearcacheforasset'];

  constructor(
    private _spinnerService: SpinnerService,
    private _syncUpdateService: SyncUpdateService,
    private _configService: AppConfigService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log(req);
    switch (true) {
      // don't add standard headers if login request or if request is to public site https://lifespeak.com, flag as external request
      case (req.url.indexOf('Authentication/personallogin') !== -1):
      case (req.url.indexOf('/Password/resetpassword') !== -1):
      case (req.url.indexOf('/confirmresetpassword') !== -1):
      case (req.url.indexOf('//lifespeak') !== -1):
      case (req.url.indexOf('/appsettings.json') !== -1):
        break;
      // add standard headers if internal request or if localhost (and if environment is dev) - for messaging tool requests
      case (req.url.indexOf('lifespeak') !== -1):
      case (req.url.indexOf('localhost') !== -1 && this._configService.configs.Environment === 'dev'):
        req = req.clone({ headers: this.getStandardHttpClientHeaders() });


        // this prevents the file from caching and breaking the spinner
        // add a cachebreaker to any GET request that doesn't have it
        if (req.method === 'GET' && !req.urlWithParams.includes('_cb')) {
          const cacheBuster: number = Math.floor(Math.random() * 500000 + 1);
          req = req.clone({
            params: req.params.set(
              '_cb', cacheBuster.toString()
            )
          })
        }

        break;
    }

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        //console.log(event);
        // console.log('X-LS-Sequence: ' + event.headers.get('X-LS-Sequence'));
        // console.log('X-LS-Sync-Result: ' + event.headers.get('X-LS-Sync-Result'));
        const seqVal = event.headers.get('X-LS-Sequence');
        const syncResult = event.headers.get('X-LS-Sync-Result');

        // if we find a seqVal in the response header, remove it from the request ID array
        if (seqVal) {
          this._spinnerService.removeRequestId(parseInt(seqVal, 10));
        }

        // if a sync result is detected, update the sync status
        if (syncResult) {
          const syncStats = syncResult.split(',');
          this.updateSyncVals(syncStats);
        }
      }
    },
      (error) => {
        console.log(error);
        // If there is an error turn off the spinner
        this._spinnerService.requestError();
        // in the event of an error stop the spinner
        return throwError(error);
      }));
  }

  // parse out the sync vals and send to the sync update service
  updateSyncVals(syncStats): void {
    this.syncInfo.operationID = parseInt(syncStats[0], 10);
    this.syncInfo.isSynced = syncStats[1] === 'true';
    this.syncInfo.adminUser = syncStats[2];
    if (syncStats.length > 3) {
      //instantCacheClear flag is set in "emsoperationid|cacheclearflag" format like 3|True or 3|False
      this.syncInfo.instantCacheClear = syncStats[3].split('|')[1] === "True";//check against string to convert string to bool
      this.syncInfo.emsOperationId = parseInt(syncStats[3].split('|')[0]);
    }
    this._syncUpdateService.updateSyncData(this.syncInfo);
  }
  // add the standard headers for an api request
  getStandardHttpClientHeaders(): any {
    // console.log('getStandardHttpClientHeaders');
    var headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('X-LS-Auth-Token', this.getApiToken())
      .set('X-LS-CORS-Template', this._configService.configs.CORSTemplate)
      .set('X-LS-Sequence', this._spinnerService.setRequestId().toString());
    if (this._configService.configs.loginToken) {
      headers= headers.set('X-LS-Auth-User-Token', this._configService.configs.loginToken.Token);
    }
    return headers;
  }
  private getApiToken() {
    //get domain from the url
    const domain = window.location.href;
    if (domain.indexOf('//expert') !== -1) {
      return this._configService.configs['apiToken-expert']
    }
    return this._configService.configs['apiToken-ems'];
  }

}

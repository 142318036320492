import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmsConfig } from '../../shared/emsConfig';

@Injectable()
export class AiService {

    private _getChatCompletion = this.emsConfig.apiEndpoint + '/ai/getChatCompletion';
    private _getTranscriptionFromUrl = this.emsConfig.apiEndpoint + '/ai/gettranscriptionfromurl';
    private _getTranslationFromUrl = this.emsConfig.apiEndpoint + '/ai/gettranslationfromurl';
  
    constructor(private _httpClient: HttpClient, private emsConfig: EmsConfig) { }

    // COMPLETION 

    getChatCompletion(prompt: string, content: string, temperature: number = 0.5): Observable<any> {
        const body = { prompt: prompt, content: content, temperature: temperature };
        return this._httpClient.post<any>(this._getChatCompletion, body);
    }

    // AUDIO & SPEECH TO TEXT

    getTranscriptionFromUrl(audio: any, audioFormat: string, language: string): Observable<any> {
        const body = { audio: audio, language: language, audioFormat: audioFormat };
        return this._httpClient.post<any>(this._getTranscriptionFromUrl, body);
    }

    getTranslationFromUrl(audio: any, audioFormat: string): Observable<any> {
        const body = { audio: audio, audioFormat: audioFormat };
        return this._httpClient.post<any>(this._getTranslationFromUrl, body);
    }
 
}


// Angular Things
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

// Feature Routing Module
import { SyncRoutingModule } from './sync-routing.module';

// Shared Module
import { SharedModule } from '../shared/shared.module';

// Components
import { SyncComponent } from './sync.component';
import { OrganizationSyncComponent } from './organization-sync.component';
import { AssetSyncComponent } from 'app/sync/asset-sync.component';

// providers
import { SyncService } from './sync.service';
import { SyncUpdateService } from './sync-update.service';

// Filters and Pipes
import { SyncTableFilter, SyncOrgNameFilter } from './sync-filters.pipe';


@NgModule({
  imports: [
    SharedModule,
    SyncRoutingModule
  ],
  declarations: [
      OrganizationSyncComponent,
      AssetSyncComponent,
      SyncComponent,
      SyncTableFilter,
      SyncOrgNameFilter
  ],
  providers: [
    SyncService,
    SyncUpdateService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ]
})

export class SyncModule {}

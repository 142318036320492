import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginToken } from '.././shared/login-token';
import { SpinnerService } from '.././shared/spinner.service';
import { Router } from '@angular/router';
import { AppConfigService } from 'app/services/AppConfigService.service';

@Injectable()
export class EmsConfig {

  // storage for loginToken
  public loginToken: LoginToken;

  // name of localstorage key for login token
  private loginTokenKey: string;

  // token for organization level access to the api (users submit their own token when making requests as well)
  public apiToken: string;

  // endpoint for root level api access
  public apiPath: string;

  // endpoint for api access
  public apiEndpoint: string;

  // endpoint for api access
  public libraryEndpoint: string;

  // endpoint for prod api access
  public apiProdEndpoint: string;

  // URL to access reporting methods in api project
  public apiReportingEndpoint: string;

  // cors template for the ems connection to api
  public corsTemplate: string;

  public emsDomain: string;

  // check the EMS environment variable
  public emsEnvironment: string;

  // if permissions related updates are made, this build version will be udpated and used to force log-out users
  public buildVersion: string;

  // messaging tool
  public messagingPath: string;

  public assetPreviewLink: string;

  // flag to check which infrastructure the code is in (Azure vs On-prem)
  public infraLocation: string;

  public powerBIDashboardEndpoint: string;

  public powerBIOrganizationEndpoint: string;

  //special token for clearing prod cache
  public clearCacheToken: string;

  public signalRHubURL: string;
  public tokenEncryptionKey: string;
  public assetPath: string;
  public secureAssetPath: string;
  public contentAssetPath: string;
  public LifeSpeakCaptionPath: string;
  public LifeSpeakVideoPath: string;
  //End point to authenticate user based on new aspcore identity
  public authApiEndpoint: string;
  public liveDomain: string;
  public previewDomain: string;

  // Add this property to the end of a standard yyyy-MM-dd date format to make it inclusive
  // ie. expire at the end of the day instead of the start
  public inclusiveEndDate: string = " 23:59:59.999";
  public text: any;

  private requestSequence: number = 0;

	public enableAI: boolean;
	public azureAISpeechServiceKey: string;
	public azureAISpeechServiceRegion: string;
	
  constructor(private _spinnerService: SpinnerService,
    private _httpClient: HttpClient,
    private router: Router,
    private configService: AppConfigService) {

    this.setConfigs();
    this.loadLanguageValues();
  }

  private setConfigs() {
    //check if user has a saved session
    this.configService.loginToken$.subscribe(data => { this.loginToken = data;    }, error => {
      console.log('Failed to load saved session ',error);
    });

    //Start assigning config values from  appsettings.json
    this.assignConfigValues();
  }

  private assignConfigValues() {
    // name of localstorage key for login token
    this.loginTokenKey = this.configService.configs.loginTokenKey;
    // token for organization level access to the api (users submit their own token when making requests as well)
    this.apiToken = this.getApiToken();
    // endpoint for root level api access
    this.apiPath = this.configService.configs.apiPath;
    // endpoint for api access
    this.apiEndpoint = this.configService.configs.apiEndpoint;
    // endpoint for api access
    this.libraryEndpoint = this.configService.configs.libraryEndpoint;
    // endpoint for prod api access
    this.apiProdEndpoint = this.configService.configs.apiProdEndpoint;
    // URL to access reporting methods in api project
    this.apiReportingEndpoint = this.configService.configs.apiReportingEndpoint;
    // cors template for the ems connection to api
    this.corsTemplate = this.configService.configs.CORSTemplate;
    this.emsDomain = this.getEmsSubDomain();
    // check the EMS environment variable
    this.emsEnvironment = this.configService.configs.Environment;
    // if permissions related updates are made, this build version will be udpated and used to force log-out users
    this.buildVersion = this.configService.configs.BuildVersion;
    // messaging tool
    this.messagingPath = this.configService.configs.messagingPath;
    this.assetPreviewLink = this.configService.configs.assetPreviewLink;
    // flag to check which infrastructure the code is in (Azure vs On-prem)
    this.infraLocation = this.configService.configs.infraLocation;
    this.powerBIDashboardEndpoint = this.configService.configs.powerBIDashboardEndpoint;
    this.powerBIOrganizationEndpoint = this.configService.configs.powerBIOrganizationEndpoint;
    //special token for clearing prod cache
    this.clearCacheToken = this.configService.configs['apiToken-cache'];
    this.signalRHubURL = this.configService.configs.SignalRHubURL;
    this.tokenEncryptionKey = this.configService.configs.TokenEncryptionKey;
    this.assetPath = this.configService.configs.AssetPath;
    this.secureAssetPath = this.configService.configs.secureAssetPath;
    this.contentAssetPath = this.configService.configs.contentAssetPath;
    this.LifeSpeakCaptionPath = this.configService.configs.LifeSpeakCaptionPath;
    this.LifeSpeakVideoPath = this.configService.configs.LifeSpeakVideoPath;
    //End point to authenticate user based on new aspcore identity
    this.authApiEndpoint = this.configService.configs.authApiEndpoint;
    this.liveDomain = this.configService.configs.LiveDomain;
    this.previewDomain = this.configService.configs.PreviewDomain;
    this.loginToken = this.configService.configs.loginToken;
    this.enableAI = this.configService.configs.enableAI == "true";
    this.azureAISpeechServiceKey = this.configService.configs.azureAISpeechServiceKey;
    this.azureAISpeechServiceRegion = this.configService.configs.azureAISpeechServiceRegion;
  }

  // sets the login token variable -- optionally stores locally for extra access
  setLoginToken(loginToken: LoginToken, storeToken: boolean): void {
    this.loginToken = loginToken;
    if (storeToken) {
      this.setSessionCookie(this.loginToken.Token, 1000);
    }
    this.configService.configs.loginToken = loginToken;
  }

  // if there is a login token, returns it -- otherwise returns invalid
  getLoginToken(): LoginToken {
    if (this.loginToken) {
      return this.loginToken;
    }
    return this.loginToken;
  }

  public get UserRole(): string {
    return this.loginToken === undefined ? 'anon' : this.loginToken.Role;
  }



  public parseObject(val: any) {
    if (typeof (val) === 'string') {
      val = JSON.parse(val);
    }
    return val;
  }

  public get PermissionVal(): number {
    return this.loginToken?.PermissionVal;
  }

  public get UserFirstName(): string {
    return this.loginToken?.FirstName;
  }

  public userHasStandardNavAccess(): boolean {
    return (this.UserRole === 'systemadmin' || this.UserRole === 'accountadmin');
  }

  deleteLoginToken() {
    this.loginToken = new LoginToken();
    this.setSessionCookie(this.loginTokenKey, -1);
    this.configService.changeLoginTokenStatus(this.loginToken);
  }

  private getApiToken() {
    //get domain from the url
    const domain = window.location.href;

    if (domain.indexOf('//expert') !== -1) {
      return this.configService.configs['apiToken-expert']
    }
    return this.configService.configs['apiToken-ems'];
  }

  private getEmsSubDomain() {
    //get domain from the url
    const url = window.location.href;
    //get subdomain from url
    return url.split('.')[0].split('//')[1];
  }

  getFileUploadHeaders(): any[] {
    return [
      { 'name': 'X-LS-Auth-Token', 'value': this.apiToken },
      { 'name': 'X-LS-CORS-Template', 'value': this.corsTemplate },
      { 'name': 'X-LS-Auth-User-Token', 'value': this.loginToken.Token }];
  }

  private setSessionCookie(value, days): void {
    var d = new Date;
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${this.loginTokenKey}=${value};path=/;expires=${d.toUTCString()}`;
  }

  //Get language values from ems api
  private loadLanguageValues() {
    var langEndpoint = `${this.apiPath}api/language/emslanguageesources`;

    this._httpClient.get<any>(langEndpoint).subscribe(data => {
      this.text = JSON.parse(data);
    }, error => {
      console.log(error);
      this.router.navigate(['/login']);
    });
  }

  // add the standard headers for an api request
  getStandardHttpClientHeaders(): any {
    console.log('getStandardHttpClientHeaders');
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('X-LS-Auth-Token', this.apiToken)
      .set('X-LS-CORS-Template', this.corsTemplate)
      .set('X-LS-Auth-User-Token', this.loginToken.Token)
      .set('X-LS-Sequence', this._spinnerService.setRequestId().toString());
    return headers;
  }

}

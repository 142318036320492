<div class="section-padding">

  <h1> Page List</h1>
  <div class="sort-container">


   <!--Search Options-->
    <div class="Language-container">
      <h4 class="basic-info__heading setting__heading ls-subheading">
        Select Language
      </h4>
      <div class="select-container">
        <select class="setting__select-input select-input" name="volumeLanguage" [(ngModel)]="Language">
            <option value="EN">EN</option>
            <option value="FR">FR</option>
            <option value="SP">SP</option>
          </select>
      </div>
    </div>
    <div class="sort-container__searchbar">
      <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
      <input class="searchbar__input" [(ngModel)]="SearchItem" type="search" placeholder="Search for text">
    </div>
    <div class="search-button-container">
      <button (click)="searchTextItem()" type="button" class="btn module-submit">
          Search
        </button>

      <button (click)="ClearSearch()" type="button" class="btn module-submit">
          Clear
      </button>
    </div>
    <div class="sort-container__searchbar resource_searchbar">
        <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        <input class="searchbar__input" [(ngModel)]="SearchResourceItem" type="search" placeholder="Search for Page">
      </div>
  </div>
  <div>

     <!--Search Table-->
    <table class="display-table" *ngIf="Search==true">
      <thead>
        <tr>
          <th>Page Name</th>
          <th>Resource Name</th>
          <th>English Text</th>
          <th>French Text</th>
          <th>Spanish Text</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let items of searchResult">
          <td>{{items.PageName}}</td>
          <td class="edit-link" (click)="editResource(items.ID, items.GroupId)">{{items.ResourceKey}}</td>
          <td innerHtml={{trimText(items.ResourceType.English)}}></td>
          <td innerHtml={{trimText(items.ResourceType.French)}}></td>
          <td innerHtml={{trimText(items.ResourceType.Spanish)}}></td>
        </tr>
      </tbody>
    </table>
  </div>

 <!--Page List-->
  <div *ngIf="Search==false">
    <table class="display-table">
      <thead>
        <tr>
          <th>Page ID</th>
          <th>Page Name</th>
          <th>Date Created</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let page of pageList| textResourceFilter:SearchResourceItem">
          <tr >
            <td>{{page.ID}}</td>
            <td class="edit-link" (click)="toggleResouces(page.ID)">{{page.PageName}}</td>
            <td>{{trimDate(page.DateCreated)}}</td>
          </tr>
          <tr *ngIf="rowSelected==page.ID" style="background:none;">
            <td colspan="3" style="background-color: wheat;">

               <!--Resource List-->
              <table class="resource-table">
                <thead>
                  <tr>
                    <th style="width:15%">Resource Name</th>
                    <th style="width:30%">English Text</th>
                    <th style="width:30%">French Text</th>
                    <th style="width:25%">Spanish Text</th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let item of resourceList">
                    <td class="edit-link" (click)="editResource(page.ID, item.GroupId)">{{item.ResourceKey}}</td>
                    <td innerHtml={{trimText(item.ResourceType.English)}}></td>
                    <td innerHtml={{trimText(item.ResourceType.French)}}></td>
                    <td innerHtml={{trimText(item.ResourceType.Spanish)}}></td>
                  </tr>

                </tbody>
              </table>
            </td>
            <button class="btn--fab b-dark-blue cms-container__manage cms-container__fab--add" (click)="editResource(page.ID,'-1')">
                <svg viewBox="0 0 24 24"><path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" /></svg>
              </button>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="add-new-page-container">
    <h3>Add a new page</h3>
    <div class="new-page-form-container">
      <form action="" class="new-page-form">
        <div class="basic-info__setting lang-setting">
          <div class="input-container">
            <input type="text" class="basic-info__text setting__text-input text-input" name="newPage" [(ngModel)]="newPage.PageName" placeholder="Type new page name">
          </div>
        </div>
        <button class="btn" type="button" (click)="createNewPage(newPage.PageName)">Create</button>
      </form>
    </div>
  </div>
</div>

import { Component, HostBinding, OnInit } from '@angular/core';;
import { CmsService } from 'app/cms/cms.service';
import { EmsConfig } from '../.././shared/emsConfig';

@Component({
  selector: 'cms-reports',
  templateUrl: 'cms-reports.component.html'
})
export class CMSReportingComponent implements OnInit {

  public cmsReports: any;

  public powerBIMessage: string = this.emsConfig.text.EMS_Reporting.CMS_Reports_PowerBI_Message;
  
  constructor(private cmsService: CmsService, private  emsConfig: EmsConfig ){


  }
  
  ngOnInit() {

    this.cmsService.getCMSReports().subscribe(reports => {
      this.cmsReports = this.emsConfig.parseObject(reports);
      console.log(reports);
    })

  }

  public  getCMSReport(apiEndpoint: string, reportName: string): void{
    const reportLink = `${this.emsConfig.apiReportingEndpoint}/${apiEndpoint}?reportName=${reportName}&token=${this.emsConfig.getLoginToken().Token}`;
    window.location.href = reportLink;
  }


}

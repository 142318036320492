<h2 mat-dialog-title>Result of query</h2>
<mat-dialog-content class="mat-typography">
	
	
	<table width="100%">
		<thead>
              <tr>
                <th></th>
              </tr>
            </thead>
		<tbody>
    		<tr>
				<td>Question</td>
    		</tr>
    		
    		<tr>
				<td>
					<textarea class="aitextarea" matInput placeholder="Ex. Pizza" value="Sushi" [(ngModel)]="data.question" ></textarea>
				</td>
			</tr>
			
			<tr>
				<td>Answer</td>
    		</tr>

			<tr>
				<td>
					<textarea class="aitextarea" matInput placeholder="Ex. Pizza" value="Sushi" [(ngModel)]="data.answer" ></textarea>
				</td>	
			</tr>
			<tr>
				<td><button type="button" class="" (click)="detectCategoriesCustomEdit()" [disabled]="networkCall">Save Question</button> </td>	
			</tr>
		</tbody>
	</table>
    
    
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
<div class="section-padding">
  <div class="sort-container">
    <div class="sort-container__searchbar">
      <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
      <input class="searchbar__input" [(ngModel)]="expertSearch" type="search" placeholder="Search for expert">
    </div>
  </div>

  <div class="expert-container__quiz-list card-container">
    <div class="expert-card card" (click)="onSelect()" 
      *ngFor="let expert of allExperts | expertSearchFilter:expertSearch"
      (blur)="deselectExpert($event)" (focus)="selectExpert($event)" data-id="{{expert.ExpertID}}" tabindex="-1">
      <div class="expert-card__image"
        [style.background-image]="'url(' + _emsConfig.assetPath + '/ImageAssets/originals/experts/' + expert.PhotoUrl+')'">
      </div>
      <h4 class="expert-card__title ls-subheading expert-card__full-name f-dark-blue">{{expert.ExpertName.English}}
      </h4>
    </div>
  </div>

  <button class="btn--fab b-dark-blue cms-container__fab cms-container__fab--add" *ngIf="!showEditButton" title="Add new expert"
    (click)="createNewExpert()">
    <svg viewBox="0 0 24 24">
      <path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
  </button>
</div>
import { Component, HostBinding, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { EmsConfig } from '../shared/emsConfig';
import { LoginService } from './login.service';

import 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'change-pass',
  templateUrl: './changePass.component.html'
})
export class ChangePassComponent implements OnInit {
  @HostBinding('class') login: string = 'login';
  public loginTitle: string = 'Engagement Management System';
  public password: string;
  public reEnterPassword: string;
  public username: string;
  private headers: any;
  public message: string;

  private userNameParam: string;
  private passResetToken: string;
  private jwtHelper: JwtHelperService;

  constructor(
    private _httpClient: HttpClient,
    public emsConfig: EmsConfig,
    private router: Router,
    private _loginService: LoginService,
    private route: ActivatedRoute) {

    this.jwtHelper = new JwtHelperService();
  }
  ngOnInit(): void {
    this.headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json;charset=UTF-8')
      .set('X-LS-Auth-Token', this.emsConfig.apiToken)
      .set('X-LS-CORS-Template', this.emsConfig.corsTemplate);
    this._getDataFromQueryparam();
  }

  //Get change password request token from the query param.
  private _getDataFromQueryparam() {
    this.route.queryParams.subscribe(params => {
      this.passResetToken = params['token'];
      if (this.passResetToken) {
        console.log(this.passResetToken);
        this._getResetPasswordParam();
      }
    });
  }

  //get the username and password reset code out of token
  private _getResetPasswordParam() {
    let jwt = this.jwtHelper.decodeToken(this.passResetToken);
    this.userNameParam = jwt.UserName;
    this.passResetToken = jwt.ResetCode;
    this.username = this.userNameParam;
    if (!this.userNameParam || !this.passResetToken) {
      this.message =  this.emsConfig.text.EMS_General?.Login_Invalid_Reset_Token;
    }
  }

  public ChangePassword() {
    if (this.password !== this.reEnterPassword) {
      return;
    }
    if (this.username === undefined)
      return;

    if (this.userNameParam && this.passResetToken) {
      console.log("reset account password");
      var code = atob(this.passResetToken);
      var ResetPasswordModel = {
        UserName: this.username,
        Token: code,
        Password: this.password
      }

      const body = JSON.stringify(ResetPasswordModel);
      const authEndpoint = `${this.emsConfig.authApiEndpoint}/Password/confirmresetpassword`;

      return this._httpClient.post<any>(authEndpoint, body, { headers: this.headers }).subscribe(data => {
        console.log(data);
        if (data.Success) {
          this.message = this.emsConfig.text.EMS_General?.Password_Changed;
        }
      }, error => {
        console.log(error); this.message = this.emsConfig.text.EMS_General?.Login_Error;
        //If password reset token is invalid or expired ask user to reset password again.
        if (error.error[0]?.Code === 'InvalidToken') {
          this.message = 'Token is expired, Please re-try resetting password.';
        }
        else {
          this.message = this.emsConfig.text.EMS_General?.Login_Error;
        }
      });
    }
  }

}

<h3>Trash Bin</h3>
<table class="display-table">
  <thead>
    <tr>
      <th>ID</th>
      <th>Time</th>
      <th>Nickname</th>
      <th>Question</th>
      <th>Restore</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let question of questions">
      <td>
        <div class="question-id">
          <svg *ngIf="question.QuestionType > 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
            viewBox="0 0 24 24" fill="#006B91">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
          </svg>
          {{question.QuestionID}}
        </div>
      </td>
      <td>{{question.DateSubmittedFormatted}}</td>
      <td>{{question.Email}}</td>
      <td class="max-q-width trash-question question-container" [innerHTML]="question.Question"></td>
      <td class="center-item"><button (click)="restoreQuestion(question)" class="btn ls-button-1">Restore</button></td>
    </tr>
  </tbody>
</table>
// Angular Things
import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from '.././home/login.component';
import { DashboardComponent } from '.././home/dashboard.component';
import { AuthGuard } from '.././authGuard';
import { AuthGuardSysAdmin } from '.././authGuardSysAdmin';

import { ClearCacheComponent } from 'app/admin/clear-cache.component';
import { BlogComponent } from 'app/blog/blog.component';
import { MessagesComponent } from 'app/messages/messages.component';
import { CampaignBulkbuildComponent } from 'app/campaign-bulkbuild/campaign-bulkbuild.component';
import { ChangePassComponent } from 'app/home/changePass.component';
import { ContentFusionMigrationComponent } from 'app/admin/content-fusion-migration.component';

//to secure a route add the [AuthGuard] decorator for pages that only allow system admins, use AuthGuardSysAdmin
export const appRoutes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'changepassword', component: ChangePassComponent },
  { path: 'clearcache', component: ClearCacheComponent, canActivate: [AuthGuardSysAdmin] },
  { path: 'migration', component: ContentFusionMigrationComponent, canActivate: [AuthGuardSysAdmin] },
  {
    path: 'blog', component: BlogComponent,
    loadChildren: () => import('app/blog/blog.module').then(m => m.BlogModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'adminusers',
    loadChildren: () => import('app/admin/adminuser.module').then(m => m.AdminUserModule),
    canActivate: [AuthGuardSysAdmin]
  },
  {
    path: 'userprofile',
    loadChildren: () => import('app/userprofile/userprofile.module').then(m => m.UserProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'messages',
    loadChildren: () => import('app/messages/messages.module').then(m => m.MessagesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'accounts',
    loadChildren: () => import('app/accounts/accounts.module').then(m => m.AccountsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'marathon',
    loadChildren: () => import('app/connex/marathon/marathon.module').then(m => m.MarathonModule),
    canActivate: [AuthGuard]
  },
  { path: 'addtocampaign', component: CampaignBulkbuildComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }

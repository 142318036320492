<div class="cms-tipsheets-container section-padding">
  <div class="all-tipsheets-container">
    <div class="get-connected__lang-button-container lang-button-container">
      <button class="btn--toggle get-connected__lang-switch-button lang-switch-button" [ngClass]="{'active': lang === 'EN'}"
        (click)="setLang('EN')">
        {{_emsConfig.text?.EMS_General.EN}}
      </button>
      <button class="btn--toggle get-connected__lang-switch-button lang-switch-button" [ngClass]="{'active': lang === 'FR'}"
        (click)="setLang('FR')">
        {{_emsConfig.text?.EMS_General.FR}}
      </button>
    </div>

    <div class="tipsheet-container__sort-container sort-container">
      <div class="sort-container__searchbar">
        <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        <input class="searchbar__input" [(ngModel)]="tipsheetSearch" (ngModelChange)="usingSearchBar($event)" type="search"
          placeholder="Search for tipsheets">
      </div>


      <div class="sort-container__list">
        <p class="ls-body-2">Sort</p>
        <ul class="sort-container__options">
          <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'all'}" (click)="resetFilters()">All</li>
          <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'live'}" (click)="updateVolumeFilters('live')">Active</li>
          <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'testing'}" (click)="updateVolumeFilters('testing')">Testing</li>
          <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'retired'}" (click)="updateVolumeFilters('retired')">Retired</li>
          <li class="sort-container__list-item pointer" [ngClass]="{active: activeFilter === 'qa'}" (click)="updateVolumeFilters('qa')">QA</li>
        </ul>
      </div>
    </div>

    <div class="tipsheet-container__tipsheet-list">
      <div class="tipsheet-card info-container__text-container" *ngFor="let tipsheet of tipsheets | tipsheetSearchFilter:tipsheetSearch | tipsheetStatusFilter:tipsheetStatus" (click)="onSelect(tipsheet.ID)">
        <h4 class="tipsheet-card__title ls-title f-dark-blue">{{tipsheet.VolumeTitle}}</h4>
      </div>
    </div>
  </div>

  <button class="btn--fab b-dark-blue cms-container__fab cms-container__fab--add" (click)="createNewTipsheet()">
    <svg viewBox="0 0 24 24">
      <path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" /></svg>
  </button>
</div>

// Angular Things
import { Component, OnInit, OnDestroy, Inject, NgModule } from '@angular/core';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, } from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
// EMS Things

import { UtilitiesService } from 'app/shared/utilities.service';
import { CmsService } from 'app/cms/cms.service';

// third party

import { FormsModule } from '@angular/forms';
import { DialogGenericAnswerContentDialog } from './GenericAIAnswer.component';
import { DialogGenericAddTemplate } from './AddAITemplate.component';
import { SnackbarService } from 'app/services/Snackbar.service';

import { CommonModule } from '@angular/common';
//start paging
import {ChangeDetectionStrategy, Input} from "@angular/core";
import {Observable, of} from 'rxjs';
import { delay, map, tap, timeout } from 'rxjs/operators';
import { NgxPaginationModule } from 'ngx-pagination'; 

interface IServerResponse {
    items: Template[];
    total: number;
}
//end paging

type Template = {
	ID: number;
	Template: string;
	Name: string;
	Category: string;
	Lang_Code: string;
	SendParameters: boolean;
};

export interface DialogData {
	templates: Array<Template>;
	mainId: number
	subId: number
	category: string,
	categoryType: string,
	templateLanguage: string
}

@Component({
	selector: 'dialog-content-generic-answer-dialog',
	templateUrl: 'CustomAITemplates.component.html',
	standalone: true,
	imports: [MatDialogModule, MatButtonModule, MatFormFieldModule, MatInputModule, FormsModule, MatCheckboxModule, CommonModule, NgxPaginationModule, MatTooltipModule],
	//changeDetection: ChangeDetectionStrategy.OnPush //paging
})
export class DialogContentExampleDialog implements OnInit {
	constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public _utilitiesService: UtilitiesService, public _cmsService: CmsService,
		public dialog: MatDialog, public _snackbarService: SnackbarService) { }

	networkCall: boolean = false;
	
    pageSize:number = 4;

	@Input('data') templates: Template[] = [];
	asyncTemplates: Observable<Template[]>;
	p: number = 1;
	total: number;
	loading: boolean;
	
	ngOnInit() {
		this.getPage(1);
	}

	getPage(page: number) {

		this.loading = true;

		this._cmsService.getAmazonQTemplates(this.data.category, this.data.templateLanguage, page, this.pageSize)
			.subscribe(
				data => {
					//this.networkCall = false;
					if (data.succeeded === false) {
						this._snackbarService.error('Error Templates not extracted.');
					} else {

						var total = this.total;
						if (page == 1) {
							total = data.total;
							//only update on page 1
						}
						//this.asyncTemplates = data.templates;
						this.templates = data.templates;
						//not ideal/needed. dunno how to get rid of this. UI is not responding without it.
						this.asyncTemplates = serverCall(data.templates, page, this.pageSize, total).pipe(
							tap(res => {
								this.total = total;
								this.p = page;
								this.loading = false;
							}),
							map(res => res.items)
						);
					}
				},
				err => {

				},
			);
	}

	addTemplate(): void {
		const dialogRef = this.dialog.open(DialogGenericAddTemplate, {
			data: {
				category: this.data.category,
				templateLanguage: this.data.templateLanguage,
			},
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`);
			this._snackbarService.success('Close and reopen to refresh data.');
		});
	}

	deleteTemplate(templateItem: Template): void {
		this.networkCall = true;

		this._cmsService.deleteAITemplate(templateItem.Category, templateItem.Lang_Code,  templateItem.ID)
			.subscribe(
				data => {
					this.networkCall = false;
					if (data.succeeded === false) {
						this._snackbarService.error('Delete failed.');

					} else {
						this._snackbarService.success('Success.');

						let myArray: Array<Template> = [];

						for (var i = 0; i < this.data.templates.length; i++) {
							const item: Template = this.data.templates[i];
							if (item.ID != templateItem.ID) {
								myArray.push(item);
							}
						}
						this.data.templates = myArray;

					}
				},
				err => {
					this.networkCall = false;
				},
			);
	}

	detectCategoriesCustomEdit(mainId: number, subId: number, categoryType: string, templateItem: Template): void {
		this.networkCall = true;

		var rString = this._utilitiesService.randomString(32);

		this._cmsService.questionContent(mainId, categoryType, subId, rString, templateItem.Template, templateItem.SendParameters)
			.subscribe(
				data => {
					this.networkCall = false;
					if (data.succeeded === false) {
					} else {

						console.info('Received:');
						console.info(data.value);

 						var myWidth = window.innerWidth + 'px';
						const dialogRef = this.dialog.open(DialogGenericAnswerContentDialog, {
							width: myWidth,
							data: {
								answer: data.value.Answer,
								templateId: templateItem.ID,
								category: this.data.category,
								templateLanguage: this.data.templateLanguage,
								template: templateItem.Template,
								question: data.value.Question
							},
						});

						dialogRef.afterClosed().subscribe(result => {
							console.log(`Dialog result: ${result}`);
							this._snackbarService.success('Close and reopen to refresh data.');
						});
					}
				},
				err => {
					this.networkCall = false;
				},
			);
	}

}

/**
 * Simulate an async HTTP call with a delayed observable.
 */
function serverCall(meals: Template[], page: number, pageSize:number, total:number): Observable<IServerResponse> {
    
  //  const start = (page - 1) * pageSize;
  //  const end = start + pageSize;

    return of({
            items: meals,//.slice(start, end),
            total: total
        }).pipe();
}



import { Component, OnInit,OnDestroy, OnChanges, Input, Output, EventEmitter, } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { CmsService } from 'app/cms/cms.service';
import { EmsConfig } from 'app/shared/emsConfig';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
// const URL = '/api/';


@Component({
  templateUrl: './tag-selector.component.html',
  selector: 'tag-selector',
})

export class TagSelectorComponent implements OnInit,OnDestroy {

  // current selected related volume (if not selected, it's -1)
  @Input() public module: number=-1;

  // current keyword value
  @Input() public keyword: string='Not Set';

  @Input() public keywordToken: string='';

  // unique index for this control (for identifying if this should be clised)
  @Input() public index: number=-1;

  // listener for whether another item has been clicked on
  @Input() public notification:Subject<any> = new Subject();



  // event that an item has been selected
  @Output() public selected: EventEmitter<any> = new EventEmitter<any>();


  public SelectedVolume: number=-1;


  public IsActive: boolean = false;

  public tagList:any[];

  constructor(public emsConfig: EmsConfig, public cmsService: CmsService) { }

  ngOnInit() {


    // listen for any other item that has been clicked on. If this component wasn't the originator of the click, close it
     this.notification.subscribe(event => {
      if(this.index !== event){
        this.IsActive = false;
      }
    });
  }

   ngOnDestroy() {
   // if(!!this.notification) this.notification.unsubscribe();
  }

  public ActivateDropdown(): void {

     this.getTagList();
  }

  public UpdateTag($event){
    console.log($event);
    const keyword = this.tagList.find(e=>e.Keyword_Token === this.keywordToken);
    const args = {index:this.index,keyword:keyword }
    this.selected.emit(args);

  }

  private getTagList(){

    this.cmsService.getTagListForVideos(this.module).subscribe((tagList)=>{

      console.log(tagList);
      this.tagList = tagList;
      this.IsActive=true;

    }


    )

  }






}

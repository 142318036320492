export class HelpQuestion {
  ID: number;
  SectionId: number;
  QuestionLang: string;
  Question: string;
  Answer: string;
  SortOrder: number;
  RelatedFeature: string;
  VideoUrl: string;
}

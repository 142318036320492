import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Shared Components
import { CategoriesComponent } from '../categories/categories.component';
import { PopupComponent } from './popup.component';
import { ConfirmComponent } from './confirm.component';
import { SpinnerComponent } from './spinner.component';
import { TooltipComponent } from './tooltip.component';
import { AnalyticsQueryComponent } from './analytics-query.component';
import { SyncStatusComponent } from '../sync/sync-status.component';
import { ColorPickerComponent } from './color-picker.component';
import { CreateLinkComponent } from './create-link.component';
import { ImageUploadComponent } from './image-upload.component';

// AI Components
import { AiCompletionComponent } from './ai-ui/ai-completion.component';
import { AiAudioComponent } from './ai-ui/ai-audio.component';

// Shared Services
import { DashboardService } from '../home/dashboard.service';
import { UtilitiesService } from './utilities.service';
import { PopupService } from './popup.service';
import { ConfirmService } from './confirm.service';
import { SpinnerService } from './spinner.service';
import { ConnexService } from '../connex/connex.service';
import { AnalyticsQuery } from './analytics-query';

// Services
import { AccountsService } from '../accounts/accounts.service';

// Pipes
import { SafeHtml } from './html-sanitize.pipe';
import { ClientLibraryCategory, VolumeCategory, InLibraryItemFilter, OnpointFilter, HasCaptionsFilter, ClientLibraryLang, CPDIDFilter, InCPDLibraryItemFilter, LibrarySearchFilter } from '../accounts/details/library-item-filters.pipe';
import { AccountFilter, AccountManagerFilter, AccountStatusFilter } from '../accounts/account-filters.pipe';
import { VolumeSearchFilter, StatusFilter, ModuleStatusFilter, ModuleSearchFilter, IqSearchFilter, RelatedVolumesFilter, ExpertSearchFilter, ExpertReviewFilter, VolumeInventorySearch, TextResourceFilter, TipsheetSearchFilter, TipsheetStatusFilter, StaticPageSearchFilter, TruncateTextPipe, ExpertSpecialitySearchFilter, FilterSpecialityByCategory, FilterPlaylists, SubcategoriesByCategory, SubcategoryIsLive, SubcategorySearchFilter } from '../cms/cms-filters.pipe';
import { BlogCategoryFilter, BlogTagFilter, BlogPostFilter, BlogPostTitleFilter, BlogSubcategoryFilter } from '../blog/blog-filters.pipe';

// Third Party
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AdminService } from '../admin/admin.service';
import { ClearCacheComponent } from 'app/admin/clear-cache.component';
import { UserProfileComponent } from 'app/userprofile/user-profile.component';
import { BlogService } from 'app/blog/blog.service';
import { ReportingService } from 'app/services/reporting.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SubtitleComponent } from './subtitle/subtitle.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CmsService } from 'app/cms/cms.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    MatTooltipModule,
    FileUploadModule
  ],
  declarations: [
    CategoriesComponent,
    ClearCacheComponent,
    PopupComponent,
    ConfirmComponent,
    SpinnerComponent,
    SafeHtml,
    TooltipComponent,
    ClientLibraryCategory,
    VolumeCategory,
    InLibraryItemFilter,
    OnpointFilter,
    HasCaptionsFilter,
    ClientLibraryLang,
    AccountFilter,
    AccountManagerFilter,
    AccountStatusFilter,
    VolumeSearchFilter,
    StatusFilter,
    ModuleStatusFilter,
    ModuleSearchFilter,
    RelatedVolumesFilter,
    TextResourceFilter,
    VolumeInventorySearch,
    IqSearchFilter,
    ExpertSearchFilter,
    ExpertReviewFilter,
    TipsheetSearchFilter,
    TipsheetStatusFilter,
    StaticPageSearchFilter,
    SyncStatusComponent,
    ColorPickerComponent,
    CreateLinkComponent,
    BlogCategoryFilter,
    BlogTagFilter,
    BlogPostFilter,
    BlogPostTitleFilter,
    TruncateTextPipe,
    CPDIDFilter,
    InCPDLibraryItemFilter,
    LibrarySearchFilter,
    ExpertSpecialitySearchFilter,
    FilterSpecialityByCategory,
    SubtitleComponent,
    FilterPlaylists,
    ImageUploadComponent,
    SubcategoriesByCategory,
    SubcategoryIsLive,
    SubcategorySearchFilter,
    BlogSubcategoryFilter,
    AiCompletionComponent,
    AiAudioComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    CategoriesComponent,
    PopupComponent,
    ConfirmComponent,
    SpinnerComponent,
    SafeHtml,
    TooltipComponent,
    ClientLibraryCategory,
    VolumeCategory,
    InLibraryItemFilter,
    OnpointFilter,
    HasCaptionsFilter,
    ClientLibraryLang,
    AccountFilter,
    AccountManagerFilter,
    AccountStatusFilter,
    VolumeSearchFilter,
    StatusFilter,
    ModuleStatusFilter,
    ModuleSearchFilter,
    RelatedVolumesFilter,
    TextResourceFilter,
    VolumeInventorySearch,
    IqSearchFilter,
    ExpertSearchFilter,
    ExpertReviewFilter,
    TipsheetSearchFilter,
    TipsheetStatusFilter,
    StaticPageSearchFilter,
    SyncStatusComponent,
    ColorPickerComponent,
    CreateLinkComponent,
    NgMultiSelectDropDownModule,
    BlogCategoryFilter,
    BlogTagFilter,
    BlogPostFilter,
    BlogPostTitleFilter,
    TruncateTextPipe,
    CPDIDFilter,
    LibrarySearchFilter,
    InCPDLibraryItemFilter,
    ExpertSpecialitySearchFilter,
    FilterSpecialityByCategory,
    SubtitleComponent,
    FilterPlaylists,
    ImageUploadComponent,
    SubcategoriesByCategory,
    SubcategoryIsLive,
    SubcategorySearchFilter,
    BlogSubcategoryFilter,
    AiCompletionComponent,
    AiAudioComponent
  ],
  providers: [CmsService, UtilitiesService,ReportingService, BlogService, SpinnerService, AnalyticsQuery, DashboardService, AccountsService, ConnexService, AdminService]
})

export class SharedModule { }

<div class="campaign-bulkbuild ems5">
	<!-- Final Bulk Build button - to appear for SysAdmins only -->
	<div class="campaign-bulkbuild-container section-padding">
		<header>
			<h1 *ngIf="campaign?.ID !== -1" class="bulkbuild-title ls-headline">Add Client To:
				<span class="ls-headline">{{ campaign?.Title }}
					<span *ngIf="campaign?.LinkCampaignTitle" class="inline-text"> / </span>
				</span>
				<span *ngIf="campaign?.LinkCampaignTitle" class="ls-headline">{{ campaign?.LinkCampaignTitle }}</span>
				<span class="ls-title campaign-dates">{{ campaign?.StartDate | date: 'MM/dd/yyyy' }} - {{ campaign?.EndDate | date: 'MM/dd/yyyy' }}</span>
			</h1>
      <h1 class="bulkbuild-title ls-headline" *ngIf="campaign?.ID === -1">Add to Campaign is closed at this time.</h1>
			<div class="row campaign__header-section" *ngIf="campaign?.ID !== -1">
				<div class="header__search-container">
					<input type="search" placeholder="Search clients" [(ngModel)]="campaignSearch">
					<svg class="searchbar__icon" fill="#707070" height="18" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
						<path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
						/>
						<path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
        <button class="add-client-btn btn--fab b-dark-blue" [ngClass]="{'show-form' : showForm}" title="Add new client to campaign" (click)="showNewClientForm()">
          <svg viewBox="0 0 24 24">
            <path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
          </svg>
        </button>
        <!-- Final Bulk Build button - to appear for SysAdmins only -->
        <div class="btn-col final-build-btn" *ngIf="_emsConfig.UserRole==='systemadmin'">
          <button class="btn save-btn" (click)="openConfirmDialog('campaignBulkBuild', 'finalBuild', clientCampaign, 'Are you sure you want to build/sync this campaign to prod?')">Final Build & Sync</button>
          <tooltip [tooltipMessage]="'Final build & sync will also set the status of the campaign to active.'"></tooltip>
        </div>
			</div>
		</header>
		<!-- Add New Client Form -->
		<section class="new-client" *ngIf="showForm" [ngClass]="{'show-form' : showForm}">
			<div class="info-container">
				<h3 class="title">Add New Client to Campaign</h3>
				<div class="info-container__text-container">
					<form class="form__new-client">
						<div class="row">
							<div class="col">
								<h4 class="edit__title required-field">Client</h4>
								<div class="select-container">
									<select name="clientList" id="clientList" [(ngModel)]="clientCampaign.ClientName">
										<option value="" [selected]="true" [disabled]="true">Select Client</option>
										<option [value]="client.OrganizationName" *ngFor="let client of allClients">{{ client.OrganizationName }}</option>
									</select>
								</div>
							</div>
							<div class="col rangepicker-container">
								<h4 class="edit__title required-field">Date Range</h4>
								<mat-form-field class="date-picker-field">
									<mat-label></mat-label>
									<mat-date-range-input [rangePicker]="newClientRangePicker">
										<input name="startDate" matStartDate placeholder="Start date" [(ngModel)]="clientCampaign.StartDate">
										<input name="endDate" matEndDate placeholder="End date" [(ngModel)]="clientCampaign.EndDate">
									</mat-date-range-input>
									<mat-datepicker-toggle matSuffix [for]="newClientRangePicker"></mat-datepicker-toggle>
									<mat-date-range-picker #newClientRangePicker></mat-date-range-picker>
								</mat-form-field>
							</div>
							<div class="col datepicker-container">
								<h4 class="edit__title required-field">Preview Date</h4>
								<mat-form-field>
									<mat-label></mat-label>
									<input name="previewDate" matInput [matDatepicker]="newClientPicker" [(ngModel)]="clientCampaign.PreviewDate">
									<mat-datepicker-toggle matSuffix [for]="newClientPicker"></mat-datepicker-toggle>
									<mat-datepicker #newClientPicker></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<h4 class="edit__title required-field">Message Type</h4>
								<div class="select-container">
									<select name="messageType" id="messageType" [(ngModel)]="clientCampaign.MessageType">
										<option value="full" [selected]="true">Full</option>
										<option value="lite">Lite</option>
										<option value="full-lite">Full-lite</option>
									</select>
								</div>
							</div>
							<!-- TODO: Hiding Homepage tile checkbox until we have the functionality for it -->
							<!-- <div class="col checkbox-col checkbox-container">
								<div class="checkbox">
									<label for="featuredTile">
										<h4 class="edit__title required-field">Homepage Tile</h4>
										<input type="checkbox" name="homepageTile" id="homepageTile" [ngModel]="campaign.HomepageTile">
										<span class="checkmark"></span>
									</label>
								</div>
							</div> -->
							<div class="col"></div>
							<div class="col"></div>
						</div>
						<div class="row btn-row">
							<div class="col btn-col">
								<div class="col btn-col">
									<button class="btn save-btn" title="Save" (click)="saveClient(clientCampaign)">Add to Campaign</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>

		 <!-- View/Edit Existing Clients Form Table -->
		<section class="existing-clients campaign__table">
			<table class="flex-table">
				<thead class="existing-clients__table-header">
					<tr>
						<th class="client">Client</th>
						<th class="date-range">Date Range</th>
						<th class="preview-date">Preview Date</th>
						<th class="message-type">Message Type</th>
						<!-- TODO: Hiding Homepage tile checkbox until we have the functionality for it -->
						<!-- <th class="featured-tile flex-shrink">Featured Tile</th> -->
						<td class="flex-shrink"></td>
					</tr>
				</thead>
				<tbody class="existing-client__table-body">
					<ng-container>
						<tr class="existing-client__row" *ngFor="let client of existingClients | campaignClientFilter: campaignSearch; let i = index;">
							<td data-label="Client" class="client">
								<div class="display">
									<p class="ls-body-2">{{ client.ClientName }}</p>
								</div>
							</td>
							<td data-label="Date Range" class="date-range">
								<div class="display" *ngIf="toggleIndex !== i">
									<p>{{ client.StartDate | date: 'MM/dd/yyyy' }} - {{ client.EndDate | date: 'MM/dd/yyyy' }}</p>
								</div>
								<div class="rangepicker-container" *ngIf="toggleIndex === i">
									<mat-form-field class="date-picker-field">
										<mat-label></mat-label>
										<mat-date-range-input [rangePicker]="clientRangePicker">
											<input name="startDate" matStartDate placeholder="Start date" [(ngModel)]="client.StartDate">
											<input name="endDate" matEndDate placeholder="End date" [(ngModel)]="client.EndDate">
										</mat-date-range-input>
										<mat-datepicker-toggle matSuffix [for]="clientRangePicker"></mat-datepicker-toggle>
										<mat-date-range-picker #clientRangePicker></mat-date-range-picker>
									</mat-form-field>
								</div>
							</td>
							<td data-label="Preview Date" class="preview-date">
								<div class="display" *ngIf="toggleIndex !== i">
									<p>{{ client.PreviewDate | date: 'MM/dd/yyyy' }}</p>
								</div>
								<div class="datepicker-container" *ngIf="toggleIndex === i">
									<mat-form-field>
										<mat-label></mat-label>
										<input name="previewDate" matInput [matDatepicker]="clientPicker" [(ngModel)]="client.PreviewDate">
										<mat-datepicker-toggle matSuffix [for]="clientPicker"></mat-datepicker-toggle>
										<mat-datepicker #clientPicker></mat-datepicker>
									</mat-form-field>
								</div>
							</td>
							<td data-label="Message Type" class="message-type">
								<div class="display" *ngIf="toggleIndex !== i">
									<p>{{ client.MessageType }}</p>
								</div>
								<div class="select-container" *ngIf="toggleIndex === i">
									<select name="messageType" id="messageType" [(ngModel)]="client.MessageType">
										<option value="full">Full</option>
										<option value="lite">Lite</option>
										<option value="full-lite">Full-lite</option>
									</select>
								</div>
							</td>
							<!-- TODO: Hiding Homepage tile checkbox until we have the functionality for it -->
							<!-- <td class="homepage-tile flex-shrink">
								<div class="checkbox">
									<label for="homepageTile">
										<input type="checkbox" name="homepageTile" id="homepageTile">
										<span class="checkmark"></span>
									</label>
								</div>
							</td> -->
							<td class="flex-shrink btn-col"> <!-- Edit/Save/Delete Buttons -->
								<div class="update-campaign">
									<button class="icon-btn" title="Edit Client" (click)="editClientForm(i)">
										<svg xmlns="http://www.w3.org/2000/svg" width="13.491" height="13.491" viewBox="0 0 13.491 13.491">
											<path id="pencil" d="M16.272,6.027a.746.746,0,0,0,0-1.057L14.518,3.217a.746.746,0,0,0-1.057,0L12.083,4.588l2.81,2.81M3,13.678v2.81H5.81l8.288-8.3-2.81-2.81Z" transform="translate(-3 -2.997)" fill="#2699fb"/>
										</svg>
									</button>
									<button class="icon-btn" title="Save Client" (click)="saveClient(existingClients[i])">
										<svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="14.5" viewBox="0 0 18 18">
											<path id="save" fill="#2699fb" d="M12,6H2V2H12M9,16a3,3,0,1,1,3-3,3,3,0,0,1-3,3M14,0H2A2,2,0,0,0,0,2V16a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V4Z"/>
									</svg>
									</button>
									<button class="icon-btn" title="Delete Client" (click)="deleteClient(i)">
										<svg xmlns="http://www.w3.org/2000/svg" width="11.278" height="14.5" viewBox="0 0 11.278 14.5">
											<path id="trash" d="M16.278,3.806H13.458L12.653,3H8.625l-.806.806H5V5.417H16.278M5.806,15.889A1.611,1.611,0,0,0,7.417,17.5h6.444a1.611,1.611,0,0,0,1.611-1.611V6.222H5.806Z" transform="translate(-5 -3)" fill="#2699fb"/>
									</svg>
									</button>
								</div>
							</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</section>
	</div>

</div>

// Angular Things
import { PipeTransform, Pipe } from '@angular/core';

// Classes
import { VolumeListItem } from '../shared/VolumeListItem';
import { ModuleItem } from '../shared/ModuleItem';
import { QuizItem } from './QuizItem';
import { Expert } from '../shared/expert';
import { Inventory } from '../shared/inventory';
import { TipsheetItem } from './tipsheetItem';
import { StaticPage } from './StaticPage';
import { ExpertReviewItem } from 'app/shared/expert-review-item';
import { UtilitiesService } from '../shared/utilities.service';
import { SubCategory } from 'app/shared/SubCategory';


// input will search both volume subject and volume expert
@Pipe({
  name: 'volumeSearchFilter',
  pure: false
})
export class VolumeSearchFilter implements PipeTransform {
  constructor(public _utilitiesService: UtilitiesService) {}
  transform(value: VolumeListItem[], ...args: string[]): VolumeListItem[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? this._utilitiesService.normalizeTerm(args[0].toLocaleLowerCase()) : null;
    return filter ? value.filter((volume: VolumeListItem) =>
      volume.VolumeTitleNormalized.toLocaleLowerCase().indexOf(filter) !== -1 || volume.ExpertNormalized.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}


@Pipe({
  name: 'expertReviewFilter',
  pure: false
})
export class ExpertReviewFilter implements PipeTransform {
  transform(value: ExpertReviewItem[], expertReviewFilterArgs:any, searchString: string): ExpertReviewItem[] {

    Object.keys(expertReviewFilterArgs).forEach(key=>{
      const arg = expertReviewFilterArgs[key];
      if(arg!='all'){
        value = value.filter(e=>e[key].toString() ===arg );
      }
      if(searchString!==''){
        value =value.filter(e=>e.ExpertNameNormalized.toLowerCase().includes(searchString.toLowerCase()));
      }

    });
    return value;

  }
}

// to sort volumes by active/testing/retired status
@Pipe({
  name: 'statusFilter'
})
export class StatusFilter implements PipeTransform {
  transform(value: VolumeListItem[], ...args: string[]): VolumeListItem[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
    if (filter === 'all') {
      const filteredVolumes = value.filter((volume: VolumeListItem) => volume.EMSStatus === 'live' || volume.EMSStatus === 'testing' || volume.EMSStatus === 'retired' || volume.EMSStatus === 'qa');
        console.log(filteredVolumes);
        console.log(filteredVolumes.length);
      return filteredVolumes;
    } else {
      return filter ? value.filter((volume: VolumeListItem) => volume.EMSStatus.toLocaleLowerCase().indexOf(filter) !== -1) : value;
    }
  }
}

// to sort modules by active/inactive status
@Pipe({
  name: 'moduleStatusFilter'
})
export class ModuleStatusFilter implements PipeTransform {
  transform(value: ModuleItem[], ...args: string[]): ModuleItem[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
    console.log('filter = ' + filter);
    if (filter === 'all') {
      const filteredModules = value.filter((module: ModuleItem) => module.VolumeStatus === 'live' || module.VolumeStatus === 'testing' || module.VolumeStatus === 'retired' || module.VolumeStatus === 'qa');
      console.log(filteredModules);
      console.log(filteredModules.length);
      return filteredModules;
    } else {
      return filter ? value.filter((module: ModuleItem) => module.VolumeStatus.toLocaleLowerCase().indexOf(filter) !== -1) : value;
    }
  }
}

// input will search both module subject and module expert
@Pipe({
  name: 'moduleSearchFilter',
  pure: false
})
export class ModuleSearchFilter implements PipeTransform {
  constructor(public _utilitiesService: UtilitiesService) {}
  transform(value: ModuleItem[], ...args: string[]): ModuleItem[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? this._utilitiesService.normalizeTerm(args[0].toLocaleLowerCase()) : null;
    return filter ? value.filter((module: ModuleItem) =>
      module.TitleNormalized.toLocaleLowerCase().indexOf(filter) !== -1 || module.ExpertNormalized.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}


// sorts the list of related volumes by the name of the source volume

@Pipe({
  name: 'relatedVolumesFilter',
  pure: false
})
export class RelatedVolumesFilter implements PipeTransform {
  transform(value: any[], ...args: string[]): any[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
    return filter ? value.filter((relatedVolume: any) =>
      relatedVolume.SourceName.toLocaleLowerCase().indexOf(filter) !== -1 ): value;
  }
}


// input will search both iQ subject
@Pipe({
  name: 'iqSearchFilter',
  pure: false
})
export class IqSearchFilter implements PipeTransform {
  constructor(public _utilitiesService: UtilitiesService) {}
  transform(value: QuizItem[], ...args: string[]): QuizItem[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? this._utilitiesService.normalizeTerm(args[0].toLocaleLowerCase()) : null;
    return filter ? value.filter((quiz: QuizItem) =>
      quiz.TitleNormalized.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}


@Pipe({
  name: 'expertSearchFilter',
  pure: false
})
export class ExpertSearchFilter implements PipeTransform {
  constructor(public _utilitiesService: UtilitiesService) {}
  transform(value: Expert[], ...args: string[]): Expert[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? this._utilitiesService.normalizeTerm(args[0].toLocaleLowerCase()) : null;
    return filter ? value.filter((expertItem: Expert) =>
    expertItem.NormalizedName.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}



// input will search both module subject and module expert
@Pipe({
  name: 'volumeInventorySearch',
  pure: false
})
export class VolumeInventorySearch implements PipeTransform {
  constructor(public _utilitiesService: UtilitiesService) {}
  transform(value: Inventory[], ...args: string[]): Inventory[] {
    if (value == null) {
      return null;
    }
    const filter: string = args ? args[0].toLocaleLowerCase() : null;
    return filter ? value.filter((volItem: Inventory) =>
    this._utilitiesService.normalizeTerm(volItem.VolumeTitle.toLocaleLowerCase()).indexOf(filter) !== -1) : value;
  }
}

// sorts the list of Pages by the name
@Pipe({
  name: 'textResourceFilter',
  pure: false
})
export class TextResourceFilter implements PipeTransform {
  transform(value: any[], ...args: string[]): any[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
    return filter ? value.filter((relatedText: any) =>
    relatedText.PageName.toLocaleLowerCase().indexOf(filter) !== -1 ): value;
  }
}


// input will search both volume subject for correct tipsheet
@Pipe({
  name: 'tipsheetSearchFilter',
  pure: false
})
export class TipsheetSearchFilter implements PipeTransform {
  constructor(public _utilitiesService: UtilitiesService) {}
  transform(value: TipsheetItem[], ...args: string[]): TipsheetItem[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? this._utilitiesService.normalizeTerm(args[0].toLocaleLowerCase()) : null;
    return filter ? value.filter((volume: TipsheetItem) =>
      volume.VolumeTitleNormalized.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}

// to sort tipsheets for volumes by active/testing/retired status
@Pipe({
  name: 'tipsheetStatusFilter'
})
export class TipsheetStatusFilter implements PipeTransform {
  transform(value: TipsheetItem[], ...args: string[]): TipsheetItem[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
    if (filter === 'all') {
      const filteredTipsheets = value.filter((volume: TipsheetItem) => volume.VolumeStatus === 'live' || volume.VolumeStatus === 'testing' || volume.VolumeStatus === 'retired' || volume.VolumeStatus === 'qa');
      console.log(filteredTipsheets);
      console.log(filteredTipsheets.length);
      return filteredTipsheets;
    } else {
      return filter ? value.filter((volume: TipsheetItem) => volume.VolumeStatus.toLocaleLowerCase().indexOf(filter) !== -1) : value;
    }
  }
}

// input will search title and clients for correct static page
@Pipe({
  name: 'staticPageSearchFilter',
  pure: false
})
export class StaticPageSearchFilter implements PipeTransform {
  transform(value: StaticPage[], ...args: string[]): StaticPage[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
    return filter ? value.filter((page: StaticPage) =>
      page.Title.toLocaleLowerCase().indexOf(filter) !== -1 || page.PermittedAccounts.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}

// to truncate text for display purposes (include ... at end)
@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {
  transform(value: string, ...args: string[]): string {
    if (value === undefined || value === '' || value === null) {
      return '';
    } else {
      let limit = args.length > 0 ? parseInt(args[0], 10) : 10;
      let trail = args.length > 1 ? args[1] : '...';

      return value.length > limit ? value.substring(0, limit) + trail : value;
    }
  }
}


@Pipe({
  name: 'expertSpecialitySearchFilter',
  pure: false
})
export class ExpertSpecialitySearchFilter implements PipeTransform {
  transform(value: any[], ...args: string[]): any[] {
    if (value == null) {
      return null;
    }
    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
    return filter ? value.filter(a => a.SpecialityDetails.some(u => u.speciality.toLocaleLowerCase().includes(filter))) : value;
  }
}

@Pipe({
  name: 'filterSpecialityByCategory',
  pure: false
})
export class FilterSpecialityByCategory implements PipeTransform {
  transform(value: any[], ...args: string[]): any[] {
    if (value == null) {
      return null;
    }
    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
    return filter ? value.filter(u => u.CategoryName.toLocaleLowerCase().includes(filter)) : value;
  }
}


@Pipe({
  name: 'filterPlaylists',
  pure: false
})
export class FilterPlaylists implements PipeTransform {
  constructor(public _utilitiesService: UtilitiesService) {}
  transform(value: any[], ...args: string[]): any[] {
    if (value == null) {
      return null;
    }
    const filter: string = args[0] ? this._utilitiesService.normalizeTerm(args[0].toLocaleLowerCase()) : null;
    return filter ? value.filter(u => u.TitleNormalized.toLocaleLowerCase().includes(filter)) : value;
  }
}

// filters the subcategories that are visible on site
@Pipe({
  name: 'subcategoryIsLive'
})
export class SubcategoryIsLive implements PipeTransform {
  transform(items: SubCategory[], isLive: boolean): SubCategory[] {
    return isLive? items.filter(item => item.IsLive) : items;
  }
}


// filters the subcategories by their name
@Pipe({
  name: 'subcategorySearchFilter'
})
export class SubcategorySearchFilter implements PipeTransform {
  transform(items: SubCategory[], ...args: string[]): SubCategory[] {
    if (items == null) {
      return null;
    }

    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
    return filter ? items.filter((item: SubCategory) => item.Name.toLocaleLowerCase().indexOf(filter) !== -1) : items;
  } 
}

// filters the subcategories by their parent category
@Pipe({
  name: 'subcategoriesByCategory'
})
export class SubcategoriesByCategory implements PipeTransform {
  transform(items: SubCategory[], args: any): SubCategory[] {
    // if -1 return all items
    if (args == -1) {
      return items;
    }
    if (items == null || items == undefined) {
      return null;
    }

    return items.filter(item => item.CategoryID == args);

  }
}



// filter the sorted playlist by their status 
@Pipe({
  name: 'playlistFilter',
  pure: false
})
export class PlaylistFilter implements PipeTransform {
  transform(value: any[], ...args: any): any[] {
    // if no filter value is sent then return all playlist
    if (args == null||args==undefined || args=='') {
      return value;
    }
    // if no list, then return null
    if (value == null || value == undefined) {
      return null;
    }
    // sort the playlist alphabetically
    value.sort((a, b) => a.Title.localeCompare(b.Title));
    // filter the list by status
    return value.filter(item => item.Status == args);
  }
}
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Categories } from 'app/categories/categories';
import { CategoriesService } from 'app/categories/categories.service';
import { CmsService } from 'app/cms/cms.service';
import { SnackbarService } from 'app/services/Snackbar.service';
import { SubCategory } from 'app/shared/SubCategory';
import { ConfirmService } from 'app/shared/confirm.service';
import { EmsConfig } from 'app/shared/emsConfig';
import { UtilitiesService } from 'app/shared/utilities.service';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'cms-subcategory-details',
  templateUrl: './cms-subcategory-details.component.html',
  providers: [Location]
})
export class CmsSubcategoryDetailsComponent implements OnInit, OnDestroy {

  // adds class to parent element (element named using the selector in the component decorator)
  @HostBinding('class') cmsSubcategoryDetails: string = 'cms-subcategory';

  // variables for getting account id from url
  id: number;
  private sub: any;

  // for category lists
  categoryList: Categories[];

  // for all languages ist
  allLanguages: any[];

  // for subcategory
  defaultSubCategory: SubCategory = new SubCategory();
  subCategory: SubCategory = new SubCategory();
  subCategoryTranslations: SubCategory[] = [];
  updateSubCategory = false;
  // confirm stuff
  confirmData: {};
  confirmSiteTextData: any;
  confirmAction: string;
  confirmSubscription: Subscription;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public _cmsService: CmsService,
    private _snackBar: SnackbarService,
    private _confirmService: ConfirmService,
    private _categoriesService: CategoriesService,
    private _utilitiesService: UtilitiesService,
    public _emsConfig: EmsConfig,
    private location: Location
  ) {

    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'addUpdateSubCategory') {
            this.addUpdateSubCategory();
          } 
/*           else if (this.confirmAction === 'deleteSubcategory') {
            this.deleteSubcategory();
          } */
        }
      }
    );
  }

  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(component: string, action: string, message: string): void {
    this.confirmAction = action;
    console.log(this.subCategory);
    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }

  // =================================================================================================
  // if this function is called for new subcategory creation, then it creates empty subcategory 
 // object array with supported languages assigned otherwise it gets single subcategory with translations 
//from db and assign corresponding subcategory to supported LS languages & makes a new object array. 
// ====================================================================================================

  getSubCategory() {
    // if new subcategory, load empty subcategory names for each language
    if (this.id === -1) {
      // load table of translations to add
      for (let i = 0; i < this.allLanguages.length; i++) {
        // create a new subcategory object and set properties
        let subcategoryItem: SubCategory = {
          Lang: this.allLanguages[i].Code,
          Name: null,
          LangName: this.allLanguages[i].Name,
        };
        // add to the empty subcategory list
        this.subCategoryTranslations.push(subcategoryItem);
      }
    } else {
      this.subCategoryTranslations = [];
      this._cmsService.getSubCategoryById(this.id).subscribe(data => {
        // As english is the main subcategory language, assign the subcategory with english language as default subcategory
        this.defaultSubCategory = data[data.findIndex(x => x.Lang.toLocaleLowerCase() === 'en')];

        for (let i = 0; i < this.allLanguages.length; i++) {
          // find the corresponding subcategory to language
          let langIndex = data.findIndex(x => x.Lang.toLowerCase() === this.allLanguages[i].Code.toLowerCase());

          // create a new subcategory object and set properties
          let subcategoryItem: SubCategory = {
            Lang: this.allLanguages[i].Code,
            Name: langIndex === -1 ? null : data[langIndex].Name,
            LangName: this.allLanguages[i].Name,
            SubCategoryLanguageID: langIndex === -1 ? -1 : data[langIndex].SubCategoryLanguageID,
            CategoryID: this.defaultSubCategory.CategoryID,
            ID: this.defaultSubCategory.ID
          };

          // add to the subcategory list
          this.subCategoryTranslations.push(subcategoryItem);
        }
      });
    }
  }

  // ====================================================================
  // when clicks add/edit button in available subcategory translations list 
  // =====================================================================
  addEditSubCategory(item: SubCategory): void {
    this.subCategory = item;
    if (this.id == -1 && this.subCategory.Lang.toLocaleLowerCase() !== 'en') {
      this._snackBar.error("Please create English Subcategory first!");
      this.updateSubCategory = false;
      return;
    }
    this.updateSubCategory = true;
  }

  // ================================
  // update the visible on side option 
  // =================================
  updateIsLive() {
    if (this.id !== -1) {
      this._cmsService.updateSubcategorySiteVisiblity(this.defaultSubCategory).subscribe(data => {
        this._snackBar.success();
        this.getSubCategory();
      },
        err => {
          this._snackBar.error('Subcategory is not created/edited properly - please click the "Submit" button and try again.');
        });
    }
  }

  // ========================
  // delete the subcategory
  // ========================
  // todo uncomment & implement further to delete subcategory 
/*   deleteSubcategory() {
    if (this.id !== -1) {
      this._cmsService.deleteSubcategory(this.id).subscribe(data => {
        this._snackBar.success();
      },
        err => {
          this._snackBar.error();
        });
    }
  } */
  
  
  // ================================
  // create or update subcategory 
  // =================================
  addUpdateSubCategory() {
    // if new subcategory
    if (this.id === -1) {
      this.subCategory.CategoryID = this.defaultSubCategory.CategoryID;
      this.subCategory.ID = this.id;
      this.subCategory.IsLive = this.defaultSubCategory.IsLive;
    }
    // validation check
    if (this._utilitiesService.isBlank([this.subCategory.Name]) || this.subCategory.CategoryID==-1) {
    this._snackBar.error(this._emsConfig.text.EMS_Content.Complete_Fields_Error);
    return;
  }
    this._cmsService.addUpdateSubCategory(this.subCategory).subscribe(data => {
      // check for create or update
      if (this.subCategory.Lang.toLocaleLowerCase() == 'en')
        this.id = data;
      else
        this.subCategory.SubCategoryLanguageID = data;
      // update the url with the new id
      this.location.go('/cms/subcategories/' + this.id);
      this.getSubCategory();
      this._snackBar.success(this._emsConfig.text.EMS_General.Popup_Success);
    },
      err => {
        this._snackBar.error('Subcategory is not created/edited properly - please click the "Submit" button and try again.');
      });
  }

  // =======================================
  // get list of available parent categories
  // =======================================
  getCategories(): void {
    this._categoriesService.getAllCategories()
      .subscribe(
        CategoryList => {
          this.categoryList = CategoryList['categories'];
          console.log(this.categoryList);
        });
  }

  // ================================
  // get list of languages available
  // ================================
  getAllLanguages() {
    this._cmsService.getLanguages().subscribe(langs => {
      // get list of all language supported on the site
      this.allLanguages = langs;
      // For now it supports only english & french subcategoty
      // ToDo, remove this filter to support lifespeak languages.
      this.allLanguages = this.allLanguages.filter(x => x.Code == 'EN' || x.Code == 'FR');
      console.log(this.allLanguages);
      this.getSubCategory();
    })
  }

  ngOnInit(): void {
    // gets the subcategory id from url
    this.sub = this.route.params.subscribe(params => {
      this.id = Number(params['id']);
    });
    this.getAllLanguages();
    this.getCategories();
  }

  ngOnDestroy() {
    this.confirmSubscription.unsubscribe();
  }

}

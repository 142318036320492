import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserProfile } from '.././shared/user-profile';
import { EmsConfig } from '.././shared/emsConfig';
import { AccountManager } from './account-manager';
import { Account } from './account';
import { AccountDetails } from './account-details';
import { LibraryItem } from './details/library-item';
import { VolumeListItem } from '../shared/VolumeListItem';
import { AccountInfo } from 'app/accounts/account-info';
import { Image } from '.././shared/image';
import { MessageSettings } from './details/message-settings';
import { ClientQuestionnaire } from './ClientQuestionnaire';
import { PostSuppressionItem } from './details/blog-post-suppression-item';
import { ContractInfo } from 'app/shared/contract-info';
import { forkJoin } from 'rxjs';
import { CPDVolume } from './details/CPDVolume';
import { SidebarResourceItem } from './details/sidebar-resource-item';
import { BenefitsHub } from './details/benefits-hub';
import { Tile } from './details/tile';
import { BulletinBoard } from './details/bulletin-board';
import { SSOConfigItem } from './details/sso-config-item';


@Injectable()
export class AccountsService {

  // *******helper Function URLS********
  private _getClientPromotionalVolumesURL = this.emsConfig.apiEndpoint + '/clientlibrary/getclientpromotionalvolumes';

  // *******Account URLS********
  private _createAccountURL = this.emsConfig.apiEndpoint + '/clientaccount/createnewaccount';
  private _createSegmentAccountURL = this.emsConfig.apiEndpoint + '/clientaccount/createnewsegmentaccount';
  private _editSegmentNameURL = this.emsConfig.apiEndpoint + '/clientaccount/editsegmentname';
  private _getSegmentsForClientURL = this.emsConfig.apiEndpoint + '/clientaccount/getclientsegments?client=';
  private _accountManagersURL = this.emsConfig.apiEndpoint + '/adminusers/getaccountadminlistings';
  private _accountsURL = this.emsConfig.apiEndpoint + '/clientaccount/getaccountlistings?currentAccounts=';
  private _getclientswithrestrictedatesURL = this.emsConfig.apiEndpoint + '/clientaccount/getclientswithrestrictedates';
  private _accountDetailsURL = this.emsConfig.apiEndpoint + '/clientaccount/getsettingsforaccount?username=';
  private _clientPrefURL = this.emsConfig.apiEndpoint + '/clientaccount/getclientsettings?clientname=';
  private _updateAccountURL = this.emsConfig.apiEndpoint + '/clientaccount/updatesettingsforaccount';
  private _totalMailingListURL = this.emsConfig.apiEndpoint + '/clientaccount/totalmailinglistforclient?username=';
  private _updateStatusURL = this.emsConfig.apiEndpoint + '/clientaccount/updateaccountstatus?clientname=';
  private _updateLifeSpeakIDURL = this.emsConfig.apiEndpoint + '/clientaccount/updateaccountlifespeakid?clientname=';

  // ******CLIENT LIBRARY URLS********
  private _getClientLibraryURL = this.emsConfig.apiEndpoint + '/clientlibrary/getvolumes';
  private _addToLibraryURL = this.emsConfig.apiEndpoint + '/clientlibrary/addvolume';
  private _addInventoryToLibrary = this.emsConfig.apiEndpoint + '/clientlibrary/addinventorytolibrary';
  private _removeFromLibraryURL = this.emsConfig.apiEndpoint + '/clientlibrary/deletevolume';
  private _availableCCURL = this.emsConfig.apiEndpoint + '/assetadmin/getavailablecc';

  // *******Watch and Win URLS********
  private _getGetAllContestsURL = this.emsConfig.apiEndpoint + '/contests/getallcontests?username=';
  private _updateContestURL = this.emsConfig.apiEndpoint + '/contests/createupdatecontest';
  private _deleteContestURL = this.emsConfig.apiEndpoint + '/contests/deletecontest?id=';
  private _contestHasEntriesURL = this.emsConfig.apiEndpoint + '/contests/contesthasentries?id=';

  // *******Sidebar Resource URLS********
  private _getSidebarResourceTextURL = this.emsConfig.apiEndpoint + '/sidebarresources/getSidebarResourcesbyid?id=';
  private _getSidebarResourcesListURL = this.emsConfig.apiEndpoint + '/sidebarresources/getSidebarResourcesList?orgname=';
  private _updateSidebarResourceTextURL = this.emsConfig.apiEndpoint + '/sidebarresources/insertupdatesidebarresource';
  private _deleteSidebarResourceItemURL = this.emsConfig.apiEndpoint + '/sidebarresources/deletesidebarresourceitem?id=';


  // *******ShareLink URLS********
  private _getShareKeyURL = this.emsConfig.apiEndpoint + '/clientaccount/generatesharekey';
  private _getVolumesForSharelinksURL = this.emsConfig.apiEndpoint + '/clientaccount/getvolumesforsharelinks?username=';
  private _getPastSharelinksURL = this.emsConfig.apiEndpoint + '/clientaccount/getpastsharelinks?username=';
  private _getMarathonTranscriptInfoUrl = this.emsConfig.apiEndpoint + '/clientaccount/getmarathontranscriptinfo';
  private _getCurrentExpertEventURL = this.emsConfig.apiEndpoint + '/askTheExpert/getcurrenteventid';
  private _getMatchedVolumesForSharelinksURL = this.emsConfig.apiEndpoint + '/clientaccount/getmatchedvolumesforsharelinks?username=';
  private _getTipsheetsForSharelinksURL = this.emsConfig.apiEndpoint + '/assetListings/getTipsheetsByLang'


  // *******Account info URLS********
  private _industryUrl = this.emsConfig.apiEndpoint + '/clientaccount/getindustries';
  private _partnerOrgUrl = this.emsConfig.apiEndpoint + '/clientaccount/getpartnerorganizations';
  private _userProfileRequestUrl = this.emsConfig.apiEndpoint + '/usersettings/getuserbyid?userid=';
  private _userProfileByEmailRequestUrl = this.emsConfig.apiEndpoint + '/usersettings/getuserbyemail?email=';
  private _updateEmsContactUrl = this.emsConfig.apiEndpoint + '/usersettings/updateemsuser';
  private _updateAccountInfoURL = this.emsConfig.apiEndpoint + '/clientaccount/updateaccountinfo';
  private _accountInfoURL = this.emsConfig.apiEndpoint + '/clientaccount/getinfoforaccount?username=';
  private _updateVolumesPurchasedURL = this.emsConfig.apiEndpoint + '/clientaccount/updatevolumespurchased';
  private _salesRepURL = this.emsConfig.apiEndpoint + '/adminusers/getallsalesreps';
  private _parentClientURL = this.emsConfig.apiEndpoint + '/clientaccount/getparentorg?client=';
  private _updateClientIntegrationsURL = this.emsConfig.apiEndpoint + '/clientaccount/updateclientintegrationtypes';
  private _deleteClientIntegrationURL = this.emsConfig.apiEndpoint + '/clientaccount/deleteclientintegration?client=';
  private _integrationTypesURL = this.emsConfig.apiEndpoint + '/clientaccount/getintegrationtypes';
  private _clientIntegrationsURL = this.emsConfig.apiEndpoint + '/clientaccount/getclientintegrationtypes?client=';
  private _updateClientContactChangeDateURL = this.emsConfig.apiEndpoint + '/clientaccount/updatecontactchangedate';

  // *******Archive client URLS********
  private _archiveAccountUrl = this.emsConfig.apiEndpoint + '/clientaccount/archiveaccount';
  private _dearchiveAccountUrl = this.emsConfig.apiEndpoint + '/clientaccount/dearchiveaccount';

  // *******Extra Features URLS********
  private _getOrgApprovedEmailsURL = this.emsConfig.apiEndpoint + '/clientaccount/getapprovedemails?username=';
  private _addApprovedEmailURL = this.emsConfig.apiEndpoint + '/clientaccount/addapprovedemail';
  private _deleteApprovedEmailURL = this.emsConfig.apiEndpoint + '/clientaccount/deleteapprovedemail';

  //  *******Message Setting URLS*******
  private _messageSettingDetailsURL = this.emsConfig.apiEndpoint + '/messages/getsettingdetails?username=';
  private _updateMessageSettingURL = this.emsConfig.apiEndpoint + '/messages/updateSettings';

  //  *******Change Password URLS*******
  private _changeClientPasswordURL = this.emsConfig.apiEndpoint + '/clientaccount/changegrouppassword';
  private _passwordRequestURL = this.emsConfig.apiEndpoint + '/emsoperations/sendrequestemail';

  //  *******Bulk Mailing List Upload*******
  private _bulkMailingListURL = this.emsConfig.apiEndpoint + '/assetAdmin/getbulkmailinglist?username=';

  //  *******Account contract info URLS*******
  private _insertUpdateContractInfoURL = this.emsConfig.apiEndpoint + '/clientaccount/insertupdatecontractinfo';
  private _contractInfoURL = this.emsConfig.apiEndpoint + '/clientaccount/getcontractinfo?username=';
  private _deleteContractFileURL = this.emsConfig.apiEndpoint + '/clientaccount/deletecontractpdf';
  private _updateContractFilesURL = this.emsConfig.apiEndpoint + '/clientaccount/updatecontractfiles';
  private _getLaunchDateURL = this.emsConfig.apiEndpoint + '/clientaccount/getlaunchdate?username=';
  private _getContractFileNameURL = this.emsConfig.apiEndpoint + '/clientaccount/getcontractfilenames?username=';

  // ******CLIENT CPD LIBRARY URLS********
  private _getCPDClientLibraryURL = this.emsConfig.apiEndpoint + '/cpd/emsgetcpdvolumesforclient';
  private _addToCPDLibraryURL = this.emsConfig.apiEndpoint + '/cpd/insertvolumesclientcpdlibrary';
  private _deleteVolumeCPDLibraryURL = this.emsConfig.apiEndpoint + '/cpd/deletevolumesclientcpdlibrary';

  // ******CLIENT BENEFIT HUB URLS********
  private _getClientBenefitHubListURL = this.emsConfig.apiEndpoint + '/benefitshub/getclientbenefithublist';
  private _getClientBenefitHubDetailsURL = this.emsConfig.apiEndpoint + '/benefitshub/getclientbenefithubdetails';
  private _getBenefitHubMainImageUrl = this.emsConfig.apiEndpoint + '/benefitshub/getbenefithubmainimages';
  private _insertUpdateBenefitHubURL = this.emsConfig.apiEndpoint + '/benefitshub/insertupdatebenefitbubaccount';

  // ******CLIENT BULLETIN BOARD URLS********
  private _getClientBulletinBoardListURL = this.emsConfig.apiEndpoint + '/bulletinboard/getbulletinboardlist';
  private _getClientBulletinBoardDetailsURL = this.emsConfig.apiEndpoint + '/bulletinboard/getclientbulletinboarddetails';
  private _insertUpdateBulletinBoardURL = this.emsConfig.apiEndpoint + '/bulletinboard/insertupdatebulletinboarddetails';
  private _deleteBulletinBoardURL = this.emsConfig.apiEndpoint + '/bulletinboard/deletebulletinboarddetails?clientname=';

  // ******CLIENT CAMPAIGN URLS********
  private _insertClientCampaignURL = this.emsConfig.apiEndpoint + '/campaign/insertupdateclientcampaign';

    // ******CONFIG FOR SSO LINKS********
  private _getSSOConfigURL = this.emsConfig.apiEndpoint + '/clientaccount/getclientssoconfig';

  constructor(private emsConfig: EmsConfig, private _httpClient: HttpClient) { }

  getClientPromotionalVolumes(username: string, lang: string, fromProduction: boolean = false) {
    const params = new HttpParams()
      .set('username', username)
      .set('language', lang)
      .set('fromProduction', fromProduction.toString());
    return this._httpClient.get<LibraryItem[]>(this._getClientPromotionalVolumesURL, { params: params });
  }

  // create new account
  createNewAccount(account: any): Observable<any> {
    const body = JSON.stringify(account);
    return this._httpClient.post<any>(this._createAccountURL, body);
  }
  // create new segment account
  createNewSegmentAccount(account: any): Observable<any> {
    const body = JSON.stringify(account);
    return this._httpClient.post<any>(this._createSegmentAccountURL, body);
  }
  // edit existing segment account
  editSegmentAccount(accountObj: any): Observable<any> {
    const body = JSON.stringify(accountObj);
    return this._httpClient.post<any>(this._editSegmentNameURL, body);
  }

  // get list of account managers
  getAccountManagers(): Observable<AccountManager[]> {
    return this._httpClient.get<AccountManager[]>(this._accountManagersURL);
  }

  // get list of all client accounts
  getAllAccounts(currentAccounts = false): Observable<Account[]> {
    return this._httpClient.get<Account[]>(this._accountsURL + currentAccounts);
  }
  getClientsWithRestrictedAtes( ): Observable<Account[]> {
    return this._httpClient.get<Account[]>(this._getclientswithrestrictedatesURL);
  }


  // get list of all segments for client
  getSegmentAccounts(client): Observable<any> {
    return this._httpClient.get<any>(this._getSegmentsForClientURL + client);
  }

  // get the details for a specific client account
  getAccountDetails(id: string): Observable<AccountDetails> {
    return this._httpClient.get<AccountDetails>(this._accountDetailsURL + id);
  }
  // get the details for a specific client account
  getParentClient(client: string): Observable<any> {
    return this._httpClient.get<any>(this._parentClientURL + client);
  }

  // update a given client's account settings
  updateAccountDetails(details: AccountDetails): Observable<AccountDetails> {
    const body = JSON.stringify(details);
    return this._httpClient.post<any>(this._updateAccountURL, body);
  }

  updateAccountStatus(username: string, status: string): Observable<any> {
    return this._httpClient.post<any>(this._updateStatusURL + username + '&status=' + status, null);
  }
  updateAccountLifeSpeakID(clientname: string, lifespeakid: string): Observable<any> {
    return this._httpClient.post<any>(this._updateLifeSpeakIDURL + clientname + '&lifespeakid=' + lifespeakid, null);
  }

  // ******Archive client methods ********
  archiveClient(username: string): Observable<any> {
    const body = JSON.stringify({ Username: username });
    return this._httpClient.post<any>(this._archiveAccountUrl, body);
  }

  dearchiveClient(username: string): Observable<any> {
    const body = JSON.stringify({ Username: username });
    return this._httpClient.post<any>(this._dearchiveAccountUrl, body);
  }


  // ******CLIENT LIBRARY METHODS********
  // get client's library (category=-1 returns all volumes)
  // fromProduction flag indicates getting data from production database
  // onlyInLibrary boolean will either get ALL volumes or those only in the client library,
  // set true if you only need the client's volumes, not the entire library
  getClientLibrary(username: string, lang: string, fromProduction: boolean = false, onlyInLibrary = false) {
    const params = new HttpParams()
      .set('clientname', username)
      .set('language', lang)
      .set('categoryId', '-1')
      .set('fromProduction', fromProduction.toString())
      .set('onlyInLibrary', onlyInLibrary.toString());
    return this._httpClient.get<LibraryItem[]>(this._getClientLibraryURL, { params: params });
  }

  // add volumes to client's library
  addToClientLibrary(username: string, volumeID: number): Observable<any> {
    const body = JSON.stringify({ Key: username, Value: volumeID });
    return this._httpClient.post<any>(this._addToLibraryURL, body);
  }

  // add volumes to client's library
  addInventoryToLibrary(username: string, inventoryID: number): Observable<any> {
    const body = JSON.stringify({ Key: username, Value: inventoryID });
    return this._httpClient.post<any>(this._addInventoryToLibrary, body);
  }

  // remove volumes from client's library
  removeFromClientLibrary(username: string, volumeID: number): Observable<any> {
    const body = JSON.stringify({ Key: username, Value: volumeID });
    return this._httpClient.post<any>(this._removeFromLibraryURL, body);
  }

  // *******GSIDEBAR RESOURCES METHODS********
  // get specific sidebar resource for client
  getSidebarResourceText(id: number, clientname: string) {
    return this._httpClient.get<any>(this._getSidebarResourceTextURL + id + '&clientname=' + clientname);
  }

  // return a list of sidebar resources for client
  getSidebarResourcesList(clientname: string, lang: string) {
    return this._httpClient.get<any>(this._getSidebarResourcesListURL + clientname + '&langcode=' + lang);
  }

  updateSidebarResourcesText(sidebarResourceText: SidebarResourceItem): Observable<any> {
    const body = JSON.stringify(sidebarResourceText);
    return this._httpClient.post<any>(this._updateSidebarResourceTextURL, body);
  }

  deleteSidebarResourceItem(id: number, clientName: string): Observable<any> {
    return this._httpClient.post<any>(this._deleteSidebarResourceItemURL + id + '&clientname=' + clientName, null);
  }

  // *******ShareLink METHODS********
  getShareKey(shareInfo: any): Observable<any> {
    const body = JSON.stringify(shareInfo);
    return this._httpClient.post<any>(this._getShareKeyURL, body);
  }

  getVolumesForSharelinks(username: string, lang: string, getImages: boolean): Observable<any> {
    return this._httpClient.get<any>(this._getVolumesForSharelinksURL + username + '&language=' + lang + '&getImages=' + getImages);
  }

  getTipsheetsForSharelinks(lang: string): Observable<any> {
    return this._httpClient.get<any>(this._getTipsheetsForSharelinksURL + '?language=' + lang)
  }

  getMatchedVolumesForSharelinks(username: string): Observable<any> {
    return this._httpClient.get<any>(this._getMatchedVolumesForSharelinksURL + username)
  }

  getPastSharelinks(username: string): Observable<any> {
    return this._httpClient.get<any>(this._getPastSharelinksURL + username);
  }

  getMarathonTranscriptInfo(lang: string): Observable<any> {
    return this._httpClient.get<any>(this._getMarathonTranscriptInfoUrl + '?language=' + lang);
  }

  getCurrentExpertEventID(lang: string): Observable<any> {
    return this._httpClient.get<any>(this._getCurrentExpertEventURL + '?language=' + lang);
  }

  // *******Watch and Win METHODS********
  getAllContests(username: string): Observable<any> {
    return this._httpClient.get<any>(this._getGetAllContestsURL + username);
  }

  updateContest(contest: any): Observable<any> {
    const body = JSON.stringify(contest);
    return this._httpClient.post<any>(this._updateContestURL, body);
  }

  contestHasEntries(id: number): Observable<any> {
    return this._httpClient.get<any>(this._contestHasEntriesURL + id);
  }
  deleteContest(id: number, deleteuserentries: boolean = false): Observable<any> {
    return this._httpClient.get<any>(this._deleteContestURL + id + '&deleteuserentries=' + deleteuserentries);
  }

  // *********Account info methods********
  // get the details for a specific client account
  getAccountInfo(id: string): Observable<AccountInfo> {
    return this._httpClient.get<any>(this._accountInfoURL + id);
  }

  // get all the industries for the account info dropdown
  getIndustries(): Observable<any> {
    return this._httpClient.get<any>(this._industryUrl);
  }

  // get all the partner organizations for account info dropdown
  getPartnerOrganizations(): Observable<any> {
    return this._httpClient.get<any>(this._partnerOrgUrl);
  }

  // get list of account managers
  getUserProfileById(userid: number): Observable<UserProfile> {
    return this._httpClient.get<any>(this._userProfileRequestUrl + userid);
  }

  // get list of account managers
  getUserProfileByEmail(email: string): Observable<UserProfile> {
    return this._httpClient.get<any>(this._userProfileByEmailRequestUrl + email);
  }

 
  // get list of all integration types. ex SSO, Intranet
  getClientIntegrationTypes(): Observable<any> {
    return this._httpClient.get<any>(this._integrationTypesURL);
  }
  getClientIntegrations(client: string): Observable<any> {
    return this._httpClient.get<any>(this._clientIntegrationsURL + client);
  }
  //Get list of integration types
  public getIntegrationTypes(): Observable<any[]> {
    let integrationTypes = this._httpClient.get<any[]>(`${this._integrationTypesURL}`);
    return integrationTypes;
  }
   // update an item in the account info section
  updateAccountInfo(username: string, fieldName: string, fieldVal: any, accountManager: string): Observable<any> {
    const details = { 'username': username };
    details[fieldName] = this.formatPropForUpload(fieldVal);
    details['AccountAdminUsername'] = accountManager;
    const body = JSON.stringify(details);
    return this._httpClient.post<any>(this._updateAccountInfoURL, body)
  }

  deleteclientIntegration(username: string, integrationId: number): Observable<any> {
    const body = JSON.stringify(integrationId);
    return this._httpClient.post<any>(this._deleteClientIntegrationURL + username, body);
  }
  //Update intiration types for the client
  updateClientIntegrationTypes(username: string, integrations: any[]): Observable<any> {
    const body = JSON.stringify(integrations);
    return this._httpClient.post<any>(this._updateClientIntegrationsURL + '?username=' + username, body)
  }
  // updates a sales or partner contact in the ems (or creates a new once)
  updateClientContactChangeDate(changedate: string, userId: number, client: string): Observable<any> {
    const body = JSON.stringify(changedate);

    const url = this._updateClientContactChangeDateURL + '?userid=' + userId.toString() + '&client=' + client;
    console.log(url);
    return this._httpClient.post<any>(url, body);
  }
  // updates a sales or partner contact in the ems (or creates a new once)
  updateEmsContact(profile: UserProfile): Observable<any> {
    const body = JSON.stringify(profile);
    return this._httpClient.post<any>(this._updateEmsContactUrl, body);
  }

  // updates the volumes purchases for a given year
  // in order to avoid creating a redundant object the values are sent to server as a key/value pair
  // with the username as the key, and the value the year and number of volumes separated by comma
  updateVolumesPurchased(username: string, contractYear: number, volumesPurchased: number): Observable<any> {
    const obj = { 'Key': username, 'Value': `${contractYear},${volumesPurchased}` };
    const body = JSON.stringify(obj);
    return this._httpClient.post<any>(this._updateVolumesPurchasedURL, body);
  }

  // get list of sales reps
  getSalesReps(): Observable<any[]> {
    return this._httpClient.get<any>(this._salesRepURL);
  }

  // this method formats objects (particularly dates form upload)
  private formatPropForUpload(prop: any): string {
    if (typeof (prop) !== 'object') {
      return prop;
    } else if (prop.date !== undefined) {
      // if it's a date, format it as yyyy-mm-dd
      return `${prop.date.month}/${prop.date.day}/${prop.date.year}`;
    }
  }

  // get approved email domains for organization
  getApprovedEmails(username: string): Observable<string[]> {
    return this._httpClient.get<any>(this._getOrgApprovedEmailsURL + username);
  }

  // Check if a mailing list exists
  addApprovedEmail(username: string, emailDomain: string): Observable<any> {
    const body = JSON.stringify({ 'Key': username, 'Value': emailDomain });
    return this._httpClient.post<any>(this._addApprovedEmailURL, body);
  }

  // Check if a mailing list exists
  deleteApprovedEmail(username: string, emailDomain: string): Observable<any> {
    const body = JSON.stringify({ 'Key': username, 'Value': emailDomain });
    return this._httpClient.post<any>(this._deleteApprovedEmailURL, body);
  }

  // *******Message Settings METHODS********
  // get the default message settings for a specific client account
  getmessageSettingDetails(id: string): Observable<MessageSettings> {
    return this._httpClient.get<MessageSettings>(this._messageSettingDetailsURL + id);
  }

  // update the default message settings for a specific client account
  updateMessageSettings(details: MessageSettings): Observable<MessageSettings> {
    const body = JSON.stringify(details);
    return this._httpClient.post<any>(this._updateMessageSettingURL, body);
  }

  // *******Password METHODS********
  // changes the client password
  changeClientPassword(oldPassword: string, newPassword: string, username: string): Observable<string> {
    return this._httpClient.post<string>(this._changeClientPasswordURL, { Username: username, OldUserKey: oldPassword, NewUserKey: newPassword });
  }

  sendPasswordChangeRequest(body: string): Observable<any> {
    return this._httpClient.post<any[]>(this._passwordRequestURL, { Body: body, Subject: 'EMS Password Change Request' });
  }

  // *******Bulk mailing list upload METHODS********
  // get lits of users by client
  getBulkMailingList(username: string): Observable<any> {
    return this._httpClient.get<any>(this._bulkMailingListURL + username);
  }

  // get total mailing list users by client
  getTotalMailingListforClient(username: string): Observable<any> {
    return this._httpClient.get<any>(this._totalMailingListURL + username);
  }

  // ******* Account contract Info *******

  // create or update contract records for client
  insertUpdateContractInfo(details: ContractInfo): Observable<any> {
    const body = JSON.stringify(details);
    return this._httpClient.post<any>(this._insertUpdateContractInfoURL, body);
  }

  // get contract info for clients
  getContractInfo(id: string): Observable<ContractInfo> {
    return this._httpClient.get<any>(this._contractInfoURL + id);
  }

  // delete contract file from cloudberry
  deleteContractFile(username: string, fileName: string): Observable<any> {
    const body = JSON.stringify({ Key: username, Value: fileName });
    return this._httpClient.post<any>(this._deleteContractFileURL, body);
  }

  // update contract file name in db after delete/ upload
  updateContractFiles(username: string, fileName: string): Observable<any> {
    const body = JSON.stringify({ Key: username, Value: fileName });
    return this._httpClient.post<any>(this._updateContractFilesURL, body);
  }

  // get account's launch date
  getLaunchDate(id: string): Observable<string> {
    return this._httpClient.get<any>(this._getLaunchDateURL + id);
  }
  // gets contract file name for client
  getContractFileName(id: string): Observable<string> {
    return this._httpClient.get<any>(this._getContractFileNameURL + id);
  }

  private _getQuestionnaireURL = this.emsConfig.apiEndpoint + '/questionnaire/getquestionnaire?clientname=';
  private _createQuestionnaireURL = this.emsConfig.apiEndpoint + '/questionnaire/create';
  private _updateQuestionnaireURL = this.emsConfig.apiEndpoint + '/questionnaire/update';
  private _getlastquestionnaireresetURL = this.emsConfig.apiEndpoint + '/questionnaire/getlastquestionnairereset?client=';
  private _clientquestionnaireresetURL = this.emsConfig.apiEndpoint + '/questionnaire/resetclientquestionnaire?client=';

  // ******* Client Questionnaire METHODS ********
  getQuestionnaire(clientname: string, lang: string): Observable<any> {
    return this._httpClient.get<any>(this._getQuestionnaireURL + clientname + '&language=' + lang);
  }

  createQuestionnaire(q: ClientQuestionnaire): Observable<any> {
    const body = JSON.stringify(q);
    return this._httpClient.post<any>(this._createQuestionnaireURL, body);
  }

  updateQuestionnaire(q: ClientQuestionnaire): Observable<any> {
    const body = JSON.stringify(q);
    return this._httpClient.post<any>(this._updateQuestionnaireURL, body);
  }

  //Get client preferences
  getClientPreferences(client: string, lang: string): Observable<AccountDetails> {
    return this._httpClient.get<AccountDetails>(this._clientPrefURL + client + '&language=' + lang);
  }

  //Get datetime of last questionnaire reset for client
  getLastQuestionnaireReset(client: string): Observable<any> {
    return this._httpClient.get<any>(this._getlastquestionnaireresetURL + client);
  }

  //Send client questionnaire reset request
  sendClientQuestionnaireReset(client: string): Observable<any> {
    return this._httpClient.post<any>(this._clientquestionnaireresetURL + client, null);

  }


  // ******CLIENT CPD LIBRARY METHODS********
  // get client's CPD library
  getCpdVolumesforClient(username: string) {
    const params = new HttpParams()
      .set('clientname', username)
    return this._httpClient.get<CPDVolume[]>(this._getCPDClientLibraryURL, { params: params });
  }

  // add volumes to client's CPD library
  addToClientCPDLibrary(clientname: string, volumeid: number): Observable<any> {
    const body = JSON.stringify({ Key: clientname, Value: volumeid });
    return this._httpClient.post<any>(this._addToCPDLibraryURL, body);
  }

  // remove volumes from client's CPD library
  deleteVolumeClientCPDLibrary(clientname: string, volumeid: number): Observable<any> {
    const body = JSON.stringify({ Key: clientname, Value: volumeid });
    return this._httpClient.post<any>(this._deleteVolumeCPDLibraryURL, body);
  }

  // ******BENEFIT HUB METHODS********
  // get client's benefit hub list
  getClientBenefitHubList(clientname: string) {
    const params = new HttpParams()
      .set('clientname', clientname)
    return this._httpClient.get<BenefitsHub[]>(this._getClientBenefitHubListURL, { params: params });
  }

  // get client's benefit hub details
  getClientBenefitHubDetails(id: number, clientname: string) {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('clientname', clientname);
    return this._httpClient.get<BenefitsHub>(this._getClientBenefitHubDetailsURL, { params: params });
  }
  // get all available main Images
  getBenefitHubMainImage() {
    return this._httpClient.get<any[]>(this._getBenefitHubMainImageUrl);
  }

  // create or update benefit hub page for client
  insertUpdateBenefitHub(details: BenefitsHub): Observable<any> {
    const body = JSON.stringify(details);
    return this._httpClient.post<any>(this._insertUpdateBenefitHubURL, body);
  }

  // ****** BULLETIN BOARD METHODS********
  getClientBulletinBoardList(clientname: string): Observable<any> {
    const params = new HttpParams()
      .set('clientname', clientname)
    return this._httpClient.get<any>(this._getClientBulletinBoardListURL, { params: params });
  }

  getClientBulletinBoardDetails(clientname: string, language: string): Observable<BulletinBoard[]> {
    const params = new HttpParams()
      .set('clientname', clientname)
      .set('language', language)
    return this._httpClient.get<BulletinBoard[]>(this._getClientBulletinBoardDetailsURL, { params: params });
  }

  insertUpdateClientBulletinBoard(details: BulletinBoard): Observable<any> {
    const body = JSON.stringify(details);
    return this._httpClient.post<any>(this._insertUpdateBulletinBoardURL, body);
  }

  deleteBulletinBoard(clientName: string, id: number): Observable<any> {
    return this._httpClient.post<any>(this._deleteBulletinBoardURL + clientName + '&Id=' + id, null);
  }

  //  *******Tiles URLS*******
  private _getCurrentTilesURL = this.emsConfig.apiEndpoint + '/homepage/getcurrenthomepagetilesbyclient?clientName=';
  private _getTilesByDateURL = this.emsConfig.apiEndpoint + '/homepage/gethomepagetilesbydate';
  private _getTileTypesURL = this.emsConfig.apiEndpoint + '/homepage/gethomepagetiletypes';
  private _getTileURL = this.emsConfig.apiEndpoint + '/homepage/gethomepagetile?id=';
  private _getImagesURL = this.emsConfig.apiEndpoint + '/homepage/gethomepageimages?clientName=';
  private _getBlogsURL = this.emsConfig.apiEndpoint + '/homepage/gethomepageblogs';
  private _updateTileURL = this.emsConfig.apiEndpoint + '/homepage/addupdatehomepagetile';
  private _deleteTileURL = this.emsConfig.apiEndpoint + '/homepage/deletehomepagetile?id=';

  // *******Tiles METHODS ********
  getCurrentTiles(clientname: string): Observable<Tile[]> {
    return this._httpClient.get<Tile[]>(this._getCurrentTilesURL + clientname);
  }

  getTilesByDateRange(clientname: string, startdate: string, enddate: string): Observable<Tile[]> {
    const params = new HttpParams()
      .set('clientName', clientname)
      .set('startDate', startdate)
      .set('endDate', enddate);
    return this._httpClient.get<Tile[]>(this._getTilesByDateURL, { params: params });
  }

  getTileTypes(): Observable<any> {
    return this._httpClient.get<any>(this._getTileTypesURL);
  }

  getTileByID(id: number): Observable<Tile> {
    return this._httpClient.get<Tile>(this._getTileURL + id);
  }

  getImages(clientname: string): Observable<string[]> {
    return this._httpClient.get<string[]>(this._getImagesURL + clientname);
  }

  getBlogs(clientname: string, lang: string, lsBreathe: boolean) {
    const params = new HttpParams()
      .set('clientname', clientname)
      .set('language', lang)
      .set('lsBreathe', lsBreathe.toString());
    return this._httpClient.get<any>(this._getBlogsURL, { params: params });
  }

  addUpdateTile(tile: Tile): Observable<any> {
    const body = JSON.stringify(tile);
    return this._httpClient.post<any>(this._updateTileURL, body);
  }

  deleteTile(id: number, clientName: string): Observable<any> {
    return this._httpClient.post<any>(this._deleteTileURL + id + '&clientname=' + clientName, null);
  }

  // ******* Campaign Account METHODS ********
  getClientCampaignList(clientName: string): Observable<any> {
    const url = this.emsConfig.apiEndpoint + "/campaign/getclientcampaignlist";
    const params = new HttpParams()
      .set('clientname', clientName);
    return this._httpClient.get<any>(url, { params });
  }

  getClientCampaignDetail(clientName: string, participatingId: number): Observable<any> {
    const url = this.emsConfig.apiEndpoint + "/campaign/getclientcampaigndetail";
    const params = new HttpParams()
      .set('clientname', clientName)
      .set('ParticipatingID', participatingId.toString());
    return this._httpClient.get<any>(url, { params });
  }

  insertUpdateClientCampaign(details: any): Observable<any> {
    const body = JSON.stringify(details);
    return this._httpClient.post<any>(this._insertClientCampaignURL, body);
  }

  getAvailableCC(): Observable<any> {
    return this._httpClient.get<any>(this._availableCCURL);
  }

  getClientSSOConfig(clientname: string):Observable<SSOConfigItem> {
    const params = new HttpParams()
      .set('clientname', clientname)
    return this._httpClient.get<SSOConfigItem>(this._getSSOConfigURL, { params: params });
  }
}

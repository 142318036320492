import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmsConfig } from '.././shared/emsConfig';
import { Categories } from './categories';

@Injectable()
export class CategoriesService {

  public categoryList: Categories[] = [];
  private _getAllCategoriesUrl = this.emsConfig.apiEndpoint + '/assetlistings/getclientcategories?getAllCategories=true';

  constructor(private _http: HttpClient, private emsConfig: EmsConfig) {
    // initialize categories
    this.getAllCategories().subscribe(
      CategoryList => {
        console.log("categories: ", CategoryList);
        this.categoryList = CategoryList['categories'];
      });
  }

  // get list of categories for account
  getAllCategories(): Observable<Categories[]> {
    return this._http.get<Categories[]>(this._getAllCategoriesUrl);
  }

  // get a category name by providing an id
  getCategoryName(id: any): string {
    if (isNaN(id)) {
      return 'Landing page';
    } else {
      // loop thru and return category name based on id provided
      for (let i = 0; i < this.categoryList.length; i++) {
        if (this.categoryList[i].ID === id) {
          return this.categoryList[i].Name.English;
        }
      }
    }

  }

}

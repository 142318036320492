import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmsConfig } from '../shared/emsConfig';
import { AdditionalDataUser } from 'app/shared/objects/additional-data-user';
import { CanadaReportBuildItem } from 'app/cms/canadaReportBuildItem';

@Injectable()
export class ReportingService {

  // report listing
  private _additionalDataUsers = this.emsConfig.apiEndpoint + '/analytics/getadditionaldatausers';

  public AdditionalDataUsers: AdditionalDataUser[] = [];

  public reportPath = 'https://lifespeaksecure.s3.amazonaws.com/UsageReports/';

  constructor(private _httpClient: HttpClient, private emsConfig: EmsConfig) {}

  // get list of users from the login questionnaire
  getAdditionalDataUsers(): Observable<any> {
    return this._httpClient.get<any>(this._additionalDataUsers);
  }

  // build canada report
  runCanadaReportBuilder(buildReportItem: CanadaReportBuildItem): Observable<any> {
    let url = this.emsConfig.apiEndpoint + '/reporting/canadareportbuilder';
    const body = JSON.stringify(buildReportItem);
    return this._httpClient.post<any>(url, body);
  }

}

<main class="section-padding inventory">
  <div class="header">
    <h1>Inventory</h1>
    <button (click)="openConfirmDialog('inventoryList', 'submit', _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)" class="btn">{{ _emsConfig.text?.EMS_General.Save }}</button>
  </div>

  <section class="inventory__filter-container">
    <div>
      <h2>
        <span>Volume Language</span>
        <div class="tooltip-container"><tooltip [tooltipMessage]="'Select the checkboxs to filter volumes by language'"></tooltip></div>
      </h2>
      <div class="checkbox-container" *ngFor="let lang of languages">
        <input type="checkbox" [(ngModel)]="lang.checked" (change)="toggleLanguage(lang.Key,lang.checked)">{{ lang.title }}
      </div>
    </div>

    <div>
      <h2>
        <span>Inventory Type</span>
        <div class="tooltip-container"><tooltip [tooltipMessage]="'Deselect the checkbox to hide the column from table'"></tooltip></div>
      </h2>
      <div class="checkbox-container" *ngFor="let item of inventories;">
        <input type="checkbox" [(ngModel)]="item.checked" (change)="toggleInventory(item.Key,item.checked)">{{ item.Value }}
      </div>
    </div>

    <div>
      <h2>
        <span>Inventory Status</span>
        <div class="tooltip-container"><tooltip [tooltipMessage]="'Select the checkboxes to filter volumes by status'"></tooltip></div>
      </h2>
      <div class="checkbox-container" *ngFor="let status of inventoryStatuses">
        <input type="checkbox" [(ngModel)]="status.checked" (change)="toggleStatus(status.Key, status.checked)">{{ status.Value }}
      </div>
    </div>
  </section>

  <section class="inventory__search-container">
    <div class="sort-container__searchbar">
      <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
      <input class="searchbar__input" [(ngModel)]="volumeSearch" type="search" placeholder="Search for volumes">
    </div>
    <div class="inventory__legend">
      <span>Volume status:</span>
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
          <path id="Volume_Off_icon" d="M1,1H13V13H1ZM0,14H14V0H0Z" fill="#666"/>
        </svg>
        {{ inventoryStatuses[0]?.Value }}
      </span>
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
          <g id="Volume_Soon_icon" transform="translate(-800 -200)">
            <rect id="Rectangle_2536" data-name="Rectangle 2536" width="14" height="14" transform="translate(800 200)" fill="#fd8e01"/>
            <path id="Path_3209" data-name="Path 3209" d="M27,1a6,6,0,1,0,6,6,6,6,0,0,0-6-6m2.52,8.52L26.4,7.6V4h.9V7.12L30,8.74Z" transform="translate(780 200)" fill="#fff"/>
          </g>
        </svg>
        {{ inventoryStatuses[1]?.Value }}
      </span>
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
          <g id="Volume_New_icon" transform="translate(-840 -200)">
            <path id="Path_3210" data-name="Path 3210" d="M0,0H14V14H0Z" transform="translate(840 200)" fill="#238469"/>
            <path id="Path_3211" data-name="Path 3211" d="M44.641,5.174V8.817H44.2a.382.382,0,0,1-.165-.032.388.388,0,0,1-.13-.108L42.189,6.5c.007.066.011.128.014.189s0,.118,0,.171V8.817h-.745V5.174h.446A.751.751,0,0,1,42,5.179a.306.306,0,0,1,.068.018.241.241,0,0,1,.057.039.747.747,0,0,1,.063.068l1.732,2.187c-.007-.069-.013-.137-.016-.2S43.9,7.16,43.9,7.1V5.174Z" transform="translate(800 200)" fill="#fff"/>
            <path id="Path_3212" data-name="Path 3212" d="M46,5.825v.85h1.146V7.3H46v.867H47.49v.65H45.145V5.175H47.49v.65Z" transform="translate(800 200)" fill="#fff"/>
            <path id="Path_3213" data-name="Path 3213" d="M52.974,5.174,51.849,8.817h-.766L50.4,6.559a.93.93,0,0,1-.038-.116c-.012-.042-.024-.087-.034-.134-.012.047-.024.092-.036.134a.93.93,0,0,1-.038.116l-.694,2.258h-.766L47.668,5.174h.71a.322.322,0,0,1,.184.05.227.227,0,0,1,.1.133l.5,1.9c.014.06.03.125.044.2s.031.145.045.221a2.874,2.874,0,0,1,.111-.417l.59-1.9a.247.247,0,0,1,.1-.126.284.284,0,0,1,.179-.057h.25a.286.286,0,0,1,.177.052.315.315,0,0,1,.1.131l.585,1.9a2.678,2.678,0,0,1,.111.4c.013-.075.027-.145.041-.211s.029-.128.044-.186l.5-1.9a.234.234,0,0,1,.094-.128.3.3,0,0,1,.181-.055Z" transform="translate(800 200)" fill="#fff"/>
          </g>
        </svg>
        {{ inventoryStatuses[2]?.Value }}
      </span>
      <span>
        <div class="active-square"></div>
        {{ inventoryStatuses[3]?.Value }}
      </span>
    </div>
    <div class="sort-dropdown">
      <!-- TODO - add sorting? -->
    </div>
  </section>

  <section class="inventory__table">

    <table id="inventory-list">
      <thead>
        <tr>
          <th>VOLUME'S NAME</th>
          <th *ngFor="let item of inventories" [hidden]="!item.checked">{{ item.Value }}</th>
        </tr>
      </thead>
      <tbody>
        <!-- Hide Total count row if user is searching for volume -->
        <tr class="row-total" [hidden]="(volumeSearch && volumeSearch.length > 0)">
          <td>Total</td>
          <td *ngFor="let inventory of inventories;" [hidden]="!inventory.checked">{{ inventory.total }}</td>
        </tr>
        <tr *ngFor="let item of inventoryOfVolume | volumeInventorySearch: (volumeSearch && volumeSearch.length > 0 ? _utilitiesService.normalizeTerm(volumeSearch) : ''); let i=index">
          <td>{{ item.VolumeTitle }}</td>
          <td *ngFor="let inventory of inventories;" [hidden]="!inventory.checked">
            <div class="ls-checkbox" (click)="updateVolumeStatus(item, inventory.Key)">
              <input  type="number"
                      id="inventory-{{ i }}-{{ inventory.Key }}"
                      [(ngModel)]="item?.InventoryStatusMapping[inventory.Key]"
                      min="0"
                      max="3"
                      class="status-input"
                      disabled
              >
              <label for="inventory-{{ i }}-{{ inventory.Key }}" class="status-icon">
                <span *ngIf="!item?.InventoryStatusMapping[inventory.Key] || item?.InventoryStatusMapping[inventory.Key] === 0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="Volume_Off_icon" d="M1,1H13V13H1ZM0,14H14V0H0Z" fill="#666"/>
                  </svg>
                </span>
                <span *ngIf="item?.InventoryStatusMapping[inventory.Key] === 1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <g id="Volume_Soon_icon" transform="translate(-800 -200)">
                      <rect id="Rectangle_2536" data-name="Rectangle 2536" width="14" height="14" transform="translate(800 200)" fill="#fd8e01"/>
                      <path id="Path_3209" data-name="Path 3209" d="M27,1a6,6,0,1,0,6,6,6,6,0,0,0-6-6m2.52,8.52L26.4,7.6V4h.9V7.12L30,8.74Z" transform="translate(780 200)" fill="#fff"/>
                    </g>
                  </svg>
                </span>
                <span *ngIf="item?.InventoryStatusMapping[inventory.Key] === 2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <g id="Volume_New_icon" transform="translate(-840 -200)">
                      <path id="Path_3210" data-name="Path 3210" d="M0,0H14V14H0Z" transform="translate(840 200)" fill="#238469"/>
                      <path id="Path_3211" data-name="Path 3211" d="M44.641,5.174V8.817H44.2a.382.382,0,0,1-.165-.032.388.388,0,0,1-.13-.108L42.189,6.5c.007.066.011.128.014.189s0,.118,0,.171V8.817h-.745V5.174h.446A.751.751,0,0,1,42,5.179a.306.306,0,0,1,.068.018.241.241,0,0,1,.057.039.747.747,0,0,1,.063.068l1.732,2.187c-.007-.069-.013-.137-.016-.2S43.9,7.16,43.9,7.1V5.174Z" transform="translate(800 200)" fill="#fff"/>
                      <path id="Path_3212" data-name="Path 3212" d="M46,5.825v.85h1.146V7.3H46v.867H47.49v.65H45.145V5.175H47.49v.65Z" transform="translate(800 200)" fill="#fff"/>
                      <path id="Path_3213" data-name="Path 3213" d="M52.974,5.174,51.849,8.817h-.766L50.4,6.559a.93.93,0,0,1-.038-.116c-.012-.042-.024-.087-.034-.134-.012.047-.024.092-.036.134a.93.93,0,0,1-.038.116l-.694,2.258h-.766L47.668,5.174h.71a.322.322,0,0,1,.184.05.227.227,0,0,1,.1.133l.5,1.9c.014.06.03.125.044.2s.031.145.045.221a2.874,2.874,0,0,1,.111-.417l.59-1.9a.247.247,0,0,1,.1-.126.284.284,0,0,1,.179-.057h.25a.286.286,0,0,1,.177.052.315.315,0,0,1,.1.131l.585,1.9a2.678,2.678,0,0,1,.111.4c.013-.075.027-.145.041-.211s.029-.128.044-.186l.5-1.9a.234.234,0,0,1,.094-.128.3.3,0,0,1,.181-.055Z" transform="translate(800 200)" fill="#fff"/>
                    </g>
                  </svg>
                </span>
                <span *ngIf="item?.InventoryStatusMapping[inventory.Key] === 3">
                  <div class="active-square"></div>
                </span>
              </label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</main>

<h2 mat-dialog-title>Custom questions</h2>
<mat-dialog-content class="mat-typography">
	
	
	<table>
		<thead>
              <tr>
                <th>Add a new template</th>
              </tr>
            </thead>
		<tbody>
    		<tr>
				<td>Name</td>
    		</tr>
    		<tr>
				<td>
					<textarea matInput placeholder="Ex. Pizza" value="Sushi" [(ngModel)]="templateInfo.Name" ></textarea>
				</td>
			</tr>
			<tr>
				<td>Template</td>
    		</tr>
			<tr>
				<td>
					<textarea matInput placeholder="Ex. Pizza" value="Sushi" [(ngModel)]="templateInfo.Template" ></textarea>
				</td>	
			</tr>
			<tr>
				<td><button type="button" class="" (click)="addTemplate()" [disabled]="networkCall">Add Template</button> </td>	
			</tr>
		</tbody>
	</table>
    
    
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
// Angular Things
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// EMS Things
import { EmsConfig } from 'app/shared/emsConfig';
import { SecuredRoutes } from 'app/shared/secured-routes';

// Services


@Component({
  selector: 'blog-component',
  templateUrl: 'blog.component.html'
})

export class BlogComponent implements OnInit {
  subscription: Subscription;

  constructor(public _emsConfig: EmsConfig, private router: Router, public _securedRoutes: SecuredRoutes) {
  }

  ngOnInit(): void {

  }
}

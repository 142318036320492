import { Component, HostBinding, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { EmsConfig } from '../../shared/emsConfig';
import { ConnexService } from '../connex.service';
import { SecuredRoutes } from 'app/shared/secured-routes';
import { PopupService } from '../../shared/popup.service';

// Objects
import { AteAnswer } from '../Objects/AteAnswer';

//signalr service and lib 
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { WebSocketsService } from 'app/connex/ate/websockets.service'
import { AteQuestionStatus } from '../Objects/AteQuestionStatus';

@Component({
  selector: 'ate-mod-posted',
  templateUrl: 'ate-mod-posted.component.html',
  providers: [ConnexService]
})
export class ATEModPosted implements OnInit {
  @HostBinding('class') classAttribute: string = 'ate-mod-posted';

  @Input() questions: AteAnswer[];
  @Input() langStr: string;

  @Output() moveQuestion = new EventEmitter();
  @Output() editQuestion = new EventEmitter();

  public hubConnection: HubConnection;
  public toggleModify = false;

  // temporarily disable all trash buttons for 1.5 seconds after clicking, to prevent double click spamming
  public disableButtons: boolean = false;

  constructor(
    public _emsConfig: EmsConfig,
    public _securedRoutes: SecuredRoutes,
    private _connexService: ConnexService,
    private _popupService: PopupService,
    private hubConnectionService: WebSocketsService
  ) {
  }

  async ngOnInit() {
    try {
      this.hubConnection = this.hubConnectionService.getConnection();
    } catch (err) {
      console.log(" Signalr connection error " + err);
    }
  }

  trashQuestion(question: AteAnswer) {
    this.tempDisableButtons();
    question.Status = AteQuestionStatus.Rejected;
    this._connexService.modifyATEQuestion(question)
      .subscribe(response => {
        if (response.QuestionID !== -1) {
          this.moveQuestion.emit(question);
          //Also post just question to live chat
          this.hubConnection.send('RemoveQuestionFromLiveChat', question);
        } else {
          question.Status = AteQuestionStatus.Sent;
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
        }
      })
  }

  tempDisableButtons() {
    this.disableButtons = true;
    setTimeout(() => {
      this.disableButtons = false;
    }, 1500);
  }

  modifyQuestion(question: AteAnswer) {
    const clone = JSON.parse(JSON.stringify(question));
    this.editQuestion.emit(clone);
  }

  ngOnDestroy() {
    console.log("destroy");
  }
}
import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})

export class ClickOutsideDirective {
  constructor(private _elementRef: ElementRef) { }

  @Output()
  public clickOutside = new EventEmitter();

  // HostListener allows us to listen for certain events on the host (ex. the DOM element)
  @HostListener('document:click', ['$event.target'])
  // the onClick methor will be invoked every time a click is performed on the whole document
  public onClick(targetElement: any) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement); // elementRef is a service that grants access to the DOM element using it's nativeElement property
    if (!clickedInside) {
      this.clickOutside.emit(null);
    }
  }
}
import { Component, HostBinding, OnDestroy, Input } from "@angular/core";
import { Subscription } from 'rxjs';
import { FormsModule } from "@angular/forms";
import { EmsConfig } from ".././shared/emsConfig";
import { SecuredRoutes } from "app/shared/secured-routes";
import { SyncUpdateService } from ".././sync/sync-update.service";
import { SyncInfoAsset } from "app/sync/sync-info-asset";
import { SyncService } from "app/sync/sync.service";
import { AssetSyncRequest } from "app/sync/asset-sync-request";
import { PopupService } from '../shared/popup.service';
import { AdminService } from "app/admin/admin.service";

@Component({
  selector: "syncstatus", // this renames the undefined element after router-outlet in the dom
  templateUrl: "sync-status.component.html"
})
export class SyncStatusComponent implements OnDestroy {
  public syncStatus: boolean;
  public syncMessage: string;
  public subscription: Subscription;
  public instantCacheClear:boolean;
  @Input() assetID: number = -1;
  @Input() tableID: number = -1;
  @Input() parentID: number = -1;
  @Input() ateChatReady: boolean = false;
  @Input() includeChildrenByDefault: boolean = false;
  @Input() emsOperationId: number = -1; //used to check if operation id supports instant cache clearing feature

  public modalOpen: boolean = false;

  public assetInfo: SyncInfoAsset;

  public assetSyncRequest: AssetSyncRequest = new AssetSyncRequest();

  public get isAssetSync(): boolean {
    return this.assetID !== -1;
  }

  public get hasChildRecords(): boolean {

    return this.tableID === 8;
  }

  private assetSyncMessage = this._emsConfig.text?.EMS_Sync.Asset_Sync_Confirmation;

  constructor(
    public _emsConfig: EmsConfig,
    private _syncDataService: SyncService,
    private _popupService: PopupService,
    public securedRoutes: SecuredRoutes,
    private _adminService: AdminService,
    private _syncUpdateService: SyncUpdateService
  ) {
    this.subscription = _syncUpdateService.syncData$.subscribe(data => {
      if (!data.prevent) {
        console.log(data);
        this.setSyncVals(data);
      }
    });
  }

  public setSyncVals(syncInfo): void {
    this.syncStatus = syncInfo.isSynced;
    if (this.isAssetSync) {
      this.syncMessage = this.syncStatus ? 'This record is live' : 'All changes are not live';
    } else {
      this.syncMessage = this.syncStatus ? 'Changes are live.' : 'Changes are not live.<br> Go to Sync Tab.';
    }
    this.instantCacheClear= (syncInfo.instantCacheClear && this.emsOperationId==syncInfo.emsOperationId);
  }

  public closeModal() {
    this.modalOpen = false;
  }

  public stopProp(event: any) {
    event.stopPropagation();
  }

  public getAssetSyncInfo(): void {
    if (!this.isAssetSync) {
      return;
    }

    this._syncDataService
      .getSyncInfoForAsset(this.assetID, this.tableID)
      .subscribe(data => {
        this.assetInfo = data;
        this.modalOpen = true;
      });
  }

  public executeAssetSyncWithChildAssets() {
    this.assetSyncRequest.includeChildren = true;
    this.executeAssetSync();
  }

  public executeAssetSyncWithParentOnly() {
    this.assetSyncRequest.includeChildren = false;
    this.executeAssetSync();
  }

  public executeAssetSync() {

    if (!this.isAssetSync || !confirm(this.assetSyncMessage)) {
      return;
    }

    if (this.includeChildrenByDefault) {
      this.assetSyncRequest.includeChildren = true;
    }

    this.assetSyncRequest.assetID = this.assetID;
    this.assetSyncRequest.tableID = this.tableID;
    this._syncDataService
      .executeAssetSyncOperationById(this.assetSyncRequest)
      .subscribe(result => {
        if (result.succeeded) {
          const cacheId = this.parentID != -1 ? this.parentID : this.assetID;
          this._popupService.updateNotification({ message: 'Sync Succeeded.', success: true });
          this.setSyncVals({ isSynced: true });
          // If sync was successful, check whether the sync was for ate event with chat ready
          if (this.assetSyncRequest.tableID === 9 && this.ateChatReady) {
            setTimeout(() => {
              this.getEmailNotificationStatusAfterSync();
            }, 2000);
          }
        } else {
          this._popupService.updateNotification({
            message: result.message ?
              result.message : 'There was an error. Please contact your adminstrator.',
              success: false,
               duration: 10000
          });

        }
      });
  }

  public getEmailNotificationStatusAfterSync() {
    // Clear ate cache before email is sent
    this._adminService.clearCacheForAsset('Events_Expert_' + this.assetID)
      .subscribe((data) => {
        console.log(data);
        if (data) {
          this._syncDataService
            .getAteEmailSentStatus(this.assetSyncRequest)
            .subscribe((result) => {
              if (result.succeeded) {
                this._popupService.updateNotification({
                  message: 'Email Sent to the subscribers',
                  success: true,
                });
              } else {
                this._popupService.updateNotification({
                  message: 'Email was not sent to subscribers! Please contact the DEVs.',
                  success: false,
                });
              }
            });
        } else {
          this._popupService.updateNotification({
            message: 'There was cache clearing error! Please contact the DEVs.',
            success: false,
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

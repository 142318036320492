import { Component, Input, EventEmitter, Output, OnInit, AfterViewInit } from "@angular/core";
import { AiService } from './ai.service';
import { SnackbarService } from 'app/services/Snackbar.service';
import { EmsConfig } from '../../shared/emsConfig';
import { AiPrompts, AiPromptType, AiChatData, AudioResponseFormat } from '../objects/aiPrompts';

@Component({
  selector: "ai-completion.",
  templateUrl: "ai-completion.component.html",
})

export class AiCompletionComponent implements OnInit {

    public enableAI: boolean = this._emsConfig.enableAI;
    public aiPrompts; // List of preset prompts
    public generatingAI: boolean = false; // AI Loading state
    public aiChatData: AiChatData[] = []; // prompt-specific data to pass to html component
    
    @Input() prompt: AiPromptType; // Type of AI command
    @Input() content: string = ''; // Provided content for AI Prompt: this can be a blog post, video transcript, video url
    @Input() list: any; // Additional parameters for AI Prompt 
    @Input() lang: string = "EN"; // EMS page lang

    @Output() emitResponse = new EventEmitter<any>(); // response from OpenAI to send back to parent component
    
    constructor (
        public _emsConfig: EmsConfig,
        private _aiService: AiService,
        private _snackBar: SnackbarService
    ) {}

    ngOnInit() {
        this.getPromptAndChatData();
    }

    getPromptAndChatData() {
        this.aiPrompts = new AiPrompts("");
        // Pass a concat list to prompt string
        if (this.list) {
            if (this.prompt == AiPromptType.Subcategories) {
                // Join all subcategories into a single string
                this.aiPrompts = new AiPrompts(this.list.map(s => s.Name).join(', '));
            } else if (this.prompt == AiPromptType.Tags) {
                // Join all tags into a single string
                this.aiPrompts = new AiPrompts(this.list.map(s => s['Name' + this.lang.toUpperCase()]).join(', '));
            }
        }
        // Set up prompt-specific variables to pass to html component
        this.aiChatData = [
            {
                showPrompt: false,
                prompt: this.aiPrompts.Keywords,
                promptPlaceholder: 'E.g. ' + this.aiPrompts.Keywords,
                showTemperature: true,
                temperature: 0.5,
                ctaText: this.generatingAI ? "Generating keywords..." : "Generate keywords",
                tooltipMessage: "Generate keywords via AI. Will provide 15-20 keywords. Please only generate once."
            },
            {
                showPrompt: false,
                prompt: this.aiPrompts.Subcategories,
                promptPlaceholder: 'E.g. ' + this.aiPrompts.Subcategories,
                showTemperature: false,
                temperature: 0.5,
                ctaText: this.generatingAI ? "Selecting subcategories..." : "Select subcategories",
                tooltipMessage: "Use AI to automatically check all applicable subcategories"
            },
            {
                showPrompt: false,
                prompt: this.aiPrompts.Tags,
                promptPlaceholder: 'E.g. ' + this.aiPrompts.Tags,
                showTemperature: false,
                temperature: 0.5,
                ctaText: this.generatingAI ? "Selecting tags..." : "Select tags",
                tooltipMessage: "Use AI to automatically check all applicable tags"
            }
        ]
    }

    getChatCompletion(): void {
        this.generatingAI = true;
        switch (this.prompt) {
            case AiPromptType.Keywords:
                this.getChatCompletionKeywords();
                break;
            case AiPromptType.Subcategories:
            case AiPromptType.Tags:
                this.getChatCompletionSubcategories();
                break;
        }
    }

    // Use OpenAI service to generate keywords
    getChatCompletionKeywords() {
        this._aiService.getChatCompletion(this.aiChatData[this.prompt].prompt, this.content, this.aiChatData[this.prompt].temperature).subscribe(
            res => {
                console.log(res);
                this.aiChatData[this.prompt].showPrompt = false;
                this.generatingAI = false;
                this.emitResponse.emit(res.choices[0].message.content[0].text);
            },
            err => {
                console.log(err)
                this._snackBar.error(err.errorMessage)
                this.generatingAI = false;
            }
        )
    }

    // Use OpenAI service to select any applicable subcategories checkboxes from list of available subcategories
    getChatCompletionSubcategories() {
        let newResponseArray = [];
        this.emitResponse.emit(newResponseArray); // Clear all previously selected checkboxes
        this._aiService.getChatCompletion(this.aiChatData[1].prompt, this.content, this.aiChatData[this.prompt].temperature).subscribe(
            res => {
                console.log(res)
                let aiSelection = res.choices[0].message.content[0].text.split(', ');
                for (let i = 0; i < this.list.length; i++) {
                    if (Object.values(aiSelection).includes(this.prompt == AiPromptType.Subcategories ? this.list[i].Name : this.list[i]['Name' + this.lang.toUpperCase()])) {
                        newResponseArray[this.list[i].ID] = true;
                    }
                }
                this.aiChatData[this.prompt].showPrompt = false;
                this.generatingAI = false;
                this.emitResponse.emit(newResponseArray);
            },
            err => {
                console.log(err)
                this._snackBar.error(err.errorMessage)
                this.generatingAI = false;
            }
        )
    }

}

// Angular Things
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../services/canDeactivateGuard.service';

// Components
import { CmsComponent } from './cms.component';
import { CmsVolumesComponent } from './details/cms-volumes.component';
import { CmsVolumeDetailComponent } from './details/cms-volume-details.component';
import { CmsOnpointDetailComponent } from './details/cms-onpoint-details.component';
import { CmsModulesComponent } from './details/cms-modules.component';
import { CmsModuleDetailComponent } from './details/cms-module-details.component';
import { CmsIqListComponent } from './details/cms-iq-list.component';
import { CmsIqEditComponent } from './details/cms-iq-edit.component';
import { CmsExpertComponent } from './details/cms-expert.component';
import { CmsExpertDetailComponent } from './details/cms-expert-details.component';
import { CmsInventoryListComponent } from './details/cms-inventory-list.component';

// Security Things
import { AuthGuard } from '../authGuard';
import { CMSReportingComponent } from 'app/cms/reports/cms-reports.component';
import { CMSManageSiteText } from './details/cms-manage-text.component';
import { CMSManageTextDetails } from './details/cms-manage-text-details.component';
import { CmsTipsheetListComponent } from './details/cms-tipsheet-list.component';
import { CmsTipsheetEditComponent } from './details/cms-tipsheet-edit.component';
import { CmsCpdComponent } from './details/cms-cpd.component';
import { CmsCpdVolumesComponent } from './details/cms-cpd-volumes.component';
import { CmsPollsComponent } from './details/cms-polls.component';
import { CmsPollDetailsComponent } from './details/cms-polls-details.component';
import { CmsCanadaReportTemplateListComponent } from './details/cms-canada-report-template-list.component';
import { CmsCanadaReportTemplateEditComponent } from './details/cms-canada-report-template-edit.component';
import { CmsCanadaReportBuildComponent } from './details/cms-canada-report-build.component';
import { CmsExpertReviewComponent } from './details/cms-expert-review.component';
import { CmsHelpComponent } from './details/cms-help.component';
import { CmsHelpEditComponent } from './details/cms-help-edit.component';
import { CmsImageAssetsComponent } from './details/cms-images.component';
import { CMSExpertSpecialities } from './details/cms-expert-specialities.component';
import { CmsCampaignListComponent } from './details/cms-campaign/cms-campaign-list/cms-campaign-list.component';
import { CmsCampaignDetailsComponent } from './details/cms-campaign/cms-campaign-details/cms-campaign-details/cms-campaign-details.component';
import { CmsLearningHubComponent } from './details/learninghub-list.component';
import { CmsLearningHubDetailsComponent } from './details/learninghub-details.component';
import { CmsSubcategoryListComponent } from './details/cms-subcategory/cms-subcategory-list/cms-subcategory-list.component';
import { CmsSubcategoryDetailsComponent } from './details/cms-subcategory/cms-subcategory-details/cms-subcategory-details.component';
import { CmsSubtitlingExportComponent } from './details/cms-subtitling-export.component';
import { CmsSubtitlingImportComponent } from './details/cms-subtitling-import.component';

const cmsRoutes: Routes = [
  {
    path: 'cms', component: CmsComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: CmsVolumesComponent, canActivate: [AuthGuard], }, // sets default route to volumes
      { path: 'volumes', component: CmsVolumesComponent, canActivate: [AuthGuard] },
      { path: 'reports', component: CMSReportingComponent, canActivate: [AuthGuard] },
      { path: 'volumes/:id', component: CmsVolumeDetailComponent, canActivate: [AuthGuard] },
      { path: 'modules', component: CmsModulesComponent, canActivate: [AuthGuard] },
      { path: 'modules/:id', component: CmsModuleDetailComponent, canActivate: [AuthGuard] },
      { path: 'onpoints', component: CmsVolumesComponent, canActivate: [AuthGuard] },
      { path: 'onpoints/:id', component: CmsOnpointDetailComponent, canActivate: [AuthGuard] },
      { path: 'iQ', component: CmsIqListComponent, canActivate: [AuthGuard] },
      { path: 'iQ/:id', component: CmsIqEditComponent, canActivate: [AuthGuard] },
      { path: 'expert', component: CmsExpertComponent, canActivate: [AuthGuard] },
      { path: 'expert/:id', component: CmsExpertDetailComponent, canActivate: [AuthGuard] },
      { path: 'inventory', component: CmsInventoryListComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'manage_text', component: CMSManageSiteText, canActivate: [AuthGuard] },
      { path: 'manage_text/:page/:id', component: CMSManageTextDetails, canActivate: [AuthGuard] },
      { path: 'tipsheets', component: CmsTipsheetListComponent, canActivate: [AuthGuard] },
      { path: 'tipsheets/:id', component: CmsTipsheetEditComponent, canActivate: [AuthGuard] },
      { path: 'cpd', component: CmsCpdComponent, canActivate: [AuthGuard] },
      { path: 'cpd/:profession/:jurisdiction/:language', component: CmsCpdVolumesComponent, canActivate: [AuthGuard] },
      { path: 'polls', component: CmsPollsComponent, canActivate: [AuthGuard] },
      { path: 'polls/:poll', component:  CmsPollDetailsComponent, canActivate: [AuthGuard] },
      { path: 'canadareports/template', component:  CmsCanadaReportTemplateListComponent, canActivate: [AuthGuard] },
      { path: 'canadareports/template/:id', component:  CmsCanadaReportTemplateEditComponent, canActivate: [AuthGuard] },
      { path: 'canadareports/reports', component:  CmsCanadaReportBuildComponent, canActivate: [AuthGuard] },
      { path: 'expertreview', component:  CmsExpertReviewComponent, canActivate: [AuthGuard] },
      { path: 'help', component:  CmsHelpComponent, canActivate: [AuthGuard] },
      { path: 'help/:id', component:  CmsHelpEditComponent, canActivate: [AuthGuard] },
      { path: 'specialities', component:  CMSExpertSpecialities, canActivate: [AuthGuard] },
      { path: 'images', component: CmsImageAssetsComponent, canActivate: [AuthGuard] },
      { path: 'campaigns', component: CmsCampaignListComponent, canActivate: [AuthGuard] },
      { path: 'campaigns/:id', component: CmsCampaignDetailsComponent, canActivate: [AuthGuard] },
      { path: 'learninghub', component: CmsLearningHubComponent, canActivate: [AuthGuard] },
      { path: 'learninghub/:id', component: CmsLearningHubDetailsComponent, canActivate: [AuthGuard] },
      { path: 'subcategories', component: CmsSubcategoryListComponent, canActivate: [AuthGuard] },
      { path: 'subcategories/:id', component: CmsSubcategoryDetailsComponent, canActivate: [AuthGuard]  },
      { path: 'subtitling/export', component: CmsSubtitlingExportComponent, canActivate: [AuthGuard]  },
      { path: 'subtitling/import', component: CmsSubtitlingImportComponent, canActivate: [AuthGuard]  }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(cmsRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class CmsRoutingModule { }

<div class="cms-page-container inner-page-container">
  <div class="vertical-nav" [ngClass]="{closed: closeNav}">
    <button class="vertical-nav__toggle pointer" (click)="closeNav = !closeNav">
      <svg class="close" fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
        <path d="M0-.5h24v24H0z" fill="none" />
      </svg>
      <svg class="open" fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
        <path d="M0-.25h24v24H0z" fill="none" />
      </svg>
    </button>
    <ul>

      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/volumes']" [routerLinkActive]="['active']">
        {{_emsConfig.text?.EMS_Content.Volumes}}</li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/modules']" [routerLinkActive]="['active']">
        {{_emsConfig.text?.EMS_Content.Modules}}</li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/onpoints']"
        [routerLinkActive]="['active']">{{_emsConfig.text?.EMS_Content.Onpoints}}</li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/iQ']" [routerLinkActive]="['active']">
        {{_emsConfig.text?.EMS_Content.IQ}}</li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/expert']" [routerLinkActive]="['active']">
        {{_emsConfig.text?.EMS_Content.Experts}}</li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/expertreview']"
        [routerLinkActive]="['active']">Expert Review Tool</li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/reports']">CMS Reports</li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/manage_text']"
        [routerLinkActive]="['active']">Manage Site Text</li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/inventory']"
        [routerLinkActive]="['active']">{{_emsConfig.text?.EMS_Content.Inventory}}</li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/tipsheets']"
        [routerLinkActive]="['active']">Tipsheets</li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/cpd']" [routerLinkActive]="['active']">CPD
      </li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/campaigns']" [routerLinkActive]="['active']">
        Campaigns</li>
     <!-- <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/polls']" [routerLinkActive]="['active']">
        Polls</li>-->
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/help']" [routerLinkActive]="['active']">
        Help</li>
      <li class="vertical-nav__list-item ls-subheading"
        [ngClass]="{'active': active.canadareports, 'close-sub-nav': closeSubNav.canadareports}"
        (click)="toggleSubNav('canadareports')" [routerLinkActive]="['active']">Canada Report Templates
        <ul class="vertical-nav__subnav" (click)="stopProp($event)">
          <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/canadareports/template']"
            [routerLinkActive]="['active']">Templates</li>
          <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/canadareports/reports']"
            [routerLinkActive]="['active']">Run Report</li>
        </ul>
      </li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/images']" [routerLinkActive]="['active']">
        Image Assets</li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/learninghub']" [routerLinkActive]="['active']">
        Learning Hub</li>
        <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/subcategories']"
        [routerLinkActive]="['active']">Subcategories</li>
      <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/cms/subtitling/export']"
        [routerLinkActive]="['active']">Manage Subtitling</li>
    </ul>
  </div>
  <div class="inner-page-main">
    <router-outlet></router-outlet>
  </div>
</div>

import { ChangeDetectorRef, Component, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsService } from 'app/cms/cms.service';
import { SnackbarService } from 'app/services/Snackbar.service';
import { SpeechRecognitionService } from 'app/services/speechRecognition.service';
import * as saveAs from 'file-saver';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { ConfirmService } from '../confirm.service';
import { EmsConfig } from '../emsConfig';
import { PopupService } from '../popup.service';
import { SpinnerService } from '../spinner.service';
import { SubtitleLang } from '../SubtitleLang';
import { UtilitiesService } from '../utilities.service';
import { Location } from '@angular/common';
import { AiPromptType, AudioResponseFormat } from '../../shared/objects/aiPrompts';

@Component({
  selector: './app-subtitle-details',
  templateUrl: './subtitle.component.html'
})

export class SubtitleComponent implements OnInit {
  confirmSubscription: Subscription;
  confirmAction: string;
  public Subtitles: SubtitleLang[] = [];
  
  //this variable is used to store all the languages that are stored in Languages table in DB
  public allLsLanguages: any[] = [];
  
  //after filtering out existing subtitle languages for current clip, this varibale stores the rest languages of allLsLanguages array
  public languages: any[] = [];

  private subtitlePath = this._emsConfig.LifeSpeakCaptionPath;
  maxFileSize: number = 10 * 1024 * 1024;
  public subtitleUploader: FileUploader = new FileUploader({ url: this._emsConfig.apiEndpoint + '/assetadmin/uploadsubtitlefile', maxFileSize: this.maxFileSize });
  public showAddEditSubtitleSection: boolean = false;
  public showAddSubtitleSection: boolean = false;
  public clipLanguage: string = '';
  public subtitleLanguage: SubtitleLang = new SubtitleLang();
  public editSingleSubtitle: boolean = false;
  public fileUploadSuccess = false;
  @ViewChild('fileInput') fileInput: any;


  confirmData: {};
  confirmSubtitleData: any;


  @Input() assetId: number;
  @Input() currentVideoTitle: string = '';
  @Input() Language: string;
  @Input() TableID: number;
  @Input() VolumeID:number;
  @Input() VideoUrl: string = '';

  aiPromptType = AiPromptType;
  audioResponseFormat = AudioResponseFormat;

  constructor(
    private location: Location,
    private _cmsService: CmsService,
    private _utilitiesService: UtilitiesService,
    private _confirmService: ConfirmService,
    private _spinnerService: SpinnerService,
    public _emsConfig: EmsConfig,
    private _snackBar: SnackbarService,
    public _speechRecognitionService: SpeechRecognitionService
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'deleteSubtitle') {
            this.deleteEntireSubtitle(this.confirmSubtitleData);
          }
          if (this.confirmAction === 'deleteVttFile') {
            this.removeVttFile();
          }
        }
      }
    );
  }


  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(component: string, action: string, subtitleData: any, message: string): void {
    this.confirmAction = action;
    this.confirmSubtitleData = subtitleData;

    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }


  // ============================
  // subtitle management for clip
  // =============================

  // get list of all subtitles for the clip
  getAllSubtitles(): void {
    this._cmsService.getAllSubtitles(this.assetId)
      .subscribe(
        async subtitleItem => {
          this.Subtitles = subtitleItem;
          await this.filterExistingSubtitleLanguages();
          await this.sortSubtitleList();
        },
        err => {
          console.log(err);
          if (err.status != 404)
            this._snackBar.error();
        }
      );
  }

  // create subtitle file name from lag code & clipid, as we dont store them in db
  getSubtitleFileName(langCode: string): string {
    return `${langCode.toLocaleLowerCase()}-captions-${this.assetId}.vtt`;
  }

  // sort the subtitles before display to make sure default language is always on top
  async sortSubtitleList(){
    if (this.Subtitles) {
      this.Subtitles.forEach((element, index) => {
        element.TableID = this.TableID;
        element.VolumeID=this.VolumeID;
        if (element.LangCode.toLowerCase() === this.Language.toLowerCase()) {
          this.Subtitles[index].Title = this.currentVideoTitle;
          var element = this.Subtitles[index];
          this.Subtitles.splice(index, 1);
          this.Subtitles.splice(0, 0, element);
        }
      });
    }
  }

  // if subtitle does not have any title then show default text
  getTitle(title: string): string {
    return title ? title : 'Please add title';
  }

  // downloads the subtitle from amazon
  downloadSubtitleFile(langCode) {
    let fileName = `${langCode.toLowerCase()}-captions-${this.assetId}.vtt`;
    let path = `${this.subtitlePath}/${langCode.toLowerCase()}/${fileName}`;
    console.log(path);
    saveAs(path, fileName);
  }


  //loads all the languages supported by the LifeSpeak
  getAllLanguages() {
    this._cmsService.getLanguages().subscribe(languages => {
      this.allLsLanguages = this.languages = languages;
      console.log(this.allLsLanguages);
    });
  }

  // filter out languages to make sure that existed languages are not showing up in lang dropdown for 'add new subtitle section'
  async filterExistingSubtitleLanguages() {
    this.languages = this.allLsLanguages?.filter(o1 => !this.Subtitles.some(o2 => o1.Code.toLowerCase() === o2.LangCode.toLowerCase()));
    console.log(this.languages);
  }

   // ==========================================================================================================================
  // To add, update or delete, the shared subtitle component has individual save/delete button. Every time user saves/deletes/updates 
  // any subtitle it would trigger sync for parent component and reload the page to get sync status. When user click sync button 
  // in parent page, the subtitle data would also sync with parent data with single store procedure  
  // ============================================================================================================================


  //add new subtitle info for the clip
  addSubtitleForClip() {
    console.log(this.subtitleLanguage);
    // check for empty entries before saving subtitle
    // To save a new subtitle language, there must be atleast value for language & uploaded vtt file or language & title
    if ((this._utilitiesService.isBlank([this.subtitleLanguage.LangCode])
      || this._utilitiesService.isBlank([this.subtitleLanguage.SubtitleFileName])) && (this._utilitiesService.isBlank([this.subtitleLanguage.LangCode])
        || this._utilitiesService.isBlank([this.subtitleLanguage.Title]))) {
      this._snackBar.error('Please select your language, and add either a vtt file, a title, or both');
      return;
    }
    this.subtitleLanguage.ClipID = this.assetId;
    this.subtitleLanguage.TableID = this.TableID;
    this.subtitleLanguage.VolumeID= this.VolumeID;
    this.subtitleLanguage.CaptionUploaded = (this.fileUploadSuccess || this.subtitleLanguage.CaptionUploaded) ? true : false;
    this._cmsService.addUpdateSubtitleForClip(this.subtitleLanguage).subscribe(data => {
      if (data != -1) {
        this._snackBar.success('The new subtitle info has been saved');
        this.refresh();
      }
    },
      err => {
        this._snackBar.error();
        return;
      });
  }

  //first update the subtitle info in DB. If it is successful then remove it from amazon, otherwise return & show the error
  removeVttFile() {
    if (this.subtitleLanguage.CaptionUploaded) {
      this.subtitleLanguage.CaptionUploaded = false;
      this._cmsService.addUpdateSubtitleForClip(this.subtitleLanguage).subscribe(data => {
        console.log(data);
      },
        err => {
          this._snackBar.error();
          return;
        })
    };

    this._cmsService.deleteSubtitleFile(this.subtitleLanguage.LangCode.toLowerCase(), this.subtitleLanguage.SubtitleFileName)
      .subscribe(
        data => {
          console.log(data);
          this.subtitleLanguage.SubtitleFileName = '';
          this._snackBar.success('The VTT file has been deleted successfully');
          this.refresh();
        },
        err => { this._snackBar.error(); }
      );
  }

  //bulk updates(title) for all the subtitles
  bulkUpdateSubtitlesForClip() {
    console.log(this.Subtitles);
    this._cmsService.bulkUpdateSubtitlesForClip(this.Subtitles).subscribe(data => {
      this._snackBar.success("All the subtitles have been updated");
      this.refresh();
    }, err => {
      this._snackBar.error();;
      return;
    });
  }


  
  //load single subtitle edit for individual subtitle
  loadSingleSubtitleEditSection(item: SubtitleLang) {
    this.toggleAddSubtitleSection();
    this.subtitleLanguage = item;
    if (this.subtitleLanguage.CaptionUploaded) {
      this.subtitleLanguage.SubtitleFileName = this.getSubtitleFileName(this.subtitleLanguage.LangCode);
    }
    this.editSingleSubtitle = true;
  }

  //refresh the page after all subtitle actions to get sync status
  refresh(): void {
    window.location.reload();
  }

  //first delete subtitle info from DB. If it successful then remove it from amazon, otherwise return & show the error
  deleteEntireSubtitle(item: SubtitleLang) {
    this._cmsService.deleteEntireSubtitleInfo(item)
      .subscribe(
        data => {
        },
        err => {
          this._snackBar.error();
          return;
        }
      );
    item.SubtitleFileName = this.getSubtitleFileName(item.LangCode);
    this._cmsService.deleteSubtitleFile(item.LangCode.toLowerCase(), item.SubtitleFileName)
      .subscribe(
        data => {
          console.log(data);
          this._snackBar.success('File info has been deleted successfully');
          this.refresh();
        },
        err => { this._snackBar.error(); }
      );
  }

  // toggle to show add,edit,delete button
  toggleAddEditSubtitle(): void {
    this.showAddEditSubtitleSection = !this.showAddEditSubtitleSection;
  }

  // toggle to add subtitle
  toggleAddSubtitleSection(): void {
    this.subtitleLanguage = new SubtitleLang();
    this.showAddSubtitleSection = !this.showAddSubtitleSection;
    this.toggleAddEditSubtitle();
  }

  // cancel funtion for add/edit single subtitle file
  cancelSingleAddEditSection() {
    this.cancelBulkEditSection();
    this.getAllSubtitles();
  }

  // cancel function for all subtitle edit section
  cancelBulkEditSection() {
    this.showAddSubtitleSection = false;
    this.showAddEditSubtitleSection = false;
    this.editSingleSubtitle = false;
  }

  // Add File type to uploader queue
  addSubtitleFile(file) {
    this.uploaderInit();
    // Ensure langcode is set in order to upload into correct folder
    this.subtitleUploader.options.additionalParameter = { 'langcode': this.subtitleLanguage.LangCode.toLocaleLowerCase() };

    const fileItem = new FileItem(this.subtitleUploader, file, {url:''});
    this.subtitleUploader.queue.push(fileItem);
    
    this.uploadSubtitleFile();
  }

  // ===============
  // subtitle upload
  // ===============
  // checks before upload & then uploads vtt file
  uploadSubtitleFile(): void {
    let currentItem;
    let uploader;
    let fileType = '';
    this.fileInput.nativeElement.value = '';

    uploader = this.subtitleUploader.queue;
    currentItem = this.subtitleUploader.queue[0];
    fileType = '.vtt';

    if (currentItem !== undefined && currentItem.file.name.indexOf(fileType) === -1) {
      this._snackBar.info('Only ' + fileType + ' files are permitted for upload');
      uploader.pop();
    } else if (!this.subtitleLanguage.LangCode) {
      this._snackBar.info('Select Subtitle Language first');
      uploader.pop();
    }
    else {
      // by default, the file uploader plugin requires credentials for submission, this removes them
      uploader.forEach(file => file.withCredentials = false);
      // manually trigger the ajax spinner (doesn't work for file uploader requests)
      // this._spinnerService.updateSpinner({ toggle: true });
      this.subtitleUploader.queue[0].file.name = this.getSubtitleFileName(this.subtitleLanguage.LangCode);
      currentItem.upload();
    }
  }

  uploaderInit() {
    // gets all the token api headers and adds to any upload request
    this.subtitleUploader.options.headers = this._emsConfig.getFileUploadHeaders();
    this.subtitleUploader.onAfterAddingAll = (file) => {
      this.subtitleUploader.options.additionalParameter = { 'langcode': this.subtitleLanguage.LangCode.toLocaleLowerCase() };
    }
    // gets the response after vtt file upload is completed
    this.subtitleUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (this._utilitiesService.isBlank([response])) {
        this._snackBar.error('Unknown error. Subtitle file has not been uploaded');
        this._spinnerService.updateSpinner({ toggle: false });
      } else {
        if (!item.isSuccess) {
          this._snackBar.error('Subtitle file has not been uploaded. Please try again.');
          this.subtitleUploader.queue = [];
          this._spinnerService.updateSpinner({ toggle: false });
        } else {
          this._snackBar.success('Subtitle file has been successfully uploaded. Please click save button to store the details', 5000);
          this.subtitleLanguage.SubtitleFileName = this.subtitleUploader.queue[0].file.name;
          this.fileUploadSuccess = true;
          this.subtitleLanguage.CaptionUploaded = true;
          this.subtitleUploader.queue = [];
          this._spinnerService.updateSpinner({ toggle: false });
        }
      }
    };
  }

  ngOnInit(): void {
    this.uploaderInit();
    this.getAllLanguages();
    this.getAllSubtitles();
  }

}

<div class="canada-report-template-edit-container section-padding">
    <div class="basic-container info-container volume-details-container__info-container">
        <h3 class="ls-subheading f-dark-blue info-container__heading">Template Details</h3>
        <div class="details-container info-container__text-container">
          <form action="" class="addEditTipsheet">
            <div class="info-container__section-split">
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z" />
                    </svg>
                  </div>
                  Template ID
                </h4>
                <p class="basic-info__text setting__text ls-subheading">{{template?.ID}}</p>
              </div>
            </div>
            <div class="info-container__section-split">
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                    </svg>
                  </div>
                  Template Title
                  <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Content.Canada_Report_Template_Tooltip"></tooltip>
                </h4>
                <div class="input-container">
                  <input type="text" class="basic-info__text setting__text-input text-input template-title" name="templateTitle" [(ngModel)]="template && template.TemplateTitle">
                </div>
              </div>
            </div>
          </form>
        </div>
    </div>
    <div class="basic-container info-container volume-details-container__info-container">
      <h3 class="ls-subheading f-dark-blue info-container__heading">Template Contents</h3>
      <div class="details-container info-container__text-container editor-container">
        <textarea id="template-area" [innerHTML]="templateEditorData | safeHtml"></textarea>
        <div class="form-button-container">
          <button (click)="openConfirmDialog('template', 'updateTemplate', template, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
            type="button" class="btn template-submit">
            Save
          </button>
        </div>
      </div>
    </div>
</div>

<div class="expert-details-container section-padding expert-details">

  <syncstatus [assetID]="expertDetails?.ExpertID" [tableID]="12"></syncstatus>
  <div class="basic-container info-container module-details-container__info-container">
    <h3 class="ls-subheading f-dark-blue info-container__heading">{{_emsConfig.text?.EMS_General.Expert_Details}}</h3>
    <div class="subheading">
      All of the common (non-language specific) fields appear on the English tabs. The other tabs contain only
      language-specific fields
      Clicking submit will save the data in all tabs.

    </div>

    <div class="expert-details__lang-button-container lang-button-container extra-margin-top">
      <button class="btn--back back pointer" (click)="getBack()">&#8592; Back</button>
      <button
        (click)="openConfirmDialog('expertDetails', 'updateExpert',expertDetails, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
        type="button" class="btn expert-details-submit btn-place-right">
        Update expert info
      </button>
    </div>
    <div class="details-container info-container__text-container ">
      <form class="addEditModuleForm">
        <div class="info-container__section-split">
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z" />
                </svg>
              </div>
              {{_emsConfig.text?.EMS_General.Expert_ID}}
            </h4>
            <p class="basic-info__text setting__text ls-subheading">{{expertDetails?.ExpertID}}</p>
          </div>

          <div [hidden]="Lang !== 'EN'" class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                </svg>
              </div>
              {{_emsConfig.text?.EMS_General.First_Name}}
            </h4>
            <div class="input-container">
              <input type="text" class="basic-info__text setting__text-input text-input" name="firstNameUrl"
                [(ngModel)]="expertDetails && expertDetails.FirstName">
            </div>
          </div>

          <div [hidden]="Lang !== 'EN'" class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                </svg>
              </div>
              {{_emsConfig.text?.EMS_General.Last_Name}}
            </h4>
            <div class="input-container">
              <input type="text" class="basic-info__text setting__text-input text-input" name="lastNameUrl"
                [(ngModel)]="expertDetails && expertDetails.LastName">
            </div>
          </div>

          <div [hidden]="Lang !== 'EN'" class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                </svg>
              </div>
              Main Language
            </h4>
            <div class="select-container">
              <select class="setting__select-input select-input" name="lang" id="lang"
                [(ngModel)]="expertDetails && expertDetails.Lang">
                <option value="">Select language</option>
                <option value="EN">English</option>
                <option value="FR">French</option>
              </select>
            </div>
          </div>


          <div class="input-container">
            <label class="basic-info__heading setting__heading" for="username">Signup email <tooltip
                [tooltipMessage]="'Add the expert’s email to create an ATE sign-in with the password: ATE!1Expert@LS'">
              </tooltip></label>
            <input type="email" class="basic-info__text setting__text-input text-input" name="username" id="username"
              [(ngModel)]="expertDetails && expertDetails.Username">
          </div>

          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                </svg>
              </div>
              Include in Content Fusion?
            </h4>
            <div class="basic-info__checkbox-container">
              <div class="checkbox-container">
                <div class="contentfusion-checkbox">
                  <input name="includecontent" [(ngModel)]="expertDetails && expertDetails.IncludeInContentFusion" type="checkbox">
                </div>
              </div>
            </div>
          </div>

          <div [hidden]="Lang !== 'EN'" class="basic-info__setting upload-setting image-upload__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
                </svg>
              </div>
              {{_emsConfig.text?.EMS_General.Expert_Image}} <tooltip
                [tooltipMessage]="_emsConfig.text?.EMS_General.Expert_Image_Tooltip"></tooltip>
            </h4>
            <div class="expert-image-container">
              <img [src]="imgPreviewUrl" alt="Image is not uploaded yet">
            </div>
          </div>

          <div [hidden]="Lang !== 'EN'" class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading ">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
                </svg>
              </div>
              {{_emsConfig.text?.EMS_General.Image_File_Name}}
            </h4>
            <div class="expert-image-path">{{expertDetails?.PhotoUrl}}</div>
          </div>

          <div [hidden]="Lang !== 'EN'" class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading ">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M14,10H19.5L14,4.5V10M5,3H15L21,9V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3M5,12V14H19V12H5M5,16V18H14V16H5Z" />
                </svg>
              </div>
              {{_emsConfig.text?.EMS_General.Notes}}
            </h4>
            <div class="input-container">
              <textarea class="basic-info__text setting__text-input textarea-input" rows="4" name="noteUrl"
                [(ngModel)]="expertDetails && expertDetails.Notes"></textarea>
            </div>
          </div>



          <div [hidden]="Lang !== 'EN'"
            *ngIf="expertDetails && !_utilitiesService.isBlank([expertDetails.ConnexStatus])">
            <div class="row-items">
              <div class="select-container">
                <label class="basic-info__heading setting__heading" for="time-zone">Time Zone</label>
                <select class="setting__select-input select-input" name="time-zone" id="time-zone"
                  [(ngModel)]="expertDetails && expertDetails.TimeZone">
                  <option value="ET">Eastern Time</option>
                  <option value="PT">Pacific Time</option>
                </select>
              </div>
            </div> <!-- ./row-items -->

          </div>
          <!-- END: Connex Properties -->
        </div>

        <!--second half-->
        <div class="info-container__section-split">
          <div class="basic-info__setting">
            <div class='expert-lang-edit'>
              <a href="javascript:void(0)" (click)="toggleLangSection()">
                {{addDeleteLangText}}</a>
            </div>
          </div>
          <div class="basic-info__setting expert-lang-container" *ngIf="showLangSection">
            <div class="select-container">
              <select class="setting__select-input select-input" name="expertLanguage" [(ngModel)]="selectedLanguage"
                (ngModelChange)="SelectLanguage($event)">
                <option [disabled]="true" [selected]="true" value="">Add Languages</option>
                <option value="{{lang.Code}}" *ngFor="let lang of allLanguages">
                  {{lang.Name}}</option>
              </select>
            </div>
          </div>


          <div class="global-bio-container">
            <mat-tab-group [selectedIndex]="selected" (selectedIndexChange)="langTabSelectedIndexChange($event)">
              <mat-tab *ngFor="let tab of langTabs; let index = index">
                <ng-template mat-tab-label>{{tab.Lang | uppercase}}</ng-template>

                <div class="expert-lang-form-container" [ngClass]="{'form-disabled': showLangSection}">
                  <div class="basic-info__setting">
                    <h4 class="basic-info__heading setting__heading ls-subheading">
                      <div class="svg-icon svg-baseline">
                        <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                          <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                        </svg>
                      </div>
                      {{_emsConfig.text?.EMS_General.Title}}
                    </h4>
                    <div class="input-container">
                      <input type="text" class="basic-info__text setting__text-input text-input"
                        id="expertTitle{{index}}" name="expertTitle{{index}}" [(ngModel)]="tab.Title"
                        [disabled]="showLangSection">
                    </div>
                  </div>

                  <div class="basic-info__setting">
                    <h4 class="basic-info__heading setting__heading ls-subheading">
                      <div class="svg-icon svg-baseline">
                        <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                          <path
                            d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />
                        </svg>
                      </div>
                      {{_emsConfig.text?.EMS_General.Short_Bio}} <tooltip
                        [tooltipMessage]="_emsConfig.text?.EMS_General.Short_Bio_Tooltip"></tooltip>
                    </h4>
                    <div class="input-container">
                      <input type="text" class="basic-info__text setting__text-input text-input" id="short-bio{{index}}"
                        name="short-bio{{index}}" [(ngModel)]="tab.ShortBio" [disabled]="showLangSection">
                    </div>
                  </div>

                  <div class="basic-info__setting transcript-setting">
                    <h4 class="basic-info__heading setting__heading ls-subheading">
                      <div class="svg-icon svg-baseline">
                        <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                          <path
                            d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z" />
                        </svg>
                      </div>
                      {{_emsConfig.text?.EMS_General.Full_Bio}} <tooltip
                        [tooltipMessage]="_emsConfig.text?.EMS_General.Full_Bio_Tooltip">
                      </tooltip>
                    </h4>
                    <div class="input-container editor-container">
                      <textarea id="expert-full-bio-default{{index}}" [innerHTML]="tab.FullBio"></textarea>
                    </div>
                  </div>

                </div>
                <button mat-raised-button class="btn delete-btn" (click)="removeLangTab(index)" *ngIf="showLangSection">
                  Delete {{tab.Lang | uppercase}} Tab </button>
              </mat-tab>
            </mat-tab-group>
          </div>

          <div class="bio-videos-container">
            <div class="basic-info__setting">
              <h4 class="basic-info__heading setting__heading ls-subheading">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" viewBox="0 0 24 24">
                    <path
                      d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                  </svg>
                </div>
                Bio Videos
              </h4>
              <div class="input-container">
                <div class="bio-video-list">
                  <div class="video-field-labels">
                    <p class="bio-video-label brightcove-label">Brightcove ID</p>
                    <p class="bio-video-label lang-label">Language</p>
                    <p class="bio-video-label uploaded-label">Uploaded to Amazon (High Res)</p>
                    <p class="bio-video-label uploaded-label">Uploaded to Amazon (Low Res)</p>
                  </div>
                  <!-- display/edit fields -->
                  <div class="video-item-container" *ngFor="let video of bioVideos; let i = index">
                    <div class="video-field-container brightcove-id-field">
                      <input id="brightcove-id-{{i}}" type="text"
                        class="basic-info__text setting__text-input text-input" name="video-brightcove-id-{{i}}"
                        [(ngModel)]="video && video.BrightcoveID">
                    </div>
                    <div class="video-field-container language-field">
                      <div class="select-container">
                        <select class="setting__select-input select-input" name="video-lang-{{i}}"
                          id="video-language-{{i}}" [(ngModel)]="video && video.LangCode">
                          <option value="">Select language</option>
                          <option value="EN">English</option>
                          <option value="FR">French</option>
                          <option value="ES">Spanish</option>
                        </select>
                      </div>
                    </div>
                    <div class="video-field-container uploaded-field">
                      <input name="high-uploaded-{{i}}" type="checkbox" id="high-uploaded-{{i}}"
                        [(ngModel)]="video.HighResUploaded" value="{{video.HighResUploaded}}" disabled>
                    </div>
                    <div class="video-field-container uploaded-field">
                      <input name="low-uploaded-{{i}}" type="checkbox" id="low-uploaded-{{i}}"
                        [(ngModel)]="video.LowResUploaded" value="{{video.LowResUploaded}}" disabled>
                    </div>
                    <div class="video-field-container btns-field">

                      <!--RETRANSFER TO AWS-->
                      <button (click)="retransferBioVideo(video)" type="button"
                        [style.visibility]="video.LowResUploaded===false && video.HighResUploaded===false?'hidden':'visible'"
                        class="btn--fab-smaller btn--delete f-dark-blue">
                        <svg viewBox="0 0 500 500">
                          <g data-name="1" id="_1">
                            <path fill="#4397CC"
                              d="M398.1,233.2c0-1.2,0.2-2.4,0.2-3.6c0-65-51.8-117.6-115.7-117.6c-46.1,0-85.7,27.4-104.3,67c-8.1-4.1-17.2-6.5-26.8-6.5  c-29.5,0-54.1,21.9-58.8,50.5C57.3,235.2,32,269.1,32,309c0,50.2,40.1,90.9,89.5,91h276.7l0,0c45.2-0.2,81.7-37.5,81.7-83.4  C480,270.6,443.3,233.3,398.1,233.2z" />
                          </g>
                        </svg>
                      </button>
                      <!--SUBMIT-->
                      <button (click)="insertUpdateBioVideo(video)" type="button"
                        class="btn--fab-smaller exisiting-customizations__update-existing exisiting-customizations__btn">
                        <svg viewBox="0 0 24 24">
                          <path fill="#69B8A8"
                            d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />
                        </svg>
                      </button>
                      <!--DELETE-->
                      <button (click)="deleteBioVideo(video)" type="button"
                        class="btn--fab-smaller btn--delete f-dark-blue">
                        <svg viewBox="0 0 24 24">
                          <path fill="#E3604C"
                            d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <!-- add new fields -->
                  <div class="video-item-container" *ngIf="showNewVideoFields">
                    <div class="video-field-container brightcove-id-field">
                      <input id="new-brightcove-id" type="text" class="basic-info__text setting__text-input text-input"
                        name="new-video-brightcove-id" [(ngModel)]="newBioVid.BrightcoveID">
                    </div>
                    <div class="video-field-container language-field">
                      <div class="select-container">
                        <select class="setting__select-input select-input" name="new-video-lang" id="new-video-language"
                          [(ngModel)]="newBioVid.LangCode">
                          <option value="">Select language</option>
                          <option value="EN">English</option>
                          <option value="FR">French</option>
                          <option value="ES">Spanish</option>
                        </select>
                      </div>
                    </div>
                    <div class="video-field-container uploaded-field">
                      <input name="new-high-uploaded" type="checkbox" id="new-high-uploaded"
                        [(ngModel)]="newBioVid.HighResUploaded" value="{{newBioVid.HighResUploaded}}" disabled>
                    </div>
                    <div class="video-field-container uploaded-field">
                      <input name="new-low-uploaded" type="checkbox" id="new-low-uploaded"
                        [(ngModel)]="newBioVid.LowResUploaded" value="{{newBioVid.LowResUploaded}}" disabled>
                    </div>
                    <div class="video-field-container btns-field">
                      <!--SUBMIT-->
                      <button (click)="insertUpdateBioVideo(newBioVid)" type="button"
                        class="btn--fab-smaller exisiting-customizations__update-existing exisiting-customizations__btn">
                        <svg viewBox="0 0 24 24">
                          <path fill="#69B8A8"
                            d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <button (click)="addNewBioVideo()" class="add-new-bio-video">Add new</button>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- <div class="form-button-container">
          <button (click)="openConfirmDialog('expertDetails', 'updateExpert',expertDetails, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)" type="button" class="btn module-submit">
            Submit
          </button>
        </div> -->
      </form>
    </div>

    <!-- TODO: Add additional languages section for titles/bios in non-default languages -->


    <h3 class="ls-subheading f-dark-blue info-container-subheading" [hidden]="Lang !== 'EN'">
      {{_emsConfig.text?.EMS_General.Expert_Profile}}</h3>
    <div class="details-container info-container__text-container expert-profile-style" [hidden]="Lang !== 'EN'">
      <table>
        <tr>
          <td>
            <div class="info-container__section-split_one-third">
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading ">
                  <div class="svg-icon svg-baseline">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7.7" height="11" viewBox="0 0 7.7 11">
                      <defs>
                        <style>
                          .a {
                            fill: #95989a;
                            mix-blend-mode: multiply;
                            isolation: isolate;
                          }
                        </style>
                      </defs>
                      <path class="a"
                        d="M8.85,7.225A1.375,1.375,0,1,1,10.225,5.85,1.375,1.375,0,0,1,8.85,7.225M8.85,2A3.85,3.85,0,0,0,5,5.85C5,8.737,8.85,13,8.85,13S12.7,8.737,12.7,5.85A3.85,3.85,0,0,0,8.85,2Z"
                        transform="translate(-5 -2)" />
                    </svg>
                  </div>
                  {{_emsConfig.text?.EMS_General.Expert_Address}}
                </h4>
                <div class="input-container">
                  <textarea class="basic-info__text setting__text-input textarea-input" rows="4" name="noteUrl"
                    [(ngModel)]="expertDetails && expertDetails.Address"></textarea>
                </div>
              </div>
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading ">
                  <div class="svg-icon svg-baseline">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7.7" height="11" viewBox="0 0 7.7 11">
                      <defs>
                        <style>
                          .a {
                            fill: #95989a;
                            mix-blend-mode: multiply;
                            isolation: isolate;
                          }
                        </style>
                      </defs>
                      <path class="a"
                        d="M8.85,7.225A1.375,1.375,0,1,1,10.225,5.85,1.375,1.375,0,0,1,8.85,7.225M8.85,2A3.85,3.85,0,0,0,5,5.85C5,8.737,8.85,13,8.85,13S12.7,8.737,12.7,5.85A3.85,3.85,0,0,0,8.85,2Z"
                        transform="translate(-5 -2)" />
                    </svg>
                  </div>
                  {{_emsConfig.text?.EMS_Accounts.Expert_Select_Place}}
                </h4>
                <div class="select-container">
                  <select class="setting__select-input select-input" name="province-state" id="province-state"
                    [(ngModel)]="expertDetails && expertDetails.Province">
                    <option [disabled]="true" [selected]="true" value="">
                      {{_emsConfig.text?.EMS_General.EMS_Select_Province_Or_State}}</option>
                    <optgroup label="Province">
                      <option *ngFor="let item of Provinces">{{item}}</option>
                    </optgroup>
                    <optgroup label="State">
                      <option *ngFor="let item of States">{{item}}</option>
                    </optgroup>
                  </select>
                </div>
              </div>

              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading ">
                  <div class="svg-icon svg-baseline">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7.7" height="11" viewBox="0 0 7.7 11">
                      <defs>
                        <style>
                          .a {
                            fill: #95989a;
                            mix-blend-mode: multiply;
                            isolation: isolate;
                          }
                        </style>
                      </defs>
                      <path class="a"
                        d="M8.85,7.225A1.375,1.375,0,1,1,10.225,5.85,1.375,1.375,0,0,1,8.85,7.225M8.85,2A3.85,3.85,0,0,0,5,5.85C5,8.737,8.85,13,8.85,13S12.7,8.737,12.7,5.85A3.85,3.85,0,0,0,8.85,2Z"
                        transform="translate(-5 -2)" />
                    </svg>
                  </div>
                  {{_emsConfig.text?.EMS_General.Expert_Postal_Code}}
                </h4>
                <div class="input-container">
                  <input type="text" class="basic-info__text setting__text-input text-input" name="expertTitle"
                    [(ngModel)]="expertDetails && expertDetails.PostalCode">
                </div>
              </div>

              <div class="basic-info__setting">
                <div class="expert-checkbox-profile" style="padding: 15px 0px 30px 0px;">
                  <input name="expertDoNotContact" [(ngModel)]="expertDetails && expertDetails.DoNotContact"
                    type="checkbox">
                  <h4 class="basic-info__heading setting__heading ls-subheading" style="margin-left: 10px;">
                    {{_emsConfig.text?.EMS_General.Expert_Dont_Contact}} <tooltip
                      [tooltipMessage]="_emsConfig.text?.EMS_General.Do_Not_Contact_Tooltip"></tooltip>
                  </h4>
                </div>
              </div>

              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading ">
                  <div class="svg-icon svg-baseline">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                      <defs>
                        <style>
                          .a {
                            fill: #95989a;
                          }
                        </style>
                      </defs>
                      <path class="a"
                        d="M7.5,2A5.5,5.5,0,1,1,2,7.5,5.516,5.516,0,0,1,7.5,2m0,1.1a4.114,4.114,0,0,0-2.7.935l6.16,6.16A4.7,4.7,0,0,0,11.9,7.5,4.413,4.413,0,0,0,7.5,3.1m2.695,7.865L4.035,4.8A4.114,4.114,0,0,0,3.1,7.5a4.413,4.413,0,0,0,4.4,4.4A4.114,4.114,0,0,0,10.195,10.965Z"
                        transform="translate(-2 -2)" />
                    </svg>
                  </div>
                  {{_emsConfig.text?.EMS_General.Expert_Reason_Not_Contact}}
                </h4>
                <div class="input-container">
                  <input type="text" class="basic-info__text setting__text-input text-input" name="expertTitle"
                    [(ngModel)]="expertDetails && expertDetails.Do_Not_Contact_Reason">
                </div>
              </div>

              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.708" viewBox="0 0 11 8.708">
                      <defs>
                        <style>
                          .a {
                            fill: #95989a;
                          }
                        </style>
                      </defs>
                      <path class="a"
                        d="M5.5,3A7.778,7.778,0,0,0,.133,5.14a.465.465,0,0,0,0,.651L1.27,6.928a.465.465,0,0,0,.646,0,5.234,5.234,0,0,1,1.219-.848.457.457,0,0,0,.257-.412V4.251A6.681,6.681,0,0,1,5.5,3.917a6.827,6.827,0,0,1,2.1.33V5.668a.462.462,0,0,0,.257.413,5.614,5.614,0,0,1,1.224.848.444.444,0,0,0,.312.128.463.463,0,0,0,.335-.133l1.137-1.137A.457.457,0,0,0,11,5.461a.45.45,0,0,0-.137-.321A7.776,7.776,0,0,0,5.5,3M4.125,4.833V6.208S1.375,8.5,1.375,9.875v1.833h8.25V9.875c0-1.375-2.75-3.667-2.75-3.667V4.833H5.958V5.75H5.042V4.833H4.125M5.5,7.125A1.833,1.833,0,1,1,3.667,8.958,1.833,1.833,0,0,1,5.5,7.125m0,.688A1.146,1.146,0,1,0,6.646,8.958,1.146,1.146,0,0,0,5.5,7.813Z"
                        transform="translate(0 -3)" />
                    </svg>
                  </div>
                  {{_emsConfig.text?.EMS_General.Phone_Number}}
                </h4>
                <div class="input-container">
                  <input type="text" class="basic-info__text setting__text-input text-input" name="phoneNumberUrl"
                    [(ngModel)]="expertDetails && expertDetails.PhoneNumber">
                </div>
              </div>

              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.8" viewBox="0 0 11 8.8">
                      <defs>
                        <style>
                          .a {
                            fill: #95989a;
                          }
                        </style>
                      </defs>
                      <path class="a"
                        d="M11.9,6.2,7.5,8.95,3.1,6.2V5.1L7.5,7.85,11.9,5.1m0-1.1H3.1A1.1,1.1,0,0,0,2,5.1v6.6a1.1,1.1,0,0,0,1.1,1.1h8.8A1.1,1.1,0,0,0,13,11.7V5.1A1.1,1.1,0,0,0,11.9,4Z"
                        transform="translate(-2 -4)" />
                    </svg>
                  </div>
                  {{_emsConfig.text?.EMS_General.Expert_Email}}
                </h4>
                <div class="input-container">
                  <input type="email" class="basic-info__text setting__text-input text-input" name="emailUrl"
                    [(ngModel)]="expertDetails && expertDetails.Email">
                </div>
              </div>
            </div>
          </td>

          <td style="display: block;">
            <table>
              <tr>
                <td style="display: block;">
                  <div class="info-container__section-split_one-third">
                    <div class="basic-info__setting">
                      <div class="expert-checkbox-language">
                        <input name="expertDoNotContact" [(ngModel)]="isEnglish" (change)="FieldsChange('EN')"
                          type="checkbox">
                        <h4 class="basic-info__heading setting__heading ls-subheading" style="margin-left: 10px;">
                          English
                        </h4>
                      </div>

                      <div class="expert-checkbox-language">
                        <input name="expertDoNotContact" [(ngModel)]="isFrench" (change)="FieldsChange('FR')"
                          type="checkbox">
                        <h4 class="basic-info__heading setting__heading ls-subheading" style="margin-left: 10px;">
                          French
                        </h4>
                      </div>

                      <div class="expert-checkbox-language">
                        <input name="expertDoNotContact" [(ngModel)]="isSpanish" (change)="FieldsChange('ES')"
                          type="checkbox">
                        <h4 class="basic-info__heading setting__heading ls-subheading" style="margin-left: 10px;">
                          Spanish
                        </h4>
                      </div>

                      <div class="expert-checkbox-language">
                        <input name="expertDoNotContact" [(ngModel)]="isOtherLanguage" (change)="FieldsChange('Other')"
                          type="checkbox">
                        <h4 class="basic-info__heading setting__heading ls-subheading" style="margin-left: 10px;">
                          Other Languages
                        </h4>
                      </div>
                    </div>

                    <!-- begin manage contract files -->
                    <div class="basic-info__setting">
                      <h4 class="basic-info__heading setting__heading ls-subheading">
                        <span class="svg-icon svg-baseline">
                          <svg xmlns="http://www.w3.org/2000/svg" width="9.427" height="11" viewBox="0 0 9.427 11">
                            <defs>
                              <style>
                                .a {
                                  fill: #95989a;
                                }
                              </style>
                            </defs>
                            <path class="a"
                              d="M5.048,2A1.044,1.044,0,0,0,4,3.048v8.381a1.048,1.048,0,0,0,1.048,1.048h2.1v-1l1.095-1.095H5.048V9.333H9.285l1.048-1.048H5.048V7.238H11.38l1-1V5.143L9.238,2H5.048m3.667.786L11.6,5.667H8.714V2.786M12.46,7.762a.288.288,0,0,0-.21.084l-.534.534L12.81,9.47l.534-.529a.3.3,0,0,0,0-.414l-.681-.681a.285.285,0,0,0-.2-.084m-1.053.927L8.19,11.91V13H9.28L12.5,9.779Z"
                              transform="translate(-4 -2)" />
                          </svg>
                        </span> {{_emsConfig.text?.EMS_Accounts.Contract_Files}}
                      </h4>
                      <div class="display-edit-field ">
                        <a class="expert-link" href="javascript:void(0)" (click)="getContractFiles()">manage contract
                          files</a>
                      </div>
                    </div>
                    <!--end manage contract files-->

                    <div class="basic-info__setting">

                      <div class="expert-checkbox-profile">
                        <input name="expertDoNotContact" [(ngModel)]="expertDetails && expertDetails.ContractSigned"
                          type="checkbox">
                        <h4 class="basic-info__heading setting__heading ls-subheading" style="margin-left: 10px;">
                          Contract has been signed?
                        </h4>
                      </div>

                      <div class="expert-checkbox-profile">
                        <input name="expertDoNotContact" [(ngModel)]="expertDetails && expertDetails.Does_LiveEvent"
                          type="checkbox">
                        <h4 class="basic-info__heading setting__heading ls-subheading" style="margin-left: 10px;">
                          Live Events?
                        </h4>
                      </div>

                      <div class="expert-checkbox-profile">
                        <input name="expertDoNotContact"
                          [(ngModel)]="expertDetails && expertDetails.VerificationEmailSent" type="checkbox">
                        <h4 class="basic-info__heading setting__heading ls-subheading" style="margin-left: 10px;">
                          Verification email sent last 12 months?
                        </h4>
                      </div>
                    </div>
                  </div>
                </td>
                <td style="width: 60%;">
                  <div class="info-container__section-expertise">
                    <div class="basic-info__setting">
                      <div class='expert-speaciality-text'>
                        <h4 class="basic-info__heading setting__heading ls-subheading ">
                          <div class="svg-icon svg-baseline">
                            <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                              <path
                                d="M14,10H19.5L14,4.5V10M5,3H15L21,9V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3M5,12V14H19V12H5M5,16V18H14V16H5Z" />
                            </svg>
                          </div>
                          Area of expertise
                        </h4>
                        <a class="expert-link" [routerLink]="['/cms/specialities']">Manage specialities</a>
                        <a class="expert-link" href="javascript:void(0)" (click)="toggleSpecialitySection()">
                          {{addEditDeleteText}}</a>
                      </div>
                    </div>
                    <div class="basic-info__setting" *ngIf="showSpecialitySection">
                      <div class='expert-speaciality-text'>
                        <div class="select-container">
                          <select class="setting__select-input select-input" name="volumeCategory"
                            (change)="filterSpecialityByCategory($event.target.value)">
                            <option [disabled]="true" [selected]="true" value="">Category</option>
                            <option value="{{category.ID}}" *ngFor="let category of categoryList">
                              {{category.Name.English}}</option>
                          </select>
                        </div>
                        <div class="select-container">
                          <select class="setting__select-input select-input" name="speciality"
                            [(ngModel)]="specialities">
                            <option [disabled]="true" [selected]="true" value="">Speciality</option>
                            <option [ngValue]="item" *ngFor="let item of specialityByCategory">{{item.Speciality}}
                            </option>
                          </select>
                        </div>
                        <div class="extra-padding">
                          <button class="btn b-dark-blue ls-button-2" (click)="addSpeciality()">Add</button>
                        </div>
                      </div>
                    </div>
                    <div class="basic-info__setting">
                      <div class='expert-speaciality-text' *ngFor="let value of individualSpecialityList;let i=index;">
                        <div class="display-edit-field display-expert-speciality" *ngIf="!showSpecialitySection">
                          <input type="text" class="text-input expert-text-input" [(ngModel)]="value.CategoryName"
                            readonly />
                        </div>
                        <div class="display-edit-field display-expert-speciality" *ngIf="!showSpecialitySection">
                          <input type="text" class="text-input  expert-text-input" [(ngModel)]="value.Speciality"
                            readonly />
                        </div>
                        <div class="select-container select-cotainer-delete-btn" *ngIf="showSpecialitySection">
                          <div class="speciality-delete-button" (click)=deteteExpertSpeciality(value.SpecialityID)
                            *ngIf="showSpecialitySection">
                            <svg xmlns="http://www.w3.org/2000/svg" width="9.725" height="10" viewBox="0 0 9.725 10">
                              <defs>
                                <style>
                                  .d {
                                    fill: #ee6a53;
                                  }
                                </style>
                              </defs>
                              <path class="d"
                                d="M7.039,3v.556H4V4.667h.608v7.222A1.167,1.167,0,0,0,5.823,13H11.9a1.167,1.167,0,0,0,1.216-1.111V4.667h.608V3.556H10.686V3H7.039m0,2.778H8.255v5H7.039v-5m2.431,0h1.216v5H9.47Z"
                                transform="translate(-4 -3)" />
                            </svg>
                          </div>
                          <select class="setting__select-input select-input" name="Categories-{{i}}" id=""
                            [(ngModel)]="value.CategoryName">
                            <option [disabled]="true" [selected]="true" value="">Category</option>
                            <option value="{{category.Name.English}}" *ngFor="let category of categoryList">
                              {{category.Name.English}}</option>
                          </select>
                        </div>
                        <div class="select-container" *ngIf="showSpecialitySection">
                          <select class="setting__select-input select-input" name="specialities-{{i}}" id=""
                            [(ngModel)]="value.Speciality">
                            <option [disabled]="true" [selected]="true" value="">Speciality</option>
                            <option value="{{item.Speciality}}"
                              *ngFor="let item of specialityList|filterSpecialityByCategory:value.CategoryName">
                              {{item.Speciality}}</option>
                          </select>
                        </div>
                        <div class="extra-padding" *ngIf="showSpecialitySection">
                          <button class="btn b-dark-blue ls-button-2" (click)="updateSpeciality(value)">Update</button>
                        </div>
                      </div>
                    </div>

                    <div class="basic-info__setting">
                      <h4 class="account-info__heading setting__heading ls-subheading">
                        <span class="svg-icon svg-baseline">
                          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                            <defs>
                              <style>
                                .a {
                                  fill: #95989a;
                                }
                              </style>
                            </defs>
                            <path class="a"
                              d="M2,8H1V2A1,1,0,0,1,2,1h8V2H2V8m4.5,4a.5.5,0,0,1-.5-.5V10H4A1,1,0,0,1,3,9V4A1,1,0,0,1,4,3h7a1,1,0,0,1,1,1V9a1,1,0,0,1-1,1H8.95L7.1,11.855A.523.523,0,0,1,6.75,12H6.5M5,5V6h5V5H5M5,7V8H9V7Z"
                              transform="translate(-1 -1)" />
                          </svg>
                        </span> Areas of expertise notes
                      </h4>
                      <textarea class="setting__textarea" name="billing-address-text" id="billing-address"
                        [(ngModel)]="expertDetails && expertDetails.ExpertiseNotes"></textarea>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="basic-info__setting">
                    <div class='expert-speaciality-text'>
                      <h4 class="basic-info__heading setting__heading ls-subheading ">
                        <div class="svg-icon svg-baseline">
                          <svg xmlns="http://www.w3.org/2000/svg" width="9.9" height="11" viewBox="0 0 9.9 11">
                            <defs>
                              <style>
                                .a {
                                  fill: #95989a;
                                }
                              </style>
                            </defs>
                            <path class="a"
                              d="M11.25,10.9H4.65v-.77c0-1.1,2.2-1.7,3.3-1.7s3.3.6,3.3,1.7M7.95,4.3A1.65,1.65,0,1,1,6.3,5.95,1.65,1.65,0,0,1,7.95,4.3m0-2.2a.55.55,0,1,1-.55.55.55.55,0,0,1,.55-.55m3.85,0H9.5a1.643,1.643,0,0,0-3.1,0H4.1A1.1,1.1,0,0,0,3,3.2v7.7A1.1,1.1,0,0,0,4.1,12h7.7a1.1,1.1,0,0,0,1.1-1.1V3.2A1.1,1.1,0,0,0,11.8,2.1Z"
                              transform="translate(-3 -1)" />
                          </svg>
                        </div>
                        Assignments
                      </h4>
                      <a class="expert-link" href="javascript:void(0)" (click)="toggleAssignmentSection()">
                        {{addEditDeleteAssignmentText}}</a>
                    </div>
                  </div>
                  <div class="basic-info__setting" *ngIf="showAssignmentSection">
                    <div class='expert-speaciality-text'>
                      <div class="input-container" style="margin-top: 5px;">
                        <input type="text" class="basic-info__text setting__text-input text-input"
                          name="assignmentTopic" placeholder="Topic" [(ngModel)]="assignments.Assignments">
                      </div>
                      <div class="select-container" style="padding-left: 30px;">
                        <select class="setting__select-input select-input" name="media"
                          [(ngModel)]="assignments.AssignmentType">
                          <option [disabled]="true" [selected]="true" value="">Media</option>
                          <option [ngValue]="item" *ngFor="let item of mediaDropdownList">{{item}}</option>
                        </select>
                      </div>
                      <div class="input-container auto-margin">
                        <input placeholder="Event Date" type="date" name="launch-date"
                          [ngModel]="assignments.AssignmentDate | date:'yyyy-MM-dd'"
                          (ngModelChange)="assignments.AssignmentDate = $event" />
                      </div>
                      <div class="extra-padding">
                        <button class="btn b-dark-blue ls-button-2" (click)="addExpertAssignment()">Add</button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="basic-info__setting">
                    <div class='expert-speaciality-text' *ngFor="let value of assignmentList;let i=index;">
                      <div class="input-container" style="margin-top: 5px;" *ngIf="!showAssignmentSection">
                        <input type="text" class="basic-info__text setting__text-input text-input" placeholder="Topic"
                          [(ngModel)]="value.Assignments" readonly>
                      </div>
                      <div class="input-container" *ngIf="!showAssignmentSection">
                        <input type="text" class="text-input" expert-text-input [(ngModel)]="value.AssignmentType"
                          readonly />
                      </div>
                      <div class="input-container auto-margin" *ngIf="!showAssignmentSection">
                        <input placeholder="Event Date" type="date" name="launch-date"
                          [ngModel]="value.AssignmentDate | date:'yyyy-MM-dd'"
                          (ngModelChange)="value.AssignmentDate = $event" />
                      </div>


                      <div class="input-container" style="margin-top: 5px; display: flex" *ngIf="showAssignmentSection">
                        <div class="speciality-delete-button" (click)=deteteExpertAssignment(value)>
                          <svg xmlns="http://www.w3.org/2000/svg" width="9.725" height="10" viewBox="0 0 9.725 10">
                            <defs>
                              <style>
                                .d {
                                  fill: #ee6a53;
                                }
                              </style>
                            </defs>
                            <path class="d"
                              d="M7.039,3v.556H4V4.667h.608v7.222A1.167,1.167,0,0,0,5.823,13H11.9a1.167,1.167,0,0,0,1.216-1.111V4.667h.608V3.556H10.686V3H7.039m0,2.778H8.255v5H7.039v-5m2.431,0h1.216v5H9.47Z"
                              transform="translate(-4 -3)" />
                          </svg>
                        </div>
                        <input type="text" class="basic-info__text setting__text-input text-input"
                          name="assignmentTopic{{i}}" placeholder="Topic" [(ngModel)]="value.Assignments">
                      </div>
                      <div class="select-container" style="padding-left: 30px;" *ngIf="showAssignmentSection">
                        <select class="setting__select-input select-input" name="media{{i}}"
                          [(ngModel)]="value.AssignmentType">
                          <option [disabled]="true" [selected]="true" value="">Media</option>
                          <option [ngValue]="item" *ngFor="let item of mediaDropdownList">{{item}}</option>
                        </select>
                      </div>
                      <div class="input-container auto-margin" *ngIf="showAssignmentSection">
                        <input placeholder="Event Date" type="date" name="launch-date"
                          [ngModel]="value.AssignmentDate | date:'yyyy-MM-dd'"
                          (ngModelChange)="value.AssignmentDate = $event" />
                      </div>
                      <div class="extra-padding" *ngIf="showAssignmentSection">
                        <button class="btn b-dark-blue ls-button-2" (click)="updateAssignment(value)">Update</button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="basic-info__setting">
                    <h4 class="account-info__heading setting__heading ls-subheading">
                      <span class="svg-icon svg-baseline">
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                          <defs>
                            <style>
                              .a {
                                fill: #95989a;
                              }
                            </style>
                          </defs>
                          <path class="a"
                            d="M2,8H1V2A1,1,0,0,1,2,1h8V2H2V8m4.5,4a.5.5,0,0,1-.5-.5V10H4A1,1,0,0,1,3,9V4A1,1,0,0,1,4,3h7a1,1,0,0,1,1,1V9a1,1,0,0,1-1,1H8.95L7.1,11.855A.523.523,0,0,1,6.75,12H6.5M5,5V6h5V5H5M5,7V8H9V7Z"
                            transform="translate(-1 -1)" />
                        </svg>
                      </span> Volumes
                    </h4>
                    <div *ngFor="let value of volumeList;" style="border-bottom-style: solid;">
                      <label class="volume-text">{{value.Key}} -<i>{{getVolumeStatus(value.Value)}}</i></label>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div class="basic-info__setting" style="width: 50%;">
              <h4 class="account-info__heading setting__heading ls-subheading">
                <span class="svg-icon svg-baseline">
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                    <defs>
                      <style>
                        .a {
                          fill: #95989a;
                        }
                      </style>
                    </defs>
                    <path class="a"
                      d="M2,8H1V2A1,1,0,0,1,2,1h8V2H2V8m4.5,4a.5.5,0,0,1-.5-.5V10H4A1,1,0,0,1,3,9V4A1,1,0,0,1,4,3h7a1,1,0,0,1,1,1V9a1,1,0,0,1-1,1H8.95L7.1,11.855A.523.523,0,0,1,6.75,12H6.5M5,5V6h5V5H5M5,7V8H9V7Z"
                      transform="translate(-1 -1)" />
                  </svg>
                </span> {{_emsConfig.text?.EMS_Accounts.General_Comments}}
              </h4>
              <textarea class="setting__textarea" name="billing-address-text" id="billing-address"
                [(ngModel)]="expertDetails && expertDetails.GeneralComments"></textarea>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!-- expert fees section -->
    <h3 class="ls-subheading f-dark-blue info-container-subheading extra-margin-bottom" [hidden]="Lang !== 'EN'">
      {{_emsConfig.text?.EMS_General.Expert_Fees}}</h3>
    <div class="details-container info-container__text-container" [hidden]="Lang !== 'EN'" style="width: 60%;">
      <div class="expert-profile-style">
        <div class="info-container__section-split-expertFee">
          <div class="basic-info__setting info-fee-container">
            <h4 class="account-info__heading setting__heading ls-subheading">
              <span class="svg-icon svg-baseline">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                  <defs>
                    <style>
                      .a {
                        fill: #95989a;
                      }

                      .b {
                        fill: #fff;
                      }
                    </style>
                  </defs>
                  <g transform="translate(-79 -681)">
                    <circle class="a" cx="5.5" cy="5.5" r="5.5" transform="translate(79 681)" />
                    <path class="b"
                      d="M2.592-.96a.681.681,0,0,0,.66-.724.472.472,0,0,0-.146-.366,1.2,1.2,0,0,0-.386-.226Zm-.372-3.9a1.221,1.221,0,0,0-.31.084.691.691,0,0,0-.2.132.472.472,0,0,0-.114.17.549.549,0,0,0-.036.2.506.506,0,0,0,.152.382,1.256,1.256,0,0,0,.392.246ZM2.832-3.4q.192.056.384.122a3.361,3.361,0,0,1,.37.152,2.162,2.162,0,0,1,.332.2,1.258,1.258,0,0,1,.268.264,1.191,1.191,0,0,1,.178.348,1.456,1.456,0,0,1,.064.452,1.978,1.978,0,0,1-.128.714,1.749,1.749,0,0,1-.376.592,1.931,1.931,0,0,1-.608.42,2.46,2.46,0,0,1-.824.2l-.048.5a.312.312,0,0,1-.1.2.311.311,0,0,1-.23.09H1.66L1.74.02A3.485,3.485,0,0,1,.862-.246,2.613,2.613,0,0,1,.156-.7L.54-1.26a.388.388,0,0,1,.13-.114A.35.35,0,0,1,.844-1.42a.436.436,0,0,1,.2.05q.1.05.218.12t.26.144a1.572,1.572,0,0,0,.322.122l.148-1.508q-.288-.08-.574-.188A1.856,1.856,0,0,1,.9-2.972,1.39,1.39,0,0,1,.53-3.44a1.586,1.586,0,0,1-.142-.712,1.555,1.555,0,0,1,.128-.616,1.661,1.661,0,0,1,.374-.54A1.961,1.961,0,0,1,1.5-5.7,2.4,2.4,0,0,1,2.32-5.88l.044-.444a.312.312,0,0,1,.1-.2.311.311,0,0,1,.23-.09h.456l-.076.784a2.3,2.3,0,0,1,.742.26,2.838,2.838,0,0,1,.526.384l-.3.444A.524.524,0,0,1,3.9-4.6a.3.3,0,0,1-.166.046.424.424,0,0,1-.142-.03q-.082-.03-.178-.072l-.206-.088a2.186,2.186,0,0,0-.234-.082Z"
                      transform="translate(82 689.5)" />
                  </g>
                </svg>
              </span> Rate per Volume:
            </h4>
            <div class="display-edit-field dollar">
              <input type="text" class="text-input" [(ngModel)]="expertDetails && expertDetails.RatePerVolumeFormatted"
                (blur)="$event.target.value=removeNonDigitCharacters($event.target.value)" />
            </div>
          </div>

          <div class="basic-info__setting info-fee-container">
            <h4 class="account-info__heading setting__heading ls-subheading">
              <span class="svg-icon svg-baseline">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                  <defs>
                    <style>
                      .a {
                        fill: #95989a;
                      }

                      .b {
                        fill: #fff;
                      }
                    </style>
                  </defs>
                  <g transform="translate(-79 -681)">
                    <circle class="a" cx="5.5" cy="5.5" r="5.5" transform="translate(79 681)" />
                    <path class="b"
                      d="M2.592-.96a.681.681,0,0,0,.66-.724.472.472,0,0,0-.146-.366,1.2,1.2,0,0,0-.386-.226Zm-.372-3.9a1.221,1.221,0,0,0-.31.084.691.691,0,0,0-.2.132.472.472,0,0,0-.114.17.549.549,0,0,0-.036.2.506.506,0,0,0,.152.382,1.256,1.256,0,0,0,.392.246ZM2.832-3.4q.192.056.384.122a3.361,3.361,0,0,1,.37.152,2.162,2.162,0,0,1,.332.2,1.258,1.258,0,0,1,.268.264,1.191,1.191,0,0,1,.178.348,1.456,1.456,0,0,1,.064.452,1.978,1.978,0,0,1-.128.714,1.749,1.749,0,0,1-.376.592,1.931,1.931,0,0,1-.608.42,2.46,2.46,0,0,1-.824.2l-.048.5a.312.312,0,0,1-.1.2.311.311,0,0,1-.23.09H1.66L1.74.02A3.485,3.485,0,0,1,.862-.246,2.613,2.613,0,0,1,.156-.7L.54-1.26a.388.388,0,0,1,.13-.114A.35.35,0,0,1,.844-1.42a.436.436,0,0,1,.2.05q.1.05.218.12t.26.144a1.572,1.572,0,0,0,.322.122l.148-1.508q-.288-.08-.574-.188A1.856,1.856,0,0,1,.9-2.972,1.39,1.39,0,0,1,.53-3.44a1.586,1.586,0,0,1-.142-.712,1.555,1.555,0,0,1,.128-.616,1.661,1.661,0,0,1,.374-.54A1.961,1.961,0,0,1,1.5-5.7,2.4,2.4,0,0,1,2.32-5.88l.044-.444a.312.312,0,0,1,.1-.2.311.311,0,0,1,.23-.09h.456l-.076.784a2.3,2.3,0,0,1,.742.26,2.838,2.838,0,0,1,.526.384l-.3.444A.524.524,0,0,1,3.9-4.6a.3.3,0,0,1-.166.046.424.424,0,0,1-.142-.03q-.082-.03-.178-.072l-.206-.088a2.186,2.186,0,0,0-.234-.082Z"
                      transform="translate(82 689.5)" />
                  </g>
                </svg>
              </span> Rate per blog post:
            </h4>
            <div class="display-edit-field dollar">
              <input type="text" class="text-input"
                [(ngModel)]="expertDetails && expertDetails.RatePerBlogPostFormatted"
                (blur)="$event.target.value=removeNonDigitCharacters($event.target.value)" />
            </div>
          </div>

          <div class="basic-info__setting info-fee-container">
            <h4 class="account-info__heading setting__heading ls-subheading">
              <span class="svg-icon svg-baseline">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                  <defs>
                    <style>
                      .a {
                        fill: #95989a;
                      }

                      .b {
                        fill: #fff;
                      }
                    </style>
                  </defs>
                  <g transform="translate(-79 -681)">
                    <circle class="a" cx="5.5" cy="5.5" r="5.5" transform="translate(79 681)" />
                    <path class="b"
                      d="M2.592-.96a.681.681,0,0,0,.66-.724.472.472,0,0,0-.146-.366,1.2,1.2,0,0,0-.386-.226Zm-.372-3.9a1.221,1.221,0,0,0-.31.084.691.691,0,0,0-.2.132.472.472,0,0,0-.114.17.549.549,0,0,0-.036.2.506.506,0,0,0,.152.382,1.256,1.256,0,0,0,.392.246ZM2.832-3.4q.192.056.384.122a3.361,3.361,0,0,1,.37.152,2.162,2.162,0,0,1,.332.2,1.258,1.258,0,0,1,.268.264,1.191,1.191,0,0,1,.178.348,1.456,1.456,0,0,1,.064.452,1.978,1.978,0,0,1-.128.714,1.749,1.749,0,0,1-.376.592,1.931,1.931,0,0,1-.608.42,2.46,2.46,0,0,1-.824.2l-.048.5a.312.312,0,0,1-.1.2.311.311,0,0,1-.23.09H1.66L1.74.02A3.485,3.485,0,0,1,.862-.246,2.613,2.613,0,0,1,.156-.7L.54-1.26a.388.388,0,0,1,.13-.114A.35.35,0,0,1,.844-1.42a.436.436,0,0,1,.2.05q.1.05.218.12t.26.144a1.572,1.572,0,0,0,.322.122l.148-1.508q-.288-.08-.574-.188A1.856,1.856,0,0,1,.9-2.972,1.39,1.39,0,0,1,.53-3.44a1.586,1.586,0,0,1-.142-.712,1.555,1.555,0,0,1,.128-.616,1.661,1.661,0,0,1,.374-.54A1.961,1.961,0,0,1,1.5-5.7,2.4,2.4,0,0,1,2.32-5.88l.044-.444a.312.312,0,0,1,.1-.2.311.311,0,0,1,.23-.09h.456l-.076.784a2.3,2.3,0,0,1,.742.26,2.838,2.838,0,0,1,.526.384l-.3.444A.524.524,0,0,1,3.9-4.6a.3.3,0,0,1-.166.046.424.424,0,0,1-.142-.03q-.082-.03-.178-.072l-.206-.088a2.186,2.186,0,0,0-.234-.082Z"
                      transform="translate(82 689.5)" />
                  </g>
                </svg>
              </span> Rate per ATE:
            </h4>
            <div class="display-edit-field dollar">
              <input type="text" class="text-input" [(ngModel)]="expertDetails && expertDetails.RatePerATEFormatted"
                (blur)="$event.target.value=removeNonDigitCharacters($event.target.value)" />
            </div>
          </div>
          <div class="basic-info__setting info-fee-container">
            <h4 class="account-info__heading setting__heading ls-subheading">
              <span class="svg-icon svg-baseline">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                  <defs>
                    <style>
                      .a {
                        fill: #95989a;
                      }

                      .b {
                        fill: #fff;
                      }
                    </style>
                  </defs>
                  <g transform="translate(-79 -681)">
                    <circle class="a" cx="5.5" cy="5.5" r="5.5" transform="translate(79 681)" />
                    <path class="b"
                      d="M2.592-.96a.681.681,0,0,0,.66-.724.472.472,0,0,0-.146-.366,1.2,1.2,0,0,0-.386-.226Zm-.372-3.9a1.221,1.221,0,0,0-.31.084.691.691,0,0,0-.2.132.472.472,0,0,0-.114.17.549.549,0,0,0-.036.2.506.506,0,0,0,.152.382,1.256,1.256,0,0,0,.392.246ZM2.832-3.4q.192.056.384.122a3.361,3.361,0,0,1,.37.152,2.162,2.162,0,0,1,.332.2,1.258,1.258,0,0,1,.268.264,1.191,1.191,0,0,1,.178.348,1.456,1.456,0,0,1,.064.452,1.978,1.978,0,0,1-.128.714,1.749,1.749,0,0,1-.376.592,1.931,1.931,0,0,1-.608.42,2.46,2.46,0,0,1-.824.2l-.048.5a.312.312,0,0,1-.1.2.311.311,0,0,1-.23.09H1.66L1.74.02A3.485,3.485,0,0,1,.862-.246,2.613,2.613,0,0,1,.156-.7L.54-1.26a.388.388,0,0,1,.13-.114A.35.35,0,0,1,.844-1.42a.436.436,0,0,1,.2.05q.1.05.218.12t.26.144a1.572,1.572,0,0,0,.322.122l.148-1.508q-.288-.08-.574-.188A1.856,1.856,0,0,1,.9-2.972,1.39,1.39,0,0,1,.53-3.44a1.586,1.586,0,0,1-.142-.712,1.555,1.555,0,0,1,.128-.616,1.661,1.661,0,0,1,.374-.54A1.961,1.961,0,0,1,1.5-5.7,2.4,2.4,0,0,1,2.32-5.88l.044-.444a.312.312,0,0,1,.1-.2.311.311,0,0,1,.23-.09h.456l-.076.784a2.3,2.3,0,0,1,.742.26,2.838,2.838,0,0,1,.526.384l-.3.444A.524.524,0,0,1,3.9-4.6a.3.3,0,0,1-.166.046.424.424,0,0,1-.142-.03q-.082-.03-.178-.072l-.206-.088a2.186,2.186,0,0,0-.234-.082Z"
                      transform="translate(82 689.5)" />
                  </g>
                </svg>
              </span> Rate per podcast:
            </h4>
            <div class="display-edit-field dollar">
              <input type="text" class="text-input" [(ngModel)]="expertDetails && expertDetails.RatePerPodcastFormatted"
                (blur)="$event.target.value=removeNonDigitCharacters($event.target.value)" />
            </div>
          </div>

          <div class="basic-info__setting info-fee-container">
            <h4 class="account-info__heading setting__heading ls-subheading">
              <span class="svg-icon svg-baseline">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                  <defs>
                    <style>
                      .a {
                        fill: #95989a;
                      }

                      .b {
                        fill: #fff;
                      }
                    </style>
                  </defs>
                  <g transform="translate(-79 -681)">
                    <circle class="a" cx="5.5" cy="5.5" r="5.5" transform="translate(79 681)" />
                    <path class="b"
                      d="M2.592-.96a.681.681,0,0,0,.66-.724.472.472,0,0,0-.146-.366,1.2,1.2,0,0,0-.386-.226Zm-.372-3.9a1.221,1.221,0,0,0-.31.084.691.691,0,0,0-.2.132.472.472,0,0,0-.114.17.549.549,0,0,0-.036.2.506.506,0,0,0,.152.382,1.256,1.256,0,0,0,.392.246ZM2.832-3.4q.192.056.384.122a3.361,3.361,0,0,1,.37.152,2.162,2.162,0,0,1,.332.2,1.258,1.258,0,0,1,.268.264,1.191,1.191,0,0,1,.178.348,1.456,1.456,0,0,1,.064.452,1.978,1.978,0,0,1-.128.714,1.749,1.749,0,0,1-.376.592,1.931,1.931,0,0,1-.608.42,2.46,2.46,0,0,1-.824.2l-.048.5a.312.312,0,0,1-.1.2.311.311,0,0,1-.23.09H1.66L1.74.02A3.485,3.485,0,0,1,.862-.246,2.613,2.613,0,0,1,.156-.7L.54-1.26a.388.388,0,0,1,.13-.114A.35.35,0,0,1,.844-1.42a.436.436,0,0,1,.2.05q.1.05.218.12t.26.144a1.572,1.572,0,0,0,.322.122l.148-1.508q-.288-.08-.574-.188A1.856,1.856,0,0,1,.9-2.972,1.39,1.39,0,0,1,.53-3.44a1.586,1.586,0,0,1-.142-.712,1.555,1.555,0,0,1,.128-.616,1.661,1.661,0,0,1,.374-.54A1.961,1.961,0,0,1,1.5-5.7,2.4,2.4,0,0,1,2.32-5.88l.044-.444a.312.312,0,0,1,.1-.2.311.311,0,0,1,.23-.09h.456l-.076.784a2.3,2.3,0,0,1,.742.26,2.838,2.838,0,0,1,.526.384l-.3.444A.524.524,0,0,1,3.9-4.6a.3.3,0,0,1-.166.046.424.424,0,0,1-.142-.03q-.082-.03-.178-.072l-.206-.088a2.186,2.186,0,0,0-.234-.082Z"
                      transform="translate(82 689.5)" />
                  </g>
                </svg>
              </span> Rate per live event:
            </h4>
            <div class="display-edit-field dollar">
              <input type="text" class="text-input"
                [(ngModel)]="expertDetails && expertDetails.RatePerLiveEventFormatted"
                (blur)="$event.target.value=removeNonDigitCharacters($event.target.value)" />
            </div>
          </div>
        </div>
        <div class="info-container__section-split-expertFee">
          <div class="basic-info__setting">
            <h4 class="account-info__heading setting__heading ls-subheading">
              <span class="svg-icon svg-baseline">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                  <defs>
                    <style>
                      .a {
                        fill: #95989a;
                      }

                      .b {
                        fill: #fff;
                      }
                    </style>
                  </defs>
                  <g transform="translate(-79 -681)">
                    <circle class="a" cx="5.5" cy="5.5" r="5.5" transform="translate(79 681)" />
                    <path class="b"
                      d="M2.592-.96a.681.681,0,0,0,.66-.724.472.472,0,0,0-.146-.366,1.2,1.2,0,0,0-.386-.226Zm-.372-3.9a1.221,1.221,0,0,0-.31.084.691.691,0,0,0-.2.132.472.472,0,0,0-.114.17.549.549,0,0,0-.036.2.506.506,0,0,0,.152.382,1.256,1.256,0,0,0,.392.246ZM2.832-3.4q.192.056.384.122a3.361,3.361,0,0,1,.37.152,2.162,2.162,0,0,1,.332.2,1.258,1.258,0,0,1,.268.264,1.191,1.191,0,0,1,.178.348,1.456,1.456,0,0,1,.064.452,1.978,1.978,0,0,1-.128.714,1.749,1.749,0,0,1-.376.592,1.931,1.931,0,0,1-.608.42,2.46,2.46,0,0,1-.824.2l-.048.5a.312.312,0,0,1-.1.2.311.311,0,0,1-.23.09H1.66L1.74.02A3.485,3.485,0,0,1,.862-.246,2.613,2.613,0,0,1,.156-.7L.54-1.26a.388.388,0,0,1,.13-.114A.35.35,0,0,1,.844-1.42a.436.436,0,0,1,.2.05q.1.05.218.12t.26.144a1.572,1.572,0,0,0,.322.122l.148-1.508q-.288-.08-.574-.188A1.856,1.856,0,0,1,.9-2.972,1.39,1.39,0,0,1,.53-3.44a1.586,1.586,0,0,1-.142-.712,1.555,1.555,0,0,1,.128-.616,1.661,1.661,0,0,1,.374-.54A1.961,1.961,0,0,1,1.5-5.7,2.4,2.4,0,0,1,2.32-5.88l.044-.444a.312.312,0,0,1,.1-.2.311.311,0,0,1,.23-.09h.456l-.076.784a2.3,2.3,0,0,1,.742.26,2.838,2.838,0,0,1,.526.384l-.3.444A.524.524,0,0,1,3.9-4.6a.3.3,0,0,1-.166.046.424.424,0,0,1-.142-.03q-.082-.03-.178-.072l-.206-.088a2.186,2.186,0,0,0-.234-.082Z"
                      transform="translate(82 689.5)" />
                  </g>
                </svg>
              </span> {{_emsConfig.text?.EMS_Accounts.Currency}}
            </h4>
            <div class="select-container">
              <select class="setting__select-input select-input" [(ngModel)]="expertDetails && expertDetails.Currency">
                <option value="CAD">CAD</option>
                <option value="USD">USD</option>
              </select>
            </div>
          </div>

          <div class="basic-info__setting">
            <h4 class="account-info__heading setting__heading ls-subheading">
              <span class="svg-icon svg-baseline">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                  <defs>
                    <style>
                      .a {
                        fill: #95989a;
                      }

                      .b {
                        fill: #fff;
                      }
                    </style>
                  </defs>
                  <g transform="translate(-79 -681)">
                    <circle class="a" cx="5.5" cy="5.5" r="5.5" transform="translate(79 681)" />
                    <path class="b"
                      d="M2.592-.96a.681.681,0,0,0,.66-.724.472.472,0,0,0-.146-.366,1.2,1.2,0,0,0-.386-.226Zm-.372-3.9a1.221,1.221,0,0,0-.31.084.691.691,0,0,0-.2.132.472.472,0,0,0-.114.17.549.549,0,0,0-.036.2.506.506,0,0,0,.152.382,1.256,1.256,0,0,0,.392.246ZM2.832-3.4q.192.056.384.122a3.361,3.361,0,0,1,.37.152,2.162,2.162,0,0,1,.332.2,1.258,1.258,0,0,1,.268.264,1.191,1.191,0,0,1,.178.348,1.456,1.456,0,0,1,.064.452,1.978,1.978,0,0,1-.128.714,1.749,1.749,0,0,1-.376.592,1.931,1.931,0,0,1-.608.42,2.46,2.46,0,0,1-.824.2l-.048.5a.312.312,0,0,1-.1.2.311.311,0,0,1-.23.09H1.66L1.74.02A3.485,3.485,0,0,1,.862-.246,2.613,2.613,0,0,1,.156-.7L.54-1.26a.388.388,0,0,1,.13-.114A.35.35,0,0,1,.844-1.42a.436.436,0,0,1,.2.05q.1.05.218.12t.26.144a1.572,1.572,0,0,0,.322.122l.148-1.508q-.288-.08-.574-.188A1.856,1.856,0,0,1,.9-2.972,1.39,1.39,0,0,1,.53-3.44a1.586,1.586,0,0,1-.142-.712,1.555,1.555,0,0,1,.128-.616,1.661,1.661,0,0,1,.374-.54A1.961,1.961,0,0,1,1.5-5.7,2.4,2.4,0,0,1,2.32-5.88l.044-.444a.312.312,0,0,1,.1-.2.311.311,0,0,1,.23-.09h.456l-.076.784a2.3,2.3,0,0,1,.742.26,2.838,2.838,0,0,1,.526.384l-.3.444A.524.524,0,0,1,3.9-4.6a.3.3,0,0,1-.166.046.424.424,0,0,1-.142-.03q-.082-.03-.178-.072l-.206-.088a2.186,2.186,0,0,0-.234-.082Z"
                      transform="translate(82 689.5)" />
                  </g>
                </svg>
              </span> payment terms
            </h4>
            <div class="display-edit-field">
              <input type="text" class="text-input"
                [(ngModel)]="expertDetails && expertDetails.PaymentTermsNetDaysFormatted" />
            </div>
          </div>

          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />
                </svg>
              </div>
              {{_emsConfig.text?.EMS_General.Expert_Royalty}} <tooltip
                [tooltipMessage]="_emsConfig.text?.EMS_General.Expert_Royalty_Tooltip"></tooltip>
            </h4>
            <div class="basic-info__checkbox-container">
              <div class="checkbox-container">
                <div class="expert-checkbox">
                  <input name="expertRoyalty" [(ngModel)]="expertDetails && expertDetails.Royalty" type="checkbox">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-full">
        <div class="basic-info__setting">
          <h4 class="account-info__heading setting__heading ls-subheading">
            <span class="svg-icon svg-baseline">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                <defs>
                  <style>
                    .a {
                      fill: #95989a;
                    }
                  </style>
                </defs>
                <path class="a"
                  d="M2,8H1V2A1,1,0,0,1,2,1h8V2H2V8m4.5,4a.5.5,0,0,1-.5-.5V10H4A1,1,0,0,1,3,9V4A1,1,0,0,1,4,3h7a1,1,0,0,1,1,1V9a1,1,0,0,1-1,1H8.95L7.1,11.855A.523.523,0,0,1,6.75,12H6.5M5,5V6h5V5H5M5,7V8H9V7Z"
                  transform="translate(-1 -1)" />
              </svg>
            </span> Electronic billing instructions
          </h4>
          <textarea class="setting__textarea" name="billing-address-text" id="billing-address"
            [(ngModel)]="expertDetails && expertDetails.EBillInstructions"></textarea>
        </div>
      </div>
    </div>
    <div>
      <button
        (click)="openConfirmDialog('expertDetails', 'updateExpert',expertDetails, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
        type="button" class="btn module-submit btn-place-right">
        Update expert info
      </button>
    </div>
    <!-- Begin manage contract file -->
    <div class="modal-window" *ngIf="displayUploadFileModal">
      <div class="modal-container sync-request-modal-container" (click)="onModalBodyClickEvent($event)">
        <div class="modal__title-container b-dark-blue">
          <h3 class="welcome-page-modal__title modal__title ls-headline f-white">Contract Files</h3>
          <button class="welcome-page-modal__close modal__close" (click)="closeRequestModal()">
            <svg viewBox="0 0 24 24">
              <path fill="#ffffff"
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
          </button>
        </div>
        <div>
          <table style="width: 90%; margin: 20px !important;" *ngIf="contractFile.length>0">
            <tr *ngFor="let value of contractFile;">
              <td style="font-size: 16px;"><a href="javascript:void(0);" (click)="downloadPDF(value)">{{ value }}</a>
              </td>
              <td><button class="contract-svg-button" (click)="downloadPDF(value)"> <svg width="15" height="20"
                    viewBox="0 0 11 14">
                    <defs>
                      <style>
                        .a {
                          fill: #1a89da;
                        }
                      </style>
                    </defs>
                    <g transform="translate(-490.326 -473.692)">
                      <path class="a"
                        d="M496.658,755.4c.842-.218,1.775.041,2.8-.17v-4.85h4.726v4.795c1.022.307,1.958.014,2.837.2l-5.305,5.452Z"
                        transform="translate(-6.056 -276.687)" />
                      <path class="a" d="M495.122,817.7v-1.467h10.84c.25.423.044.9.16,1.467Z"
                        transform="translate(-4.796 -330.007)" />
                    </g>
                  </svg></button></td>
              <td><button class="contract-svg-button" style="margin-bottom: 4px;"
                  (click)="deleteContractFile(value)"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32"
                    height="32" viewBox="0 0 32 32" style=" fill:#000000;">
                    <path
                      d="M 15 4 C 14.476563 4 13.941406 4.183594 13.5625 4.5625 C 13.183594 4.941406 13 5.476563 13 6 L 13 7 L 7 7 L 7 9 L 8 9 L 8 25 C 8 26.644531 9.355469 28 11 28 L 23 28 C 24.644531 28 26 26.644531 26 25 L 26 9 L 27 9 L 27 7 L 21 7 L 21 6 C 21 5.476563 20.816406 4.941406 20.4375 4.5625 C 20.058594 4.183594 19.523438 4 19 4 Z M 15 6 L 19 6 L 19 7 L 15 7 Z M 10 9 L 24 9 L 24 25 C 24 25.554688 23.554688 26 23 26 L 11 26 C 10.445313 26 10 25.554688 10 25 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 16 12 L 16 23 L 18 23 L 18 12 Z M 20 12 L 20 23 L 22 23 L 22 12 Z">
                    </path>
                  </svg></button></td>
            </tr>
          </table>
          <div class="contract-text">
            <span>Add pdf</span>
            <input type="file" ng2FileSelect id="contractPdfUploader" name="contractPdfUploader"
              class="uploader-select-btn" [uploader]="contractPdfUploader" (onFileSelected)="uploadAsset()" />
          </div>
        </div>
      </div>
    </div>
    <!-- end manage contract file -->
  </div>

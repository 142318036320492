<div class="ate ems5">
  <div class="inner-page-main section-padding expert-details ate--moderate">
    <button class="btn--back back pointer" [routerLink]="['/asktheexpert']">&#8592; Back</button>
    <div class="ate__event-info">
      <div>
        <span><strong>Title:</strong> {{expertEvent?.EventTopic}}</span>
        <span><strong>Expert:</strong> {{expertEvent?.Expert?.ExpertName.English}}</span>
        <span><strong>Date:</strong> {{expertEvent?.EventDateString?.English}}</span>
        <span><strong>Language:</strong> {{langStr}}</span>
        <span><strong>Moderated as:</strong> {{expertEvent.EventModerator}}</span>
      </div>
      <div>
        <button *ngIf="!expertEvent.StreamLive" (click)="enableLiveStream(true)"
          class="btn ls-button-2 enable-stream-btn">Show Question Box</button>
        <button *ngIf="expertEvent.StreamLive" (click)="enableLiveStream(false)"
          class="btn ls-button-2 enable-stream-btn">Hide Question Box</button>
      </div>
    </div>
    <div class="basic-container module-details-container__info-container">
      <div class="flex-container">
        <div class="tabs card-box-shadow">
          <div class="tabs__container">
            <a [routerLink]="['/asktheexpert/event-edit', expertEvent.EventID]" class="tab">
              <span class="page-header f-dark-blue ls-subheading">Event Details</span>
            </a>
            <a [routerLink]="['/asktheexpert/moderate', expertEvent.EventID]" class="tab tab--active">
              <span class="page-header ls-subheading">Moderate</span>
            </a>
            <a [routerLink]="['/asktheexpert/question-edit', expertEvent.EventID]" class="tab">
              <span class="page-header f-dark-blue ls-subheading">Edit Questions</span>
            </a>
          </div>
        </div>
        <div class="ate__users live-indicator">
          <div>
            <div *ngIf="expertEvent.StreamLive" class="ate-live"></div>
            Users connected:&nbsp;<strong>{{connectedUsersText}}</strong>
          </div>
        </div>
      </div>
      <div class="details-container info-container__text-container ">
        <div class="two-col">
          <div class="socket-disconnected" *ngIf="!hubConnected">
            <h3>{{_emsConfig.text?.EMS_Expert.Connection_Dropped}}</h3>
          </div>
          <div class="ate-mod-info two-col__right">
            <button class="btn ls-button-1" (click)="addQuestion(false)">Add Question</button>
            <button class="btn ls-button-1" (click)="addQuestion(true)">Add Comment</button>
          </div>
        </div>
        <ul class="ate-mod-nav toggle-buttons">
          <li (click)="toggleTab(1)" [ngClass]="{'active': activeTab === 1}" class="secondary-nav__list-item ls-title">
            Inbox</li>
          <li (click)="toggleTab(2)" [ngClass]="{'active': activeTab === 2}" class="secondary-nav__list-item ls-title">
            Posted Content</li>
          <li (click)="toggleTab(3)" [ngClass]="{'active': activeTab === 3}" class="secondary-nav__list-item ls-title">
            Trash Bin</li>
        </ul>

        <ate-mod-inbox [hidden]="activeTab !== 1" [questions]="inboxQuestions" [langStr]="langStr"
          (moveQuestion)="moveQuestionToParent($event)" (editQuestion)="modifyQuestion($event)"></ate-mod-inbox>
        <ate-mod-posted [hidden]="activeTab !== 2" [questions]="postedQuestions" [langStr]="langStr"
          (moveQuestion)="moveQuestionToParent($event)" (editQuestion)="modifyQuestion($event)"></ate-mod-posted>
        <ate-mod-trash [hidden]="activeTab !== 3" [questions]="trashQuestions" [langStr]="langStr"
          (moveQuestion)="moveQuestionToParent($event)"></ate-mod-trash>

        <div class="modal-window ate-mod-modal" [hidden]="!modModal" [ngClass]="{'mod-comment': modComment}"(click)="closeQuestion()">
          <div class="modal-container" (click)="onEvent($event)">
            <div class="modal__title-container b-dark-blue">
              <h3 class="modal__title ls-headline f-white">Add {{modModalTitle}} ({{langStr}})</h3>
              <button class="modal__close" (click)="closeQuestion()">
                <svg viewBox="0 0 24 24">
                  <path fill="#ffffff"
                    d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                </svg>
              </button>
            </div>
            <div class="modal__content">
              <table>
                <tbody>
                  <tr *ngIf="!modComment">
                    <td>Nickname:</td>
                    <td class="col-fill-space"><input id="ate-mod-nickname" [(ngModel)]="modNickname" type="text"
                        (input)="onNicknameChange($event.target.value)"></td>
                  </tr>
                  <tr>
                    <td>{{modModalTitle}}</td>
                    <td class="textarea-container col-fill-space">
                      <textarea id="ate-question-submit"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="modComment" class="ate-mod-modal__preloaded-container">
                <div class="basic-info__checkbox-container">
                  <h4>Preloaded Content:</h4>
                  <div class="checkbox-container">
                    <div class="ls-radio">
                      <input name="preloadedContent" [(ngModel)]="selectedPreloadedResource" (ngModelChange)="resourceChange()"
                        type="radio" value="text" id="text">
                      <label for="text"><span></span></label>
                    </div>
                    Text
                  </div>
                  <div class="checkbox-container">
                    <div class="ls-radio">
                      <input name="preloadedContent" [(ngModel)]="selectedPreloadedResource" (ngModelChange)="resourceChange()"
                        type="radio" value="blog" id="blog">
                      <label for="blog"><span></span></label>
                    </div>
                    Blog
                  </div>
                  <div class="checkbox-container">
                    <div class="ls-radio">
                      <input name="preloadedContent" [(ngModel)]="selectedPreloadedResource" (ngModelChange)="resourceChange()"
                        type="radio" value="video" id="video">
                      <label for="video"><span></span></label>
                    </div>
                    Video
                  </div>
                </div>
                <div class="ate-mod-modal__resource">
                  <div class="preloaded-text" *ngIf="modComment && selectedPreloadedResource === 'text'">
                    <h4>Text:</h4>
                    <div class="select-container">
                      <select id="ate-preloaded-text" name="ate-preloaded-text" (change)="addPreloadedText($event.target.value)"
                        [(ngModel)]="preloadedTextVal" class="setting__select-input select-input">
                        <option [selected]="true" value="">Select text</option>
                        <option value={{text.ResourceType[langStr]}} *ngFor="let text of preloadedText">{{text.ResourceKey}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="blog-selection" *ngIf="modComment && selectedPreloadedResource === 'blog'">
                    <h4>Blog:</h4>
                    <input type="text" [(ngModel)]="blogSearchTerm" placeholder="Search blogs">
                    <div class="select-container">
                      <select id="expert-blog-list" name="expert-blog-list" (change)="addExpertBlogPost($event.target.value)"
                        [(ngModel)]="blogVal" class="setting__select-input select-input">
                        <option [selected]="true" value="">Select Expert Blog Post</option>
                        <option value={{post.ID}} *ngFor="let post of expertBlogPosts | blogSearchFilter:blogSearchTerm"
                          [innerHTML]="post.PostTitle"></option>
                      </select>
                    </div>
                  </div>
                  <div class="video-selection" *ngIf="modComment && selectedPreloadedResource === 'video'">
                    <h4>Video:</h4>
                    <div class="asset-container__select">
                      <div class="row">
                        <div class="select-container">
                          <select name="editCategories" (change)="getVolumes($event.target.value)" class="setting__select-input select-input">
                            <option value="" selected disabled>Select Category</option>
                            <option *ngFor="let category of categories" [value]="category.ID">
                              {{ category.Name.English }}
                            </option>
                          </select>
                        </div>
                        <div class="select-container">
                          <select name="editVolumes" (change)="getVideosForVolume($event.target.value)" [disabled]="volumes.length < 1" class="setting__select-input select-input">
                            <option value="" selected disabled>Select Volume</option>
                            <option *ngFor="let volume of volumes" [value]="volume.ID">
                              {{ volume.VolumeTitle }}
                            </option>
                          </select>
                        </div>
                        <div class="select-container">
                          <select name="editVideos"
                          [(ngModel)]="moduleVal"
                          (ngModelChange)="addVideoAsset($event, modules?.volume)"
                          [disabled]="modules?.media.length < 1"
                          class="setting__select-input select-input">
                            <option value="" selected disabled>Select Video</option>
                            <option *ngFor="let module of modules?.media" [ngValue]="module">
                              {{ module.Title }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="align-btn-right">
                <button class="btn ls-button-1" [disabled]="!validInput" (click)="modSubmitQuestion()">Submit</button>
                <button class="btn ls-button-1" (click)="closeQuestion()">Cancel</button>
              </div>
            </div>
          </div>
        </div>

        <modify-ate-question [hidden]="!toggleModify" [activeQuestion]="activeQuestion" [expertEvent]="expertEvent"
          (deactivateModal)="closeModify($event)" (updateVals)="updateFromModify($event)"></modify-ate-question>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { LoginToken } from '.././shared/login-token';
import { EmsConfig } from '../shared/emsConfig';


@Injectable()
export class ConfirmService {

   notification(data: any): any {
       return data;
   }

    private confirmData: Subject<any> = new BehaviorSubject(this.notification({prevent: true}));

    private actionConfirmed = new Subject<any>();

   //so we can subscribe in the components
   confirmData$ = this.confirmData.asObservable();
   actionConfirmed$ = this.actionConfirmed.asObservable();

   //when update notification is called, pass in the data to confirm.component.ts
   updateNotification(data: any): void {
       this.confirmData.next(data);
   }

   //pass whether confirmed or not to settings component (ex. account-settings.component)
    confirmAction(data: any) {
        this.actionConfirmed.next(data);
    }

}

// Angular Things
import {Component, HostBinding, OnInit, OnDestroy} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

// Services
import { UtilitiesService } from '../../shared/utilities.service';
import { ConfirmService } from '../../shared/confirm.service';
import { PopupService } from '../../shared/popup.service';
import { EmsConfig } from '../../shared/emsConfig';
import { CmsService } from '../../cms/cms.service';
import { LanguageItem } from 'app/shared/languageItem';
import { SiteTextItem } from 'app/shared/objects/siteTextItem';

// third party
declare var tinymce: any;

@Component({
  selector: 'cms-manage-text-details',
  templateUrl: 'cms-manage-text-details.component.html'
})
export class CMSManageTextDetails implements OnInit, OnDestroy {
  @HostBinding('class') classAttribute: string = 'cms-manage-text-details';
  private sub: any;
  id: any;
  private page: string;
  resourceGroupId: number;

  initText: string = 'this is editor text';

  allLanguages: any[];
  resourceTranslations: SiteTextItem[] = [];
  allResourceTranslations: SiteTextItem[] = [];
  translationItem: SiteTextItem;
  newTranslation: SiteTextItem = {};

  showAddEditSection = false;
  showEditForm = false;
  showAddForm = false;

  resourceDetails: LanguageItem = new LanguageItem();
  pageName: string;
  resourceKey: string;
  pageID: any;
  resourceSyncKey: number;
  errorMessage: string;

  // editor stuff
  public Lang: string = 'EN';


  // confirm stuff
  confirmData: {};
  confirmSiteTextData: any;
  confirmAction: string;
  confirmSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private cmsService: CmsService,
    public _emsConfig: EmsConfig,
    private _confirmService: ConfirmService,
    private _utilitiesService: UtilitiesService,
    private _popupService: PopupService,
    private location: Location,
    private router: Router
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'updateResources') {
            this.addEditTranslation(this.confirmSiteTextData);
          } else if (this.confirmAction === 'deleteResource') {
            this.deleteTranslation(this.confirmSiteTextData);
          }
        }
      }
    );
  }

  // =======================================================
  // characters: &,>,< will be stored in non-entity form
  // =======================================================
  unEntity(text: string) {
    text = text.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    return text;
 }


  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(component: string, action: string, data: any, message: string): void {
    this.confirmAction = action;
    this.resourceDetails.ResourceKey = this.resourceKey;
    this.confirmSiteTextData = data;
    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }

  // =================================
  // checks and sends data to database
  // =================================
  addEditTranslation(siteText): void {
    // pull data from correct editor
    if (siteText.GroupId === -1) {
      siteText.ResourceValue = this.unEntity(tinymce.get('resource-text-area-add').getContent());
    } else {
      siteText.ResourceValue = this.unEntity(tinymce.get('resource-text-area').getContent());
    }

    // check if brand new translation and set the new resource key (for url)
    if (this._utilitiesService.isBlank([this.resourceKey])) {
      this.resourceKey = siteText.ResourceKey;
    }

    // check that fields are filled out
    if (this._utilitiesService.isBlank([siteText.ResourceKey])) {
      this._popupService.updateNotification({ message: this._emsConfig.text.EMS_Content.Complete_Fields_Error, error: true });
    } else {
      console.log(siteText);
      console.log('update it!');
      this.cmsService.addUpdateSiteTextItem(siteText).subscribe(data => {
        console.log(data);
        if (data.success) {
          // update the url
          this.location.go('/cms/manage_text/' + this.pageID + '/' + data.groupId);

          // get updated translations by resource key
          this.getResourceKeySiteText(data.groupId);

          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Success, success: true });
        } else {
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
        }
      })
    }
  }

  // delete a translation for resource key
  deleteTranslation(siteText): void {
    console.log(siteText);
    this.cmsService.deleteSiteTextItem(siteText).subscribe(data => {
      console.log(data);
      if (data.success) {
          // get updated translations by resource key
          this.getResourceKeySiteText(siteText.GroupId);

          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Success, success: true });
      } else {
        this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
      }
    });
  }

  // =========================================
  // gets details of resources and languages
  // =========================================
  //get all translations for a specific page/resource key
  getResourceKeySiteText(groupId) {
    if (groupId === -1) {
      // load table of translations to add
      for (let i = 0; i < this.allLanguages.length; i++) {
        // create a new translation object and set properties
        let translation: SiteTextItem = {
          LangCode: this.allLanguages[i].Code,
          ResourceValue: null,
          LangName: this.allLanguages[i].Name
        };

        console.log(translation);

        // add to the resource translations array
        this.resourceTranslations.push(translation);
      }
    } else {
      this.resourceTranslations = [];
      this.cmsService.getSiteTextByResourceGroupId(groupId).subscribe(siteText => {
        this.allResourceTranslations = siteText;
        console.log(this.allResourceTranslations);


        // get the sync key for new translations (if existing resource key)
        if (this.allResourceTranslations.length > 0) {
          this.pageID = this.allResourceTranslations[0].PageID;
          this.pageName = this.allResourceTranslations[0].PageName;
          this.resourceKey = this.allResourceTranslations[0].ResourceKey;
          this.resourceSyncKey = this.allResourceTranslations[0].SyncKey;
          console.log('SyncKey: ' + this.resourceSyncKey);
        }

        for (let i = 0; i < this.allLanguages.length; i++) {
          // find the corresponding translation to language
          let langIndex = siteText.findIndex(x => x.LangCode.toLowerCase() === this.allLanguages[i].Code.toLowerCase());

          // create a new translation object and set properties
          let translation: SiteTextItem = {
            LangCode: this.allLanguages[i].Code,
            ResourceValue: this._utilitiesService.isBlank([siteText[langIndex]]) ? null : siteText[langIndex].ResourceValue,
            LangName: this.allLanguages[i].Name
          };

          // add to the resource translations array
          this.resourceTranslations.push(translation);
        }

        console.log(this.resourceTranslations);
      });
    }
  }

  // get list of languages available
  getAllLanguages() {
    this.cmsService.getLanguages().subscribe(langs => {
      // get list of all language supported on the site
      this.allLanguages = langs;

      // now get all translations for resource key
      this.getResourceKeySiteText(this.resourceGroupId);
    })
  }

  // ======================
  // setup edit/add forms
  // ======================

  // populate the add/edit sections
  selectTranslation(langCode): void {
    // get correct translation from the list of all language translations
    const langIndex = this.allResourceTranslations.findIndex(x => x.LangCode.toLowerCase() === langCode.toLowerCase());
    this.translationItem = this.allResourceTranslations[langIndex];
    console.log(this.translationItem);

    // show correct form
    this.showAddForm = false;
    this.showEditForm = true;
    this.showAddEditSection = true;

    // wait for the element to be available in dom before editor init
    setTimeout(() => {
      this.setupTinyMce('resource-text-area', this.translationItem.ResourceValue);
    }, 0);

  }

  createTranslation(langCode): void {
    // pre-populate some values for the new translation
    this.newTranslation.LangCode = langCode;
    this.newTranslation.PageID = this.pageID;
    this.newTranslation.GroupId = -1;

    // check if this is a brand new resource key
    if (this._utilitiesService.isBlank([this.resourceSyncKey])) {
      this.newTranslation.ResourceKey = '';
    } else {
      // only set if it's an existing resource key
      this.newTranslation.ResourceKey = this.resourceKey;
    }

    // show correct form
    this.showEditForm = false;
    this.showAddForm = true;
    this.showAddEditSection = true;

    // wait for the element to be available in dom before editor init
    setTimeout(() => {
      this.setupTinyMce('resource-text-area-add', '');
    }, 0);
  }

  // ==========================
  // get back to listing page
  // ==========================
  getBack() {
    this.router.navigate(['/cms/manage_text'], {
      queryParams: {page: this.pageID}
    });
  }

  // ================================================
  // Performs component initialization & retieve data
  // ================================================
  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.pageID = params['page'];
      this.resourceGroupId = parseInt(params['id']);
    });

    // first get list of all languages available
    this.getAllLanguages();
  }

  // since this runs each time a resource is selected we'll pass the editor content and set it in the init so it doesn't get overwritten
  setupTinyMce(editorId, translationContent): void {
    // make sure the old instance is removed before new init
    tinymce.remove(tinymce.get(editorId));

    // run the init & set the content
    tinymce.init({
      selector: '#' + editorId,
      entity_encoding : 'raw',
      plugins: 'link table code lists nonbreaking',
      toolbar: 'code | undo redo | styles fontsize | bold italic underline | bullist numlist outdent indent | link unlink | hr',
      font_size_formats: '13px 16px 18px 20px 24px',
      browser_spellcheck: true,
      min_height: 300,
      convert_urls: false,
      remove_script_host: false,
      inline_styles: true,
      menubar: false,
      forced_root_block: 'aside', // Use an obscure block choice to easily parse and remove in post processing below
      setup: function (editor) {
        editor.on('init', function(e) {
          editor.setContent(translationContent);
        });
        editor.on('PostProcess', function(e) {
          // Search through editor content and replace all forced root blocks specified above:
          e.content = e.content.replace(/<aside>/, ""); // Replace starting aside tag
          e.content = e.content.replace(/<aside>/g, "<br />"); // Convert all subsequent aside tags to a <br /> tag to allow for linebreaks
          e.content = e.content.replace(/<\/aside>/g, ""); // And remove all aside closing tags
        });
      }
    });
  }

  // ===================================
  // Performs cleanup of component
  // ===================================
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.confirmSubscription.unsubscribe();
    tinymce.remove(tinymce.get('resource-text-area'));
    tinymce.remove(tinymce.get('resource-text-area-add'));
  }
}

<div class="confirm-message" [ngClass]="{'confirm-message__success': confirmData.success, 'confirm-message__error': confirmData.error}" *ngIf="active">
    <div class="confirm-modal modal-window">
      <div class="confirm-modal__container modal-container b-white">
        <button class="confirm-modal__close modal__close" (click)="cancelConfirm()">
          <svg viewBox="0 0 24 24">
              <path fill="#707070" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
        <p class="confirm-modal__text ls-subheading" [innerHTML]="confirmData.message"></p>
        <div class="confirm-modal__button-container">
          <button class="btn btn--small confirm-modal__cancel ls-button-1 uppercase" (click)="cancelConfirm()">{{_emsConfig.text?.EMS_General.Cancel}}</button>
          <button class="btn btn--small confirm-modal__confirm b-dark-blue ls-button-1 uppercase" (click)="confirm()">{{_emsConfig.text?.EMS_General.Confirm}}</button>
        </div>
      </div>
    </div>
</div>

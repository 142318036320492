// Angular Things
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ATEListComponent } from './ate/ate-list.component';
import { ATEEditComponent } from './ate/ate-edit.component';
import { ATEEventEditComponent } from './ate/ate-event-edit.component';

import { ATEModerationComponent } from './ate/ate-moderation.component';
import { AteExpertComponent } from './expert/ate-expert.component';

// Security Things
import { AuthGuard } from '../authGuard';

// to secure a route add the [AuthGuard] decorator
// for pages that only allow system admins, use AuthGuardSysAdmin
export const connexRoutes: Routes = [

  { path: 'asktheexpert', component: ATEListComponent, canActivate: [AuthGuard] },
  { path: 'asktheexpert/question-edit/:id', component: ATEEditComponent, canActivate: [AuthGuard] },
  { path: 'asktheexpert/event-edit/:id', component: ATEEventEditComponent, canActivate: [AuthGuard] },
  { path: 'asktheexpert/moderate/:id', component: ATEModerationComponent, canActivate: [AuthGuard] },
  { path: 'asktheexpert/expert', component: AteExpertComponent, canActivate: [AuthGuard] }

];

@NgModule({
  imports: [
    RouterModule.forChild(connexRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class ConnexRoutingModule { }

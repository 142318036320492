import { Component } from '@angular/core';

import { Subscription } from 'rxjs';

import { ConfirmService } from './confirm.service';
import { EmsConfig } from './emsConfig';

@Component({
    selector: 'confirm-message',
    templateUrl: 'confirm.component.html'
})
export class ConfirmComponent {

    public confirmData: any = {};

    confirmed: boolean = false;

    // set initial vals
    public active: boolean = false;

    subscription: Subscription;

    constructor(
      private _confirmService: ConfirmService,
      public _emsConfig: EmsConfig
    ) {
        // set initial popup vals
        this.confirmData.success = false;
        // get the message for the confirm modal from the confirm service
        this.subscription = _confirmService.confirmData$.subscribe(
            data => {
                // on init we prevent the popup from displaying
                if (!data.prevent) {
                    this.updateConfirm(data);
                }
            }
        )
    }

    cancelConfirm(): void {
        // if cancelled, just close the popup
        this.active = false;

        // send cancelled message
        this._confirmService.confirmAction({confirmed: false, data: this.confirmData});

    }

    confirm() {
        // close the popup, but set confirmed to true
        this.active = false;
        this.confirmed = true;
        // pass true to confirm service
        this._confirmService.confirmAction({confirmed: this.confirmed, data: this.confirmData});
    }

    // updates the popup with various values
    updateConfirm(data: any): void {
        this.confirmData = data;
        // set default confirm message if there is none
        if (!data.message) {
          this.confirmData.message = this._emsConfig.text.EMS_General.Confirm_Message;
        }
        // open the confirm modal
        this.active = true;

    }

}

// Angular Things
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';

// Classes
import { QuizItem } from '../QuizItem';

//  Services
import { CmsService } from '../cms.service';
import { UtilitiesService } from '../../shared/utilities.service';

@Component({
  selector: 'cms-iq-list', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-iq-list.component.html',
  providers: [CmsService]
})

export class CmsIqListComponent implements OnInit {

  iqSearch: string;

  errorMessage: string;

  allQuizzes: QuizItem[] = [];

  // edit volumes variables
  showEditButton: boolean = false;
  selectedIqId: number;

  constructor(private router: Router, private route: ActivatedRoute, public _cmsService: CmsService, public _emsConfig: EmsConfig, public _utilitiesService: UtilitiesService) { }

  getQuizzes(): void {
    this._cmsService.getAllIqQuizzes()
      .subscribe(
      QuizItem => {
        this.allQuizzes = QuizItem;
        for (let i = 0; i < this.allQuizzes.length; i++) {
          this.allQuizzes[i].TitleNormalized = this._utilitiesService.normalizeTerm(this.allQuizzes[i].Title);
        }
      },
      err => this.errorMessage = <any>err,
      () => console.log(this.allQuizzes)
      );
  }

  // focus on selected volume and store the id
  selectIq($event: any): void {
    const target = $event.currentTarget;
    target.focus();
    this.selectedIqId = $event.currentTarget.attributes.id.value;
    console.log(this.selectedIqId);
    this.showEditButton = true;
  }

  deselectIq($event: any): void {
    // check if click is on specific element
    if ($event.relatedTarget != null) {
      // if click is another volume or the fab button, show the edit button
      if ($event.relatedTarget.className === 'btn--fab b-dark-blue cms-container__fab cms-container__fab--edit' || $event.relatedTarget.className === 'iq-card card') {
        this.showEditButton = true;
      } else {
        // if not clicking on other volume, show add button instead of edit
        this.showEditButton = false;
      }
    } else {
      this.showEditButton = false;
    }
  }

  // Navigates to Volume Details template
  onSelect() {
    // If search query exists, add param to route to allow for easy navigation back to filtered list of IQs
    this.router.navigate([], { queryParams: { query: this.iqSearch } });
    // Navigate with relative link after route is updated with search query param
    setTimeout(() => {
      this.router.navigate([this.selectedIqId], { relativeTo: this.route });
    }, 100)
  }

  ngOnInit(): void {

    // checks if search query param exists, and populates search input filter
    if (this.route.snapshot.queryParams["query"]) {
      const searchParam = this.route.snapshot.queryParams["query"];
      this.iqSearch = searchParam;
    };

    this.getQuizzes();
  }

}

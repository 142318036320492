export class OlsMigrationLogName {
    Expert: string = "Expert";
    Taxonomy: string = "Taxonomy";
    Blog: string = "Blog";
    Video: string = "Video";
}


export class OlsMigrationLogType {
    Content?: string = "Content";
}

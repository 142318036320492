<div class="learning-hub learning-hub--overview">
  <div class="page-ribbon">
    <div class="page-header">
      <h2 class="title">Learning Hub</h2>
    </div>
  </div>
  <section class="overview section-padding">
    <div class="volumes-container__sort-container sort-container two-col">
      <div class="col playlist-filter">
        <div class="sort-container__searchbar">
          <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          <input class="searchbar__input" [(ngModel)]="search" type="search"
            placeholder="Search for playlist">
        </div>
      </div>
      <div class="col lang-filter">
        <div>
          <button (click)="selectedLang='EN'" [ngClass]="{'active':selectedLang==='EN'}" class="title">
            {{ emsConfig.text?.EMS_General.EN }}
          </button>
          <button (click)="selectedLang='FR'" [ngClass]="{'active':selectedLang==='FR'}" class="title">
            {{ emsConfig.text?.EMS_General.FR }}
          </button>
        </div>
      </div>
    </div>

    <table class="display-table display-table--sortable">
      <thead>
        <tr>
          <th class="no-click" *ngIf="clientName">
            Participation ID
          </th>
          <th (click)="sortBy('ID')"
            [ngClass]="{'active':sortCategory==='ID', 'active--asc':sortCategory==='ID' && sortCategoryCounter===1, 'active--desc':sortCategory==='ID' && sortCategoryCounter===2}">
            Playlist ID
          </th>
          <th (click)="sortBy('Title')"
            [ngClass]="{'active':sortCategory==='Title', 'active--asc':sortCategory==='Title' && sortCategoryCounter===1, 'active--desc':sortCategory==='Title' && sortCategoryCounter===2}">
            Title
          </th>
          <th (click)="sortBy('Status')"
            [ngClass]="{'active':sortCategory==='Status', 'active--asc':sortCategory==='Status' && sortCategoryCounter===1, 'active--desc':sortCategory==='Status' && sortCategoryCounter===2}">
            Status
          </th>
          <th class="no-click">
            Lang
          </th>
          <th *ngIf="clientName" class="no-click">
            Training Hub
          </th>
          <th (click)="sortBy('DateCreated')"
            [ngClass]="{'active':sortCategory==='DateCreated', 'active--asc':sortCategory==='DateCreated' && sortCategoryCounter===1, 'active--desc':sortCategory==='DateCreated' && sortCategoryCounter===2}">
            Date
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let playlist of playlists | filterPlaylists:search">
          <ng-container *ngIf="playlist.Lang == selectedLang">
            <tr>
              <td *ngIf="clientName">{{playlist.ParticipationID}}</td>
              <td>{{playlist.ID}}</td>
              <td class="title-size edit-link" (click)="navToPlaylistDetails(playlist.ID,playlist.ParticipationID)">{{
                playlist.Title }}</td>
              <td>
                <h5 class="ls-body-2 status-active" *ngIf="playlist.Status === 'live'">Active</h5>
                <h5 class="ls-body-2 status-{{playlist.Status}}" *ngIf="playlist.Status != 'live'">
                  {{ playlist.Status | titlecase }}</h5>
              </td>
              <td>{{ playlist.Lang }}</td>
              <td *ngIf="clientName">{{ playlist.UsageTracking }}</td>
              <td>{{ playlist.DateCreated | date:'yyyy-MM-dd' }}</td>
            </tr>
          </ng-container>
        </ng-container>
        <tr *ngIf="playlists.length === 0">
          <td>No playlist</td>
        </tr>
      </tbody>
    </table>
  </section>
  <div *ngIf="clientName" class="btn-container">
    <button (click)="displayModal=!displayModal" class="btn--fab b-dark-blue">
      <svg viewBox="0 0 24 24">
        <path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
      </svg>
    </button>
  </div>
  <button *ngIf="!clientName" (click)="createNewPlaylist()"
    class="btn--fab b-dark-blue cms-container__fab cms-container__fab--add">
    <svg viewBox="0 0 24 24">
      <path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
  </button>

  <!-- Begin participation modal  -->
  <div class="modal-window" *ngIf="displayModal">
    <div class="modal-container info-container__text-container" (click)="onModalBodyClickEvent($event)">
      <div class="modal__title-container b-dark-blue">
        <h3 class="f-white">Add playlist to {{clientName}}</h3>
        <button class="modal__close" (click)="displayModal=!displayModal">
          <svg viewBox="0 0 24 24">
            <path fill="#ffffff"
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>
      <div class="add-playlist-container">
        <div class="select-container">
          <select name="selectplaylist" [(ngModel)]="playlistIdToAdd" class="setting__select-input select-input">
            <option value="0" selected disabled>Select playlist</option>
            <option *ngFor="let playlist of availablePlaylistsForClient;" [value]="playlist.ID">
              {{playlist.Title}}
            </option>
          </select>
        </div>
        <button (click)="addPlaylist()" class="save-btn btn">
          Submit
        </button>
      </div>
    </div>
  </div>
  <!-- end participation modal  -->
</div>

<div class="subtitling subtitling--import ems5">
  <div class="page-ribbon">
    <div class="page-header">
      <h2 class="title">Bulk Subtitling</h2>
    </div>
  </div>
  <section class="subtitling__tabs">
    <div>
      <a [routerLink]="['/cms/subtitling/export']" class="tab title">
        Export files
      </a>
      <a [routerLink]="['/cms/subtitling/import']" class="tab title active">
        Import files
      </a>
    </div>
  </section>
  <section class="overview">
    <div class="basic-container info-container">
      <div class="subtitling__instructions" >
        <h3>Instructions</h3>
        <span>Use the Import Files tool to add caption / subtitling files to our system in bulk. Simply drag and drop (or choose) all VTT caption files for upload. The files must be in the correct naming convention and have the .vtt file extension.
            <br/>
            Note: The vendor should already provide the files in the correct format.
        </span>
      </div>
      <div class="uploader-drop">
        <div ng2FileDrop 
          (fileOver)="isFileOverDropzone = $event" 
          (onFileDrop)="validateFileName()"
          [uploader]="uploader"
          class="well my-drop-zone upload-container__dropzone"
          [ngClass]="{'nv-file-over': isFileOverDropzone}" >
            Drag and drop subtitling VTT files here
        </div>
        <input ng2FileSelect 
          type="file"  
          multiple 
          [uploader]="uploader" 
          (change)="validateFileName()" 
        />
      </div>

      <div class="uploader-queue" style="margin-bottom: 40px">
        <div class="uploader-queue__header">
          <h3>Upload queue</h3>
          <p>Queue length: {{ uploader?.queue?.length }}</p>
        </div>
        <div class="subtitling__table uploader-queue__table">
          <table class="display-table">
            <thead>
              <tr>
                <th width="50%">Name</th>
                <th>Size</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of uploader.queue">
                <td><strong>{{ item?.file?.name }}</strong></td>
                <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                <td class="text-center">
                  <span *ngIf="item.isSuccess">Success</span>
                  <span *ngIf="item.isCancel">Cancel</span>
                  <span *ngIf="item.isError">Error</span>
                </td>
                <td class="button-container" width="235">
                    <button type="button" 
                      (click)="uploadItem(item)" 
                      [disabled]="item.isError"
                      [ngClass]="item.isError ? 'disabled' : ''"
                      class="btn ls-button-1 btn--small">
                        Upload
                    </button>
                    <button type="button" 
                      (click)="item.remove()"
                      class="btn btn--small delete-btn">
                        Remove
                    </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="uploader?.queue?.length > 0" class="uploader-queue__footer">
          <div class="button-container">
            <button type="button" 
              (click)="uploadQueue()" 
              [disabled]="!uploader.getNotUploadedItems().length"
              class="btn">
                Upload All
            </button>
            <button type="button"
              (click)="uploader.clearQueue()" 
              [disabled]="!uploader.queue.length"
              class="btn delete-btn">
                Remove All
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

// Angular Things
import { Component, Inject } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { UtilitiesService } from 'app/shared/utilities.service';
import { CmsService } from 'app/cms/cms.service';
import { FormsModule } from '@angular/forms';
import { AITemplate } from 'app/shared/aiTemplate';
import { SnackbarService } from 'app/services/Snackbar.service';

export interface DialogDataAddTemplate {
	category: '',
	templateLanguage: ''
}

@Component({
	selector: 'dialog-content-add-template-dialog',
	templateUrl: 'AddAITemplate.component.html',
	standalone: true,
	imports: [MatDialogModule, MatButtonModule, MatFormFieldModule, MatInputModule, FormsModule, MatCheckboxModule],
})
export class DialogGenericAddTemplate {
	constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataAddTemplate, public _utilitiesService: UtilitiesService, public _cmsService: CmsService, public _snackbarService: SnackbarService) {

		this.templateInfo = {} as AITemplate;

		this.templateInfo.Category = this.data.category;
		this.templateInfo.Lang_Code = this.data.templateLanguage;
	}
	networkCall: boolean = false;
	test = '';
	postSearch: string;

	templateInfo: AITemplate;

	addTemplate(): void {
		this.networkCall = true;

		this._cmsService.addAITemplate(this.templateInfo)
			.subscribe(
				data => {
					this.networkCall = false;
					if (data.succeeded === false) {
						this._snackbarService.error('Fail to create');
					} else {
						this._snackbarService.success('Success.');
						this.templateInfo = {} as AITemplate;
					}
				},
				err => {
					this.networkCall = false;
				},
			);
	}

}

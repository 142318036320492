export class ClientCampaign {
    CampaignID: number;
    CampaignTitle: string;
    ParticipatingID: number = -1;
    ClientID: string;
    ClientName: string;
    StartDate: Date;
    EndDate: Date;
    PreviewDate: Date;
    HomepageTile: boolean;
    LanguageCode: string;
    MessageType: string;
}
import { Component, HostBinding, OnInit } from "@angular/core";
import { CmsService } from "app/cms/cms.service";
import { EmsConfig } from "../.././shared/emsConfig";
import { Router, ActivatedRoute } from "@angular/router";
// Classes
import { LanguageItem } from "../../shared/languageItem";
import { SiteTextPage } from "app/shared/objects/siteTextPage";
import { PopupService } from "app/shared/popup.service";


@Component({
  selector: "cms-manage-text",
  templateUrl: "cms-manage-text.component.html"
})
export class CMSManageSiteText implements OnInit {
  @HostBinding('class') classAttribute: string = 'cms-manage-text';

  // search items
  searchResult: any;
  SearchItem: string = "";
  Search: boolean = false;
  Language: string = "EN";

  pageList: any;
  public resourceList: any;

  temp: number;
  public rowSelected: number = -1;
  public SearchResourceItem: string;

  newPage: SiteTextPage = {};

  constructor(
    private cmsService: CmsService,
    private emsConfig: EmsConfig,
    private router: Router,
    private route: ActivatedRoute,
    private _popupService: PopupService
  ) {}


  toggleResouces(ID: number): void {
    this.Search = false;
    if (this.temp != ID) {
      this.rowSelected = -1;
    }
    if (this.rowSelected === -1) {
      this.router.navigate(['/cms/manage_text'], {
        queryParams: {page: ID}
      });
      this.rowSelected = ID;
      this.cmsService.getResourcesByPageID(ID).subscribe(Resources => {
        this.resourceList = Resources;
        console.log(this.resourceList);
        this.temp = ID;
      });
    } else {
      if (this.rowSelected === ID) {
        this.rowSelected = -1;
        this.router.navigate(['/cms/manage_text']);

      } else {
        this.rowSelected = ID;

      }
    }
  }

  // ================================
  // trimming the current dateformat
  // ================================
  trimDate(date: string): string {
    return date.substring(0, date.indexOf(" "));
  }

  // ===========================
  // removes all the html tag
  // ============================
  trimText(text: string): string {
    return text.replace(/<[^>]+>/gm, "").substring(0, 80);
  }

  // ===================================
  // navigates to resource details page
  // ===================================
  editResource(pageID: string, groupId: string) {
    if (this.router.url.includes("manage_text")) {
      this.router.navigate([pageID, groupId], {
        relativeTo: this.route
      });
    }
  }

  // ==============================================
  // search for the text according to the language
  // ==============================================
  searchTextItem() {
    this.Search = true;
    this.cmsService.getSearchResult(this.Language, this.SearchItem).subscribe(result => {
        this.searchResult = result;
      });
  }

  // ===========================
  // clears all the search Data
  // ===========================
  ClearSearch() {
    this.Search = false;
    this.SearchItem = '';
    this.Language = 'EN';
  }

  // ===========================
  // gets list of pages
  // ===========================
  getSiteTextPages(): void {
    this.cmsService.getAllPages().subscribe(pages => {
      this.pageList = pages;
      console.log(this.pageList);
    });
  }

  // ===========================
  // create new site text page
  // ===========================
  createNewPage(pageName): void {
    console.log(pageName);
    this.newPage.ID = -1;
    this.newPage.PageName = pageName;

    this.cmsService.addPage(this.newPage).subscribe(data => {
       console.log(data);
        if (data != -1) {
          // update list of pages
          this.getSiteTextPages();

          // clear the new page name
          this.newPage.PageName = '';

          this._popupService.updateNotification({ message: this.emsConfig.text.EMS_General.Popup_Success, success: true });
        } else {
          this._popupService.updateNotification({ message: this.emsConfig.text.EMS_General.Popup_Error, error: true });
        }
    });
  }

  // ==================================================
  // Performs component initialization & retieve data
  // ===================================================
  ngOnInit() {
    this.getSiteTextPages();

    if(this.route.snapshot.queryParams["page"])
    {
      const param = this.route.snapshot.queryParams["page"];
      this.toggleResouces(parseInt(param));
    }
  }
}

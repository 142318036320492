<div class="learning-hub learning-hub--playlist ems5">
    <div class="page-ribbon">
        <div class="page-header">
            <h2 class="title">Edit Playlist<span>{{playlist?.Title}}</span></h2>
        </div>
        <syncstatus *ngIf="!addPlaylist" [assetID]="playlist?.ID" [tableID]="1014"></syncstatus>
    </div>

    <!-- <button class="btn--back back pointer" [routerLink]="['/cms/learninghub']">&#8592; Back</button> -->

    <section class="playlist playlist--cms section-padding">
        <h3 class="ls-subheading f-dark-blue info-container__heading">Playlist Details</h3>
        <div class="playlist__details basic-info info-container__text-container">
            <div class="edit-modal-fields-container">
                <div class="row visually-hidden">
                    <h4>ID</h4>
                    <p>{{playlist?.ID}}</p>
                </div>
                <form class="add-edit-tile-form" name="playlist-form">
                    <div class="details-container">
                        <div class="row">
                            <div class="col">
                                <h4 class="edit__title basic-info__heading setting__heading ls-subheading required-field setting__heading--onpoint-title">
                                    <div class="svg-icon svg-baseline">
                                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                                        <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                                    </svg>
                                    </div>
                                    Title
                                </h4>
                                <div class="input-container">
                                    <input type="text" name="title" [ngModel]="playlist?.Title" (ngModelChange)="playlist.Title = $event" class="text-input">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <h4 class="edit__title account-info__heading setting__heading ls-subheading required-field">Playlist Subtitle</h4>
                                <div class="input-container">
                                    <input type="text" name="subtitle" [ngModel]="playlist?.Subtitle" (ngModelChange)="playlist.Subtitle = $event" class="text-input">
                                </div>
                            </div>
                        </div>
                        <div class="row two-col">
                            <div class="col basic-info__setting">
                                <h4 class="edit__title basic-info__heading setting__heading ls-subheading required-field">
                                    <span class="svg-icon svg-baseline">
                                      <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M0 0h24v24H0z" fill="none"/>
                                          <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"/>
                                      </svg>
                                    </span>
                                    Language
                                  </h4>
                                <div class="select-container">
                                    <select name="editLang" *ngIf="playlist" [(ngModel)]="playlist.Lang"
                                        (change)="onFilterLang($event.target.value)" class="setting__select-input select-input">
                                        <option value="EN">{{emsConfig.text?.EMS_General.EN}}</option>
                                        <option value="FR">{{emsConfig.text?.EMS_General.FR}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <h4 class="edit__title basic-info__heading setting__heading ls-subheading required-field">
                                    <div class="svg-icon svg-baseline">
                                      <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/>
                                      </svg>
                                    </div>
                                    Status
                                </h4>
                                <div class="select-container">
                                    <select name="status" *ngIf="playlist" [(ngModel)]="playlist.Status" class="setting__select-input select-input">
                                        <option value="active">Active</option>
                                        <option value="archived">Archived</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <h4 class="edit__title account-info__heading setting__heading ls-subheading required-field">Playlist Description</h4>
                                <textarea id="description-text-area" name="description" [ngModel]="playlist?.Description" (ngModelChange)="playlist.Description = $event"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <h4 class="edit__title account-info__heading setting__heading ls-subheading">Playlist Full Description</h4>
                                <textarea id="fulldescription-text-area" name="fulldescription" [ngModel]="playlist?.FullDescription" (ngModelChange)="playlist.FullDescription = $event"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <h4 class="edit__title account-info__heading setting__heading ls-subheading">Default image</h4>
                                <div ng2FileDrop [ngClass]="{'nv-file-over': imageHasBaseDropZoneOver}"
                                    (fileOver)="fileOverBaseImage($event)" [uploader]="playlistImageUploader"
                                    class="well my-drop-zone upload-container__dropzone" *ngIf="!imageLoaded">
                                    Drag and drop file here
                                </div>
                                <div class="upload-setting__file-container"
                                    *ngFor="let item of playlistImageUploader.queue">
                                    <div class="upload-setting__file-name-btns"
                                        *ngIf="playlistImageUploader?.queue?.length > 0 && !imageLoaded">
                                        <input type="text" name="subtitle" [value]="item?.file?.name" class="text-input">

                                        <div class="upload-btn-container">
                                            <button type="button" class="btn btn-success btn-xs" (click)="item.upload()"
                                                (mousedown)="uploadingGifAdd()"
                                                [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                                Upload
                                            </button>
                                            <button type="button" class="btn btn-danger btn-xs" (click)="item.remove()">
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="upload-container__existing-image" *ngIf="imageLoaded">
                                    <input type="text" name="subtitle" [value]="playlist.ImageName" class="text-input">
                                    <button type="button" class="btn btn-remove btn-xs"
                                        (click)="previewImage()">Preview</button>
                                    <button type="button" class="btn btn-remove btn-xs" (click)="clearModuleImage()"
                                        *ngIf="imageLoaded">Remove</button>
                                    <button type="button" class="btn btn-remove btn-xs"
                                        (click)="removeImageFromAWS(playlist.ImageName)" *ngIf="imageLoaded">Remove
                                        uploaded image from
                                        AWS</button>
                                    <p class="image-in-queue" *ngIf="imageLoaded">* Your new image has
                                        been added to the processing queue and will replace the old image once processed.
                                        Please allow 5
                                        minutes for processing.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col checkbox  checkbox-container">
                        <label for="editclientspecific">
                            <h4 class="edit__title">Complience training</h4>
                            <input name="editclientspecific" *ngIf="playlist" type="checkbox"
                                [checked]="playlist?.Complience"
                                (change)="playlist.Complience = !playlist.Complience">
                        </label>
                    </div> -->
                </form>
            </div>
        </div>

        <h3 class="ls-subheading f-dark-blue info-container__heading">Playlist Content</h3>
        <div class="playlist__clients basic-info info-container__text-container">
            <div class="playlist__content">
                <!--Assets type specific edits -->
                <div class="asset-list-container details-container" cdkDropList (cdkDropListDropped)="drop($event)">
                    <div *ngFor="let asset of selectedAssets ; let i=index" class="two-col" cdkDrag>
                        <div class="content-container col">
                            <h4 class="edit__title account-info__heading setting__heading ls-subheading required-field">Content type</h4>
                            <div class="select-container">
                                <select [(ngModel)]="asset.AssetTypeID" class="setting__select-input select-input">
                                    <option [ngValue]="0" selected disabled>Select content type</option>
                                    <option *ngFor="let assetType of AssetTypes" [ngValue]="assetType.ID">
                                        {{assetType.TileType}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Tipsheet -->
                        <div class="asset-container col" *ngIf="asset.AssetTypeID === assetsType.Tipsheet">
                            <div class="asset-container__select">
                                <h4 class="edit__title account-info__heading setting__heading ls-subheading required-field">Tipsheet</h4>
                                <div class="select-container">
                                    <select name="editTipsheet" [(ngModel)]="asset.AssetID" class="setting__select-input select-input">
                                        <option [ngValue]="0" selected disabled>Select Tipsheet</option>
                                        <option *ngFor="let tipsheet of tipsheets" [ngValue]="tipsheet.ID">{{ tipsheet.VolumeTitle }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="asset-container__delete">
                                <button *ngIf="selectedAssets.length > 1" (click)="deleteAsset(i)" title="Delete" class="btn-delete">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.278" height="14.5" viewBox="0 0 11.278 14.5">
                                        <path id="trash"
                                            d="M16.278,3.806H13.458L12.653,3H8.625l-.806.806H5V5.417H16.278M5.806,15.889A1.611,1.611,0,0,0,7.417,17.5h6.444a1.611,1.611,0,0,0,1.611-1.611V6.222H5.806Z"
                                            transform="translate(-5 -3)" fill="#2699fb" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <!-- BlogPost -->
                        <div class="asset-container col" *ngIf="asset.AssetTypeID == assetsType.BlogPost">
                            <div class="asset-container__select">
                                <h4 class="edit__title account-info__heading setting__heading ls-subheading required-field">Blog Post</h4>
                                <div class="select-container">
                                    <select name="editBlog" [(ngModel)]="asset.AssetID" class="setting__select-input select-input">
                                        <option [ngValue]="0" selected disabled>Select Post</option>
                                        <option *ngFor="let blog of blogs" [ngValue]="blog.ID">{{ blog.PostTitle }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="asset-container__delete">
                                <button *ngIf="selectedAssets.length > 1" (click)="deleteAsset(i)" title="Delete" class="btn-delete">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.278" height="14.5" viewBox="0 0 11.278 14.5">
                                        <path id="trash"
                                            d="M16.278,3.806H13.458L12.653,3H8.625l-.806.806H5V5.417H16.278M5.806,15.889A1.611,1.611,0,0,0,7.417,17.5h6.444a1.611,1.611,0,0,0,1.611-1.611V6.222H5.806Z"
                                            transform="translate(-5 -3)" fill="#2699fb" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <!-- ATE Archives -->
                        <div class="asset-container col" *ngIf="asset.AssetTypeID === assetsType.ATEArchived">
                            <div class="asset-container__select">
                                <h4 class="edit__title account-info__heading setting__heading ls-subheading required-field">Web chat archive</h4>
                                <div class="select-container">
                                    <select name="editImage" [(ngModel)]="asset.AssetID" class="setting__select-input select-input">
                                        <option [ngValue]="0" selected disabled>Select webchat archive</option>
                                        <option *ngFor="let ate of ateArchives" [ngValue]="ate.EventID">{{ ate.EventTopic }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="asset-container__delete">
                                <button *ngIf="selectedAssets.length > 1" (click)="deleteAsset(i)" title="Delete" class="btn-delete">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.278" height="14.5" viewBox="0 0 11.278 14.5">
                                        <path id="trash"
                                            d="M16.278,3.806H13.458L12.653,3H8.625l-.806.806H5V5.417H16.278M5.806,15.889A1.611,1.611,0,0,0,7.417,17.5h6.444a1.611,1.611,0,0,0,1.611-1.611V6.222H5.806Z"
                                            transform="translate(-5 -3)" fill="#2699fb" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <!-- Video -->
                        <div class="asset-container col" *ngIf="asset.AssetTypeID ===  assetsType.Video">
                            <div class="asset-container__select">
                                <div class="row">
                                    <div class="select-container" [ngClass]="{'select-container--hidden':asset.HideCategoryOption && asset.HideVolumesOption}">
                                        <select name="editCategories" [hidden]="asset.HideCategoryOption"
                                            (change)="getVolumes(asset,$event.target.value)" class="setting__select-input select-input">
                                            <option value="" selected disabled>Select Category</option>
                                            <option *ngFor="let category of categories" [value]="category.ID">
                                                {{category.Name.English}}
                                            </option>
                                        </select>
                                        <select name="editVolumes" [hidden]="asset.HideVolumesOption"
                                            (change)="getVideosForVolume(asset,$event.target.value)" class="setting__select-input select-input">
                                            <option value="" selected disabled>Select Volume</option>
                                            <option *ngFor="let volume of asset.Volumes" [value]="volume.ID">
                                                {{volume.VolumeTitle}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="select-container">
                                        <select name="editVideos" (change)="selectVideo(asset)" [(ngModel)]="asset.AssetID" class="setting__select-input select-input">
                                            <option value="0" selected disabled>Select Video</option>
                                            <ng-container *ngIf="asset.Modules">
                                                <option *ngFor="let module of asset.Modules;" [value]="module.ID">
                                                    {{module.Title}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="asset-container__delete">
                                <button *ngIf="selectedAssets.length > 1" (click)="deleteAsset(i)" title="Delete" class="btn-delete">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.278" height="14.5"
                                        viewBox="0 0 11.278 14.5">
                                        <path id="trash"
                                            d="M16.278,3.806H13.458L12.653,3H8.625l-.806.806H5V5.417H16.278M5.806,15.889A1.611,1.611,0,0,0,7.417,17.5h6.444a1.611,1.611,0,0,0,1.611-1.611V6.222H5.806Z"
                                            transform="translate(-5 -3)" fill="#2699fb" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-button-container submit-btn">
                        <button (click)="addAsset()" class="btn module-submit">
                            Add another asset
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-button-container submit-btn">
            <button
                (click)="confirmAction('addUpdatePlaylist', 'Are you sure you want to update this Playlist?  Do not forget to sync the record')"
                type="button" class="btn module-submit">
                Submit
            </button>
        </div>

        <div class="img-preview-modal modal-window" *ngIf="showImgPreview" (click)="closePreviewModal()">
            <div class="modal-container img-modal-container">
                <div class="modal__title-container b-dark-blue">
                    <h3 class="modal__title ls-headline f-white">Playlist Image Preview</h3>
                    <button class="modal__close" (click)="closePreviewModal()">
                        <svg viewBox="0 0 24 24">
                            <path fill="#ffffff"
                                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </div>
                <div class="img-preview-content-container">
                    <div class="img-container">
                        <img [src]="imgPreviewUrl" (error)="updateImageUrl($event)" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

export class AssetSyncRequest{

assetID: number;

tableID: number;

notes: string = '';

includeChildren: boolean = false;

}

import { AccountsService } from './../../accounts/accounts.service';
import { PopupService } from 'app/shared/popup.service';
import { ConfirmService } from 'app/shared/confirm.service';

// Angular Things
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';
import { UtilitiesService } from '../../shared/utilities.service';

//  Services
import { CmsService } from '../cms.service';
import { PollJson } from './../../shared/pollJson';
import * as moment from 'moment';

@Component({
  selector: 'cms-polls', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-polls.component.html'
})

export class CmsPollsComponent implements OnInit {
  @HostBinding('class') classAttribute: string = 'cms-polls';




  public polls: PollJson[] = [];
  public

  constructor(private router: Router,
    private route: ActivatedRoute,
    public _cmsService: CmsService,
    public _emsConfig: EmsConfig,
    public _accountService: AccountsService,
    public _popupService: PopupService,
    public _utilities: UtilitiesService) {

  }

  ngOnInit(): void {
    this.getListOfPolls();
  }

  onEvent(event: any) {
    event.stopPropagation();
  }



  // Nav to the CPD Volume Editor
  navToPollDetails = function (poll: number) {
    this.router.navigate([poll], { relativeTo: this.route });
  }


  //Get list of available polls 
  getListOfPolls = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    this._cmsService.getListOfPolls()
      .subscribe((res: PollJson[]) => {
        this.polls = res;
        console.log(this.polls);
        this.polls.forEach((p: any) => {
          p.Poll_Details = JSON.parse(p.Poll_Details);
          if (new Date(p.Start_Date) >= today) {
            p.ExpiredPoll = false;
          } else {
            p.ExpiredPoll = true;
          }
        });
      });
  }



}

// Angular Things

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, JsonpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmsConfig } from '.././shared/emsConfig';
import { Playlist } from 'app/shared/objects/playlist';



@Injectable()
export class LearningHubService {

    private _playlistsUrl = this.emsConfig.apiEndpoint + "/playlist/getallplaylists";
    private _playlistDetailsUrl = this.emsConfig.apiEndpoint + "/playlist/getplaylistassetsdetails?id=";
    private _getclientspecificplaylistdetailsUrl = this.emsConfig.apiEndpoint + "/playlist/getclientspecificplaylistdetails";
    private _clientPlaylistsUrl = this.emsConfig.apiEndpoint + "/playlist/getclientplaylists?clientname=";
    private _clearClientPlaylistCustomizationUrl = this.emsConfig.apiEndpoint + "/playlist/clearclientplaylistcustomization";
    private _addUpdatePlaylistUrl = this.emsConfig.apiEndpoint + "/playlist/addupdateplaylist";
    private _updateclientspecificplaylistUrl = this.emsConfig.apiEndpoint + "/playlist/updateclientspecificplaylist";
    private _addplaylisttoclientUrl = this.emsConfig.apiEndpoint + "/playlist/addplaylisttoclient";
    private _deletePlaylistforClientUrl = this.emsConfig.apiEndpoint + "/playlist/deleteplaylistparicipation";
    constructor(private _httpClient: HttpClient, private emsConfig: EmsConfig) { }

    // gets list of all the playlist
    getAllPlayLists(): Observable<any> {
        return this._httpClient.get<any>(this._playlistsUrl);
    }
    // gets details of given playlist
    getPlayListDetails(id: number): Observable<any> {
        return this._httpClient.get<any>(this._playlistDetailsUrl + id.toString());
    }

    getClientSpecificPlaylistDetails(id: number, participationid: number, client: string): Observable<any> {
        const params = new HttpParams().set('playlistid', id.toString()).set('client', client).set('participationid', participationid.toString());

        return this._httpClient.get<any>(this._getclientspecificplaylistdetailsUrl, {
            params: params
        });
    }
    //gets lists of client's playlist
    getClientPlayLists(client: string): Observable<any> {
        return this._httpClient.get<any>(this._clientPlaylistsUrl + client);
    }
    //deletes client's customization for playlist
    clearClientPlaylistCustomization(id: number, client: string, participationid: number): Observable<any> {
         return this._httpClient.post<Playlist>(this._clearClientPlaylistCustomizationUrl+'?id='+id.toString()+'&client='+client+'&participationid='+participationid.toString(),'');
    }

    addUpdatePlaylist(playlist: Playlist): Observable<Playlist> {
        const body = JSON.stringify(playlist);
        return this._httpClient.post<Playlist>(this._addUpdatePlaylistUrl, body);
    }
    updateClientSpecificPlaylist(playlist: Playlist): Observable<Playlist> {
        const body = JSON.stringify(playlist);
        return this._httpClient.post<Playlist>(this._updateclientspecificplaylistUrl, body);
    }
    addPlaylistToClient(playlistid: number, client: string): Observable<any> {
        const params = new HttpParams().set('playlistid', playlistid.toString()).set('client', client);
        return this._httpClient.get<any>(this._addplaylisttoclientUrl, {
            params: params
        });
    }
    //Delete playlist from client's account
    deletePlaylistParticipation(playlistid: number, participationid: number, client: string): Observable<any> {
        const params = new HttpParams().set('playlistid', playlistid.toString()).set('client', client).set('participationid', participationid.toString());
        return this._httpClient.get<any>(this._deletePlaylistforClientUrl, {
            params: params
        });
    }
    generateUsageReport(client:string,participationid:number,startdate:string,enddate:string): void {
      const url = this.emsConfig.apiReportingEndpoint + '/getplaylistusage?token=' +
      this.emsConfig.loginToken.Token + '&client=' + client+'&participationid='+
        participationid+'&startdate='+startdate+'&enddate='+enddate;
      window.open(url, '_blank');
    }
}

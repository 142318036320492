<div class="cms-campaign-list ems5">
    <div class="page-ribbon">
			<div class="page-header f-dark-blue ls-subheading">Campaigns</div>
    </div>
    <div class="campaign__listing-container section-padding">
			<section class="campaign__listing-section info-container">
				<div class="info-container__text-container">
					<table class="flex-table campaign__listing--table">
						<thead class="f-blue">
							<tr>
								<th class="id">ID</th>
								<th class="campaign-title">Title</th>
								<th class="date">Start Date</th>
								<th class="date">End Date</th>
								<th class="date">Preview Date</th>
							</tr>
						</thead>
						<tbody class="f-dark-charcoal ls-body-2">
							<ng-container *ngFor="let campaign of campaigns">
								<tr>
									<td data-label="ID" class="id">{{ campaign.ID }}</td>
									<td data-label="Title" class="campaign-title">
										<a [routerLink]="['/cms/campaigns/', campaign.ID]">{{ campaign.Title }}</a>
									</td>
									<td data-label="Start Date" class="date">{{ campaign.StartDate | date: 'MM/dd/yyyy'}}</td>
									<td data-label="End Date" class="date">{{ campaign.EndDate | date: 'MM/dd/yyyy'}}</td>
									<td data-label="Preview Date" class="date">{{ campaign.DefaultPreviewDate | date: 'MM/dd/yyyy'}}</td>
								</tr>
							</ng-container>
						</tbody>
					</table>
				</div>
			</section>
			<div class="btn-container">
				<button [routerLink]="[-1]" class="btn--fab b-dark-blue" title="Add new campaign">
					<svg viewBox="0 0 24 24">
						<path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
					</svg>
				</button>
			</div>
    </div>
</div>

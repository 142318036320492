<div class="iq-details-container section-padding">
  <h3 class="ls-headline ls-display f-dark-blue">{{quizDetails?.Title}}</h3>
  <syncstatus [assetID]="quizDetails?.ID" [tableID]="18" ></syncstatus>
  <div class="details-container">
    <div class="iq-quiz-container info-container__text-container" *ngFor="let clip of quizDetails?.Clips; let c = index">
      <button type="button" class="f-dark-blue ls-title clip-container__clip-title" (click)="showQuestions(c)">{{clip.Title}}</button>
      <div class="clip__questions-container" *ngIf="c == selectedClip">
        <div class="question" *ngFor="let question of clip.Questions; let i = index">
          <p class="ls-subheading question-listing">Question {{i + 1}}</p>
          <form action="addEditQuestion" class="addEditQuestion" (ngSubmit)="iqSubmitQuestion(question, c)">
            <div class="form-split left-split">
              <input type="text" hidden name="questionID{{i + 1}}" [(ngModel)]="question.ID">
              <input type="text" hidden name="clipID{{i + 1}}" [(ngModel)]="question.MediaClipId">
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z" />
                    </svg>
                  </div>
                  Question
                </h4>
                <div class="input-container">
                  <input type="text" class="basic-info__text setting__text-input text-input" name="question{{i + 1}}" [(ngModel)]="question.Question">
                </div>
              </div>
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                        <path clip-path="url(#b)" d="M4 9h16v2H4zm0 4h10v2H4z"/>
                    </svg>
                  </div>
                  Explanation
                </h4>
                <div class="input-container explanation-input-container">
                  <textarea type="text" class="basic-info__text setting__text-input textarea-input explanation-textarea" name="explanation{{i + 1}}" [(ngModel)]="question.Explanation" rows="4"></textarea>
                </div>
              </div>
            </div>
            <div class="form-split right-split">
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path d="M3,13H15V11H3M3,6V8H21V6M3,18H9V16H3V18Z" />
                    </svg>
                  </div>
                  Order
                </h4>
                <div class="input-container">
                  <input type="text" class="basic-info__text setting__text-input text-input" name="rank{{i + 1}}" [(ngModel)]="question.Rank">
                </div>
              </div>
            </div>

            <div class="form-button-container">
              <button type="submit" class="btn iq-question-submit">
                Save Question
              </button>
              <button type="button" class="btn" (click)="showAnswers(i, c)" *ngIf="question.Answers?.length > 0">
                View Answers
              </button>
              <button type="button" class="btn" (click)="showAnswers(i, c)" *ngIf="question.Answers?.length === 0">
                Create Answers
              </button>
              <button type="button" class="btn iq-question-delete" (click)="openConfirmDialog('iqDetails', 'deleteQuestion', question.ID, _emsConfig.text?.EMS_General.Confirm_Delete_Message)">
                Delete Question
              </button>
            </div>
          </form>

          <div class="answers-container" *ngIf=" 'clip' + c +  'question' + i  == selectedAnswers">
            <div class="answer-labels">
              <h4 class="basic-info__heading setting__heading ls-subheading answer-title">
                Answer
              </h4>
              <h4 class="basic-info__heading setting__heading ls-subheading answer-rank">
                Rank
              </h4>
              <h4 class="basic-info__heading setting__heading ls-subheading answer-correct">
                Correct?
              </h4>
              <h4 class="basic-info__heading setting__heading ls-subheading answer-delete">
                Delete?
              </h4>
            </div>
            <form class="answer">
              <div class="answer-fieldset" *ngFor="let answer of question.Answers; let a = index ">
                <input type="text" hidden name="questionID{{i + 1}}" [(ngModel)]="answer.ID">
                <div class="basic-info__setting answer-title">
                  <div class="input-container"><input type="text" class="basic-info__text setting__text-input text-input" name="answer{{a + 1}}" [attr.answerId]="answer.ID" [(ngModel)]="answer.Answer" #currentField></div>
                </div>
                <div class="basic-info__setting answer-rank">
                  <div class="input-container"><input type="text" class="basic-info__text setting__text-input text-input" name="answerOrder{{a + 1}}" [attr.answerId]="answer.ID" [(ngModel)]="answer.Rank" #currentField></div>
                </div>
                <div class="basic-info__checkbox-container answer-correct">
                  <!-- parameter passed to correctAnswer method are i = question index; c = clip index -->
                  <button class="btn-checkbox"
                          [ngClass]="{'isCorrect': answer.IsCorrect}"
                          [attr.answerId]="answer.ID" #currentField
                          (click)="setCorrectAnswer(currentField, i, c)">
                  </button>
                </div>
                <div class="basic-info__checkbox-container answer-delete">
                  <div class="checkbox-container">
                    <div class="ls-checkbox">
                      <input name="answerDelete{{a + 1}}" type="checkbox" id="answerDelete{{a + 1}}">
                      <label for="answerDelete{{a + 1}}" (click)="selectAnswerForDelete(answer.ID)"><span></span></label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-button-container" *ngIf="i != createNewAnswer">
                <button (click)="iqSubmitAnswers(question.Answers)"
                  type="button" class="btn iq-question-submit">
                  Save Answers
                </button>
                <button type="button" class="btn" (click)="createAnswer(i, question.ID, c)">
                  Add Answer
                </button>
                <button type="button" class="btn iq-answers-delete" (click)="openConfirmDialog('iqDetails', 'deleteAnswers', null, _emsConfig.text?.EMS_General.Confirm_Delete_Message)" [disabled]="disableFormButtons">
                  Delete Answers
                </button>
              </div>
            </form>

            <form class="answer new-answer-container" *ngIf="i == createNewAnswer">
              <div class="answer-fieldset">
                <div class="basic-info__setting answer-title">
                  <div class="input-container"><input type="text" class="basic-info__text setting__text-input text-input" placeholder="Enter new answer" name="newAnswer" [(ngModel)]="newAnswer.Answer"></div>
                </div>
                <div class="basic-info__setting answer-rank">
                  <div class="input-container"><input type="text" class="basic-info__text setting__text-input text-input" placeholder="Rank" name="newAnswerOrder" [(ngModel)]="newAnswer.Rank"></div>
                </div>
                <div class="basic-info__checkbox-container answer-correct">
                  <div class="checkbox-container">
                    <div class="ls-checkbox">
                      <input name="newAnswerCorrect" [(ngModel)]="newAnswer.IsCorrect" type="checkbox" id="newAnswerCorrect" value=true>
                      <label for="newAnswerCorrect"><span></span></label>
                    </div>
                    <!-- Correct? -->
                  </div>
                </div>
                <div class="basic-info__checkbox-container answer-delete" style="opacity: 0;">
                  <div class="checkbox-container">
                    <div class="ls-checkbox" >
                      <input name="newAnswerDelete" type="checkbox" id="newAnswerDelete" disabled>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-button-container">
                <button (click)="iqCreateAnswer(newAnswer,question.Answers)" type="button" class="btn iq-question-submit">
                  Save Answer
                </button>
                <button (click)="clearNewAnswer()" type="button" class="btn iq-question-submit">
                  Clear
                </button>
              </div>
            </form>

          </div>
        </div>

        <!-- add new question -->
        <div class="clip-container" *ngIf="c == createIqQuestion" style="margin: 10px;">
          <div class="clip__questions-container">
            <div class="question">
              <p class="ls-subheading question-listing">Question</p>
              <form action="addEditQuestion" class="addEditQuestion" (ngSubmit)="iqCreateQuestion(newQuestion, c)" #addIqQuestionForm="ngForm">
                <div class="form-split left-split">
                  <div class="basic-info__setting">
                    <h4 class="basic-info__heading setting__heading ls-subheading">
                      <div class="svg-icon svg-baseline">
                        <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                          <path d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z"
                          />
                        </svg>
                      </div>
                      Question
                    </h4>
                    <div class="input-container">
                      <input type="text" class="basic-info__text setting__text-input text-input" name="newQuestion{{c + 1}}" [(ngModel)]="newQuestion.Question">
                    </div>
                  </div>
                  <div class="basic-info__setting">
                    <h4 class="basic-info__heading setting__heading ls-subheading">
                      <div class="svg-icon svg-baseline">
                        <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                          <path clip-path="url(#b)" d="M4 9h16v2H4zm0 4h10v2H4z" />
                        </svg>
                      </div>
                      Explanation
                    </h4>
                    <div class="input-container explanation-input-container">
                      <textarea type="text" class="basic-info__text setting__text-input textarea-input explanation-textarea" name="newExplanation{{c + 1}}" [(ngModel)]="newQuestion.Explanation" rows="4"></textarea>
                    </div>
                  </div>
                </div>
                <div class="form-split right-split">
                  <div class="basic-info__setting">
                    <h4 class="basic-info__heading setting__heading ls-subheading">
                      <div class="svg-icon svg-baseline">
                        <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                          <path d="M3,13H15V11H3M3,6V8H21V6M3,18H9V16H3V18Z" />
                        </svg>
                      </div>
                      Order
                    </h4>
                    <div class="input-container">
                      <input type="text" class="basic-info__text setting__text-input text-input" name="newRank{{c + 1}}" [(ngModel)]="newQuestion.Rank">
                    </div>
                  </div>
                </div>

                <div class="form-button-container">
                  <button type="submit" class="btn iq-question-create">
                    Create Question
                  </button>
                  <button (click)="clearNewQuestion()" type="button" class="btn iq-question-submit">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- end add new question -->
        <button (click)="createQuestion(c, clip.ID)" type="button" class="btn" *ngIf="c != createIqQuestion">
          Add Question
        </button>
      </div>

    </div>


  </div>
</div>

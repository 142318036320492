import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { SyncService } from 'app/sync/sync.service';
import { PopupService } from 'app/shared/popup.service';
import { EmsConfig } from 'app/shared/emsConfig';
import { ConfirmService } from 'app/shared/confirm.service';
import { SyncRequest } from "app/sync/sync-request";
import { SyncResult } from "app/sync/sync-result";
import { SyncOperation } from "app/sync/sync-operation";
import { Observable } from 'rxjs';


@Component({
  selector: 'assetSync',
  templateUrl: 'asset-sync.component.html'
})

export class AssetSyncComponent implements OnInit, OnDestroy {

  public assetOperations: any[];
  public SyncOperations: SyncOperation[];
  public Result: SyncResult;

  private confirmSyncOperation: string;
  private confirmData = {};
  private errorMessage: any;
  public formattedSyncInfo: any[] = [];
  public modalOpen: boolean = false;
  private confirmSub:any;

  constructor(private syncService: SyncService, private popupService: PopupService, public emsConfig: EmsConfig, private confirmService: ConfirmService) {
  }

  ngOnInit() {

    this.confirmSub = this.confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          this.executeSync(this.confirmSyncOperation);
        } else {
          console.log('cancelled');

        }
      }
    );

    this.syncService.getAssetOperations().subscribe(data => {
      // get the operations and try to parse the json.  If it can't be parsed, it's likely an error so display
      try {
        this.assetOperations = data;
      } catch (ex) {

        window.alert(`The following error has occured. Please send a screenshot to the admin ${ex.toString()}`);
      }

    });
  }


  ngOnDestroy() {
    this.confirmSub.unsubscribe();
  }

  public getSyncInfo(operationName: string): void {
    this.syncService.getSyncInfoForAssets(operationName)
      .subscribe(
      info => {
      this.modalOpen = true;
        this.formatSyncInfo((<any>Object).entries(info));

      }
      );
  }

  public closeModal() {
    this.modalOpen = false;
  }

  public stopProp(event: any) {
    event.stopPropagation();
  }

  public openConfirmDialog(action: string, message: string): void {
    this.confirmSyncOperation = action;


    this.confirmData = { message: message };
    this.confirmService.updateNotification(this.confirmData);
  }

  public executeSync(operationName: string) {
    const request: SyncRequest = new SyncRequest();
    request.OperationName = operationName;
    if (request.OperationName !== '') {
      this.executeSyncForAsset(request);
    }
  }


  private formatSyncInfo(syncInfo: any[]): void {
    // clear out any values in the formatted table
    this.formattedSyncInfo = [];

    // create new array to store the formattedValues
    const self = this;

    // iterate through all the data in the array and add to the table
    syncInfo.forEach((entry: any[]) => {
      const key = entry[0].split('__')[0];

      // if that table already exists in the info, add it now, otherwise just contact the value
      let operationVal = self.formattedSyncInfo.find(e => e.Table === key);
      if (operationVal === undefined) {
        self.formattedSyncInfo.push({ Table: key, Records: entry[1] });
      } else {
        operationVal = operationVal.Records += ` ,${entry[1]}`;
      }


    })

  }



  private executeSyncForAsset(operation: SyncRequest): void {

    // this won't be necessary once I figure out how to unsubscribe
    if(operation.OperationName === undefined){
      return;}


    this.syncService.executeAssetSyncOperation(operation)
      .subscribe(
      SyncOperationItem => {
        this.Result = SyncOperationItem;
        this.popupService.updateNotification({ message: this.Result.Message, success: this.Result.Status });
      },
      err => {
        this.errorMessage = <any>err
        this.popupService.updateNotification({ message: 'An error occured.', success: false });
      }
      );
  }

}

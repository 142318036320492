// angular things
import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// config
import { EmsConfig } from '../shared/emsConfig';

@Component({
  selector: 'cms', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms.component.html'
})

export class CmsComponent {

  closeNav: boolean = false;
  closeSubNav: any = {};
  active: any = {};


  constructor(public _emsConfig: EmsConfig) { }

  toggleSubNav(type: string): void {
    this.closeSubNav[type] = !this.closeSubNav[type];
    this.active[type] = !this.active[type];
  }

  
  stopProp(event: any): void {
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.closeSubNav.canadareports = true;
    this.active.canadareports = false;
  }

}

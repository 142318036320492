<div class="cms-page-container inner-page-container sync-page">
    <div class="vertical-nav" [ngClass]="{closed: closeNav}">
        <button class="vertical-nav__toggle pointer" (click)="closeNav = !closeNav">
            <svg class="close" fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/><path d="M0-.5h24v24H0z" fill="none"/></svg>
            <svg class="open" fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/><path d="M0-.25h24v24H0z" fill="none"/></svg>
        </button>
        <ul>

            <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/sync', 'organization']" [routerLinkActive]="['active']">Sync Organizations </li>
        </ul>
    </div>
    <div class="sync-component inner-page-main">
        <router-outlet></router-outlet>
    </div>
</div>

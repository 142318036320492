<div class="section-padding">
  <h1>LifeSpeak Polls</h1>

  <table class="display-table display-table--extra-padding" id="poll-list">
    <thead>
      <tr>
        <th>Poll ID</th>
        <th>Poll Details</th>
        <th>Start date</th>
        <th>End Date</th>
        <th>Type</th>
        <!-- <th>Delete Poll</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pd of polls">
        <td>{{ pd.Poll_ID}}</td>
        <td class="edit-link" (click)="navToPollDetails(pd.Poll_ID)">
          {{ pd.Poll_Details.Poll.Language['EN'][0].Questions[0].Question}}
        </td>
        <td [ngStyle]="!pd.ExpiredPoll?{'background-color':'#CFEAE4'} : {'background-color': '#FBE1DD'}">
          {{pd.Start_Date | date: 'dd-MMM-yyyy hh:mm'}}</td>
        <td>{{pd.End_Date | date: 'dd-MMM-yyyy hh:mm'}}</td>
        <td *ngIf="pd.Global_poll">Global</td>
        <td *ngIf="pd.Global_poll===false">{{pd.Client}}</td>
        <!-- <td class="title-size edit-link"> 
            <button class="btn ls-button-1"
            (click)="confirmAction('deletePoll','Are you sure?',pd.Poll_ID)">Delete</button>
          </td> -->

      </tr>
    </tbody>
  </table>

  <button class="btn--fab b-dark-blue cms-container__fab cms-container__fab--add" (click)="navToPollDetails(-1)">
    <svg viewBox="0 0 24 24">
      <path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" /></svg>
  </button>
</div>
export enum AiPromptType {
    Keywords = 0,
    Subcategories = 1,
    Tags = 2,
    Transcript = 3,
    Subtitles = 4
}

export class AiPrompts {
    Keywords: string = "Extract 20 keywords from provided text and format in a comma separated list.";
    Subcategories: string = "";
    Tags: string = "";
    Transcript: string = "Generate a transcript from the provided text.";

    constructor(list: string) {
        this.Subcategories = "Categories: [" + list + "]\n\nCategorize the provided text using any applicable categories from the above list.\nFormat in a comma separated list.";
        this.Tags = "Categorize the provided text using only the applicable tags from the following list:\n[" + list + "]\n\nFormat in a comma separated list.";
    }
}

export class AiChatData {
    showPrompt?: boolean;
    prompt?: string;
    promptPlaceholder?: string;
    showTemperature?: boolean;
    temperature?: number;
    ctaText?: string;
    tooltipMessage?: string;
}

export class AudioResponseFormat {
    static JSON: string = "json";
    static Text: string = "text";
    static SRT: string = "srt";
    static VerboseJson: string = "verbose_json";
    static VTT: string = "vtt";
}

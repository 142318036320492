import { AteQuestion } from './AteQuestion';
import { TextItem } from '../../shared/TextItem';

export class AteAnswer extends AteQuestion {
  DateAnswered: string;
  Answer: string;
  DateSent: string;
  DueDateFormatted: string;
  SendToAdmin: boolean;
  DueSoon: boolean;
  SendBackNotes: string;
  ExpertResend: boolean;

}

import { TextItem } from '.././shared/TextItem';
import { ExpertBiography } from './objects/expertBiography';

export class Expert {
  ExpertID: any;
  ExpertName: TextItem = new TextItem();
  ExpertNameFormatted: string;
  ExpertShortBio: string;
  FullBio: string;
  FirstName: string;
  LastName: string;
  Title: string;
  PhotoUrl: string;
  Email: string;
  PhoneNumber: string;
  Notes: string;
  Royalty: boolean;
  ConnexStatus: string = '';
  TimeZone: string;
  Lang: string;
  Username: string;
  ConnexCategories: number[];
  NormalizedName: string;
  DoNotContact: boolean;
  Spoken_Languages: string;
  Address: string;
  Province: string;
  PostalCode: string;
  Do_Not_Contact_Reason: string;
  Does_LiveEvent: boolean;
  ContractSigned: boolean;
  VerificationEmailSent: boolean;
  RatePerVolume: number;
  RatePerBlogPost: number;
  RatePerATE: number;
  RatePerPodcast: number;
  RatePerLiveEvent: number;
  PaymentTermsNetDays: number;
  Currency: string;
  EBillInstructions: string;
  ExpertiseNotes: string;
  GeneralComments: string;
  IsDefaultLang: boolean;
  ShortBio: string;
  ExpertBiography: ExpertBiography[];
  IncludeInContentFusion:boolean;
  public get RatePerVolumeFormatted(): string {
    return this.RatePerVolume ? this.RatePerVolume.toLocaleString() : '0';
  }

  // strip out all the currency information (aside from periods for cents) and apply to Annual Revenue
  public set RatePerVolumeFormatted(val: string) {
    const rate = val.replace(/[^\d\.]/g, '');
    this.RatePerVolume = parseInt(rate);
  }

  public get RatePerBlogPostFormatted(): string {
    return this.RatePerBlogPost ? this.RatePerBlogPost.toLocaleString() : '0';
  }

  // strip out all the currency information (aside from periods for cents) and apply to media rate
  public set RatePerBlogPostFormatted(val: string) {
    const rate = val.replace(/[^\d\.]/g, '');
    this.RatePerBlogPost = parseInt(rate);
  }

  public get RatePerATEFormatted(): string {
    return this.RatePerATE ? this.RatePerATE.toLocaleString() : '0';
  }

  // strip out all the currency information (aside from periods for cents) and apply to media rate
  public set RatePerATEFormatted(val: string) {
    const rate = val.replace(/[^\d\.]/g, '');
    this.RatePerATE = parseInt(rate);
  }

  public get RatePerPodcastFormatted(): string {
    return this.RatePerPodcast ? this.RatePerPodcast.toLocaleString() : '0';
  }

  // strip out all the currency information (aside from periods for cents) and apply to media rate
  public set RatePerPodcastFormatted(val: string) {
    const rate = val.replace(/[^\d\.]/g, '');
    this.RatePerPodcast = parseInt(rate);
  }

  public get RatePerLiveEventFormatted(): string {
    return this.RatePerLiveEvent ? this.RatePerLiveEvent.toLocaleString() : '0';
  }

  // strip out all the currency information (aside from periods for cents) and apply to  media rate
  public set RatePerLiveEventFormatted(val: string) {
    const rate = val.replace(/[^\d\.]/g, '');
    this.RatePerLiveEvent = parseInt(rate);
  }

  public get PaymentTermsNetDaysFormatted(): string {
    return this.PaymentTermsNetDays ? this.PaymentTermsNetDays.toLocaleString() : '0';
  }

  // strip out all the currency information (aside from periods for cents) and apply to net terms days
  public set PaymentTermsNetDaysFormatted(val: string) {
    const rate = val.replace(/[^\d\.]/g, '');
    this.PaymentTermsNetDays = parseInt(rate);
  }
}

<div class="cms-canada-report-build-container section-padding">
  <h2 class="ls-subheading f-dark-blue info-container__heading">Build Canada Reports</h2>
  <div class="info-container__notes-container">
    <ul>
      <li><b>Save Historical Data</b> checkbox will be shown only when you select the period from April 1st to September
        30th (same year) Or April 1st to March 31st(next year).</li>
      <li> Check <b>Save Historical Data</b> if you want to save department's/ departments' total usages for
        current year & use it in next year's report as <b>Previous year's total usage</b>.</li>
      <li>Note, saving historical data must be done at least twice per year. Once for the half year report and once for the full year report.
        We recommend running the report a few times and then saving historical data once you are familiar and comfortable with the reporting numbers.
        These numbers will be used to populate the comparison value in next years report. So it is mandatory to complete this step.</li>
    </ul>
  </div>
    <div class="info-container__text-container canada-rreport-build-container">
    <form action="" class="edit-report-build">
      <div class="date-section-container">
        <div class="basic-info__setting">
          <h4 class="basic-info__heading setting__heading ls-subheading required-field">
            <div class="svg-icon svg-baseline">
              <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                <path d="M19,19H5V8H19M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M16.53,11.06L15.47,10L10.59,14.88L8.47,12.76L7.41,13.82L10.59,17L16.53,11.06Z" />
              </svg>
            </div>
            Report Start Date
          </h4>
          <input type="date" [ngModel]="buildItem.StartDate | date:'yyyy-MM-dd'" (ngModelChange)="buildItem.StartDate = $event" name="reportStartDate">
        </div>
        <div class="basic-info__setting">
          <h4 class="basic-info__heading setting__heading ls-subheading required-field">
            <div class="svg-icon svg-baseline">
              <svg fill="#BBBBBB" height="20"  viewBox="0 0 24 24">
                  <path d="M19,19H5V8H19M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M9.31,17L11.75,14.56L14.19,17L15.25,15.94L12.81,13.5L15.25,11.06L14.19,10L11.75,12.44L9.31,10L8.25,11.06L10.69,13.5L8.25,15.94L9.31,17Z" />
              </svg>
            </div>
            Report End Date
          </h4>
          <input type="date" [ngModel]="buildItem.EndDate | date:'yyyy-MM-dd'" (ngModelChange)="buildItem.EndDate = $event" name="reportEndDate">
        </div>

        <div class="radio-container" *ngIf="DisplayHistoricalData">
          <div class="ls-checkbox">
            <input name="save-data" type="checkbox" id="save-data" [(ngModel)]="buildItem.SaveHistoricalData">
            <label for="save-data">
              <span></span>
            </label>
          </div>
          Save Historical Data
        </div>
      </div>
      <div class="basic-info__setting report-template-setting">
        <h4 class="basic-info__heading setting__heading ls-subheading required-field">
          <div class="svg-icon svg-baseline">
            <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
              />
            </svg>
          </div>
          Report Template
        </h4>
        <div class="build-messages-container">
          <div class="select-container">
            <select class="setting__select-input select-input" name="reportTemplate" [(ngModel)]="buildItem.TemplateName">
              <option value="{{template.TemplateTitle}}" *ngFor="let template of templates">{{template.TemplateTitle}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="basic-info__setting report-type-setting">
        <h4 class="basic-info__heading setting__heading ls-subheading required-field">
          <div class="svg-icon svg-baseline">
            <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
              />
            </svg>
          </div>
          Report Type
        </h4>
        <div class="radio-btns-container">
          <div class="radio-container">
            <div class="ls-radio">
              <input name="report-type" type="radio" id="report-type-1" [(ngModel)]="buildItem.ReportType" (ngModelChange)="toggleDepartmentField($event)" [value]="'single'">
              <label for="report-type-1">
                <span></span>
              </label>
            </div>
            Single Department
          </div>
          <div class="radio-container">
            <div class="ls-radio">
              <input name="report-type" type="radio" id="report-type-2" [(ngModel)]="buildItem.ReportType" (ngModelChange)="toggleDepartmentField($event)" [value]="'all'">
              <label for="report-type-2">
                <span></span>
              </label>
            </div>
            All Departments
          </div>
        </div>
      </div>

      <div class="basic-info__setting" *ngIf="showDeptField">
        <h4 class="basic-info__heading setting__heading ls-subheading">
          <div class="svg-icon svg-baseline">
            <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
              <path d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z"
              />
            </svg>
          </div>
          Department
        </h4>
        <!-- <div class="input-container">
          <input type="text" class="basic-info__text setting__text-input text-input" name="reportType" placeholder="Canada department number" [(ngModel)]="buildItem.DeptNum">
        </div> -->
        <div class="build-messages-container">
          <div class="select-container">
            <select class="setting__select-input select-input" name="deptNum" [(ngModel)]="buildItem.DeptNum">
              <option value="{{dept.DepartmentNum}}" *ngFor="let dept of canadaDepts">{{dept.DepartmentName}} ({{dept.DepartmentNum}})</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-button-container">
        <button (click)="openConfirmDialog('buildcanadareports', 'buildCanadaReport', buildItem, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
          type="button" class="btn">
          Build Report/s
        </button>
      </div>
    </form>
  </div>
</div>

<div class="organization-sync-container section-padding">
  <h1>{{emsConfig.text?.EMS_Sync.Sync_Org_Title}}</h1>
  <p><strong>* {{emsConfig.text?.EMS_Sync.Intro}}</strong></p>

  <div class="sort-container">
    <div class="sort-container__searchbar">
      <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
      <input class="searchbar__input" [(ngModel)]="accountSearch" type="search"
        placeholder="{{emsConfig.text?.EMS_Accounts.Search_Account}}">
    </div>

    <div class="sort-container__list">
      <p class="ls-body-2">{{emsConfig.text?.EMS_General.Sort}}</p>
      <ul class="sort-container__options">
        <li class="sort-container__list-item pointer" [ngClass]="{active: activeTableFilter === 'Creator'}"
          (click)="updateSyncTable('Creator')">{{emsConfig.text?.EMS_Sync.Sync_Creator}}</li>
        <li class="sort-container__list-item pointer" [ngClass]="{active: activeTableFilter === 'DateCreated'}"
          (click)="updateSyncTable('DateCreated')">{{emsConfig.text?.EMS_Sync.Sync_Date_Created}}</li>
        <li class="sort-container__list-item pointer" [ngClass]="{active: activeTableFilter === 'OrganizationName'}"
          (click)="updateSyncTable('OrganizationName')">{{emsConfig.text?.EMS_Sync.Sync_Organization}}</li>
        <li class="sort-container__list-item pointer" [ngClass]="{active: activeTableFilter === 'Description'}"
          (click)="updateSyncTable('Description')">{{emsConfig.text?.EMS_Sync.Sync_Description}}</li>
      </ul>
    </div>
  </div>
  <span class="cacheclear-icon">
    <svg class="magic-circle" viewBox="0 0 60 55" width="50" height="50" xmlns="http://www.w3.org/2000/svg">
      <path fill="#00D1B2"
        d="m19.2 36.4-4.75-10.45L4 21.2l10.45-4.75L19.2 6l4.75 10.45L34.4 21.2l-10.45 4.75ZM36.4 42l-2.35-5.25-5.25-2.35 5.25-2.4 2.35-5.2 2.4 5.2 5.2 2.4-5.2 2.35Z" />
    </svg>Instant cache clear feature
  </span>
  <table class="display-table sync-organization-table display-table--extra-padding">
    <thead>
      <tr>
        <th>{{emsConfig.text?.EMS_Sync.Sync_ID}} </th>
        <th>{{emsConfig.text?.EMS_Sync.Sync_Creator}} </th>
        <th>{{emsConfig.text?.EMS_Sync.Sync_Date_Created}}</th>
        <th>{{emsConfig.text?.EMS_Sync.Sync_Organization}}</th>
        <th>{{emsConfig.text?.EMS_Sync.Sync_Description}}</th>
        <th class="tooltip-column">{{emsConfig.text?.EMS_Sync.EMS_Url}}
          <tooltip [tooltipMessage]="emsConfig.text?.EMS_Sync.EMS_Url_Tooltip"></tooltip>
        </th>
        <th class="tooltip-column">{{emsConfig.text?.EMS_Sync.Sync_Url}}
          <tooltip [tooltipMessage]="emsConfig.text?.EMS_Sync.EMS_Sync_Tooltip"></tooltip>
        </th>
        <th class="tooltip-column">{{emsConfig.text?.EMS_Sync.Sync_Changes}}
          <tooltip [tooltipMessage]="emsConfig.text?.EMS_Sync.Sync_Changes_Tooltip"></tooltip>
        </th>
        <th class="tooltip-column">{{emsConfig.text?.EMS_Sync.Revert_Changes}}
          <tooltip [tooltipMessage]="emsConfig.text?.EMS_Sync.Revert_Changes_Tooltip"></tooltip>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let op of SyncOperations | syncTableFilter: activeTableFilter | syncOrgNameFilter:accountSearch">
        <td>{{op.ID}}</td>
        <td>{{op.Creator}}</td>
        <td>{{op.DateCreated}}</td>
        <td>{{op.OrganizationName}}</td>
        <td><a (click)="getSyncInfo(op.ID)" href="javascript:void(0);"> {{op.Description}}</a>
          <span class="cacheclear-icon" *ngIf="op.InstantCacheClear">
            <svg viewBox="0 0 60 55" width="50" height="50" class="magic-circle column" xmlns="http://www.w3.org/2000/svg">
              <path fill="#00D1B2"
                d="m19.2 36.4-4.75-10.45L4 21.2l10.45-4.75L19.2 6l4.75 10.45L34.4 21.2l-10.45 4.75ZM36.4 42l-2.35-5.25-5.25-2.35 5.25-2.4 2.35-5.2 2.4 5.2 5.2 2.4-5.2 2.35Z" />
            </svg>
          </span>
        </td>
        <td><a href="#{{op.EmsUrl}}">{{emsConfig.text?.EMS_Sync.EMS_Url}}</a></td>
        <td><a target="_blank" href="{{op.StagingUrl}}">{{emsConfig.text?.EMS_Sync.Sync_Url}}</a></td>
        <td *ngIf="emsConfig.UserRole==='systemadmin' || op.MinRoleRequired==='accountadmin'"><button
            class="sync-table-btn"
            (click)="openConfirmDialog('sync', 'sync', op.ID,op.OrganizationName, 'Are you sure you want to sync <strong>' + op.Description + '</strong> for <strong>' + op.OrganizationName + '</strong>?',op.Name)">
            {{emsConfig.text?.EMS_Sync.Sync_Changes}}</button></td>
        <td *ngIf="emsConfig.UserRole==='accountadmin' &&  op.MinRoleRequired==='systemadmin'">
          {{emsConfig.text?.EMS_Sync.Pending}}</td>
        <td><button class="sync-table-btn"
            (click)="executeSync(op.ID,true,op.OrganizationName,op.Name)">{{emsConfig.text?.EMS_Sync.Revert_Changes}}</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!--modal for displaying information about a given sync task-->
<div class="modal-window sync-description-modal" *ngIf='modalOpen' (click)="closeModal()">
  <div class="modal-container b-white" (click)="stopProp($event)">
    <div class="modal__title-container b-dark-blue">
      <h3 class="modal__title ls-headline f-white">Operation Info</h3>
      <button class="welcome-page-modal__close modal__close" (click)="closeModal()">
        <svg viewBox="0 0 24 24">
          <path
            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            fill="#ffffff"></path>
        </svg>
      </button>
    </div>

    <div class="modal__settings-container sync-descripton-text-container">
      <div class="sync-description-table-container">
        <table class="display-table display-table--extra-padding">
          <thead>
            <tr>
              <th>Records Modified</th>
              <th>Operation</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let infoItem of syncInfo">
              <td>{{infoItem.TableName}}</td>
              <td>{{infoItem.Operation}}</td>
              <td>{{infoItem.Message}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="sync-description-legend-container">
        <h4 class="sync-description-heading">{{emsConfig.text?.EMS_Sync.Sync_Description_Legend_Heading}}</h4>
        <div class="sync-description-legend" [innerHTML]="emsConfig.text?.EMS_Sync.Sync_Description_Legend"></div>
      </div>
    </div>
  </div>

</div>

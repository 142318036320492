// Angular Things
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';

// Classes
import { ModuleItem } from '../../shared/ModuleItem';

// Components

//  Services
import { CmsService } from '../cms.service';
import { UtilitiesService } from '../../shared/utilities.service';


@Component({
  selector: 'cms-modules', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-modules.component.html'
})

export class CmsModulesComponent implements OnInit {

  allModules: any;

  errorMessage: string;

  lang: string = 'EN';

  // edit volumes variables
  showEditButton: boolean = false;

  selectedModuleId: number;

  // filter variables
  moduleSearch: string;
  moduleStatus: string;
  public isOnPoint: boolean = false;
  activeFilter: string = 'all';
  currentlySearching: boolean = false;
  activeFilterStore: string;
  moduleStatusStore: string;


  constructor(private router: Router, private route: ActivatedRoute, public _cmsService: CmsService, public _emsConfig: EmsConfig, public _utilitiesService: UtilitiesService) { }

  // update various pipe filters
  public updateModuleFilters(status: string): void {
    this.moduleStatus = status;
    this.activeFilter = status;
    console.log('status changed');
  }

  // if using the search bar ignore the other filter options
  usingSearchBar(text: any): void {
    if (this.moduleSearch.length > 0) {
      if (!this.currentlySearching) {
        // store the current filter settings to apply back later
        this.currentlySearching = true;
        this.activeFilterStore = this.activeFilter;
        this.moduleStatusStore = this.moduleStatus;
      }
      // turn off the filters
      this.resetFilters();

    } else {
      // when search input is cleared, set back the filters
      this.activeFilter = this.activeFilterStore;
      this.moduleStatus = this.moduleStatusStore;
      this.currentlySearching = false;
    }
  }

  // reset filters
  public resetFilters(): void {
    this.activeFilter = 'all';
    this.updateModuleFilters('all');
  }

  // focus on selected volume and store the id
  selectModule($event: any): void {
    const target = $event.currentTarget;
    target.focus();
    this.selectedModuleId = $event.currentTarget.attributes.id.value;
    console.log(this.selectedModuleId);
    this.showEditButton = true;
  }

  deselectModule($event: any): void {
    // check if click is on specific element
    if ($event.relatedTarget != null) {
      // if click is another volume or the fab button, show the edit button
      if ($event.relatedTarget.className === 'btn--fab b-dark-blue cms-container__fab cms-container__fab--edit' || $event.relatedTarget.className === 'module-card card') {
        this.showEditButton = true;
      } else {
        // if not clicking on other volume, show add button instead of edit
        this.showEditButton = false;
      }
    } else {
      this.showEditButton = false;
    }
  }


  getAllModules(): void {
    this._cmsService.getAllModules(this.lang)
      .subscribe(
      ModuleItem => {
        this.allModules = ModuleItem;
        for (let i = 0; i < this.allModules.length; i++) {
          this.allModules[i].TitleNormalized = this._utilitiesService.normalizeTerm(this.allModules[i].Title);
          this.allModules[i].ExpertNormalized = this._utilitiesService.normalizeTerm(this.allModules[i]?.Expert);
        }
        this.updateModuleFilters(this.moduleSearch ? 'all' : 'live');
      },
      err => this.errorMessage = <any>err,
      () => console.log(this.allModules)
      );
  }

  // Navigates to Volume Details template
  onSelect() {
    // If search query exists, add param to route to allow for easy navigation back to filtered list of modules
    this.router.navigate([], { 
      relativeTo: this.route, 
      queryParams: { query: this.moduleSearch },
      queryParamsHandling: 'merge' 
    });
    // Navigate with relative link after route is updated with search query param
    setTimeout(() => {
      this.router.navigate([this.selectedModuleId], { relativeTo: this.route });
    }, 100)
  }

  createNewModule() {
    // Navigate with relative link
    this.router.navigate(['-1'], { relativeTo: this.route });
  }

  // update the volume list by language
  setLang(lang: string): void {
    this.lang = lang;
    console.log(this.lang);

    // adding query param for selected language, so that if a user navigates away from this page and comes back, the language they chose will be remembered
    this.router.navigate(['/cms/modules'], {
      relativeTo: this.route, 
      queryParams: { lang: lang },
      queryParamsHandling: 'merge' 
    });

    // reset the filters
    this.resetFilters();
    this.getAllModules();
  }

  ngOnInit(): void {

    // checks if search query param exists, and populates search input filter
    if (this.route.snapshot.queryParams["query"]) {
      const searchParam = this.route.snapshot.queryParams["query"];
      this.moduleSearch = searchParam;
    };

    // checks if language query param exists, and calls setLang() method to set and remember selected language
    if (this.route.snapshot.queryParams["lang"]) {
      const param = this.route.snapshot.queryParams["lang"];
      this.setLang(param);
    };

    // gets list of modules by language
    this.getAllModules();

  }
}

<div class="heading-container">
  <section class="event-intro">
    <h1 class="ls-headline">
      <span *ngIf="event.EventID !== -1">{{_emsConfig.text?.EMS_Expert?.Welcome_Message}}</span>
      <span *ngIf="event.EventID === -1">{{_emsConfig.text?.EMS_Expert?.NoEventScheduled}}</span>
      <span class="ls-display">{{eventTopic}}</span>
    </h1>
    <h4 id="eventDate">{{eventDate}}</h4>
  </section>
  <div class="expert-bio">
    <img class="expert-thumb" [src]="expertPhoto" [alt]="expert?.ExpertName[expertLang]">
    <div class="expert-intro">
      <h3 class="ls-display">{{expert?.ExpertNameFormatted}}</h3>
      <h4 class="ls-subheading">{{expert?.ShortBio}}</h4>
    </div>
  </div>
</div>

<ul class="secondary-nav b-dark-grey f-white">
  <li class="secondary-nav__list-item ls-title f-white" [class.active]="selectedTab == 'Pending'"
    (click)="selectedTab ='Pending'">{{_emsConfig?.text?.EMS_Sync?.Pending}}</li>
  <li class="secondary-nav__list-item ls-title f-white" [class.active]="selectedTab == 'Answered'"
    (click)="selectedTab ='Answered'">{{_emsConfig?.text?.EMS_Expert?.Answered}}</li>
</ul>

<div class="no-event-scheduled" *ngIf="eventId === -1">
  <h3 class="ls-display">{{_emsConfig?.text?.EMS_Expert?.NoEventScheduled}}</h3>
</div>

<section class="socket-disconnected" *ngIf="!hubConnected">
  <h3>{{_emsConfig.text?.EMS_Expert?.Connection_Dropped}}</h3>
</section>

<div class="tab-content-container" *ngIf="eventId !== -1" [ngSwitch]="selectedTab">
  <ate-expert-answered *ngSwitchCase="'Answered'" [questions]="answeredQuestions" [expertLang]="expertLang">
  </ate-expert-answered>
  <div class="ate-add-comment" *ngSwitchCase="'Pending'">
    <button class="btn ls-button-1" (click)="editAnswer()">{{_emsConfig.text?.EMS_Expert?.Add_Comment}}</button>
  </div>
  <table *ngSwitchCase="'Pending'" class="display-table">
    <thead>
      <tr>
        <th>{{_emsConfig.text?.EMS_Sync?.Sync_ID}}</th>
        <th>{{_emsConfig.text?.EMS_Expert?.Nickname}}</th>
        <th>{{_emsConfig.text?.EMS_Expert?.Question}}</th>

        <th *ngIf="pending">{{_emsConfig.text?.EMS_Expert?.Actions}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let answer of answers">
        <td>{{answer.QuestionID}}</td>
        <td>{{answer.Email}}</td>
        <td class="question" [innerHTML]="answer.Question"></td>
        <td *ngIf="pending" class="actions">
          <button class="btn ls-button-1 edit"
            (click)="editAnswer(answer.QuestionID)">{{_emsConfig.text?.EMS_Expert?.Reply}}</button>
          <button [disabled]="_utilities.isBlank([answer.Answer])" class="btn ls-button-1 submit"
            (click)="confirmAction('submitAnswer', _emsConfig.text?.EMS_Expert.Confirm_Answer, answer)">{{_emsConfig.text?.EMS_Expert?.Submit}}</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>



<div class="modal-window expert-edit-modal" [hidden]="!editMode" (click)="closeEditModal()" [ngSwitch]="modalMode">
  <div class="modal-container" (click)="onEvent($event)">
    <div class="modal__title-container b-dark-blue">
      <h3 class="modal__title ls-headline f-white" *ngSwitchCase="modalModeEnum.submitAnswer">
        {{_emsConfig.text?.EMS_Expert?.Answer_Question}}</h3>
      <h3 class="modal__title ls-headline f-white" *ngSwitchCase="modalModeEnum.addComment">
        {{_emsConfig.text?.EMS_Expert?.Add_A_Comment}}</h3>
      <button class="modal__close" (click)="closeEditModal()">
        <svg viewBox="0 0 24 24">
          <path fill="#ffffff"
            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>
      </button>
    </div>
    <div class="modal__content">
      <table>
        <tbody>
          <tr *ngSwitchCase="modalModeEnum.submitAnswer">
            <td>{{_emsConfig.text?.EMS_Expert?.Nickname}}:</td>
            <td>{{editingAnswer.Email}}</td>
          </tr>
          <tr *ngSwitchCase="modalModeEnum.submitAnswer">
            <td>{{_emsConfig.text?.EMS_Expert?.Question}} ({{expertLang}}):</td>
            <td class="question" [innerHTML]="editingAnswer?.Question"></td>
          </tr>
          <tr>
            <td *ngSwitchCase="modalModeEnum.submitAnswer">{{_emsConfig.text?.EMS_Expert?.Answer}}* ({{expertLang}}):
            </td>
            <td *ngSwitchCase="modalModeEnum.addComment">{{_emsConfig.text?.EMS_Expert?.Comment}} ({{expertLang}}):</td>
            <td class="textarea-container">
              <textarea id="ate-answer-editor"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="model-btn-container">
        <div class="align-btn-right" *ngSwitchCase="modalModeEnum.submitAnswer">
          <ng-container *ngIf="_emsConfig.enableAI">
            <svg style="margin-right: 15px;" class="btn ls-button-1" [ngClass]="{'recording': speechToggle}"
              (click)="recordVoice()" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" height="35px" width="35px"
              version="1.1" id="mic" viewBox="0 0 241.5 241.5" xml:space="preserve">
              <path d="M120.75,150.585c26.957,0,48.889-21.931,48.889-48.889V48.889C169.639,21.931,147.707,0,120.75,0
	C93.793,0,71.861,21.931,71.861,48.889v52.808C71.861,128.654,93.793,150.585,120.75,150.585z M86.861,48.889
	C86.861,30.203,102.063,15,120.75,15c18.686,0,33.889,15.203,33.889,33.889v52.808c0,18.686-15.202,33.889-33.889,33.889
	c-18.687,0-33.889-15.203-33.889-33.889V48.889z M55.572,69.748v31.949c0,35.939,29.239,65.177,65.178,65.177
	c35.939,0,65.178-29.238,65.178-65.177V69.748h15v31.949c0,41.68-31.973,76.019-72.678,79.815V226.5h36.785v15h-88.57v-15h36.785
	v-44.988c-40.705-3.795-72.678-38.135-72.678-79.815V69.748H55.572z" />
            </svg>
          </ng-container>
          <button class="btn ls-button-1"
            (click)="confirmAction('addUpdateAnswer', _emsConfig.text?.EMS_General.Confirm_Message, editingAnswer)">{{_emsConfig.text?.EMS_General?.Save}}</button>
          <button class="btn ls-button-1"
            (click)="confirmAction('submitAnswer', _emsConfig.text?.EMS_Expert.Confirm_Answer, editingAnswer)">{{_emsConfig.text?.EMS_Expert?.Submit}}</button>
        </div>
        <div class="align-btn-right" *ngSwitchCase="modalModeEnum.addComment">
          <button class="btn ls-button-1"
            (click)="confirmAction('addUpdateAnswer', _emsConfig.text?.EMS_Expert.Confirm_Comment,null)">{{_emsConfig.text?.EMS_Expert?.Submit_Comment}}</button>
          <button class="btn ls-button-1" (click)="closeEditModal()">{{_emsConfig.text?.EMS_General.Cancel}}</button>
        </div>
      </div>

    </div>

  </div>
</div>

<div class="organization-sync-container section-padding branch-status-page">
    <h1>Clear Server Cache</h1>
    <p> This tool allows administrators to clear the cache on the production server. <br />
        Note that clearing assets, such experts and clips, will clear these items for all LifeSpeak clients.<br />
        Additionally, clearing for certain assets such as experts will clear some dependant assets (ATE Events) <br />
        If you want to clear a specific client's pages, watch and win or account settings
        please use the organization selector at the bottom of this table.<br />

    </p>
    <table class="display-table sync-organization-table display-table--extra-padding">
        <thead>
            <tr>
                <th>Asset</th>
                <th>Item Count</th>
                <th>Items in Cache</th>
                <th>Clear Assets</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cacheItem  of _assetCacheItems">
                <td class="project-title"> {{cacheItem.CacheTitle}}</td>
                <td> {{cacheItem.ItemCount}} </td>
                <td>
                    <span *ngIf="cacheItem.ItemCount===0">--</span>
                    <a href="javascript:void(0);" *ngIf="cacheItem.ItemCount > 0"
                        (click)="ShowCachedItems(cacheItem.TableId,cacheItem.CachedItems.join(','),cacheItem.CacheTitle)">Show
                    </a>

                </td>
                <td> <a href="javascript:void(0);"
                        (click)="ClearCacheForAsset(cacheItem.ClearCacheValue?cacheItem.ClearCacheValue : cacheItem.CacheKey)">Clear
                        Assets</a>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr colspan="3">
                <td colspan="4">
                    <div class="cache-org-header">
                        <h4>Clear server cache for organization</h4>
                    </div>
                    <div class="cache-org-selector" style="display:flex">
                        <div>
                            Clears server cache for account settings, watch and win, and library information
                        </div>

                        <div>
                            <label for="clearOrgSettings">Select Organization</label><br />
                            <select class="setting__select-input select-input" name="clearOrgSettings"
                                [(ngModel)]="_selectedOrg">
                                <option value="{{org.OrganizationName}}" *ngFor="let org of accounts">
                                    {{org.OrganizationName}}</option>
                            </select>
                        </div>
                        <div>
                            <a *ngIf="_selectedOrg !==''" href="javascript:void(0);"
                                (click)="ShowCachedDataForClient()">
                                Show cached items for {{_selectedOrg}}</a>
                        </div>
                        <div>
                            <a *ngIf="_selectedOrg !==''" href="javascript:void(0);" (click)="ClearCacheForOrg()">
                                Clear Cache for {{_selectedOrg}}</a>
                        </div>
                    </div>
                </td>
            </tr>
            <tr colspan="3" class="clear-server-cache-section">
                <td colspan="4">
                    <div>

                            <a class="clear-server-cache-link" href="javascript:void(0);" (click)="ClearServerCache()">Clear Entire Server Cache</a>

                    </div>

                </td>
            </tr>

        </tfoot>
    </table>

    <button class="btn volume-submit" style="margin-top: 10px;" (click)="RefreshCachedItemsData()">Refresh Cache
        Data</button>


    <!-- /.modal-begin -->
    <div class="modal-window add-account-window" *ngIf="_modalOpen" (click)="_modalOpen = false">
        <div class="modal-container" (click)="stopProp($event)">
            <div class="modal__title-container b-dark-blue">
                <h3 class="modal__title ls-headline f-white">Cached {{ _selectedAsset }} List</h3>
                <button class="modal__close" (click)="_modalOpen = false">
                    <svg viewBox="0 0 24 24">
                        <path fill="#ffffff"
                            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                    </svg>
                </button>
            </div>
            <form class="modal__content">
                <p class="cache-clear-display" [innerHTML]="_selectedAssetNames"></p>
            </form>
        </div>
    </div> <!-- /.modal-end -->
</div>

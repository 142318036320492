import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { EmsConfig } from '../shared/emsConfig';

@Injectable()
export class SpinnerService {

  private spinnerData: Subject<any> = new BehaviorSubject(this.notification({}));
  spinnerData$ = this.spinnerData.asObservable();
  private currentRequests: number[] = [];
  private requestID: number = 0;

  constructor(private _router: Router) {

    this._router.events.subscribe(event => {
      // For  lazy loading- Enable/Disable spinner when user navigate to different pages
      if (event instanceof NavigationStart) {
        this.updateSpinner({ toggle: true });
      } else if (event instanceof NavigationEnd) {
        this.updateSpinner({ toggle: false });
      }
    });
  }
  notification(data: any): any {
    return data;
  }

  updateSpinner(data: any): void {
    this.spinnerData.next(data);
  }

  // set and return a request ID, and ensure the ajax spinner is turned on
  setRequestId(): number {
    // if there are no current requests at this point, turn on the spinner
    if (this.currentRequests.length === 0) {
      this.updateSpinner({ toggle: true });
    }

    // increment the request counter
    this.requestID++;
    this.currentRequests.push(this.requestID);
    //console.log('setrequestID: ' + this.requestID);
    return this.requestID;
  }

  removeRequestId(requestID: number): void {
    // filter out the request id
    this.currentRequests = this.currentRequests.filter(e => e !== requestID);

    // if there are no more current requests turn off the spinner
    if (this.currentRequests.length === 0) {
      this.updateSpinner({ toggle: false });
    }
  }

  // empty the current requests array and display an error
  requestError(): void {
    this.currentRequests = [];
    this.updateSpinner({ toggle: false });
  }

}

<div class="ate ems5">
  <div class="inner-page-main section-padding ate--question-edit">
    <button class="btn--back back pointer" [routerLink]="['/asktheexpert']">&#8592; Back</button>
       <syncstatus [hidden]="answers.length===0 || answers==null" [assetID]="eventID" [tableID]="22"></syncstatus>
     <div class="ate__event-info">
      <div>
        <span><strong>Title:</strong> {{expertEvent.EventTopic}}</span>
        <span><strong>Expert:</strong> {{expertEvent.Expert?.ExpertName.English}}</span>
        <span><strong>Date:</strong> {{expertEvent.EventDateString?.English}}</span>
        <span><strong>Language:</strong> {{langStr}}</span>
        <span><strong>Moderated as:</strong> {{expertEvent.EventModerator}}</span>
      </div>
      <div>
        <button class="btn" (click)="confirmAction('importQuestions', 'Only run this command after the webchat has finished.')">Import event
        questions</button>
      </div>
    </div>
    <div class="basic-container module-details-container__info-container">
      <div class="tabs card-box-shadow">
        <div class="tabs__container">
          <a [routerLink]="['/asktheexpert/event-edit', expertEvent.EventID]" class="tab">
            <span class="page-header f-dark-blue ls-subheading">Event Details</span>
          </a>
          <a [routerLink]="['/asktheexpert/moderate', expertEvent.EventID]" class="tab">
            <span class="page-header f-dark-blue ls-subheading">Moderate</span>
          </a>
          <a [routerLink]="['/asktheexpert/question-edit', expertEvent.EventID]" class="tab tab--active">
            <span class="page-header ls-subheading">Edit Questions</span>
          </a>
        </div>
      </div>
      <div class="details-container info-container__text-container ">
        <div class="toggle-buttons">
          <button class="btn--toggle" [ngClass]="{'active': connexStatus === connexStatusType.ateEditing}" (click)="connexStatus = connexStatusType.ateEditing">Editing</button>
          <button class="btn--toggle" [ngClass]="{'active': connexStatus === connexStatusType.ateReady}" (click)="connexStatus = connexStatusType.ateReady">Ready</button>
          <button class="btn--toggle" [ngClass]="{'active': connexStatus === connexStatusType.Trash}" (click)="connexStatus = connexStatusType.Trash">Trash</button>
        </div>
        <table class="questions-table display-table display-table--extra-padding">
          <thead>
            <tr>
              <th>ID</th>
              <th>Question</th>
              <th>Answer</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let answer of answers | connexStatusFilter:connexStatus">
              <td>{{answer.QuestionID}}</td>
              <td (click)="modifyAnswer(answer.QuestionID)" class="title-size edit-link max-q-width"
                [innerHTML]="truncate(answer.Question, 200)"></td>
              <td class="max-q-width" [innerHTML]="truncate(answer.Answer, 200)"></td>
              <td class="archive-question" *ngIf="connexStatus != 9"><button (click)="updateQuestionWithStatus(answer, 9)"
                  class="btn ls-button-1 btn--delete-bg">Trash</button></td>
              <td class="archive-question" *ngIf="connexStatus === 9"><button (click)="updateQuestionWithStatus(answer, 6)"
                  class="btn ls-button-1">Restore</button></td>
            </tr>
          </tbody>
        </table>
        <div class="modal-window modify-question" [hidden]="!modalActive" (click)="toggleEditWindow()">
          <div class="modal-container" (click)="onEvent($event)">
            <div class="modal__title-container b-dark-blue">
              <h3 class="modal__title ls-headline f-white">Modify Question ({{expertEvent.Language}})</h3>
              <button class="modal__close" (click)="toggleEditWindow()">
                <svg viewBox="0 0 24 24">
                  <path fill="#ffffff"
                    d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                </svg>
              </button>
            </div>
            <div class="modal__content">
              <table>
                <tbody>
                  <tr>
                    <td>Question</td>
                    <td class="textarea-container col-fill-space">
                      <textarea id="ate-question-editor"></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td>Answer</td>
                    <td class="textarea-container col-fill-space">
                      <textarea id="ate-answer-editor"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="align-btn-right">
                <button class="btn ls-button-1" *ngIf="activeQuestion.Status < 7"
                  (click)="confirmAction('updateQuestion', 'Are you sure you want to update this question?')">Update</button>
                <button class="btn ls-button-1" *ngIf="activeQuestion.Status === 6"
                  (click)="confirmAction('updateQuestionWithStatus', 'Are you sure?', 7)">Finished editing</button>
                <button class="btn ls-button-1" *ngIf="activeQuestion.Status === 7 || activeQuestion.Status === 8"
                  (click)="confirmAction('updateQuestionWithStatus', 'Are you sure?', 6)">Re-edit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="tipsheet-details-container section-padding">
  <div class="tipsheet-edit-container ">
  <button class="btn btn--upload go-to-volume" (click)="goToVolume()">Go to volume</button>
  <syncstatus [assetID]="tipsheet?.ID" [tableID]="37" ></syncstatus>
    <div class="basic-container info-container volume-details-container__info-container">
        <h3 class="ls-subheading f-dark-blue info-container__heading">Tipsheet Details</h3>
        <div class="preview-link" *ngIf="showPreviewLink">
          <a [href]="tipsheetPreviewLink" target="_blank">Preview Tipsheet</a>
        </div>
        <div class="details-container info-container__text-container">
          <form action="" class="addEditTipsheet">
            <div class="info-container__section-split">
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z" />
                    </svg>
                  </div>
                  Tipsheet ID
                </h4>
                <p class="basic-info__text setting__text ls-subheading">{{tipsheet?.ID}}</p>
              </div>
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
                  <span class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" />
                    </svg>
                  </span>
                  Language
                </h4>
                <div class="select-container select-language-container">
                  <select class="setting__select-input select-input" name="tipsheetLanguage" [(ngModel)]="tipsheet && tipsheet.TipsheetLang" (change)="getSimpleVolumes(tipsheet.TipsheetLang)" [disabled]="!addNewTipsheet">
                    <option value="EN">EN</option>
                    <option value="FR">FR</option>
                  </select>
                </div>
              </div>
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                    </svg>
                  </div>
                  Volume
                </h4>
                <div class="select-container">
                  <select *ngIf="tipsheet" class="setting__select-input select-input" name="volumeID" [(ngModel)]="tipsheet.VolumeId" [disabled]="!addNewTipsheet">
                    <option [ngValue]="-1" disabled>Select a volume</option>
                    <option [ngValue]="vol.ID" *ngFor="let vol of simpleVolumes">{{vol.VolumeTitle}}</option>
                  </select>
                </div>
              </div>
              <div class="basic-info__setting" *ngIf="showPreviewLink">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                    </svg>
                  </div>
                  Asset Key
                </h4>
                <p class="basic-info__text setting__text ls-subheading">{{ tipsheet?.AssetKey }}</p>
              </div>
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path
                        d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                    </svg>
                  </div>
                  Include in Content Fusion?
                </h4>
                <div class="basic-info__checkbox-container">
                  <div class="checkbox-container">
                    <div class="contentfusion-checkbox">
                      <input name="includecontent" [(ngModel)]="tipsheet && tipsheet.IncludeInContentFusion" type="checkbox">
                    </div>
                  </div>
                </div>
              </div>
            </div>


          <div class="info-container__section-split">

            <div class="basic-info__setting" *ngIf="id!==-1">
              <h4 class="basic-info__heading setting__heading ls-subheading">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path
                      d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />
                  </svg>
                </div>
                Subcategories
              </h4>
              <div class="video-subcategory-category">Video Series Category: <span>{{categoryName}}</span></div>
              <ul class="subcategory-list-container">
                <li *ngFor="let subcategory of subCategories">
                  <div class="checkbox-container">
                    <div class="ls-checkbox">
                      <mat-checkbox name="subCategory{{subcategory.ID}}" [(ngModel)]="tipsheetSubcategoryIDs[subcategory.ID]"
                        [color]="'primary'" id="subCategory{{subcategory.ID}}" value="{{subcategory.ID}}">
                        {{subcategory.Name}}</mat-checkbox>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          </form>
        </div>
    </div>
    <div class="basic-container info-container volume-details-container__info-container">
      <h3 class="ls-subheading f-dark-blue info-container__heading">
        <span class="tooltip-container">
          Tipsheet Contents
          <tooltip [tooltipMessage]="tipsheetContentTooltip"></tooltip>
        </span>
      </h3>
      <div class="details-container info-container__text-container editor-container">
        <textarea id="tipsheet-area" [innerHTML]="tipsheetEditorData"></textarea>
        <div class="form-button-container">
          <button (click)="openConfirmDialog('tipsheets', 'updateTipsheet', tipsheet, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
            type="button" class="btn volume-submit">
            Save
          </button>
        </div>
      </div>
    </div>
</div>
</div>

import { Component, HostBinding, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, skip, take } from 'rxjs';
import { EmsConfig } from '.././shared/emsConfig';
import { LoginToken } from '.././shared/login-token';
import { LoginService } from './login.service';
import { map } from 'rxjs';
import { AppConfigService } from 'app/services/AppConfigService.service';
import { SpinnerService } from 'app/shared/spinner.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  @HostBinding('class') login: string = 'login';
  public loginTitle: string = 'Engagement Management System';
  public password: string;
  public username: string;
  public loginError: string = '';
  public rememberMe: boolean = true;
  public loggedIn: boolean;
  public showPasswordReset: boolean;
  public resetSuccess: string;
  public showPage: boolean;
  constructor(
    private _httpClient: HttpClient,
    public emsConfig: EmsConfig,
    private router: Router,
    private _loginService: LoginService,
    private appsettingsService: AppConfigService,
    private _spinnerService: SpinnerService
  ) {
    this._spinnerService.updateSpinner({ toggle: true });
  }
  ngOnInit(): void {
    //check for login token
    this.appsettingsService.loginToken$.subscribe((token: LoginToken) => {
        if (token?.IsValid && token) {
          //check if there is a return url in the query params then navigate to that url
          this._spinnerService.updateSpinner({ toggle: false });
          const returnUrl = this.router.parseUrl(this.router.url).queryParams['returnUrl'];
          if (returnUrl) {
            this.router.navigateByUrl(returnUrl);
          } else {
            this.router.navigateByUrl('/dashboard');
          }
        } else {
          this._spinnerService.updateSpinner({ toggle: false });
          this.showPage = true;
        }
      });
  }
  public userLogin(): void {
    const rememberMe: boolean = this.rememberMe;
    const router: Router = this.router;

    this.authenticateUser(this.username, this.password);
  }

  private authenticateUser(username: string, password: string) {

    if (username === undefined || password === undefined)
      return;
    const body = JSON.stringify({
      Email: username,
      Password: password,
      UserAgentString: 'ems',
      OrganizationName: 'EMS',
      IP: '',
      EMSBuildVersion: this.emsConfig.buildVersion
    });
    const authEndpoint = this.emsConfig.authApiEndpoint + '/Authentication/personallogin';


    return this._httpClient.post<any>(authEndpoint, body, { headers: this.getRequestHeaders() })
      .subscribe(data => {
        // console.log(data);
        // if successful login update the view so the header is displayed
        if (data.IsValid) {
          this.setupLoginSession(data);
          this.router.navigateByUrl('/dashboard');
        }
      }, error => {
        this.handleError(error);
      });

  }

  private setupLoginSession(data: any) {
    this._loginService.changeLoginStatus(true);
    this.emsConfig.setLoginToken(data, this.rememberMe);
  }

  private handleError(error: Response) {
    if (error.status === 400 || error.status === 404) {
      this.loginError = this.emsConfig?.text?.EMS_General?.Login_Invalid_Cred;
    } else {
      this.loginError = this.emsConfig?.text?.EMS_General?.Login_Error;
    }
  }

  forgotPassword() {
    if (this.username === undefined)
      return;
    this.resetSuccess = '';
    const authEndpoint = `${this.emsConfig.authApiEndpoint}/Password/resetpassword?UserName=${this.username}&allowAnonymous=true&sendLink=true&fromems=true`;

    return this._httpClient.get<any>(authEndpoint, { headers: this.getRequestHeaders() }).subscribe(data => {
      console.log(data);
      if (data.Result) {
        this.resetSuccess = 'Password reset link has been sent to your email address.';
      }
    }, error => {
      console.log(error); this.resetSuccess = this.emsConfig.text?.EMS_General?.Login_Error;
    });
  }
  private getRequestHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json;charset=UTF-8')
      .set('X-LS-Auth-Token', this.emsConfig.apiToken)
      .set('X-LS-CORS-Template', this.emsConfig.corsTemplate);
  }

}

import { element } from 'protractor';
import { Component, HostBinding, OnInit, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';

import { EmsConfig } from '../../shared/emsConfig';
import { ConnexService } from '../connex.service';
import { LoginToken } from '../../shared/login-token';
import { UtilitiesService } from '../../shared/utilities.service';
import { ConfirmService } from '../../shared/confirm.service';
import { PopupService } from '../../shared/popup.service';

// Connex objects
import { AteAnswer } from '../Objects/AteAnswer';
import { AteQuestionStatus } from '../Objects/AteQuestionStatus';
import { AteQuestionType } from '../Objects/AteQuestionType';
import { WebSocketsService } from '../ate/websockets.service';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { Expert } from 'app/shared/expert';
import { Event } from 'app/shared/event';
import { SpeechRecognitionService } from 'app/services/speechRecognition.service';

declare var tinymce: any;
@Component({
  selector: 'ate-expert',
  templateUrl: 'ate-expert.component.html',
  providers: [ConnexService]
})
export class AteExpertComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostListener('document:visibilitychange', ['$event']) onvisibilitychange(event) {
    //If page or tab is not visible and socket is disconnected then try reconnecting.
    if (event.target.visibilityState === 'visible') {
      console.log('welcome back to chat...' + this.hubConnection.state);
      this._handleSignalRReconnection();
    }
  }

  @HostBinding('class') classAttribute: string = 'connex ate-expert';
  public expertLang: string = 'English';
  public expert: Expert = new Expert();
  public event: Event = new Event();
  public answers: AteAnswer[] = [];
  public answeredQuestions: AteAnswer[] = [];

  public hubConnected: boolean = true;

  public loginToken: LoginToken;

  public body: any;

  public editMode: boolean = false;
  public editingAnswer: AteAnswer = new AteAnswer();

  // used to toggle between pending and answered questions
  public selectedTab: string = 'Pending';
  public modalModeEnum = AteModalMode;
  public modalMode: AteModalMode = this.modalModeEnum.submitAnswer;
  // MyEnum = MyEnum;
  // myEnumVar:MyEnum = MyEnum.Second
  public pending: boolean = true;
  // editor variables
  public answerEditor: any;

  public confirmSubscription: Subscription;
  public hubConnection: HubConnection;
  public eventId: any;
  private expertComment: string;
  public expertPhoto: string;
  public eventDate: string;
  public eventTopic: string;
  public speechToggle: boolean = false;
  constructor(
    public _emsConfig: EmsConfig,
    public _connexService: ConnexService,
    public _utilities: UtilitiesService,
    private _confirmService: ConfirmService,
    private _popupService: PopupService,
    private hubConnectionService: WebSocketsService,
    private _speechService: SpeechRecognitionService
  ) {
    this.body = document.getElementsByTagName('body')[0];

    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (response.data.action === 'addUpdateAnswer') {
            this.addUpdateAnswer(response.data.answer);
          } else if (response.data.action === 'submitAnswer') {
            this.submitAnswer(response.data.answer);
          }
        }
      });
  }

  editAnswer(id?: number) {
    // create a deep copy of the object
    if (id) {
      this.editingAnswer = JSON.parse(JSON.stringify(this.answers.find(e => e.QuestionID === id)));
      console.log(this.editingAnswer);
    } else {
      this.modalMode = this.modalModeEnum.addComment;
    }
    this.openEditModal();
  }
  openEditModal(): void {
    // Prevent body scrolling
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
    this.editMode = true;
    this.answerEditor = tinymce.get('ate-answer-editor');
    //get answer saved in local storage
    //set key id to 0 if expert is editing comment
    let savedId = this.modalMode === this.modalModeEnum.addComment ? 0 : this.editingAnswer.QuestionID;
    var savedAnswer = this._getWithExpiry(savedId);

    if (this.answerEditor != null && this.modalMode == this.modalModeEnum.submitAnswer) {
      let ans = this.editingAnswer.Answer ?? '';
      this.answerEditor.setContent(ans);
    } else {
      this.answerEditor.setContent('');
    }
    //only set local storage's answer to editor if expert haven't saved it in the db.
    if (savedAnswer && this.answerEditor.getContent() == '') {
      this.answerEditor.setContent(savedAnswer);
    }
    //if ans is saved in the db append it to browser's answer
    if (savedAnswer && this.answerEditor.getContent() !== '') {
      var tmp = this.answerEditor.getContent() as string;
      tmp = tmp.replace(tmp, savedAnswer);
      this.answerEditor.setContent(tmp);
    }
  }

  closeEditModal(): void {
    this.body.classList.remove('no-scroll');
    this.editMode = false;
    this.editingAnswer.SendBackNotes = '';
    if (this.modalMode = this.modalModeEnum.addComment)
      this.modalMode = this.modalModeEnum.submitAnswer;
    else
      this.modalMode = this.modalModeEnum.addComment;
  }

  // get questions filtered by expert ID
  getQuestionsForExpert(expertID: number, eventID: number): void {
    this._connexService.getATEQuestionsForExpert(expertID, eventID)
      .subscribe(response => {
        console.log(response);
        this.answers = response.filter(q => q.Status === AteQuestionStatus.Pending)
          .sort((a, b) => b.QuestionID - a.QuestionID);
        this.answeredQuestions = response.filter(q => q.Status === AteQuestionStatus.Answered || q.Status === AteQuestionStatus.Sent);
      });
  }

  // add or update the connex answer
  addUpdateAnswer(answer?: AteAnswer): void {
    if (this.modalMode === this.modalModeEnum.submitAnswer) {
      if (this.editMode) {
        answer.Answer = this.answerEditor.getContent();
      }
      //update question answer
      let oldStatus = answer.Status;
      if (answer.SendToAdmin)
        answer.Status = AteQuestionStatus.Answered;
      this._connexService.modifyATEQuestion(answer)
        .subscribe(succeeded => {
          if (succeeded) {
            // get the index of the updated answer
            const index = this.answers.findIndex(x => x.QuestionID === answer.QuestionID);
            if (answer.SendToAdmin) {
              // if the submit was successful remove the answer from the array and exit modal window (if open)
              this.removeAnswerFromList(answer.QuestionID);
              answer.Status = AteQuestionStatus.Answered;
              this.answeredQuestions.push(answer);
              console.log('sent to admin');
              console.log(answer);
              this.hubConnection.send('SendAnsweredQuestionBackToModerator', answer);
            } else {
              // if the save was successful assign the obect back to the array
              this.answers[index] = answer;
              console.log('updated');
              console.log(answer);
            }
            this.closeEditModal();
            this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Success, success: true });
          } else {

            answer.Status = oldStatus;
            this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
          }
        });
    }

    if (this.modalMode === this.modalModeEnum.addComment) {
      this.expertComment = this.answerEditor.getContent();
      if (this._utilities.isBlank([this.expertComment])) {
        this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Required, error: true });
        return;
      }
      answer = this.buildExpertComment();
      let oldStatus = answer.Status;
      answer.Status = AteQuestionStatus.Answered;
      this._connexService.submitATEQuestion(answer)
        .subscribe(response => {
          if (response.QuestionID > -1) {
            console.log('comment added');
            console.log(response);
            this.answeredQuestions.push(response);
            this.hubConnection.send('SendAnsweredQuestionBackToModerator', response);
            this.closeEditModal();
            //remove saved expert comment from local storage
            this._setWithExpiry(0, "", 0);
          } else {
            answer.Status = oldStatus;
            this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
          }
        });
    }
  }
  // remove an answer from the array
  removeAnswerFromList(id: number): void {
    const index = this.answers.findIndex(x => x.QuestionID === id);
    this.answers.splice(index, 1);
  }

  // submit the answer back to LifeSpeak
  submitAnswer(answer: AteAnswer): void {
    // if editor modal is open get answer from the editor
    if (this.editMode)
      answer.Answer = this.answerEditor.getContent();

    if (this._utilities.isBlank([answer.Answer])) {
      this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Required, error: true });
    } else {
      // set the flag to send back
      answer.SendToAdmin = true;
      this.addUpdateAnswer(answer);
    }
  }


  confirmAction(action: string, message: string, answer: AteAnswer): void {
    this._confirmService.updateNotification({ action: action, message: message, answer: answer });
  }

  buildExpertComment() {
    // var email = this.expert.ExpertName[this.expertLang] ?? this.expert.ExpertName['English'];
    var email = this.expert.ExpertNameFormatted;

    let q = new AteAnswer();
    q.Email = email;
    q.Question = this.expertComment;
    q.EmployeeName = this.loginToken.EmployeeNumber;
    q.OriginalLang = this.event.Language;
    q.ExpertID = this.expert.ExpertID;
    q.ClientName = 'team';
    q.ArchiveChatID = +this.eventId;
    q.QuestionType = AteQuestionType.ExpertComment;
    q.SendToAdmin = true;
    q.Answer = '';
    return q;
  }

  onEvent(event: any) {
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.loginToken = this._emsConfig?.loginToken;
    this.addExpertClass();
    this.expert.ExpertName = { English: '', French: '' };

    // get expert info based on login token employee number
    this._connexService.getExpertByUsername(this.loginToken.EmployeeNumber)
      .subscribe(response => {
        console.log(response);

        this.expert = response.Expert;
        this.event = response.Event;

        if (response.Event.Language === 'FR') {
          this.getFrenchText();
        }
        if (response.Event.EventID !== -1) {
          this.expertLang = response.Event.Language === 'FR' ? 'French' : 'English';
          this.eventDate = response.Event.EventDateString[this.expertLang];
          this.eventTopic = response.Event.EventTopic;
        } else {
          this.expertLang = response.Expert.Lang === 'FR' ? 'French' : 'English';
        }
        //Get eventid for the signalR connection
        this.eventId = response.Event.EventID;
        this.getQuestionsForExpert(this.expert.ExpertID, this.eventId);
        this.expertPhoto = this._emsConfig.assetPath + "/ImageAssets/originals/experts/" + response.Expert.PhotoUrl;
        this.connectSignalR(this.eventId);
        //subscribe to speech to text updates
        this.subscription = this._speechService.convertedText.subscribe(text => {
          this.answerEditor.setContent(this.answerEditor.getContent() + text);
        });
      });

    // subscribe to the hub connection status
    this.hubConnectionService.connectedStatus.subscribe((status: boolean) => {
      this.hubConnected = status;
    });
  }

  // If French expert, get the French text now
  getFrenchText() {
    // Load text for expert
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = this._emsConfig.apiPath + 'languageConfig.ashx?lang=FR';
    script.onload = () => {
      console.log('French text loaded');
    }
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  ngAfterViewInit() {
    var that = this;
    tinymce.init({
      selector: '#ate-answer-editor',
      entity_encoding: 'raw',
      plugins: 'link code lists preview autolink',
      toolbar:
        'undo redo | bullist numlist | bold italic underline | link unlink',
      browser_spellcheck: true,
      min_height: 200,
      menubar: false,
      inline_styles: true,
      forced_root_block: false,
      default_link_target: '_blank',
      init_instance_callback: function (editor) {
        // Below is special functionality to auto-save an experts answer as they type in the tiny editor. This will help
        // them recover answers faster in case of accidentally closing their browser, etc..
        // As they type in the tiny editor we save the answer to local storage as a key value pair. If it is a question they are responding to
        // we save the question ID as a the key (to prevent overwriting other answers). Otherwise if it's a comment, the key is 0
        // tinymce is added and setup dynamically after the component is initialized so angular's change event doesn't
        // work. To overcome this use tinymce's provided change event to get expert's typed input.
        editor.on('Change', function (e) {
          try {
            //save answer to local storage
            //set key id to 0 if expert is editing comment
            let id = that.modalMode === that.modalModeEnum.addComment ? 0 : that.editingAnswer.QuestionID;
            that._setWithExpiry(id, e.level.content, 2);
          } catch (error) {
            console.log(error);
          }
        });
      }
    });

  }
  private _setWithExpiry(key, value, hourstolive) {

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const now = new Date()
    let expireInHours = now.setHours(now.getHours() + hourstolive);
    const item = {
      value: value,
      expiry: expireInHours,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }
  private _getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }
  private connectSignalR(eventID: string): void {
    //add signalr client
    try {
      this.hubConnectionService.buildConnection(eventID);
      this.hubConnection = this.hubConnectionService.getConnection();
      // register all server events so we don't miss any messages
      this.registerOnServerEvents();

    } catch (err) {
      console.log(" Signalr connection error " + err);
    }
  }

  private registerOnServerEvents(): void {
    this.hubConnection.on('OnConnected', (message: any) => {
      console.log('####### HUB MESSAGE #######');
      console.log(message);
    });

    this.hubConnection.on('ReceiveQuestionFromModerator', (question: AteAnswer) => {
      console.log("Received question from the Moderator");
      if (question.Status !== AteQuestionStatus.Pending) {
        return;
      }
      console.log(question);
      const QIndex = this.answers.findIndex(x => x.QuestionID === question.QuestionID);
      if (QIndex > -1) {
        return;
      } else {
        this.answers.unshift(question);
      }
    });

    this.hubConnection.on('UpdateQuestion', (question: AteAnswer) => {
      console.log("UpdateQuestion");
      console.log(question);

      if (question.QuestionType > 1)
        return;

      if (question.Status === AteQuestionStatus.Answered || question.Status === AteQuestionStatus.Sent) {
        const answerIndex = this.answeredQuestions.findIndex(x => x.QuestionID === question.QuestionID);
        if (answerIndex > -1) {
          this.answeredQuestions[answerIndex] = question;
          console.log('updated answeredQuestions', this.answeredQuestions[answerIndex]);
        } else {
          this.removeAnswerFromList(question.QuestionID);
          question.Status = AteQuestionStatus.Answered;
          this.answeredQuestions.push(question);
        }
      } else {
        const QIndex = this.answers.findIndex(x => x.QuestionID === question.QuestionID);
        console.log(this.answers);
        console.log("found index", this.answers[QIndex]);

        if (QIndex > -1) {
          this.answers[QIndex].Email = question.Email;
          this.answers[QIndex].Question = question.Question;

          // Ensure question is updated if it is currently open in the editing modal
          if (question.QuestionID === this.editingAnswer.QuestionID) {
            this.editingAnswer.Question = question.Question;
          }

          console.log('updated pending question', this.answers[QIndex]);
        }
        console.log(this.answers);
      }
    });

    this.hubConnection.on('RemoveQuestionFromLiveChat', (question: AteAnswer) => {
      console.log("RemoveQuestionFromLiveChat");
      console.log(question);
      if (question.Status === AteQuestionStatus.Rejected) {
        console.log(this.answers);

        this.removeAnswerFromList(question.QuestionID);
        console.log('removed id', question.QuestionID);
      }
    });
    this.hubConnection.on('LiveChatStatus', (status: boolean, eventid: string) => {
      console.log('LiveChatStatus' + status + eventid);
    });
    this.hubConnection.onreconnecting(reconnecting => {
      console.log(reconnecting);
      this.hubConnected = false;
    });

    this.hubConnection.onclose(closed => {
      console.log(closed);
      this.hubConnected = false;
    });
    this.hubConnection.onreconnected(reconnected => {
      console.log(reconnected); this.hubConnected = true;
    });
  }

  ngOnDestroy(): void {
    this.confirmSubscription.unsubscribe();
    tinymce.remove(this.answerEditor);
    this.subscription?.unsubscribe();
  }
  private addExpertClass(): void {
    var body = document.body;
    body.classList.add("ems-expert");
  }
  private _handleSignalRReconnection() {

    //If page or tab is not visible and socket is disconnected then try reconnecting.
    if (this.hubConnection.state !== HubConnectionState.Connected) {
      console.log('Manually reconnecting.....');
      this.connectSignalR(this.eventId);
    }
  }

  subscription: Subscription;

  recordVoice() {
    console.log(this.speechToggle);
    this.speechToggle = !this.speechToggle;

    if (this.speechToggle) {
      this._speechService.startListening();
    } else {
      this._speechService.stopListening();
    }
  }

}
export enum AteModalMode {
  submitAnswer,
  addComment
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmsConfig } from '.././shared/emsConfig';
import { ClientCampaignBuild } from '.././shared/objects/ClientCampaignBuild';

// Message object
import { CustomMessage } from './custom-message';
import { MessageScheduleItem } from './message-schedule-item';
import { CanadaReportBuildItem } from 'app/cms/canadaReportBuildItem';
import { MessageCampaignList } from './message-campaign-list';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  // *******Messages URLS********
  private _getMessagesURL = this.emsConfig.apiEndpoint + '/messages/getmessagesforuser?username=';
  private _getMessageDetailsURL = this.emsConfig.apiEndpoint + '/messages/getmessagedetails?id=';
  private _createUpdateMessageURL = this.emsConfig.apiEndpoint + '/messages/createupdate';
  private _deleteMessageURL = this.emsConfig.apiEndpoint + '/messages/deletemessage?id=';

  private _getHeroImagesURL = this.emsConfig.apiEndpoint + '/messages/getheroimages?username=';
  private _getCtaCalloutButtonImagesURL = this.emsConfig.apiEndpoint + '/messages/getctacalloutbuttonimages';

  // *******Thumbnails URLS*******
  private _getCategoriesURL = this.emsConfig.apiEndpoint + '/messages/getcategoriesforuser?username=';

  // *******Messaging Tool URLS*******
  private _sendEmailURL = this.emsConfig.messagingPath + '/api/messages/sendemail';
  private _downloadSuppFileURL = this.emsConfig.messagingPath + '/api/messages/getsupplementaryfile?messageid=';


  // *******Users for Messages URLS*******
  private _getUsersByOrgURL = this.emsConfig.apiEndpoint + '/usertracking/getuserswithemailfororganization?orgName=';

  // *******Message Template URLS*******
  private _getMessageTemplatesURL = this.emsConfig.apiEndpoint + '/messages/getmessagetemplates';
  private _getTemplateDetailsURL = this.emsConfig.apiEndpoint + '/messages/gettemplatedetails?id=';
  private _createUpdateTemplateURL = this.emsConfig.apiEndpoint + '/messages/createupdatetemplate';
  private _deleteTemplateURL = this.emsConfig.apiEndpoint + '/messages/deletetemplate?id=';

  // *******Message Schedule URLS*******
  private _getScheduleURL = this.emsConfig.apiEndpoint + '/messages/getscheduleforuser?clientname=';
  private _updateScheduleURL = this.emsConfig.apiEndpoint + '/messages/updatescheduleforuser?username=';

  private _getGlobalPollListURL = this.emsConfig.apiEndpoint + '/polljson/polljsongetglobalpolllist';

  // *******Build w/ Console Apps URLS*******
  private _buildCanadaReportURL = this.emsConfig.messagingPath + '/api/consoleapps/buildcanadareport';
  // *******Campaign Messages URLS*******
  private _getCampaignListURL = this.emsConfig.apiEndpoint + '/campaign/getcampaignlistformessages';
  private _getCampaignConfigURL = this.emsConfig.apiEndpoint + '/campaign/getcampaignmessageconfig?campaignId=';
  private _getCampaignMessageImagesURL = this.emsConfig.apiEndpoint + '/campaign/getcampaignmessageimages?awsPath=';
  private _updateCampaignMessageConfigURL = this.emsConfig.apiEndpoint + '/campaign/updatecampaignmessageconfig';
  private _buildCampaignMessagesURL = this.emsConfig.apiEndpoint + '/messages/messagebuilder';

  private _getMessagesSiteTextURL = this.emsConfig.apiEndpoint + '/language/getResourceListByPageID?ID=1188';

  constructor(private emsConfig: EmsConfig, private _httpClient: HttpClient) { }

  // return a list of custom messages for a given user
  getMessagesForUser(username: string): Observable<CustomMessage[]> {
    return this._httpClient.get<CustomMessage[]>(this._getMessagesURL + username);
  }

  // return all message details for a given id
  getMessageDetails(id: number): Observable<CustomMessage> {
    return this._httpClient.get<CustomMessage>(this._getMessageDetailsURL + id);
  }

  // create or update a custom message in the DB, an ID of -1 will create a message
  createUpdateMessage(message: CustomMessage): Observable<any> {
    // create a deep copy of the object to avoid the original message's widgets property from getting stringified
    const messageCopy = JSON.parse(JSON.stringify(message));
    messageCopy.Widgets = JSON.stringify(messageCopy.Widgets);
    const body = JSON.stringify(messageCopy);
    return this._httpClient.post<any>(this._createUpdateMessageURL, body);
  }

  // delete a custom message
  deleteMessage(id: number): Observable<any> {
    return this._httpClient.post<any>(this._deleteMessageURL + id, '');
  }
  downloadSuppFiles(id: number): Observable<any> {
    return this._httpClient.post<any>(this._downloadSuppFileURL + id, '');
  }

  // return a list of hero images
  getHeroImages(username: string = null): Observable<any> {
    return this._httpClient.get<any>(this._getHeroImagesURL + username);
  }

  // return a list of categories for a given user
  getCategoriesForUser(username: string): Observable<any> {
    return this._httpClient.get<any>(this._getCategoriesURL + username);
  }

  // return a list of users for an organization whom have consented to receive emails
  getUsersForOrg(orgName: string): Observable<any> {
    return this._httpClient.get<any>(this._getUsersByOrgURL + orgName + '&fromProduction=true');
  }

  getCtaCalloutButtonImages() {
    return this._httpClient.get<any[]>(this._getCtaCalloutButtonImagesURL);
  }

  // send an email message via the messaging tool API
  sendEmail(messageID: number, title: string, recipients: string[], client: string, mailDirect: boolean, recipientVars = null): Observable<any> {
    const emailInfo = {
      'MessageID': messageID,
      'MessageTitle': title,
      'Recipients': recipients,
      'Client': client,
      'MailDirect': mailDirect,
      'RecipientVars': recipientVars
    };

    const body = JSON.stringify(emailInfo);
    return this._httpClient.post<any>(this._sendEmailURL, body);
  }

  // return a list of message templates
  getMessageTemplates(): Observable<CustomMessage[]> {
    return this._httpClient.get<CustomMessage[]>(this._getMessageTemplatesURL);
  }

  // return template details for a given id
  getTemplateDetails(id: number): Observable<CustomMessage> {
    return this._httpClient.get<CustomMessage>(this._getTemplateDetailsURL + id);
  }

  // create or update a custom template in the DB, an ID of -1 will create a message
  createUpdateTemplate(template: CustomMessage): Observable<any> {
    // create a deep copy of the object to avoid the original template's widgets property from getting stringified
    const templateCopy = JSON.parse(JSON.stringify(template));
    templateCopy.Widgets = JSON.stringify(templateCopy.Widgets);
    const body = JSON.stringify(templateCopy);
    return this._httpClient.post<any>(this._createUpdateTemplateURL, body);
  }

  // delete a custom template
  deleteTemplate(id: number): Observable<any> {
    return this._httpClient.post<any>(this._deleteTemplateURL + id, '');
  }


  // get a 12 month rolling message schedule for a user
  getMessageSchedule(username: string): Observable<MessageScheduleItem[]> {
    return this._httpClient.get<MessageScheduleItem[]>(this._getScheduleURL + username);
  }


  // update the monthly message schedule for user
  updateMessageSchedule(schedule: MessageScheduleItem[]): Observable<any> {
    const body = JSON.stringify(schedule);
    return this._httpClient.post<any>(this._updateScheduleURL, body);
  }

  // ===============================
  // Build w/ Console App Methods
  // ===============================
  // send an email message via the messaging tool API
  buildCanadaReport(buildReportItem: CanadaReportBuildItem): Observable<any> {
    const body = JSON.stringify(buildReportItem);
    return this._httpClient.post<any>(this._buildCanadaReportURL, body);
  }

  getCampaignMessageImages(awsPath: string) {
    return this._httpClient.get<any[]>(this._getCampaignMessageImagesURL + awsPath);
  }

  // send an email message via the messaging tool API
  buildCampaignMessages(buildCampaignUser: ClientCampaignBuild): Observable<any> {
    const body = JSON.stringify(buildCampaignUser);
    return this._httpClient.post<any>(this._buildCampaignMessagesURL, body);
  }
  // update campaign config
  updateCampaignMessageConfig(config: string, campaignId: number): Observable<any> {
    const body = JSON.stringify({ 'key': campaignId, 'value': config });
    return this._httpClient.post<any>(this._updateCampaignMessageConfigURL, body);
  }

  getCampaignMessageConfig(campaignId: number): Observable<any> {
    return this._httpClient.get<any>(this._getCampaignConfigURL + campaignId.toString());
  }

  // return a list of campaigns for messages
  getCampaignList(): Observable<MessageCampaignList[]> {
    return this._httpClient.get<MessageCampaignList[]>(this._getCampaignListURL);
  }

  // return EMS Messages page Site Text
  getEmsMessagesSiteText(): Observable<any> {
    return this._httpClient.get<any>(this._getMessagesSiteTextURL);
  }

  runMessageBuilder(campaignClient: ClientCampaignBuild): Observable<any> {
    let url = this.emsConfig.apiEndpoint + '/messages/messagebuilder';
    const body = JSON.stringify(campaignClient);
    return this._httpClient.post<any>(url, body);
  }

}

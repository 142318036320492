<div class="cms-campaign-details ems5">
  <div class="page-ribbon">
    <a [routerLink]="['/cms/campaigns']" class="page-header--link page-header f-dark-blue ls-subheading">
      <svg class="close" fill="#2699FB" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
        <path d="M0-.5h24v24H0z" fill="none" />
      </svg>
      <span>Campaigns</span>
    </a>
  </div>
  <div class="campaign-details-container">
    <syncstatus [assetID]="campaignDetails?.ID" [tableID]="1018"></syncstatus>
    <h1 class="campaign__title ls-title f-dark-blue">{{ campaignDetails?.Title }}</h1>
    <div class="campaign__settings section-padding">
      <section class="campaign__general-settings">
        <!-- General Settings Form -->
        <h2 class="title">General Settings</h2>
        <div class="info-container__text-container">
          <form class="form__general-settings edit" [formGroup]="settingsForm">
            <div class="row">
              <!-- Campaign Name -->
              <div class="col">
                <h4 class="edit__title required-field">Campaign Title</h4>
                <div class="input-container">
                  <input formControlName="CampaignName" type="text">
                </div>
              </div>
              <div class="col">
                <h4 class="edit__title required-field">Campaign Language</h4>
                <div class="select-container">
                  <select id="language" formControlName="CampaignLanguage"
                    (change)="languageChange($event.target.value)">
                    <option [selected]="true" value="EN">EN</option>
                    <option value="FR">FR</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <!-- Related ATE Event Select -->
                <h4 class="edit__title required-field">Related ATE Event</h4>
                <div class="select-container">
                  <select id="relatedEvent" formControlName="RelatedEvent">
                    <option value="" [selected]="true">Select Related ATE Event</option>
                    <option [ngValue]="ate.EventID" *ngFor="let ate of filteredAteEvents">{{ ate.EventTopic }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- Campaign Date Range Picker -->
              <div class="col rangepicker-container">
                <h4 class="edit__title required-field">Default Date Range</h4>
                <mat-form-field class="datepicker-field">
                  <mat-label></mat-label>
                  <mat-date-range-input [rangePicker]="cmsRangepicker">
                    <input type="text" matStartDate formControlName="StartDate" placeholder="Start Date">
                    <input type="text" matEndDate formControlName="EndDate" placeholder="End Date">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matsuffix [for]="cmsRangepicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #cmsRangepicker></mat-date-range-picker>
                </mat-form-field>
              </div>
              <div class="col datepicker-container">
                <!-- Campaign Preview Date Picker -->
                <h4 class="edit__title required-field">Preview Date</h4>
                <mat-form-field>
                  <mat-label></mat-label>
                  <input matInput formControlName="PreviewDate" [matDatepicker]="cmsDatepicker"
                    placeholder="Preview Date">
                  <mat-datepicker-toggle matSuffix [for]="cmsDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #cmsDatepicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <!-- Campaign Status Select -->
                <h4 class="edit__title required-field">Campaign Status</h4>
                <div class="select-container">
                  <select id="status" formControlName="CampaignStatus">
                    <option [selected]="true" value="1">Creation</option>
                    <option value="2">Bulkbuild</option>
                    <option value="3">Active</option>
                    <option value="4">Retired</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <!-- Linked Campaign Select (for French Campaigns) -->
                <div class="linked-campaign__container" *ngIf="campaignDetails.LanguageCode === 'FR'">
                  <h4 class="edit__title">Link English Campaign</h4>
                  <div class="select-container">
                    <select id="linkedCampaign" formControlName="LinkedCampaign">
                      <option [ngValue]="-1"></option>
                      <option [ngValue]="item.ID" *ngFor="let item of linkedCampaigns" [innerHTML]="item.Title">
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Linked Campaign read-only text (for English Campaigns) -->
                <div class="linked-campaign__container read-only"
                  *ngIf="campaignDetails?.LanguageCode === 'EN' && campaignDetails?.LinkCampaignTitle">
                  <h4 class="edit__title required">Linked French Campaign</h4>
                  <p class="linked-campaign__text">{{ campaignDetails?.LinkCampaignTitle }}</p>
                </div>
              </div>
            </div>
            <!-- TODO: Hiding for now until we figure out how to display campaigns on homepage -->
            <!-- <div class="row">
                            <div class="col">
                                <h4 class="edit__title">Featured Tile</h4>
                                <div class="select-container">
                                    <select name="featuredTile" id="featured-tile" formControlName="FeaturedTile">
                                        <option value="">Select tile</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col"></div>
                        </div> -->
            <div class="row" *ngIf="campaignDetails.ID !== -1">
              <div class="col">
                <!-- Campaign Image -->
                <h4 class="edit__title required-field">Campaign Image</h4>
                  <image-upload *ngIf="campaignDetails.AssetKey"
                    filePath="ImageAssets/originals/campaigns/landingPage"
                    [fileName]="campaignDetails.AssetKey"
                    [existingImageUrl]="campaignDetails.AssetKey ? _emsConfig.assetPath + '/ImageAssets/originals/campaigns/landingPage/' + campaignDetails.AssetKey + '.jpg' : ''"
                  ></image-upload>
              </div>
            </div>
            <div class="row">
              <div class="col btn-container">
                <!-- Global Save Button -->
                <button type="submit" class="save-btn btn"
                  (click)="openConfirmDialog('campaignDetails', 'addUpdateCampaignDetails', campaignDetails, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)">Save</button>

                <button *ngIf="campaignDetails.ID !== -1" class="btn ls-button-1 bulkbuild-btn" [disabled]="disableBuildBtn"
                  (click)="openConfirmDialog('campaignDetails', 'bulkBuildMessages', campaignDetails, 'Are you sure you wan to bulk build campaign messages for all the clients?')">Build
                  Messages for all clients</button>
              </div>
            </div>
          </form>
        </div>
      </section>

      <section class="campaign__content-settings">
        <!-- Content Settings Form Section -->
        <h2 class="title">Content</h2>
        <p class="ls-subheading" *ngIf="campaignDetails.CampaignSections.length === 0">There are currently no campaign
          content sections.</p>
        <div class="campaign__content-section info-container__text-container"
          *ngFor="let section of campaignDetails.CampaignSections; let sectionIndex = index">
          <h3 class="campaign__section-title ls-subheading f-dark-charcoal">Part {{sectionIndex + 1 }}</h3>
          <div class="row section__title-row">
            <!-- Campaign Section Title -->
            <div class="col">
              <h4 class="edit__title">Title</h4>
              <div class="input-container">
                <input type="text" name="title" [(ngModel)]="section.Title">
              </div>
            </div>
          </div>
          <div class="campaign__section info-container draggable">
            <div class="campaign__section--edit">
              <div [dragula]="'contents'" [(dragulaModel)]="section.Details">
                <form #form="ngForm" class="form__section-content"
                  *ngFor="let detail of section.Details; let assetIndex = index">
                  <!-- Content Settings Form -->
                  <div class="form-container">
                    <div #formItem class="form-item">
                      <div class="row info-container display-row" *ngIf="detail.ID !== -1">
                        <!-- Display Text for Blog Posts -->
                        <p class="display-text ls-subheading">{{detail.AssetType}} - {{ detail.AssetTitle }}</p>
                        <div class="icon-btn-container row">
                          <button class="edit-asset" (click)="detail.ID = -1" title="Edit Asset">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.491" height="13.491"
                              viewBox="0 0 13.491 13.491">
                              <path id="pencil"
                                d="M16.272,6.027a.746.746,0,0,0,0-1.057L14.518,3.217a.746.746,0,0,0-1.057,0L12.083,4.588l2.81,2.81M3,13.678v2.81H5.81l8.288-8.3-2.81-2.81Z"
                                transform="translate(-3 -2.997)" fill="#2699fb" />
                            </svg>
                          </button>
                          <button type="button" class="delete-asset"
                            (click)="deleteItem(campaignDetails?.ID, detail?.ID, false, sectionIndex, assetIndex)"
                            title="Delete Asset">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.278" height="14.5"
                              viewBox="0 0 11.278 14.5">
                              <path id="trash"
                                d="M16.278,3.806H13.458L12.653,3H8.625l-.806.806H5V5.417H16.278M5.806,15.889A1.611,1.611,0,0,0,7.417,17.5h6.444a1.611,1.611,0,0,0,1.611-1.611V6.222H5.806Z"
                                transform="translate(-5 -3)" fill="#2699fb" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div class="row info-container" *ngIf="detail.ID === -1">
                        <!-- Content Type Select -->
                        <div class="col">
                          <h4 class="edit__title">Content Type</h4>
                          <div class="select-container">
                            <select name="contentType" id="contentType" [(ngModel)]="detail.AssetTypeId" required>
                              <option [disabled]="true" [selected]="true" value="-1">Select Content Type</option>
                              <option [ngValue]="asset.AssetTypeId" *ngFor="let asset of assets">{{ asset.AssetType }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- delete asset button to appear here in Create mode -->
                        <div class="col btn-col create-col" *ngIf="campaignDetails?.ID === -1">
                          <button type="button" class="delete-asset"
                            (click)="deleteItem(campaignDetails?.ID, detail?.ID, false, sectionIndex, assetIndex)"
                            title="Delete Asset">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.278" height="14.5"
                              viewBox="0 0 11.278 14.5">
                              <path id="trash"
                                d="M16.278,3.806H13.458L12.653,3H8.625l-.806.806H5V5.417H16.278M5.806,15.889A1.611,1.611,0,0,0,7.417,17.5h6.444a1.611,1.611,0,0,0,1.611-1.611V6.222H5.806Z"
                                transform="translate(-5 -3)" fill="#2699fb" />
                            </svg>
                          </button>
                        </div>
                      </div>

                      <div class="subcontent__container--blog" *ngIf="detail.AssetTypeId == 7">
                        <!-- Blog Post Select -->
                        <div class="row info-container subcontent__container" *ngIf="detail.ID === -1">
                          <div class="col">
                            <div class="select-container">
                              <select name="blogpost" id="blogpost-{{ sectionIndex }}" [(ngModel)]="detail.AssetID" (change)="checkForDupe(sectionIndex, detail.AssetID, detail.AssetTypeId)">
                                <option [disabled]="true" [selected]="true" value="-1">Select Blog Post</option>
                                <option [ngValue]="blogpost.ID" *ngFor="let blogpost of blogposts">{{ blogpost.PostTitle
                                  }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="subcontent__container--tipsheet" *ngIf="detail.AssetTypeId == 2">
                        <!-- Tipsheet Select -->
                        <div class="row info-container subcontent__container" *ngIf="detail.ID === -1">
                          <div class="col">
                            <div class="select-container">
                              <select name="tipsheet" id="tipsheet-{{ sectionIndex }}" [(ngModel)]="detail.AssetID" (change)="checkForDupe(sectionIndex, detail.AssetID, detail.AssetTypeId)">
                                <option [disabled]="true" [selected]="true" value="-1">Select Tipsheet</option>
                                <option [ngValue]="tipsheet.ID" *ngFor="let tipsheet of tipsheets">{{
                                  tipsheet.VolumeTitle }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="subcontent__container--video" *ngIf="detail.AssetTypeId == 1">
                        <!-- Video Select Group -->
                        <div class="row info-container" *ngIf="detail.ID === -1">
                          <div class="col">
                            <div class="select-container">
                              <select name="categories" id="categories-{{ sectionIndex }}"
                                (change)="categoryChange($event.target.value, sectionIndex, assetIndex)">
                                <option [disabled]="true" [selected]="true" [value]="0">Select Category</option>
                                <!-- using [value] directive here and for the volume select below, as there are no [ngModel] values for [ngValue] to bind to. Using [ngValue] in this case was causing the value output to be the category string value being displayed in the front-end, instead of the numerical category ID we need -->
                                <option *ngFor="let category of categories" [value]="category.ID">{{
                                  category.CategoryName }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col">
                            <div class="select-container">
                              <select name="volumes" id="volumes-{{ sectionIndex }}"
                                (change)="volumeChange($event.target.value, sectionIndex, assetIndex)">
                                <option [disabled]="true" [selected]="true" value="">Select Volume</option>
                                <option *ngFor="let volume of volumes[detail.AssetCategory]" [value]="volume.ID">{{
                                  volume.VolumeTitle }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row info-container" *ngIf="detail.ID === -1">
                          <div class="col">
                            <div class="select-container">
                              <select name="videos" id="videos-{{ sectionIndex }}" [(ngModel)]="detail.AssetID" (change)="checkForDupe(sectionIndex, detail.AssetID, detail.AssetTypeId)">
                                <option [disabled]="true" [selected]="true" value="-1">Select Video</option>
                                <option *ngFor="let video of videos[detail.AssetVolume]" [ngValue]="video.ID">{{
                                  video.Title }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="row text-btn-row">
                <button class="text-btn ls-button-1 f-dark-blue" (click)="addAsset(section)">+Add Asset</button>
              </div>
            </div>
          </div>
          <div class="row section-btn-row btn-container">
            <!-- Global Save Button: no confirm dialog for this one (essentially using it to make saving assets easier) -->
            <button type="submit" class="save-btn btn" (click)="submitCampaignDetails()">Save</button>
            <!-- Delete Section Button -->
            <button type="button" class="btn delete-btn" *ngIf="campaignDetails.CampaignSections.length >= 1" (click)="deleteItem(campaignDetails?.ID, section.ID, true, sectionIndex)">Delete Section</button>
          </div>
        </div>
      </section>
      <div class="row section-btn-row btn-container">
        <button class="btn" (click)="addSection()">Add Section</button> <!-- Add Section Button -->
      </div>
    </div>
  </div>
</div>

import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EmsConfig } from "app/shared/emsConfig";

@Injectable({
    providedIn: 'root'
  })
  export class SnackbarService {

    constructor(
      private _snackBar: MatSnackBar,
      public _emsConfig: EmsConfig) {
    }

    error(message: string=this._emsConfig.text.EMS_General.Popup_Error, durationLimit:number=4000) {
      return this._snackBar.open(message, undefined, {panelClass: ['snackbar-error'], duration:durationLimit});
    }

    success(message: string=this._emsConfig.text.EMS_General.Popup_Success, durationLimit:number=4000) {
      return this._snackBar.open(message, undefined, {panelClass: ['snackbar-success'], duration:durationLimit});
    }

    warning(message: string=this._emsConfig.text.EMS_General.Popup_Error, durationLimit:number=4000) {
      return this._snackBar.open(message, undefined, {panelClass: ['snackbar-warning'], duration:durationLimit});
    }

    info(message: string, durationLimit:number=4000) {
      return this._snackBar.open(message, undefined, {panelClass: ['snackbar-info'], duration:durationLimit});
    }
  }

<div class="section-padding">

    <syncstatus [assetID]="cpdTitleID" [tableID]="41"></syncstatus>

  <button class="btn--back back pointer" [routerLink]="['/cms/cpd']">&#8592; Back</button>

  <h1>CPD Volumes Editor</h1>

  <div class="cpd-title-select">
    <h3>{{activeTitle?.Profession}} ({{activeTitle?.Lang}}) - {{activeTitle?.Title}}</h3>
    <h4>Status: {{activeTitle?.Status}}</h4>
    <div class="info-container">
      <h3 class="cpd-vol-toggle edit-link ls-subheading f-dark-blue info-container__heading"
        (click)="toggleEdit = !toggleEdit">Toggle Edit CPD Title</h3>
      <div class="cpd-add-info info-container__text-container" *ngIf="toggleEdit">
        <div class="cpd-add-wrapper">
          <div class="basic-info__setting input-container">
          <h4 class="basic-info__heading setting__heading ls-subheading">
            Title
          </h4>
            <input type="text" class="basic-info__text text-input" [(ngModel)]="titleDetails.Title" name="title">
        </div>
          <div class="basic-info__setting  select-container">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              Status
            </h4>
            <select class="setting__select-input select-input" name="lang" id="lang" [(ngModel)]="titleDetails.StatusID">
              <option [value]="item.ID" *ngFor="let item of statusList">{{item.Status}}</option>
            </select>
          </div>
        </div>
        <p [innerHTML]="_emsConfig.text?.EMS_Content.Cpd_Title_Status_Note"></p>

      </div>
      <button class="btn add-cpd-btn" (click)="updateCpdTitle()" *ngIf="toggleEdit">Update</button>
    </div>
  </div>



  <div class="add-cpd-vol info-container">
    <h3 class="cpd-vol-toggle edit-link ls-subheading f-dark-blue info-container__heading" (click)="toggleAdd = !toggleAdd">Toggle Add Volume</h3>

    <div class="cpd-add-info info-container__text-container" *ngIf="toggleAdd">
      <div class="sort-container__searchbar">
        <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        <input class="searchbar__input" [(ngModel)]="volSearch" type="search" placeholder="Volume search">
      </div>

      <div class="cpd-add-wrapper">
        <div class="select-container">
          <select id="cpd-vol-select" class="select-input" name="cpd-vol-select" [(ngModel)]="volId">
            <option value="-1" [selected]="true">Select Volume</option>
            <option *ngFor="let vol of volumes | volumeSearchFilter:volSearch" [value]="vol.ID">{{vol.VolumeTitle}}</option>
          </select>
        </div>

        <input type="number" class="cpd-mins" placeholder="minutes" [(ngModel)]="mins">

        <div class="select-container accreditation-container">
          <h4>Optional Accreditation Type</h4>
            <mat-form-field class="accreditation-full-width">
              <input type="text" [(ngModel)]="accType" placeholder="" matInput [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let acc of accTypes" [value]="acc">
                  {{acc}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
        </div>
      </div>
    </div>

    <button class="btn add-cpd-btn" (click)="addCpdVol()" *ngIf="toggleAdd">Add Volume</button>
  </div>

  <div class="table-container cpd-volume-list">
    <table class="display-table">
      <thead>
        <tr>
          <th>CPD ID</th>
          <th>Vol ID</th>
          <th>Volume Name</th>
          <th>Minutes</th>
          <th>Accreditation</th>
          <th>Volume Status</th>
          <th>Edit</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let vol of cpdVols" [ngClass]="{'edit-cpd-vol': vol.EditMode}">
          <td>{{vol.CPDID}}</td>
          <td>{{vol.ID}}</td>
          <td>{{vol.VolumeTitle}}</td>
          <td>
            <span *ngIf="!vol.EditMode">{{vol.CPDMinutesAccredited}}</span>
            <input *ngIf="vol.EditMode" type="number" class="cpd-mins" placeholder="minutes" [(ngModel)]="vol.CPDMinutesAccredited">
          </td>
          <td>
            <span *ngIf="!vol.EditMode">{{vol.AccreditationType}}</span>
            <div *ngIf="vol.EditMode" class="select-container accreditation-container">
                <mat-form-field class="accreditation-full-width">
                  <input type="text" [(ngModel)]="vol.AccreditationType" placeholder="" matInput [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option [value]=""></mat-option>
                    <mat-option *ngFor="let acc of accTypes" [value]="acc">
                      {{acc}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
            </div>
          </td>
          <td>{{getOnPointStatus(vol.EMSStatus)}}</td>
          <td>
            <!-- EDIT ICON -->
            <svg *ngIf="!vol.EditMode" (click)="editVol(vol.CPDID)" class="edit-link" width="24" height="24" viewBox="0 0 24 24">
              <path fill="#006B91" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
            </svg>

            <!-- SAVE ICON -->
            <svg *ngIf="vol.EditMode" (click)="updateVol(vol.CPDID)" class="edit-link" width="24" height="24" viewBox="0 0 24 24">
              <path fill="#69B8A8" d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />
            </svg>
          </td>
          <td>
            <!-- CLOSE EDIT ICON -->
            <svg *ngIf="vol.EditMode" (click)="closeEdit()" class="edit-link" width="24" height="24" viewBox="0 0 24 24" fill="#707070">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/>
            </svg>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>

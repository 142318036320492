// Angular Things
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LearningHubService } from 'app/services/learninghub.service';
import { Playlist } from 'app/shared/objects/playlist';
import { PopupService } from 'app/shared/popup.service';
import { UtilitiesService } from 'app/shared/utilities.service';

import * as moment from 'moment';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';

//  Services
import { CmsService } from '../cms.service';
import { PlaylistFilter } from '../cms-filters.pipe';

@Component({
  selector: 'cms-learninghub-list', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'learninghub-list.component.html'
})

export class CmsLearningHubComponent implements OnInit {
  @HostBinding('class') classAttribute: string = 'cms-playlists';

  public showEditButton: boolean;
  public activeFilter: string;
  public statusFilter: string;
  public search: string;
  public selectedLang: string = 'EN';
  public clientName: string;
  public playlists: any = [];
  public availablePlaylistsForClient: any = [];
  public sortCategory: string = 'ID';
  public sortCategoryCounter: number = 0;
  public displayModal: boolean;
  public playlistIdToAdd: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public cmsService: CmsService,
    public emsConfig: EmsConfig,
    private _playlistService: LearningHubService,
    private _popupService: PopupService,
    private _utilitiesService: UtilitiesService
  ) { }

  getPlaylists() {
    this.route.params.subscribe(params => {
      this.route.parent.params.subscribe(params => {
        this.clientName = params['id'];
        if (this.clientName) {
          this._playlistService.getClientPlayLists(this.clientName).subscribe(list => {
            this.playlists = list;
            this.sortBy('ID');

            this._playlistService.getAllPlayLists().subscribe(allPlaylists => {
              //sort the playlist alphabetically and also filterout archived ones
              this.availablePlaylistsForClient= new PlaylistFilter().transform(allPlaylists,'active');
            });
          });
        } else {
          this._playlistService.getAllPlayLists().subscribe(list => {
            // only sort the playlist alphabetically
            // it will not filter anything as we are sending no filter value to the pipe
            this.playlists= new PlaylistFilter().transform(list,'');
            //allows for multi-lang search filter
            for (let i = 0; i < this.playlists.length; i++) {
              this.playlists[i].TitleNormalized = this._utilitiesService.normalizeTerm(this.playlists[i].Title);
            }
          });
        }
      });
    });
  }

  // Nav to the playlist Editor
  navToPlaylistDetails = function (id: number,ParticipationID: number) {
  var extraparam= this.clientName ? `--${ParticipationID}` :'';
    this.router.navigate([`${id}${extraparam}`], { relativeTo: this.route });
  }


  selectPlaylist(playlist: Playlist) {
    console.log(playlist);
  }
  addPlaylist() {
    this._playlistService.addPlaylistToClient(this.playlistIdToAdd, this.clientName).subscribe(x => {
      console.log(x);
      console.log(this.playlistIdToAdd);
      if(x){
        this._popupService.updateNotification({ message: 'Playlist successfully added to ' + this.clientName, success: true });

        this.getPlaylists();
        this.displayModal=false;
      }else{
        this._popupService.updateNotification({
          message: 'Something went wrong! Update failed. Please refresh and try again.',
          error: true
      });
      }
    });
  }
  // Sort functionality for tables
  // Table header click triggers a new sort - first click sorts column ascending, second click sorts column descending, third click removes sorting for the column and defaults to sort by ID
  sortBy(key: string) {
    // Sorting logic
    if (key != this.sortCategory) {
      this.sortCategory = key;
      this.sortCategoryCounter = 1;
    } else {
      this.sortCategoryCounter++;
    }

    if (this.sortCategoryCounter > 2) {
      this.sortCategoryCounter = 0;
      this.sortCategory = 'ID';
    }

    // Sorting functions
    switch (this.sortCategoryCounter) {
      case 0: // default
      default:
        this.playlists.sort((a, b) => a['ID'] - b['ID']);
        break;
      case 1: // asc
        this.playlists.sort((a, b) => {
          if (key === 'ID') return a[key] - b[key]
          else if (key === 'DateCreated') return moment(a[key]).diff(moment(b[key]))
          else return a[key].localeCompare(b[key])
        })
        break;
      case 2: // desc
        this.playlists.sort((a, b) => {
          if (key === 'ID') return b[key] - a[key]
          else if (key === 'DateCreated') return moment(b[key]).diff(moment(a[key]))
          else return b[key].localeCompare(a[key])
        })
        break
    }
  }

  createNewPlaylist() {
    this.router.navigate([-1], { relativeTo: this.route });
  }

  onModalBodyClickEvent(event: any) {
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.getPlaylists();
  }
}

import { Component, OnInit } from '@angular/core';
import { EmsConfig } from '../../shared/emsConfig';
import { FileUploader } from 'ng2-file-upload';
import { SnackbarService } from 'app/services/Snackbar.service';
import { CmsService } from '../cms.service';
import { LanguageItem } from 'app/shared/objects/languages';
import { SubtitleLang } from 'app/shared/SubtitleLang';

@Component({
  selector: 'cms-subtitling-import',
  templateUrl: 'cms-subtitling-import.component.html'
})

export class CmsSubtitlingImportComponent implements OnInit {

  public response: string = "";
  public uploader: FileUploader;
  public isFileOverDropzone: boolean = false;
  public availableLangs: LanguageItem[] = [];
  public maxFileSize: number = 10 * 1024 * 1024;
  public fileLangCode: string;

  constructor(
    private _emsConfig: EmsConfig,
    private _cmsService: CmsService,
    private _snackbarService: SnackbarService
  ) {
    this.uploader = new FileUploader({
      url: this._emsConfig.apiEndpoint + '/assetadmin/uploadsubtitlefile', 
      maxFileSize: this.maxFileSize
    });

    this.uploader.response.subscribe(res => this.response = res);
  }

  // Check to ensure uploaded files match supported vtt naming convention
  public validateFileName(): void {
    this.uploader.queue = Object.values(this.uploader.queue).filter(item => {
      // File format should look like this: [langCode]-captions-[videoId].vtt(ex: fr-captions-123.vtt)
      let matchesNamingConvention = item.file.name.match(/^([a-z]{2})(?:-captions-)(\d{3,5})(?:.vtt)/)
      // File must have a language code prefix that matches the supported languages in our db
      let itemLangCode = item.file.name.slice(0,2).toLocaleLowerCase();
      this.fileLangCode = itemLangCode;
      if (itemLangCode !== this.fileLangCode) {
        this._snackbarService.error("Please upload files one language at a time")
      }
      let matchesAvailLangs = this.availableLangs.some(lang => lang.Code.toLowerCase() == itemLangCode)
      // File must be in .vtt format
      if (item.file.name.indexOf('.vtt') > -1) {
        if (matchesNamingConvention && matchesAvailLangs) {
          return item
        } else {
          this._snackbarService.error("Incorrect file name")
        }
      } else {
        this._snackbarService.error("Only .vtt files are permitted")
      }
    })
    
    this.validateFileData();
  }

  // Check to ensure video ID in uploaded vtt file name exists
  private validateFileData(): void {
    Object.values(this.uploader.queue).forEach(async item => {
        let videoId = Number(item.file.name.split('-captions-').pop().split('.vtt')[0])
        let videoIdExists = false;
        let videoTitle = ''
        await new Promise<void>(resolve => {
          this._cmsService.getModuleDetails(videoId).subscribe(res => {
            videoIdExists = res.media.ID > 0;
            videoTitle = res.media.Title;
            resolve();
          })
        })
        // By default, the file uploader plugin requires credentials for submission, this remove them
        item.withCredentials = false;
        item.isError = !videoIdExists;
    })
  }

  public uploadQueue(): void {
    this.uploader.queue.forEach(async item => {
      this.uploadItem(item)
    });
  }

  public async uploadItem(item): Promise<void> {
    this.uploader.options.additionalParameter = { 'langcode': this.fileLangCode };
    item.withCredentials = false;
    if (!item.isError) {
      item.upload(); // Upload file to AWS
      let subtitleLang: SubtitleLang = new SubtitleLang();
      subtitleLang.ClipID = Number(item.file.name.split('-captions-').pop().split('.vtt')[0]);
      subtitleLang.LangCode = this.fileLangCode;
      subtitleLang.CaptionUploaded = true;
      await new Promise<void>(resolve => {
        this._cmsService.getModuleDetails(subtitleLang.ClipID).subscribe(res => {
          subtitleLang.Title = res.media.Title;
          resolve();
        })
      })
      this.recordUpload(subtitleLang);
    }
  }

  // Add a new db record in Media_Subtitles table
  public recordUpload(subtitleLang: SubtitleLang): void {
    this._cmsService.addUpdateSubtitleForClip(subtitleLang).subscribe(data => {
      this._snackbarService.success("All subtitle files have been added");
    }, err => {
      this._snackbarService.error(err.message);
      return;
    });
  }

  ngOnInit(): void {
    // Get all available languages
    this._cmsService.getLanguages().subscribe(languages => {
      this.availableLangs = languages as LanguageItem[];
    })
    // Gets all the token api headers and adds to any upload request
    this.uploader.options.headers = this._emsConfig.getFileUploadHeaders();
  }

  ngOnDestroy() {
  }
}

// Angular Things
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';
import { UtilitiesService } from '../../shared/utilities.service';

// Classes
import { Expert } from '../../shared/expert';

// Components
import { CategoriesComponent } from '../../categories/categories.component';

//  Services
import { CmsService } from '../cms.service';

@Component({
  selector: 'cms-cpd', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-cpd.component.html'
})

export class CmsCpdComponent implements OnInit {
  @HostBinding('class') classAttribute: string = 'cpd-mgmt';

  titles: any[]= [];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public _cmsService: CmsService,
    public _emsConfig: EmsConfig,
    public _utilities: UtilitiesService
    ) { }

  // Nav to the CPD Volume Editor
  navToVolumes = function(profession: string, jurisdiction: string, language: string) {    
    this.router.navigate([profession, jurisdiction, language], {relativeTo: this.route});
  }

  getCpdTitles = function() {
    this._cmsService.getCpdTitles()
      .subscribe(res => {
        console.log(res);
        this.titles = res;
      });
  }

  ngOnInit(): void {
    this.getCpdTitles();    
  }

}

import { PipeTransform, Pipe } from '@angular/core';
import { SyncOperation } from './sync-operation';

@Pipe({
  name: 'syncTableFilter'
})
export class SyncTableFilter implements PipeTransform {
  transform(array: Array<SyncOperation>, args: string): Array<SyncOperation> {
    if (array == null) {
      return null;
    }

    array.sort((a: any, b: any) => {
      if (a[args] < b[args]) {
        return -1;
      } else if (a[args] > b[args]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}

@Pipe({
  name: 'syncOrgNameFilter'
})
export class SyncOrgNameFilter implements PipeTransform {
  transform(value: SyncOperation[], ...args: string[]): SyncOperation[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
    return filter ? value.filter((item: SyncOperation) =>
      item.OrganizationName.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}

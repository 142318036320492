import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';
import { VolumeListItem } from '../../shared/VolumeListItem';
import { UtilitiesService } from '../../shared/utilities.service';
import { SnackbarService } from 'app/services/Snackbar.service';
import { EmsConfig } from '../../shared/emsConfig';
import { LanguageItem } from 'app/shared/objects/languages';
import { Workbook } from 'exceljs';
import { saveAs } from "file-saver";
import * as moment from 'moment';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'cms-subtitling-export',
  templateUrl: 'cms-subtitling-export.component.html'
})

export class CmsSubtitlingExportComponent implements OnInit {

  public lang: string = 'EN';
  public langList:  LanguageItem[] = [];
  public langSelection: string[] = []; 
  public allVolumes: VolumeListItem[] = [];
  public volumeExportList = [];
  private _exportVolumeData = [];
  public isGenerateBtnDisabled: boolean = true;
  public sortCategory: string = 'ID';
  public sortCategoryCounter: number = 2; // This defaults the starting sort order to descending

  constructor(
    public _cmsService: CmsService,
    public _utilitiesService: UtilitiesService,
    public _emsConfig: EmsConfig,
    public _snackbarService: SnackbarService,
  ) {}
  
  getAllVolumes(lang: string): void {
    this.lang = lang;
    const onPoints = this._cmsService.getAllVolumes(true, lang)
    const volumes = this._cmsService.getAllVolumes(false, lang)
    forkJoin([onPoints, volumes]).subscribe(
      res => {
        this.allVolumes = [...res[0], ...res[1]].filter(v => v.EMSStatus !== 'retired'); // Filter any volumes with a retired status
        this.sort('ID');
      },
      err => { console.log(err) }
    )
  }

  // On filter click, toggle between languages and reset volume list
  volumeFilterClick(): void {
    this.getAllVolumes(this.lang === 'FR' ? 'EN' : 'FR') 
  }

  // Get all checked volumes from table
  setVolumeIdExportList(id: number): void {
    // create list of volume ids to pass to API request
    if (this.volumeExportList.find(i => i == id)) {
      this.volumeExportList = this.volumeExportList.filter(i => i !== id)
    } else {
      this.volumeExportList.push(id)
    }

    this.setDisabledButton();
  }

  // Get all checked langs from table
  setLangCodeExportList(lang: string): void {
    // create list of lang codes to pass to API request
    lang = lang.toUpperCase();
    if (this.langSelection.includes(lang)) {
      this.langSelection = this.langSelection.filter(i => i !== lang)
    } else {
      this.langSelection.push(lang)
    }

    this.setDisabledButton();
  }

  setDisabledButton(): void {
    this.isGenerateBtnDisabled = (this.volumeExportList.length < 1 || this.langSelection.length < 1)
  }

  // On generate button click
  getVolumeExportData(): void {
    if (!this.isGenerateBtnDisabled) {
      this._cmsService.getModuleListForSubtitling(this.volumeExportList, this.langSelection)
        .subscribe(
          res => {
            this._snackbarService.success("Your export file is being generated")
            this._exportVolumeData = res
          },
          err => { this._snackbarService.error(err.status + ' ' + err.statusText) },
          () => this.generateExportFile()
      );
    } else {
      this._snackbarService.error("Please select at least one volume and one language")
    }
  }

  // Create excel sheet after all data has been gathered
  generateExportFile(): void {
    // Create new excel work book
    let { worksheet, workbook } = this._setupExcelFormat();
    worksheet.addRows(this._exportVolumeData);
    // Add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `Captions_${moment(Date()).format('DD-MM-YYYY')}.xlsx`);
    });
  }

  private _setupExcelFormat() {
    let workbook = new Workbook();
    workbook.creator = 'LifeSpeak';
    workbook.lastModifiedBy = this._emsConfig.loginToken.EmployeeNumber;
    workbook.created = new Date();
    //add name to sheet
    const worksheet = workbook.addWorksheet('Share links', { views: [{ state: 'frozen', ySplit: 1 }] });
    //add column name
    worksheet.columns = [
      { header: 'Module ID', key: 'ModuleID' },
      { header: 'Volume ID', key: 'VolumeID' },
      { header: 'Title (original)', key: 'Title' },
      { header: 'Video Location', key: 'VideoLocation' },
      { header: 'Caption File Name', key: 'CaptionFileName' },
      { header: 'Lang Code', key: 'LangCode' },
      { header: 'Language', key: 'Language' },
      { header: 'Title (translated)', key: 'TitleTranslated' },
      { header: 'Transcripts', key: 'Transcripts' }
    ];
    return { worksheet, workbook };
  }

  // Sort functionality for tables
  // Table header click triggers a new sort - first click sorts column ascending, second click sorts column descending, third click removes sorting for the column and defaults to sort by ID
  sortBy(key: string) {
    // Sorting logic
    if (key != this.sortCategory) {
      this.sortCategory = key;
      this.sortCategoryCounter = 1;
    } else {
      this.sortCategoryCounter++;
    }

    if (this.sortCategoryCounter > 2) {
      this.sortCategoryCounter = 0;
      this.sortCategory = 'ID';
    }
    this.sort(key);
  }

  sort(key: string): void {
    // Sorting functions
    switch (this.sortCategoryCounter) {
      case 0: // default
      default:
        this.allVolumes.sort((a, b) => a['ID'] - b['ID']);
        break;
      case 1: // asc
        this.allVolumes.sort((a, b) => {
          if (key === 'ID') return a[key] - b[key]
          else return (a[key]).toString().localeCompare(b[key].toString())
        })
        break;
      case 2: // desc
        this.allVolumes.sort((a, b) => {
          if (key === 'ID') return b[key] - a[key]
          return (b[key]).toString().localeCompare(a[key].toString())
        })
        break
    }
  }

  ngOnInit(): void {
    this.getAllVolumes(this.lang);
    // Get all available languages
    this._cmsService.getLanguages().subscribe(languages => {
      this.langList = languages as LanguageItem[];
    });
  }

}

import { Expert } from './expert';
import { TextItem } from './TextItem';

export class ExpertEvent  {
  DivisionID: number;
  EventDate: Date;
  EventDateString: TextItem;
  EventDateStringEnd: TextItem;
  EventID: number = -1;
  EventModerator: string;
  EventTopic: TextItem = new TextItem();
  Language: string;
  Expert: Expert;
  ExpertID: number;
  StreamLive: boolean;
  For_Marathon:boolean;
  MarathonScheduleId: number = 0;
  ChatStartTime: string;
  ChatEndTime: string;
}

<h3>Answers Ready to Post</h3>
<table class="display-table">
  <thead>
    <tr>
      <th>ID</th>
      <th>Time</th>
      <th>Nickname</th>
      <th>Question / Answer</th>
      <th class="right-align">Post Answer</th>
      <th class="right-align">Move to trash</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let question of questions | connexStatusFilter:3">
      <td>
        <div (click)="modifyQuestion(question)" class="question-id">
          <svg class="pointer" *ngIf="question.QuestionType > 1" xmlns="http://www.w3.org/2000/svg" width="24"
            height="24" viewBox="0 0 24 24" fill="#006B91">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
          </svg>
          <a class="edit-link">{{question.QuestionID}}</a>
        </div>
      </td>
      <td>{{question.DateSubmittedFormatted}}</td>
      <td>{{question.Email}}</td>
      <td class="max-q-width question-answer-display">
        <p><span *ngIf="question.QuestionType === 1">Question:</span><span
            *ngIf="question.QuestionType > 1">Comment:</span></p>
        <a (click)="modifyQuestion(question)" class="question-container edit-link" [innerHTML]="question.Question"></a>
        <p *ngIf="question.QuestionType === 1">Answer:</p>
        <div *ngIf="question.QuestionType === 1" class="answer" [innerHTML]="question.Answer"></div>
      </td>
      <td class="right-align"><button
          (click)="confirmAction('postAnswer', _emsConfig.text?.EMS_General.Confirm_Message, 4, question)"
          class="btn ls-button-1">Post<span *ngIf="question.QuestionType > 1"> comment</span></button></td>
      <td class="right-align"><button (click)="sendQuestion(question, 5)"
          class="btn ls-button-1 btn--delete-bg">Trash</button></td>
    </tr>
  </tbody>
</table>

<h3>Pending with Expert</h3>
<table class="display-table">
  <thead>
    <tr>
      <th>ID</th>
      <th>Time</th>
      <th>Nickname</th>
      <th>Question</th>
      <th class="right-align">Move to trash</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let question of questions | connexStatusFilter:2">
      <td (click)="modifyQuestion(question)"><a class="edit-link">{{question.QuestionID}}</a></td>
      <td>{{question.DateSubmittedFormatted}}</td>
      <td>{{question.Email}}</td>
      <td class="max-q-width"><a (click)="modifyQuestion(question)" class="question-container edit-link"
          [innerHTML]="question.Question"></a></td>
      <td class="right-align"><button (click)="sendQuestion(question, 5)"
          class="btn ls-button-1 btn--delete-bg">Trash</button></td>
    </tr>
  </tbody>
</table>

<h3>Unassigned Questions</h3>
<table class="display-table">
  <thead>
    <tr>
      <th>ID</th>
      <th>Date & Time</th>
      <th>Client</th>
      <th>Nickname</th>
      <th>Question</th>
      <th class="right-align">Post Question</th>
      <th class="right-align">Move to trash</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let question of questions | connexStatusFilter:1">
      <td>
        <div (click)="modifyQuestion(question)" class="question-id">
          <svg class="pointer" *ngIf="question.QuestionType > 1" xmlns="http://www.w3.org/2000/svg" width="24"
            height="24" viewBox="0 0 24 24" fill="#006B91">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
          </svg>
          <a class="edit-link">{{question.QuestionID}}</a>
        </div>
      </td>
      <td>{{question.DateSubmitted | date:'MMM d h:mm a'}}</td>
      <td>{{question.ClientName}}</td>
      <td>{{question.Email}}</td>
      <td class="max-q-width"><a (click)="modifyQuestion(question)" class="question-container edit-link"
          [innerHTML]="question.Question"></a></td>
      <td class="right-align">
        <button *ngIf="question.QuestionType === 1" (click)="sendQuestion(question, 2)" class="btn ls-button-1"
          [disabled]="disableButtons">Post</button>
        <button *ngIf="question.QuestionType > 1" (click)="sendQuestion(question, 4)" class="btn ls-button-1"
          [disabled]="disableButtons">Post</button>
      </td>
      <td class="right-align"><button (click)="sendQuestion(question, 5)" class="btn ls-button-1 btn--delete-bg"
          [disabled]="disableButtons">Trash</button></td>
    </tr>
  </tbody>
</table>

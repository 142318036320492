export class CampaignDetails {
  ID: number;
  Length: number;
  Description: string;
  StartDate: string;
  EndDate: string;
  DefaultPreviewDate: string;
  LanguageCode: string;
  Title: string;
  Subtitle: string;
  CampaignStatus: CampaignStatus;
  CampaignSections: CampaignSection[] = [];
  RelatedEvent: RelatedEvent = new RelatedEvent();
  LinkCampaignId: number;
  LinkCampaignTitle: string;
  AssetKey?: string;

}

export class CampaignSection {
  ID: number = -1;
  Title: string;
  Details: CampaignAsset[] = [];
}

export class CampaignAsset {
  ID: number = -1;
  AssetID: number = -1;
  AssetTypeId: number = -1;
  AssetType: string;
  AssetTitle: string;
  SortOrder: number;
  AssetCategory: number = 0;
  AssetVolume: number = 0;
}

export enum CampaignStatus {
  Creation = 1,
  Bulkbuild = 2,
  Active = 3,
  Retired = 4
}

export class RelatedEvent {
  EventID: number;
  Topic: string;
}

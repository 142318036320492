// Angular Things
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Feature Modules
import { SyncModule } from './sync/sync.module';
import { CmsModule } from './cms/cms.module';
// Shared Module
import { SharedModule } from './shared/shared.module';

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './home/login.component';
import { ChangePassComponent } from './home/changePass.component';
import { DashboardComponent } from './home/dashboard.component';
import { AnalyticsQueryComponent } from './shared/analytics-query.component'

// Services
import { AuthGuard } from './authGuard';
import { AuthGuardSysAdmin } from './authGuardSysAdmin';
import { SecuredRoutes } from 'app/shared/secured-routes';
import { LoginService } from './home/login.service';
import { EmsConfig } from './shared/emsConfig';
import { DashboardNameFilter } from './home/dashboard.pipe';
import { HttpRequestInterceptor } from './interceptor.module';

// Connex
import { ConnexModule } from './connex/connex.module';

// Directives
import { ClickOutsideDirective } from './helpers/clickOutside.directive';
import { NgChartsModule } from 'ng2-charts';

// Third Party
import * as moment from 'moment';
import { ConfirmService } from './shared/confirm.service';
import { PopupService } from './shared/popup.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DragulaModule } from 'ng2-dragula';
import { CookieService } from 'ngx-cookie-service';

import { AppConfigService } from './services/AppConfigService.service';
export function appConfigInit(appConfigService: AppConfigService) {
  return () => {
    return appConfigService.load()
  };
}
@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgChartsModule,
    AppRoutingModule,
    SharedModule,
    ConnexModule,
    SyncModule,
    CmsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSnackBarModule,
    DragulaModule.forRoot()
  ],       // module dependencies
  declarations: [
    AppComponent,
    LoginComponent,
    ChangePassComponent,
    DashboardComponent,
    ClickOutsideDirective,
    DashboardNameFilter,
    AnalyticsQueryComponent
  ],   // components, pipes and directives
  bootstrap: [AppComponent],     // root component
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigInit,
      multi: true,
      deps: [AppConfigService]
    },
    AuthGuard,
    AuthGuardSysAdmin,
    SecuredRoutes,
    LoginService,
    ConfirmService,
    PopupService,
    DatePipe,
    MatDatepickerModule,
    MatNativeDateModule,
    EmsConfig, CookieService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ]
})
export class AppModule { }

<div class="inner-page-main section-padding expert-specialities-container">

    <div class="basic-container module-details-container__info-container">
        <button class="btn--back back pointer" (click)="getBack()">&#8592; Back</button>
      <h3 class="ls-subheading f-dark-blue info-container__heading expert-speciality-heading">Expert Specialities</h3>

      <div class="searchbox-container">
        <div class="sort-container__searchbar">
          <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          <input class="searchbar__input" [(ngModel)]="specialitySearch" type="search" placeholder="Search for speciality">
        </div>
        <a class="expert-link" href="javascript:void(0)" (click)="toggleSpecialitySection()"> {{addEditDeleteText}}</a>
      </div>


      <div class="details-container info-container__text-container">
        <div class="expert-speciality-edit-container" *ngIf="showSpecialitySection">
          <div class="select-container">
            <select class="setting__select-input select-input" name="volumeCategory" [(ngModel)]="categoryID">
              <option [disabled]="true" [selected]="true" value="">Category</option>
              <option value="{{category.ID}}" *ngFor="let category of categoryList">{{category.Name.English}}</option>
            </select>
          </div>
          <div class="input-container">
            <input type="text" class="basic-info__text setting__text-input text-input" name="speciality" [(ngModel)]="speciality" placeholder="Speciality">
          </div>
          <button class="btn b-dark-blue ls-button-2 button-extra-radius" (click)="addSpeciality()">Add</button>
        </div>

        <div *ngFor="let catitem of specialitiesByGroup| expertSpecialitySearchFilter:specialitySearch">
          <h5>{{catitem.CategoryName}}</h5>
          <ul>
          <li [ngClass]="{'edit-speciality': showSpecialitySection}" *ngFor="let item of catitem.SpecialityDetails">
              <div class="speciality-delete-button" (click)=deteteSpeciality(item) *ngIf="showSpecialitySection">
              <svg xmlns="http://www.w3.org/2000/svg" width="9.725" height="10" viewBox="0 0 9.725 10"><defs><style>.a{fill:#ee6a53;}</style></defs>
                <path class="a" d="M7.039,3v.556H4V4.667h.608v7.222A1.167,1.167,0,0,0,5.823,13H11.9a1.167,1.167,0,0,0,1.216-1.111V4.667h.608V3.556H10.686V3H7.039m0,2.778H8.255v5H7.039v-5m2.431,0h1.216v5H9.47Z" transform="translate(-4 -3)"/></svg>
             </div>
             {{ item.speciality }}
          </li>
        </ul>
        </div>

      </div>

  </div>
</div>

import { Injectable } from '@angular/core';
import { UserPermissions } from './user-permissions';
import { EmsConfig } from './emsConfig';
import { UrlSegment } from '@angular/router';


@Injectable()
export class SecuredRoutes {

  private SecuredRoutesTable: any[] = [
    {
      route: 'AggregatePersonalizedByDate',
      permissionType: UserPermissions.PersonalReportAccess
    },
    {
      route: 'AggregatePersonalizedViews',
      permissionType: UserPermissions.PersonalReportAccess
    },
    // this is for syncing lifespeak assets
    {
      route: 'lifespeakasset',
      permissionType: UserPermissions.CMSAccess | UserPermissions.ATEAccess
    },
    {
      route: 'connex',
      permissionType: UserPermissions.ConnexAccess
    },
     {
      route: 'cms',
      permissionType: UserPermissions.CMSAccess
    },
    {
      route: 'asktheexpert',
      permissionType: UserPermissions.ATEAccess
    },
    {
      route: 'blog',
      permissionType: UserPermissions.BlogAccess
    }
  ];

  constructor(public _emsConfig: EmsConfig) { }

  public RouteIsSecure(routeName: string) {

    return this.SecuredRoutesTable.map(entry => entry.route).includes(routeName);

  }

  public FindAvailableRouteByPermission(): any {
    const route: any = this.SecuredRoutesTable.find(entry => this._emsConfig.PermissionVal === entry.permissionType);
    return route;
  }

  // this checks a user's permission to see if they have access to a given route
  public CurrentUserHasAccess(urlStr: string, permittedByDefault: boolean): boolean {

    // system admins always have access
    if (this._emsConfig.UserRole === 'systemadmin') {
      return true;
    }

    // check the table to see if the token exists for this route
    const route: any = this.SecuredRoutesTable.find(entry => urlStr.indexOf(entry.route) !== -1);

    // if no route can be found, the secureByDefault flag determines the action
    if (route === undefined) {
      if (this._emsConfig.UserRole === 'accountadmin') {
        return permittedByDefault;
      } else {
        return false;
      }
    }

    // if a match is found check if the user has access
    if (this._emsConfig.PermissionVal & route.permissionType) {
      return true;
    }

    return false;
  }
}

<div class="blog-container inner-page-container">
    <div class="vertical-nav">
        <ul class="main">
            <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/blog/posts']" [routerLinkActive]="['active']">Posts</li>
            <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/blog/categories']" [routerLinkActive]="['active']">Categories</li>
            <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/blog/tags']" [routerLinkActive]="['active']">Tags</li>
            <li class="vertical-nav__list-item ls-subheading" [routerLink]="['/blog/featuredposts']" [routerLinkActive]="['active']">Featured Posts</li>
        </ul>
    </div>
    <div class="inner-page-main">
        <router-outlet></router-outlet>
    </div>
</div>

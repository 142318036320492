<!-- allow sys admins to edit prompt before sending requests -->
<div *ngIf="enableAI && _emsConfig.UserRole === 'systemadmin'" class="ai-ui ai-ui--completion input-container">
    <div *ngIf="!aiChatData[prompt].showPrompt" class="ai-ui__wrapper ai-ui__hide-prompt">
        <button (click)="aiChatData[prompt].showPrompt = !aiChatData[prompt].showPrompt" class="btn btn--ai ai-ui__open">
            <svg viewBox="0 0 24 24"><path class="cls-1" d="M21,11v-2h-2v-2c0-.5-.2-1-.6-1.4s-.9-.6-1.4-.6h-2v-2h-2v2h-1v-1.1c-.3-.3-.7-.5-1-.7s-.8-.2-1.2-.2c-.9,0-1.6.3-2.2.9s-1,1.3-1,2.2c-1,.1-1.8.6-2.5,1.3-.7.7-1,1.6-1,2.6s0,.7.1,1c0,.3.2.7.4,1-.2.3-.3.6-.4,1,0,.3-.1.7-.1,1,0,1,.3,1.9,1,2.7s1.5,1.2,2.5,1.3c0,.8.4,1.5,1,2.1.6.6,1.4.9,2.2.9s.8,0,1.2-.2c.4-.2.7-.4,1-.7v-1.1h1v2h2v-2h2c.5,0,1-.2,1.4-.6s.6-.9.6-1.4v-2h2v-2h-2v-2h2ZM11,17.8c0,.3-.1.6-.4.9-.2.2-.5.4-.9.4s-.6-.1-.9-.4c-.2-.3-.4-.6-.4-.9.4-.1.7-.3,1-.5s.6-.5.8-.8c.2-.2.2-.5.2-.8s-.2-.5-.4-.6c-.2-.2-.5-.2-.8-.2-.3,0-.5.2-.7.4-.2.3-.4.5-.7.6s-.6.2-.9.2c-.5,0-1-.2-1.4-.6s-.6-.9-.6-1.4,0-.2,0-.2c0,0,0-.2,0-.2.3.2.6.3.9.4s.7.1,1,.1.5,0,.7-.3c.2-.2.3-.4.3-.7s0-.5-.3-.7c-.2-.2-.4-.3-.7-.3-.5,0-1-.2-1.4-.6s-.6-.9-.6-1.4.2-1,.6-1.4.9-.6,1.4-.6c.2.3.4.6.7.8.3.2.6.4,1,.5.3,0,.5,0,.8,0,.2-.1.4-.3.5-.6,0-.3,0-.5,0-.8-.1-.2-.3-.4-.6-.5-.2,0-.5-.2-.6-.5-.1-.2-.2-.5-.2-.7s.1-.6.4-.9c.2-.2.5-.4.9-.4s.6.1.9.4c.2.2.4.5.4.9v11.5ZM12,11h1v2h-1v-2ZM17,17h-5v-2h3v-6h-3v-2h5v10Z"/></svg>
            {{ aiChatData[prompt].ctaText }}
        </button>
        <tooltip class="ai-ui" [tooltipMessage]="aiChatData[prompt].tooltipMessage"></tooltip>
    </div>
    <div *ngIf="aiChatData[prompt].showPrompt" class="ai-ui__wrapper ai-ui__show-prompt">
        <!-- Optional textarea to edit AI prompt-->
        <textarea [(ngModel)]="aiChatData[prompt].prompt" class="ai-ui__prompt" name="ai-ui"></textarea>
        <div class="ai-ui__buttons">
            <!-- Optional Temperature input to adjust randomness -->
            <input *ngIf="aiChatData[prompt].showTemperature" 
                type="number" 
                min="0" max="1" 
                step="0.1"
                [(ngModel)]="aiChatData[prompt].temperature" 
                class="ai-ui__temperature" 
            />
            <tooltip *ngIf="aiChatData[prompt].showTemperature" class="ai-ui ai-ui--temperature" tooltipMessage="The sampling temperature, between 0 and 1. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic."></tooltip>
            <button (click)="getChatCompletion()" [disabled]="aiChatData[prompt].prompt.length < 1 || !content || generatingAI" class="btn btn--ai">
                {{ generatingAI ? "Sending..." : "Send"}}
            </button>
            <button (click)="aiChatData[prompt].showPrompt = !aiChatData[prompt].showPrompt" class="ai-ui__close">
                <svg viewBox="0 0 24 24"><path fill="#111F50" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
            </button>
        </div>
    </div>
</div>

<!-- non sys admins should only send requests with predetermined prompt -->
<div *ngIf="enableAI && _emsConfig.UserRole !== 'systemadmin'" class="ai-ui input-container">
    <div class="ai-ui__wrapper ai-ui__hide-prompt">
        <button (click)="getChatCompletion()" class="btn btn--ai ai-ui" [disabled]="aiChatData[prompt].prompt.length < 1 || !content || generatingAI">
            <svg viewBox="0 0 24 24"><path class="cls-1" d="M21,11v-2h-2v-2c0-.5-.2-1-.6-1.4s-.9-.6-1.4-.6h-2v-2h-2v2h-1v-1.1c-.3-.3-.7-.5-1-.7s-.8-.2-1.2-.2c-.9,0-1.6.3-2.2.9s-1,1.3-1,2.2c-1,.1-1.8.6-2.5,1.3-.7.7-1,1.6-1,2.6s0,.7.1,1c0,.3.2.7.4,1-.2.3-.3.6-.4,1,0,.3-.1.7-.1,1,0,1,.3,1.9,1,2.7s1.5,1.2,2.5,1.3c0,.8.4,1.5,1,2.1.6.6,1.4.9,2.2.9s.8,0,1.2-.2c.4-.2.7-.4,1-.7v-1.1h1v2h2v-2h2c.5,0,1-.2,1.4-.6s.6-.9.6-1.4v-2h2v-2h-2v-2h2ZM11,17.8c0,.3-.1.6-.4.9-.2.2-.5.4-.9.4s-.6-.1-.9-.4c-.2-.3-.4-.6-.4-.9.4-.1.7-.3,1-.5s.6-.5.8-.8c.2-.2.2-.5.2-.8s-.2-.5-.4-.6c-.2-.2-.5-.2-.8-.2-.3,0-.5.2-.7.4-.2.3-.4.5-.7.6s-.6.2-.9.2c-.5,0-1-.2-1.4-.6s-.6-.9-.6-1.4,0-.2,0-.2c0,0,0-.2,0-.2.3.2.6.3.9.4s.7.1,1,.1.5,0,.7-.3c.2-.2.3-.4.3-.7s0-.5-.3-.7c-.2-.2-.4-.3-.7-.3-.5,0-1-.2-1.4-.6s-.6-.9-.6-1.4.2-1,.6-1.4.9-.6,1.4-.6c.2.3.4.6.7.8.3.2.6.4,1,.5.3,0,.5,0,.8,0,.2-.1.4-.3.5-.6,0-.3,0-.5,0-.8-.1-.2-.3-.4-.6-.5-.2,0-.5-.2-.6-.5-.1-.2-.2-.5-.2-.7s.1-.6.4-.9c.2-.2.5-.4.9-.4s.6.1.9.4c.2.2.4.5.4.9v11.5ZM12,11h1v2h-1v-2ZM17,17h-5v-2h3v-6h-3v-2h5v10Z"/></svg>
            {{ aiChatData[prompt].ctaText }}
        </button>
        <tooltip class="ai-ui" [tooltipMessage]="aiChatData[prompt].tooltipMessage" ></tooltip>
    </div>
</div>

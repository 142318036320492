<div class="sync-status cacheclear-icon" *ngIf="instantCacheClear" [ngClass]="{'unsynced': !syncStatus }">
 <span>
   <svg class="magic-circle" viewBox="0 0 60 55" width="50" height="50" xmlns="http://www.w3.org/2000/svg">
     <path fill="#00D1B2"
     d="m19.2 36.4-4.75-10.45L4 21.2l10.45-4.75L19.2 6l4.75 10.45L34.4 21.2l-10.45 4.75ZM36.4 42l-2.35-5.25-5.25-2.35 5.25-2.4 2.35-5.2 2.4 5.2 5.2 2.4-5.2 2.35Z" />
    </svg>Instant cache clearing on sync.
  </span>
</div>

<div class="sync-status" [ngClass]="{'synced': syncStatus && !isAssetSync, 'sync-status--org': !isAssetSync}">
  <!-- only show these icons for org sync -->
  <svg id="synced-icon" class="sync-status__icon" *ngIf="!isAssetSync && !hasChildRecords" [ngClass]="{'synced': syncStatus}" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" />
  </svg>
  <svg id="unsynced-icon" class="sync-status__icon" *ngIf="!isAssetSync && !hasChildRecords"  [ngClass]="{'synced': syncStatus}" viewBox="0 0 24 24">
    <path fill="currentColor" d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
  </svg>

  <span class="sync-status__text"  (click)="executeAssetSync()"  [ngClass]="{'synced': syncStatus,'sync-clickable': isAssetSync && !hasChildRecords}" [innerHtml]="syncMessage"></span>
  <svg  *ngIf="isAssetSync && !hasChildRecords"  [ngClass]="{'synced': syncStatus}" (click)="getAssetSyncInfo()" class="sync-status__info" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 14H6v-2h2v2zm0-3H6V9h2v2zm0-3H6V6h2v2zm7 6h-5v-2h5v2zm3-3h-8V9h8v2zm0-3h-8V6h8v2z"/>
  </svg>
  <div class="sync-status__option"  *ngIf="isAssetSync && hasChildRecords">
    <a class="sync-asset-only" href="javascript:void(0);"  (click)="executeAssetSyncWithParentOnly()">{{_emsConfig.text?.EMS_Sync.Sync_ParentOnly}}  </a>
    <a class="sync-asset-with-child" href="javascript:void(0);"  (click)="executeAssetSyncWithChildAssets()">{{_emsConfig.text?.EMS_Sync.Sync_ParentAndChildren}} </a>
    <a href="javascript:void(0);"  (click)="getAssetSyncInfo()">Sync History</a>
  </div>
</div>


<!--modal for displaying information about a given sync task-->
<div class="modal-window sync-description-modal" id="asset-sync-info-modal" *ngIf='modalOpen' (click)="closeModal()">
  <div class="modal-container b-white" (click)="stopProp($event)">
    <div class="modal__title-container b-dark-blue">
      <h3 class="modal__title ls-headline f-white">Operation Info</h3>
      <button class="welcome-page-modal__close modal__close" (click)="closeModal()">
        <svg viewBox="0 0 24 24">
          <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" fill="#ffffff"></path>
        </svg>
      </button>
    </div>
    <div class="modal__settings-container" style="height:auto">

      <div id="sync-notice"> This table shows the difference between this record on the staging and production sites<br/>
      Clicking the submit button below will push all your changes live. Changes will appear on the production site once
      the cache has cleared (approximately  2 hours)
      </div>
      <table class="display-table--extra-padding">
        <tbody>
          <tr>
            <td>Current Status</td>
            <td>{{ assetInfo.SyncStatus}} </td>
          </tr>
          <tr>
            <tr *ngIf="assetInfo.AddUser!='NO_USER'">
              <td>Last Modified</td>
              <td>{{ assetInfo.EventAddedDateString }} by {{assetInfo.AddUser}}</td>
            </tr>
            <tr *ngIf="assetInfo.SyncUser!='NO_USER'">
              <td>Last Synced</td>
              <td>{{ assetInfo.EventSyncedDateString }} by {{assetInfo.SyncUser}}
                <br/> {{assetInfo.EventNotes}}
              </td>
            </tr>
           <tr *ngIf="assetInfo.EventChanges!=''">
                <td>Unsynced fields</td>
                <td [innerHTML]="assetInfo.EventChanges">
                </td>
              </tr>
        </tbody>
      </table>
      <div class="execute-sync">


        <label>Notes about sync (optional)</label>
        <input  [(ngModel)]="assetSyncRequest.notes"  class="basic-info__text setting__text-input text-input" type="text"/>
        <button class="btn" (click)= "executeAssetSync()"> Sync</button>
      </div>


    </div>

  </div>

</div>

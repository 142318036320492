<div class="inner-page-main section-padding  expert-details">
    <syncstatus [assetID]="allResourceTranslations[0]?.GroupId" [tableID]="11" ></syncstatus>

  <div class="module-details-container edit-event-details">
    <div class="basic-container module-details-container__info-container">
        <button class="btn--back back pointer" (click)="getBack()">&#8592; Back</button>
      <h3 class="ls-subheading f-dark-blue info-container__heading">Resource Details</h3>

      <div class="details-container info-container__text-container ">
        <div class="info-container__section-split info-container__basic-details">
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z"
                  />
                </svg>
              </div>
              Page ID/ Name
            </h4>
            <p class="basic-info__text setting__text ls-subheading">{{pageID}} / {{pageName}}</p>
          </div>

          <div class="basic-info__setting resource-name">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                </svg>
              </div>
              Resource Name
            </h4>
            <p class="basic-info__text setting__text ls-subheading">{{resourceKey}}</p>
          </div>

          <div class="translations-table">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                </svg>
              </div>
              Available Translations
            </h4>
            <div class="table-container">
              <table class="display-table display-table--extra-padding">
                <thead>
                  <tr>
                    <th>Language</th>
                    <th>Translation</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor=" let t of resourceTranslations">
                    <td class="text-lang-name">{{t.LangName}}</td>
                    <td class="text-translation" [innerHTML]="t.ResourceValue | slice:0:150"></td>
                    <td class="text-edit text-btn" *ngIf="t.ResourceValue != null" (click)="selectTranslation(t.LangCode)">Edit</td>
                    <td class="text-add text-btn" *ngIf="t.ResourceValue === null" (click)="createTranslation(t.LangCode)">Add</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="info-container__section-split info-container__add-edit-container" *ngIf="showAddEditSection">
          <!-- edit form -->
          <form action="" class="edit-translation" *ngIf="showEditForm">
            <div class="basic-info__setting resource-name">
              <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                  </svg>
                </div>
                Resource Name
              </h4>
              <div class="input-container">
                <input type="text" class="basic-info__text setting__text-input text-input" name="editResourceKey" disabled [(ngModel)]="translationItem.ResourceKey">
              </div>
            </div>
            <div class="basic-info__setting lang-setting">
              <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                  </svg>
                </div>
                Resource Language
              </h4>
              <div class="input-container">
                <input type="text" class="basic-info__text setting__text-input text-input" name="editLang" disabled [(ngModel)]="translationItem.LangCode">
              </div>
            </div>
            <div class="basic-info__setting">
              <h4 class="basic-info__heading setting__heading ls-subheading">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z"
                    />
                  </svg>
                </div>
                Site Text
              </h4>
              <div class="input-container">
                <textarea id="resource-text-area" [innerHTML]="translationItem.ResourceValue"></textarea>
              </div>
            </div>

            <div class="form-button-container">
              <button *ngIf="_emsConfig.loginToken.Role === 'systemadmin'" (click)="openConfirmDialog('resourceDetails', 'deleteResource', translationItem, _emsConfig.text?.EMS_General.Confirm_Delete_Message)" type="button" class="btn translation-delete">
                Delete
              </button>
              <button (click)="openConfirmDialog('resourceDetails', 'updateResources', translationItem, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
                type="button" class="btn module-submit">
                  Submit
                </button>
            </div>
          </form>

          <!-- add form -->
          <form action="" class="add-translation" *ngIf="showAddForm">
            <div class="basic-info__setting resource-name">
              <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                  </svg>
                </div>
                Resource Name
              </h4>
              <div class="input-container">
                <input type="text" class="basic-info__text setting__text-input text-input" name="newResourceKey" [(ngModel)]="newTranslation.ResourceKey">
              </div>
            </div>
            <div class="basic-info__setting lang-setting">
              <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                  </svg>
                </div>
                Resource Language
              </h4>
              <div class="input-container">
                <input type="text" class="basic-info__text setting__text-input text-input" name="newLang" disabled [(ngModel)]="newTranslation.LangCode">
              </div>
            </div>
            <div class="basic-info__setting">
              <h4 class="basic-info__heading setting__heading ls-subheading">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z"
                    />
                  </svg>
                </div>
                Site Text
              </h4>
              <div class="input-container">
                <textarea id="resource-text-area-add" [innerHTML]="newTranslation.ResourceValue"></textarea>
              </div>
            </div>

            <div class="form-button-container">
              <button (click)="openConfirmDialog('resourceDetails', 'updateResources', newTranslation, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
                type="button" class="btn module-submit">
                  Submit
                </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

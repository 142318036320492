import { Component, OnInit } from '@angular/core';
import { CampaignBulkBuildService } from 'app/services/campaign-bulkbuild.service';
import { ConfirmService } from 'app/shared/confirm.service';
import { EmsConfig } from 'app/shared/emsConfig';
import * as moment from 'moment';
import { CampaignDetails } from 'app/cms/details/cms-campaign/campaign-details';
import { ClientCampaign } from 'app/accounts/details/account-campaign/clientCampaign';
import { UtilitiesService } from 'app/shared/utilities.service';
import { AccountsService } from 'app/accounts/accounts.service';
import { Account } from 'app/accounts/account';
import { SnackbarService } from 'app/services/Snackbar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-campaign-bulkbuild',
  templateUrl: './campaign-bulkbuild.component.html'
})
export class CampaignBulkbuildComponent implements OnInit {
  campaign: CampaignDetails = new CampaignDetails();
  clientCampaign: ClientCampaign = new ClientCampaign();
  existingClients: ClientCampaign[] = [];
  allClients: Account[] = [];

  previewDate: any;
  startDate: any;
  endDate: any;

  showForm: boolean = false;
  toggleIndex: number = -1;

  // Searchbar variables
  campaignSearch: string;
  currentlySearching: boolean = false;
  campaignStatus: string;
  activeFilter: string = 'editing';
  activeFilterStore: string;
  campaignStatusStore: string;

  // variables for Confirm/Popup Services
  confirmData: {};
  confirmBulkBuildData: any;
  confirmSubscription: Subscription;
  confirmAction: string;

  constructor(
    private _campaignBulkBuildService: CampaignBulkBuildService,
    private _accountsService: AccountsService,
    private _utilitiesService: UtilitiesService,
    private _confirmService: ConfirmService,
    public _emsConfig: EmsConfig,
    private _snackBar: SnackbarService
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if(this.confirmAction === 'finalBuild') {
            this.finalBuildAndSync();
          }
        }
      }
    )
  }

  // show/hides Add New Client Form
  showNewClientForm(): void {
    this.showForm = !this.showForm;
  }

  // Saves new/existing clients to campaign
  saveClient(clientCampaign: ClientCampaign) {
    if(!this.validateForm(clientCampaign)) {
      console.log('form invalid!');

    } else {
      this._campaignBulkBuildService.insertUpdateClientCampaignForBulkUpload(clientCampaign)
      .subscribe(data => {
        console.log(data);
        this._snackBar.success('Client has successfully been added/updated.');

        // closes Add New Client Form on successful save
        this.showForm = false;

        // resetting toggle index when existing client form is saved
        this.toggleIndex = -1;

        // call function that gets existing clients and populates form table
        this.getCampaignClientList(this.campaign.ID);
      },
      err => {
        console.log(err);
        this._snackBar.error('Client has not been successfully added/updated. Please try again.');
      })
    }
  }

  // Additional logic to validate client forms
  validateForm(clientCampaign: ClientCampaign ): boolean {
    // checks if required fields are blank, triggers error popup
    if(this._utilitiesService.isBlank([clientCampaign.ClientName, clientCampaign.StartDate, clientCampaign.EndDate, clientCampaign.PreviewDate, clientCampaign.MessageType])) {
      this._snackBar.error(this._emsConfig.text.EMS_General.Popup_Required);
      return false;
    // throws error if campaign preview date is after campaign start date
    } else if (moment(clientCampaign.PreviewDate).isAfter(clientCampaign.StartDate)) {
      this._snackBar.error('Preview date must be before or the same date as the campaign start date.');
      return false;
      // When using the 'Add New Client to Campaign' form - check if the client has already been added to the campaign
      // -1 indicates that a client does not yet exist, and a participatingID has not been assigned
    } else if (clientCampaign.ParticipatingID === -1) {
      let foundIndex = this.existingClients.findIndex(c => {
        return c.ClientName === clientCampaign.ClientName;
      });

      // -1 in this case indicates that a client is not yet in the existingClients array
      if(foundIndex !== -1) {
        this._snackBar.error('Client has already been added to this campaign.');
        return false;
      }
      console.log(foundIndex);
    }

    return true;
  }

  // when a sysadmin clicks on "Final Build & Sync" button, it will take all bulk build client data and sync it to prod
  finalBuildAndSync() {
    this._campaignBulkBuildService.bulkuploadclientcampaigndata().
    subscribe(data => {
      console.log('success save');
      console.log(data);
      this._snackBar.success('Campaign has been built and synced to prod for the clients on this page.');
    },
    (error) => {
      console.log(error);
      this._snackBar.error(this.setFinalBuildErrorMessage(error.error));
    })
  }

  setFinalBuildErrorMessage(error: string) {
    if (error.includes('campaign-not-synced')) {
       return 'Campaigns have not been synced to prod in the CMS. Please Sync and retry'
    }
    else if (error.includes('52000')) {
      return error
    }
    else {
      return 'There was an issue and we couldn’t build the campaign properly. Please contact techsupport@lifespeak.com.';
    }
  }

  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(
    component: string,
    action: string,
    campaignData: any,
    message: string
  ): void {
    this.confirmAction = action;
    this.confirmBulkBuildData = campaignData,
    this.confirmData = {component: component, message: message};

    this._confirmService.updateNotification(this.confirmData);
  }


  // Deletes existing clients from Campaign
  deleteClient(index: number) {
    this._campaignBulkBuildService.deleteClientCampaign(this.existingClients[index].ParticipatingID)
    .subscribe(data => {
      console.log(data);

      // removing client from existingClients array
      this.existingClients.splice(index, 1);
      this._snackBar.success('Client has been removed from the campaign.');
    },
    err => {
      console.log(err);
      this._snackBar.error('Update failed. Please try again.');
    })
  }

  // Toggles display/edit modes for Existing Client form table
  editClientForm(index: number): void {
    if(this.toggleIndex === index) {
      this.toggleIndex = -1;

    } else {
      this.toggleIndex = index;

      // setting values for Date/Range pickers
      this.existingClients[index].StartDate = new Date(this.existingClients[index].StartDate);
      this.existingClients[index].EndDate = new Date(this.existingClients[index].EndDate);
      this.existingClients[index].PreviewDate = new Date(this.existingClients[index].PreviewDate);
    }
  }

  // Pulls back clients who have already been added to campaign
  getCampaignClientList(id: number) {
    this._campaignBulkBuildService.getClientCampaignBulkUploadList(id)
    .subscribe(data => {
      this.existingClients = data;
      console.log(data);
    },
    err => {
      console.log(err);
    })
  }

  // Gets list of all clients
  getAllAccounts() {
    this._accountsService.getAllAccounts(true)
    .subscribe(data => {
      console.log(data);
      this.allClients = data;
    },
    err => {
      console.log(err);
    })
  }

  ngOnInit(): void {
    // Grabbing Campaign Details
    this._campaignBulkBuildService.getCampaignForBulkUpload()
    .subscribe(
      data => {
        this.campaign = data;
        console.log(data)
        console.log(this.campaign.ID);

        // setting default values for Date/Range pickers
        this.clientCampaign.StartDate = new Date(this.campaign.StartDate);
        this.clientCampaign.EndDate = new Date(this.campaign.EndDate);
        this.clientCampaign.PreviewDate = new Date(this.campaign.DefaultPreviewDate);

        // setting default value for client select
        this.clientCampaign.ClientName = "";

        // setting default value for message type select
        this.clientCampaign.MessageType = "full";

        this.clientCampaign.CampaignID = this.campaign.ID;

        this.getCampaignClientList(this.campaign.ID);

        console.log(this.clientCampaign.ParticipatingID);
      },
      (err) => {
        console.log(err);
      })

      this.getAllAccounts();
  }
}

<div class="subtitling subtitling--export">
  <div class="page-ribbon">
    <div class="page-header">
      <h2 class="title">Bulk Subtitling</h2>
    </div>
  </div>
  <section class="subtitling__tabs">
    <div>
      <a [routerLink]="['/cms/subtitling/export']" class="tab title active">
        Export files
      </a>
      <a [routerLink]="['/cms/subtitling/import']" class="tab title">
        Import files
    </a>
    </div>
  </section>
  <section class="overview">
    <div class="basic-container info-container">
      <div class="subtitling__export-actions">
        <div class="subtitling__instructions" >
          <h3>Instructions</h3>
          <span>Use the Export Files tool to generate subtitling / captioning files for vendors. Select the volumes and OnPoints with the languages you need subtitled or captioned in and then Generate Translation Files. The system will create a spreadsheet with all the relevant information for a vendor to build subtitle files. The file contains info such as the video location and caption naming conventions for vendors to follow.</span>
        </div>
        <div>
          <div class="subtitling__export-button">
            <button type="button" class="btn ls-button-2" 
            [ngClass]="isGenerateBtnDisabled ? 'disabled' : ''"
            (click)="getVolumeExportData()">
              Generate Translation Files
          </button>
          </div>
        </div>
      </div>
      <div class="sort-container__list filters">
        <ul class="sort-container__options">
          <li class="sort-container__list-item pointer" [ngClass]="{active: lang === 'EN'}"
            (click)="volumeFilterClick()">English</li>
          <li class="sort-container__list-item pointer" [ngClass]="{active: lang === 'FR'}"
            (click)="volumeFilterClick()">French</li>
        </ul>
      </div>
      <div class="two-col-tables">
        <div class="subtitling__table subtitling__table--lang">
          <table class="display-table">
            <thead>
              <tr>
                <th colspan="3">Language</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let lang of langList">
                <tr>
                  <td>
                    <div class="ls-checkbox">
                      <input id="{{ lang.Code }}" type="checkbox" (click)="setLangCodeExportList(lang.Code)">
                      <label [attr.for]="lang.Code"><span></span></label>
                    </div>
                  </td>
                  <td>{{ lang.Code.toUpperCase() }}</td>
                  <td>{{ lang.Name }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="subtitling__table subtitling__table--volume">
          <table class="display-table display-table--sortable">
            <thead>
              <tr>
                <th colspan="2" (click)="sortBy('ID')" [ngClass]="{'active':sortCategory==='ID', 'active--asc':sortCategory==='ID' && sortCategoryCounter===1, 'active--desc':sortCategory==='ID' && sortCategoryCounter===2}">
                  Volume ID
                </th>
                <th (click)="sortBy('VolumeTitle')"
                  [ngClass]="{'active':sortCategory==='VolumeTitle', 'active--asc':sortCategory==='VolumeTitle' && sortCategoryCounter===1, 'active--desc':sortCategory==='VolumeTitle' && sortCategoryCounter===2}">
                    Title
                </th>
                <th (click)="sortBy('OnPoint')" 
                  [ngClass]="{'active':sortCategory==='OnPoint', 'active--asc':sortCategory==='OnPoint' && sortCategoryCounter===1, 'active--desc':sortCategory==='OnPoint' && sortCategoryCounter===2}">
                    OnPoint
                </th>
                <th (click)="sortBy('EMSStatus')" 
                  [ngClass]="{'active':sortCategory==='EMSStatus', 'active--asc':sortCategory==='EMSStatus' && sortCategoryCounter===1, 'active--desc':sortCategory==='EMSStatus' && sortCategoryCounter===2}">
                    Status
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let volume of allVolumes">
                <ng-container *ngIf="volume.Language == lang">
                  <tr>
                    <td>
                      <div class="ls-checkbox">
                        <input id="{{volume.ID}}" type="checkbox" (click)="setVolumeIdExportList(volume.ID)">
                        <label [attr.for]="volume.ID"><span></span></label>
                      </div>
                    </td>
                    <td width="80" style="width:80px">{{ volume.ID }}</td>
                    <td class="title-size">{{ volume.VolumeTitle }}</td>
                    <td class="title-size">{{ volume.OnPoint.toString() | titlecase }}</td>
                    <td><h5 class="ls-body-2 status-{{volume.EMSStatus}}">{{ volume.EMSStatus === 'live' ? 'Active' : volume.EMSStatus | titlecase }}</h5></td>
                  </tr>
                </ng-container>
              </ng-container>
              <tr *ngIf="allVolumes.length === 0">
                <td>No volumes</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</div>

import { Component } from '@angular/core';
import { SnackbarService } from 'app/services/Snackbar.service';
import { ConfirmService } from 'app/shared/confirm.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { CmsService } from 'app/cms/cms.service';
import { OlsMigrationLogName, OlsMigrationLogType } from 'app/shared/objects/olsMigration';

@Component({
  selector: 'content-fusion-migration',
  templateUrl: 'content-fusion-migration.component.html'
})


export class ContentFusionMigrationComponent {
  confirmData: {};
  confirmAction: string;
  confirmSubscription: Subscription;

  logName = new OlsMigrationLogName;
  logType = new OlsMigrationLogType;

  constructor(
    public _snackbarService: SnackbarService, 
    private _confirmService: ConfirmService,
    private _cmsService: CmsService
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'expert-all') {
            this.migrateExperts(true);
          } else if (this.confirmAction === 'expert-latest') {
            this.migrateExperts(false);
          } else if (this.confirmAction === 'taxonomy-all') {
            this.migrateTaxonomy(true);
          } else if (this.confirmAction === 'taxonomy-latest') {
            this.migrateTaxonomy(false);
          }else if (this.confirmAction === 'blog') {
            this.migrateBlogs();
          } else if (this.confirmAction === 'video') {
            this.migrateVideos();
          }
        }
      }
    );
  }
  // ====================
  // confirm modal stuff
  // ====================
  openConfirmDialog(component: string, action: string, message: string): void {
    this.confirmAction = action;
    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }

  // sends the expert data from mhr db to content fusion db
  public migrateExperts(uploadAll: boolean) {
    this._cmsService.uploadExpertsToContentFusion(uploadAll).subscribe(
      res => {
        console.log(res);
        this.logMigration(this.logName.Expert, this.logType.Content)
        this._snackbarService.success("Expert data is migrated sucessfully!")
      }, err => {
        console.log(err);
        this._snackbarService.error("Error migrating experts");
      }
    );
  }

  // sends the taxonomy data(categories, subcategories & blog tags) from mhr db to content fusion db
  public migrateTaxonomy(uploadAll: boolean) {
    this._cmsService.uploadTaxonomyToContentFusion(uploadAll).subscribe(
      res => {
        console.log(res);
        this.logMigration(this.logName.Taxonomy, this.logType.Content)
        this._snackbarService.success("Taxonomy data is migrated sucessfully!")
      }, err => {
        console.log(err);
        this._snackbarService.error("Error migrating taxonomy data");
      }
    );
  }
  
  // sends the blog data from mhr db to content fusion db
  public migrateBlogs() {
    this._snackbarService.success("Blog data is migrated sucessfully!")
  }

  // sends the video data from mhr db to content fusion db
  public migrateVideos() {
    this._snackbarService.success("Video data is migrated sucessfully!")
  }

  // Log migration in OLS_Migration table
  public logMigration(name:string, type: string) {
    this._cmsService.logMigration(name, type).subscribe(res => console.log(res));
  }


  ngOnDestroy(): void {
    this.confirmSubscription.unsubscribe();
  }

}

<table class="display-table connanswers">
  <thead>
    <tr>
      <th>ID</th>
      <th>Date sent</th>
      <th>Nickname</th>
      <th>Question</th>
      <th>Answer</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let question of questions">
      <td>
        <svg *ngIf="question.QuestionType > 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
          viewBox="0 0 24 24" fill="#006B91">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
        </svg>
        {{question.QuestionID}}
      </td>
      <td>{{question.DateSubmittedFormatted}}</td>
      <td>{{question?.Email}}</td>
      <td class="question" [innerHTML]="question.Question"></td>
      <td class="answerdata" *ngIf="question.QuestionType === 1" [innerHTML]="question.Answer"></td>
    </tr>
  </tbody>
</table>
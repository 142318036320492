import { Component, HostBinding, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { EmsConfig } from '../../shared/emsConfig';
import { ConnexService } from '../connex.service';
import { SecuredRoutes } from 'app/shared/secured-routes';
import { PopupService } from '../../shared/popup.service';
import { ConfirmService } from '../../shared/confirm.service';

// Objects
import { AteAnswer } from '../Objects/AteAnswer';

//signalr service and lib
import { HubConnection, HubConnectionState } from "@microsoft/signalr";

import { WebSocketsService } from 'app/connex/ate/websockets.service';
import { AteQuestionStatus } from '../Objects/AteQuestionStatus';

@Component({
  selector: 'ate-mod-inbox',
  templateUrl: 'ate-mod-inbox.component.html',
  providers: [ConnexService]
})
export class ATEModInbox implements OnInit {
  @HostBinding('class') classAttribute: string = 'ate-mod-inbox';

  @Input() questions: AteAnswer[];
  @Input() langStr: string;

  @Output() moveQuestion = new EventEmitter();
  @Output() editQuestion = new EventEmitter();

  public hubConnection: HubConnection;
  public confirmSubscription: Subscription;
  // temporarily disable all submit buttons for 1.5 seconds after clicking, to prevent double click spamming
  public disableButtons: boolean = false;

  constructor(
    public _emsConfig: EmsConfig,
    public _securedRoutes: SecuredRoutes,
    public _popupService: PopupService,
    private _confirmService: ConfirmService,
    private _connexService: ConnexService,
    private hubConnectionService: WebSocketsService
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        console.log(response);
        if (response.confirmed) {
          if (response.data.action === 'postAnswer') {
            this.sendQuestion(response.data.answer, response.data.status);
          }
        }
      });
  }

  async ngOnInit() {
    try {
      this.hubConnection = this.hubConnectionService.getConnection();
    } catch (err) {
      console.log(" Signalr connection error " + err);
    }
  }

  confirmAction(action: string, message: string, status: AteQuestionStatus, answer: AteAnswer): void {
    this._confirmService.updateNotification({ action: action, message: message, status: status, answer: answer });
  }

  // A general method to send a question with a new status
  sendQuestion(question: AteAnswer, newStatus: number) {
    this.tempDisableButtons();
    // Save old status incase we need to revert in error
    const oldStatus = question.Status;
    question.Status = newStatus;

    this._connexService.modifyATEQuestion(question)
      .subscribe(response => {
        console.log(response);
        if (response.QuestionID !== -1) {
          this.moveQuestion.emit(response);
          this.sendHub(response)
        } else {
          question.Status = oldStatus;
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
        }
      });
  }

  sendHub(question: AteAnswer) {
    if (question.Status === AteQuestionStatus.Pending) {
      // Send user question to expert
      this.hubConnection.send('SendQuestionToExpert', question);
      this.hubConnection.send('PostToLivechat', question);
    }
    if (question.Status === AteQuestionStatus.Sent) {
      this.hubConnection.send('UpdateQuestion', question);
    }
    if (question.Status === AteQuestionStatus.Rejected) {
      this.hubConnection.send('RemoveQuestionFromLiveChat', question);
    }
  }

  tempDisableButtons() {
    this.disableButtons = true;
    setTimeout(() => {
      this.disableButtons = false;
    }, 1500);
  }

  // send clone of question to parent for editing
  modifyQuestion(question: AteAnswer) {
    const clone = JSON.parse(JSON.stringify(question));
    this.editQuestion.emit(clone);
  }

  ngOnDestroy() {
    this.confirmSubscription.unsubscribe();
  }
}

import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

// services
import { CmsService } from '../cms.service';
import { EmsConfig } from 'app/shared/emsConfig';
import { UtilitiesService } from 'app/shared/utilities.service';
import { ConfirmService } from 'app/shared/confirm.service';
import { SnackbarService } from 'app/services/Snackbar.service';

// classes
import { TipsheetItem } from '../tipsheetItem';
import { SimpleVolume } from 'app/shared/SimpleVolume';
import { CategoriesService } from 'app/categories/categories.service';

import { SubCategory } from 'app/shared/SubCategory';

// third party
declare var tinymce: any;

@Component({
  selector: 'cms-tipsheet-edit', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-tipsheet-edit.component.html'
})

export class CmsTipsheetEditComponent implements OnInit, OnDestroy, AfterViewInit {

  // variables for getting account id from url
  id: number;

  volumeIdFromUrl: number;
  langFromUrl: string;
  volumeTitleFromUrl: any;

  volumeId: number;

  private sub: any;

  lang: string;

  errorMessage: string;

  tipsheet: TipsheetItem;

  simpleVolumes: any;

  addNewTipsheet: boolean = false;

  //for subcategory list
  subCategories: SubCategory[] = [];
  tipsheetSubcategoryIDs: any = {};
  categoryName:string;

  // editor variables
  editor: any;
  activeEditor: any;
  tipsheetEditorData: any = '';
  updatedTipsheetEditorData: any;

  // confirm service variables
  confirmData: {};
  confirmTipsheetData: any;
  confirmAction: string;
  confirmSubscription: Subscription;

  tipsheetContentTooltip: string = 'Tipsheet titles and expert info is now added programmatically on the site (existing title & author are hidden until they are removed from the database).';

  tipsheetPreviewLink: string;
  showPreviewLink: boolean = false;

  constructor(public _cmsService: CmsService, public _emsConfig: EmsConfig, public _utilitiesService: UtilitiesService, private router: Router, private route: ActivatedRoute, private _confirmService: ConfirmService, private _location: Location, private _categories: CategoriesService,private _snackBar: SnackbarService) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'updateTipsheet') {
            this.updateTipsheet(this.confirmTipsheetData);
          }
        }
      }
    );
   }

  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(component: string, action: string, volumeData: any, message: string): void {
    this.confirmAction = action;
    this.confirmTipsheetData = volumeData;

    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }


  updateEditor(editorData): void {
    this.activeEditor = tinymce.get('tipsheet-area');
    if (this.activeEditor != null) {
      this.activeEditor.setContent(editorData);
    }
  }

  getTipsheetDetails(): void {
    this._cmsService.getTipsheet(this.id).subscribe(data => {
      this.tipsheet = data;
      console.log(this.tipsheet);

      if (this.id === -1 && !this._utilitiesService.isBlank([this.volumeIdFromUrl])) {
        this.addNewTipsheet = true;

        this.tipsheet.ID = 0;
        this.tipsheet.TipsheetContent = '';
        this.tipsheet.TipsheetLang = this.langFromUrl;
        this.tipsheet.VolumeId = this.volumeIdFromUrl;
      } else if (this.id === -1) {
        this.addNewTipsheet = true;

        this.tipsheet.ID = 0;
        this.tipsheet.TipsheetContent = '';
        this.tipsheet.TipsheetLang = 'EN';
        this.tipsheet.VolumeId = null;
      } else {
        this.lang = this.tipsheet.TipsheetLang;

        // show priview link for existing tipsheet
        this.showPreviewLink = true;            
        this.tipsheetPreviewLink = `${this._emsConfig.assetPreviewLink}/tipsheet/${this.tipsheet.AssetKey}`;

        this.volumeId = this.tipsheet.VolumeId;
          // convert the string into an array & set tipsheet subcategories to true
          for (let i = 0; i < this.tipsheet.SubcategoryIds?.split(',').map(Number).length; i++) {
            this.tipsheetSubcategoryIDs[this.tipsheet.SubcategoryIds?.split(',').map(Number)[i]] = true;
          }
      }


      this.getSimpleVolumes(this.lang);

      this.tipsheetEditorData = this.tipsheet.TipsheetContent;
      this.updateEditor(this.tipsheetEditorData);
      this.getAllSubcategoriesByCategory(this.tipsheet.CategoryID);
    });
  }

  getTipsheetByVolumeId(): void {
    console.log('get tipsheet by volume');
    this._cmsService.getTipsheetByVolume(this.volumeId, this.lang).subscribe(data => {
      this.tipsheet = data;
      console.log(this.tipsheet);

      if (this.tipsheet.ID === 0 && this._utilitiesService.isBlank([this.tipsheet.TipsheetContent])) {
        this.addNewTipsheet = true;
        this.tipsheet.ID = 0;
        this.tipsheet.TipsheetContent = '';
        this.tipsheet.TipsheetLang = this.langFromUrl;
        this.tipsheet.VolumeId = this.volumeIdFromUrl;
      } else {
        this.lang = this.tipsheet.TipsheetLang;

        this.volumeId = this.tipsheet.VolumeId;
        // update the url with tipsheet id
        this._location.replaceState('/cms/tipsheets/' + this.tipsheet.ID);
      }

      console.log(this.tipsheet);
      this.getSimpleVolumes(this.tipsheet.TipsheetLang);

      this.tipsheetEditorData = this.tipsheet.TipsheetContent;
      this.updateEditor(this.tipsheetEditorData);
    });
  }

  getSimpleVolumes(matchedLang): void {
    // second param is onPoint (true or false)
    this._cmsService.getSimpleVolumes(matchedLang, false)
      .subscribe(
        SimpleVolume => {
          this.simpleVolumes = SimpleVolume;
        },
        err => this.errorMessage = <any>err,
      );
  }

  getUpdatedContentFromEditor(): void {
    console.log(tinymce.activeEditor.getContent());
    return tinymce.activeEditor.getContent();
  }

  convertSpaces(title) {
    title = title.replace(/\s/g, '-');
    return title;
  }

  // remove aria-level attributes for AODA compliance
  // ex: aria-level="1"
  cleanHtml(tipsheetContent) {
    tipsheetContent = tipsheetContent.replace(/aria-level="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/gi, "");
    return tipsheetContent;
  }

  updateTipsheet(tipsheet): void {
    if(tipsheet.ID === 0) {
      tipsheet.ID = -1;
    }

    // make sure volume id is an int
    tipsheet.VolumeId = Number(tipsheet.VolumeId);

    console.log(tipsheet);
    this.updatedTipsheetEditorData = this.getUpdatedContentFromEditor();
    this.tipsheet.SubcategoryIds=this.updateTipsheetSubcategories();
    tipsheet.TipsheetContent = this.cleanHtml(this.updatedTipsheetEditorData);
    console.log(tipsheet);

    this._cmsService.addUpdateTipsheet(tipsheet).subscribe(
      data => {
        console.log(data);
        this.id = data.tipsheetID;
        if (data.tipsheetID === -1) {
          this._snackBar.error('Tipsheet not updated. Please try again.');
        } else {
          this._snackBar.success('Tipsheet has been updated successfully.');
          this.getTipsheetDetails();
        }
      },
      err => this.errorMessage = <any>err,
    );
  }

  goToVolume(): void {
    this.router.navigate(['/cms/volumes', this.volumeId ]);
  }

  setLang(lang): void {
    this.lang = lang;
    this.getTipsheetDetails();
  }


  // get the subcategory list by volume category ID
  getAllSubcategoriesByCategory(id: number): void {
    this._cmsService.getSubCategoriesByCategoryId(id)
      .subscribe(
        data => {
          this.subCategories = data;
          this.categoryName = this._categories.getCategoryName(id);
        },
        err => {
          this._snackBar.error();
          return;
        });
  }

     // adds the updated subcategory IDs in an empty array
    updateTipsheetSubcategories(){
      var result = new Array();
      for (let i = 0; i < this.subCategories.length; i++) {
          // if the subcategory is marked true, then it will be to added to the array
        if(this.tipsheetSubcategoryIDs[this.subCategories[i].ID])
        result.push(this.subCategories[i].ID);
      }
       return result.join(",");
    }

  ngOnInit(): void {
    // gets the account id from url
    this.sub = this.route.params.subscribe(params => {
      this.id = Number(params['id']);
      // check if volumeId query param exists
      if (this._utilitiesService.isBlank([params.volumeId])) {
        this.volumeIdFromUrl = undefined;
      } else {
        this.volumeIdFromUrl = Number(params.volumeId);
      }

      if (this._utilitiesService.isBlank([this.volumeIdFromUrl]) && this.id === -1 ) {
        this.volumeId = -1;
      } else {
        this.volumeId = this.volumeIdFromUrl;
      }

      this.langFromUrl = params['lang'];
      console.log(this.id);
      console.log(this.volumeIdFromUrl);
      console.log(this.langFromUrl);
    });

    if (!this._utilitiesService.isBlank([this.langFromUrl])) {
      this.lang = this.langFromUrl;
    } else {
      this.lang = 'EN';
    }

    // check to see which tipsheet retrieval method to use
    if (this._utilitiesService.isBlank([this.volumeId]) && this.id !== -1) {
      this.getTipsheetDetails();
    } else {
      this.getTipsheetByVolumeId();
    }


  }

  ngAfterViewInit() {
    tinymce.init({
      selector: '#tipsheet-area',
      plugins: 'link code lists preview template table',
      toolbar:
        'code | undo redo | styles | fontsize | bullist numlist | bold italic underline | link unlink | alignleft aligncenter | template | table',
      font_size_formats: '13px 16px 18px 20px 24px 45px 56px',
      browser_spellcheck: true,
      min_height: 650,
      menubar: false,
      body_class:'tipsheet_body',
      content_css: [
        this._emsConfig.assetPath +'/Tipsheet-Style/tipsheet-style-sample-5.0-reskin.css'
      ],
      style_formats: [
        {
          title: 'Tipsheet Title',
          block: 'h1',
          class: 'tipsheet-title'
        },
        {
          title: 'Summary',
          block: 'h2',
          classes: 'summary'
        },
        {
          title: 'Actionable Tips',
          block: 'h2',
          classes: 'actionable'
        },
        {
          title: 'Resources',
          block: 'h2',
          classes: 'resources'
        },
        {
          title: 'Expert Name',
          block: 'h2',
          classes: 'author'
        },
        {
          title: 'Section Subheading',
          block: 'h3',
        },
        {
          title: 'Paragraph',
          block: 'p',
          styles: {color: '#707070'},
        },
      ],
      templates: [
        { 'title': 'Tipsheet Footer', 'description': 'Incl. disclaimer', 'content': '<small>This site, its content, and its services are for educational use only. Nothing contained in this site is or should be considered, or used as a substitute for, medical advice, diagnosis or treatment.</small>' }
      ],

    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    tinymce.remove(this.editor);
    this.confirmSubscription.unsubscribe();
  }

}

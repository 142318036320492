import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { LoginToken } from '.././shared/login-token';
import { EmsConfig } from '../shared/emsConfig';


@Injectable()
export class PopupService {

   notification(data: any): any {
       return data;
   }

   private popupData: Subject<any> = new BehaviorSubject(this.notification({prevent: true}));

   popupData$ = this.popupData.asObservable();

   //when update notification is called, pass in the data to popup.component.ts
   updateNotification(data: any): void {
       this.popupData.next(data);
   }

}

// angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

// project
import { EmsConfig } from '.././shared/emsConfig';
import { SyncOperation } from './sync-operation';
import { SyncRequest } from './sync-request';
import { SyncResult } from './sync-result';
import { SyncInfo } from 'app/sync/sync-info';
import { SyncInfoAsset } from 'app/sync/sync-info-asset';
import { AssetSyncRequest } from 'app/sync/asset-sync-request';

@Injectable()
export class SyncService {

  private organizationSyncURL = this.emsConfig.apiEndpoint + '/emsoperations/executesyncoperation';
  private assetSyncURL = this.emsConfig.apiEndpoint + '/emsoperations/executeassetsyncoperation';
  private organizationUnsyncedURL = this.emsConfig.apiEndpoint + '/emsoperations/getunsyncedoperations';
  private assetSyncOperatonsUrl = this.emsConfig.apiEndpoint + '/emsoperations/getassetoperations';
  private syncRequestURL = this.emsConfig.apiEndpoint + '/emsoperations/sendsyncrequestemail';
  private syncInfoURL = this.emsConfig.apiEndpoint + '/emsoperations/getsyncinfoforoperation?syncid=';
  private assetsyncoperationbyidUrl = this.emsConfig.apiEndpoint + '/emsoperations/executeassetsyncoperationbyid';
  private getEmailSentStatusUrl = this.emsConfig.apiEndpoint + '/emsoperations/getemailnotificationstatusaftersync';
  private assetSyncInfoURL = this.emsConfig.apiEndpoint + '/emsoperations/getpendingassetsforoperation?operationName=';
  private assetByIDInfoURL = this.emsConfig.apiEndpoint + '/emsoperations/getsyncinfoforasset';


  constructor(private emsConfig: EmsConfig, private _httpClient: HttpClient) {

  }

  getSyncStatusForOrganizations(): Observable<SyncOperation[]> {
    return this._httpClient.get<SyncOperation[]>(this.organizationUnsyncedURL);
  }

  getSyncInfoForOrganizations(syncid: number): Observable<SyncInfo[]> {
    return this._httpClient.get<SyncInfo[]>(this.syncInfoURL + syncid.toString());
  }

  getSyncInfoForAsset(assetId: number, tableId: number): Observable<SyncInfoAsset> {
    const myParams= `?tableId=${tableId}&assetId=${assetId}`;
    return this._httpClient.get<SyncInfoAsset>(this.assetByIDInfoURL+ myParams);
  }

  getSyncInfoForAssets(operationName: string): Observable<any> {
    return this._httpClient.get<SyncInfo[]>(this.assetSyncInfoURL + operationName);
  }

  getAssetOperations(): Observable<any> {
    return this._httpClient.get<any>(this.assetSyncOperatonsUrl);
  }

  executeSyncOperation(operation: SyncRequest): Observable<SyncResult> {
    const body = JSON.stringify(operation);
    return this._httpClient.post<SyncResult>(this.organizationSyncURL, body);
  }

  executeAssetSyncOperation(operation: SyncRequest): Observable<SyncResult> {
    const body: string = JSON.stringify(operation);
    return this._httpClient.post<SyncResult>(this.assetSyncURL, body);
  }

  executeAssetSyncOperationById(request: AssetSyncRequest): Observable<any> {
    const body: string = JSON.stringify(request);
    return this._httpClient.post<AssetSyncRequest>(this.assetsyncoperationbyidUrl, body);
  }

  getAteEmailSentStatus(request: AssetSyncRequest): Observable<any> {
    const body: string = JSON.stringify(request);
    return this._httpClient.post<AssetSyncRequest>(this.getEmailSentStatusUrl, body);
  }

  // sendSyncRequest(email: string, subject: string, syncOperation: string, syncAdminUser: string): Observable<any> {
  sendSyncRequest(syncEmailRequest: any): Observable<any> {
    const body = JSON.stringify(syncEmailRequest);
    return this._httpClient.post<SyncResult>(this.syncRequestURL, body);
  }




}

export class PollJson {
  Poll_ID: number;
  Poll_Details?: PollDetail= new PollDetail();
  Date_Created: Date | string;
  Start_Date: Date | string;
  End_Date: Date | string;
  Global_poll: boolean; 
  Client: string;
}
export class PollDetail {
  Poll?: Poll;
}

export class Poll {
  Colour?:   string;
  Language?: Language = new Language();
}

export class Language {
  EN?: En[];
  FR?: En[];
}

export class En {
  Description?: string;
  ID?:          number;
  Questions?:   Question[];
}

export class Question {
  ID?:       number;
  Question?: string;
  Options?:  Option[];
}

export class Option {
  Rank?:   number;
  ID?:     number;
  Option?: string;
}




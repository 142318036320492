          <!--subtitle contents for the module/ onpoints-->
          <div class="subtitle-container">
            <div class="subtitle-container-title">
              <h4 class="basic-info__heading setting__heading ls-subheading">
                <span class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" />
                  </svg>
                </span>
                Available Subtitles
              </h4>
              <button class="subtitle-add-edit" (click)="toggleAddEditSubtitle()"
                *ngIf="!showAddEditSubtitleSection && !showAddSubtitleSection">
                Add, edit or delete subtitles</button>
            </div>

            <!--display subtitle list-->
            <div class="subtitle-display-list" *ngIf="!showAddEditSubtitleSection && !showAddSubtitleSection">
              <table class="subtitle-display-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Lang</th>
                    <th>Title</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of Subtitles;">
                    <td>
                      <div *ngIf="item.CaptionUploaded">
                        <a href="javascript:void(0);" (click)="downloadSubtitleFile(item.LangCode)">
                          <svg viewBox="0 0 9.725 10" width="13">
                            <g id="Upload_icon" transform="translate(-490.326 -473.692)">
                              <path id="Path_38" data-name="Path 38"
                                d="M496.658,754.5c.691-.179,1.458.034,2.3-.139v-3.984h3.882v3.938a20.526,20.526,0,0,0,2.33.167l-4.358,4.479Z"
                                transform="translate(-6.105 -276.687)" fill="#1a89da" />
                              <path id="Path_41" data-name="Path 41" d="M495.122,817.437v-1.205h8.9c.206.348.037.74.132,1.205Z"
                                transform="translate(-4.796 -332.245)" fill="#1a89da" />
                            </g>
                          </svg>
                        </a>
                      </div>
                      <div class="no-caption" *ngIf="!item.CaptionUploaded">?</div>
                    </td>
                    <td [ngClass]="{'no-title': !item.CaptionUploaded}">{{item.Lang}}</td>
                    <td [ngClass]="{'no-title': !item.Title}">{{getTitle(item.Title)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!--bulk update/remove subtitle files-->
            <div class="subtitle-edit-list" *ngIf="showAddEditSubtitleSection">
              <table class="subtitle-display-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Lang</th>
                    <th>Title</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of Subtitles;let i=index;">
                    <td>
                      <div>
                        <a href="javascript:void(0);" (click)="openConfirmDialog('moduleDetails', 'deleteSubtitle', item, _emsConfig.text?.EMS_Content.Confirm_Delete_Module_Subtitle)">
                          <svg viewBox="0 0 9.725 10" width="15" fill="#ee6a53">
                            <path class="a"
                              d="M7.039,3v.556H4V4.667h.608v7.222A1.167,1.167,0,0,0,5.823,13H11.9a1.167,1.167,0,0,0,1.216-1.111V4.667h.608V3.556H10.686V3H7.039m0,2.778H8.255v5H7.039v-5m2.431,0h1.216v5H9.47Z"
                              transform="translate(-4 -3)" />
                          </svg>
                        </a>
                      </div>
                    </td>
                    <td><a href="javascript:void(0);" (click)="loadSingleSubtitleEditSection(item)">{{item.Lang}}</a></td>

                    <td>
                      <div class="input-container">
                        <input type="text" class="basic-info__text setting__text-input text-input" name="subtitle-{{i}}"
                          [(ngModel)]="item.Title" placeholder="Please add title"
                          [disabled]="item.LangCode.toUpperCase()==Language.toUpperCase()">
                        </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="subtitle-edit-button form-button-container">
                <button type="button" class="btn" (click)="toggleAddSubtitleSection()">
                  Add text track
                </button>

                <button type="button" class="btn" (click)="cancelBulkEditSection()">
                  Cancel
                </button>

                <button type="button" class="btn" (click)="bulkUpdateSubtitlesForClip()">
                  Save
                </button>
              </div>
            </div>

            <!--add/edit single subtitle including upload/remove vtt file-->
            <div class="subtitle-add-container" *ngIf="!showAddEditSubtitleSection && showAddSubtitleSection">
              <table id="subtitle-add-table">
                <thead>
                  <tr>
                    <th>Lang</th>
                    <th>Title</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="select-container" *ngIf="!editSingleSubtitle">
                        <select class="setting__select-input select-input"
                          [ngClass]="{'no-uploaded-file': !subtitleLanguage.LangCode}" name="selectLanguage"
                          [(ngModel)]="subtitleLanguage && subtitleLanguage.LangCode">
                          <option [selected]="true" [disabled]="true" value="">Select</option>
                          <option value={{item.Code}} *ngFor="let item of languages">{{item.Name}}</option>
                        </select>
                      </div>
                      <div class="subtitle-edit-lang" *ngIf="editSingleSubtitle">
                        {{subtitleLanguage.Lang}}
                      </div>
                    </td>
                    <td>
                      <div class="input-container">
                        <input type="text" [(ngModel)]="subtitleLanguage && subtitleLanguage.Title" placeholder="Please add title"
                          class="basic-info__text setting__text-input text-input" name="subtitle" [disabled]="subtitleLanguage.LangCode.toUpperCase()==Language.toUpperCase()">
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="subtitle-info-text-container">
                <div class="subtitle-info-text">
                  <label>{{subtitleLanguage.SubtitleFileName}}</label>
                  <label class="no-uploaded-file" *ngIf="!subtitleLanguage.CaptionUploaded">No file uploaded</label>
                </div>
                <div class="subtitle-uploader" *ngIf="!subtitleLanguage.CaptionUploaded">
                  <button type="button" class="btn" (click)="fileInput.click()">Upload VTT File</button>
                  <span>
                    <input type="file" #fileInput ng2FileSelect id="subtitleUploader" name="subtitleUploader"
                      [uploader]="subtitleUploader" (onFileSelected)="uploadSubtitleFile()" />
                  </span>
                </div>
                <button type="button" class="btn delete-btn" (click)="openConfirmDialog('subtitleDetails', 'deleteVttFile', '', _emsConfig.text?.EMS_Content.Confirm_Delete_Module_Vtt_File)" *ngIf="subtitleLanguage.CaptionUploaded">
                  Remove VTT File
                </button>
              </div>

              <div class="subtitle-edit-button form-button-container">
                <ai-audio
                  [audioUrl]="VideoUrl"
                  [inputLang]="Language.toUpperCase()"
                  [outputLang]="subtitleLanguage.LangCode.toUpperCase()"
                  [outputFormat]="audioResponseFormat.VTT"
                  [disableCreateFileBtn]="subtitleLanguage.CaptionUploaded"
                  (emitFile)="addSubtitleFile($event)"
                ></ai-audio>
                <div>
                  <button type="button" class="btn" (click)="cancelSingleAddEditSection()">
                    Cancel
                  </button>
                </div>
                <div>
                  <button type="button" class="btn" (click)="addSubtitleForClip()">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

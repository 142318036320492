import { Component, OnInit, OnDestroy } from '@angular/core';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';

//  Services
import { CmsService } from '../cms.service';
import { UtilitiesService } from '../../shared/utilities.service';
import { TipsheetItem } from '../tipsheetItem';
import { Router, Route, ActivatedRoute } from '@angular/router';

declare var tinymce: any;

@Component({
  selector: 'cms-tipsheet-list', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-tipsheet-list.component.html',
})

export class CmsTipsheetListComponent implements OnInit, OnDestroy {

  tipsheets: TipsheetItem[] = [];
  errorMessage: string;

  lang: string = 'EN';

  // filter variables
  tipsheetSearch: string;
  tipsheetStatus: string;
  activeFilter: string = 'all';
  currentlySearching: boolean = false;
  activeFilterStore: string;
  tipsheetStatusStore: string;

  constructor( public _cmsService: CmsService, public _emsConfig: EmsConfig, public _utilitiesService: UtilitiesService, private router: Router, private route: ActivatedRoute) { }


  // sort features
  // update various pipe filters
  public updateVolumeFilters(status: string): void {
    this.tipsheetStatus = status;
    this.activeFilter = status;
    console.log('status changed');
    console.log(status);
    console.log(this.tipsheets.length);
  }

  // if using the search bar ignore the other filter options
  usingSearchBar(text: any): void {
    if (this.tipsheetSearch.length > 0) {
      if (!this.currentlySearching) {
        // store the current filter settings to apply back later
        this.currentlySearching = true;
        this.activeFilterStore = this.activeFilter;
        this.tipsheetStatusStore = this.tipsheetStatus;
      }

      // turn off the filters
      this.activeFilter = 'all';
      this.tipsheetStatus = 'all';
    } else {
      // when search input is cleared, set back the filters
      this.activeFilter = this.activeFilterStore;
      this.tipsheetStatus = this.tipsheetStatusStore;
      this.currentlySearching = false;
    }
  }

  // reset filters
  public resetFilters(): void {
    this.activeFilter = 'all';
    this.updateVolumeFilters('all');
  }


  getAllTipsheets(): void {
    this._cmsService.getTipsheets(this.lang)
      .subscribe(
        TipsheetItem => {
          this.tipsheets = TipsheetItem;

          for (let i = 0; i < this.tipsheets.length; i++) {
            this.tipsheets[i].VolumeTitleNormalized = this._utilitiesService.normalizeTerm(this.tipsheets[i].VolumeTitle);
          }

        },
        err => this.errorMessage = <any>err,
        () => console.log(this.tipsheets)
      );
  }

  setLang(lang: string): void {
    this.lang = lang;
    console.log(this.lang);
    this.resetFilters();
    this.getAllTipsheets();
  }

  // Navigates to tipsheet edit
  onSelect(id) {
    // Navigate with relative link
    if (this.router.url.includes('tipsheets')) {
      this.router.navigate([id], { relativeTo: this.route });
    } else {
      // if coming directly from cms default route (need to add volumes to url)
      this.router.navigate(['tipsheets', id], { relativeTo: this.route });
    }
  }


  createNewTipsheet(): void {
    const id = -1;
    // Navigate with relative link
    if (this.router.url.includes('tipsheets')) {
      this.router.navigate([id], { relativeTo: this.route });
    } else {
      // if coming directly from cms default route (need to add volumes to url)
      this.router.navigate(['tipsheets', id], { relativeTo: this.route });
    }
  }


  ngOnInit(): void {
    // get list of tipsheets
    this.getAllTipsheets();

  }

  ngOnDestroy() {

  }
}

// Angular Things
import { Component, HostBinding, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { TitleCasePipe, LocationStrategy } from '@angular/common';
//services
import { CmsService } from "../cms.service";
import { UtilitiesService } from '../../shared/utilities.service';
import { ConfirmService } from '../../shared/confirm.service';
import { SnackbarService } from 'app/services/Snackbar.service';

// Config stuff
import { EmsConfig } from "../../shared/emsConfig";
import { Observable, fromEvent, Subscription, filter } from 'rxjs';

// Classes
import { Inventory } from "../../shared/inventory";
import { forEach } from "underscore";


@Component({
  selector: "cms-inventory-list",
  templateUrl: "cms-inventory-list.component.html",
  providers: [CmsService]
})
export class CmsInventoryListComponent {
  @HostBinding('class') classAttribute: string = 'cms-inventory-list';

  public connectList: any;

  languages: any[] = []; // all langs
  checkedLanguages: any[] = ['EN', 'FR']; // selected/filtered langs
  
  inventories: any[] = []; // all inventory types
  checkedInventories: any[] = []; // selected/filtered inventory types

  inventoryStatuses: any[] = []; // all statuses
  checkedInventoryStatuses: any[] = []; // selected/filtered statuses

  inventoryOfVolume: Inventory[] = [];
  originalVolumes: Inventory[] = [];
  updatedData: any[] = [];

  // filter variables
  volumeSearch: string;

  confirmSubscription: Subscription;
  confirmAction: string;
  confirmData: {};

  showUnsavedChangesWarning: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _cmsService: CmsService,
    public _emsConfig: EmsConfig,
    public _utilitiesService: UtilitiesService,
    private _confirmService: ConfirmService,
    private _snackBar: SnackbarService
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.data.component === "inventoryList" && response.confirmed) {
          this.updatedData.forEach(i => {
            this.updateInventory(i.VolumeTitle, i.VolumeID, i.InventoryID, i.StatusID)
          })
        }
        this.updatedData = []
      }
    );
  }

  // ========================
  // gets list of inventories
  // ========================
  getInventories(): void {
    this._cmsService.getInventories().subscribe(async data => {
      this.inventories = data.inventories;
      this.inventories.forEach(inv => {
        inv.checked = true;
        inv.total = 0;
        this.checkedInventories.push(inv.Key)
      })
      await this.getInventoriesForVolumes();
    });
  }

  // ================================
  // gets list of inventory statuses
  // ================================
  getInventoryStatuses(): void {
    this._cmsService.getInventoryStatuses().subscribe(data => {
      this.inventoryStatuses = [{ Key: 0, Value: 'Unassigned', checked: true }]
      this.inventoryStatuses.push(...data.inventoryStatuses);
      this.inventoryStatuses.forEach(status => {
        status.checked = true;
        this.checkedInventoryStatuses.push(status.Key)
      })
      console.log(this.inventoryStatuses)
    });
  }

  // ================
  // get ALL volumes
  // ================
  getInventoriesForVolumes():any {
    this._cmsService.getInventoryForVolumes('').subscribe(async InventoryVolume => {
      this.originalVolumes = InventoryVolume;
      await this.mapInventoryStatuses(InventoryVolume);
    });

  }

  // =================================
  // updates inventory for each volume
  // =================================
  updateInventory(volumeTitle: string, volumeID: number, inventoryID: number, statusID: number){
    this._cmsService.insertDeleteInventory(volumeTitle, volumeID, inventoryID, statusID).subscribe(
      data => {
        this._snackBar.success("The volume has been successfully updated.");
      },
      err => {
        console.log(err)
        this._snackBar.error('Sorry, an error has occurred with the volume: ' + err.error.VolumeTitle);
    });
  }

  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(component: string, action: string, message: string): void {
    this.confirmAction = action;
    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }

  // =====================================
  // Updating a volume's inventory status
  // =====================================
  updateVolumeStatus(item, inventoryID) {
    // Checkboxes in the inventory table cycle through these available inventory statuses:
    // 0 = Unassigned
    // 1 = Coming soon
    // 2 = New
    // 3 = Active

    // Update status icon in table
    if (item.InventoryStatusMapping[inventoryID] === 3) {
      item.InventoryStatusMapping[inventoryID] = 0
    } else {
      item.InventoryStatusMapping[inventoryID]++;
    }

    // Generate list of volumes to be updated and sent to DB after clicking Save
    let existingVolIndex = this.updatedData.findIndex(i => i.VolumeID === item.VolumeID)
    if (existingVolIndex < 0) {
      // If volumeID doesnt already exist, add volume to list
      this.updatedData.push({
        VolumeTitle: item.VolumeTitle,
        VolumeID: item.VolumeID,
        InventoryID: inventoryID,
        StatusID: item.InventoryStatusMapping[inventoryID]
      })
    } else {
      // If volumeID exists, check if an inventory has already been updated
      let itemsWithMatchingVols = this.updatedData.filter(data => data.VolumeID == item.VolumeID)
      let inventoryExists = itemsWithMatchingVols.some(el => el.InventoryID === inventoryID)
      if (inventoryExists) { // If volume with inventory exists - update its StatusID
        let existingVolInvIndex = this.updatedData.findIndex(data => (data.VolumeID == item.VolumeID && data.InventoryID == inventoryID))
        this.updatedData[existingVolInvIndex].StatusID = item.InventoryStatusMapping[inventoryID]
      } else { // If volume exists, but inventory doesn't - add inventory
        this.updatedData.push({
          VolumeTitle: item.VolumeTitle,
          VolumeID: item.VolumeID,
          InventoryID: inventoryID,
          StatusID: item.InventoryStatusMapping[inventoryID]
        })
      }
    }
    this.getInventoryTotals();
  }

  // ==========================
  // toggle & set the LANGUAGE
  // ==========================
  toggleLanguage(key:number, checkedValue:boolean){
    this.languages[key-1].checked = checkedValue
    this.checkedLanguages = [];
    this.languages.forEach(lang => {
      if (lang.checked) {
        this.checkedLanguages.push(lang.Value)
      }
    })
    console.log("checked LANG", this.checkedLanguages)
    this.filterAllVolumes();
  }

  // ================================
  // toggle & set the INVENTORY TYPE
  // ================================
  toggleInventory(key:number, checkedValue:boolean){
    this.inventories[key-1].checked = checkedValue;
    this.checkedInventories = [];
    this.inventories.forEach(inv => {
      if (inv.checked) {
        this.checkedInventories.push(inv.Key)
      }
    })
    console.log("checked INV", this.checkedInventories)
    this.filterAllVolumes();
  }

  // ==================================
  // toggle & set the INVENTORY STATUS
  // ==================================
  toggleStatus(key: number, checkedValue: boolean){
    this.inventoryStatuses[key]['checked'] = checkedValue;
    this.checkedInventoryStatuses = [];
    this.inventoryStatuses.forEach(status => {
      if (status.checked) {
        this.checkedInventoryStatuses.push(status.Key)
      }
    })
    console.log("checked STATUS", this.checkedInventoryStatuses)
    this.filterAllVolumes();
  }

  // ====================================================
  // Maps inventory column with associated volume status
  // ====================================================
  // Awaits within the getInventoriesForVolumes() function
  // requires this.inventories and this.originalVolumes before running
  mapInventoryStatuses(volumes) {
    volumes.forEach(vol => {
      vol.InventoryStatusMapping = JSON.parse(vol.InventoryStatusMapping)
      for (let i = 1; i <= this.inventories.length; i++) {
        // If inventory ID doesn't exist in mapping array, insert this inventory ID into array with a status of 0 (unassigned)
        if (!(i in vol.InventoryStatusMapping)) {
          vol.InventoryStatusMapping[i] = 0;
        }
      }
    })
    this.inventoryOfVolume = volumes;
    this.getInventoryTotals();
    console.log("inventoryOfVolume", this.inventoryOfVolume)
  }

  // ============================================
  //  FILTER ALL VOLUMES by:
  //  Language, Inventory Type & Inventory Status
  // ============================================
  filterAllVolumes(): void {
    this.inventoryOfVolume = this.originalVolumes.filter(vol => {
      let langCheck = this.checkedLanguages.includes(vol.VolumeLang)
      let filteredInvStatusMap = Object.keys(vol.InventoryStatusMapping)
                                .filter(k => this.checkedInventories.includes(parseInt(k)))
                                .reduce((cur, key) => Object.assign(cur, { [key]: vol.InventoryStatusMapping[key]}), {});
      let statusCheck = Object.values(filteredInvStatusMap).some(v => this.checkedInventoryStatuses.includes(v))
      if (langCheck && statusCheck) {
        return vol;
      }
    })
    this.getInventoryTotals();
  }

  // ======================================
  // gets volume totals for each inventory
  // ======================================
  getInventoryTotals() {
    // Reset inventory totals
    for (let i = 0; i < this.inventories.length; i++) {
      this.inventories[i].total = 0;
    }
    // Add to total if inventory status for volume is 2 or 3 (i.e. "New" or "Active")
    this.inventoryOfVolume.forEach(vol => {
      for (let i = 1; i <= this.inventories.length; i++) {
        if (vol.InventoryStatusMapping[i] == 2 || vol.InventoryStatusMapping[i] == 3) {
          this.inventories[i-1].total++;
        }
      }
    })
  }

  // ==================================================
  // Performs component initialization & retieve data
  // ===================================================
  ngOnInit(): void {
    this.languages = [
      { Key: 1, Value: 'EN', title: 'English', checked: true },
      { Key: 2, Value: 'FR', title: 'French', checked: true }
    ]
    this.getInventories();
    this.getInventoryStatuses();
  }

  // =====================================================================
  // Warn user they are navigating away from the page with unsaved changes
  // =====================================================================
	canDeactivate(): Observable<boolean> | boolean {
    console.log("candeactivate guard")
    if (!this.showUnsavedChangesWarning && this.updatedData.length > 0) {
      this.showUnsavedChangesWarning = true;
      this._snackBar.error("Warning! You've made some changes on this page. Please save any changes prior to navigating away from this page; otherwise all changes will be unsaved.");
      // Hiding warning message after 4 seconds and resetting warning trigger.
      setTimeout(() => {
        this.showUnsavedChangesWarning = false;
      }, 4000);
      return false;
    }
		return true;
	}

  ngOnDestroy(): void {
    this.confirmSubscription.unsubscribe();
  }
}

export enum AteQuestionStatus {
  Unassigned = 1,
  Pending,
  Answered,
  Sent,
  Rejected,
  ateEditing,
  ateReady,
  Trash=9
}

export class ExpertBiography {
  ID?: number;
  ExpertID?: number;
  ShortBio?: string;
  FullBio?: string;
  Title?: string;
  Lang?: string;
  LangName?: string;
  ExpertName?:string;
}

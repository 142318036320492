import { Component, Input, EventEmitter, Output, OnInit, AfterViewInit, SimpleChanges } from "@angular/core";
import { AiService } from './ai.service';
import { SnackbarService } from 'app/services/Snackbar.service';
import { EmsConfig } from '../../shared/emsConfig';
import { AudioResponseFormat } from '../../shared/objects/aiPrompts';

@Component({
  selector: "ai-audio",
  templateUrl: "ai-audio.component.html",
})

export class AiAudioComponent implements OnInit {

    public enableAI: boolean = this._emsConfig.enableAI;
    public generatingAI: boolean = false; // AI Loading state
    public audioResponseFormat = AudioResponseFormat;
    public showGenerateBtn: boolean = true;
    public showCreateFileBtn: boolean = false;
    
    // Component input parameters
    @Input() audioUrl: string = ""; // Provided audio clip for openAI
    @Input() inputLang: string = ""; // Language of provided audio clip
    @Input() outputLang: string = ""; // Expected language of openAI response
    @Input() outputFormat: string; // Expected format of openAI response - see AudioResponseFormat class
    @Input() disableCreateFileBtn: boolean = false; // Disable Create File button if a file is already uploaded
    
    // OpenAI Response
    @Output() emitResponse = new EventEmitter<any>(); // response from OpenAI to send back to parent component
    @Output() emitFile = new EventEmitter<any>(); // generated file to send back to parent component
    public response: string = ""; // response from OpenAI to appear in ai-audio component

    constructor (
        public _emsConfig: EmsConfig,
        private _aiService: AiService,
        private _snackBar: SnackbarService
    ) {}

    ngOnInit() {
        this.displayGenerateBtn()
    }

    ngOnChanges(changes: SimpleChanges) {
        // Update output lang if new text track is added
        if (changes.outputLang) { 
            this.outputLang = changes.outputLang.currentValue;
            this.displayGenerateBtn();
        }
    }

    displayGenerateBtn() {
        // Disable Generate VTT button if language is not english or native to audio clip
        if (this.outputLang !== "EN" && (this.outputLang !== this.inputLang)) {
            this.showGenerateBtn = false;
        } else {
            this.showGenerateBtn = true
        }
    }

    // Get Transcription for same language audio 
    getTranscription() {
        this.generatingAI = true;
        this._aiService.getTranscriptionFromUrl(this.audioUrl, this.outputFormat, this.inputLang).subscribe(
            res => {
                this.generatingAI = false;
                this.emitResponse.emit(res);
                this.response = res;
                this.showCreateFileBtn = true;
            },
            err => {
                console.log("ERR", err)
                this._snackBar.error(err.message)
                this.disableCreateFileBtn = true;
                this.generatingAI = false;
            }
        )
    }

    // Translate non-english audio into English Transcription
    getEnglishTranslation() {
        this.generatingAI = true;
        this._aiService.getTranslationFromUrl(this.audioUrl, this.outputFormat).subscribe(
            res => {
                this.generatingAI = false;
                this.emitResponse.emit(res);
                this.response = res;
                this.showCreateFileBtn = true;
            },
            err => {
                console.log("ERR", err)
                this._snackBar.error(err.errorMessage)
                this.generatingAI = false;
            }
        )
    }

    // Create VTT file from response string
    createFile() {
        const blob = new Blob([this.response], { type: 'text/' + this.outputFormat });
        const file = new File([blob], 'ai-audio.' + this.outputFormat, { type: 'text/' + this.outputFormat });
        this.emitFile.emit(file);
    }
}

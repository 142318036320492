import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { CacheItem } from './cache-item';
import { AdminService } from './admin.service';
import { EmsConfig } from 'app/shared/emsConfig';
import { forkJoin } from 'rxjs';
import { SpinnerService } from 'app/shared/spinner.service';
import { PopupService } from 'app/shared/popup.service';
import { AccountsService } from 'app/accounts/accounts.service';

@Component({
  selector: 'clear-cache',
  templateUrl: 'clear-cache.component.html'
})


export class ClearCacheComponent implements OnInit {

  public accounts: any[];
  public _cacheItems: CacheItem[];
  public _modalOpen: boolean = false;
  public _selectedAsset: string;
  public _selectedAssetNames: string;
  public get _assetCacheItems(){return this._cacheItems.filter(e=>e.UserSetting===false)};
  public _selectedOrg: string = '';
  private _userCacheRegex = 'UserPrefs_([A-Za-z0-9-]*)_[A-Za-z]*';
  private _cachedOrgProperties: string[] = ['Approved_Emails','UserPrefs', 'AllUserVolumes', 'Account_Dashboard', 'AccountConnect_Item','SubcategoryIds','Promotions_LifespeakPromotion', 'Training_ScheduleItem','CategoriesGeneric','CategoriesPrimary','CategoriesAuthorized','Subcats','MostPopular'];

  constructor(private _adminService: AdminService, private _accountsService: AccountsService, private emsConfig: EmsConfig, private _spinnerService: SpinnerService) {

  }




  ngOnInit() {

    this._cacheItems = [
      { CacheTitle: 'Videos', CacheKey: 'Clip', ItemCount: 0, CachedItems: [], UserSetting: false, TableId: 7,ClearCacheValue:'Clip'  },
      { CacheTitle: 'ATE Events',  CacheKey: 'Events', ItemCount: 0, CachedItems: [], UserSetting: false, TableId: 9,ClearCacheValue:'Event'  },
      { CacheTitle: 'Language Resources',  CacheKey: 'Language', ItemCount: 0, CachedItems: [],  UserSetting: false, TableId: 8 },
      { CacheTitle: 'Experts',  CacheKey: 'Expert', ItemCount: 0, CachedItems: [], UserSetting: false, TableId: 12,ClearCacheValue:'Expert' },
      { CacheTitle: 'Blogs',  CacheKey: 'Blog', ItemCount: 0, CachedItems: [], UserSetting: false, TableId: 12,ClearCacheValue:'Blog' },
      { CacheTitle: 'Client Settings',  CacheKey: 'ClientPrefs_', ItemCount: 0, CachedItems: [],  UserSetting: true, TableId: -1 }
    ]

    this.GetAllCachedItemsData();

    this._accountsService.getAllAccounts().subscribe(e =>
      this.accounts = e
    );
  }



  public RefreshCachedItemsData() {

    this.ResetCacheItems(true);
    this.ResetCacheItems(false);

    this.GetAllCachedItemsData();
  }
  public ClearCacheForAsset(assetToken: string) {
    this._adminService.clearCacheForAsset(assetToken).subscribe(data => {
      this.BuildCacheItemTable(data, false);
    });
  }

  public ClearCacheForOrg(): void {
    if (this._selectedOrg === '') {
      alert('Select a client');
      return;
    }

    
   this._adminService.removeCachedItemsForClient(this._selectedOrg).subscribe(data => {

      console.log(data);


      alert(`Cache has been cleared for ${this._selectedOrg}`);


      //remove spinner manually, since this is not going to the normal api
      this._spinnerService.updateSpinner({ toggle: false });

    });
  }

  public ClearServerCache(): void {

    const warningText='This will clear the cache for the Library and API and could affect site performance.\r\n Do you wish to continue?';
    if (!window.confirm(warningText)) {
      alert('Operation cancelled');
      return;
    }

    
    this._adminService.clearEntireLibraryCache().subscribe(data => {

      this.RefreshCachedItemsData();
      alert(`Server cache has been cleared`);


      //remove spinner manually, since this is not going to the normal api
      this._spinnerService.updateSpinner({ toggle: false });

    });
  }

  public ShowCachedDataForClient() {


    this._adminService.getCachedItemsForClient(this._selectedOrg).subscribe(data => {

      //log out the items for debuging
      console.log(data);
 
      //remove spinner manually, since this is not going to the normal api
      this._spinnerService.updateSpinner({ toggle: false });
      this._selectedAssetNames = `<h3>API</h3><ul><li>${data.sort().join('</li><li>')}</li></ul><h3>Library</h3><ul><li> ${data.sort().join('</li><li>')}</li></ul>`;
      this._modalOpen=true;
    }, err => console.log('Error:', err));
  }

  public ShowCachedItems(tableId: number, assetVals: string, assetName: string): void {


    this._selectedAsset = assetName;
    this._selectedAssetNames = this._cacheItems.find(e => e.CacheTitle === assetName)
      .CachedItems.sort((a, b) => parseInt(a) - parseInt(b))
      .join(', ');
    this._modalOpen = true;


  }

  public stopProp(event: any): void {
    event.stopPropagation();
  }

  private BuildCacheItemTable(data: any[], isUserSetting?: boolean): void {
    if (isUserSetting !== null) {
      this.ResetCacheItems(isUserSetting);
    }
    if (!data) {
      return;
    }

    data.forEach(record => {

      // note that some cached items can belong to multiple asset groups,
      //filter to find the ones that apply
      const that = this;
      let currentCacheItems = this._cacheItems.filter(item => record.Value.includes(item.CacheKey));
      if (currentCacheItems !== undefined) {

        // if there are matches for this cached item. go through each asset group and add to list
        currentCacheItems.forEach((currentCacheItem)=>{
        const cacheValue = record.Value;
        if (!currentCacheItem.CachedItems.includes(cacheValue)) {
          currentCacheItem.ItemCount++;
          currentCacheItem.CachedItems.push(cacheValue);
        }
        });
      }
    });
  }



  private GetAllCachedItemsData() {

    const cacheKeys = this._cachedOrgProperties.map(e => `${e}_`).join(',');
    var apiAssets = this._adminService.getAllCachedAssets();
 



    this._adminService.getAllCachedAssets().subscribe(data => {

      //log out the items for debuging
      console.log(data);
 

      this.BuildCacheItemTable(data);
      //remove spinner manually, since this is not going to the normal api
      this._spinnerService.updateSpinner({ toggle: false });
    }, err => console.log('Error:', err));
  }





  private ResetCacheItems(isUserSetting: boolean): void {

    this._cacheItems.filter(e => e.UserSetting === isUserSetting).forEach(e => {
      e.ItemCount = 0;
      e.CachedItems = [];
    })

  }



}

<div class="help-edit-container section-padding">
  <syncstatus [assetID]="questions[0]?.SectionId" [tableID]="1003" ></syncstatus>
  <!-- question list -->
  <div class="help-question-container info-container__text-container" *ngFor="let q of questions; let i = index">
    <form #form="ngForm">
      <div class="form-split left-split">
        <div class="basic-info__setting">
          <h4 class="basic-info__heading setting__heading ls-subheading required-field">
            <div class="svg-icon svg-baseline">
              <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                <path d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z" />
              </svg>
            </div>
            Question
          </h4>
          <div class="input-container">
            <input type="text" class="basic-info__text setting__text-input text-input" name="question{{i + 1}}" [(ngModel)]="q.Question">
          </div>
        </div>
        <div class="basic-info__setting">
          <h4 class="basic-info__heading setting__heading ls-subheading required-field">
            <div class="svg-icon svg-baseline">
              <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path clip-path="url(#b)" d="M4 9h16v2H4zm0 4h10v2H4z"/>
              </svg>
            </div>
            Answer
          </h4>
          <div class="input-container answer-input-container">
            <textarea type="text" class="basic-info__text setting__text-input textarea-input answer-textarea"  name="answer{{i + 1}}" [(ngModel)]="q.Answer" rows="6"></textarea>
          </div>
        </div>
        <div class="basic-info__setting">
          <h4 class="basic-info__heading setting__heading ls-subheading">
            <div class="svg-icon svg-baseline">
              <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                <path d="M16,20H20V16H16M16,14H20V10H16M10,8H14V4H10M16,8H20V4H16M10,14H14V10H10M4,14H8V10H4M4,20H8V16H4M10,20H14V16H10M4,8H8V4H4V8Z" />
              </svg>
            </div>
            Related Feature
          </h4>
          <div class="select-container">
            <select class="setting__select-input select-input" name="relatedFeature{{i + 1}}" [(ngModel)]="q.RelatedFeature">
              <option value="-1" disabled>Select a feature</option>
              <option value="ate">Ask the Expert</option>
              <option value="cpd">CPD</option>
              <option value="expertblog">Expert Blog</option>
              <option value="iq">iQ</option>
              <option value="compliance">Compliance</option>
              <option value="mailinglist">Mailing List</option>
              <option value="closedcaptions">Closed Captions</option>
              <option value="marathon">Marathon</option>
              <option value="poll">Poll</option>
            </select>
          </div>
        </div>
        <div class="basic-info__setting">
          <h4 class="basic-info__heading setting__heading ls-subheading">
            <div class="svg-icon svg-baseline">
              <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                <path d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z" />
              </svg>
            </div>
            Video URL
          </h4>
          <div class="input-container">
            <input type="text" class="basic-info__text setting__text-input text-input" name="url{{i + 1}}" [(ngModel)]="q.VideoUrl">
          </div>
        </div>
      </div>

      <div class="form-split right-split">
        <div class="basic-info__setting">
          <h4 class="basic-info__heading setting__heading ls-subheading required-field">
            <div class="svg-icon svg-baseline">
              <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                <path d="M3,13H15V11H3M3,6V8H21V6M3,18H9V16H3V18Z" />
              </svg>
            </div>
            Order
          </h4>
          <div class="input-container">
            <input type="text" class="basic-info__text setting__text-input text-input" name="order{{i + 1}}" [(ngModel)]="q.SortOrder">
          </div>
        </div>
      </div>
      <div class="form-button-container">
        <button (click)="openConfirmDialog('helpQuestion', 'updateQuestion', q, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)" type="button" class="btn question-submit">
          Save
        </button>
        <button (click)="openConfirmDialog('helpQuestion', 'deleteQuestion', q.ID, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)" type="button" class="btn question-delete">
          Delete
        </button>
      </div>
    </form>
  </div>

  <!-- add new question -->
  <div class="help-question-container info-container__text-container" *ngIf="showAddFields">
    <form class="new-question-form">
      <div class="form-split left-split">
        <div class="basic-info__setting">
          <h4 class="basic-info__heading setting__heading ls-subheading required-field">
            <div class="svg-icon svg-baseline">
              <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                <path d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z" />
              </svg>
            </div>
            Question
          </h4>
          <div class="input-container">
            <input type="text" class="basic-info__text setting__text-input text-input" name="newQuestion" [(ngModel)]="newQuestion.Question">
          </div>
        </div>
        <div class="basic-info__setting">
          <h4 class="basic-info__heading setting__heading ls-subheading required-field">
            <div class="svg-icon svg-baseline">
              <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path clip-path="url(#b)" d="M4 9h16v2H4zm0 4h10v2H4z"/>
              </svg>
            </div>
            Answer
          </h4>
          <div class="input-container answer-input-container">
            <textarea type="text" class="basic-info__text setting__text-input textarea-input answer-textarea"  name="newAnswer" [(ngModel)]="newQuestion.Answer" rows="6"></textarea>
          </div>
        </div>
        <div class="basic-info__setting">
          <h4 class="basic-info__heading setting__heading ls-subheading">
            <div class="svg-icon svg-baseline">
              <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                <path d="M16,20H20V16H16M16,14H20V10H16M10,8H14V4H10M16,8H20V4H16M10,14H14V10H10M4,14H8V10H4M4,20H8V16H4M10,20H14V16H10M4,8H8V4H4V8Z" />
              </svg>
            </div>
            Related Feature
          </h4>
          <div class="select-container">
            <select class="setting__select-input select-input" name="newRelatedFeature" [(ngModel)]="newQuestion.RelatedFeature">
              <option value="-1" disabled>Select an feature</option>
              <option value="ate">Ask the Expert</option>
              <option value="cpd">CPD</option>
              <option value="expertblog">Expert Blog</option>
              <option value="iq">iQ</option>
              <option value="compliance">Compliance</option>
              <option value="learninghub">Learning Hub</option>
              <option value="mailinglist">Mailing List</option>
              <option value="closedcaptions">Closed Captions</option>
              <option value="marathon">Marathon</option>
              <option value="poll">Poll</option>
            </select>
          </div>
        </div>
        <div class="basic-info__setting">
          <h4 class="basic-info__heading setting__heading ls-subheading">
            <div class="svg-icon svg-baseline">
              <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                <path d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z" />
              </svg>
            </div>
            Video URL
          </h4>
          <div class="input-container">
            <input type="text" class="basic-info__text setting__text-input text-input" name="newUrl" [(ngModel)]="newQuestion.VideoUrl">
          </div>
        </div>
      </div>

      <div class="form-split right-split">
        <div class="basic-info__setting">
          <h4 class="basic-info__heading setting__heading ls-subheading required-field">
            <div class="svg-icon svg-baseline">
              <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                <path d="M3,13H15V11H3M3,6V8H21V6M3,18H9V16H3V18Z" />
              </svg>
            </div>
            Order
          </h4>
          <div class="input-container">
            <input type="text" class="basic-info__text setting__text-input text-input" name="newOrder" [(ngModel)]="newQuestion.SortOrder">
          </div>
        </div>
      </div>
      <div class="form-button-container">
        <button (click)="openConfirmDialog('helpQuestion', 'updateQuestion', newQuestion, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)" type="button" class="btn question-submit">
          Save
        </button>
        <button (click)="clearNewQuestion()" type="button" class="btn question-delete">
          Clear
        </button>
      </div>
    </form>
  </div>

  <!-- add new button -->
  <div class="button-container">
    <button type="button" class="btn question-add" (click)="addNewQuestion()">Add Question</button>
  </div>
</div>

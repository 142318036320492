// Angular Things
import { Component, Inject } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { UtilitiesService } from 'app/shared/utilities.service';
import { CmsService } from 'app/cms/cms.service';
import { FormsModule } from '@angular/forms';
import { SnackbarService } from 'app/services/Snackbar.service';

export interface DialogDataAnswer {
	answer: '',
	question: '',
	//category: '',
	//templateLanguage: '',
	//templateId: 0
}


@Component({
	selector: 'dialog-normal-answer-dialog',
	templateUrl: 'AIAnswer.component.html',
	standalone: true,
	imports: [MatDialogModule, MatButtonModule, MatFormFieldModule, MatInputModule, FormsModule, MatCheckboxModule],
})
export class DialogGenericAnswerContentDialog {
	constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataAnswer, public _utilitiesService: UtilitiesService, public _cmsService: CmsService,
		public _snackbarService: SnackbarService) { }
}

<div class="modal-window" (click)="toggleEditWindow()">
  <div class="modal-container" (click)="onEvent($event)">
    <div class="modal__title-container b-dark-blue">
      <h3 class="modal__title ls-headline f-white">Modify <span *ngIf="question.QuestionType < 2">Question</span> <span
          *ngIf="question.QuestionType > 1">Comment</span> (ID: {{question.QuestionID}})</h3>
      <button class="modal__close" (click)="toggleEditWindow()">
        <svg viewBox="0 0 24 24">
          <path fill="#ffffff"
            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>
      </button>
    </div>
    <div class="modal__content">
      <table>
        <tbody>
          <tr>
            <td class="required-field">Nickname:</td>
            <td class="col-fill-space">
              <input type="text" [(ngModel)]="question.Email" (input)="onNicknameChange($event.target.value)">
              ({{question.ClientName}})
            </td>
          </tr>
          <tr>
            <td>Date sent:</td>
            <td class="col-fill-space">{{question.DateSubmittedFormatted}}</td>
          </tr>
          <tr>
            <td>Type:</td>
            <td *ngIf="question.Status > 1">{{getQuestionType(question.QuestionType)}}</td>
            <td *ngIf="question.Status <= 1">
              <span *ngIf="question.QuestionType === 2">Mod comment</span><span
                *ngIf="question.QuestionType === 3">Expert comment</span>
              <select *ngIf="question.QuestionType !== 2 && question.QuestionType !== 3" id="question-type"
                name="question-type" [(ngModel)]="question.QuestionType">
                <option [ngValue]="1">Question</option>
                <option [ngValue]="4">User comment</option>
              </select>
            </td>
          </tr>
          <tr *ngIf="expertEvent.For_Marathon && question.Status === 1">
            <td>Marathon Reassign:</td>
            <td>
              <select id="marathon-related-chats" name="marathon-related-chats"
                (change)="toggleReassign($event.target.value)" [(ngModel)]="relatedChatID">
                <option [selected]="true" value="-1">Select Marathon Chat</option>
                <option [value]="chat.EventID" *ngFor="let chat of relatedMarathonChats">{{chat.Title}}</option>
              </select>

              <button *ngIf="showReassign" (click)="reassignQuestion()" class="btn ls-button-1">Reassign</button>
            </td>
          </tr>
          <tr>
            <td class="required-field">
              <span *ngIf="question.QuestionType < 2">Question:</span>
              <span *ngIf="question.QuestionType > 1">Comment:</span>
            </td>
            <td class="textarea-container col-fill-space">
              <textarea id="ate-question-editor"></textarea>
            </td>
          </tr>
          <!-- don't show answer field for moderator until question status is at least 3 OR if it's a user comment needing a reply -->
          <tr [hidden]="hideAnswerField(question)">
            <td class="required-field">
              <span *ngIf="question.QuestionType < 2">Answer:</span>
              <span *ngIf="question.QuestionType > 1">Reply:</span>
            </td>
            <td class="textarea-container col-fill-space">
              <textarea id="ate-answer-editor"></textarea>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="align-btn-right">
        <button class="btn ls-button-1" *ngIf="question.Status < 7" (click)="updateQuestion()">Update</button>
        <!--only show this button if question is in pending-->
        <button class="btn ls-button-1" *ngIf="question.Status === 1" (click)="updateAndSend(2)">
          Update & Post <span *ngIf="question.QuestionType === 1">Question</span><span
            *ngIf="question.QuestionType > 1">Comment</span>
        </button>
        <button class="btn ls-button-1" *ngIf="question.Status === 2" (click)="updateAndSend(3)">Update & Move</button>
        <!--only show this button if question is in answered-->
        <button class="btn ls-button-1" *ngIf="question.Status === 3" (click)="updateAndSend(4)">Update & Post
          Answer</button>
      </div>

    </div>

  </div>
</div>

import { Injectable, Inject } from '@angular/core';
import { UtilitiesService } from './utilities.service';
import * as moment from 'moment';


@Injectable()
export class AnalyticsQuery {

  StartDate: any;

  EndDate: string;

  MediaType: string;

  UserType: string;

  GroupByParam: string;

  StackByParam: string;

  Language: string;

  Usernames: string;

  BrowserType: string;

  ReportName: string;

  AdditionalData: string;

  AdditionalDataList: Array<string> = [];

  ListOfOrgs: Array<string> = [];
  

  constructor( @Inject(UtilitiesService) private _utilities: UtilitiesService) {
    // listen to when the event starts, and then reset the query for that particular report type
    this.init();
  }

  public formatForRequest() {    
    console.log('Start date: ' + this.StartDate);
    console.log('End date: ' + this.EndDate);    
    this.Usernames = this.ListOfOrgs.join(',');
    this.AdditionalData = this.AdditionalDataList.join('|');
  }

  public extractDateParts(rawDate: string, monthOnly: boolean) {
    if (this.GroupByParam === 'month') {

      let months: Array<string> = ",Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sept,Oct,Nov,Dec".split(',');
      let target = rawDate.split('/');
      let month = target.length > 1 ? months[parseInt(target[1])] + ' ' : '';
      let year = target[0];
      return monthOnly ? month : `${month} ${year} `;
    }
    //for quarters, we want to return the format Q1'16
    else if(this.GroupByParam === 'quarter')
    {
      let target = rawDate.split('/');
      return `Q${target[1]} '${target[0].substring(2)}`;
    }
    //for days, we want to return the format mm/dd/yy
    else if (this.GroupByParam === 'day'){
        let target = rawDate.split('/');
      return `${target[1]}/${target[2]}/${target[0].substring(2)}`;
    }
    else{
       return rawDate;
    }

  }

  public toString() {
    let userList: string = this.ListOfOrgs.length > 0 ? this.ListOfOrgs.join(',') : 'all users';
    if (this.AdditionalDataList[0] === 'all' && this.AdditionalDataList.length === 1)
      return `${userList} - ${this.UserType} user types - ${this.MediaType} media formats - ${this.BrowserType} devices  -${this.Language} language from ${this.StartDate} to ${this.EndDate}`;

    return `${userList} (${this.AdditionalDataList})- ${this.UserType} user types - ${this.MediaType} media formats - ${this.BrowserType} devices  -${this.Language} language from ${this.StartDate} to ${this.EndDate}`
  }

  public init(): void {
    this.EndDate = this.getInitialDate(false);
    this.StartDate = this.getInitialDate(false);
    this.UserType = 'all';
    this.MediaType = 'all';
    this.Language = 'all';
    this.BrowserType = 'all';
    this.GroupByParam = 'month';
    this.StackByParam='';
    this.Usernames = '';
    this.AdditionalDataList[0] = 'all';
    this.ReportName = 'custom';
  }

  public getInitialDate(setOneYearBack: boolean): string {
    let initDate = moment();

    if (setOneYearBack) {
      initDate.year((initDate.year()-1))
      initDate.startOf('month');
      return initDate.format('YYYY-MM-DD');
    } else {
      return initDate.format('YYYY-MM-DD');
    }
  }

  public clearAllUsers(): void {
    this.ListOfOrgs = [];
  }

}

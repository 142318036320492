<div class="organization-sync-container section-padding">
  <h1>Sync Assets</h1>

  <table class="display-table sync-organization-table display-table--extra-padding">
    <thead>
      <tr>
        <th>Asset </th>
        <th>Items Pending</th>
        <th class="tooltip-column">{{emsConfig.text?.EMS_Sync.Sync_Changes}}
          <tooltip [tooltipMessage]="emsConfig.text?.EMS_Sync.Sync_Changes_Tooltip"></tooltip>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let op of assetOperations">
        <td>{{op.Sync_Name}}</td>
        <td> <a href="javascript:void(0);" (click)="getSyncInfo(op.Operation_Name)">{{op.Changes_Pending}}</a></td>
        <td><button (click)="openConfirmDialog(op.Operation_Name, 'This will sync all'+ op.Sync_Name +' assets. Do you wish to continue?')"
            class="sync-table-btn"> {{emsConfig.text?.EMS_Sync.Sync_Changes}}</button></td>
      </tr>
    </tbody>
  </table>
</div>


<!--modal for displaying information about a given sync task-->
<div class="modal-window sync-description-modal" *ngIf='modalOpen' (click)="closeModal()">
  <div class="modal-container b-white" (click)="stopProp($event)">
    <div class="modal__title-container b-dark-blue">
      <h3 class="modal__title ls-headline f-white">Operation Info</h3>
      <button class="welcome-page-modal__close modal__close" (click)="closeModal()">
            <svg viewBox="0 0 24 24">
                <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" fill="#ffffff"></path>
            </svg>
            </button>
    </div>

    <div class="modal__settings-container sync-descripton-text-container">
      <div class="sync-description-table-container">
        <div class="asset-sync-table-container">
        <table class="display-table display-table--extra-padding">
          <thead>
            <tr>
              <th>Asset</th>
              <th>Records To Modified</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let info of formattedSyncInfo">
              <td>{{info.Table}}</td>
              <td>{{info.Records}}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>

    </div>
  </div>

</div>

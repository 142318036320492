// Angular Things
import {Component, HostBinding, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

// Services
import { UtilitiesService } from '../../shared/utilities.service';
import { ConfirmService } from '../../shared/confirm.service';
import { PopupService } from '../../shared/popup.service';
import { EmsConfig } from '../../shared/emsConfig';
import { CmsService } from '../cms.service';
import { CategoriesService } from 'app/categories/categories.service';
import { Categories } from 'app/categories/categories';
import { ExpertSpeciality } from 'app/shared/ExpertSpeciality';
@Component({
  selector: 'cms-expert-specialities',
  templateUrl: 'cms-expert-specialities.component.html'
})
export class CMSExpertSpecialities implements OnInit {
  @HostBinding('class') classAttribute: string = 'cms-expert-specialities';
  private sub: any;
  id: any;
  private page: string;


  pageID: any;
  errorMessage: string;
  plainText: boolean = false;

 specialitiesByGroup: any[]=[];
  // confirm stuff
  confirmData: {};
  confirmExpertData: any;
  confirmAction: string;
  confirmSubscription: Subscription;

  public categoryList: Categories[];
  public specialityList: ExpertSpeciality[];
  public categoryID: number;
  public speciality: string;
  specialitySearch: string;

  public addEditDeleteText: string= 'Add, edit or delete';
  public showSpecialitySection: boolean= false;

  constructor(
    private route: ActivatedRoute,
    private cmsService: CmsService,
    public _emsConfig: EmsConfig,
    private _confirmService: ConfirmService,
    private _categoriesService: CategoriesService,
    private _popupService: PopupService,
    private location: Location,
    private router: Router
  ) {

  }


  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(component: string, action: string, message: string): void {
    this.confirmAction = action;
    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }



  getCategories(): void {
    this._categoriesService.getAllCategories()
      .subscribe(
        CategoryList => {
          this.categoryList = CategoryList['categories'];
        },
        err => this.errorMessage = <any>err
      );
  }

   // ====================================
  // get all expert speciality & sort them
  // =====================================
  getExpertSpecialitiesWithCategory(): void {
    this.cmsService.getAllExpertSpeciality()
      .subscribe(
        SpecialityList => {
          this.specialityList = SpecialityList;
          console.log(this.specialityList);
          if(this.specialityList!==undefined || this.specialityList!==null) {
          // group all specialities by category
          let groups = this.specialityList.reduce(function(obj, item){
            // check if current item's category exists, if does then reduce it to single category
            obj[item.CategoryName] = obj[item.CategoryName] || [];
            obj[item.CategoryName].push({categoryID: item.CategoryID, specialityID: item.SpecialityID, speciality: item.Speciality});
            return obj;
        }, {});
        // create a new array of objects of specialities by category name
        this.specialitiesByGroup = Object.keys(groups).map(function(key){
            return {CategoryName: key, SpecialityDetails: groups[key]};
        });
        console.log(this.specialitiesByGroup);
      }
        },
        err => this.errorMessage = <any>err
      );
  }

   // ==========================
  // delete speciality of experts
  // ===========================
  deteteSpeciality(item: any): void {
    console.log(item);
    this.cmsService.deleteExpertSpeciality(item.categoryID, item.specialityID).subscribe(
      data=>{
        console.log(data);
        if (data) {
          this._popupService.updateNotification({
            message: 'Expert Speciality has been deleted successfully',
            success: true
          });
          this.getExpertSpecialitiesWithCategory();
      }else {
        this._popupService.updateNotification({
          message: this._emsConfig.text.EMS_General.Popup_Error,
          error: true
        });
      }
      });
  }
   // ==========================
  // add speciality for experts
  // ===========================
  addSpeciality():void{
    this.cmsService.insertExpertSpeciaity(this.categoryID, this.speciality).subscribe(
      data => {
        console.log(data);
        if (data) {
          this._popupService.updateNotification({
            message: 'Expert speciality has been created successfully',
            success: true
          });
          this.getExpertSpecialitiesWithCategory();
      }else {
        this._popupService.updateNotification({
          message: this._emsConfig.text.EMS_General.Popup_Error,
          error: true
        });
      }
      });
  }
   // ==========================
  // hide/show edit section
  // ===========================
  toggleSpecialitySection(): void{
    this.showSpecialitySection = !this.showSpecialitySection;
    this.addEditDeleteText = this.showSpecialitySection ? 'Done editing' : 'Add, edit or delete';
  }

  // ==========================
  // get back to expert detail page
  // ==========================
  getBack() {
  this.location.back();
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.page = params['page'];
    });
    this.getCategories();
    this.getExpertSpecialitiesWithCategory();
  }

}

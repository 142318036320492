// Angular Things
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';
import { UtilitiesService } from '../../shared/utilities.service';

// Classes
import { Expert } from '../../shared/expert';

// Components
import { CategoriesComponent } from '../../categories/categories.component';

//  Services
import { CmsService } from '../cms.service';

@Component({
  selector: 'cms-expert', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-expert.component.html'
})

export class CmsExpertComponent implements OnInit {

  allExperts: Expert[]= [];
  expertSearch: string;

  lang: string = 'EN';

  // edit Experts variables
  showEditButton: boolean = false;
  selectedExpertId: number;

  // filter variables
   expertStatus: string;
  public isOnPoint: boolean = false;
  activeFilter: string = 'active';
  currentlySearching: boolean = false;
  activeFilterStore: string;
  expertStatusStore: string;

  public showExpertPreview: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute, public _cmsService: CmsService, public _emsConfig: EmsConfig, public _utilities: UtilitiesService) { }

  // update various pipe filters
  public updateExpertFilters(status: string): void {
    this.expertStatus = status;
    this.activeFilter = status;
    console.log('status changed');
    console.log(this.allExperts.length);
  }

  // if using the search bar ignore the other filter options
  usingSearchBar(text: any): void {
    if (this.expertSearch.length > 0) {
      if (!this.currentlySearching) {
        // store the current filter settings to apply back later
        this.currentlySearching = true;
        this.activeFilterStore = this.activeFilter;
        this.expertStatusStore = this.expertStatus;
      }

      // turn off the filters
      this.activeFilter = 'all';
      this.expertStatus = 'all';
    } else {
      // when search input is cleared, set back the filters
      this.activeFilter = this.activeFilterStore;
      this.expertStatus = this.expertStatusStore;
      this.currentlySearching = false;
    }
  }

  // reset filters
  public resetFilters(): void {
    this.activeFilter = 'all';
    this.updateExpertFilters('all');
  }


  getAllExperts(): void {
    this._cmsService.getAllExperts()
      .subscribe(
      ExpertItem => {
        this.allExperts = ExpertItem;
        for (let i = 0; i < this.allExperts.length; i++) {
          this.allExperts[i].NormalizedName = this._utilities.normalizeTerm(this.allExperts[i].ExpertName.English);
        }
        console.log(this.allExperts);
      }
    );
  }



  // focus on selected Expert and store the id
  selectExpert($event: any): void {
    const target = $event.currentTarget;
    target.focus();
    this.selectedExpertId = $event.currentTarget.attributes.id.value;
    console.log(this.selectedExpertId);
    this.showEditButton = true;
  }

  deselectExpert($event: any): void {
    // check if click is on specific element
    if ($event.relatedTarget != null) {
      // if click is another Expert or the fab button, show the edit button
      if ($event.relatedTarget.className === 'btn--fab b-dark-blue cms-container__fab cms-container__fab--edit' || $event.relatedTarget.className === 'iq-card card') {
        this.showEditButton = true;
      } else {
        // if not clicking on other Expert, show add button instead of edit
        this.showEditButton = false;
      }
    } else {
      this.showEditButton = false;
    }
  }

  createNewExpert(): void {
    // Set Selected Expert to -1 for new Expert
    this.showExpertPreview = false;
    this.selectedExpertId = -1;
    if (this.router.url.includes('expert')) {
      this.router.navigate([this.selectedExpertId], { relativeTo: this.route });
    } else {
      // if coming directly from cms default route (need to add Expert to url)
      this.router.navigate(['expert', this.selectedExpertId], { relativeTo: this.route });
    }
  }

  // Navigates to Expert Details template
  onSelect() {
    // Navigate with relative link
  if (this.router.url.includes('expert')) {
      this.router.navigate([this.selectedExpertId], { relativeTo: this.route });
    } else {
      // if coming directly from cms default route (need to add Expert to url)
      this.router.navigate(['expert', this.selectedExpertId], { relativeTo: this.route });
    }
  }

  // update the Expert list by language
  setLang(lang: string): void {
    this.lang = lang;
    console.log(this.lang);
    // reset the filters
    this.resetFilters();
    this.getAllExperts();
  }

  ngOnInit(): void {
      // gets list of expert by language
    this.getAllExperts();
  }

}

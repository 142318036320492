import { AteQuestionStatus } from './AteQuestionStatus';
import { AteQuestionType } from './AteQuestionType';
import { TextItem } from '../../shared/TextItem';

export class AteQuestion {
  QuestionID;
  DateSubmitted: string;
  DateSubmittedFormatted: string;
  Email: string;
  EmployeeName: string;
  Question: string;
  CategoryID: number;
  ExpertID: number;
  ClientName: string;
  Status: number;
  QuestionType: AteQuestionType;
  ArchiveChatID: number;
  OriginalLang: string;
  ExpertReject: boolean;
  DatePosted: Date;
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmService } from "app/shared/confirm.service";
import { EmsConfig } from "app/shared/emsConfig";
import { PopupService } from "app/shared/popup.service";
import { UtilitiesService } from "app/shared/utilities.service";
import { Subscription } from "rxjs";
import { CmsService } from "../cms.service";
import { HelpQuestion } from "../HelpQuestion";
import { HelpSection } from "../HelpSection";

@Component({
  selector: 'cms-help-edit', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-help-edit.component.html'
})

export class CmsHelpEditComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  private sub: any;

  questions: HelpQuestion[] = [];
  helpSection: HelpSection;

  errorMessage: string;

  lang: string = 'EN';

  sectionLang: string;
  sectionId: number;

  createNew: boolean = false;
  showAddFields: boolean = false;
  newQuestion: HelpQuestion;

  confirmData: {};
  confirmQuestionData: any;
  confirmAction: string;
  confirmSubscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute, public _cmsService: CmsService, public _emsConfig: EmsConfig, public _confirmService: ConfirmService, public _utilitiesService: UtilitiesService, public _popupService: PopupService) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'updateQuestion') {
            console.log('update a question');
            this.updateQuestion(this.confirmQuestionData);
          } else if (this.confirmAction === 'deleteQuestion') {
            this.deleteHelpQuestion(this.confirmQuestionData);
          }
        }
      }
    );
  }

  openConfirmDialog(component: string, action: string, questionData: any, message: string): void {
    this.confirmAction = action;
    this.confirmQuestionData = questionData;

    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }

  getSectionQuestions(sectionId): void {
    this._cmsService.getHelpQuestions(sectionId).subscribe(
        data => {
          this.questions = data;
        },
        err => this.errorMessage = <any>err,
        () => console.log(this.questions)
    );
  }

  updateQuestion(q): void {
    console.log(q);

    if (this._utilitiesService.isBlank([q.Question, q.Answer, q.SortOrder])) {
      this._popupService.updateNotification({ message: 'Please fill out all required fields.', error: true });
    } else {
      console.log(q);
      this._cmsService.updateHelpQuestion(q).subscribe(
        data => {
          console.log(data);
          if (this.createNew) {
            if (data.questionId === -1) {
              this._popupService.updateNotification({ message: 'Help question not created. Please try again.', error: true });
            } else {
              this._popupService.updateNotification({ message: 'Help question has been created successfully.', success: true });
              this.createNew = false;
              this.showAddFields = false;
              this.getSectionQuestions(this.sectionId);
            }
          } else {
            if (!data.succeeded) {
              this._popupService.updateNotification({ message: 'Help question not been updated. Please try again.', error: true });
            } else {
              this._popupService.updateNotification({ message: 'Help question has been updated successfully.', success: true });
              this.getSectionQuestions(this.sectionId);
            }
          }
        }
      );
    }
  }

  deleteHelpQuestion(id): void {
    this._cmsService.deleteHelpQuestion(id).subscribe(
      data => {
        if (!data.succeeded) {
          this._popupService.updateNotification({ message: 'Help question not been deleted. Please try again.', error: true });
        } else {
          this._popupService.updateNotification({ message: 'Help question has been deleted successfully.', success: true });
          this.getSectionQuestions(this.sectionId);
        }
      }
    );
  }

  geHelpSection(id): void {
    this._cmsService.getHelpSectionbyID(id).subscribe(
      data => {
        this.helpSection = data;
        this.sectionLang = data.SectionLang;
      },
      err => this.errorMessage = <any>err,
      () => console.log(this.helpSection)
    );
  }

  addNewQuestion(): void {
    this.newQuestion = new HelpQuestion;
    this.newQuestion.ID = -1;
    this.newQuestion.Question = null;
    this.newQuestion.Answer = null;
    this.newQuestion.QuestionLang = this.sectionLang;
    this.newQuestion.RelatedFeature = null;
    this.newQuestion.SectionId = this.sectionId;
    this.newQuestion.SortOrder = null;
    this.newQuestion.VideoUrl = null;

    this.createNew = true;
    this.showAddFields = true;
  }

  clearNewQuestion(): void {
    this.createNew = false;
    this.showAddFields = false;
  }

  ngOnInit(): void {
    // gets the section id from the url
    this.sub = this.route.params.subscribe(params => {
      this.sectionId = parseInt(params['id']);
    });

    this.getSectionQuestions(this.sectionId);
    this.geHelpSection(this.sectionId);
  }

  ngOnDestroy(): void {
    this.confirmSubscription.unsubscribe();
  }

}

export class SubtitleLang {
  ID?: number=-1;
  Lang?: string;
  Title?: string;
  CaptionUploaded: boolean=false;
  LangCode?:string="";
  ClipID?:number;
  SubtitleFileName?:string;
  TableID?:number;
  VolumeID?:number;
}

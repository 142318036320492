<nav *ngIf='isLoggedIn' class='navbar b-dark-blue card-box-shadow'>
  <ul class='nav__list uppercase'>
    <li *ngIf="_emsConfig.UserRole === 'systemadmin' || _emsConfig.UserRole === 'accountadmin'"
      class="nav__links-list-item">
      <a class="nav__link f-secondary ls-title nav__dashboard-icon" [routerLink]="['/dashboard']"
        [routerLinkActive]="['active']">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path fill="#FFFFFF" d="M13,3V9H21V3M13,21H21V11H13M3,21H11V15H3M3,13H11V3H3V13Z" />
        </svg>
      </a>
    </li>
    <li class="nav__links-list-item" *ngIf="_securedRoutes.CurrentUserHasAccess('cms', false)"><a
        class="nav__link f-secondary ls-title" [routerLink]="['/cms']" [routerLinkActive]="['active']">CMS</a></li>
    <li class="nav__links-list-item" *ngIf="_securedRoutes.CurrentUserHasAccess('blog', false)"><a
        class="nav__link f-secondary ls-title" [routerLink]="['/blog']" [routerLinkActive]="['active']">Blog</a></li>
    <li *ngIf="_emsConfig.userHasStandardNavAccess() " class="nav__links-list-item"><a
        class="nav__link f-secondary ls-title" [routerLink]="['/accounts']" [routerLinkActive]="['active']">Accounts</a>
    </li>
    <li *ngIf="_emsConfig.userHasStandardNavAccess() " class="nav__links-list-item"><a
      class="nav__link f-secondary ls-title" [attr.href]="powerBIDashboardEndpoint" target="_blank" >Reports</a>
  </li>
    <!-- <li class="nav__links-list-item" *ngIf="_securedRoutes.CurrentUserHasAccess('connex', false)"><a class="nav__link f-secondary ls-title" [routerLink]="['/connex']" [routerLinkActive]="['active']">Connex</a></li> -->
    <li class="nav__links-list-item" *ngIf="_securedRoutes.CurrentUserHasAccess('asktheexpert', false)"><a
        class="nav__link f-secondary ls-title" [routerLink]="['/asktheexpert']" [routerLinkActive]="['active']">ATE</a>
    </li>
    <li class="nav__links-list-item"  *ngIf="_securedRoutes.CurrentUserHasAccess('asktheexpert', false)"><a
        class="nav__link f-secondary ls-title" [routerLink]="['/marathon']" [routerLinkActive]="['active']">Marathon</a>
    </li>
    <li class="nav__links-list-item"  *ngIf="isBulkBuild"><a
      class="nav__link f-secondary ls-title" [routerLink]="['/addtocampaign']" [routerLinkActive]="['active']">Add To Campaign</a>
  </li>
    <li *ngIf="_emsConfig.userHasStandardNavAccess()" class="nav__links-list-item"><a
        class="nav__link f-secondary ls-title" [routerLink]="['/messages']" [routerLinkActive]="['active']">Messages</a>
    </li>
    <li *ngIf="_emsConfig.userHasStandardNavAccess()" class="nav__links-list-item"><a
        class="nav__link f-secondary ls-title" [routerLink]="['/sync']" [routerLinkActive]="['active']">Sync</a></li>
  </ul>
  <div *ngIf="_emsConfig.UserRole !== 'expert'" class="nav__profile-icon" [routerLink]="['/userprofile']"
    [routerLinkActive]="['active']">
    <svg fill="#FFFFFF" height="30" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z">
      </path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  </div>
  <div class="nav__settings-icon" (clickOutside)="close()" [ngClass]="{'active': showDropdownMenu}">
    <svg fill="#FFFFFF" height="30" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg"
      (click)="showDropdown()">
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z" />
    </svg>
    <div class="settings-dropdown dropdown-menu" *ngIf="showDropdownMenu">
      <ul class="settings-list dropdown-menu__list dropdown-menu__list">
        <li *ngIf="_emsConfig.UserRole==='systemadmin'"
          class="settings-list__list-item dropdown-menu__list-item getHelpDoc"><a
            class="settings__link dropdown-menu__link ls-body-2" [routerLink]="['/clearcache']"
             >Clear Cache</a></li>
        <li *ngIf="_emsConfig.UserRole==='systemadmin'" class="settings-list__list-item dropdown-menu__list-item">
          <a class="settings__link dropdown-menu__link ls-body-2" [routerLink]="['/adminusers']"
            >Admin Users</a>
        </li>
        <li *ngIf="_emsConfig.UserRole==='systemadmin'" class="settings-list__list-item dropdown-menu__list-item">
          <a class="settings__link dropdown-menu__link ls-body-2" [routerLink]="['/migration']"
            >Migration</a>
        </li>
        <li class="settings-list__list-item dropdown-menu__list-item getHelpDoc"><a
            class="settings__link dropdown-menu__link ls-body-2" (click)="getHelpDoc();"
            >Help</a></li>
        <li class="settings-list__list-item dropdown-menu__list-item"><a
            class="settings__link dropdown-menu__link ls-body-2" (click)='logOut();'
            href='javascipt:void(0);'>Logout</a></li>
      </ul>
    </div>
  </div>
</nav>
<div class='container' [ngClass]="{loggedIn: isLoggedIn}">
  <router-outlet></router-outlet>
</div>

<popup-message></popup-message>
<confirm-message></confirm-message>
<ajax-spinner></ajax-spinner>

// Angular Things
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';

// Classes
import { QuizItem } from '../QuizItem';
import { QuizQuestion } from '../QuizQuestion';
import { QuizAnswer } from '../QuizAnswer';

//  Services
import { CmsService } from '../cms.service';
import { ConfirmService } from '../../shared/confirm.service';
import { PopupService } from '../../shared/popup.service';
import { SnackbarService } from 'app/services/Snackbar.service';

@Component({
  selector: 'cms-iq-edit', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-iq-edit.component.html',
  providers: [CmsService]
})
export class CmsIqEditComponent implements OnInit, OnDestroy {
  // id from url variables
  id: number;
  private sub: any;

  errorMessage: string;

  quizDetails: QuizItem;

  answer: any;
  questionData: any;

  selectedClip: number;
  selectedAnswers: any;

  createIqQuestion: number;
  newQuestionClipId: number;

  createNewAnswer: number;
  newAnswerQuestionId: number;

  newQuestion: QuizQuestion;
  newAnswer: QuizAnswer;

  disableFormButtons: boolean = false;

  answersToDelete: Array<any> = [];

  confirmData: {};
  confirmIqData: any;
  confirmAction: string;
  confirmSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private _cmsService: CmsService,
    public _emsConfig: EmsConfig,
    private _confirmService: ConfirmService,
    private _popupService: PopupService,
    private _snackBar: SnackbarService
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'deleteQuestion') {
            this.iqDeleteQuestion(this.confirmIqData);
          } else if (this.confirmAction === 'deleteAnswers') {
            this.iqDeleteAnswers();
          }
        }
      }
    );
  }

  openConfirmDialog(
    component: string,
    action: string,
    iqData: any,
    message: string
  ): void {
    this.confirmAction = action;
    this.confirmIqData = iqData;

    this.confirmData = { component: component, message: message };
    // send data to the confirm service
    this._confirmService.updateNotification(this.confirmData);
  }

  getQuizDetails(): void {
    this._cmsService.getIqQuizDetails(this.id).subscribe(data => {
      this.quizDetails = data.quiz;
    });
  }

  // ****** SHOW/HIDE METHODS ******
  // shows the new question form
  createQuestion(index, clipId): void {
    if (index === this.createIqQuestion) {
      this.createIqQuestion = -1;
    } else {
      this.createIqQuestion = index;
    }

    // so we can set the rank automatically
    const numQuestions = this.quizDetails.Clips[index].Questions.length;

    this.newQuestion = new QuizQuestion();
    this.newQuestion.Question = '';
    this.newQuestion.Explanation = '';
    this.newQuestion.Rank = numQuestions + 1;

    this.newQuestionClipId = clipId;
  }

  // validate if questions' ranks are in consecutive & also starts from 1
  areRankNumbersConsecutive(rankList) {    // Sort the Rank array
    rankList.sort(function (a, b) { return a - b; });
    // checking the adjacent elements
    for (var i = 1; i < rankList.length; i++) {
      // if duplicate then continue
      if (rankList[i] === rankList[i - 1])
        continue;
      // if not duplicate, that means numbers are not cosecutive
      if (rankList[i] != rankList[i - 1] + 1)
        return [false, 'Rank numbers are not consecutive'];
    }
    // check first Rank number starts from 1
    if (rankList[0] === 1)
      return [true, '']
    else
      return [false, 'Rank must start from 1'];
  }

  // Create a rank list for the questions
  createQuizRankList(index: number): Array<number> {
    var rankList: number[] = [];
    this.quizDetails.Clips[index].Questions.forEach((x, no) => {
      rankList.push(parseInt(x.Rank));
    });
    return rankList;
  }

  // shows the new answer form
  createAnswer(index, questionId, clipIndex): void {
    // show the correct new answer container
    if (index === this.createNewAnswer) {
      this.createNewAnswer = -1;
    } else {
      this.createNewAnswer = index;
    }

    // so we can set the rank automatically
    const numAnswers = this.quizDetails.Clips[clipIndex].Questions[index].Answers.length;

    // create a new answer instance
    this.newAnswer = new QuizAnswer();
    this.newAnswer.Answer = '';
    this.newAnswer.Rank = numAnswers + 1;

    // get the question id and store it (for answer creation)
    this.newAnswerQuestionId = questionId;
  }

  clearNewQuestion(): void {
    this.createIqQuestion = -1;
  }

  clearNewAnswer(): void {
    this.createNewAnswer = -1;
    this.newAnswerQuestionId = null;
  }

  showQuestions(index): void {
    if (index === this.selectedClip) {
      this.selectedClip = -1;
    } else {
      this.selectedClip = index;
    }
  }

  showAnswers(index, clipIndex): void {
    if (index === this.selectedAnswers) {
      this.selectedAnswers = -1;
    } else {
      const answerContainerString = 'clip' + clipIndex + 'question' + index;
      this.selectedAnswers = answerContainerString;
    }
    // reste the answers to delete array if switching between questions
    this.answersToDelete = [];
  }

  // ****** UPDATE METHODS ******
  iqSubmitQuestion(questionInfo, index): void {
    var rankList = this.createQuizRankList(index);
    var isValidRank = this.areRankNumbersConsecutive(rankList);
    if (!isValidRank[0]) {
      this._snackBar.error(isValidRank[1].toString());
      return;
    }
    this._cmsService
      .createUpdateIqQuizQuestion(questionInfo)
      .subscribe(data => {
        if (data.questionId === -1) {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Error,
            error: true
          });
        } else {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Success,
            success: true
          });
          // get the questions again (with new question added)
          this.getQuizDetails();
        }
      });
  }

  iqSubmitAnswers(answers): void {
    // check if answer list has only one correct answer, if true then save
    var correctCount = answers.filter(el => el.IsCorrect === true).length;
    if (correctCount === 1) {
      this._cmsService.createUpdateIqQuizAnswer(answers)
        .subscribe(data => {
          if (data.succeeded) {
            this._popupService.updateNotification({
              message: this._emsConfig.text.EMS_General.Popup_Success,
              success: true
            });
            // get the questions details (with updated answers)
            this.getQuizDetails();
          } else {
            this._popupService.updateNotification({
              message: this._emsConfig.text.EMS_General.Popup_Error,
              error: true
            });
          }
        });
    }
    else {
      this._popupService.updateNotification({
        message: 'Please select one correct answer before saving',
        error: true
      });
    }
  }

  // sets the correct answer on page load (as we're not using ngModel for this setting)
  setCorrectAnswer(formChange: any, questionIndex, clipIndex): void {
    const answerId = Number(formChange.getAttribute('answerId'));

    // get answers by question for comparison
    const questionAnswers = this.quizDetails.Clips[clipIndex].Questions[questionIndex].Answers;

    // loop through each of the answers
    for (let i = 0; i < questionAnswers.length; i++) {
      // check if answer that was clicked matches one of the question answer ids
      if (answerId === questionAnswers[i].ID) {
        // if it matches, set the isCorrect to opposite of it's current value
        this.quizDetails.Clips[clipIndex].Questions[questionIndex].Answers[i].IsCorrect = !questionAnswers[i].IsCorrect;
      } else {
        // if it doesn't match, set isCorrect to false
        this.quizDetails.Clips[clipIndex].Questions[questionIndex].Answers[i].IsCorrect = false;
      }

    }
  }

  // ****** CREATE METHODS ******

  iqCreateQuestion(questionInfo: any, index: number): void {
    var rankList = this.createQuizRankList(index);
    rankList.push(parseInt(questionInfo.Rank));
    var isValidRank = this.areRankNumbersConsecutive(rankList);
    if (!isValidRank[0]) {
      this._snackBar.error(isValidRank[1].toString());
      return;
    }

    this.newQuestion.ID = -1;
    this.newQuestion.Question = questionInfo.Question;
    this.newQuestion.Explanation = questionInfo.Explanation;
    this.newQuestion.Rank = questionInfo.Rank;
    this.newQuestion.MediaClipId = this.newQuestionClipId;

    this._cmsService
      .createUpdateIqQuizQuestion(this.newQuestion)
      .subscribe(data => {
        if (data.questionId === -1) {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Error,
            error: true
          });
        } else {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Success,
            success: true
          });
          // get the questions again (with new question added)
          this.getQuizDetails();
          // close the new question form
          this.createIqQuestion = -1;
        }
      });
  }

  iqCreateAnswer(answerInfo: any, questionAnswers: any): void {
    // new answer needs to be passed to API in an array (uses the same method as the update answers)
    let answerArray: Array<any> = [];

    this.newAnswer.ID = -1;
    this.newAnswer.Answer = answerInfo.Answer;
    this.newAnswer.Rank = answerInfo.Rank;
    // by default first answer is true, later select the actual right answer
    this.newAnswer.IsCorrect = (questionAnswers.length === 0) ? true : answerInfo.IsCorrect;
    this.newAnswer.ParentQuestionID = this.newAnswerQuestionId;

    answerArray.push(this.newAnswer);

    this._cmsService.createUpdateIqQuizAnswer(answerArray)
      .subscribe(data => {

        if (data.succeeded) {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Success,
            success: true
          });
          // get the questions details (with new answers added)
          this.getQuizDetails();
          // close the new answer form
          this.createNewAnswer = -1;
        } else {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Error,
            error: true
          });
        }
      });
  }

  // ****** DELETE METHODS ******

  iqDeleteQuestion(questionId: number): void {
    this._cmsService.deleteIqQuizQuestion(questionId).subscribe(data => {
      if (data.succeeded) {
        this._popupService.updateNotification({
          message: this._emsConfig.text.EMS_General.Popup_Success,
          success: true
        });
        // get the quiz details (without deleted question)
        this.getQuizDetails();
      } else {
        this._popupService.updateNotification({
          message: this._emsConfig.text.EMS_General.Popup_Error,
          error: true
        });
      }
    });
  }

  selectAnswerForDelete(answerId: number): void {
    const index = this.answersToDelete.indexOf(answerId);
    // checks if id is included to delete (if it is already included in the array delete it - this means it has been unflagged for deletion)
    if (index > -1) {
      this.answersToDelete.splice(index, 1);
    } else {
      this.answersToDelete.push(answerId);
    }
  }

  iqDeleteAnswers(): void {
    const numRequests = this.answersToDelete.length;
    let successCount = 0;
    let failCount = 0;

    // disable form buttons in case of multiple requests
    this.disableFormButtons = true;

    for (let i = 0; i < this.answersToDelete.length; i++) {
      const answerId = this.answersToDelete[i].toString();
      this._cmsService.deleteIqQuizAnswer(answerId).subscribe(data => {
        if (!data.succeeded) {
          failCount++;
        } else {
          successCount++;
        }

        // check if requests are complete
        if (successCount + failCount === numRequests) {
          // check if all were successful
          if (successCount === numRequests) {
            this._popupService.updateNotification({
              message: this._emsConfig.text.EMS_General.Popup_Success,
              success: true
            });
            // reset the changedAnswers array when answers successfully updated
            this.answersToDelete = [];
            // get the questions details (with updated answers)
            this.getQuizDetails();
          } else {
            this._popupService.updateNotification({
              message: this._emsConfig.text.EMS_General.Popup_Error,
              error: true
            });
          }

          // enable form buttons
          this.disableFormButtons = false;
        } else {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Error,
            error: true
          });
        }
      });
    }
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = Number(params['id']);
    });

    this.getQuizDetails();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.confirmSubscription.unsubscribe();
  }
}

import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Categories } from 'app/categories/categories';
import { CategoriesService } from 'app/categories/categories.service';
import { CmsService } from 'app/cms/cms.service';
import { SubCategory } from 'app/shared/SubCategory';
import { EmsConfig } from 'app/shared/emsConfig';


@Component({
  selector: 'app-cms-subcategory-list',
  templateUrl: './cms-subcategory-list.component.html'
})
export class CmsSubcategoryListComponent {
  @HostBinding('class') cmsSubcategoryList: string = 'cms-subcategory';

  // for category lists
  categoryList: Categories[];

  // for subcategory lists
  subCategories: SubCategory[] = [];

  // filter items
  public selectedCategory: number = -1;
  public subcategorySearch: string;
  public isLive: boolean = false;


  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public _cmsService: CmsService,
    private _categoriesService: CategoriesService,
    public _emsConfig: EmsConfig
  ) { }

  // get list of parent categories
  getCategories(): void {
    this._categoriesService.getAllCategories()
      .subscribe(
        CategoryList => {
          this.categoryList = CategoryList['categories'];
          console.log(this.categoryList);
        });
  }

  // get list of all subcategories
  getAllSubcategories(): void {
    this._cmsService.getAllSubcategories()
      .subscribe(
        SubcategoryList => {
          this.subCategories = SubcategoryList;
          console.log(this.subCategories);
        });
  }

  // Navigates to Subcategory Details page
  onSelect(id: number) {
    // Navigate with relative link
    this.router.navigate([id], { relativeTo: this.route });
  }

  // Navigate to the new subcategory page
  createNewSubcategory(): void {
    let id = -1;
    this.router.navigate([id], { relativeTo: this.route });
  }
  ngOnInit(): void {
    this.getCategories();
    this.getAllSubcategories();
  }
}

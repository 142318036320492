<div class="login-header card-box-shadow">
  <svg class="login-header__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 105">
    <defs>
      <rect x="5.8" y="7.2" width="288" height="91" />
    </defs>
    <clipPath>
      <use xlink:href="#SVGID_1_" />
    </clipPath>
    <path class="st0" fill="#54BAEA"
      d="M232.9 97c0.4 0.1 1.4 0.1 2.7-0.5 1.9-0.9 4.9-2.9 7.1-5.6 1.5-1.8 2.6-3.7 3.5-5.3 1.2-2 1.8-3 2.6-2.9 6.5 0.7 13.4-1.2 20.7-5.7 7.3-4.5 13.2-10.3 16.9-16.8 2.3-4 5.6-11.5 1.7-17.9 -6.4-10.5-23.7-10.1-33-10 -9.6 0.2-18 0.9-25.6 2.1 -8.5 1.4-16.8 3-23.4 6.8 -3.9 2.2-5.9 4.9-6.1 7.8 -0.3 5.7 5.3 10.2 9.4 13.4 5.4 4.3 11.5 7.9 17.3 11.2 3.7 2.1 7.4 4 11 5.7 0.3 0.1 0.7 0.5 1 1.3 0.6 2.1-0.2 7.1-4.8 14.7 -0.2 0.4-0.5 0.8-0.8 1.2C233.1 96.7 233 96.9 232.9 97M233.3 98.2c-0.6 0-1.1-0.1-1.4-0.5 -0.3-0.4-0.3-0.9 0-1.4 0.1-0.2 0.2-0.4 0.4-0.5 0.3-0.4 0.5-0.7 0.7-1.1 4-6.7 5.3-11.9 4.7-13.8 -0.1-0.3-0.2-0.5-0.3-0.5 -3.7-1.7-7.4-3.7-11.1-5.7 -5.8-3.3-12-6.9-17.4-11.2 -4.3-3.4-10.3-8.1-9.9-14.4 0.2-3.4 2.4-6.3 6.7-8.8 6.9-4 15.2-5.6 23.8-7 7.7-1.3 16.1-2 25.8-2.1 9.5-0.2 27.2-0.5 34 10.5 4.2 6.9 0.7 14.9-1.7 19.1 -3.9 6.7-9.9 12.6-17.3 17.2 -7.5 4.6-14.7 6.6-21.4 5.9 -0.3 0.2-1 1.3-1.5 2.3 -0.9 1.6-2.1 3.6-3.7 5.4 -2.1 2.4-5 4.7-7.5 5.9C235.2 98 234.2 98.2 233.3 98.2" />
    <path class="st1" fill="#EC6A53"
      d="M259.1 73.5c4.6 0.6 8.7 0.9 12.4 0.8 3.8-0.1 9.4-0.5 14.1-3 3-1.6 5.2-4.2 6.1-7.1 0.9-2.8 0.8-6.2-0.3-10 -1-3.7-2.8-7.4-5.4-11.6 -2.5-3.9-5.6-7.8-9.3-11.5 -3.6-3.7-7.6-7.1-11.7-9.9 -4.4-3-8.5-5.2-12.7-6.8 -4.4-1.6-8.6-2.4-12.3-2.2 -5.5 0.3-10 2.6-13.4 6.9 -6 7.6-10.1 20.6-4.8 31.1 1.9 3.7 4.7 7.3 8.4 10.5C237.8 67.6 248.1 72.1 259.1 73.5M270.2 75.5c-3.4 0-7.1-0.3-11.2-0.8 -11.2-1.5-21.7-6.1-29.6-13.1 -3.8-3.3-6.7-7-8.6-10.9 -5.5-11-1.3-24.5 4.9-32.4 3.6-4.5 8.5-7 14.2-7.3 3.9-0.2 8.2 0.6 12.8 2.3 4.3 1.6 8.5 3.9 13 6.9 4.2 2.9 8.2 6.3 11.9 10 3.7 3.8 6.8 7.7 9.4 11.7 2.7 4.2 4.5 8.1 5.6 11.9 1.1 4 1.2 7.6 0.3 10.7 -1 3.2-3.3 6-6.6 7.8 -4.8 2.7-10.7 3.1-14.6 3.2C271.1 75.5 270.6 75.5 270.2 75.5" />
    <path class="st2" fill="#75C8B7"
      d="M258 13.6c-14.2 3.6-26.2 12.3-32.3 23.2 -5.3 9.6-3 19.1-0.2 25.4 1.6 3.6 3.5 6.5 5.7 8.8 6.2 6.4 15.4 7.5 25.2 3 5.5-2.5 10.8-6.3 16.3-11.5 5.1-5 9.5-10.5 13.1-16.5 3.7-6.2 5.8-11.7 6.6-16.9 0.6-3.8 0.6-9.1-2.8-12.9 -3-3.4-8.1-5.1-15.2-5C269.6 11.2 264.4 12 258 13.6M244.9 77.8c-5.6 0-10.6-2.1-14.5-6.1 -2.3-2.4-4.3-5.4-6-9.1 -4.2-9.3-4.2-18.5 0.2-26.4 6.2-11.2 18.5-20 33-23.7 6.5-1.7 11.8-2.5 16.7-2.5 7.5-0.1 12.9 1.7 16.1 5.4 3.6 4.1 3.7 9.8 3 13.8 -0.8 5.4-3 11-6.8 17.4 -3.6 6-8.1 11.7-13.2 16.7 -5.5 5.4-11 9.2-16.6 11.8C252.7 76.9 248.7 77.8 244.9 77.8" />
    <path class="st3" fill="#E9AD21"
      d="M250 8.4c-2.3 0-4.5 0.7-6.5 2.1 -3.6 2.5-6.3 6.6-8.7 12.9 -2.2 5.9-3.6 12.5-4.2 20.2 -0.6 7.6-0.3 14.9 0.9 21.8 1.3 7.4 3.3 13.2 6.3 17.7 3.7 5.6 9 8.6 14.6 8.3 2.8-0.1 5.8-1 9.2-2.5 10.1-4.7 17.1-13.1 19.1-23.2 2.6-13-0.7-27.9-8.8-39.8 -4.3-6.3-9.2-12.6-15.7-15.9C254.1 8.9 252 8.4 250 8.4M251.7 92.5c-5.7 0-11.1-3.2-14.8-8.8 -3.1-4.7-5.2-10.6-6.5-18.2 -1.2-7-1.5-14.4-0.9-22 0.6-7.8 2-14.5 4.3-20.5 2.5-6.6 5.4-10.9 9.1-13.5 4-2.8 9-3 13.8-0.6 6.8 3.4 11.8 9.8 16.2 16.3h0c8.3 12.1 11.6 27.3 9 40.7 -2.1 10.4-9.2 19.2-19.7 24 -3.5 1.6-6.7 2.5-9.6 2.6C252.2 92.5 252 92.5 251.7 92.5" />
    <polygon class="st4" fill="#231F20" points="5.8 72.6 5.8 97.1 20.1 97.1 19.4 93.4 10.2 93.4 10.2 72.6 " />
    <rect x="26.7" y="72.6" fill="#231F20" class="st4" width="4.4" height="24.5" />
    <polygon class="st4" fill="#231F20"
      points="43.8 76.4 43.8 82.9 52.1 82.9 52.7 86.7 43.8 86.7 43.8 97.1 39.4 97.1 39.4 72.6 54.3 72.6 54.3 76.4 " />
    <polygon class="st4" fill="#231F20"
      points="59.7 97.1 59.7 72.6 74.9 72.6 74.9 76.4 64 76.4 64 82.5 72.7 82.5 73.3 86.3 64 86.3 64 93.3 75.2 93.3 75.2 97.1 " />
    <defs>
      <rect x="5.8" y="7.2" width="288" height="91" />
    </defs>
    <clipPath>
      <use xlink:href="#SVGID_3_" />
    </clipPath>
    <path class="st5" fill="#231F20"
      d="M89.2 97.5c-3.2 0-6.4-0.8-9-1.8l0.9-4.8c2.4 1.2 5.8 2.1 8.3 2.1 2.6 0 4.1-1 4.1-2.7 0-1.6-1-2.3-4.1-3.3 -5.4-1.7-8.3-3.3-8.3-7.6 0-4.5 3.6-7.5 8.7-7.5 3.2 0 5.9 0.7 7.7 1.6l-0.8 4.8c-2.2-1.1-4.7-1.8-7-1.8 -2.1 0-3.4 1.1-3.4 2.4 0 1.5 1.1 2.1 4.3 3.2 5.7 1.8 8.1 3.5 8.1 7.6C98.6 94.5 94.9 97.5 89.2 97.5" />
    <path class="st5" fill="#231F20"
      d="M112.4 76.7H110v7.6h2.5c3 0 4.6-1.3 4.6-3.8C117 78.1 115.4 76.7 112.4 76.7M112.4 88.7H110v8.5h-5.7V72.3h8.5c5.9 0 9.6 2.9 9.6 8C122.5 85.7 118.6 88.7 112.4 88.7" />
    <polygon class="st4" fill="#231F20"
      points="127.3 97.1 127.3 72.3 143.4 72.3 143.4 76.9 132.9 76.9 132.9 82.1 141.1 82.1 141.8 86.6 132.9 86.6 132.9 92.5 143.7 92.5 143.7 97.1 " />
    <path class="st4" fill="#231F20"
      d="M159.9 76.9h0.1l3.4 10.2h-6.9L159.9 76.9zM156.7 72.1l-9.1 25.1h5.5l1.9-5.6h10l2 6 5.6-1.1 -8.9-24.3H156.7z" />
    <polygon class="st4" fill="#231F20"
      points="190.8 72.3 182.5 83.3 182.5 72.3 176.8 72.3 176.8 97.1 182.5 97.1 182.5 85.3 191.7 97.5 197.7 96.4 188 83.8 197.2 72.3 " />
  </svg>
  <h1 class="login-header__title ls-bold">{{loginTitle}}</h1>
</div>
<div class="full-page-img ">
  <div class="login-form b-white changepass">
    <h2 class="login-form__title ls-headline f-dark-blue">Change password</h2>
    <div class="validaton-messages">
      <p class="login-form__error">{{message}}</p>
    </div>
    <div class="login-form__input-container">
      <div>
        <svg class="login-form__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        <input class="login-form__input ls-title" [disabled]="true" type="text" [(ngModel)]="username"
          placeholder="you@example.com" />

      </div>
      <div>
        <svg class="login-form__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
        </svg>
        <input class="login-form__input ls-title" type="password" [(ngModel)]="password" placeholder="password" />
      </div>
      <div>
        <svg class="login-form__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
        </svg>
        <input class="login-form__input ls-title" type="password" [(ngModel)]="reEnterPassword"
          placeholder="re-type password" />
      </div>

    </div>
    <div class="login-form__input-container login-form--remember">
      <label class="login-form__label login-form__return ls-body-1 f-primary" [routerLink]="['/login']">Back to
        login</label>
      <input class="login-form__btn btn btn--full-width ls-button-2" type="button" value="Submit"
        (click)="ChangePassword()" />
    </div>
  </div>

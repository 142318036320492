import { PipeTransform, Pipe } from '@angular/core';


@Pipe({
  name: 'connexStatusFilter',
  pure: false
})
export class ConnexStatusFilter implements PipeTransform {
  transform(items: any[], status: number): any[] {
    // if a status of ate-ready (7) is selected we want to also pull in status ate-processed (8)
    // this is irrelevant for the user but is important for back-end processes
    if (status === 7) {
      return items.filter(item => item.Status === status || item.Status === 8);
    }
    return items.filter(item => item.Status === status);
  }
}

@Pipe({
  name: 'connexLangFilter',
  pure: false
})
export class ConnexLangFilter implements PipeTransform {
  transform(items: any[], lang: string): any[] {
    if (items === null) {
      return null;
    }

    return items.filter(item => item.OriginalLang === lang);
  }
}

@Pipe({
  name: 'connexExpertLangFilter',
  pure: false
})
export class ConnexExpertLangFilter implements PipeTransform {
  transform(items: any[], lang: string): any[] {
    if (items === null) {
      return null;
    }

    return items.filter(item => item.Lang === lang);
  }
}

@Pipe({
  name: 'connexEmergencyRejectionFilter'
})
export class ConnexEmergencyRejectionFilter implements PipeTransform {
  transform(items: any[], emergencyRejection: boolean): any[] {
    return items.filter(item => item.EmergencyRejection === emergencyRejection);
  }
}

@Pipe({
  name: 'translatedATEFilter'
})
export class ATETranslatedFilter implements PipeTransform {
  transform(items: any[], isTranslated: boolean): any[] {
    return items.filter(item => item.IsTranslated === isTranslated);
  }
}

// input will search both webchat topic and speaker
@Pipe({
  name: 'webchatSearchFilter',
  pure: false
})
export class WebchatSearchFilter implements PipeTransform {
  transform(value: any[], ...args: string[]): any[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
    return filter ? value.filter((webchat: any) =>
      webchat.EventTopic.toLocaleLowerCase().indexOf(filter) !== -1 || webchat.ExpertNameNormalized.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}

// to sort webchats by editing/ready filter
@Pipe({
  name: 'webchatStatusFilter'
})
export class WebchatStatusFilter implements PipeTransform {
  transform(webchats: any[], ...args: string[]): any[] {
    if (webchats == null) {
      return null;
    }
    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;

    if (filter === 'editing') {
      return webchats.filter((webchat: any) => !webchat.IsTranslated);
    } else if (filter === 'ready') {
      return webchats.filter((webchat: any) => webchat.IsTranslated);
    } else if (filter === 'for_marathon') {
      console.log(webchats);
      return webchats.filter((webchat: any) => webchat.For_Marathon);
    } else {
      return webchats;
    }
  }
}


// ate moderation filters
@Pipe({
  name: 'blogSearchFilter'
})
export class BlogSearchFilter implements PipeTransform {
  transform(items: any[], searchTerm: string): any[] {
    if (items == null)
      return null;

    return items.filter(post => post.PostTitleNormalized.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) !== -1);
  }
}


@Pipe({
  name: 'marathonSearchFilter',
  pure: false
})
export class MarathonSearchFilter implements PipeTransform {
  transform(value: any[], ...args: string[]): any[] {
    if (value == null) {
      return null;
    }

    const filter: string = args[0] ? args[0].toLocaleLowerCase() : null;
    return filter ? value.filter((marathon: any) =>
      marathon.Title.toLocaleLowerCase().indexOf(filter) !== -1 || marathon.Language.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}

import { Component, OnInit } from '@angular/core';
import { CmsService } from 'app/cms/cms.service';

@Component({
  selector: 'app-cms-campaign-list',
  templateUrl: './cms-campaign-list.component.html'
})
export class CmsCampaignListComponent implements OnInit {
  campaigns: any;

  constructor(
    private _cmsService: CmsService
  ) { }

  ngOnInit(): void {
    this._cmsService.getCampaignList()
    .subscribe(
      data => {
        this.campaigns = data;
        console.log(this.campaigns);
      }
    )
  }

}

<div class="cms-canada-report-template-container section-padding">
  <h1 class="report-template-header">Canada Report Templates</h1>
  <div class="all-template-container">
    <div class="template-container__template-list">
      <table class="display-table display-table--extra-padding">
        <thead>
          <tr>
            <th>ID</th>
            <th>Template Title</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let template of templates">
            <td>{{template.ID}}</td>
            <td class="template-link" (click)="onSelect(template.ID)">{{template.TemplateTitle}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <button class="btn--fab b-dark-blue cms-container__fab cms-container__fab--add" (click)="createNewTemplate()">
      <svg viewBox="0 0 24 24">
        <path fill="#ffffff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" /></svg>
    </button>
</div>

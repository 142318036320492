import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { LoginToken } from '.././shared/login-token';
import { EmsConfig } from '../shared/emsConfig';

@Injectable()
export class LoginService {

  constructor( ) { }

  //see if the user has a valid login token
  checkLoginStatus(): boolean {
    let currentToken: LoginToken =  window["loginToken"] ;
    let loginStatus = currentToken !== null && currentToken?.IsValid;
    return loginStatus;
  }

  //set the is logged in var
  private isLoggedIn: Subject<boolean> = new BehaviorSubject(this.checkLoginStatus());

  isLoggedIn$ = this.isLoggedIn.asObservable();

  //update the login status
  changeLoginStatus(isLoggedIn: boolean) {
    this.isLoggedIn.next(isLoggedIn);
  }

}

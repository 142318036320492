
// Angular Things
import { Component, HostBinding, OnInit } from '@angular/core';

// Config stuff
import { EmsConfig } from '../../shared/emsConfig';

//  Services
import { CmsService } from '../cms.service';
import { ImageConfigDetails } from 'app/shared/objects/imageConfigDetails';

@Component({
  selector: 'cms-images', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-images.component.html'
})

export class CmsImageAssetsComponent implements OnInit {
  @HostBinding('class') classAttribute: string = 'cms-images';

  public images: string[] = [];
  public imageConfigDetails: ImageConfigDetails;

  public imageConfigs: string[] = [];
  public originalImages: string[] = [];
  public missingImages: string[] = [];

  constructor(
    public _cmsService: CmsService,
    public _emsConfig: EmsConfig) {

  }

  ngOnInit(): void {
    this.getImageAssets();
  }

  //Get list of available polls
  getImageAssets = () => {

    this._cmsService.getFolderWithImageResizingConfig().subscribe(folders => {
      console.log(folders);
      console.log(folders);
      this.imageConfigDetails = folders as ImageConfigDetails;
    }, error => { console.log(error) });
  }

}

// Angular Things
import {
  Component,
  HostBinding,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

// Services
import { CmsService } from '../cms.service';
import { ConnexService } from '../../connex/connex.service';
import { UtilitiesService } from '../../shared/utilities.service';
import { ConfirmService } from '../../shared/confirm.service';
import { PopupService } from '../../shared/popup.service';

// Classes
import { Expert } from '../../shared/expert';
import { ConnexCategory } from '../../connex/Objects/ConnexCategory';
import { EmsConfig } from '../../shared/emsConfig';
import { ExpertAssignments } from 'app/shared/ExpertAssignments';
import { ExpertSpeciality } from 'app/shared/ExpertSpeciality';
import { IndividualExpertSpeciality } from 'app/shared/IndividualExpertSpeciality';
import { Categories } from 'app/categories/categories';

import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { SpinnerService } from 'app/shared/spinner.service';
import { CategoriesService } from 'app/categories/categories.service';
import { ExpertBioVideo } from '../ExpertBioVideoItem';
import { FormControl } from '@angular/forms';
import { ExpertBiography } from 'app/shared/objects/expertBiography';

declare var tinymce: any;

@Component({
  selector: 'cms-expert-details', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-expert-details.component.html',
  providers: [Location]
})
export class CmsExpertDetailComponent implements OnInit, OnDestroy {
  // adds class to parent element (element named using the selector in the component decorator)
  // @HostBinding('class.cmsVolumeDetail') cmsVolumeDetail = true;

  // variables for getting account id from url
  id: number;

  public Lang: string = 'EN';

  private sub: any;
  errorMessage: string;

  expertDetails: Expert;
  newExpertID: any;
  oldAssignment = new ExpertAssignments();
  customTranscript: any;

  // Connex properties
  public connexCategories: ConnexCategory[] = [];
  public categoryVals: any = {};
  Provinces: any;
  States: any;
  mediaDropdownList: any;
  public categoryList: Categories[];
  public specialityList: ExpertSpeciality[];
  public specialityByCategory: ExpertSpeciality[];

  specialities: ExpertSpeciality;
  public specialityID: number;
  public individualSpecialityList: ExpertSpeciality[];
  public speciality = new Array<IndividualExpertSpeciality>();

  public assignmentList: ExpertAssignments[];
  public assignments = new ExpertAssignments();
  public volumeList: any;

  public isEnglish: boolean = false;
  public isFrench: boolean = false;
  public isSpanish: boolean = false;
  public isOtherLanguage: boolean = false;
  public spokenLanguage: string[] = [];

  // confirm variables
  confirmData: {};
  confirmExpertData: any;
  confirmAction: string;
  confirmSubscription: Subscription;

  // image stuff
  imgPreviewUrl: string;
  showImgPreview: boolean = false;
  imageToUpload: string;
  resendImage = false;
  imageInAmazon: boolean = false;

  public displayUploadFileModal = false;
  saveReminderPdf: boolean = false;
  public contractFile: string[] = [];
  private pdfPath = this._emsConfig.secureAssetPath + '/ExpertContracts/';
  maxFileSize: number = 10 * 1024 * 1024;
  public contractPdfUploader: FileUploader = new FileUploader({ url: this._emsConfig.apiEndpoint + '/expertAdmin/addconractpdftoamazon', maxFileSize: this.maxFileSize });
  private failureMessage = { message: 'Sorry, an error has occured. Please contact an administrator', error: true };
  public addEditDeleteText: string = 'Add, edit or delete';
  public addEditDeleteAssignmentText: string = 'Add, edit or delete';
  public addDeleteLangText: string = 'Add or delete languages';
  public showSpecialitySection: boolean = false;
  public showAssignmentSection: boolean = false;

  bioVideos: ExpertBioVideo[];
  newBioVid: ExpertBioVideo;
  showNewVideoFields: boolean = false;

  allLanguages: any[];
  selectedLanguage: string='';
  mainLanguage: any;
  showLangSection: boolean = false;
  langTabs: ExpertBiography[] = [];
  selected:number=0;
  tabtitle: string = '';
  isMainLanguage = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private _cmsService: CmsService,
    private _connexService: ConnexService,
    public _utilitiesService: UtilitiesService,
    private _confirmService: ConfirmService,
    private _popupService: PopupService,
    public _emsConfig: EmsConfig,
    private _changeDetectionRef: ChangeDetectorRef,
    private _spinnerService: SpinnerService,
    private _categoriesService: CategoriesService
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'updateExpert') {
            this.expertSubmitForm(this.confirmExpertData);
          }
        }
      }
    );
  }

  onEvent(event: any) {
    event.stopPropagation();
  }

  // setting language when language tab changes
  setLang(lang: string): void {
    this.Lang = lang;
  }

  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(
    component: string,
    action: string,
    expertData: any,
    message: string
  ): void {
    this.confirmAction = action;
    this.confirmExpertData = expertData;

    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }

  // gets details about expert and initialize the data in different field of UI
  getExpertDetails(): void {
    this._cmsService.getExpertDetails(this.id).subscribe(data => {
      // this.expertDetails = data;
      this.expertDetails = new Expert();
      this.expertDetails = Object.assign(this.expertDetails, data);
      console.log(this.expertDetails);
      if (this.id === -1) {
        this.expertDetails.ExpertID = 'N/A';
        this.expertDetails.ConnexStatus = '';
        this.expertDetails.TimeZone = 'ET';
        this.expertDetails.Lang = '';
      } else {
        // empty langTabs array before assigning biography list to avoid data redundancy after any transaction
        this.langTabs = [];
        setTimeout(() => {
          // assign all biography list from experts language table
          this.langTabs = this.expertDetails.ExpertBiography;
          // also add main language data in langTabs array in Zero index to show in tab list
          this.langTabs.splice(0, 0, (this.buildExpertBiography(this.expertDetails.Lang, this.expertDetails.Title, this.expertDetails.ShortBio, this.expertDetails.FullBio)));
          for (let i = 0; i < this.langTabs.length; i++) {
         // set up tinymce for all elements in array
            this.setupTinyMce(i);
          }
        }, 0);


        console.log('editing expert #' + this.id);
        if (data.PhotoUrl !== '') {
          // this.imageInAmazon = true;
          this.previewExpertImage();
        }
      }
      this.getLanguagesForExpert(this.expertDetails.Spoken_Languages);
      // check for bio videos
      this.getBioVideos();

    }, err => (this.errorMessage = <any>err));
  }


  // ==================
  // Bio Video Section
  // ==================

  // get list of bio videos for expert
  getBioVideos(): void {
    this._cmsService.getExpertBioVideos(this.id).subscribe(data => {
      console.log(data);
      this.bioVideos = data;
    });
  }

  // add or update bio video
  insertUpdateBioVideo(video): void {
    console.log(video);
    if (this._utilitiesService.isBlank([video.BrightcoveID, video.ExpertID, video.LangCode])) {
      this._popupService.updateNotification({ message: this._emsConfig.text.EMS_Content.Complete_Fields_Error, error: true });
    } else {
      // set the uploaded properties to false so DB knows the video needs to re-uploaded
      video.HighResUploaded = false;
      video.LowResUploaded = false;

      this._cmsService.addUpdateExpertBioVideo(video).subscribe(data => {
        console.log(data);
        if (data != -1) {
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Success, success: true });

          // hide new video fields
          this.showNewVideoFields = false;

          // get the expert details (gets list of new bio videos and checks for syncing)
          this.getExpertDetails();
        } else {
          this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
        }
      });
    }
  }

  // delete a bio video
  deleteBioVideo(video): void {
    console.log(video);
    this._cmsService.deleteExpertBioVideo(video.ID).subscribe(data => {
      console.log(data);

      if (data) {
        this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Success, success: true });
        // get the new list of bio videos
        this.getBioVideos();
      } else {
        this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
      }

    });
  }


  //  flag a video for retransfer to AWS
  retransferBioVideo(video): void {
    console.log(video);
    this._cmsService.retransferExpertBioVideo(video.ID).subscribe(data => {
      console.log(data);

      if (data) {
        this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Success, success: true });
        // get the new list of bio videos
        this.getBioVideos();
      } else {
        this._popupService.updateNotification({ message: this._emsConfig.text.EMS_General.Popup_Error, error: true });
      }

    });
  }

  addNewBioVideo(): void {
    // setup new video object
    this.newBioVid = new ExpertBioVideo();
    this.newBioVid.ID = -1;
    this.newBioVid.ExpertID = this.id;
    this.newBioVid.HighResUploaded = false;
    this.newBioVid.LowResUploaded = false;

    // show the add video fields
    this.showNewVideoFields = true;
  }

  expertSubmitForm(expertDetails): void {
    // check that all fields are filled out before trying to create/update volume
    if (
      this._utilitiesService.isBlank([
        expertDetails.FirstName,
        expertDetails.LastName
      ])
    ) {
      this._popupService.updateNotification({
        message: this._emsConfig.text.EMS_Content.Complete_Fields_Error,
        error: true
      });
    } else {
      if (expertDetails.ExpertID === 'N/A') {
        expertDetails.ExpertID = -1;
      }
      this.expertDetails.Spoken_Languages = this.spokenLanguage.toString();
      // TODO: Right now all updates/inserts will be for default lang. Update once additional language support is added for titles/bios
      this.expertDetails.IsDefaultLang = true;

      // get full bio
      // this.expertDetails.FullBio = tinymce.get('expert-full-bio-default').getContent();
      this.expertDetails.ExpertBiography = this.getExpertMultilangBiography();
      console.log(expertDetails);
      this._cmsService.addUpdateExpert(expertDetails).subscribe(response => {
        // throw error if the expert's email is already assigned to someone else (for ATE Sign-up purposes)
        if (response.Status === 'already-assigned') {
          this._popupService.updateNotification({
            message: 'Expert email already assigned to Expert ID: ' + response.ExpertID,
            error: true
          });
          return;
        }

        if (response.ExpertID !== -1) {
          // check for create or update
          if (this.id === -1) {
            // this is to updated the url with the new volume id
            this.newExpertID = response.ExpertID;
            this.location.go('/cms/expert/' + this.newExpertID);
            this.id = this.newExpertID;
          }

          // If a username has been supplied (and is a valid email address), update the Expert's User Role
          if (!this._utilitiesService.isBlank([expertDetails.Username])) {
            if (!this._utilitiesService.isValidEmail(expertDetails.Username)) {
              this._popupService.updateNotification({
                message: this._emsConfig.text.EMS_GDPR_Info.Valid_Email_Error,
                error: true
              });
              return;
            } else {
              this.updateExpertRole(expertDetails.Username, expertDetails.Lang);
            }
          }

          this.getExpertDetails();

          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Success,
            success: true
          });
        } else {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Error,
            error: true
          });
        }
      });
    }
  }

  getLanguagesForExpert(lang: string): void {
    this.spokenLanguage = (lang === '') || (lang==undefined || lang===null) ? (this.spokenLanguage = []) : lang.toString().split(',');
    this.isEnglish = this.spokenLanguage.includes('EN');
    this.isFrench = this.spokenLanguage.includes('FR');
    this.isSpanish = this.spokenLanguage.includes('ES');
    this.isOtherLanguage = this.spokenLanguage.includes('Other');
  }

  // fetch the image path from the amazon according db photoUrl
  previewExpertImage(): void {
    let imgUrl = '';
    // use the correct image preview path
    imgUrl = this._emsConfig.assetPath + '/ImageAssets/originals/experts/' + this.expertDetails.PhotoUrl;
    this.imgPreviewUrl = imgUrl;
    console.log(this.imgPreviewUrl);
  }


  // Update an expert's user role to Connexpert
  updateExpertRole(username: string, lang: string): void {
    this._connexService.updateExpertRole(username, lang)
      .subscribe(response => {
        console.log(response);
        if (response === -1) {
          this._popupService.updateNotification({
            message: "There was a problem updating the Expert's Connex Signup Email. Please confirm the email and try again.",
            error: true
          });
        }
      });
  }


  // =================================================
  // contract file upload, download & delete functions
  // =================================================

  // gets all the contract file names in string and convert them to array
  getContractFiles() {
    this._cmsService.getContractFileName(this.id)
      .subscribe(data => {
        this.contractFile = (data === '') || (data==undefined || data===null) ? (this.contractFile = []) : data.toString().split(',');
        this.displayUploadFileModal = true;
        console.log(this.contractFile.length);
      },
        err => {
          this._popupService.updateNotification(this.failureMessage);
        }
      );
  }

  // deletes file name from DB & also file from cloudberry
  deleteContractFile(value) {
    const index: number = this.contractFile.indexOf(value);
    this.contractFile.splice(index, 1);
    this._cmsService.deleteContractFile(this.id, value)
      .subscribe(
        data => {
          this.updateContractFile(this.contractFile.toString());
          console.log(data);
          this.getExpertDetails();
          this._popupService.updateNotification({ message: 'File has been deleted successfully', success: true });
        },
        err => { this._popupService.updateNotification(this.failureMessage); }
      );
  }

  // checks before upload & then uploads pdf file
  uploadAsset(): void {
    let currentItem;
    let uploader;
    let fileType = '';
    uploader = this.contractPdfUploader.queue;
    currentItem = this.contractPdfUploader.queue[0];
    fileType = '.pdf';

    if (currentItem !== undefined && currentItem.file.name.indexOf(fileType) === -1) {
      alert('Only ' + fileType + ' files are permitted for upload');
      uploader.pop();
    } else if (currentItem.file.name.includes(' ')) {
      alert('Please ensure file name does not include spaces');
      uploader.pop();
    } else if (this.contractFile.find(x => x === currentItem.file.name)) {
      alert('That pdf already exists!')
      uploader.pop();
    } else {
      // by default, the file uploader plugin requires credentials for submission, this remove them
      uploader.forEach(file => file.withCredentials = false);
      // manually trigger the ajax spinner (doesn't work for file uploader requests)
      this._spinnerService.updateSpinner({ toggle: true });
      currentItem.upload();
    }
  }

  // updates contract file name in db after each upload, delete
  updateContractFile(fileName: string) {
    this._cmsService.updateContractFiles(this.id, fileName)
      .subscribe(
        data => {
          console.log(data);
          this.getExpertDetails();
        },
        err => { this._popupService.updateNotification(this.failureMessage); }
      );
  }

  onModalBodyClickEvent(event: any) {
    event.stopPropagation();
  }

  // downloads pdf from cloudberry
  downloadPDF(value) {
    let path = `${this.pdfPath + 'Expert-' + this.id + '-Contract' + '/'}${value}`;
    console.log(path);
    window.open(path, '_blank');
  }

  // cloases the manage contract file modal
  closeRequestModal() {
    this.displayUploadFileModal = false;
  }

  // format size to byte
  private formatBytes(bytes, decimals?) {
    if (bytes === 0) { return '0 Bytes' };
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  // =================================================
  // add, delete & update speciality
  // =================================================

  // gets all the categories
  getCategories(): void {
    this._categoriesService.getAllCategories()
      .subscribe(
        CategoryList => {
          this.categoryList = CategoryList['categories'];
        },
        err => this.errorMessage = <any>err
      );
  }

  // gets all the specialities with category details
  getExpertSpecialitiesWithCategory(): void {
    this._cmsService.getAllExpertSpeciality()
      .subscribe(
        SpecialityList => {
          this.specialityList = SpecialityList;
          console.log(this.specialityList);
        },
        err => this.errorMessage = <any>err
      );
  }

  // adds specialities for expert
  addSpeciality(): void {
    console.log(this.specialities);
    this._cmsService.addIndividualExpertSpeciality(this.id, this.specialities.SpecialityID).subscribe(
      data => {
        console.log(data);
        if (data) {
          this._popupService.updateNotification({
            message: 'A new speciality has been added for the expert!',
            success: true
          });
          this.getIndividualExpertSpecialities();
        } else {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Error,
            error: true
          });
        }
      });
  }

  // updates specialities for expert
  updateSpeciality(value: ExpertSpeciality): void {
    this._cmsService.updateIndividualExpertSpeciality(this.id, value).subscribe(
      data => {
        console.log(data);
        if (data) {
          this._popupService.updateNotification({
            message: 'Expert speciality has been updated!',
            success: true
          });
          this.getIndividualExpertSpecialities();
        } else {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Error,
            error: true
          });
        }
      });
  }

  // deletes specialities for expert
  deteteExpertSpeciality(SpecialityID: number): void {
    this._cmsService.deleteIndividualExpertSpeciality(this.id, SpecialityID).subscribe(
      data => {
        if (data) {
          this._popupService.updateNotification({
            message: 'Expert speciality has been deleted successfully!',
            success: true
          });
          this.getIndividualExpertSpecialities();
        } else {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Error,
            error: true
          });
        }
      });
  }

  // get specialities for individual expert
  getIndividualExpertSpecialities(): void {
    this._cmsService.getIndividualExpertSpeciality(this.id)
      .subscribe(
        SpecialityList => {
          this.individualSpecialityList = SpecialityList;
          console.log(this.individualSpecialityList);
        },
        err => this.errorMessage = <any>err
      );
  }

  // filter specialities for expert
  filterSpecialityByCategory(filterVal: any) {
    this.specialityByCategory = this.specialityList.filter(item => item.CategoryID == filterVal);
    console.log(this.specialityByCategory);
  }

  // add expert assignment
  addExpertAssignment(): void {
    this.assignments.ExpertID = this.id;
    console.log(this.assignments);
    this._cmsService.insertExpertAssignment(this.assignments).subscribe(
      data => {
        if (data) {
          this._popupService.updateNotification({
            message: 'A new assignment has been added for the expert!',
            success: true
          });
          this.getExpertAllAssignments();
        } else {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Error,
            error: true
          });
        }
      });
  }

  // adupdated expert assignment
  updateAssignment(value: ExpertAssignments): void {
    console.log(this.oldAssignment);
    this._cmsService.updateExpertAssignment(value).subscribe(
      data => {
        console.log(data);
        if (data) {
          this._popupService.updateNotification({
            message: 'Expert assignment has been updated!',
            success: true
          });
          this.getExpertAllAssignments();
        } else {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Error,
            error: true
          });
        }
      });
  }

  // get expert's all assignment
  getExpertAllAssignments(): void {
    this._cmsService.getExpertAllAssignments(this.id)
      .subscribe(
        AssignmentList => {
          this.assignmentList = AssignmentList;
          console.log(this.assignmentList);
        },
        err => this.errorMessage = <any>err
      );
  }

  // delete expert assignment
  deteteExpertAssignment(item: ExpertAssignments): void {
    console.log(item);
    item.AssignmentDate = this._utilitiesService.trimDate(item.AssignmentDate);
    this._cmsService.deleteExpertAssignment(item).subscribe(
      data => {
        if (data) {
          this._popupService.updateNotification({
            message: 'Expert assignment has been deleted successfully!',
            success: true
          });
          this.getExpertAllAssignments();
        } else {
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Error,
            error: true
          });
        }
      });
  }

  // get expert volume with status
  getVolumesByExpert(): void {
    this._cmsService.getVolumesByExpert(this.id)
      .subscribe(
        VolumeList => {
          this.volumeList = VolumeList;
          console.log(this.volumeList);
        },
        err => this.errorMessage = <any>err
      );
  }

  getVolumeStatus(item: string): string {
    return (item === 'live') ? 'Active' : 'Retired';
  }

  removeNonDigitCharacters(value: string) {
    return value.replace(/[^0-9,.]/g, '');
  }

  // toggle speciality add edit delet section
  toggleSpecialitySection(): void {
    this.showSpecialitySection = !this.showSpecialitySection;
    if (this.showSpecialitySection) {
      this.getCategories();
      this.getExpertSpecialitiesWithCategory();
    }
    this.addEditDeleteText = this.showSpecialitySection ? 'Done editing' : 'Add, edit or delete';
  }

  // toggle assignment add edit delet section
  toggleAssignmentSection(): void {
    this.showAssignmentSection = !this.showAssignmentSection;
    this.addEditDeleteAssignmentText = this.showAssignmentSection ? 'Done editing' : 'Add, edit or delete';
  }

  // Language checkbox section
  FieldsChange(values: any) {
    console.log(values);
    switch (values) {
      case 'EN':
        this.checkLanguageAnddelete(this.isEnglish, values);
        break;
      case 'FR':
        this.checkLanguageAnddelete(this.isFrench, values);
        break;
      case 'ES':
        this.checkLanguageAnddelete(this.isSpanish, values);
        break;
      case 'Other':
        this.checkLanguageAnddelete(this.isOtherLanguage, values);
        break;
    }
  }

  checkLanguageAnddelete(flagValue: boolean, lang: string): void {
    if (flagValue && !this.spokenLanguage.includes(lang)) {
      this.spokenLanguage.push(lang);
    } else if (!flagValue && this.spokenLanguage.includes(lang)) {
      this.spokenLanguage.splice(this.spokenLanguage.indexOf(lang), 1);
    }
  }

  // get list of languages available
  getAllLanguages() {
    this._cmsService.getLanguages().subscribe(langs => {
      this.allLanguages = langs;
      console.log(this.allLanguages);
    })
  }


  ngOnInit(): void {
    // gets the expert id from url
    this.sub = this.route.params.subscribe(params => {
      this.id = Number(params['id']);
    });

    this.States = this._utilitiesService.allStatesOfUSA();
    this.Provinces = this._utilitiesService.allProvicesOfcanada();
    this.mediaDropdownList = this._utilitiesService.allMediaList();

    // gets all the token api headers and adds to any upload request
    this.contractPdfUploader.options.headers = this._emsConfig.getFileUploadHeaders();
    this.contractPdfUploader.options.additionalParameter = { 'expertid': this.id };

    // gets response when file size exceeded/ too large
    this.contractPdfUploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      switch (filter.name) {
        case 'fileSize':
          this._popupService.updateNotification({ message: 'Maximum upload size exceeded ' + this.formatBytes(item.size) + ' of ' + this.formatBytes(this.maxFileSize) + ' allowed', error: true });
          this._spinnerService.updateSpinner({ toggle: false });
          break;
        default:
          this._popupService.updateNotification({ message: 'Unknown error ' + filter.name, error: true });
          this._spinnerService.updateSpinner({ toggle: false });
      }
    };

    // gets the response after post pdf file upload is completed
    this.contractPdfUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (this._utilitiesService.isBlank([response])) {
        this._popupService.updateNotification({ message: 'Unknown error. Contract file has not been uploaded', error: true });
        this._spinnerService.updateSpinner({ toggle: false });
      } else {
        if (!item.isSuccess) {
          this._popupService.updateNotification({ message: 'Contract file has not been uploaded. Please try again.', error: true });
          this.contractPdfUploader.queue = [];
          this._spinnerService.updateSpinner({ toggle: false });
        } else {
          this._popupService.updateNotification({ message: 'Contract file has been successfully uploaded.', success: true });
          this.contractPdfUploader.queue = [];
          this.saveReminderPdf = true;
          this.contractFile.push(item.file.name);
          this.updateContractFile(this.contractFile.toString());
          this._spinnerService.updateSpinner({ toggle: false });
        }
      }
    };

    // gets expert details
    this.getExpertDetails();
    this.getExpertAllAssignments();
    this.getVolumesByExpert();
    this.getIndividualExpertSpecialities();
    this.getAllLanguages();
  }

  // ============================
  //Expert Biography Globalization
  // =============================

  // set up tinymce editor
  setupTinyMce(editorId): ExpertBiography[] {
    // make sure the old instance is removed before new init
    tinymce.remove(tinymce.get('expert-full-bio-default' + editorId));
    var bio: ExpertBiography[] = this.langTabs;

    var editMode = this.showLangSection;

   // run the init
    tinymce.init({
      selector: '#expert-full-bio-default' + editorId,
      // selector: '.expert-full-bio-default',
      plugins: 'link table code lists',
      toolbar:
        'code | undo redo | styles fontsize | bullist numlist | bold italic underline | link unlink | alignleft aligncenter | nonbreaking',
      font_size_formats: '16px',
      browser_spellcheck: true,
      min_height: 350,
      readonly:(editMode===true)?1:0,
      menubar: false,
      inline_styles: true,
      content_style: 'body {font-size: 16px;}',
      formats: {
        paragraph: { block: 'p', styles: { fontSize: '14px' } },
      },
      style_formats: [
        { title: 'Paragraph', format: 'paragraph' },
      ],
      setup: function (editor) {
        editor.on('init change blur', function (e) {
          bio[editorId].FullBio = editor.getContent();
        });
      }
    });
    return bio;
  }

  // add main lang biography to expertdetails array & remove it from lang tabs array to avoid duplicate value
  getExpertMultilangBiography(): ExpertBiography[] {
    var index = 0;
    for (let i = 0; i < this.langTabs.length; i++) {
      if (this.langTabs[i].Lang === this.expertDetails.Lang) {
        this.expertDetails.FullBio = this.langTabs[i].FullBio;
        this.expertDetails.ShortBio = this.langTabs[i].ShortBio;
        this.expertDetails.Title = this.langTabs[i].Title;
        index = i;
      }
    }
    this.langTabs.splice(index, 1);
    return this.langTabs;
  }


  // build expert bio for individual tab
  buildExpertBiography(lang, title = '', ShortBio = '', fullbio = ''): ExpertBiography {
    const expertBiography: ExpertBiography = new ExpertBiography();
    expertBiography.FullBio = fullbio,
    expertBiography.ShortBio = ShortBio,
    expertBiography.Title = title;
    expertBiography.Lang = lang;
    return expertBiography;
  }

  // when selecting language from dropdown
  SelectLanguage(value: any) {
    if (this.langTabs.findIndex(x => x.Lang === this.selectedLanguage) === -1) {
      setTimeout(() => {
        this.langTabs.push(this.buildExpertBiography(this.selectedLanguage));
        this.selected=this.langTabs.length - 1;
        this._changeDetectionRef.markForCheck();
          }, 0);

    } else {
      this._popupService.updateNotification({ message: 'You already added this Language', error: true });
    }
  }

  // when tab is changed
  langTabSelectedIndexChange(index: number) {
    this.selected=index;
    setTimeout(() => {
      this.setupTinyMce(index);
    }, 0);
  }

  // toggle language editor to add delete
  toggleLangSection(): void {
    this.showLangSection = !this.showLangSection;
    this.setupTinyMce(this.selected);
    this.addDeleteLangText = this.showLangSection ? 'Done editing' : 'Add or delete languages';
  }
  // delete lang for expert
  removeLangTab(index: number) {
    console.log(this.langTabs);
    if (this.langTabs[index].Lang === this.expertDetails.Lang) {
      this.isMainLanguage = true;
    }
    // check if current language exists in database
    if (this.langTabs[index].ID || this.isMainLanguage) {
      this._cmsService.deleteExpertBiographyByLan(this.id, this.langTabs[index].Lang, this.isMainLanguage)
        .subscribe(
          data => {
            if (data) {
              this._popupService.updateNotification({
                message: 'Language data has been deleted successfully!',
                success: true
              });
              console.log(data);
              this.langTabs.splice(index, 1);
              this.getExpertDetails();
            } else {
              this._popupService.updateNotification({
                message: this._emsConfig.text.EMS_General.Popup_Error,
                error: true
              });
            }
          });
    } else {
      this.langTabs.splice(index, 1);
      console.log(index);
    }
    //this.setupTinyMce(index);
  }


 // ================================
  // get back to expert listing page
  // ===============================
  getBack() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    // if tinymce was initialized then destroy
    if (tinymce.initialized === true){
    tinymce.activeEditor.destroy();
    }
    this.confirmSubscription.unsubscribe();
  }
}

// Angular Things
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmService } from 'app/shared/confirm.service';
import { EmsConfig } from 'app/shared/emsConfig';
import { PopupService } from 'app/shared/popup.service';
import { UtilitiesService } from 'app/shared/utilities.service';
import { Subscription } from 'rxjs';
import { CmsService } from '../cms.service';
import { HelpSection } from '../HelpSection';

@Component({
  selector: 'cms-help', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-help.component.html'
})

export class CmsHelpComponent implements OnInit {
  subscription: Subscription;

  helpSections: HelpSection[] = [];

  errorMessage: string;

  lang: string = 'EN';

  newHelpSection: HelpSection;
  editSection: HelpSection;

  addSection: boolean = false;
  updatingSection: boolean = false;
  createNew: boolean = false;

  editMode: boolean = false;


  confirmData: {};
  confirmSectionData: any;
  confirmAction: string;
  confirmSubscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute, public _cmsService: CmsService, public _emsConfig: EmsConfig, public _confirmService: ConfirmService, public _popupService: PopupService, public _utilitiesService: UtilitiesService) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'addSection') {
            this.updateHelpSection(this.confirmSectionData);
            console.log('add a section');
          }
        }
      }
    );
   }

  openConfirmDialog(component: string, action: string, sectionData: any, message: string): void {
    this.confirmAction = action;
    this.confirmSectionData = sectionData;

    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }

  getHelpSections(lang): void {
      this._cmsService.getHelpSections(lang).subscribe(
        data => {
          this.helpSections = data;
        },
        err => this.errorMessage = <any>err,
        () => console.log(this.helpSections)
      );
  }

  getSingleSection(id): void {
    this._cmsService.getHelpSectionbyID(id).subscribe(
      data => {
        this.editSection = data;
      },
      err => this.errorMessage = <any>err,
      () => console.log(this.editSection)
    );
  }

  createNewHelpSection(): void {
    console.log('add it!');
    this.addSection = true;
    this.newHelpSection = new HelpSection;
    this.newHelpSection.ID = -1;
    this.newHelpSection.SectionName = null;
    this.newHelpSection.SectionLang = null;
    this.newHelpSection.SortOrder = null;
    this.editMode = true;
    this.updatingSection = false;
    this.createNew = true;
  }

  clearNewSection(): void {
    this.addSection = false;
    this.createNew = false;
    this.editMode = false;
  }

  editHelpSection(id): void {
    // this.router.navigate([id], { relativeTo: this.route });
    this.editMode = true;
    this.updatingSection = true;
    this.createNew = false;
    this.addSection = false;

    this.getSingleSection(id);
  }

  updateHelpSection(section): void {
    console.log(section);

    if (this._utilitiesService.isBlank([section.SectionName, section.SectionLang, section.SortOrder])) {
      this._popupService.updateNotification({ message: 'Please fill out all required fields.', error: true });
    } else {
      console.log(section);
      this._cmsService.updateHelpSection(section).subscribe(
        data => {
          console.log(data);
          if (this.createNew) {
            if (data.sectionId === -1) {
              this._popupService.updateNotification({ message: 'Help section not created. Please try again.', error: true });
            } else {
              this._popupService.updateNotification({ message: 'Help section has been updated successfully.', success: true });
              this.createNew = false;
              //show edit fields after successful create to allow for syncing
              this.editHelpSection(data.sectionId);
              this.getHelpSections(this.lang);
            }
          } else {
            if (!data.succeeded) {
              this._popupService.updateNotification({ message: 'Help section not been updated. Please try again.', error: true });
            } else {
              this._popupService.updateNotification({ message: 'Help section has been updated successfully.', success: true });
              this.getHelpSections(this.lang);
            }
          }
        }
      );
    }

  }

  editQuestions(sectionId): void {
    this.router.navigate([sectionId], { relativeTo: this.route });
  }

  // update the help sections list by language
  setLang(lang: string): void {
    this.lang = lang;
    console.log(this.lang);
    this.getHelpSections(this.lang);
  }

  ngOnInit(): void {
    this.getHelpSections(this.lang);
  }

  ngOnDestroy() {
    this.confirmSubscription.unsubscribe();
  }

}

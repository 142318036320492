import { Component, HostBinding, OnInit } from '@angular/core';

// components & other things
import { EmsConfig } from '../shared/emsConfig';
import { TooltipComponent } from '../shared/tooltip.component';

// classes
import { SyncOperation } from './sync-operation';
import { SyncRequest } from './sync-request';
import { SyncResult } from './sync-result';
import { SyncInfo } from 'app/sync/sync-info';

// services
import { SyncService } from './sync.service';
import { PopupService } from '../shared/popup.service';
import { ConfirmService } from '../shared/confirm.service';
import { Subscription } from 'rxjs';
import { AdminService } from 'app/admin/admin.service';

@Component({
  selector: 'organizationSync', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'organization-sync.component.html'
})

export class OrganizationSyncComponent implements OnInit {

  public SyncOperations: SyncOperation[];

  public Result: SyncResult;

  public ErrorMessage: string;

  activeTableFilter: string;

  public modalOpen: boolean = false;

  public syncInfo: Array<SyncInfo> = [];

  public accountSearch: string;

  confirmSubscription: Subscription;

  confirmData: {};
  confirmSyncId: any;
  confirmAction: string;
  confirmOrgName: string;

  constructor(private syncService: SyncService,
    private popupService: PopupService,
    public emsConfig: EmsConfig,
    private confirmService: ConfirmService,
    private _adminService: AdminService) {
    this.confirmSubscription = confirmService.actionConfirmed$.subscribe(
      response => {
        console.log('sync id is ' + this.confirmSyncId);
        if (!response.confirmed) {
          console.log('cancelled');
        } else if (response.data.component === 'sync' && response.confirmed) {
          console.log('confirmed');
          if (this.confirmAction === 'sync') {
            this.executeSync(this.confirmSyncId, false, this.confirmOrgName,response.data.operation);
          }
        }
      }
    );
  }

  public executeSync(operationID: number, revert: boolean, orgName: string, operation: string) {
    const request: SyncRequest = new SyncRequest();
    request.OperationID = operationID;
    request.Revert = revert;
    request.OrganizationName = orgName;
    request.OperationName = operation;
    this.executeSyncForOrganization(request);
  }

  ngOnInit() {
    this.getUnsyncedOperationsForOrganizations();
  }

  // retrieve all unsynced operation for this user
  private getUnsyncedOperationsForOrganizations(): void {
    this.syncService.getSyncStatusForOrganizations()
      .subscribe(
        SyncOperationItems => {
          this.SyncOperations = SyncOperationItems;
          console.log(this.SyncOperations);
        },
        err => this.ErrorMessage = <any>err
      );
  }

  private updateSyncOperations(operationID: number): void {
    this.SyncOperations = this.SyncOperations.filter(function (operation: any) {
      return operation.ID !== operationID;
    });
  }

  public getSyncInfo(operationID: number): void {
    this.syncService.getSyncInfoForOrganizations(operationID)
      .subscribe(
        info => {
          this.syncInfo = info;
          this.modalOpen = true;
          console.log(this.syncInfo);
        });
  }

  public closeModal() {
    this.modalOpen = false;
  }

  // retrieve all unsynced operation for this user
  private executeSyncForOrganization(operation: SyncRequest): void {
    this.syncService.executeSyncOperation(operation)
      .subscribe(
        SyncOperationItem => {
          this.Result = SyncOperationItem;
          //if error occurs show message for 10 seconds
          this.popupService.updateNotification({ message: this.Result.Message, success: this.Result.Status, duration: this.Result.Status ? 5000 : 10000 });
          if (this.Result.Message === 'Sync Succeeded' || this.Result.Message === 'Revert Succeeded') {
            this.updateSyncOperations(operation.OperationID);
          }
          //clear main site's cache
          this._cacheHandler(operation);
        },
        err => {
          this.ErrorMessage = <any>err;
          this.popupService.updateNotification({ message: 'An error occured.', success: false });
        }
      );
  }
  //This function makes a request to 5.0 web app and clears the cache for the client's volumes and preferences.
  //Function is looking for OperationName and successes message.
  //in order to clear other asset's cache add a check for that OperationName and also update the cache endpoint
  //on 5.0 web app.
  private _cacheHandler(operation: SyncRequest) {
    if (this.Result.Message === 'Sync Succeeded' && operation.OperationName === 'update_client_library') {
      this.ClearClientCacheForAsset('volumes', operation.OrganizationName, null);
    }
    if (this.Result.Message === 'Sync Succeeded' && operation.OperationName === 'update_account_settings') {
      this.ClearClientCacheForAsset('preferences', operation.OrganizationName,null);
      this.ClearClientCacheForAsset('explorevideos', operation.OrganizationName,null);
    }
  }

  public ClearClientCacheForAsset(assetToken: string, client: string, lang: string) {
    this._adminService.clearClientCacheForAsset(assetToken, client, lang).subscribe(data => {
      console.log(data);
    });
  }

  // update various pipe filters
  public updateSyncTable(type: string): void {
    this.activeTableFilter = type;
  }


  stopProp(event: any) {
    event.stopPropagation();
  }

  openConfirmDialog(component: string, action: string, syncId: any, orgName: string, message: string,operation:string): void {
    this.confirmSyncId = syncId;
    this.confirmAction = action;
    this.confirmOrgName = orgName;
    this.confirmData = { component: component, message: message ,operation:operation};
    this.confirmService.updateNotification(this.confirmData);
  }

  ngOnDestroy(): void {
    this.confirmSubscription.unsubscribe();
  }

}

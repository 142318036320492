import { Injectable } from '@angular/core';
import { EmsConfig } from '../../shared/emsConfig';
import { Subject } from 'rxjs';

import * as signalR from "@microsoft/signalr";
import { EncryptionService } from '../helpers/EncryptionService';

@Injectable({
  providedIn: 'root',
})
export class WebSocketsService {

  private connection: signalR.HubConnection;
  // create subject so components can subscribe to connected status and display error message
  connectedStatus: Subject<boolean> = new Subject<boolean>();

  constructor(private _emsConfig: EmsConfig, private EncrDecr: EncryptionService) { }

  public buildConnection(eventId): void {
    //Get Auth token and append to connection
    let token = this._emsConfig.loginToken.BearerToken;
    // console.log(token);
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this._emsConfig.signalRHubURL + "/chathub?client=" + this._emsConfig.emsDomain + '&eventid=' + eventId + "&token=" + encodeURIComponent(token))
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Debug)
      .build();
    //start connection
    this.startConnection();
  }

  public getConnection(): signalR.HubConnection {
    return this.connection;
  }

  private startConnection(): void {
    console.log('startConnection');
    this.connection
      .start()
      .then(() => {
        this.connectedStatus.next(true);
        console.log('Hub connection started');
      })
      .catch(err => {
        console.log('Error while establishing connection, retrying...');
        this.connectedStatus.next(false);
      });
  }



}

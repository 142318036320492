
<div class="dashboard-page-container inner-page-container">
    <div class="inner-page-main" style="padding:50px">
        <h1>{{welcomeMessage}}</h1>
        <div class="controls">

           <div class="searchbar-input">
                <svg class="searchbar__icon" fill="#707070" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                </svg>
             <!--  <input [(ngModel)]="accountSearch" type="search" placeholder="{{_emsConfig.text?.EMS_Accounts.Search_Account}}">-->
            </div>

            <div class="ls-checkbox just-my-accounts" *ngIf="_emsConfig.loginToken?.Role === 'accountadmin'">
                <input [(ngModel)]="filterAccounts" type="checkbox" id="filter-accounts">
                <label for="filter-accounts"><span></span></label>
                {{_emsConfig.text?.EMS_Reporting.My_Accounts}}
            </div>
        </div>
        <table class="display-table display-table--extra-padding">
            <thead>
                <tr>
                    <th class="tooltip-column">{{_emsConfig.text?.EMS_Reporting.Organization}} <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Reporting.Organization_Dashboard_Tooltip"></tooltip>  </th>
                    <th class="tooltip-column">{{_emsConfig.text?.EMS_Reporting.Daily_Views}} <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Reporting.Daily_Views_Tooltip"></tooltip></th>
                    <th class="tooltip-column">{{_emsConfig.text?.EMS_Reporting.Quarterly_Views}} <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Reporting.Quarterly_Views_Tooltip"></tooltip></th>
                    <th class="tooltip-column">{{_emsConfig.text?.EMS_Reporting.Annual_Views}} <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Reporting.Annual_Views_Tooltip"></tooltip></th>
                    <th class="tooltip-column">{{_emsConfig.text?.EMS_Reporting.Accounts_Created}} <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Reporting.Accounts_Created_Tooltip"></tooltip></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of accountsFiltered |dashboardNameFilter:accountSearch ">
                    <td><a href="javascript:void(0);" [routerLink]="['/accounts/account',item.Organization]">  {{item.Organization}}</a></td>
                    <td>{{item.ViewsDaily.toLocaleString()}}</td>
                    <td>{{item.ViewsQuarterly.toLocaleString()}}</td>
                    <td>{{item.ViewsCalendarYear.toLocaleString()}}</td>
                    <td>{{item.PersonalAccounts.toLocaleString()}}</td>
                </tr>
            </tbody>
        </table>

    </div>

</div>

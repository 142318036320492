
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EmsConfig } from '.././shared/emsConfig';

// Connex objects
import { AteQuestion } from './Objects/AteQuestion';
import { AteQuestionStatus } from './Objects/AteQuestionStatus';
import { AteAnswer } from './Objects/AteAnswer';
import { ConnexCategory } from './Objects/ConnexCategory';
import { MarathonScheduleItem } from './Objects/MarathonScheduleItem';
import { MarathonParticipatingClient } from './Objects/MarathonParticipatingClient';
import { MarathonTranscript } from './Objects/MarathonTranscript';
import { forkJoin } from 'rxjs';
import { MarathonPostEventEmailRequest } from './Objects/MarathonPostEventEmailRequest';

@Injectable()
export class ConnexService {
  private _getCategoriesURL = this.emsConfig.apiEndpoint + '/askTheExpert/getcategories';
  private _getExpertByUsernameURL = this.emsConfig.apiEndpoint + '/askTheExpert/getexpertbyusername?username=';
  private _updateExpertRoleURL = this.emsConfig.apiEndpoint + '/askTheExpert/updateexpertrole';

  private _updateQuestionAndAnswerURL = this.emsConfig.apiEndpoint + '/askTheExpert/updatequestionandanswer';

  // ATE edit / translate URLs
  private _getExpertEventsURL = this.emsConfig.apiEndpoint + '/askTheExpert/getasktheexpertevents';
  private _getMarathonTranscriptsURL = this.emsConfig.apiEndpoint + '/askTheExpert/getmarathontranscripts';
  private _getExpertEventByIDURL = this.emsConfig.apiEndpoint + '/askTheExpert/geteventbyid?withStreamStatus=true&id=';
  private _getAnswersByWebchatIDURL = this.emsConfig.apiEndpoint + '/askTheExpert/getanswersbywebchatid?id=';
  private _updateWebchatURL = this.emsConfig.apiEndpoint + '/askTheExpert/updatemodifiedwebchats';

  private _getATEEventByIDURL = this.emsConfig.apiEndpoint + '/askTheExpert/geteventdetails?id=';
  private _getAteParticipatingClientsURL = this.emsConfig.apiEndpoint + '/askTheExpert/getateparticipatingclients?eventid=';
  private _updateCreateEventURL = this.emsConfig.apiEndpoint + '/askTheExpert/createupdateevent';
  private _getATEEnrollemntURL = this.emsConfig.apiEndpoint + '/askTheExpert/getcurrentenrollmentforchat?eventID=';
  private _updateATEStreamStatusURL = this.emsConfig.apiEndpoint + '/askTheExpert/updatestreamstatus?eventId=';
  private _submitATEQuestionURL = this.emsConfig.apiEndpoint + '/askTheExpert/submitatequestionadmin';
  private _getATEQuestionsURL = this.emsConfig.apiEndpoint + '/askTheExpert/getatequestions?eventId=';
  private _modifyATEQuestionsURL = this.emsConfig.apiEndpoint + '/askTheExpert/modifyquestion';
  private _getATEQuestionsForExpertURL = this.emsConfig.apiEndpoint + '/askTheExpert/getatequestionsforexpert?expertId=';
  private _getATEMarathonScheduleListURL = this.emsConfig.apiEndpoint + '/askTheExpert/getatemarathonschedulelist?language=';
  private _getEventsByMarathonId = this.emsConfig.apiEndpoint + '/askTheExpert/geteventsbymarathonid?id=';

  private _importEventQuestionsURL = this.emsConfig.apiEndpoint + '/askTheExpert/importeventquestions?eventId=';

  private _getMarathonScheduleList = this.emsConfig.apiEndpoint + '/askTheExpert/getmarathonschedules';
  private _getMarathonDetailsByID = this.emsConfig.apiEndpoint + '/askTheExpert/getmarathondetailsbyid?eventid=';
  private _addUpdateMarathon = this.emsConfig.apiEndpoint + '/askTheExpert/addupdatemarathonschedule';
  private _deleteMarathonTranscript = this.emsConfig.apiEndpoint + '/askTheExpert/deletemarathontranscript';

  private _requestMarathonTestEmailURL = this.emsConfig.apiEndpoint + '/askTheExpert/sendpostmarathontestemail';
  private _requestMarathonTestEmailAZURL = this.emsConfig.apiEndpoint + '/askTheExpert/sendpostmarathontestemailaz';

  constructor(private _httpClient: HttpClient, private emsConfig: EmsConfig) { }

  // *****************************
  // ATE Edit / translate
  // *****************************

  // get list of connex categories
  getCategories(): Observable<ConnexCategory[]> {
    return this._httpClient.get<ConnexCategory[]>(this._getCategoriesURL);
  }

  // get a connexpert by providing a username
  getExpertByUsername(username: string): Observable<any> {
    const encodedName = encodeURIComponent(username);
    return this._httpClient.get<any>(
      this._getExpertByUsernameURL + encodedName
    );
  }
  // update an expert's role to Connexpert so they can login to EMS, -1 = not found
  updateExpertRole(username: string, lang: string): Observable<any> {
    const body = JSON.stringify({ UserName: username, Lang: lang });
    return this._httpClient.post<any>(this._updateExpertRoleURL, body);
  }

  // update a question and answer
  updateQuestionAndAnswer(answer: AteAnswer): Observable<any> {
    const body = JSON.stringify(answer);
    return this._httpClient.post<any>(this._updateQuestionAndAnswerURL, body);
  }
  // Get event listings
  // fromProduction flag indicates getting data from production database
  getAllExpertEvents(clientname: string, count: number = null, fromProduction: boolean = false): Observable<any[]> {
    let queryParams = '?fromProduction=' + fromProduction + (count ? '&count=' + count : '') + (clientname ? '&clientname=' + clientname : '');
    return this._httpClient.get<any[]>(this._getExpertEventsURL + queryParams);
  }

  getMarathonTranscriptEvents(clientname: string ,lang:string, fromProduction: boolean = false): Observable<any[]> {
    let queryParams =  '?clientname=' + clientname  +'&language='+ lang+'&fromprod=' + fromProduction;
    return this._httpClient.get<any[]>(this._getMarathonTranscriptsURL + queryParams);
  }

  // Get specific ask the expert event
  getExpertEventByID(id: number): Observable<any> {
    return this._httpClient.get<any[]>(this._getExpertEventByIDURL + id);
  }

  // get event details by id
  getAteEventbyID(id: number): Observable<any> {
    return this._httpClient.get<any[]>(this._getATEEventByIDURL + id);
  }

  getAteParticipatingClientsByEventID(id: number): Observable<any> {
    return this._httpClient.get<any[]>(this._getAteParticipatingClientsURL + id);
  }


  // update or create new event
  updateCreateEvent(event: Event): Observable<any> {
    const body = JSON.stringify(event);
    console.log(event);
    return this._httpClient.post<any>(this._updateCreateEventURL, body);
  }
  // Get a list of questions and answers by web chat ID
  getAnswersByChatID(id: number): Observable<any[]> {
    return this._httpClient.get<any[]>(this._getAnswersByWebchatIDURL + id);
  }

  // Get the current enrollment for a given webchat
  getWebchatEntrollmentByID(id: number): Observable<any[]> {
    return this._httpClient.get<any[]>(this._getATEEnrollemntURL + id);
  }

  updateATEWebchats(): Observable<any> {
    return this._httpClient.post<any>(this._updateWebchatURL, '');
  }

  updateATEStreamStatus(eventId: number, streamVal: boolean): Observable<any> {
    const url = this._updateATEStreamStatusURL + eventId.toString() + '&streamVal=' + streamVal.toString();
    return this._httpClient.post<any>(url, '');
  }

  submitATEQuestion(question: AteAnswer): Observable<AteAnswer> {
    const body = JSON.stringify(question);
    return this._httpClient.post<AteAnswer>(this._submitATEQuestionURL, body);
  }

  getATEQuestions(eventId: number): Observable<AteAnswer[]> {
    return this._httpClient.get<AteAnswer[]>(this._getATEQuestionsURL + eventId);
  }

  getATEQuestionsForExpert(expertId: number, eventId: number): Observable<AteAnswer[]> {
    return this._httpClient.get<AteAnswer[]>(this._getATEQuestionsForExpertURL + expertId + '&eventid=' + eventId);
  }

  modifyATEQuestion(question: AteAnswer): Observable<AteAnswer> {
    const body = JSON.stringify(question);
    return this._httpClient.post<AteAnswer>(this._modifyATEQuestionsURL, body);
  }

  importATEQuestions(eventId: number): Observable<boolean> {
    return this._httpClient.get<boolean>(this._importEventQuestionsURL + eventId);
  }

  getATEMarathonScheduleList(lang: string): Observable<MarathonScheduleItem[]> {
    return this._httpClient.get<MarathonScheduleItem[]>(this._getATEMarathonScheduleListURL + lang);
  }

  getEventsByMarathonId(id: number): Observable<MarathonScheduleItem[]> {
    return this._httpClient.get<MarathonScheduleItem[]>(this._getEventsByMarathonId + id);
  }
  getMarathonScheduleList(): Observable<MarathonScheduleItem[]> {
    return this._httpClient.get<MarathonScheduleItem[]>(this._getMarathonScheduleList);
  }

  addUpdateMarathonSchedule(marathon: MarathonScheduleItem): Observable<MarathonScheduleItem> {
    const body = JSON.stringify(marathon);
    return this._httpClient.post<MarathonScheduleItem>(this._addUpdateMarathon, body);
  }
  deleteMarathonTranscript(transcript: MarathonTranscript): Observable<boolean> {
    const body = JSON.stringify(transcript);
    return this._httpClient.post<boolean>(this._deleteMarathonTranscript, body);
  }

  //make parallel http call
  getMarathonDetailsById(marathonId: number): Observable<any[]> {
    let marathondetails = this._httpClient.get<MarathonScheduleItem>(this._getMarathonDetailsByID + marathonId);
    let assignedEvents = this._httpClient.get<MarathonScheduleItem[]>(this._getEventsByMarathonId + marathonId);
    return forkJoin([marathondetails, assignedEvents]);
  }

  requestMarathonTestEmailURL(request: MarathonPostEventEmailRequest): Observable<boolean> {
    const body = JSON.stringify(request);
    return this._httpClient.post<boolean>((this.emsConfig.infraLocation == 'azure')? this._requestMarathonTestEmailAZURL: this._requestMarathonTestEmailURL, body);
  }
}
